import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useIntl } from "react-intl";
import { Grid, Header, Divider, Loader } from "semantic-ui-react";
import { SemanticWIDTHS } from "semantic-ui-react/dist/commonjs/generic";
import { Svg } from "../../../../../assets/svg";
import { StatisticElement, DashboardInformation } from "../../../../../interfaces";
import { PlayersServices } from "../../../../../services";

import { descriptors, DashboardType } from "../../descriptors";

import "./styles.scss";
import { PlayerStatsCard } from "../../../../../components/PlayerStatsCard";

interface OwnProps {
  coachId: number;
  playerId: number;
}
type Props = OwnProps & RouteComponentProps;

const StatisticsFC: React.FC<Props> = ({ history, coachId, playerId }) => {
  const { formatMessage } = useIntl();

  const [playersElements, setPlayersElements] = React.useState<StatisticElement[]>([]);
  const [dashboardInfo, setDashboardInfo] = React.useState<DashboardInformation | undefined>(undefined);

  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getStatistics = async () => {
      try {
        setLoading(true);
        // const stats = await CoachService.statistics(coachId);
        PlayersServices.dashboardInformation(playerId, "MONTHLY", coachId).then(setDashboardInfo);
        //  loadData(stats);

        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    getStatistics();
  }, [playerId, coachId]);

  React.useEffect(() => {
    if (dashboardInfo?.statistics) {
      loadData();
    }
  }, [dashboardInfo]);

  const getDescColor = (amount?: number | string): "positive" | "negative" | undefined => {
    if (amount === undefined || Number(amount) === 0) {
      return undefined;
    }
    return Number(amount) > 0 ? "positive" : "negative";
  };

  const loadData = (): void => {
    setPlayersElements(
      dashboardInfo
        ? [
            {
              title: formatMessage({ ...descriptors[DashboardType.posts] }),
              value: dashboardInfo.statistics?.goalsPosted?.value,
              description: dashboardInfo.statistics?.goalsPosted?.percent,
              descColor: getDescColor(dashboardInfo.statistics?.goalsPosted?.percent),
              iconName: Svg.PostGoals2,
              disable: true,
            },
            {
              title: formatMessage({ ...descriptors[DashboardType.followers] }),
              value: dashboardInfo.statistics?.followers?.value,
              description: dashboardInfo.statistics?.followers?.percent,
              descColor: getDescColor(dashboardInfo.statistics?.followers?.percent),
              iconName: Svg.PlayerActive2,
              disable: true,
            },
            {
              title: formatMessage({ ...descriptors[DashboardType.likes] }),
              value: dashboardInfo.statistics?.likes?.value,
              description: dashboardInfo.statistics?.likes?.percent,
              descColor: getDescColor(dashboardInfo.statistics?.likes?.percent),
              iconName: "",
              icon: "heart outline",
              disable: true,
            },
            {
              title: formatMessage({ ...descriptors[DashboardType.comments] }),
              value: dashboardInfo.statistics?.comments?.value,
              description: dashboardInfo.statistics?.comments?.percent,
              descColor: getDescColor(dashboardInfo.statistics?.comments?.percent),
              iconName: "",
              icon: "comment alternate outline",
              disable: true,
            },
            {
              title: formatMessage({ ...descriptors[DashboardType.shares] }),
              value: dashboardInfo.statistics?.shares?.value,
              description: dashboardInfo.statistics?.shares?.percent,
              descColor: getDescColor(dashboardInfo.statistics?.shares?.percent),
              iconName: "",
              icon: "paper plane outline",
              disable: true,
            },
            {
              title: formatMessage({ ...descriptors[DashboardType.clicks] }),
              value: dashboardInfo.statistics?.clicks?.value,
              description: dashboardInfo.statistics?.clicks?.percent,
              descColor: getDescColor(dashboardInfo.statistics?.clicks?.percent),
              iconName: Svg.Clicks,
              disable: true,
            },
          ]
        : []
    );
  };

  const statisticsElement = (sectionName: string, stats: StatisticElement[]): JSX.Element => {
    return (
      <Grid className={"statistics"}>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h2">{formatMessage({ ...descriptors[DashboardType[`${sectionName}Title`]] })}</Header>
          </Grid.Column>
        </Grid.Row>
        <div className="postCardContainer playerStatsRow">
          {stats.map(
            ({ title, iconName, value, description, descColor, additionalDesc, additionalValue, icon }, index) => {
              return (
                <PlayerStatsCard
                  key={title + index}
                  imageName={iconName}
                  icon={icon}
                  title={title}
                  subtitle="Last 30 days"
                  value={Number(value)}
                  description={Number(description)}
                  additionalValue={additionalValue}
                  additionalDesc={additionalDesc}
                  descType={descColor}
                />
              );
            }
          )}
        </div>
      </Grid>
    );
  };

  return (
    <Grid.Row columns={1} className={"statisticsContent"}>
      <Grid.Column>
        <Grid columns={1}>
          <Grid.Row columns={1}>
            <Grid.Column width={16}>
              {loading && <Loader active size="large" />}
              {statisticsElement("statisticsSection", playersElements)}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid.Row>
  );
};

export const Statistics = withRouter(StatisticsFC);
