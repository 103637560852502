import React from "react";
import { Header, Grid, Image } from "semantic-ui-react";

import "./styles.scss";

interface OwnProps {
  avatarUrl: string;
  nickName: string;
}

type Props = OwnProps;
const baseDataElm = "TiktokAccountInfoClass";
/**
 * TiktokAccountInfoClass
 */
const TiktokAccountInfoClass: React.FC<Props> = ({ nickName, avatarUrl }) => {
  return (
    <Grid.Row>
      <Grid>
        <Grid.Row>
          <Header as="h5">Tiktok Account</Header>
        </Grid.Row>
        <Grid.Row>
          <Image className={"avatarImage"} src={avatarUrl} />
          <Header data-elm-id={baseDataElm+"Nickname"} as="h6" className="smallPadding">
            {nickName}
          </Header>
        </Grid.Row>
      </Grid>
    </Grid.Row>
  );
};

export const TiktokAccountInfo = TiktokAccountInfoClass;
