import * as React from "react";
import { filterPassedTime, nextWeekDay, validAge } from "../../../../../utils";
import moment from "moment-timezone";
import { SocialGrid, SocialRowGrid } from "../../../../../styling/baseStyle";
import DatePicker from "react-datepicker";
import { Input } from "semantic-ui-react";
import { dateKey, timeKey } from "../../../../../interfaces";
import { useCallback, useEffect, useState } from "react";

interface ProjectSchedulerParams {
  deliveryDate?: string;
  minDate?: Date;
  editable?: boolean;
  onChange: (d: Date) => void;
}

type Props = ProjectSchedulerParams;

export const ProjectScheduler: React.FC<Props> = ({
  deliveryDate,
  editable = true,
  minDate = nextWeekDay(nextWeekDay()),
  onChange,
}) => {
  const [postDate, setPostDate] = useState<Date | undefined>();

  useEffect(() => {
    if (deliveryDate) {
      setPostDate(moment(deliveryDate).toDate());
    } else {
      setPostDate(minDate);
    }
  }, [deliveryDate]);

  const updateDate = useCallback(
    (date: Date) => {
      setPostDate(date);
      onChange(date);
    },
    [onChange]
  );

  return (
    <SocialGrid className={"xbigPaddingTop xxbigPaddingLeft"}>
      <SocialRowGrid>
        <SocialGrid.Column width={8} className={"noPadding"}>
          <DatePicker
            customInput={<Input icon="calendar alternate outline" />}
            data-elm-id={"ProjectSelectedDateInput"}
            id={dateKey}
            name={dateKey}
            selected={postDate}
            onChange={updateDate}
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={15}
            maxDate={validAge(minDate, 1)}
            minDate={minDate}
            dateFormat="EE, MM/dd/yyyy"
            disabled={!editable}
            autoComplete={"off"}
            placeholderText={"Select a date"}
          />
        </SocialGrid.Column>
        <SocialGrid.Column width={8} className={"noPadding"}>
          <DatePicker
            customInput={<Input icon="clock outline" />}
            data-elm-id={"ProjectSelectedTimeInput"}
            id={timeKey}
            name={timeKey}
            selected={postDate}
            onChange={updateDate}
            showTimeSelect
            showTimeSelectOnly
            dateFormat="h:mm aa"
            autoComplete={"off"}
            disabled={!editable}
            placeholderText={"Select a time"}
          />
        </SocialGrid.Column>
      </SocialRowGrid>
    </SocialGrid>
  );
};
