import * as React from "react";
import { useMemo } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useIntl } from "react-intl";
import { Grid, Loader } from "semantic-ui-react";
import { get } from "lodash";

import { ConfirmationModal, SideBar, TitleSection } from "../../../components";
import { Media, UploadProgressMap, VideoProjectRequest, VideoProjectSource } from "../../../interfaces";
import { AppContext } from "../../../providers";
import { getSocialNetworkMessages, URLS } from "../../../utils";
import { VideoProjectServices } from "../../../services";

import { VideoProjectForm } from "./components/VideoProjectForm";
import { modalReducer } from "./reducer";

import { descriptors, VideoProjectPageType } from "./descriptors";
import { WhiteContainer } from "../../../styling/baseStyle";
import "./styles.scss";
import { ProjectFileUploader } from "../../../utils/projectFileUploader";
import { ConfigContext } from "../../../contexts/appContexts";
import { UploadProgressModal } from "../../../components/UploadProgressModal";

type Props = RouteComponentProps;

const VideoProjectFC: React.FC<Props> = ({ history, location }) => {
  const { formatMessage } = useIntl();
  const { userContext } = React.useContext(AppContext);
  const { firebase } = React.useContext(ConfigContext);

  const [state, dispatch] = React.useReducer(modalReducer, {
    open: false,
    status: "ACTIVE",
    duplicated: false,
    action: "close",
  });

  const preloadMedia = get(location, "state.preloadMedia", undefined) as Media | undefined;
  const scriptId = get(location, "state.scriptId", undefined);

  // Properties
  const [loading, setLoading] = React.useState<boolean>(false);
  const [formErrorMessages, setFormErrorMessages] = React.useState<string[] | undefined>(undefined);
  const [uploadingFiles, setUploadingFiles] = React.useState(false);
  const [uploadProgressMap, setUploadProgressMap] = React.useState<UploadProgressMap>();
  const [isValid, setIsValid] = React.useState(true);
  const [videoProject, setVideoProject] = React.useState<any | undefined>({
    originalMediaGroup: preloadMedia ? { type: "VIDEO", media: [preloadMedia] } : undefined,
  });

  const playerId = useMemo(() => {
    return userContext?.player?.id;
  }, [userContext?.player?.id]);
  const coachId = useMemo(() => {
    return userContext?.subscriptions!![0].pricePlan.coachId;
  }, [userContext?.subscriptions]);

  const createVideoProject = async (): Promise<any> => {
    if (videoProject) {
      const {
        allowShortLink,
        allowComplianceDisclaimer,
        allowHashtags,
        isAutoPost,
        status,
        originalMediaGroup,
        withMessage,
        preferredPostDate,
        hashtags,
        initialMessages,
        preferences,
      } = videoProject;

      const snFiltered = Object.keys(videoProject.socialNetworkMessages).filter(
        key => videoProject.socialNetworkMessages[key]
      );
      const socialNetworkMessages = getSocialNetworkMessages(snFiltered);

      const uploader = new ProjectFileUploader("videoProject", playerId!.toString(), firebase);
      const uploadMap: UploadProgressMap = {};
      originalMediaGroup.media.forEach((mediaObj: Media) => {
        uploadMap[mediaObj.id] = { progress: 0, task: undefined };
      });
      setUploadProgressMap(uploadMap);
      setUploadingFiles(true);
      originalMediaGroup.media = await uploader.prepareMedia(originalMediaGroup.media, (fileId, progress) => {
        setUploadProgressMap(map => {
          return { ...map, [fileId]: { progress, task: undefined } };
        });
      });
      setUploadingFiles(false);
      setLoading(true);

      const request: VideoProjectRequest = {
        playerId: playerId!!,
        coachId: coachId,
        socialNetworkMessages,
        messageToPost: withMessage,
        hashtags,
        originalMediaGroup,
        status,
        isAutoPost,
        preferredPostDate,
        allowShortLink,
        allowComplianceDisclaimer,
        initialMessages,
        allowPlayerHashtags: allowHashtags,
        scriptId,
        preferences,
        source: VideoProjectSource.PLAYER_WEB,
      };

      VideoProjectServices.create(request)
        .then(newProject => {
          setLoading(false);
          history.replace(URLS.videoProject.replace(":id", `${newProject.videoProjectId}`));
        })
        .catch(error => {
          setLoading(false);
          setFormErrorMessages([error.substring(0, 100)]);
        });
    }
  };

  const cancelAction = () => {
    dispatch({ type: "open_cancel", status: "ACTIVE" });
  };

  const confirmationModal = (): JSX.Element => {
    const modalTitle = formatMessage({
      ...descriptors[VideoProjectPageType.cancelAlertTitle],
    });
    return (
      <ConfirmationModal
        title={!state.duplicated ? modalTitle : undefined}
        message={""}
        openConfirmationModal={state.open}
        onClose={() => dispatch({ type: "close" })}
        okHandler={() => {
          dispatch({ type: "close" });
          history.push(URLS.player.libraryVC);
        }}
        rejectHandler={() => dispatch({ type: "close" })}
      />
    );
  };

  const customLinkSection = (): JSX.Element => (
    <Grid.Column width={7} floated="right" className="skip-link noMarginRight">
      <span className={"rightPadding"} data-elm-id={"videoProjectPageCancelBtn"} onClick={cancelAction}>
        {formatMessage({ ...descriptors[VideoProjectPageType.cancelBtn] })}
      </span>
    </Grid.Column>
  );

  const content = (): JSX.Element => {
    return (
      <Grid columns={1} className={"newProjectContentSection contentSection"}>
        {confirmationModal()}
        <TitleSection
          title={formatMessage({ ...descriptors[VideoProjectPageType.title] })}
          ready={true}
          customLinkSection={customLinkSection()}
          customNextLabel={formatMessage({ ...descriptors[VideoProjectPageType.saveBtn] })}
          handleNextAction={() => createVideoProject()}
          disableButtons={uploadingFiles || !isValid}
          titleSize={8}
          buttonsSize={8}
          nextButtonSize={7}
          showLinkOption={true}
          showNextOption={true}
        />
        <Grid.Row columns={1} className={"formSection leftPadding"}>
          <Grid className={"infoSection"}>
            <Grid.Column width={16} className={"formContainer"}>
              {videoProject && (
                <VideoProjectForm
                  disabled={false}
                  modeEditable={false}
                  videoProject={videoProject}
                  handleValidation={setIsValid}
                  updatingHandler={setVideoProject}
                  pageErrors={formErrorMessages}
                  coachId={coachId!!}
                  playerId={`${playerId}`}
                />
              )}
            </Grid.Column>
          </Grid>
        </Grid.Row>
        <UploadProgressModal open={uploadingFiles} progressMap={uploadProgressMap} />
      </Grid>
    );
  };

  return (
    <WhiteContainer>
      <SideBar history={history} />
      {loading ? <Loader active size="large" /> : content()}
    </WhiteContainer>
  );
};

export const PlayerVideoProjectPage = withRouter(VideoProjectFC);
