import * as React from "react";
import { Grid, Message, Loader, Dimmer } from "semantic-ui-react";
import { useIntl } from "react-intl";
import { get } from "lodash";
import { getDownloadURL } from "firebase/storage";

import { AppContext } from "../../providers";
import { StickerCard, UploadMultipleElements } from "../../components";
import { StickerServices } from "../../services";
import { mediaElementSticket } from "../../utils";
import * as DTO from "../../interfaces";

import { SettingsType, descriptors } from "./descriptors";
import { InternalGrid } from "../../styling/baseStyle";
import { StickersContainer, StickerListGrid, TabContentGrid } from "./styled";

interface OwnProps {
  withWritePermission: boolean;
  onChange: (stickerRequest: DTO.StickerRequest) => void;
  onLoading: (loading: boolean) => void;
}

type Props = OwnProps;

const StickersFC: React.FC<Props> = ({
  withWritePermission = false,
  onChange,
  onLoading,
}) => {
  const { formatMessage } = useIntl();
  const { userContext } = React.useContext(AppContext);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [stickers, setStickers] = React.useState<DTO.MediaGroup | undefined>(
    undefined
  );
  const [stickersDic, setStickersDic] = React.useState<
    { [key: string]: DTO.StickerPreview } | undefined
  >(undefined);
  const [generalErrors, setGeneralErrors] = React.useState<string[]>([]);

  const coachId = get(userContext, "coach.id", "");

  React.useEffect(() => {
    setLoading(true);
    const getStickers = async () => {
      const result = await StickerServices.get(coachId);

      setStickers(result.stickers);
      if (result.stickers) {
        setStickersDic(() => {
          let dicStickers = {};
          result.stickers!!.media!!.forEach((media, id) => {
            dicStickers = {
              ...dicStickers,
              [id]: {
                uid: id,
                loading: false,
                media,
              },
            };
          });

          return {
            ...dicStickers,
          };
        });
      }

      setLoading(false);
    };

    getStickers();
  }, [coachId]);

  React.useEffect(() => {
    if (stickersDic) {
      const imageLoading = Object.values(stickersDic).reduce(
        (accumulator, stickerValue) => stickerValue.loading || accumulator,
        false
      );

      if (!imageLoading) {
        prepareRequest();
      }

      setLoading(imageLoading);
    }
  }, [stickersDic]);

  React.useEffect(() => {
    onLoading(loading);
  }, [loading]);

  const prepareRequest = () => {
    if (stickersDic) {
      if (Object.values(stickersDic!).length > 100) {
        setGeneralErrors(() => [
          formatMessage({ ...descriptors[SettingsType.maximunStickersError] }),
        ]);
        return;
      }

      const request: DTO.StickerRequest = {
        coachId,
        stickers: {
          ...stickers,
          media: Object.values(stickersDic).map(
            (dicElm: DTO.StickerPreview, index) => {
              return {
                ...dicElm!!.media,
                sortOrder: index,
              } as DTO.Media;
            }
          ),
          type: "MIX",
        },
      };
      onChange(request);
    }
  };

  const stickerToFile = (file: DTO.SocialFiles): DTO.StickerPreview => {
    file.uploadTask
      ?.then(() => {
        getDownloadURL(file.uploadTask!.snapshot.ref)
          .then((url) => {
            setStickersDic((dicStc) => {
              if (dicStc && dicStc[file.uid]) {
                return {
                  ...dicStc,
                  [file.uid]: {
                    ...dicStc[file.uid],
                    loadStatus: "SUBMITTED",
                    loading: false,
                    media: mediaElementSticket(
                      url,
                      "png",
                      "IMAGE",
                      Object(dicStc.keys).length
                    ),
                  },
                };
              }
              return {
                ...dicStc,
              };
            });
          })
          .catch((e) => {
            setStickersDic((dicStc) => {
              if (dicStc && dicStc[file.uid]) {
                return {
                  ...dicStc,
                  [file.uid]: {
                    ...dicStc[file.uid],
                    loading: false,
                    loadStatus: "FAILED",
                  },
                };
              }
              return {
                ...dicStc,
              };
            });
          });
      })
      .catch((e) => {
        setStickersDic((dicStc) => {
          if (dicStc && dicStc[file.uid]) {
            return {
              ...dicStc,
              [file.uid]: {
                ...dicStc[file.uid],
                loadStatus: "FAILED",
              },
            };
          }
          return {
            ...dicStc,
          };
        });
      });

    const newPrompt: DTO.StickerPreview = {
      loading: true,
      uid: file.uid,
      uploadTask: file.uploadTask,
      thumbnail: file.thumbnail,
    };

    return newPrompt;
  };

  const deleteElement = (key: string): void => {
    setStickersDic((surls) => {
      if (surls) {
        delete surls[key];
      }

      return {
        ...surls,
      };
    });
  };

  return (
    <TabContentGrid
      className={
        !withWritePermission
          ? "listManagerContent disabledForm"
          : "listManagerContent tabContent"
      }
      columns={1}
    >
      <InternalGrid columns={1} className={"noPadding"}>
        {generalErrors.length > 0 && (
          <Grid.Row columns={1}>
            <Message floating error list={generalErrors} />
          </Grid.Row>
        )}
        <StickersContainer columns={1}>
          <Grid.Column width={16}>
            <UploadMultipleElements
              accept={"image/png, image/jpeg"}
              fileName={`customStickers/${coachId}`}
              nodeName={"sticker"}
              title={"Or Browse"}
              customMessage={"Drag and drop your stickers here to upload"}
              onChangeImage={(file) => {
                setStickersDic((cstickers) => {
                  return {
                    ...cstickers,
                    [file.uid]: stickerToFile(file),
                  };
                });
              }}
            />
          </Grid.Column>
        </StickersContainer>

        <StickerListGrid columns={4}>
          <Grid.Row>
            {stickersDic &&
              Object.keys(stickersDic)?.map((key: string) => {
                const dicElemp = stickersDic[key];
                const uri = dicElemp.media
                  ? dicElemp.media.uri
                  : dicElemp.thumbnail;
                return (
                  <StickerCard
                    key={key}
                    uri={uri!!}
                    onDelete={() => deleteElement(key)}
                  />
                );
              })}
          </Grid.Row>
        </StickerListGrid>
      </InternalGrid>
      {loading && (
        <Dimmer active inverted>
          <Loader inverted content="Loading" />
        </Dimmer>
      )}
    </TabContentGrid>
  );
};

export const StickersTab = StickersFC;
