import * as React from "react";
import{ useIntl } from "react-intl";
import { Button, Grid } from "semantic-ui-react";

import { BillingPeriod } from "../../../../../interfaces";
import { capitalizeFormatter } from "../../../../../utils";

import { descriptors, CoachSummaryType } from "../../descriptors";
import "../../styles.scss";

interface OwnProps {
  price: number;
  interval: BillingPeriod;
  selectedBillingPeriod: BillingPeriod;
  onSelectedPrice: (selected: BillingPeriod) => void;
}

type Props = OwnProps 

const AvailablePriceFC: React.FC<Props> = ({
  interval,
  price,
  selectedBillingPeriod,
  onSelectedPrice
}) => {
  const { formatMessage } = useIntl();
  return (
    <Grid.Column centered width={8}>
      <Button
        onClick={() => onSelectedPrice(interval)}
        className={selectedBillingPeriod === interval ? "boxButton selected" : "boxButton"}>
        <Grid centered className={"availablePriceAmountBox"} width={16}>
          <Grid.Column width={16}>
            <p className={"availablePriceFrecuencyLabel"}>
              {formatMessage(
                {
                  ...descriptors[CoachSummaryType.planPayFrecuency],
                },
                { interval: capitalizeFormatter(interval) }
              )}
            </p>
          </Grid.Column>
          <Grid.Column width={16}>
            <p className={"availablePriceLabel"}>{"$" + price}</p>
          </Grid.Column>
        </Grid>
      </Button>
    </Grid.Column>
  );
};

export const AvailablePrice = AvailablePriceFC
