import * as React from "react";
import { Field } from "formik";
import MaskedInput from "react-text-mask";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Grid, Image, Loader, Modal, Button, Header } from "semantic-ui-react";
import moment from "moment";
import DatePicker from "react-datepicker";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";

import { Svg } from "../../../../assets";
import { BasicFormProps } from "../../../../interfaces";
import { phoneNumberMask, dateMask, validAge, getImageAsBase64 } from "../../../../utils";

import { UserChangePasswordForm } from "../../../../containers/UserChangePasswordForm";

import { descriptors, ProfileInformationType } from "./descriptors";
import { fields } from "./formFields";
import "../../../../styling/datepicker.scss";
import "../../../../styling/antd.scss";
import "./styles.scss";

interface OwnProps extends BasicFormProps {
  messages?: { info?: string[]; error?: string[]; warn?: string[] };
  isSubmitting?: boolean;
  onChangeImage: (img: File) => void;
  handleSetFieldValue?: (field: string, value: any) => void;
}

type Props = OwnProps;

const ProfileInformationClass: React.FC<Props> = ({
  values,
  isSubmitting,
  handleChange,
  handleSetFieldValue,
  onChangeImage,
}) => {
  const [playerPictureFile, setPlayerPictureFile] = React.useState<string | undefined>();
  const startDate = values.birthday ? moment(values.birthday, "YYYY-MM-DD").toDate() : undefined;
  const [userWithPicture] = React.useState<boolean>(!!values.photoUrl);

  const { formatMessage } = useIntl();
  return (
    <Grid.Column floated="left" width={8} className={"profileColumn smallTop"}>
      <Header as="h4" textAlign={"center"}>
        <FormattedMessage {...descriptors[ProfileInformationType.titleSection]} />
      </Header>
      <Grid.Row>
        <div className={"profileInformation"}>
          {isSubmitting && <Loader active size="large" />}
          <div className={"uploadPictureContainer noBackground"}>
            <ImgCrop
              showGrid
              onModalOk={value => {
                getImageAsBase64(value, (base64Image: any) => {
                  setPlayerPictureFile(base64Image);
                  onChangeImage(value as File);
                });
              }}
            >
              <Upload
                action={(): string => {
                  return Svg.UserBorder;
                }}
                showUploadList={false}
                listType="picture"
                className="avatar-uploader"
              >
                <>
                  <div className={!userWithPicture ? "uploadPictureContainer" : "uploadPictureContainer noBackground"}>
                    {playerPictureFile ? (
                      <Image src={playerPictureFile} className="profilePicture" />
                    ) : (
                      <>
                        {" "}
                        <Image
                          src={values.photoUrl || Svg.UserBorder}
                          className={!userWithPicture ? "defaultPicture" : "profilePicture"}
                        />
                        {!userWithPicture && (
                          <div className="profileCameraIconContainer">
                            <Image src={Svg.Camera} className="cameraIcon" />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="titleDIVSection">
                    <span className={"columnTitle"}>
                      <FormattedMessage
                        {...descriptors[
                          ProfileInformationType[
                            playerPictureFile || userWithPicture ? "changePicture" : "uploadPicture"
                          ]
                        ]}
                      />
                    </span>
                  </div>
                </>
              </Upload>
            </ImgCrop>
          </div>

          {fields.map((field, index) => {
            return (
              <Form.Group widths="equal" key={"group" + index}>
                <Form.Input
                  key={field.id + index}
                  fluid
                  data-elm-id={"profileInformationInput" + field.id}
                  id={field.id}
                  type={field.type}
                  name={field.name}
                  placeholder={formatMessage({ ...descriptors[field.placeholder] })}
                  onChange={handleChange}
                  value={values[field.id]}
                  disabled={isSubmitting}
                />
              </Form.Group>
            );
          })}

          <Form.Group widths="equal" key={"group4"}>
            <DatePicker
              data-elm-id={"profileInformationBirthday"}
              id={"birthday"}
              name={"birthday"}
              selected={startDate}
              placeholderText={formatMessage({ ...descriptors[ProfileInformationType.birthday] })}
              onChange={(date: any) => handleSetFieldValue!("birthday", date ? date : "")}
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={15}
              maxDate={new Date()}
              minDate={validAge(new Date(), -110)}
              disabled={isSubmitting}
              customInput={<MaskedInput mask={dateMask} />}
            />
          </Form.Group>
          <Form.Group widths="equal" key={"group5"}>
            <div className="field">
              <Field
                name="phoneNumber"
                data-elm-id={"profileInformationPhoneNumber"}
                render={({ phoneField }: { phoneField: any }) => (
                  <MaskedInput
                    {...phoneField}
                    value={values.phoneNumber}
                    mask={phoneNumberMask}
                    id="phoneNumber"
                    placeholder={formatMessage({ ...descriptors[ProfileInformationType.phoneNumber] })}
                    type="text"
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                )}
              />
            </div>
          </Form.Group>
        </div>
      </Grid.Row>
    </Grid.Column>
  );
};

export const PlayerProfileInformation = ProfileInformationClass;
