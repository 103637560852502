import * as React from "react";
import{ useIntl } from "react-intl";
// import { Svg } from "../../../../../../../assets";
import * as DTO from "../../../../../../../interfaces";

import { CategoryRow } from "../CategoryRow";

interface OwnProps {
  promptList: DTO.Prompt[];
  categories: DTO.Category[];
  campaignPrompts: DTO.SelectedPrompts;
  coachId: string;

  onAddPromptHandler: (prompt: DTO.Prompt) => void;
  onRemovePromptHandler: (prompt: DTO.Prompt) => void;
}

type Props = OwnProps 

export default 
  ({ promptList, categories, campaignPrompts, onAddPromptHandler, onRemovePromptHandler }: Props) => {
    const [categoriesHash, setCategoriesHash] = React.useState<{ [key: string]: string }>({});
    const [withCategorizedLibraryPrompts, setWithCategorizedLibraryPrompts] = React.useState<boolean>(false);

    React.useEffect(() => {
      const convertToHash = (result: DTO.Category[]): { [key: string]: string } => {
        let hash = {};
        result.map(category => {
          hash = {
            ...hash,
            [category.categoryId!]: category.name,
          };
        });

        return hash;
      };

      setCategoriesHash(convertToHash(categories));
    }, [categories]);

    const uncategorizedPrompts = (): DTO.Prompt[] => {
      return promptList.filter(prompt => {
        return prompt.categories?.length === 0 && prompt.status !== "DRAFT";
      });
    };

    const uncategorized = uncategorizedPrompts();

    const promptFilteredByCategory = (categoryId: string): DTO.Prompt[] => {
      const promptsFiltered = promptList.filter(prompt => {
        return prompt.categories?.map(c => c.categoryId!).includes(categoryId) && prompt.status !== "DRAFT";
      });

      if (!withCategorizedLibraryPrompts && promptsFiltered.length > 0) {
        setWithCategorizedLibraryPrompts(true);
      }
      return promptsFiltered;
    };

    const showPromps = (category: DTO.Category): JSX.Element | undefined => {
      const prompts = promptFilteredByCategory(category.categoryId!);
      if (prompts.length === 0) {
        return undefined;
      }
      return (
        <CategoryRow
          key={category.categoryId}
          category={category}
          promptList={prompts}
          totalElements={prompts.length}
          name={categoriesHash[category.categoryId!]}
          campaignPrompts={campaignPrompts}
          onAddPrompt={onAddPromptHandler}
          onRemovePrompt={onRemovePromptHandler}
        />
      );
    };

    const showEmptySection = !withCategorizedLibraryPrompts && uncategorized.length === 0;
    return (
      <div className="promptListContainer">
        {uncategorized.length > 0 && (
          <CategoryRow
            key={"uncategorized"}
            name={"Uncategorized"}
            promptList={uncategorized}
            totalElements={uncategorized.length}
            campaignPrompts={campaignPrompts}
            onAddPrompt={onAddPromptHandler}
            onRemovePrompt={onRemovePromptHandler}
          />
        )}

        {categories.map(category => showPromps(category))}

        {/* {showEmptySection && <EmptyView text={"You have no Library Prompts"} customIconImage={Svg.Library} />} */}
      </div>
    );
  }
