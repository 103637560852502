import { defineMessages } from "react-intl";

export enum PlayerVerifiedSuccessType {
  title = "PlayerVerifiedSuccessType.title",
  accountCreated = "PlayerVerifiedSuccessType.accountCreated",
}

export const en = {
  [PlayerVerifiedSuccessType.title]: "Awesome!",
  [PlayerVerifiedSuccessType.accountCreated]: "Download the app to get started:",
};

export const descriptors = defineMessages({
  [PlayerVerifiedSuccessType.title]: {
    id: PlayerVerifiedSuccessType.title,
    defaultMessage: en[PlayerVerifiedSuccessType.title],
    description: "-",
  },
  [PlayerVerifiedSuccessType.accountCreated]: {
    id: PlayerVerifiedSuccessType.accountCreated,
    defaultMessage: en[PlayerVerifiedSuccessType.accountCreated],
    description: "-",
  },
});
