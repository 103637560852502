import { client as axios } from "./axiosClient";
import { Constants, catchServiceException } from "@socialcoach/services";
import * as DTO from "../interfaces";

const DIGEST_SERVICE = `${Constants.API_URL}/coaches/digest-emails`;

export class DigestEmailServices {
  @catchServiceException()
  static async get(coachId: string): Promise<DTO.DigestEmailElementResponse[]> {
    const { data } = await axios.get<DTO.DigestEmailElementResponse[]>(`${DIGEST_SERVICE}/${coachId}`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async update(coachId: string, emails: DTO.DigestEmailElement[]): Promise<DTO.DigestEmailElementResponse[]> {
    const { data } = await axios.put<DTO.DigestEmailElementResponse[]>(`${DIGEST_SERVICE}/${coachId}`, emails, {
      withCredentials: true,
    });
    return data;
  }
}
