import * as React from "react";

import { EditoVideoQueueSectionByStatus } from "../..";
import * as DTO from "../../../interfaces";
import { VideoProjectServices } from "../../../services";
import { Loader } from "semantic-ui-react";

interface OwnProps {
  statuses: DTO.VideoProjectStatus[];
  includeDemoProjects: boolean;
  showError?: (ErrorMessage: string) => void;
}

type Props = OwnProps;

const EditorQueueListComponentFC: React.FC<Props> = ({ includeDemoProjects = true, statuses }) => {
  const [videoProjects, setVideoProjects] = React.useState<DTO.Page<DTO.PlayerVideoProjectResponse> | undefined>(
    undefined
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    loadAll();
  }, [includeDemoProjects]);

  async function loadAll() {
    setLoading(true);

    const filters: DTO.SearchVideoProjectFilter = {
      status: statuses,
      includeDemoProjects,
    };
    const response = await VideoProjectServices.getAsEditor("createdDate,DESC", filters, 0, 2000).finally(() => {
      setLoading(false);
    });

    setVideoProjects(cpages => {
      return {
        ...cpages!,
        ...response,
      };
    });
  }

  const videoProjectsFiltered = videoProjects ? videoProjects.content : [];
  const waitingForEditing = statuses.includes(DTO.VideoProjectStatus.SUBMITTED_FOR_EDITING);

  return loading ? (
    <Loader active size="large" />
  ) : videoProjects && videoProjects.content.length > 0 ? (
    <EditoVideoQueueSectionByStatus
      key={"editor_editing_queue_" + statuses[0]}
      sectionName={waitingForEditing ? "Needs Editing" : "Editing Complete (Last 30 days)"}
      edited={waitingForEditing === false}
      videoProjects={videoProjectsFiltered}
      totalElements={videoProjects?.totalElements}
    />
  ) : null;
};

export const EditorVideoQueueListComponent = EditorQueueListComponentFC;
