import * as React from "react";
import { ConfigContext } from "../../../contexts/appContexts";
import SocialNetworkButton from "../../SocialNetworkButton";
import { SocialNetworksName, ConfigProperties } from "../../../interfaces";
import { SocialNetworkCustomProps } from "../SocialProps";
import CoachSocialNetworkButton from "../../Coaches/CoachSocialNetworkButton";
import { SCTwitterLogin } from "../../SCTwitterLogin";
import useScript from "../../../utils/useScript";

const SN_NAME = "twitter";

const TwitterFC: React.FC<SocialNetworkCustomProps> = ({
  title,
  username,
  handleLogin,
  handleDelete,
  inProfile,
  asACoach = false,
  loading,
  viewName = "Register",
  connected,
}) => {
  useScript("https://platform.twitter.com/widgets.js");
  const config: ConfigProperties = React.useContext(ConfigContext);

  const handlerAction = (sn: SocialNetworksName) => {
    if (username !== "" && handleDelete) {
      handleDelete(sn);
    }
  };

  const ButtonComponent = asACoach ? CoachSocialNetworkButton : SocialNetworkButton;

  const TwitterSocialButton = (
    <ButtonComponent
      title={title || ""}
      data-elm-id={`social${viewName}Button${SN_NAME.toUpperCase}${connected}`}
      account={username || ""}
      inProfile={inProfile}
      socialNetwork={SN_NAME}
      handler={handlerAction}
      disconnectHandler={handlerAction}
      loading={loading}
      connected={connected}
    />
  );

  const isForDelete = username !== "";
  return (
    <div>
      {isForDelete ? (
        TwitterSocialButton
      ) : (
        <SCTwitterLogin
          children={TwitterSocialButton}
          credentials="same-origin"
          screenName=""
          loginUrl={config.socialNetworks.twitter.callback!!}
          onFailure={(error: Error) => {
            if (error) {
              handleLogin(error.message, null);
            }
          }}
          onSuccess={(response: any) => {
            response.json().then(({ profile }: any) => {
              handleLogin(null, profile);
            });
          }}
          requestTokenUrl={config.socialNetworks.twitter.tokenUrl!!}
          // showIcon={false}
          forceLogin={username === ""}
          tag="div"
        />
      )}
    </div>
  );
};

export const Twitter = TwitterFC;
// config.socialNetworks.twitter.tokenUrl!!
