import { client as axios } from "./axiosClient";
import { catchServiceException, Constants } from "@socialcoach/services";
import * as DTO from "../interfaces/dtos";
import { VideoScriptOrderType } from "../interfaces/dtos";

const VIDEO_SCRIPTS_SERVICE = `${Constants.API_URL}/video-scripts`;

export class VideoScriptsServices {
  @catchServiceException()
  static async create(videoScriptRequest: DTO.VideoScriptRequest): Promise<DTO.VideoScript> {
    const { data } = await axios.post<DTO.VideoScript>(
      `${VIDEO_SCRIPTS_SERVICE}`,
      {
        ...videoScriptRequest,
      },
      {
        withCredentials: true,
      }
    );

    return data;
  }

  @catchServiceException()
  static async notify(request: DTO.ScriptNotificationRequest): Promise<DTO.ScriptNotificationResponse> {
    const { data } = await axios.post<DTO.ScriptNotificationResponse>(
      `${VIDEO_SCRIPTS_SERVICE}/notify`,
      {
        ...request,
      },
      {
        withCredentials: true,
      }
    );

    return data;
  }

  @catchServiceException()
  static async search(filters?: DTO.SearchFilter | null): Promise<DTO.VideoScriptsSearchResponse> {
    const { data } = await axios.post<DTO.VideoScriptsSearchResponse>(
      `${VIDEO_SCRIPTS_SERVICE}/search`,
      {
        searchTerm: filters?.title,
      },
      {
        withCredentials: true,
      }
    );

    return data;
  }

  @catchServiceException()
  static async update(id: number, videoScriptRequest: DTO.VideoScriptRequest): Promise<DTO.VideoScript> {
    const response = await axios.put<DTO.VideoScript>(
      `${VIDEO_SCRIPTS_SERVICE}/${id}`,
      {
        ...videoScriptRequest,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async delete(id: number): Promise<void> {
    const { data } = await axios.delete<void>(`${VIDEO_SCRIPTS_SERVICE}/${id}`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async get(id: number): Promise<DTO.VideoScript> {
    const { data } = await axios.get<DTO.VideoScript>(`${VIDEO_SCRIPTS_SERVICE}/${id}`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async getDetails(id: number): Promise<DTO.VideoScriptDetails> {
    const { data } = await axios.get<DTO.VideoScriptDetails>(`${VIDEO_SCRIPTS_SERVICE}/details/${id}`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async getAll(
    orderType: DTO.VideoScriptOrderType = VideoScriptOrderType.ALPHABETICAL
  ): Promise<DTO.VideoScripts> {
    const response = await axios.get<DTO.VideoScripts>(`${VIDEO_SCRIPTS_SERVICE}?orderType=${orderType}`, {
      withCredentials: true,
    });
    return response.data;
  }
}
