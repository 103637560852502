import { SignUpType } from "./descriptors";
import * as MODEL from "../../../interfaces/models";

export const fields: MODEL.FormField[][] = [
  [
    {
      id: "firstName",
      name: "firstName",
      type: "text",
      label: "firstName",
      placeholder: SignUpType.firstName,
      disabled: false,
    },
    {
      id: "lastName",
      name: "lastName",
      type: "text",
      label: "lastName",
      placeholder: SignUpType.lastName,
      disabled: false,
    },
  ],
  [
    {
      id: "email",
      name: "email",
      type: "email",
      label: "Email",
      placeholder: SignUpType.email,
      disabled: false,
    },
  ],
];

export const passwordFields: MODEL.FormField[][] = [
  [
    {
      id: "password",
      name: "password",
      type: "password",
      label: "password",
      placeholder: SignUpType.password,
      disabled: false,
    },
    {
      id: "confirmPassword",
      name: "confirmPassword",
      type: "password",
      label: "confirmPassword",
      placeholder: SignUpType.confirmPassword,
      disabled: false,
    },
  ],
];
