import styled from "styled-components";
import { Grid } from "semantic-ui-react";
import { theme } from "../../../../styling/theme";

export const PostHeader = styled(Grid.Row)({
  color: `${theme.lineColor}`,
  fontSize: "14px",
  letterSpacing: "-0.21px",
  lineHeight: "16px",
  textAlign: "inherit",
  display: "contents",
});

export const SmallTitle = styled(Grid.Row)({
  color: `${theme.titleColor}`,
  fontFamily: `"Open Sans", sans-serif`,
  fontSize: "1em",
  fontWeight: "bold",
  letterSpacing: "-0.23px",
  lineHeight: "20.43px",
  textAlign: "left",
});

export const ReviewDate = styled.p`
  padding-top: 5px;
  margin-bottom: 5px;
  text-align: left;
  font-size: 12px;
`;

export const ReviewBy = styled.p`
  padding-top: 0px;
  text-align: left;
  font-size: 12px;
  margin-bottom: 3px;
`;
