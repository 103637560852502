import { defineMessages } from "react-intl";

export enum ScheduledPostType {
  scheduledOptionsLabel = "ScheduledPostType.scheduledOptionsLabel",
  deliveryTimeDescription = "ScheduledPostType.deliveryTimeDescription",

  postNow = "ScheduledPostType.postNow",
  postScheduled = "ScheduledPostType.postScheduled",
  datePlaceholder = "ScheduledPostType.datePlaceholder",
  autoPost = "ScheduledPostType.autoPost",
  noAutoPost = "ScheduledPostType.noAutoPost",
  multiimageError = "ScheduledPostType.multiimageError",
  noMediaError = "ScheduledPostType.noMediaError",
  lengthMessageError = "ScheduledPostType.lengthMessageError",
  pstZoneMessage = "ScheduledPostType.pstZoneMessage",
  customDurationLabel = "ScheduledPostType.customDurationLabel",
  deliveryModeDescription = "ScheduledPostType.deliveryModeDescription",
  deliveryOptionLabel = "ScheduledPostType.deliveryOptionLabel",
  timePlaceholder = "ScheduledPostType.timePlaceholder",
}

export const en = {
  [ScheduledPostType.deliveryOptionLabel]: "How would you like this published?",
  [ScheduledPostType.scheduledOptionsLabel]: "When would you like to schedule this?",

  [ScheduledPostType.customDurationLabel]: "Players",

  [ScheduledPostType.deliveryModeDescription]: "Would you like to post this now or schedule it for later?",

  [ScheduledPostType.datePlaceholder]: "Select a date",
  [ScheduledPostType.timePlaceholder]: "Select a time",

  [ScheduledPostType.postNow]: "Now",
  [ScheduledPostType.postScheduled]: "Later",

  [ScheduledPostType.multiimageError]: "This prompt contains multiple images and cannot be auto posted",
  [ScheduledPostType.noMediaError]:
    "Because this Prompt does not include a media file, it cannot be posted to {socialNetwork}",

  [ScheduledPostType.autoPost]: "Post automatically",
  [ScheduledPostType.noAutoPost]: "Push to {customMessage} mobile device",

  [ScheduledPostType.deliveryTimeDescription]: "Delivery Time (8:00 AM PST)",

  [ScheduledPostType.pstZoneMessage]: "All post will be posted using Pacific Time Zone",
  [ScheduledPostType.lengthMessageError]: "Because the caption is too long, it cannot be posted to {socialNetwork}.\n",
};

export const descriptors = defineMessages({
  [ScheduledPostType.pstZoneMessage]: {
    id: ScheduledPostType.pstZoneMessage,
    defaultMessage: en[ScheduledPostType.pstZoneMessage],
    description: "-",
  },
  [ScheduledPostType.deliveryTimeDescription]: {
    id: ScheduledPostType.deliveryTimeDescription,
    defaultMessage: en[ScheduledPostType.deliveryTimeDescription],
    description: "-",
  },
  [ScheduledPostType.scheduledOptionsLabel]: {
    id: ScheduledPostType.scheduledOptionsLabel,
    defaultMessage: en[ScheduledPostType.scheduledOptionsLabel],
    description: "-",
  },
  [ScheduledPostType.autoPost]: {
    id: ScheduledPostType.autoPost,
    defaultMessage: en[ScheduledPostType.autoPost],
    description: "-",
  },
  [ScheduledPostType.postNow]: {
    id: ScheduledPostType.postNow,
    defaultMessage: en[ScheduledPostType.postNow],
    description: "-",
  },
  [ScheduledPostType.datePlaceholder]: {
    id: ScheduledPostType.datePlaceholder,
    defaultMessage: en[ScheduledPostType.datePlaceholder],
    description: "-",
  },
  [ScheduledPostType.postScheduled]: {
    id: ScheduledPostType.postScheduled,
    defaultMessage: en[ScheduledPostType.postScheduled],
    description: "-",
  },
  [ScheduledPostType.noAutoPost]: {
    id: ScheduledPostType.noAutoPost,
    defaultMessage: en[ScheduledPostType.noAutoPost],
    description: "-",
  },
  [ScheduledPostType.customDurationLabel]: {
    id: ScheduledPostType.customDurationLabel,
    defaultMessage: en[ScheduledPostType.customDurationLabel],
    description: "-",
  },
  [ScheduledPostType.deliveryModeDescription]: {
    id: ScheduledPostType.deliveryModeDescription,
    defaultMessage: en[ScheduledPostType.deliveryModeDescription],
    description: "-",
  },
  [ScheduledPostType.deliveryOptionLabel]: {
    id: ScheduledPostType.deliveryOptionLabel,
    defaultMessage: en[ScheduledPostType.deliveryOptionLabel],
    description: "-",
  },
  [ScheduledPostType.timePlaceholder]: {
    id: ScheduledPostType.timePlaceholder,
    defaultMessage: en[ScheduledPostType.timePlaceholder],
    description: "-",
  },
  [ScheduledPostType.lengthMessageError]: {
    id: ScheduledPostType.lengthMessageError,
    defaultMessage: en[ScheduledPostType.lengthMessageError],
    description: "-",
  },
  [ScheduledPostType.multiimageError]: {
    id: ScheduledPostType.multiimageError,
    defaultMessage: en[ScheduledPostType.multiimageError],
    description: "-",
  },
  [ScheduledPostType.noMediaError]: {
    id: ScheduledPostType.noMediaError,
    defaultMessage: en[ScheduledPostType.noMediaError],
    description: "-",
  },
});
