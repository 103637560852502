import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Formik } from "formik";
import { useIntl } from "react-intl";
import qs from "qs";

import { CoachingProfileService, SocialCoachSessionService } from "../../../services";
import { ChangePassword } from "../../../components";
import { validationSchema } from "./validations";
import { URLS } from "../../../utils/routes";
import { PlayerVerifiedSection } from "../../VerificationPage/PlayerVerifiedSection";

interface OwnProps {
  password?: string;
  confirmPassword?: string;
  isPlayer: boolean; // TODO: REMOVE
}

interface PathProps {
  userId: string;
  actionToken: string;
}

type Props = OwnProps & RouteComponentProps<PathProps>;

const IntegratedChangePassword: React.FC<Props> = ({
  history,
  isPlayer,
  location,
  password = "",
  confirmPassword = "",
  match: {
    params: { userId, actionToken },
  },
}) => {
  const [company, setCompany] = React.useState<string>("");

  const [success, setSuccess] = React.useState<boolean>(false);
  const [likePlayer, setLikePlayer] = React.useState<boolean>(isPlayer);

  React.useEffect(() => {
    const coachId = Number(qs.parse(location.search, { ignoreQueryPrefix: true }).coachId);
    if (coachId) {
      getPublicInfo(coachId.toString());
      setLikePlayer(true);
    } else {
      setLikePlayer(false);
    }
  }, []);

  const getPublicInfo = async (coachId: string) => {
    await CoachingProfileService.coachPublicInfoByCoachId(coachId)
      .then(response => {
        setCompany(response.companyName || "");
      })
      .catch(e => {
        setCompany("");
      });
  };

  const changeForm = (): JSX.Element => {
    return (
      <Formik
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={{ password, confirmPassword }}
        validationSchema={validationSchema}
        onSubmit={async (
          { password: pwd, confirmPassword: confirmpwd },
          { setSubmitting, setErrors, validateForm }
        ) => {
          try {
            setSubmitting(true);
            const errors = await validateForm({ password: pwd, confirmPassword: confirmpwd });
            if (errors && Object.values(errors).length > 0 && actionToken && userId) {
              setErrors(errors);
            } else {
              setSubmitting(true);
              SocialCoachSessionService.changePassword(userId, actionToken, pwd!)
                .then(() => {
                  if (!likePlayer) {
                    setTimeout(() => {
                      history.push(URLS.login);
                    }, 3000);
                  } else {
                    setSuccess(true);
                    setTimeout(() => {
                      history.push(URLS.login);
                    }, 3000);
                  }
                })
                .catch(e => {
                  setSuccess(false);
                  setErrors({
                    password: e,
                    confirmPassword: undefined,
                  });
                });
            }
          } catch (e) {
            setErrors({
              password: String(e),
              confirmPassword: undefined,
            });
          }
          setSubmitting(false);
        }}
      >
        {({ errors, handleChange, handleSubmit, isSubmitting }) => (
          <ChangePassword
            messages={{
              error: Object.values(errors) as string[],
            }}
            isPlayer={likePlayer}
            companyName={company}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
        )}
      </Formik>
    );
  };
  return changeForm();
};

export const ChangePasswordForm = withRouter(IntegratedChangePassword);
