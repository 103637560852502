import * as React from "react";
import "./styles.scss";

interface OwnProps {
  name: string;
  segments: any[];
  callback: (value: string, index: number) => void;

  defaultIndex: number;
  controlRef: any;
}

const SegmentedControlClass: React.FC<OwnProps> = ({ name, segments, callback, defaultIndex = 0, controlRef }) => {
  const [activeIndex, setActiveIndex] = React.useState(defaultIndex);
  const componentReady = React.useRef<boolean>(false);

  // Determine when the component is "ready"
  React.useEffect(() => {
    if (componentReady.current) {
      componentReady.current = true;
    }
  }, []);

  React.useEffect(() => {
    const activeSegmentRef = segments[activeIndex].ref;
    const { offsetWidth, offsetLeft } = activeSegmentRef.current;
    const { style } = controlRef.current;

    style.setProperty("--highlight-width", `${offsetWidth}px`);
    style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
  }, [activeIndex, callback, controlRef, segments]);

  const onInputChange = (value: any, index: number) => {
    setActiveIndex(index);
    callback(value, index);
  };

  return (
    <div className="controls-container" ref={controlRef}>
      <div className={`controls ${componentReady.current ? "ready" : "idle"}`}>
        {segments?.map((item, i) => (
          <div key={item.value} className={`segment ${i === activeIndex ? "active" : "inactive"}`} ref={item.ref}>
            <input
              type="radio"
              value={item.value}
              id={item.label}
              name={name}
              onChange={() => onInputChange(item.value, i)}
              checked={i === activeIndex}
            />
            <label htmlFor={item.label}>{item.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export const SegmentedControl = SegmentedControlClass;
