import { defineMessages } from "react-intl";

export enum RecoverPasswordType {
  password = "RecoverPasswordType.password",
  confirmPassword = "RecoverPasswordType.confirmPassword",
  submit = "RecoverPasswordType.submit",
  title = "RecoverPasswordType.title",
  logIn = "RecoverPasswordType.logIn",
  warningTitle = "RecoverPasswordType.warningTitle",
  errorTitle = "RecoverPasswordType.errorTitle",
  infoTitle = "RecoverPasswordType.infoTitle",
  passwordMin = "RecoverPasswordType.passwordMin",
  passwordWithNumber = "RecoverPasswordType.passwordNumber",
  passwordWithSpecialCharacter = "RecoverPasswordType.passwordSpecialCharacter",
  passwordWithCapital = "RecoverPasswordType.passwordCapital",

  playerDescription = "RecoverPasswordType.playerDescription",
  subtitle = "RecoverPasswordType.subtitle",
  playerSubmit = "RecoverPasswordType.playerSubmit",
}

export const en = {
  [RecoverPasswordType.password]: "New Password",
  [RecoverPasswordType.confirmPassword]: "Confirm New Password",
  [RecoverPasswordType.submit]: "Change",
  [RecoverPasswordType.logIn]: 'Back to Sign In',
  [RecoverPasswordType.title]: "Change Password",

  [RecoverPasswordType.warningTitle]: "Warning",
  [RecoverPasswordType.errorTitle]: "Oops! There was an error",
  [RecoverPasswordType.infoTitle]: "Hey",
  [RecoverPasswordType.passwordMin]: "Password must be at least 8 characters.",
  [RecoverPasswordType.passwordWithCapital]: "Password must be at least 1 capital letter.",
  [RecoverPasswordType.passwordWithNumber]: "Password must be at least 1 numeric value.",
  [RecoverPasswordType.passwordWithSpecialCharacter]: "Password must be at least 1 special character.",

  [RecoverPasswordType.subtitle]: "Your account has been created!",
  [RecoverPasswordType.playerDescription]: "Set your password below: ",
  [RecoverPasswordType.playerSubmit]: "Save",
};

export const descriptors = defineMessages({
  [RecoverPasswordType.password]: {
    id: RecoverPasswordType.password,
    defaultMessage: en[RecoverPasswordType.password],
    description: "Password string",
  },
  [RecoverPasswordType.confirmPassword]: {
    id: RecoverPasswordType.confirmPassword,
    defaultMessage: en[RecoverPasswordType.confirmPassword],
    description: "Password string Confirmation",
  },
  [RecoverPasswordType.submit]: {
    id: RecoverPasswordType.submit,
    defaultMessage: en[RecoverPasswordType.submit],
    description: "Button text",
  },
  [RecoverPasswordType.playerSubmit]: {
    id: RecoverPasswordType.playerSubmit,
    defaultMessage: en[RecoverPasswordType.playerSubmit],
    description: "Button text",
  },
  [RecoverPasswordType.logIn]: {
    id: RecoverPasswordType.logIn,
    defaultMessage: en[RecoverPasswordType.logIn],
    description: "Link to log-in",
  },
  [RecoverPasswordType.title]: {
    id: RecoverPasswordType.title,
    defaultMessage: en[RecoverPasswordType.title],
    description: "Change Password Title",
  },
  [RecoverPasswordType.subtitle]: {
    id: RecoverPasswordType.subtitle,
    defaultMessage: en[RecoverPasswordType.subtitle],
    description: "Change Password Title",
  },
  [RecoverPasswordType.warningTitle]: {
    id: RecoverPasswordType.warningTitle,
    defaultMessage: en[RecoverPasswordType.warningTitle],
    description: "Warning messages title",
  },
  [RecoverPasswordType.errorTitle]: {
    id: RecoverPasswordType.errorTitle,
    defaultMessage: en[RecoverPasswordType.errorTitle],
    description: "Error Messages title",
  },
  [RecoverPasswordType.infoTitle]: {
    id: RecoverPasswordType.infoTitle,
    defaultMessage: en[RecoverPasswordType.infoTitle],
    description: "Info messages title",
  },
  [RecoverPasswordType.passwordMin]: {
    id: RecoverPasswordType.passwordMin,
    defaultMessage: en[RecoverPasswordType.passwordMin],
    description: "passwordMin",
  },
  [RecoverPasswordType.passwordWithCapital]: {
    id: RecoverPasswordType.passwordWithCapital,
    defaultMessage: en[RecoverPasswordType.passwordWithCapital],
    description: "passwordWithCapital text",
  },
  [RecoverPasswordType.passwordWithNumber]: {
    id: RecoverPasswordType.passwordWithNumber,
    defaultMessage: en[RecoverPasswordType.passwordWithNumber],
    description: "passwordWithNumber",
  },
  [RecoverPasswordType.passwordWithSpecialCharacter]: {
    id: RecoverPasswordType.passwordWithSpecialCharacter,
    defaultMessage: en[RecoverPasswordType.passwordWithSpecialCharacter],
    description: "passwordWithSpecialCharacter",
  },
  [RecoverPasswordType.playerDescription]: {
    id: RecoverPasswordType.playerDescription,
    defaultMessage: en[RecoverPasswordType.playerDescription],
    description: "playerDescription",
  },
});
