import { defineMessages } from "react-intl";

export enum TitleSectionType {
  saveNextBtn = "TitleSectionType.saveNextBtn",
  saveBtn = "TitleSectionType.saveBtn",
  skipBtn = "TitleSectionType.skipBtn",
}

export const en = {
  [TitleSectionType.saveNextBtn]: "Save & Next",
  [TitleSectionType.saveBtn]: "Save",
  [TitleSectionType.skipBtn]: "Skip, I'll do this later",
};

export const descriptors = defineMessages({
  [TitleSectionType.saveNextBtn]: {
    id: TitleSectionType.saveNextBtn,
    defaultMessage: en[TitleSectionType.saveNextBtn],
    description: "-",
  },
  [TitleSectionType.saveBtn]: {
    id: TitleSectionType.saveBtn,
    defaultMessage: en[TitleSectionType.saveBtn],
    description: "-",
  },
  [TitleSectionType.skipBtn]: {
    id: TitleSectionType.skipBtn,
    defaultMessage: en[TitleSectionType.skipBtn],
    description: "-",
  },
});
