import * as React from "react";
import { Icon } from "semantic-ui-react";
import { Draggable } from "react-beautiful-dnd";
import { ConfigContext } from "../../contexts/appContexts";
import { AppContext } from "../../providers";
import { createVideoThumbnailBlob, mediaEditedFirebaseUrl, saveElementToFirebase } from "../../utils";
import { Media } from "../../interfaces";
import { EditImageModal } from "../EditImageModal";

import "./styles.scss";
import { ImagePreview, UploadProgressDisplay } from "./styled";
import { get } from "lodash";

interface DraggableMediaItemProps {
  src: Media;
  id: number;
  index: number;
  total: number;
  uploadProgress?: number;

  handleClose: (id: number) => void;
  handleUpdate: (id: number, url: string) => void;
}

const DraggableMediaItemFC: React.FC<DraggableMediaItemProps> = ({
  src,
  id,
  index,
  total,
  uploadProgress,
  handleClose,
  handleUpdate,
}: DraggableMediaItemProps) => {
  const [thumbnail, setThumbnail] = React.useState<string | undefined>();
  const [showEdit, setShowEdit] = React.useState<boolean>(false);

  const { firebase } = React.useContext(ConfigContext);
  const { userContext } = React.useContext(AppContext);
  const coachId = get(userContext, "coach.id", "");

  React.useEffect(() => {
    if (src && src.uri) {
      const urlPromise =
        src.category === "IMAGE" ? Promise.resolve(src.uri) : createVideoThumbnailBlob(src.downloadLink!);
      urlPromise.then(url => {
        setThumbnail(url);
      });
    }
  }, [src]);

  const uploadEditedImage = async (file: any, blob: Blob) => {
    const timestamp = new Date().getTime();
    const url = await saveElementToFirebase(
      firebase,
      mediaEditedFirebaseUrl(coachId),
      blob,
      "editedPrompt" + id + timestamp
    );
    handleUpdate(id, url);
  };

  
  const mediaWithErrors = src.errors ? Object.values(src.errors!!).some(error => error) : false;
  return (
    <>
      <Draggable key={id} draggableId={`media-item-${id}`} index={index}>
        {(provided: any) => (
          <div
            ref={provided.innerRef}
            onClick={() => {
              setShowEdit(true);
            }}
            className={`draggable-media-item-wrapper${src.validAspectRatio && !mediaWithErrors ? "" : " withError"}`}
            {...provided.draggableProps}
            {...provided.dragHandleProps}>
            <div className="no-overflow no-pointer-events">
              <ImagePreview imageurl={thumbnail} />
            </div>
            <div
              className={`close-icon-wrapper${src.validAspectRatio ? " close-icon-basic" : " close-icon-error"}`}
              id={`${id}`}
              onClick={e => handleClose(id)}>
              <Icon name="close" className="close-icon" />
            </div>
            <UploadProgressDisplay
              active={uploadProgress !== 1 ? 1 : 0}
              progress={uploadProgress || 0}
              invalid={!src.validAspectRatio || mediaWithErrors ? 1 : 0}
            />
          </div>
        )}
      </Draggable>

      {!src.validAspectRatio && src.imageFile && showEdit && (
        <EditImageModal
          handleSave={uploadEditedImage}
          open={showEdit}
          type={src.category}
          files={[src.imageFile]}
          handleClose={() => setShowEdit(false)}
        />
      )}
    </>
  );
};

export const DraggableMediaItem = DraggableMediaItemFC;
