import { DayOfWeek } from "../../interfaces";

import { Media, Prompt } from "./Prompt";
import { Category } from "../models";

export type CampaignDeliveryMode = "RANDOM_DELIVERY" | "SPECIFIC_DATE" | "ORDERED_RANDOM";
export type CampaignStatus = "ACTIVE" | "DISABLED" | "DRAFT";
export type EligibilityLevel = "STATE" | "REGION" | "PLAYER_ID";

export const timeKey = "deliveryTimeUtc";
export const dateKey = "deliveryDateUtc";
export const titleKey = "title";
export const snKey = "socialNetworks";

export interface PromptCampaign {
  campaignPromptId?: string;
  deliveryDatetimeUtc?: string;
  socialNetworks: string[];
  promptId: string;
  sortOrder?: number;
  prompt?: Prompt;
}

export interface PromptCampaignCreateRequest extends Prompt {
  campaignPromptId?: string;
  deliveryDatetimeUtc?: string;
  socialNetworks: string[];
}

export interface PromptCampaignComposed {
  campaignPromptId?: string;
  deliveryDatetimeUtc?: string;
  socialNetworks: string[];

  prompt: Prompt;
}

export interface EligibilityCriteria {
  campaignEligibilityCriterionId?: string;
  campaignId?: string;
  isInclude: boolean;
  level?: EligibilityLevel;
  values: number[];
}

export interface SelectedDay {
  dayOfWeek: DayOfWeek;
  time?: string;
}

export interface CampaignRequest {
  campaignId?: string;
  name: string;
  isAutoPost?: boolean;
  campaignPrompts: PromptCampaign[];
  categoryIds?: string[];
  deliveryMode: CampaignDeliveryMode;
  eligibilityCriteria?: EligibilityCriteria[];
  subscriptionPlayerIds?: number[];
  subscribedPlayers?: number[];
  randomScheduleDays?: SelectedDay[];
  randomScheduleDeliveryTimePst?: string;
  deliveryTimeDatePicker?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
  startDatePicker?: string;
  endDatePicker?: string;
}

export interface CampaignEditRequest {
  campaignId?: string;
  name: string;
  isAutoPost?: boolean;
  campaignPrompts: PromptCampaign[];
  categoryIds?: string[];
  deliveryMode: CampaignDeliveryMode;
  eligibilityCriteria?: EligibilityCriteria[];
  subscribedPlayers?: number[];
  subscriptionPlayerIds?: number[];
  randomScheduleDays?: SelectedDay[];
  randomScheduleDeliveryTimePst?: string;
  startDate?: string;
  endDate?: string;
  status?: string;
  shuffle?: boolean;
}

export interface Campaign extends CampaignRequest {
  coachId?: string;
  creationDate?: string;
  // prompts?: PromptCampaign[];
  socialNetworks?: { [key: string]: boolean };
  categories?: Category[];
}

export interface SelectedCampaignInfo {
  categoryIds: string[];
  title: string;
  post?: Media;
  campaignId?: string;
}

export interface SelectedCampaign {
  [key: string]: SelectedCampaignInfo;
}

export interface EditableCampaign extends CampaignRequest {
  prompts?: PromptCampaign[];
}

export interface CampaignResume {
  campaignId: string;
  campaignName: string;
  coachId: string;
  createdDate: string;
  firstPromptMedia: Media;
  promptCount: number;
  subscribedPlayerCount: number;
  categoryIds: string[];

  campaignStatus: string;
}

export interface CampaignSubscriptionResponse {
  campaignPlayerIds: string[];
}

export interface CampaignStep {
  validations: () => Promise<any | void>;
}

export interface PlayerCampaign {
  campaignName: string;
  campaignId: number;
  isAutoPost: boolean;
}

export interface CampaignListSubscriptionResponse {
  campaignIds?: number[];
}
