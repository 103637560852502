import styled from "styled-components";
import { Grid, Checkbox, Icon, Dropdown } from "semantic-ui-react";

import { theme } from "../../../styling/theme";

export const ContainerGrid = styled(Grid.Row)`
  background: ${(props: any) => (props.customcolor ? props.customcolor : "transparent")};
`;

export const ContainerContent = styled(Grid.Column)`
  background: ${(props: any) => (props.color ? props.color : "transparent")};
  font-family: "Open Sans", sans-serif;
  color: ${theme.labelColor};
  &&& {
    margin-right: ${(props: any) => (props.marginright ? props.marginright + "px" : "auto")};
  }
`;

export const SelectRadio = styled(Checkbox)`
  &&& {
    margin-right: 10px;
  }
`;

export const DeleteIcon = styled(Icon)`
  color: ${theme.iconLightColor};

  &&& {
    margin: 5px;
  }
`;

export const CategoriesDropDown = styled(Dropdown)({
  minWidth: "12em !important",
});
