import * as React from "react";
import { orderBy } from "lodash";
import { Card, Form, Message } from "semantic-ui-react";
import {
  BasicFormProps,
  CampaignDeliveryMode,
  PromptCampaign,
  dateKey,
  timeKey,
  snKey,
} from "../../../interfaces";
import { promptSpecificDate } from "../../../utils";
import { PromptLargeCard, PromptSelectableCard } from "../../Cells";
import { DraggablePromptContainer } from "../../DraggablePromptContainer";
import { PromptCards } from "../../Lists";

import "./styles.scss";

interface OwnProps extends BasicFormProps {
  campaignPrompts: PromptCampaign[];
  campaignDeliveryMode: CampaignDeliveryMode;
  isAutoPost: boolean;
  ascOrder?: boolean;
  submitRef: any;

  /**
   * Handle delivery mode changes
   */
  handleSetFieldValue?: (field: string, value: any) => void;

  handlePromptsSorted?: (campaignPrompts: PromptCampaign[]) => void;
}

type Props = OwnProps;

/**
 * Review Step.
 */
const CampaignReviewInfoFC: React.FC<Props> = ({
  campaignPrompts,
  messages,
  values,
  campaignDeliveryMode,
  isAutoPost,
  submitRef,
  ascOrder = true,
  handleSetFieldValue,
  handleSubmit,
  handlePromptsSorted,
}) => {
  const errors = messages && messages.error && messages.error.length > 0;
  const [campaignSorted, setSortedCampaign] =
    React.useState<PromptCampaign[]>(campaignPrompts);
  const isScheduled = campaignDeliveryMode === "SPECIFIC_DATE";
  const fullSize = !isAutoPost && !isScheduled;

  React.useEffect(() => {
    sortCampaigns();
  }, [ascOrder]);

  // React.useEffect(() => {
  //   if (handlePromptsSorted) {
  //     handlePromptsSorted(campaignSorted);
  //   }
  // }, [campaignSorted]);

  const sortCampaigns = (): void => {
    if (isScheduled) {
      const toSort = campaignPrompts.map((prompt) => {
        const dateobjc = promptSpecificDate(
          values[`${prompt.promptId}${dateKey}`],
          values[`${prompt.promptId}${timeKey}`]
        );
        return {
          ...prompt,
          deliveryDate: new Date(dateobjc || ""),
        };
      });

      const lowerOrder = ascOrder ? "asc" : "desc";
      const result = orderBy(toSort, ["deliveryDate"], [lowerOrder]);

      setSortedCampaign(result);
    }
  };
  const CustomComponent = fullSize ? PromptSelectableCard : PromptLargeCard;

  /**
   * A helper function to integrate react-beautiful-dnd
   *
   * @param list the list being reordered
   * @param startIndex the 'source' parameter from react-beautiful-dnd
   * @param endIndex the 'destination' parameter from react-beautiful-dnd
   */
  const reorder = (
    list: PromptCampaign[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any): void => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    const realDestinationIndex = destination.index;
    const realSourceIndex = source.index;
    const newCampaigns = reorder(
      campaignSorted,
      realSourceIndex,
      realDestinationIndex
    );
    const final = newCampaigns.map(
      (p: PromptCampaign, index) =>
        ({ ...p, sortOrder: index } as PromptCampaign)
    );

    setSortedCampaign(final);

    if (handlePromptsSorted) {
      handlePromptsSorted(final);
    }
  };

  const prompts =
    campaignDeliveryMode === "ORDERED_RANDOM" ? (
      <DraggablePromptContainer
        isAutoPost={isAutoPost}
        prompts={campaignSorted}
        campaignDeliveryMode={campaignDeliveryMode}
        onDragEnd={onDragEnd}
        socialNetworks={values}
        handleSetFieldValue={handleSetFieldValue!}
      />
    ) : (
      campaignSorted &&
      campaignSorted.map((prompt: PromptCampaign, index: number) => {
        const props = {
          prompt: prompt.prompt!,
          campaignDeliveryMode,
          isAutoPost,
          deliveryDateUtc: values[`${prompt.promptId}${dateKey}`],
          deliveryTimeUtc: values[`${prompt.promptId}${timeKey}`],
          socialNetworks: values[`${prompt.promptId}${snKey}`],
          handleSetFieldValue: handleSetFieldValue!,
          editable: true,
        };
        return <CustomComponent key={index} {...props} />;
      })
    );

  return (
    <Form
      size="large"
      error={errors}
      ref={submitRef}
      onSubmit={handleSubmit}
      className={"campaignReviewForm"}
    >
      <div className={"formDiv"}>
        {messages && messages.error && messages.error.length > 0 && (
          <Message error list={messages && messages.error} />
        )}
        {/* <Card.Group> {prompts}</Card.Group> */}
        <Card.Group>
          <PromptCards
            fromEdit={false}
            promptsSorted={campaignSorted}
            isAutoPost={isAutoPost}
            deliveryMode={campaignDeliveryMode}
            values={values}
            handleSetFieldValue={handleSetFieldValue!}
            handlePromptsSorted={(final) => {
              setSortedCampaign(final);

              if (handlePromptsSorted) {
                handlePromptsSorted(final);
              }
            }}
          />
        </Card.Group>
      </div>
    </Form>
  );
};

export const CampaignReviewInfoComponent = CampaignReviewInfoFC;
