import { defineMessages } from "react-intl";

export enum CampaignPromptsType {
  title = "CampaignPromptsType.title",
  selectedPrompt = "CampaignPromptsType.selectedPrompt",
  multipleSelectedPrompts = "CampaignPromptsType.multipleSelectedPrompts",
}

export const en = {
  [CampaignPromptsType.title]: "Select Prompts",
  [CampaignPromptsType.selectedPrompt]: "{promptsCounter} Prompt selected for this campaign",
  [CampaignPromptsType.multipleSelectedPrompts]: "{promptsCounter} Prompts selected for this campaign",
};

export const descriptors = defineMessages({
  [CampaignPromptsType.title]: {
    id: CampaignPromptsType.title,
    defaultMessage: en[CampaignPromptsType.title],
    description: "-",
  },
  [CampaignPromptsType.selectedPrompt]: {
    id: CampaignPromptsType.selectedPrompt,
    defaultMessage: en[CampaignPromptsType.selectedPrompt],
    description: "-",
  },
  [CampaignPromptsType.multipleSelectedPrompts]: {
    id: CampaignPromptsType.multipleSelectedPrompts,
    defaultMessage: en[CampaignPromptsType.multipleSelectedPrompts],
    description: "-",
  },
});
