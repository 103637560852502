import { defineMessages } from "react-intl";

export const getDefaultInfoMessages = (defaultDeliveryTime: string) => [
  `All prompts will be sent to players device at ${defaultDeliveryTime} Pacific Time`,
  `All changes applied will take effect starting tomorrow`,
];

export enum PromptsType {
  addPrompt = "PromptsType.addPrompt",
  saveButton = "PromptsType.saveButton",
  importantTitle = "PromptsType.importantTitle",
  limitDaysMessage = "PromptsType.limitDaysMessage",
  promptsListTitle = "CoachingProfile.promptsListTitle",
  schedulePromptsTitle = "CoachingProfile.schedulePromptsTitle",
  createdTitle = "CoachingProfile.createdTitle",
  remainingPrompts = "CoachingProfile.remainingPrompts",
  morePromptsRequired = "CoachingProfile.morePromptsRequired",
  welcomePromptRequired = "CoachingProfile.welcomePromptRequired",
  specificDateTitle = "CoachingProfile.specificDateTitle",
  randomTitle = "CoachingProfile.randomTitle",
  limitDaysTitle = "CoachingProfile.limitDaysTitle",
}

export const en = {
  [PromptsType.addPrompt]: "Add Prompt",
  [PromptsType.saveButton]: "Save Random Schedule",
  [PromptsType.importantTitle]: "Important:",
  [PromptsType.limitDaysMessage]: "Select at least 3 days",
  [PromptsType.promptsListTitle]: "My Prompts",
  [PromptsType.schedulePromptsTitle]: "Schedule Prompts",
  [PromptsType.createdTitle]: "Created ",
  [PromptsType.remainingPrompts]: "random prompts remaining until you can publish",
  [PromptsType.welcomePromptRequired]: "A welcome prompt is required to publish.",
  [PromptsType.morePromptsRequired]: "more prompts required to publish.",
  [PromptsType.specificDateTitle]: "To be sent on:",
  [PromptsType.randomTitle]: "Sent randomly",
  [PromptsType.limitDaysTitle]: "Days we should send randomized prompts to your players",
};

export const descriptors = defineMessages({
  [PromptsType.addPrompt]: {
    id: PromptsType.addPrompt,
    defaultMessage: en[PromptsType.addPrompt],
    description: "-",
  },
  [PromptsType.limitDaysTitle]: {
    id: PromptsType.limitDaysTitle,
    defaultMessage: en[PromptsType.limitDaysTitle],
    description: "-",
  },
  [PromptsType.saveButton]: {
    id: PromptsType.saveButton,
    defaultMessage: en[PromptsType.saveButton],
    description: "-",
  },
  [PromptsType.importantTitle]: {
    id: PromptsType.importantTitle,
    defaultMessage: en[PromptsType.importantTitle],
    description: "-",
  },
  [PromptsType.limitDaysMessage]: {
    id: PromptsType.limitDaysMessage,
    defaultMessage: en[PromptsType.limitDaysMessage],
    description: "-",
  },
  [PromptsType.promptsListTitle]: {
    id: PromptsType.promptsListTitle,
    defaultMessage: en[PromptsType.promptsListTitle],
    description: "-",
  },
  [PromptsType.schedulePromptsTitle]: {
    id: PromptsType.schedulePromptsTitle,
    defaultMessage: en[PromptsType.schedulePromptsTitle],
    description: "-",
  },
  [PromptsType.createdTitle]: {
    id: PromptsType.createdTitle,
    defaultMessage: en[PromptsType.createdTitle],
    description: "-",
  },
  [PromptsType.remainingPrompts]: {
    id: PromptsType.remainingPrompts,
    defaultMessage: en[PromptsType.remainingPrompts],
    description: "-",
  },
  [PromptsType.morePromptsRequired]: {
    id: PromptsType.morePromptsRequired,
    defaultMessage: en[PromptsType.morePromptsRequired],
    description: "-",
  },
  [PromptsType.welcomePromptRequired]: {
    id: PromptsType.welcomePromptRequired,
    defaultMessage: en[PromptsType.welcomePromptRequired],
    description: "-",
  },
  [PromptsType.specificDateTitle]: {
    id: PromptsType.specificDateTitle,
    defaultMessage: en[PromptsType.specificDateTitle],
    description: "-",
  },
  [PromptsType.randomTitle]: {
    id: PromptsType.randomTitle,
    defaultMessage: en[PromptsType.randomTitle],
    description: "-",
  },
});
