import { defineMessages } from "react-intl";

export enum PromptsType {
  createdTitle = "PromptsType.createdTitle",
  autoPost = "PromptsType.autoPost",

  pstZoneMessage = "PromptsType.pstZoneMessage",
  specificDateTitleFuture = "PromptsType.specificDateTitleFuture",
  timePlaceholder = "PromptsType.specificDateTitlePass",

  datePlaceholder = "PromptsType.datePlaceholder",
}

export const en = {
  [PromptsType.createdTitle]: "Created: ",
  [PromptsType.autoPost]: "Auto Post:",
  [PromptsType.datePlaceholder]: "Select a date",
  [PromptsType.timePlaceholder]: "Select a time",

  [PromptsType.specificDateTitleFuture]: "To be sent on:",

  [PromptsType.pstZoneMessage]: "All prompts will be sent using Pacific Time Zone",
};

export const descriptors = defineMessages({
  [PromptsType.autoPost]: {
    id: PromptsType.autoPost,
    defaultMessage: en[PromptsType.autoPost],
    description: "-",
  },
  [PromptsType.pstZoneMessage]: {
    id: PromptsType.pstZoneMessage,
    defaultMessage: en[PromptsType.pstZoneMessage],
    description: "-",
  },
  [PromptsType.createdTitle]: {
    id: PromptsType.createdTitle,
    defaultMessage: en[PromptsType.createdTitle],
    description: "-",
  },
  [PromptsType.specificDateTitleFuture]: {
    id: PromptsType.specificDateTitleFuture,
    defaultMessage: en[PromptsType.specificDateTitleFuture],
    description: "-",
  },
  [PromptsType.timePlaceholder]: {
    id: PromptsType.timePlaceholder,
    defaultMessage: en[PromptsType.timePlaceholder],
    description: "-",
  },
  [PromptsType.datePlaceholder]: {
    id: PromptsType.datePlaceholder,
    defaultMessage: en[PromptsType.datePlaceholder],
    description: "-",
  },
});
