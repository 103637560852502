import * as React from "react";
import { Modal, Button, Grid } from "semantic-ui-react";

import { modalReducer } from "./modalReducer";
import "./styles.scss";
import { PostReviewEvent, ReviewStatus } from "../../../../interfaces";
import { printDateToChat } from "../../../../utils";

interface Props {
  /**
   * Modal Title
   */
  title?: string;
  events?: PostReviewEvent[];
  opened: boolean;
  closeHandler: () => void;
}

const ReviewHistoryModalFC: React.FC<Props> = ({ title, opened, events, closeHandler }) => {
  const [state, dispatch] = React.useReducer(modalReducer, { open: opened });

  const reviewLabels = {
    [ReviewStatus.REVIEWED]: "Reviewed",
    [ReviewStatus.REQUEST_CHANGES]: "Changes Requested",
    [ReviewStatus.COMPLETED]: "Completed",
    [ReviewStatus.CHANGES_SUBMITTED]: "Changes Submitted",
  };

  React.useEffect(() => {
    if (opened) {
      dispatch({ type: "open" });
    } else {
      dispatch({ type: "close" });
    }
  }, [opened]);

  return (
    <Modal
      size={"small"}
      open={state.open}
      onClose={() => {
        dispatch({ type: "close" });
        closeHandler();
      }}
    >
      <Modal.Header>{title}</Modal.Header>

      <Modal.Content>
        <Grid className={"complianceHistoryContainer"}>
          {events &&
            events.map(({ message, username, createdDate, status }, index) => {
              return (
                <Grid.Row className={"complianceRequest"} key={"bubble_" + index}>
                  <div>
                    <strong>{status && reviewLabels[status]}</strong>
                    {` by ${username || "Unknown"}`}
                  </div>
                  <div>{message}</div>
                  <div className={"requestInfo"}>{printDateToChat(createdDate)}</div>
                </Grid.Row>
              );
            })}
        </Grid>
      </Modal.Content>

      <Modal.Actions>
        <Button
          data-elm-id={`reviewHistoryCloseBtn`}
          primary
          onClick={() => {
            dispatch({ type: "close" });
            closeHandler();
          }}
        >
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const ReviewHistoryModal = ReviewHistoryModalFC;
