import * as React from "react";
import { Formik } from "formik";
import { RouteComponentProps, withRouter } from "react-router";
import { get } from "lodash";

import { LoginStepServices, PlayerSignUpStep, SocialCoachSessionService } from "../../../services";
import { FirstLoginPersonalInfo } from "../../../components";
import { UserRegistered } from "../../../interfaces/dtos";
import { URLS, BEDate } from "../../../utils";
import { AppContext } from "../../../providers";

import { validationSchema } from "./validations";
import { Chat, CoachBasic } from "../../../interfaces";

export interface OwnProps {
  currentPosition?: number;
  chatMessages: Chat[];
  coachInfo?: CoachBasic;

  successHandler: (user: UserRegistered) => void;
}

type Props = OwnProps & RouteComponentProps;

const IntegratedPersonalInformation: React.FC<Props> = ({ chatMessages, coachInfo, successHandler, history }) => {
  const { userContext } = React.useContext(AppContext);

  return (
    <div>
      <Formik
        validateOnBlur={false}
        validateOnChange={true}
        initialValues={{
          firstName: get(userContext, "profile.firstName", ""),
          lastName: get(userContext, "profile.lastName", ""),
          birthdate: get(userContext, "profile.birthday", ""),
          phoneNumber: get(userContext, "profile.phone", ""),
        }}
        validationSchema={validationSchema}
        onSubmit={async (
          { firstName, lastName, birthdate, phoneNumber },
          { setSubmitting, setErrors, validateForm }
        ) => {
          try {
            const errors = await validateForm({
              firstName,
              lastName,
              birthdate,
              phoneNumber,
            });

            if (errors && Object.values(errors).length > 0) {
              setErrors(errors);
            } else {
              const response = await SocialCoachSessionService.updatePlayer({
                phone: phoneNumber!,
                birthday: BEDate(birthdate),
                firstName: firstName!,
                lastName: lastName!,
                photoUrl: "",
              });
              await LoginStepServices.sendPlayerStep(PlayerSignUpStep.PLAYER_PROFILE_DATA);
              successHandler(response);
              history.push(URLS.player.firstPicture);
            }
          } catch (e) {
            setErrors({
              firstName: String(e).replace("date", "Birthdate"),
              lastName: undefined,
              birthdate: undefined,
              phoneNumber: undefined,
            });
          }
          setSubmitting(false);
        }}>
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
          <FirstLoginPersonalInfo
            messages={{ error: Object.values(errors) as string[] }}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            chatMessages={chatMessages}
            coachInfo={coachInfo}
            values={values}
          />
        )}
      </Formik>
    </div>
  );
};

export const PersonalInformationForm = withRouter(IntegratedPersonalInformation)
