import * as React from "react";
import { useCookies } from "react-cookie";

import { UserRegistered } from "../../interfaces";
import { AppContext } from "../../providers";

import { RegisterForm } from "./RegisterForm";
import { SocialForm } from "./SocialForm";
import { ThankYou } from "./ThankYou";

import "./styles.scss";
import { SignUpState } from "../../reducers/signup/initialState";
import { actions } from "../../reducers/signup/actions";
import { FloatedContainer } from "../../styling/baseStyle";

const reducer = (state: SignUpState, action: { name: string; data: any }) => {
  const { name, data } = action;
  switch (name) {
    case actions.SET_STEP:
      return { ...state, currentStep: data };
    case actions.SET_REGISTER_DATA:
      return { ...state, registerData: data, currentStep: 1 };
    case actions.SET_SOCIAL_DATA:
      return { ...state, socialData: data, currentStep: 2 };
  }
  return { ...state };
};

const Step = (props: { signupState: any; dispatch: (d: any) => void }) => {
  const { signupState, dispatch } = props;
  const [cookies, setCookie] = useCookies(["user-sc-register"]);
  const { userContext } = React.useContext(AppContext);

  let stepComponent = (
    <RegisterForm
      userRegistered={signupState.registerData || userContext}
      successHandler={user => {
        if (user) {
          setCookie("user-sc-register", user);
        }
        dispatch({ name: "SET_REGISTER_DATA", data: user });
      }}
    />
  );

  switch (signupState.currentStep) {
    case 1:
      stepComponent = (
        <SocialForm
          userId={signupState.registerData?.account?.userId}
          nextHandler={(data: any) => dispatch({ name: "SET_SOCIAL_DATA", data })}
          backHandler={() => {
            dispatch({ name: "SET_REGISTER_DATA", data: cookies["user-sc-register"] });
            dispatch({ name: "SET_STEP", data: signupState.currentStep - 1 });
          }}
        />
      );
      break;
    case 2:
      stepComponent = <ThankYou />;
      break;
    default:
      break;
  }
  return <React.Fragment>{stepComponent}</React.Fragment>;
};

export const SignUpPage = (props: any) => {
  const sessionContext = React.useContext(AppContext);

  let currentStep = parseInt(props?.match?.params?.step || "0", 10);
  currentStep = currentStep > 2 ? 0 : currentStep;

  const [signupState, dispatch] = React.useReducer(reducer, {
    currentStep,
    registerData: { ...sessionContext.userContext } as UserRegistered,
  });

  return (
    <FloatedContainer>
      <Step signupState={signupState} dispatch={dispatch} />
    </FloatedContainer>
  );
};
