import { keyBy } from "lodash";
import {
  MessageChat,
  POST_REVIEW_STATUS_NAMES,
  PlayerPost,
  SocialNetworkPreLoaded,
} from "../../../../../../../interfaces";
import { printBasicDate } from "../../../../../../../utils";

const useDisplayData = (selectedPost: PlayerPost, playerId: number) => {
  const postData = selectedPost;

  let postReviewEvents: MessageChat[] = selectedPost.postReviewEvents
    ?.filter(review => review.message && review.message?.length > 0)
    .map(event => {
      return {
        type: event.userRole === "COACH" ? "LEFT" : "RIGHT",
        owner: event.username,
        date: printBasicDate(event.createdDate),
        message: event.message,
      };
    }) as MessageChat[];

  const postedSocialNetworks = postData.socialNetworks.filter(sn => sn.status === "POSTED");

  const successSocialNetworks = postedSocialNetworks.map(sn => {
    return {
      socialNetworkId: sn.socialNetworkId,
      externalLink: sn.externalPostUrl?.replace("[", "").replace("]", ""),
      status: sn.status,
    };
  }) as SocialNetworkPreLoaded[];

  // const successSocialNetworks = keyBy(successSocialNetworksList, 'socialNetworkId')

  const message = postData.socialNetworks.filter(sn => sn.status === "POSTED")[0].postedCaption || postData.message;

  const changesRequested = postData.coachPostReviewStatus === "REQUEST_CHANGES";
  const postReviewEventsLabel = postData.coachPostReviewStatus
    ? POST_REVIEW_STATUS_NAMES[postData.coachPostReviewStatus]
    : POST_REVIEW_STATUS_NAMES.NULL;

  const postedDate = printBasicDate(postedSocialNetworks[0].postedTimestamp);

  return {
    postData,
    postReviewEvents,
    successSocialNetworks,
    message,
    playerId,
    changesRequested,
    postReviewEventsLabel,
    postedDate,
  };
};

export default useDisplayData;
