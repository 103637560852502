import * as React from "react";
import{ useIntl } from "react-intl";
import { Grid } from "semantic-ui-react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { isNumber } from "lodash";

import * as DTO from "../../interfaces";
import { PromptServices } from "../../services";
import { ConfigContext } from "../../contexts/appContexts";
import { URLS } from "../../utils";
import { Svg } from "../../assets";

import SchedulePrompts from "./components/SchedulePrompts";
import { OptionCards } from "./components/OptionCards";

import "./styles.scss";

/**
 * @deprecated The component should not be used
 */
export interface Question {
  disabled: boolean;
  text: string;
}

interface OwnProps {
  coachId: string;
  handlePromptsReady(): void;
}

type Props = OwnProps   & RouteComponentProps;

const PromptsFC: React.FC<Props> = ({ coachId, handlePromptsReady, history }) => {
  const [welcomePrompt, setWelcomePrompt] = React.useState<DTO.Prompt | undefined>(undefined);
  const [activePrompts, setActivePrompts] = React.useState<number | undefined>();
  const {
    randomPrompts: { minimumDaysconfigurable, defaultDeliveryTime, minActivePrompts, welcomePromptRequired },
  } = React.useContext(ConfigContext);

  React.useEffect(() => {
    const activePromptsCounter = async () => {
      setActivePrompts(await PromptServices.getTotalActivePrompts(coachId));
    };

    activePromptsCounter();

    const checkWelcomePrompts = async () => {
      setWelcomePrompt(await PromptServices.getWelcomePrompt(coachId));
    };
    checkWelcomePrompts();
  }, [coachId]);

  React.useEffect(() => {
    if (isNumber(activePrompts) && activePrompts >= minActivePrompts && (welcomePrompt || !welcomePromptRequired)) {
      handlePromptsReady();
    }
  }, [activePrompts, welcomePrompt]);

  const cards = [
    {
      header: "Prompt List",
      meta: "View all the prompts that you have created",
      imageSrc: Svg.PromptList,
      path: URLS.coach.library,
    },
    {
      header: "Library",
      meta: "Add or manage the content of your posts.",
      imageSrc: Svg.Library,
      path: URLS.coach.library,
    },
    {
      header: "Campaigns",
      meta: "Create and manage your prompts batches ",
      imageSrc: Svg.Campaing,
      path: URLS.coach.campaigns,
    },
  ];
  const promptsContent = (): JSX.Element => {
    return (
      <Grid>
        <Grid.Row>
          <SchedulePrompts
            coachId={coachId}
            minimumDaysconfigurable={minimumDaysconfigurable}
            defaultDeliveryTime={defaultDeliveryTime}
            activePrompts={activePrompts}
            minActivePrompts={minActivePrompts}
            welcomePromptRequired={welcomePromptRequired}
            activeWelcomePrompt={welcomePrompt}
          />
        </Grid.Row>

        <OptionCards
          cards={cards}
          onClick={card => {
            history.push(card.path);
          }}
        />
      </Grid>
    );
  };

  return promptsContent();
};

export const PromptsManager = withRouter(PromptsFC)
