import * as React from "react";
import { Popup, Button, Icon, Menu, StrictMenuItemProps, SemanticICONS } from "semantic-ui-react";

import "./styles.scss";

interface Props {
  iconName?: SemanticICONS;
  items: StrictMenuItemProps[];
  handleAction: (index: number) => void;
}

const ActionListFC: React.FC<Props> = ({ iconName = "list alternate outline", items, handleAction }) => {
  return (
    <Popup
      position={"top right"}
      trigger={
        <Button data-elm-id={`MergeActionsMenuBtn`} icon size="mini">
          <Icon name={iconName} />
        </Button>
      }
      flowing
      hoverable>
      <Menu items={items} onItemClick={(_, data) => handleAction(data.index!)} vertical secondary />
    </Popup>
  );
};

export const ActionList = ActionListFC;
