import { useState, useEffect, useContext, useMemo } from "react";
import { AdminWebSetting, WebSettings, SocialNetworksId } from "../interfaces";
import { availableFunctionality } from "../utils";
import { SettingsServices } from "../services";
import { get } from "lodash";
import { AppContext } from "../providers";

export const useSocialNetworks = (defaultCoach: string | undefined = undefined, withIGStory: boolean = true) => {
  const [displayTikTok, setDisplayTikTok] = useState<boolean>(false);
  const { userContext } = useContext(AppContext);
  const coachId =
    defaultCoach === undefined
      ? userContext?.coach
        ? get(userContext, "coach.id", "")
        : get(userContext, "subscriptions[0].pricePlan.coachId", "")
      : defaultCoach;

  useEffect(() => {
    const setTikTokPermission = (adminSettings: AdminWebSetting): void => {
      setDisplayTikTok(availableFunctionality(adminSettings, WebSettings.TIKTOK));
    };

    const getSettings = async () => {
      SettingsServices.getAdminSettingsByCoachId(coachId).then(setTikTokPermission);
    };

    getSettings();
  }, [coachId]);

  const sns: SocialNetworksId[] = useMemo(() => {
    const baseSns: SocialNetworksId[] = ["FB", "LI", "IG"];

    if (withIGStory) {
      baseSns.push("IG_STORY");
    }

    baseSns.push("TW");

    if (displayTikTok) {
      baseSns.push("TT");
    }

    return baseSns;
  }, [displayTikTok]);

  return { sns, displayTikTok };
};

// export default useSocialNetworks;
