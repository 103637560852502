import * as React from "react";
import { useIntl } from "react-intl";
import { Modal, Button } from "semantic-ui-react";

import { CampaignPageType, descriptors } from "./descriptors";

import "./styles.scss";

interface OwnProps {
  openConfirmationModal: boolean;
  size?: "mini" | "tiny" | "small" | "large" | "fullscreen";

  title?: string;
  message?: string;
  okLabel?: string;

  customActionClassName?: string;

  onClose?: () => void;
  okHandler: () => void;
  rejectHandler?: () => void;
}
type Props = OwnProps;

const ConfirmationModalFC: React.FC<Props> = ({
  openConfirmationModal,
  size = "mini",
  title,
  message,
  okLabel = undefined,
  customActionClassName = "",
  onClose,
  okHandler,
  rejectHandler,
}) => {
  const { formatMessage } = useIntl();

  formatMessage({ ...descriptors[CampaignPageType.yesBtn] });
  const headerLabel = title
    ? title
    : formatMessage({
        ...descriptors[CampaignPageType.saveCampaignAlert],
      });

  return (
    <Modal
      open={openConfirmationModal}
      onClose={onClose}
      className={"confirmationModal"}
      size={size}
    >
      <Modal.Header>{headerLabel}</Modal.Header>

      {message && <Modal.Description>{message}</Modal.Description>}

      <Modal.Actions className={customActionClassName}>
        {rejectHandler && (
          <Button
            data-elm-id={`confirmationModalNoBtn`}
            className={"secondary rounded padded"}
            onClick={rejectHandler}
          >
            {formatMessage({ ...descriptors[CampaignPageType.noBtn] })}
          </Button>
        )}
        <Button
          data-elm-id={`confirmationModalYesBtn`}
          className={"primary rounded"}
          onClick={okHandler}
        >
          {okLabel
            ? okLabel
            : formatMessage({ ...descriptors[CampaignPageType.yesBtn] })}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const ConfirmationModal = ConfirmationModalFC;
