import styled from "styled-components";
import { Grid, Card, Image } from "semantic-ui-react";
import { theme } from "../../../../styling/theme";

export const CardsGrid = styled(Grid.Row)({
  backgroundColor: "white",
  paddingLeft: "15px !important",
});

export const OptionContent = styled(Card.Content)({
  backgroundColor: "white",
});

export const OptionGroup = styled(Card.Group)({
  backgroundColor: "white",
  width: "100%",
});

export const OptionImage = styled(Image)({
  paddingTop: "20px",
  marginRight: "25px",
  width: "40px !important",
  float: "left",
});

export const OptionMeta = styled(Card.Meta)({
  color: `${theme.lineColor} !important`,
  paddingTop: "5px",
  paddingLeft: "60px",
  fontSize: "1em !important",
  letterSpacing: "-0.21px",
  lineHeight: "17px",
  textAlign: "left",
  marginLeft: "4px",
});

export const Option = styled(Card)`
  height: 158px;
  .header {
    text-align: left;
    padding: 20px 20px 5px;
  }
`;
