import { defineMessages } from "react-intl";

export enum CampaignCardType {
  createdTitle = "CampaignCardType.createdTitle",
}

export const en = {
  [CampaignCardType.createdTitle]: "Created: ",
};

export const descriptors = defineMessages({
  [CampaignCardType.createdTitle]: {
    id: CampaignCardType.createdTitle,
    defaultMessage: en[CampaignCardType.createdTitle],
    description: "-",
  },
});
