import { defineMessages } from "react-intl";

export enum SegmentationType {
  allPlayersLabel = "SegmentationType.allPlayersLabel",
  specificPlayersLabel = "SegmentationType.specificPlayersLabel",

  includeLabel = "SegmentationType.includeLabel",
  excludeLabel = "SegmentationType.excludeLabel",

  title = "SegmentationType.title",
  subtitle = "SegmentationType.subtitle",
}

export const en = {
  [SegmentationType.title]: "{campaignOrPrompt} Segmentation -",
  [SegmentationType.subtitle]: " Eligible Players",
  [SegmentationType.allPlayersLabel]: " All Players will have access to this {campaignOrPrompt}",
  [SegmentationType.specificPlayersLabel]: " Specific Players will have access to this {campaignOrPrompt}",
  [SegmentationType.includeLabel]: "Include Players - Select player will have access to this {campaignOrPrompt}",
  [SegmentationType.excludeLabel]: "Exclude Players - Select player will not have access to this {campaignOrPrompt}",
};

export const descriptors = defineMessages({
  [SegmentationType.title]: {
    id: SegmentationType.title,
    defaultMessage: en[SegmentationType.title],
    description: "-",
  },
  [SegmentationType.subtitle]: {
    id: SegmentationType.subtitle,
    defaultMessage: en[SegmentationType.subtitle],
    description: "-",
  },
  [SegmentationType.allPlayersLabel]: {
    id: SegmentationType.allPlayersLabel,
    defaultMessage: en[SegmentationType.allPlayersLabel],
    description: "-",
  },
  [SegmentationType.specificPlayersLabel]: {
    id: SegmentationType.specificPlayersLabel,
    defaultMessage: en[SegmentationType.specificPlayersLabel],
    description: "-",
  },
  [SegmentationType.includeLabel]: {
    id: SegmentationType.includeLabel,
    defaultMessage: en[SegmentationType.includeLabel],
    description: "-",
  },
  [SegmentationType.excludeLabel]: {
    id: SegmentationType.excludeLabel,
    defaultMessage: en[SegmentationType.excludeLabel],
    description: "-",
  },
});
