import * as React from "react";
import { get } from "lodash";
import * as DTO from "../../../interfaces";
import { AppContext } from "../../../providers";
import { CategoriesServices } from "../../../services";

import { CategorySection } from "../../../components/CategorySection";
import { EmptyView } from "../../../components/EmptyView";
import { Svg } from "../../../assets";
import { validateLong } from "../../../utils";

interface OwnProps {
  campaignList: DTO.CampaignResume[];
  categories: DTO.Category[];
  coachId: string;
}

type Props = OwnProps; 

export const CampaignsSearchList = ({ campaignList, categories }: Props) => {
  const [categoriesHash, setCategoriesHash] = React.useState<{
    [key: string]: string;
  }>({});
  const [withCategorizedLibraryPrompts, setWithCategorizedLibraryPrompts] =
    React.useState<boolean>(false);
  const { userContext } = React.useContext(AppContext);
  const coachId = get(userContext, "coach.id", "");

  React.useEffect(() => {
    const convertToHash = (
      result: DTO.Category[]
    ): { [key: string]: string } => {
      let hash = {};
      result.map((category) => {
        hash = {
          ...hash,
          [category.categoryId!]: category.name,
        };
      });

      return hash;
    };

    setCategoriesHash(convertToHash(categories));
  }, [categories]);

  const uncategorizedPrompts = (): DTO.CampaignResume[] => {
    return campaignList.filter((campaign) => {
      return (
        campaign.categoryIds?.length === 0 &&
        campaign.campaignStatus !== "UNPUBLISHED"
      );
    });
  };

  const unpublishedPrompts = (): DTO.CampaignResume[] => {
    return campaignList.filter((prompt) => {
      return prompt.campaignStatus === "UNPUBLISHED";
    });
  };
  const unpublished = unpublishedPrompts();
  const uncategorized = uncategorizedPrompts();

  const campaignsFilteredByCategory = (
    categoryId: string
  ): DTO.CampaignResume[] => {
    const campaignsFiltered = campaignList.filter((campaign) => {
      return (
        campaign.categoryIds?.includes(categoryId) &&
        campaign.campaignStatus !== "UNPUBLISHED"
      );
    });
    if (!withCategorizedLibraryPrompts && campaignsFiltered.length > 0) {
      setWithCategorizedLibraryPrompts(true);
    }
    return campaignsFiltered;
  };

  const campaignSection = (category: DTO.Category): JSX.Element | undefined => {
    const campaigns: DTO.CampaignResume[] = campaignsFilteredByCategory(
      category.categoryId!
    );

    if (campaigns.length === 0) {
      return undefined;
    }
    return (
      <CategorySection
        key={"category_section_" + category.categoryId}
        category={category}
        campaignList={campaigns}
        totalElements={campaigns.length}
        name={categoriesHash[category.categoryId!]}
        handleCategoryChange={(newValue) => {
          const newCatName = newValue ? newValue.trim() : newValue;
          if (newValue && validateLong(2, 25, newValue)) {
            CategoriesServices.update(coachId, {
              ...category,
              name: newCatName,
            })
              .then(() => {
                setCategoriesHash((hash) => {
                  return {
                    ...hash,
                    [category.categoryId!]: newValue,
                  };
                });
              })
              .catch((e) => {
                if (e.status === 409) {
                  alert("You already have a category named: " + newCatName);
                } else {
                  alert(e.message);
                }
              });
          } else if (newValue && !validateLong(2, 25, newCatName)) {
            alert("Category name should have 2-25 characters.");
          }
        }}
      />
    );
  };

  const showEmptySection =
    !withCategorizedLibraryPrompts &&
    unpublished.length === 0 &&
    uncategorized.length === 0;
  return (
    <div className="campaignListContainer">
      {unpublished.length > 0 && (
        <CategorySection
          name={"Unpublished"}
          campaignList={unpublished}
          disable={true}
          totalElements={unpublished.length}
        />
      )}
      {uncategorized.length > 0 && (
        <CategorySection
          name={"Uncategorized"}
          campaignList={uncategorized}
          disable={true}
          totalElements={uncategorized.length}
        />
      )}

      {categories.map((category) => {
        return campaignSection(category);
      })}

      {showEmptySection && (
        <EmptyView
          text={"You have no campaigns"}
          customIconImage={Svg.Campaing}
        />
      )}
    </div>
  );
};
