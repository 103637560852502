import * as React from "react";
import { DropdownItemProps } from "semantic-ui-react";
import * as DTO from "../../../interfaces";
import { ContainerGrid, ContainerContent, SelectRadio, CategoriesDropDown, DeleteIcon } from "./styled";

interface OwnProps {
  categories: DTO.Category[];

  someCategoriesSelected: boolean;

  /**
   * To indicates if all prompts are selected
   */
  allSelected: boolean;

  /**
   * Called when category change
   */
  onChange?(selectedCategoryId?: string): void;
  /**
   * Called when delete all is pressed
   */
  deleteAll(): void;

  /**
   * Called when select all attribute change
   */
  onSelectAll(selected: boolean): void;
}

type Props = OwnProps

const BulkAllSection: React.FC<Props> = ({
  categories,
  someCategoriesSelected,
  allSelected,
  onChange,
  onSelectAll,
  deleteAll,
}) => {
  const [selectedCategoryId, setSelectedCategoryId] = React.useState<string | undefined>(undefined);
  const [selectAll, setSelectAll] = React.useState<boolean>(allSelected);

  const categoryOptions: DropdownItemProps[] = categories.map(cat => ({
    key: cat.categoryId,
    text: cat.name,
    value: cat.categoryId,
  }));

  React.useEffect(() => {
    if (onChange) {
      onChange(selectedCategoryId);
    }
  }, [selectedCategoryId]);

  React.useEffect(() => {
    setSelectAll(allSelected);
  }, [allSelected]);

  return (
    <ContainerGrid customcolor={"white"}>
      <ContainerContent width={4} marginright={"-22"}>
        <SelectRadio
          checked={selectAll}
          data-elm-id={"bulkAllSectionSelectRadio"}
          onClick={() => {
            setSelectAll(!selectAll);
            onSelectAll(!selectAll);
          }}
        />
        {"Select All "}
      </ContainerContent>

      <ContainerContent width={9} marginright={15}>
        <CategoriesDropDown
          search
          disabled={!selectAll && !someCategoriesSelected}
          data-elm-id={"bulkAllSectionCategoriesDropDown"}
          selection
          placeholder={"Category"}
          className="searchInput"
          multiple={false}
          onChange={(e: any, data: { value: string }) => {
            setSelectedCategoryId(data.value !== "" ? (data.value as string) : undefined);
          }}
          clearable
          options={categoryOptions}
        />
      </ContainerContent>
      <ContainerContent width={3} marginright={0}>
        Delete All
        <DeleteIcon
          data-elm-id={"bulkAllSectionDeleteAll"}
          name="trash alternate outline"
          onClick={() => deleteAll()}
        />
      </ContainerContent>
    </ContainerGrid>
  );
};

export const BulkAllSectionCell = BulkAllSection;
