/* tslint:disable:no-namespace */
import axios from "axios";
import { SocialConfig } from "../../interfaces/customTypes/configProperties";
import * as DTO from "../../interfaces/dtos";

const LI_ORGANIZATION_INFO = "https://api.linkedin.com/rest/organizations?ids=List(:organizationIds)";
const LI_ORGANIZATION =
  "https://api.linkedin.com/rest/organizationAuthorizations?bq=authorizationActionsAndImpersonator&authorizationActions=List((authorizationAction:(organizationRoleAuthorizationAction:(actionType:ADMINISTRATOR_WRITE))),(authorizationAction:(organizationRoleAuthorizationAction:(actionType:DIRECT_SPONSORED_CONTENT_POSTER_WRITE))))";

namespace LinkedInNS {
  const LIURL = "https://www.linkedin.com";

  export const requestAccessToken = (
    code: any,
    state: any,
    linkedInConfig: SocialConfig,
    linkedinSecretKey: string
  ): Promise<any> => {
    const accessParams = `grant_type=authorization_code&code=${code}&state=${state}&redirect_uri=${linkedInConfig.callback}&client_id=${linkedInConfig.api_key}&client_secret=${linkedinSecretKey}`;
    return axios({
      method: "post",
      url: LIURL + "/oauth/v2/accessToken?" + accessParams,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
    });
  };

  export const requestProfile = async (token: string): Promise<any> => {
    const response = await axios.get("https://api.linkedin.com/v2/me", {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  };

  export const logout = () => {
    const width = 500;
    const height = 500;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    const wind = window.open(
      `${LIURL}/m/logout`,
      "LinkedInLogout",
      "menubar=no,location=no,resizable=no,scrollbars=no,status=no,width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );

    setTimeout(() => {
      wind!.close();
    }, 5000);
  };

  export const getOrganizations = async (accessToken: string): Promise<DTO.LinkedInOrganizationPermissionsResponse> => {
    const response = await axios.get<DTO.LinkedInOrganizationPermissionsResponse>(LI_ORGANIZATION, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Linkedin-Version": 202306,
        "X-Restli-Protocol-Version": "2.0.0",
      },
    });

    return response.data;
  };

  // https://api.linkedin.com/rest/organizations?ids=List(id1,id2,...idn)
  export const getOrganizationInfo = async (
    accessToken: string,
    organizationIds: string[]
  ): Promise<DTO.LinkedInOrganizationInfoResponse> => {
    const response = await axios.get<DTO.LinkedInOrganizationInfoResponse>(
      LI_ORGANIZATION_INFO.replace(":organizationIds", organizationIds.join(",")),
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Linkedin-Version": 202306,
          "X-Restli-Protocol-Version": "2.0.0",
        },
      }
    );

    return response.data;
  };
}

export const LinkedIn = LinkedInNS;
