import { client as axios } from "./axiosClient";
import { Constants, catchServiceException } from "@socialcoach/services";
import * as DTO from "../interfaces";

const KEYPHRASES_SERVICE = `${Constants.API_URL}/keyphrases`;

export class KeywordServices {
  @catchServiceException()
  static async get(coachId: string): Promise<DTO.KeywordElement[]> {
    const { data } = await axios.get<DTO.KeywordElement[]>(`${KEYPHRASES_SERVICE}/coaches/${coachId}`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async getAsPlayer(playerId: number): Promise<DTO.KeywordElement[]> {
    const { data } = await axios.get<DTO.KeywordElement[]>(`${KEYPHRASES_SERVICE}/players/${playerId}`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async update(coachId: string, keywords: DTO.KeywordElement[]): Promise<DTO.KeywordElement[]> {
    const { data } = await axios.put<DTO.KeywordElement[]>(`${KEYPHRASES_SERVICE}/coaches/${coachId}`, keywords, {
      withCredentials: true,
    });
    return data;
  }
}
