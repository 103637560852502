import * as React from "react";
import { Image } from "semantic-ui-react";
import { Png } from "../../../../assets";
import "./styles.scss";

interface Props {
  /**
   * Image url
   */
  photoUrl?: string;
  customClassName?: string;
}

export const ProfileImage: React.FC<Props> = ({ photoUrl, customClassName }) => {
  const imageURL = photoUrl ? photoUrl! : Png.Profile;
  return <Image src={imageURL} className={customClassName ? customClassName : "userPicture"} />;
};
