import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { PlayerVerifiedSection } from "../../../components";
import { AppContext } from "../../../providers";
import { URLS } from "../../../utils";

import { FloatedContainer } from "../../../styling/baseStyle";
import { PlayerSignUpStep } from "../../../services";

const IntegratedFirstCongrats: React.FC<RouteComponentProps> = ({ history }) => {
  const { userContext, setUserContext } = React.useContext(AppContext);

  React.useEffect(() => {
    setTimeout(() => {
      setUserContext({
        ...userContext!,
        signUpStep: PlayerSignUpStep[PlayerSignUpStep.PLAYER_BUSINESS_DATA],
      });
      history.push(URLS.player.dashboard);
    }, 3000);
  }, []);

  return (
    <FloatedContainer>
      <PlayerVerifiedSection />
    </FloatedContainer>
  );
};

export const FirstCongratsPage = withRouter(IntegratedFirstCongrats);
