import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button, Header, Grid, DropdownItemProps, Radio } from "semantic-ui-react";
import { capitalize } from "lodash";

import { AppContext } from "../../providers";
import { TiktokServices } from "../../services";

import { TiktokPrivacyLevel } from "./components/TiktokPrivacyLevel";
import { TiktokBrandedSection } from "./components/TiktokBrandedSection";
import { TiktokAccountInfo } from "./components/TiktokAccountInfo";
import { modalReducer } from "./modalReducer";
import { useQueryInfo } from "./hook";

import "./styles.scss";

interface OwnProps {
  opened: boolean;

  acceptHandler: (promptContent: string, hashtags?: string[]) => void;
  rejectHandler?: () => void;
}

type Props = OwnProps;
const baseDataElm = "TiktokPreferencesClass";
/**
 * TiktokPreferences Components
 */
const TiktokPreferencesClass: React.FC<Props> = ({ opened, rejectHandler }) => {
  const { userContext } = React.useContext(AppContext);
  const { queryInfo } = useQueryInfo(userContext?.player?.id);

  const [state, dispatch] = React.useReducer(modalReducer, { open: opened, loading: false });

  const [loading, setLoading] = React.useState<boolean>(false);

  // Setup
  const [privacyLevelSelected, setPrivacyLevelSelected] = useState<DropdownItemProps | undefined>({
    key: "none",
    value: "none",
    text: "Select a privacy setting",
  });

  const [commentDisabled, setCommentDisabled] = useState<boolean>(false);
  const [duetDisabled, setDuetDisabled] = useState<boolean>(true);
  const [stitchDisabled, setStitchDisabled] = useState<boolean>(true);
  const [brandedContentDisclosure, setBrandDisabled] = useState<boolean>(false);
  const [brandedContentType, setBrandedContentType] = useState<string | undefined>(undefined);

  const [allowComments, setAllowComment] = useState<boolean>(false);
  const [allowDuets, setAllowDuets] = useState<boolean>(false);
  const [allowStitching, setAllowStitch] = useState<boolean>(false);

  const [nickName, setNickName] = useState<string>("");
  const [avatarUrl, setAvatarUrl] = useState<string>("");

  const [privacyLevelOptions, setPrivacyLevelOptions] = useState<DropdownItemProps[]>([]);

  const selfOnly = useMemo(() => {
    return privacyLevelSelected?.key === "SELF_ONLY";
  }, [privacyLevelSelected?.key]);

  useEffect(() => {
    if (opened) {
      dispatch({ type: "open" });
    } else {
      dispatch({ type: "close" });
    }
  }, [opened]);

  useEffect(() => {
    if (userContext?.player?.id) {
      setLoading(true);
      TiktokServices.tiktokPreferences()
        .then(response => {
          setAllowComment(response.allowComments);
          setAllowDuets(response.allowDuets);
          setAllowStitch(response.allowStitching);
          setBrandDisabled(response.brandedContentDisclosure);
          setBrandedContentType(response.brandedContentType);
          if (response.privacyLevel) {
            setPrivacyLevelSelected({
              key: response.privacyLevel,
              text: capitalize(response.privacyLevel.replace(/_/g, " ")),
              value: response.privacyLevel,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [userContext?.player?.id]);

  useEffect(() => {
    if (queryInfo && queryInfo!.data) {
      setCommentDisabled(queryInfo.data.commentDisabled);
      setDuetDisabled(queryInfo.data?.duetDisabled);
      setStitchDisabled(queryInfo.data?.stitchDisabled);
      setNickName(queryInfo.data.creatorNickname);
      setAvatarUrl(queryInfo.data.creatorAvatarUrl);
      const privacyOptions = queryInfo.data.privacyLevelOptions.map(privacy => {
        return {
          key: privacy,
          text: capitalize(privacy.replace(/_/g, " ")),
          value: privacy,
        };
      });
      setPrivacyLevelOptions(privacyOptions);
    } else if (queryInfo) {
      alert(`Tiktok Error ${queryInfo.error.message}`);
    }
  }, [queryInfo]);

  useEffect(() => {
    if (selfOnly) {
      setBrandDisabled(false);
    }
  }, [selfOnly]);

  useEffect(() => {
    if (!brandedContentDisclosure) {
      setBrandedContentType(undefined);
    }
  }, [brandedContentDisclosure]);

  const saveTiktokPreferences = (): void => {
    setLoading(true);
    TiktokServices.updateTiktokPreferences({
      allowComments,
      allowDuets,
      allowStitching,
      brandedContentDisclosure,
      brandedContentType,
      privacyLevel: privacyLevelSelected?.key!!,
    }).finally(() => {
      setLoading(false);
      dispatch({ type: "close" });
    });
  };

  const allowSettings = [
    {
      label: "Allow comments ",
      key: "allow_comments",
      updateAction: setAllowComment,
      value: allowComments,
      disabled: commentDisabled,
    },
    {
      label: "Allow duets ",
      key: "allow_duets",
      updateAction: setAllowDuets,
      value: allowDuets,
      disabled: duetDisabled,
    },
    {
      label: "Allow Stitching ",
      key: "allow_stitch",
      updateAction: setAllowStitch,
      value: allowStitching,
      disabled: stitchDisabled,
    },
  ];

  return (
    <Modal
      closeIcon
      size={"small"}
      open={state.open}
      onClose={() => {
        dispatch({ type: "close" });
        if (rejectHandler) {
          rejectHandler();
        }
      }}
    >
      <Modal.Header className="tiktokModalHeader">
        <Header.Content>
          <label>TikTok Preferences</label>
        </Header.Content>
      </Modal.Header>

      <Modal.Content scrolling>
        <Grid className="tiktokPreferencesContent">
          {/* Account Info */}
          <TiktokAccountInfo avatarUrl={avatarUrl} nickName={nickName} />

          <TiktokPrivacyLevel
            privacyLevelOptions={privacyLevelOptions}
            privacyLevelSelected={privacyLevelSelected}
            onPressAction={selected => {
              setPrivacyLevelSelected({
                key: selected,
                text: capitalize(selected.replace(/_/g, " ")),
                value: selected,
              });
            }}
          />

          {/* Allows section */}
          <Grid.Row>
            <Grid className={"toggleGrid"}>
              {allowSettings.map(option => {
                return (
                  <Grid.Row className="floatedRight toggleRow">
                    <div key={"option" + option.key} className="toggleRight">
                      {/* <label className="toggleLabel">{option.label}</label> */}
                      <Radio
                        toggle
                        key={option.key}
                        label={option.label}
                        className={"settingsLevelLabel"}
                        onChange={(e: any, data: any) => {
                          option.updateAction(data.checked);
                        }}
                        name={option.key}
                        checked={option.value}
                        disabled={option.disabled}
                      />
                    </div>
                  </Grid.Row>
                );
              })}
            </Grid>
          </Grid.Row>

          <TiktokBrandedSection
            defaultBrandedContentDisclosure={brandedContentDisclosure}
            defaultBrandOption={brandedContentType}
            updateBrand={setBrandedContentType}
            updateBrandConfig={setBrandDisabled}
            disabled={loading || selfOnly}
          />
        </Grid>
      </Modal.Content>

      <Modal.Actions className="tiktokActions">
        <Button
          loading={loading}
          data-elm-id={`${baseDataElm}SavBtn`}
          disabled={privacyLevelSelected?.key == "none"}
          centered
          primary
          onClick={saveTiktokPreferences}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const TiktokPreferences = TiktokPreferencesClass;
