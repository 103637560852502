import { orderBy } from "lodash";
import { PromptCampaign } from "../interfaces";

export function sortPrompts(prompts: PromptCampaign[], deliveryRandom: boolean): PromptCampaign[] {
  if (!deliveryRandom) {
    const toSort = prompts.map(prompt => {
      return {
        ...prompt,
        deliveryDate: new Date(prompt.deliveryDatetimeUtc || ""),
      };
    });
    return orderBy(toSort, ["deliveryDate"], ["desc"]);
  } else {
    return prompts;
  }
}
