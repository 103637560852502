import * as React from "react";
import{ useIntl } from "react-intl";
import { Icon, Input } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { useSocialNetworks } from"../../../reducers";

import * as DTO from "../../../interfaces";
import { SocialGrid } from "../../../styling/baseStyle";
import {
  validAge,
  nowInUTC,
  filterPassedTime,
  changeLocalDateToPST,
  PSTimeZone,
  isToday,
  createVideoThumbnailBlob,
} from "../../../utils";
import { SocialNetworkIconButton } from "../../SocialNetworkIconButton";

import { descriptors, PromptsType } from "./descriptors";

import {
  ThumbnailPreview,
  PromptCardStyle,
  PromptHeader,
  AutoPostLabel,
  MultipleImagesCannotAutopostLabel,
  // PromptMessage,
} from "./styled";

import "../../../styling/datepicker.scss";
import "./styles.scss";

interface OwnProps {
  prompt: DTO.Prompt;
  campaignDeliveryMode: DTO.CampaignDeliveryMode;
  isAutoPost: boolean;
  isDragging?: boolean;
  deliveryDateUtc?: any;
  deliveryTimeUtc?: any;
  editable?: boolean;
  socialNetworks: string[];

  handleSetFieldValue: (field: string, value: any) => void;
}

type Props = OwnProps 

const PromptLargeCardFC: React.FC<Props> = ({
  prompt: { title, post, promptId },
  socialNetworks,
  campaignDeliveryMode,
  deliveryDateUtc,
  deliveryTimeUtc,
  isDragging = false,
  isAutoPost = false,
  editable = true,
 
  handleSetFieldValue,
}: Props) => {
  const [thumbnail, setThumbnail] = React.useState<string | undefined>(undefined);
  const [multiImage, setMultiImage] = React.useState<boolean | undefined>(undefined);
  const { formatMessage } = useIntl();
  const { sns, displayTikTok } = useSocialNetworks();
  const snKey = `${promptId}socialNetworks`;
  const timeKey = `${promptId}deliveryTimeUtc`;
  const dateKey = `${promptId}deliveryDateUtc`;
  const isOrderedRandom = campaignDeliveryMode === "ORDERED_RANDOM";
  const withPadding = isOrderedRandom && !isAutoPost;
  const scheduledCampaign = campaignDeliveryMode === "SPECIFIC_DATE";

  React.useEffect(() => {
    if (post?.media[0] && post?.media[0].uri) {
      const creativeUrl = post?.media[0].uri;
      const urlPromise =
        post?.media[0].category === "IMAGE" ? Promise.resolve(creativeUrl) : createVideoThumbnailBlob(creativeUrl);
      urlPromise.then(url => {
        setThumbnail(url);
      });

      if (post.media.length > 1) {
        handleSetFieldValue(snKey, []); // all social media autoposting is disabled
        setMultiImage(true);
      } else {
        setMultiImage(false);
      }
    } else {
      setMultiImage(false);
    }
  }, [post]);

  const dateSection = (dateEditable: boolean): JSX.Element => {
    moment.tz.setDefault(PSTimeZone);
    const promptDate = deliveryDateUtc ? new Date(deliveryDateUtc) : undefined;
    const promptTime = deliveryTimeUtc ? new Date(deliveryTimeUtc) : undefined;

    if (promptDate !== undefined) {
      const deliveryDate = moment(deliveryDateUtc).tz(PSTimeZone);
      promptDate.setDate(deliveryDate.date());
      promptDate.setMonth(deliveryDate.month());
    }
    if (promptTime !== undefined) {
      const deliveryTime = moment(deliveryTimeUtc).tz(PSTimeZone);

      promptTime.setHours(deliveryTime.hours());
      promptTime.setMinutes(deliveryTime.minutes());
    }

    const yesterday = new Date();
    yesterday.setDate(new Date().getDate() - 1);

    return (
      <SocialGrid>
        <SocialGrid.Row>
          <SocialGrid.Column width={8} className={"noPadding"}>
            <DatePicker
              customInput={<Input icon="calendar alternate outline" />}
              data-elm-id={"promptInputSelectedDate"}
              id={dateKey}
              name={dateKey}
              selected={promptDate}
              onChange={(date: any) => {
                if (date) {
                  handleSetFieldValue!(dateKey, changeLocalDateToPST(date));
                  if (isToday(date)) {
                    handleSetFieldValue!(timeKey, undefined);
                  }
                }
              }}
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={15}
              maxDate={validAge(yesterday, 1)}
              minDate={nowInUTC()}
              dateFormat="EE, MM/dd/yyyy"
              disabled={!dateEditable}
              autoComplete={"off"}
              placeholderText={formatMessage({ ...descriptors[PromptsType.datePlaceholder] })}
            />
          </SocialGrid.Column>
          <SocialGrid.Column width={7} className={"noPadding"}>
            <DatePicker
              customInput={<Input icon="clock outline" />}
              data-elm-id={"promptInputSelectedTime"}
              id={timeKey}
              name={timeKey}
              selected={promptTime}
              onChange={(date: any) => {
                if (date) {
                  handleSetFieldValue!(timeKey, changeLocalDateToPST(date));
                }
              }}
              showTimeSelect
              showTimeSelectOnly
              filterTime={(time: string | number | Date) => filterPassedTime(time, deliveryDateUtc, 1)}
              dateFormat="h:mm aa"
              autoComplete={"off"}
              disabled={!dateEditable}
              placeholderText={formatMessage({ ...descriptors[PromptsType.timePlaceholder] })}
            />
            <span className={"dateSpan"}>{formatMessage({ ...descriptors[PromptsType.pstZoneMessage] })}</span>
          </SocialGrid.Column>
        </SocialGrid.Row>
      </SocialGrid>
    );
  };

  const autoPostSection = (): JSX.Element => {
    if (multiImage) {
      return (
        <SocialGrid.Column width={3} className={"leftBigPadding"}>
          <MultipleImagesCannotAutopostLabel>
            This prompt contains multiple images and cannot be auto posted
          </MultipleImagesCannotAutopostLabel>
        </SocialGrid.Column>
      );
    } else {
      return (
        <SocialGrid.Column width={displayTikTok ? 4 : 3} className={"leftBigPadding withPointerEvents"}>
          <AutoPostLabel>{formatMessage({ ...descriptors[PromptsType.autoPost] })}</AutoPostLabel>
          <SocialGrid leftpadding={"s"} className={"smallPaddingTop socialSection"}>
            {sns.map((sn, index) => {
              const promptWithMedia = post?.media[0] && post?.media[0].uri;
              const verification = () => {
                if ((!promptWithMedia && (sn === "IG" || sn === "TT" || sn === "IG_STORY")) || !editable) {
                  return true;
                }

                return false;
              };

              return (
                <SocialNetworkIconButton
                  key={"prompt_card_" + index + sn}
                  disabled={verification()}
                  index={index + sn}
                  active={socialNetworks.includes(sn)}
                  networkId={sn}
                  onClickHandler={checked => {
                    let socialNetworksHash = {};
                    socialNetworks.map(
                      snId =>
                        (socialNetworksHash = {
                          ...socialNetworksHash,
                          [snId]: true,
                        })
                    );
                    const finalSn = {
                      ...socialNetworksHash,
                      [sn]: checked,
                    };
                    handleSetFieldValue(
                      snKey,
                      Object.keys(finalSn).filter(snId => finalSn[snId])
                    );
                  }}
                />
              );
            })}
          </SocialGrid>
        </SocialGrid.Column>
      );
    }
  };

  const adjustWidth = () => {
    if (isAutoPost && isOrderedRandom) {
      return 7;
    } else if (isAutoPost) {
      return 8;
    } else {
      return 11;
    }
  };

  return (
    <PromptCardStyle fluid className={isDragging ? "promptFluidCard dragging" : "promptFluidCard noBorderShadow"}>
      <PromptCardStyle.Content>
        <SocialGrid>
          <SocialGrid.Column width={4}>
            <ThumbnailPreview thumbnailurl={post?.media[0] && post?.media[0].uri ? thumbnail : undefined} />
          </SocialGrid.Column>
          <SocialGrid.Column width={adjustWidth()} className={withPadding ? "" : "noPaddingLateral"}>
            <PromptHeader>{title}</PromptHeader>
            {/* {!scheduledCampaign && <PromptMessage>{message}</PromptMessage>} */}
            {scheduledCampaign && dateSection(true)}
          </SocialGrid.Column>
          {isAutoPost && autoPostSection()}
          {isOrderedRandom && (
            <SocialGrid.Column width={1}>
              <Icon name={"align justify"} className={"sortIcon"} />
            </SocialGrid.Column>
          )}
        </SocialGrid>
      </PromptCardStyle.Content>
    </PromptCardStyle>
  );
};

export const PromptLargeCard = PromptLargeCardFC
