import { defineMessages } from "react-intl";

export enum CampaignsType {
  cancelBtn = "CampaignsType.cancelBtn",
  addCampaignBtn = "CampaignsType.addCampaignBtn",
  title = "CampaignsType.title",
}

export const en = {
  [CampaignsType.cancelBtn]: "Cancel",
  [CampaignsType.addCampaignBtn]: "Create a Campaign",
  [CampaignsType.title]: "Campaigns",
};

export const descriptors = defineMessages({
  [CampaignsType.cancelBtn]: {
    id: CampaignsType.cancelBtn,
    defaultMessage: en[CampaignsType.cancelBtn],
    description: "",
  },
  [CampaignsType.addCampaignBtn]: {
    id: CampaignsType.addCampaignBtn,
    defaultMessage: en[CampaignsType.addCampaignBtn],
    description: "",
  },

  [CampaignsType.title]: {
    id: CampaignsType.title,
    defaultMessage: en[CampaignsType.title],
    description: "title",
  },
});
