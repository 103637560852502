import * as Yup from "yup";
import { webUrlRegExp } from "../../../utils";
import { shortLink } from "../../../validations";

export const validationSchema = Yup.object().shape({
  shortLinkUpdated: Yup.boolean(),
  webAddress: Yup.string().when("shortLinkUpdated", {
    is: true,
    then: () => Yup.string()
      .lowercase()
      .trim()
      .required("Web address is required")
      .matches(webUrlRegExp, "Web address is invalid"),
  }),
  slug: Yup.string()
    .trim()
    .when("shortLinkUpdated", {
      is: true,
      then: () => shortLink.slug,
    }),
});
