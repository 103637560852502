import * as React from "react";
import{ useIntl } from "react-intl";
import { Grid } from "semantic-ui-react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import * as DTO from "../../../interfaces";
import { PromptServices } from "../../../services";
import { SearchComponent } from "../../Search";
import { PromptCell } from "../../Cells";

import PromptsList from "./components/PromptsList";

import "./styles.scss";

interface OwnProps {
  coachId: string;
}

type Props = OwnProps   & RouteComponentProps;

const PromptListContentFC: React.FC<Props> = ({ coachId }) => {
  const [promptList, setPromptList] = React.useState<DTO.Prompt[]>([]);
  const [welcomePrompt, setWelcomePrompt] = React.useState<DTO.Prompt | undefined>(undefined);

  React.useEffect(() => {
    const checkWelcomePrompts = async () => {
      setWelcomePrompt(await PromptServices.getWelcomePrompt(coachId));
    };
    checkWelcomePrompts();
  }, [coachId]);

  const promptsContent = (): JSX.Element => {
    return (
      <Grid>
        {welcomePrompt && (
          <Grid.Row>
            <Grid.Column className={"greyBox bigPadding"}>
              <div className="promptListContainer">
                <PromptCell prompt={welcomePrompt} />
              </div>
            </Grid.Column>
          </Grid.Row>
        )}

        <Grid.Row>
          <Grid.Column className={"greyBox bigPadding"}>
            <SearchComponent
              refreshData={setPromptList}
              content={<PromptsList promptList={promptList} />}
              searchFunction={PromptServices.searchPrompts}
              customSort={[
                { key: 1, text: "Title", value: "title" },
                { key: 2, text: "Created Date", value: "createdDate" },
                { key: 3, text: "Scheduled Send Date", value: "deliveryDatetimeUtc" },
              ]}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  return promptsContent();
};

export const PromptListContent = withRouter(PromptListContentFC)
