import { defineMessages } from "react-intl";

export enum TACType {
  tac = "SocialMediaStepType.tac",
  tacLink = "SocialMediaStepType.tacLink",
}

export const en = {
  [TACType.tac]: 'I’m accepting the {link}',
  [TACType.tacLink]: 'Accept {link}',
};

export const descriptors = defineMessages({
  [TACType.tac]: {
    id: TACType.tac,
    defaultMessage: en[TACType.tac],
    description: "terms text",
  },

  [TACType.tacLink]: {
    id: TACType.tacLink,
    defaultMessage: en[TACType.tacLink],
    description: "tacLink text",
  },
});
