import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useIntl } from "react-intl";
import { Grid, Loader, Message, Button } from "semantic-ui-react";
import { get } from "lodash";

import {
  SideBar,
  TitleSection,
  PlayersSegmentation,
  AutoSubscriptions,
  ConfirmationModal,
} from "../../../components";
import { AppContext } from "../../../providers";
import {
  Campaign,
  PromptCampaign,
  SelectedPrompts,
  CampaignRequest,
  CampaignStep,
  WebComponents,
} from "../../../interfaces";
import { CampaignServices } from "../../../services";
import { coachWithWritePermission, URLS } from "../../../utils";

import { BasicInfoStep } from "./components/BasicInfo";
import { PromptsStep } from "./components/Prompts";

import { CampaignPageType, descriptors } from "./descriptors";

import { CampaignReducer } from "./reducers";
import { actions } from "./reducers/actions";

import { WhiteContainer, ContentGridSection } from "../../../styling/baseStyle";
import "./styles.scss";

import { CampaignReview } from "./components/Review";

type Props = RouteComponentProps;

const CampaignFC: React.FC<Props> = ({ history }) => {
  const { formatMessage } = useIntl();
  const { userContext } = React.useContext(AppContext);
  const coachId = get(userContext, "coach.id", undefined);
  const basicInfoRef = React.useRef<HTMLFormElement>(null);
  const reviewRef = React.useRef<HTMLFormElement>(null);
  const withWritePermission = coachWithWritePermission(
    WebComponents.CAMPAIGNS,
    userContext
  );

  const [campaign, setCampaign] = React.useState<Campaign | undefined>(
    localStorage.getItem("coachCampaign")
      ? JSON.parse(localStorage.getItem("coachCampaign")!)
      : undefined
  );
  const segmentation = get(campaign, "eligibilityCriteria[0]", undefined);

  const [segmentedPlayers, setSegmentedPlayers] = React.useState<number[]>(
    get(segmentation, "values", [])
  );
  const [autoSubscribedPlayers, setAutoSubscribedPlayers] = React.useState<
    number[]
  >([]);

  const [isSegmented, setIsSegmented] = React.useState<boolean>(
    segmentation ? true : false
  );

  const [submitError, setSubmitError] = React.useState<string | undefined>(
    undefined
  );
  const [stepError, setStepError] = React.useState<string | undefined>(
    undefined
  );

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const [nextButtonDisabled, setNextButtonDisabled] =
    React.useState<boolean>(true);

  const stepsValidations: { [key: string]: CampaignStep } = {
    0: {
      validations: () =>
        new Promise((resolve, reject) => {
          if (basicInfoRef.current) {
            basicInfoRef!.current!.handleSubmit();
          }
          reject();
        }),
    },
    1: {
      validations: () =>
        new Promise((resolve, reject) => {
          if (
            campaign?.campaignPrompts &&
            campaign?.campaignPrompts.length > 0
          ) {
            resolve("");
          } else {
            reject(
              formatMessage({
                ...descriptors[CampaignPageType.promptStepError],
              })
            );
          }
        }),
    },
    2: {
      validations: () =>
        new Promise((resolve, reject) => {
          if (reviewRef.current) {
            reviewRef!.current!.handleSubmit();
          }
          reject();
        }),
    },
    3: {
      validations: () =>
        new Promise((resolve, reject) => {
          if (
            !isSegmented ||
            (campaign?.eligibilityCriteria &&
              campaign?.eligibilityCriteria![0].values!.length > 0)
          ) {
            resolve("");
          } else {
            reject(
              formatMessage({
                ...descriptors[CampaignPageType.segmentationStepError],
              })
            );
          }
        }),
    },
    4: {
      validations: () =>
        new Promise((resolve) => {
          resolve("");
        }),
    },
  };

  const [state, dispatch] = React.useReducer(CampaignReducer, {
    currentStep: Number(localStorage.getItem("coachNewCampaignStep")) || 0,
    openConfirmationModal: false,
    prepareConfirmationModal: false,
  });

  const currentStep = Number(state.currentStep);
  const noFirstStep = currentStep > 0;
  const finalStep = currentStep >= 4;

  const saveButtonTitle: string = formatMessage({
    ...descriptors[
      CampaignPageType[currentStep < 4 ? "nextBtn" : "publishBtn"]
    ],
  });

  React.useEffect(() => {
    setStepError(undefined);

    if (currentStep < 0) {
      history.push(URLS.coach.campaigns);
    } else {
      localStorage.setItem("coachNewCampaignStep", `${currentStep}`);
    }
  }, [state]);

  React.useEffect(() => {
    if (!isSegmented) {
      setStepError(undefined);
      setSegmentedPlayers([]);
      setCampaign({
        ...campaign!,
        eligibilityCriteria: undefined,
      });
    }
  }, [isSegmented]);

  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    if (
      window.confirm(
        'To view a previous campaign step, click the "Back" button on the screen. If you would like to cancel this campaign and go back to the menu, press OK'
      )
    ) {
      history.goBack();
    } else {
      window.history.pushState(null, "null", window.location.pathname);
    }
  };

  React.useEffect(() => {
    window.history.pushState(null, "null", window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  React.useEffect(() => {
    if (campaign) {
      localStorage.setItem("coachCampaign", JSON.stringify(campaign));
    }

    if (state.prepareConfirmationModal) {
      saveCampaign("UNPUBLISHED");
    }
  }, [campaign]);

  const saveCampaign = async (status: string = "ACTIVE"): Promise<any> => {
    try {
      if (campaign) {
        const {
          name,
          isAutoPost,
          campaignPrompts,
          deliveryMode,
          randomScheduleDays,
          randomScheduleDeliveryTimePst,
          categoryIds,
          eligibilityCriteria,
          startDate,
          endDate,
        } = campaign;

        const isScheduledDelivery = deliveryMode === "SPECIFIC_DATE";
        const prompts = campaignPrompts.map(
          (cprompt: PromptCampaign, index) => {
            return {
              promptId: cprompt.prompt!.promptId,
              socialNetworks: cprompt.socialNetworks,
              sortOrder: index,
              deliveryDatetimeUtc: isScheduledDelivery
                ? cprompt.deliveryDatetimeUtc
                : undefined,
            } as PromptCampaign;
          }
        );

        let campaignRequest: CampaignRequest = {
          name,
          isAutoPost,
          campaignPrompts: prompts,
          categoryIds,
          deliveryMode,
          status,
        };

        if (isSegmented) {
          campaignRequest = {
            ...campaignRequest,
            eligibilityCriteria,
          };
        }

        if (autoSubscribedPlayers.length > 0) {
          campaignRequest = {
            ...campaignRequest,
            subscriptionPlayerIds: autoSubscribedPlayers,
          };
        }
        if (!isScheduledDelivery) {
          campaignRequest = {
            ...campaignRequest,
            randomScheduleDays,
            startDate,
            endDate,
          };
          if (randomScheduleDeliveryTimePst) {
            campaignRequest = {
              ...campaignRequest,
              randomScheduleDeliveryTimePst,
            };
          }
        }

        setIsSubmitting(true);
        if (!isSubmitting) {
          CampaignServices.create(campaignRequest, coachId!!)
            .then((response) => {
              closeAndClean();
              setSubmitError(undefined);
            })
            .catch((e) => {
              setSubmitError(
                e.length < 150
                  ? e
                  : formatMessage({
                      ...descriptors[CampaignPageType.savingError],
                    })
              );
              dispatch({ name: actions.CLEAN_CONFIRMATION_MODAL });
            })
            .finally(() => {
              setIsSubmitting(false);
            });
        }
      }
    } catch (e: any) {
      setSubmitError(e);
    }
  };

  const onCloseModal = () => {
    dispatch({ name: actions.CLOSE_CONFIRMATION_MODAL });
  };

  const onCancelAction = () => {
    dispatch({ name: actions.OPEN_CONFIRMATION_MODAL });
  };

  const onBackAction = () => {
    setStepError(undefined);
    setSubmitError(undefined);
    dispatch({ name: actions.PREVIOUS_STEP });
  };

  const handleSubmitFormPromise = (): Promise<any> => {
    return stepsValidations[currentStep].validations();
  };

  const prepareForSave = () => {
    if (currentStep <= 4) {
      handleSubmitFormPromise()
        .then((e) => {
          saveCampaign("UNPUBLISHED");
        })
        .catch((e) => {
          if (e && e !== "") {
            setSubmitError(e);
            setStepError(undefined);
          }
        });
    }
  };

  const onNextButtonAction = () => {
    setSubmitError(undefined);
    if (currentStep <= 4) {
      handleSubmitFormPromise()
        .then((e) => {
          nextStep();
        })
        .catch((e) => {
          if (e && e !== "") {
            setStepError(e);
          }
        });
    }
  };

  const nextStep = (step: number = currentStep + 1) => {
    localStorage.setItem("coachNewCampaignStep", `${step}`);
    if (currentStep === 4) {
      saveCampaign();
    }
    dispatch({ name: actions.NEXT_STEP });
  };

  const closeAndClean = () => {
    localStorage.removeItem("coachNewCampaignStep");
    localStorage.removeItem("coachCampaign");
    dispatch({ name: actions.CANCEL });
  };

  const basicInfoSuccessHandler = (updatedCampaign: Campaign) => {
    setCampaign(updatedCampaign);
    // HERE
    nextStep(1);
  };

  const promptsSuccessHandler = (selectedPrompts: SelectedPrompts) => {
    if (Object.keys(selectedPrompts).length > 0) {
      setStepError(undefined);
    }

    const campaignPrompts = Object.keys(selectedPrompts).map((promptId) => {
      const isPrevious = campaign?.campaignPrompts.filter(
        (c) => `${c.prompt!.promptId}` === `${promptId}`
      );
      const customP = selectedPrompts[promptId].prompt;
      return {
        promptId: promptId!,
        title: selectedPrompts[promptId].title,
        post: selectedPrompts[promptId].post,
        prompt: {
          ...selectedPrompts[promptId].prompt,
          deliveryDatetimeUtc:
            isPrevious && isPrevious!.length > 0
              ? isPrevious[0].deliveryDatetimeUtc
              : undefined,
        },
        deliveryDatetimeUtc:
          isPrevious && isPrevious!.length > 0
            ? isPrevious[0].deliveryDatetimeUtc
            : undefined,
        socialNetworks: campaign!.socialNetworks
          ? Object.keys(campaign!.socialNetworks).filter((sn) => {
              return (
                campaign!.socialNetworks &&
                campaign!.socialNetworks[sn] &&
                ((customP!.post?.media[0] && customP!.post?.media[0].uri) ||
                  (sn !== "IG" && sn !== "TT" && sn !== "IG_STORY"))
              );
            })
          : [],
      } as PromptCampaign;
    });

    setCampaign((c) => {
      return { ...c!, campaignPrompts };
    });
  };

  const reviewSuccessHandler = (prompts: PromptCampaign[]) => {
    setCampaign({
      ...campaign!,
      campaignPrompts: prompts,
    });

    if (!state.prepareConfirmationModal) {
      nextStep(3);
    }
  };

  const segmentationOnChangeHandler = (
    playersId: number[],
    isInclude: boolean
  ) => {
    setSegmentedPlayers(playersId);
    setAutoSubscribedPlayers([]);
    setCampaign({
      ...campaign!,
      eligibilityCriteria: [
        {
          values: playersId,
          level: "PLAYER_ID",
          isInclude,
        },
      ],
      subscribedPlayers: [],
    });
  };

  const subscriptionOnChangeHandler = (playersId: number[]) => {
    setAutoSubscribedPlayers(playersId);
    setCampaign({
      ...campaign!,
      subscribedPlayers: playersId,
    });
  };

  const campaignsPromptsConverted = (): SelectedPrompts => {
    if (campaign?.campaignPrompts) {
      let selectedPrompts: SelectedPrompts = {};
      campaign?.campaignPrompts.map((cprompt) => {
        const { title, post, categories } = cprompt.prompt!;
        selectedPrompts = {
          ...selectedPrompts,
          [cprompt.promptId]: {
            title,
            post,
            prompt: cprompt.prompt,
            categoryIds: categories
              ? categories!.map((category) => category!.categoryId!)
              : [],
          },
        };
      });
      return selectedPrompts;
    }

    return {};
  };

  const stepSection = (): JSX.Element => {
    switch (currentStep) {
      case 0:
        return (
          <BasicInfoStep
            basicInfoSubmitRef={basicInfoRef}
            campaign={campaign}
            onValidationHandler={(withErrors) => {
              setNextButtonDisabled(withErrors);
            }}
            onSuccessHandler={basicInfoSuccessHandler}
          />
        );
      case 1:
        return (
          <PromptsStep
            coachId={coachId!}
            campaignPrompts={campaignsPromptsConverted()}
            onChangeHandler={promptsSuccessHandler}
          />
        );
      case 2:
        return (
          <CampaignReview
            submitRef={reviewRef}
            campaignDeliveryMode={campaign!.deliveryMode}
            campaignPrompts={campaign!.campaignPrompts}
            isAutoPost={campaign!.isAutoPost}
            onSuccessHandler={reviewSuccessHandler}
            onErrorHandler={onCloseModal}
            goBackHandler={onBackAction}
          />
        );
      case 3:
        return (
          <PlayersSegmentation
            disabled={!withWritePermission}
            segmentedCampaign={isSegmented}
            selectedPlayers={segmentedPlayers}
            onChange={segmentationOnChangeHandler}
            criteria={
              get(segmentation, "isInclude", true) ? "include" : "exclude"
            }
            isSegmentedOnChange={setIsSegmented}
          />
        );
      default:
        return (
          <AutoSubscriptions
            disabled={!withWritePermission}
            segmentedPlayers={segmentedPlayers}
            isInclude={get(segmentation, "isInclude", true)}
            selectedPlayers={autoSubscribedPlayers}
            onChange={subscriptionOnChangeHandler}
          />
        );
    }
  };

  const customLinkSection = (): JSX.Element => {
    return (
      <Grid.Column
        width={noFirstStep ? (finalStep ? 9 : 11) : 4}
        floated="right"
        className="noMarginTop"
      >
        <Button
          className={"secondary buttonWithLateralPadding"}
          onClick={onCancelAction}
        >
          {formatMessage({ ...descriptors[CampaignPageType.cancelBtn] })}
        </Button>
        {noFirstStep && (
          <Button
            data-elm-id={`campaignBackBtn`}
            className={"secondary buttonWithLateralPadding"}
            onClick={onBackAction}
          >
            {formatMessage({ ...descriptors[CampaignPageType.backBtn] })}
          </Button>
        )}
      </Grid.Column>
    );
  };

  const confirmationModal = (): JSX.Element => {
    return (
      <ConfirmationModal
        openConfirmationModal={state.openConfirmationModal}
        onClose={onCloseModal}
        okHandler={() => {
          dispatch({ name: actions.ACCEPT_CONFIRMATION_MODAL });
          prepareForSave();
        }}
        rejectHandler={closeAndClean}
      />
    );
  };

  return (
    <WhiteContainer>
      <SideBar history={history} />

      <ContentGridSection columns={1}>
        <TitleSection
          title={formatMessage({ ...descriptors[CampaignPageType.title] })}
          ready={true}
          customLinkSection={customLinkSection()}
          customNextLabel={saveButtonTitle}
          disableButtons={nextButtonDisabled}
          titleSize={finalStep ? 6 : 8}
          buttonsSize={finalStep ? 10 : 8}
          nextButtonSize={noFirstStep && !finalStep ? 5 : 7}
          handleNextAction={onNextButtonAction}
          showLoading={false}
          showLinkOption={withWritePermission}
          showNextOption={withWritePermission}
        />

        {(stepError || submitError) && (
          <Grid.Row>
            <Message
              className={"leftMargin"}
              error
              list={[submitError, stepError]}
            />
          </Grid.Row>
        )}
        <Grid.Row className={"campaignFormSection"}>
          <Grid.Column width={16} className={"formContainer"}>
            {isSubmitting ? <Loader active size={"large"} /> : stepSection()}
          </Grid.Column>
        </Grid.Row>

        {confirmationModal()}
      </ContentGridSection>
    </WhiteContainer>
  );
};

export const CampaignPage = withRouter(CampaignFC);
