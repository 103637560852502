import { Upload } from "tus-js-client";
import axios from "axios";

const headerDelete = (accessToken: string) => ({
  Accept: "application/vnd.vimeo.*+json;version=3.4",
  Authorization: `bearer ${accessToken}`,
  "Content-Type": "application/x-www-form-urlencode",
});

const headerPatch = {
  "Tus-Resumable": "1.0.0",
  "Upload-Offset": 0,
  "Content-Type": "application/offset+octet-stream",
  Accept: "application/vnd.vimeo.*+json;version=3.4",
};

const headerPost = (accessToken: string) => ({
  Accept: "application/vnd.vimeo.*+json;version=3.4",
  Authorization: `bearer ${accessToken}`,
  "Content-Type": "application/json",
});

const defaultOnError = (error: Error) => {
  throw error;
};

interface DurationOnSuccess {
  duration?: any;
}

interface OnSuccessReponse {
  url: string;
  html: string;
  extension: string;
}

const upload = async (
  accessToken: string,
  file: File,
  fileName: string,
  folderId: string,
  onSuccess: (body: OnSuccessReponse) => any,
  onError?: (error: Error) => void,
  onProgress?: (progress: number) => void
) => {
  const fileSize = file.size.toString();

  axios({
    method: "post",
    url: `https://api.vimeo.com/me/videos`,
    headers: headerPost(accessToken),
    data: {
      upload: {
        approach: "tus",
        size: fileSize,
      },
    },
  })
    .then(response => {
      const uploadRequest: Upload = new Upload(file, {
        endpoint: "https://api.vimeo.com/me/videos",
        uploadUrl: response.data.upload.upload_link,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: fileName,
          filetype: file.type,
        },
        headers: {},
        onError: onError || defaultOnError,
        onProgress: (bytesUploaded: number, bytesTotal: number) => {
          const progress = (bytesUploaded / bytesTotal) * 100;
          if (onProgress) {
            return onProgress(progress);
          }
        },
        onSuccess: () => {
          axios({
            method: "put",
            url: `https://api.vimeo.com/me/projects/${folderId}${response.data.uri}`,
            headers: headerPost(accessToken),
          });
          return onSuccess({
            url: response.data.link,
            html: response.data.embed.html,
            extension: file.type,
          });
        },
      });

      uploadRequest.start();
    })
    .catch(onError);
  // });
};

function dataURLtoBlob(dataurl: string) {
  const arr = dataurl.split(",");
  const mime = arr[0]!!.match(/:(.*?);/)!![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

function createVideoThumbnailBlob(videoFile: string): Promise<string> {
  const videoElement = document.createElement("video");
  videoElement.classList.add("media-item");
  return new Promise<string>((resolve, reject) => {
    videoElement.addEventListener(
      "loadeddata",
      () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d")!!;
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
        videoElement.pause();
        const dataURI = canvas.toDataURL("image/jpeg");
        resolve(dataURI);
      },
      false
    );

    videoElement.preload = "metadata";
    videoElement.muted = true;
    videoElement.setAttribute("crossOrigin", "anonymous");
    videoElement.src = videoFile;
    videoElement.play();
  });
}

interface DimensionObject {
  width: number;
  height: number;
}
function getVideoDimensions(videoFile: string): Promise<DimensionObject> {
  const videoElement = document.createElement("video");
  videoElement.classList.add("media-item");

  return new Promise<DimensionObject>((resolve, reject) => {
    videoElement.addEventListener(
      "loadeddata",
      () => {
        resolve({
          width: videoElement.videoWidth,
          height: videoElement.videoHeight,
        });
      },
      false
    );

    videoElement.preload = "metadata";
    videoElement.muted = true;
    videoElement.setAttribute("crossOrigin", "anonymous");
    videoElement.src = videoFile;
    videoElement.play();
  });
}

export { upload, createVideoThumbnailBlob, getVideoDimensions, dataURLtoBlob };
