import { defineMessages } from "react-intl";

export enum CoachSummaryType {
  title = "CoachSummaryType.title",
  payButton = "CoachSummaryType.payButton",
  planIncludes = "CoachSummaryType.planIncludes",
  planFrecuency = "CoachSummaryType.planFrecuency",
  planPayFrecuency = "CoachSummaryType.planPayFrecuency",
  secureMessage = "CoachSummaryType.secureMessage",
  subscriptionFor = "CoachSummaryType.subscriptionFor",
  processing = "CoachSummaryType.processing",
  noPlanError = "CoachSummaryType.noPlanError",
  startTrialButton = "CoachSummaryType.startTrialButton",
  passwordMin = "CoachSummaryType.passwordMin",
  passwordWithNumber = "CoachSummaryType.passwordNumber",
  passwordWithSpecialCharacter = "CoachSummaryType.passwordSpecialCharacter",
  passwordWithCapital = "CoachSummaryType.passwordCapital",

  updatePaymentButton = "CoachSummaryType.updatePaymentButton",
}

export const en = {
  [CoachSummaryType.title]: "Payment Details",
  [CoachSummaryType.payButton]: "Subscribe Now",
  [CoachSummaryType.startTrialButton]: "Start Free {trialDays} Day Trial",
  [CoachSummaryType.planIncludes]: "Plan Includes:",
  [CoachSummaryType.planFrecuency]: "{interval} Payment",
  [CoachSummaryType.planPayFrecuency]: "Pay {interval}",
  [CoachSummaryType.secureMessage]: "This is a secure 128-SSL encrypted connection.",
  [CoachSummaryType.subscriptionFor]: "Subscription for",
  [CoachSummaryType.processing]: "Processing...",
  [CoachSummaryType.noPlanError]: " NO PLAN / COACH ASSOCIATED ",
  [CoachSummaryType.passwordMin]: "Password must be at least 8 characters.",
  [CoachSummaryType.passwordWithCapital]: "Password must be at least 1 capital letter.",
  [CoachSummaryType.passwordWithNumber]: "Password must be at least 1 numeric value.",
  [CoachSummaryType.passwordWithSpecialCharacter]: "Password must be at least 1 special character.",

  [CoachSummaryType.updatePaymentButton]: "Update Credit Card",
};

export const descriptors = defineMessages({
  [CoachSummaryType.title]: {
    id: CoachSummaryType.title,
    defaultMessage: en[CoachSummaryType.title],
    description: "-",
  },
  [CoachSummaryType.planFrecuency]: {
    id: CoachSummaryType.planFrecuency,
    defaultMessage: en[CoachSummaryType.planFrecuency],
    description: "-",
  },
  [CoachSummaryType.payButton]: {
    id: CoachSummaryType.payButton,
    defaultMessage: en[CoachSummaryType.payButton],
    description: "-",
  },
  [CoachSummaryType.startTrialButton]: {
    id: CoachSummaryType.startTrialButton,
    defaultMessage: en[CoachSummaryType.startTrialButton],
    description: "-",
  },
  [CoachSummaryType.planPayFrecuency]: {
    id: CoachSummaryType.planPayFrecuency,
    defaultMessage: en[CoachSummaryType.planPayFrecuency],
    description: "-",
  },
  [CoachSummaryType.planIncludes]: {
    id: CoachSummaryType.planIncludes,
    defaultMessage: en[CoachSummaryType.planIncludes],
    description: "-",
  },
  [CoachSummaryType.secureMessage]: {
    id: CoachSummaryType.secureMessage,
    defaultMessage: en[CoachSummaryType.secureMessage],
    description: "-",
  },
  [CoachSummaryType.subscriptionFor]: {
    id: CoachSummaryType.subscriptionFor,
    defaultMessage: en[CoachSummaryType.subscriptionFor],
    description: "-",
  },
  [CoachSummaryType.processing]: {
    id: CoachSummaryType.processing,
    defaultMessage: en[CoachSummaryType.processing],
    description: "-",
  },
  [CoachSummaryType.noPlanError]: {
    id: CoachSummaryType.noPlanError,
    defaultMessage: en[CoachSummaryType.noPlanError],
    description: "-",
  },
  [CoachSummaryType.passwordMin]: {
    id: CoachSummaryType.passwordMin,
    defaultMessage: en[CoachSummaryType.passwordMin],
    description: "passwordMin",
  },
  [CoachSummaryType.passwordWithCapital]: {
    id: CoachSummaryType.passwordWithCapital,
    defaultMessage: en[CoachSummaryType.passwordWithCapital],
    description: "passwordWithCapital text",
  },
  [CoachSummaryType.passwordWithNumber]: {
    id: CoachSummaryType.passwordWithNumber,
    defaultMessage: en[CoachSummaryType.passwordWithNumber],
    description: "passwordWithNumber",
  },
  [CoachSummaryType.passwordWithSpecialCharacter]: {
    id: CoachSummaryType.passwordWithSpecialCharacter,
    defaultMessage: en[CoachSummaryType.passwordWithSpecialCharacter],
    description: "passwordWithSpecialCharacter",
  },
  [CoachSummaryType.updatePaymentButton]: {
    id: CoachSummaryType.updatePaymentButton,
    defaultMessage: en[CoachSummaryType.updatePaymentButton],
    description: "updatePaymentButton",
  },
});
