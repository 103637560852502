import { ProfileInformationType } from "./descriptors";
import { FormField } from "../../../../interfaces";

export const fields: FormField[] = [
  {
    id: "firstName",
    name: "firstName",
    type: "Input",
    label: "First Name",
    placeholder: ProfileInformationType.firstName,
    disabled: false,
  },
  {
    id: "lastName",
    name: "lastName",
    type: "Input",
    label: "Last Name",
    placeholder: ProfileInformationType.lastName,
    disabled: false,
  },
];

export const questionsFields: FormField[] = [
  {
    id: "transactionsClosedLastYear",
    name: "transactionsClosedLastYear",
    type: "number",
    answerType: "number",
    label: "Transactions Closed",
    placeholder: ProfileInformationType.transactions,
    disabled: false,
  },
  {
    id: "averageIncomesPerTransaction",
    name: "averageIncomesPerTransaction",
    type: "number",
    answerType: "float",
    label: "Average Income",
    placeholder: ProfileInformationType.income,
    disabled: false,
  },
  {
    id: "leadsNeedToCloseTransaction",
    name: "leadsNeedToCloseTransaction",
    type: "number",
    answerType: "number",
    label: "Leads To Transaction",
    placeholder: ProfileInformationType.leads,
    disabled: false,
  },
];
