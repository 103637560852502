import * as Yup from "yup";
import { UsernameLinkId, FinalMessageId } from "./descriptors";
import { validRoute } from "../../../utils";
import { values, flatten, isObject } from "lodash";
import { URLS } from "../../../utils/routes";

export const validationCoachingPageSchema = Yup.object().shape({
  [UsernameLinkId]: Yup.string()
    .trim()
    .required("Shared Link is required")
    .min(3, "Shared Link must be at least 3 characters long")
    .test("test-valid-route", "Shared link already used", value => {
      return !validRoute(flatten(values(URLS).map(url => (isObject(url) ? values(url) : url))), value);
    })
    .matches(/^[a-z0-9]+$/, "Shared link username must have only number and letters"),
  [FinalMessageId]: Yup.string()
    .trim()
    .required("Message to Players is required")
    .max(2000, "Message to Players must be at most 2000 characters long")
    .min(3, "Message to Players must be at least 3 characters long"),
});
