import { defineMessages } from "react-intl";

export enum DashboardType {
  welcome = "DashboardType.welcome",
  watchMessage = "DashboardType.watchMessage",

  remittanceSectionTitle = "DashboardType.remittanceSectionTitle",
  remittanceSectionSubtitle = "DashboardType.remittanceSectionSubtitle",

  playerSectionTitle = "DashboardType.playerSectionTitle",
  playerSectionSubtitle = "DashboardType.playerSectionSubtitle",
  playerSectionInfo = "DashboardType.playerSectionInfo",

  promptEngagementSectionTitle = "DashboardType.promptEngagementSectionTitle",
  promptEngagementSectionSubtitle = "DashboardType.promptEngagementSectionSubtitle",

  lifetimeRevenue = "DashboardType.lifetimeRevenue",
  mrr = "DashboardType.mrr",
  monthToDay = "DashboardType.monthToDay",
  pendingPrompts = "DashboardType.nextRemittance",
  playersCanceled = "DashboardType.playersCanceled",
  playersTrouble = "DashboardType.playersTrouble",
  playersActive = "DashboardType.playersActive",
  lifetime = "DashboardType.lifetime",

  published = "DashboardType.promptPublished",
  completedPrompt = "DashboardType.completedPrompt",
  promptCreated = "DashboardType.promptCreated",
  mtd = "DashboardType.mtd",

  average = "DashboardType.average",
  lifeAverage = "DashboardType.lifeAverage",

  leaderBoard = "DashboardType.leaderBoard",
}

export const en = {
  [DashboardType.welcome]: "Hi {firstname}, Welcome to your Dashboard!",
  [DashboardType.watchMessage]: "Watch this short tutorial to get to know your way around SocialCoach",

  [DashboardType.remittanceSectionTitle]: "Earnings",
  [DashboardType.remittanceSectionSubtitle]: "Successful charges from your subscribed Players",
  [DashboardType.playerSectionTitle]: "Players",
  [DashboardType.playerSectionSubtitle]: "Summary of the status of your Players",
  [DashboardType.playerSectionInfo]:
    "Note for Admins: These numbers reflect your organization’s entire account and not necessarily the number of Players you have access to as an Admin.",
  [DashboardType.promptEngagementSectionTitle]: "Prompt Engagement",
  [DashboardType.promptEngagementSectionSubtitle]: "Summary of your Prompt performance",

  [DashboardType.lifetimeRevenue]: "Lifetime Revenue",
  [DashboardType.mrr]: "MRR",
  [DashboardType.monthToDay]: "Month to Date",
  [DashboardType.pendingPrompts]: "Pending Prompts",

  [DashboardType.playersCanceled]: "Canceled",
  [DashboardType.playersTrouble]: "Trouble",
  [DashboardType.playersActive]: "Active",
  [DashboardType.lifetime]: "Lifetime",

  [DashboardType.published]: "Published",
  [DashboardType.completedPrompt]: "Completed Prompt",
  [DashboardType.promptCreated]: "Created",
  [DashboardType.mtd]: "mtd",

  [DashboardType.average]: "average",
  [DashboardType.lifeAverage]: "lifetime average",
  [DashboardType.leaderBoard]: "Leaderboard",
};

export const descriptors = defineMessages({
  [DashboardType.welcome]: {
    id: DashboardType.welcome,
    defaultMessage: en[DashboardType.welcome],
    description: "-",
  },
  [DashboardType.leaderBoard]: {
    id: DashboardType.leaderBoard,
    defaultMessage: en[DashboardType.leaderBoard],
    description: "-",
  },
  [DashboardType.watchMessage]: {
    id: DashboardType.watchMessage,
    defaultMessage: en[DashboardType.watchMessage],
    description: "-",
  },
  [DashboardType.mtd]: {
    id: DashboardType.mtd,
    defaultMessage: en[DashboardType.mtd],
    description: "-",
  },
  [DashboardType.promptCreated]: {
    id: DashboardType.promptCreated,
    defaultMessage: en[DashboardType.promptCreated],
    description: "-",
  },
  [DashboardType.remittanceSectionTitle]: {
    id: DashboardType.remittanceSectionTitle,
    defaultMessage: en[DashboardType.remittanceSectionTitle],
    description: "-",
  },
  [DashboardType.remittanceSectionSubtitle]: {
    id: DashboardType.remittanceSectionSubtitle,
    defaultMessage: en[DashboardType.remittanceSectionSubtitle],
    description: "-",
  },
  [DashboardType.playerSectionTitle]: {
    id: DashboardType.playerSectionTitle,
    defaultMessage: en[DashboardType.playerSectionTitle],
    description: "-",
  },
  [DashboardType.playerSectionInfo]: {
    id: DashboardType.playerSectionInfo,
    defaultMessage: en[DashboardType.playerSectionInfo],
    description: "-",
  },
  [DashboardType.playerSectionSubtitle]: {
    id: DashboardType.playerSectionSubtitle,
    defaultMessage: en[DashboardType.playerSectionSubtitle],
    description: "-",
  },
  [DashboardType.promptEngagementSectionTitle]: {
    id: DashboardType.promptEngagementSectionTitle,
    defaultMessage: en[DashboardType.promptEngagementSectionTitle],
    description: "-",
  },

  [DashboardType.promptEngagementSectionSubtitle]: {
    id: DashboardType.promptEngagementSectionSubtitle,
    defaultMessage: en[DashboardType.promptEngagementSectionSubtitle],
    description: "-",
  },
  [DashboardType.lifetimeRevenue]: {
    id: DashboardType.lifetimeRevenue,
    defaultMessage: en[DashboardType.lifetimeRevenue],
    description: "-",
  },
  [DashboardType.mrr]: {
    id: DashboardType.mrr,
    defaultMessage: en[DashboardType.mrr],
    description: "-",
  },
  [DashboardType.monthToDay]: {
    id: DashboardType.monthToDay,
    defaultMessage: en[DashboardType.monthToDay],
    description: "-",
  },
  [DashboardType.pendingPrompts]: {
    id: DashboardType.pendingPrompts,
    defaultMessage: en[DashboardType.pendingPrompts],
    description: "-",
  },
  [DashboardType.playersCanceled]: {
    id: DashboardType.playersCanceled,
    defaultMessage: en[DashboardType.playersCanceled],
    description: "-",
  },
  [DashboardType.playersActive]: {
    id: DashboardType.playersActive,
    defaultMessage: en[DashboardType.playersActive],
    description: "-",
  },
  [DashboardType.playersTrouble]: {
    id: DashboardType.playersTrouble,
    defaultMessage: en[DashboardType.playersTrouble],
    description: "-",
  },
  [DashboardType.lifetime]: {
    id: DashboardType.lifetime,
    defaultMessage: en[DashboardType.lifetime],
    description: "-",
  },
  [DashboardType.completedPrompt]: {
    id: DashboardType.completedPrompt,
    defaultMessage: en[DashboardType.completedPrompt],
    description: "-",
  },
  [DashboardType.published]: {
    id: DashboardType.published,
    defaultMessage: en[DashboardType.published],
    description: "-",
  },

  [DashboardType.lifeAverage]: {
    id: DashboardType.lifeAverage,
    defaultMessage: en[DashboardType.lifeAverage],
    description: "-",
  },
  [DashboardType.average]: {
    id: DashboardType.average,
    defaultMessage: en[DashboardType.average],
    description: "-",
  },
});
