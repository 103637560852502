import * as React from "react";
import { Form, TextArea } from "semantic-ui-react";
import { useDrag } from "react-dnd";
import { ItemTypes } from "../QuestionCard";

interface Props {
  disabled: boolean;
  value: string;
  onChange: (value: any) => void;
  onDrag: (index: number) => void;
  index: number;
  id: string;
}

export default ({ disabled, value, onChange, onDrag, index, id }: Props) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
    begin: () => onDrag(index),
  });

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}>
      <Form>
        <TextArea
          id={id}
          ref={input => input && !disabled && input.focus()}
          placeholder="Question"
          value={value}
          disabled={disabled}
          rows={5}
          onChange={onChange}
          style={{ cursor: "move" }}
        />
      </Form>
    </div>
  );
};
