import { ConfigProperties } from "./interfaces/customTypes/configProperties";

const defaultConfigProperties: ConfigProperties = {
  iOSUrl: "https://apps.apple.com/us/app/",
  androidUrl: "https://play.google.com/store/",
  post: {
    twitter_max_length: 280,
    linkedin_max_length: 4000,
    instagram_max_length: 2200,
    facebook_max_length: 49999,
    tiktok_max_length: 2200,
  },
  socialNetworks: {
    twitter: {
      api_key: "mooHTgPxRN3UEOaEdkiSjwXzH",
      access_token: "1513588314615410690-mCMG0XVekKmAkoeAwGLuUNTlBJ28Qy",
      callback: "http://localhost:3000/twitter/callback",
      tokenUrl: "http://localhost:3000/auth/twitter/request_token",
    },
    facebook: {
      api_key: "727377321446324",
    },
    linkedin: {
      api_key: "86cwlyj3abtdlq",
      callback: "http://localhost:3000/linkedin/callback",
      permissions: [
        "r_1st_connections_size",
        "r_organization_social",
        "w_member_social",
        "w_organization_social",
        "r_basicprofile",
        "r_emailaddress",
        "rw_organization_admin",
        "r_organization_admin",
      ],
    },
    instagram: {
      api_key: "320629722423042",
      callback: "https://localhost/instagram/callback",
      permissions: [
        "email",
        "instagram_basic",
        "instagram_manage_insights",
        "pages_show_list",
        "instagram_content_publish",
        "pages_read_engagement",
        "ads_management",
        "business_management",
      ],
    },
    tiktok: {
      api_key: "awuvdotzwrbk27nh",
      app_id: "7222938204306196486",
      callback: "https://dev.socialcoach.io/auth/tiktok/",
      permissions: ["user.info.basic", "video.list", "video.upload", "video.publish"],
    },
  },
  dashboardYoutubeId: "D7IQS4kS8_8",
  firebase: {
    api_key: "",
    auth_domain: "socialcoach-prod-d87a5.firebaseapp.com",
    data_base_url: "https://socialcoach-prod-d87a5.firebaseio.com",
    project_id: "socialcoach-prod-d87a5",
    storage_bucket: "socialcoach-prod-d87a5.appspot.com",
    messaging_sender_id: "956615638261",
    app_id: "1:956615638261:web:d2f9f6f968126d28cd99bc",
    measurement_id: "G-N9WFB7MCPR",
    baseImagesFolder: "local",
  },
  questions: [
    {
      answerType: "integer",
      content: "First, can you tell me how many transactions did you close last year?",
      contentDescriptor: "Number of transactions you closed last year",
      key: "transactionsClosedLastYear",
    },
    {
      answerType: "float",
      content: "What is your average income?",
      contentDescriptor: "Amount you make on each transaction",
      key: "averageIncomesPerTransaction",
    },
    {
      answerType: "integer",
      content: "How many leads you need in order to close a transaction?",
      contentDescriptor: "Number of leads you need in order to close a transaction",
      key: "leadsNeedToCloseTransaction",
    },
  ],
  questionsLimits: {
    transactionsClosedLastYear: 9999999,
    averageIncomesPerTransaction: 9999999,
    leadsNeedToCloseTransaction: 9999999,
  },
  stripe: {
    public_key:
      "pk_test_51GqJiHFFM1tojnilhvtudHvPAFVJ4gNWZwWAXW3txWNsEoggnQlGZEBmxZGfsqbtwwHeNFbiE6eN2OlZjJHGFkVp00ebe2Acnz",
  },
  vimeo: {
    access_token: "7db024488189ded920108f769ff8e2c5",
    folder_id: "2066860",
  },
  deployBaseUrl: "https://dev.socialcoach.io/",
  defaultComplianceDisclaimer: "Loan Officer NMLS# 1234567, Corp NMLS# 123456…",
  slugBaseUrl: "https://dev.myoc.io/",
  randomPrompts: {
    minimumDaysconfigurable: 1,
    defaultDeliveryTime: "08:00AM",
    minActivePrompts: 3,
    welcomePromptRequired: false,
  },
  coachFacebookScopes: "email",
  playerFacebookScopes:
    "public_profile,email,pages_manage_posts,pages_show_list,pages_read_engagement,pages_read_user_content",
  promptMaxLength: 63206,
  trialDays: 14,
  keywordMaxLength: 100,
  googleAnalyticsId: "G-68MJL9X8MC",
  captchaSiteKey: "6LebivkZAAAAADxqtDhZHJWgM7A_qZ3tYKfVvy-Y",
};

export default defaultConfigProperties;
