import * as React from "react";
import { differenceWith, intersectionWith, isEqual } from "lodash";
import { Dropdown, DropdownItemProps, Grid } from "semantic-ui-react";
import "../../styles.scss";

interface OwnProps {
  placeHolder: string;
  type: string;

  setFunction: (results: string[]) => void;
  // data: any, selectedFilter: DropdownItemProps
  elements: DropdownItemProps[];
  defaultElementsItems?: DropdownItemProps[];
  selectedElements?: string[];
}

type Props = OwnProps;

const SegmentationDropDownFC: React.FC<Props> = ({
  placeHolder,
  elements,
  selectedElements,
  defaultElementsItems = [],
  setFunction,
}) => {
  const dropDownProps = {
    className: "segmentedInput",
    multiple: true,
  };

  const selectedElementsNumbers = selectedElements
    ? selectedElements?.map((e) => Number(e))
    : [];
  const excludedSelectedElements = differenceWith(
    defaultElementsItems,
    elements,
    isEqual
  ).map((e) => e.text);
  const editableElements = intersectionWith(
    elements,
    defaultElementsItems,
    isEqual
  ).map((e) => Number(e.value));

  return (
    <Grid.Column>
      <label className={"permissionLabel"}>{placeHolder}</label>
      <Dropdown
        upward={true}
        header={<Dropdown.Header icon="filter" content={placeHolder} />}
        disabled={!(elements && elements?.length > 0)}
        placeholder={placeHolder}
        fluid
        selection
        options={elements}
        defaultValue={editableElements}
        value={selectedElementsNumbers}
        onChange={(e, data) => setFunction(data.value as string[])}
        {...dropDownProps}
      />
      {excludedSelectedElements.length > 0 && (
        <label className={"segmentationDesc"}>
          {"This subcoach includes additional " +
            placeHolder +
            ": " +
            excludedSelectedElements}
        </label>
      )}
    </Grid.Column>
  );
};

export const SegmentationDropDown = SegmentationDropDownFC;
