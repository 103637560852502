import * as React from "react";
import{ useIntl } from "react-intl";
import { Grid } from "semantic-ui-react";

import * as DTO from "../../../interfaces";
import { categoriesToFilter } from "../../../utils";

import { CategoriesServices, CampaignServices } from "../../../services";
import { SearchComponent, CampaignsSection, CampaignsSearchList } from "../../../components";

import "./styles.scss";

interface OwnProps {
  coachId: string;
}

type Props = OwnProps 

const CampaignsByCategoryFC: React.FC<Props> = ({ coachId }) => {
  const [searchedCampaignList, setSearchedCampaignList] = React.useState<DTO.CampaignResume[]>([]);
  const [searchActive, setSearchActive] = React.useState<boolean>(false);

  const [categories, setCategories] = React.useState<DTO.Category[]>([]);
  const [categoriesFilters, setCategoriesFilters] = React.useState<DTO.FilterType[]>([]);

  React.useEffect(() => {
    const getCategories = async () => {
      const result = await CategoriesServices.get(coachId, "CAMPAIGN");
      setCategories(() => result);
      setCategoriesFilters(categoriesToFilter(result));
    };

    getCategories();
  }, [coachId]);

  return (
    <Grid className={"campaignListComponentContainer"} data-elm-id="campaignListComponent">
      <Grid.Row>
        <Grid.Column>
          <SearchComponent
            refreshData={setSearchedCampaignList}
            refreshAllDataToExport={result => {
              if (result) {
                setSearchedCampaignList(() => result!);
              } else {
                setSearchedCampaignList(() => []);
              }
            }}
            hideWhenNotSearch={true}
            filterPlaceholder={"Group by categories"}
            customSize={50}
            content={
              searchedCampaignList && (
                <CampaignsSearchList categories={categories} campaignList={searchedCampaignList} coachId={coachId} />
              )
            }
            searchFunction={CampaignServices.searchCampaigns}
            showSortBy={false}
            filters={categoriesFilters}
            pageable={false}
            onSearchedHandler={setSearchActive}
          />

          {categories && !searchActive && <CampaignsSection categories={categories} coachId={coachId} />}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const CampaignListContent = CampaignsByCategoryFC
