import * as React from "react";

import * as DTO from "../../interfaces";
import { EditorVideoQueueListComponent } from "../../components/Lists";
import { Checkbox, Grid, Menu } from "semantic-ui-react";

export const EditorVideoQueueList = () => {
  const [includeDemoProjects, setIncludeDemoProjects] = React.useState<boolean>(
    localStorage.getItem("includeDemoProjects") === "true"
  );

  return (
    <div className="promptListContainer">
      {/* Show demo projects filter */}
      <Menu secondary className={"filterMenu"}>
        <Menu.Item className={"searchMenu"}>
          <Grid.Column width={4}>
            <Checkbox
              label="Show test/demo projects"
              checked={includeDemoProjects}
              onChange={(data, e) => {
                setIncludeDemoProjects(e.checked!!);
                localStorage.setItem("includeDemoProjects", `${e.checked}`);
              }}
            />
          </Grid.Column>
        </Menu.Item>
      </Menu>

      {/* Needs editing */}
      <EditorVideoQueueListComponent
        key={"editor_video_queue_pending"}
        includeDemoProjects={includeDemoProjects}
        statuses={[DTO.VideoProjectStatus.SUBMITTED_FOR_EDITING]}
        showError={error => {
          alert(error);
        }}
      />

      {/* Editing Complete (Last 30 days) */}
      <EditorVideoQueueListComponent
        key={"editor_video_queue_completed"}
        includeDemoProjects={includeDemoProjects}
        statuses={[
          DTO.VideoProjectStatus.READY_TO_POST,
          DTO.VideoProjectStatus.POSTED,
          DTO.VideoProjectStatus.SCHEDULED,
          DTO.VideoProjectStatus.POST_FAILED,
        ]}
        showError={error => {
          alert(error);
        }}
      />
    </div>
  );
};
