import styled from "styled-components";
import { Grid, Modal, Radio, Button } from "semantic-ui-react";

import { theme } from "../../../../../styling/theme";

export const ModalCentered = styled(Modal)({
  margin: "0 auto",
});

export const ModalSection = styled(Modal.Content)({
  background: `${theme.backgroundColor} !important`,
  padding: "45px !important",
  boxShadow: "0 10px 10px 0 rgba(0, 0, 0, 0.16)",
});

export const DescriptionSection = styled(Grid.Column)({
  borderRadius: "10px",
  boxShadow: "3px 4px 10px 0 rgba(215, 221, 234, 0.69)",
  paddingTop: "27px",
  backgroundColor: "white",
  margin: "0 auto",
  // color: ${(props: any) => (props.styles.color ? props.styles.color : 'white')}
});

export const ModalHeader = styled(Modal.Header)({
  color: `${theme.titleColor} !important`,
  textAlign: "left",
  fontSize: "28px",
  lineHeight: "34px",
  paddingBottom: "20px !important",
  // color: ${(props: any) => (props.styles.color ? props.styles.color : 'white')}
});

export const ModalDescription = styled(Modal.Description)`
  margin: ${(props: any) => (props.centered ? "0 auto" : "auto")};
`;

export const BulkContainer = styled(Grid)`
  &&& {
    margin: 20px 0px;
  }

  background-color: ${theme.backgroundColor};
`;
export const ContainerGrid = styled(Grid.Row)`
  background: ${(props: any) => (props.color ? props.color : "transparent")};
`;

export const ContainerContent = styled(Grid.Column)`
  background: ${(props: any) => (props.color ? props.color : "transparent")};
`;

export const SelectRadio = styled(Radio)``;

export const SocialButton = styled(Button)`
  &&& {
    margin: 20px auto;
  }
`;
