import * as React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableInput from "../DraggableInput";
import { Question, getQuestionId } from "../../";
import QuestionCard from "../QuestionCard";
import { Card, Grid } from "semantic-ui-react";
import { isUndefined } from "lodash";
import { transformQuestions } from "../..";

interface Props {
  questions: Question[];
  setQuestions: (questions: Question[]) => void;
  handleChange: any;
  setValues: any;
}

export default ({ questions, setQuestions, handleChange, setValues }: Props) => {
  const onChangeQuestion = (index: number, text: string) => {
    questions[index] = { ...questions[index], text };
    setQuestions([...questions]);
  };

  const onDeleteQuestionBuilder = (index: number) => {
    return () => {
      questions.splice(index, 1);
      setQuestions([...questions]);
    };
  };

  const onEditQuestionBuilder = (index: number) => {
    return () => {
      questions.forEach(question => {
        question.disabled = true;
      });
      questions[index] = { ...questions[index], disabled: !questions[index].disabled };
      setQuestions([...questions]);
    };
  };

  let indexDragged: number | undefined;

  const onDropBuilder = (dropIndex: number) => {
    const changedQuestions = [...questions];

    if (!isUndefined(indexDragged)) {
      const copyQuestion = { ...changedQuestions[indexDragged] };
      let i = indexDragged;
      while (i < dropIndex) {
        changedQuestions[i] = changedQuestions[++i];
      }
      while (i > dropIndex) {
        changedQuestions[i] = changedQuestions[--i];
      }
      changedQuestions[dropIndex] = copyQuestion;
    }

    return [...changedQuestions];
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Grid.Column>
        <Card.Group data-elm-id="question-list">
          {questions.map((question, index) => (
            <QuestionCard
              header={`Question ${index + 1}`}
              key={index}
              data-elm-id="question-list-element"
              onDelete={onDeleteQuestionBuilder(index)}
              onEdit={onEditQuestionBuilder(index)}
              onDrop={() => {
                const changedQuestions = onDropBuilder(index);
                setValues(transformQuestions(changedQuestions));
                setQuestions(changedQuestions);
              }}>
              <DraggableInput
                disabled={question.disabled}
                id={getQuestionId(index)}
                onChange={e => {
                  handleChange(e);
                  const value = e.target.value;
                  onChangeQuestion(index, value);
                }}
                value={question.text}
                index={index}
                onDrag={(inputIndex: number) => {
                  indexDragged = inputIndex;
                }}
              />
            </QuestionCard>
          ))}
        </Card.Group>
      </Grid.Column>
    </DndProvider>
  );
};
