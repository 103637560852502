import * as React from "react";
import{ useIntl } from "react-intl";
import { Image, Table, Message } from "semantic-ui-react";

import * as DTO from "../../interfaces/dtos";
import { descriptors, PlayersType } from "./descriptors";
import { Svg } from "../../assets/svg";
import { TitleSubtitleCell } from "../Cells";
import { userFullName } from "../../utils";
import { playerListReducer } from "./reducer";

import "./styles.scss";
import { SocialNetworksSection } from "../SocialNetworksSection";

interface OwnProps {
  playerResult: DTO.PlayerWithCampaignStats[];
  socialNetworks: DTO.SocialNetworksId[];
}

type Props = OwnProps 

const PlayersListWithCampaignFC: React.FC<Props> = ({ playerResult, socialNetworks }) => {
  const { formatMessage } = useIntl();
  const [state, dispatch] = React.useReducer(playerListReducer, {
    column: null,
    data: playerResult,
    direction: null,
  });

  React.useEffect(() => {
    dispatch({ type: "REFRESH_DATA", column: null, data: playerResult });
  }, [playerResult]);

  const { column, data, direction } = state;

  const contentSimple = (player: DTO.PlayerWithCampaignStats): JSX.Element => {
    const { email, photoUrl, info, countPromptReceived, countPromptPosted, countPromptRemaining } = player;
    const playerSnIds = info.socialNetworks?.map(sn => sn.networkId);
    return (
      <Table.Row key={"list_with_campaigns_" + info.playerId}>
        <Table.Cell>
          <Image circular size="mini" src={photoUrl ? photoUrl : Svg.noImageuser} />
        </Table.Cell>
        <TitleSubtitleCell
          title={email}
          subtitle={userFullName(info)}
          subcontent={<SocialNetworksSection actives={playerSnIds} allSocialNetworks={socialNetworks} />}
        />
        <Table.Cell>{countPromptReceived && countPromptReceived}</Table.Cell>
        <Table.Cell>{countPromptPosted && countPromptPosted}</Table.Cell>
        <Table.Cell>{countPromptRemaining && countPromptRemaining}</Table.Cell>
      </Table.Row>
    );
  };

  return (
    <div className="playerStatsListContainer">
      {data && data.length > 0 ? (
        <Table sortable striped size="large">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1} />
              <Table.HeaderCell>{formatMessage({ ...descriptors[PlayersType.nameHeader] })}</Table.HeaderCell>
              <Table.HeaderCell
                width={3}
                sorted={column === "countPromptReceived" ? direction : null}
                onClick={() => dispatch({ type: "CHANGE_SORT", column: "countPromptReceived" })}>
                # Prompts
                <br />
                Received
              </Table.HeaderCell>
              <Table.HeaderCell
                width={3}
                sorted={column === "countPromptPosted" ? direction : null}
                onClick={() => dispatch({ type: "CHANGE_SORT", column: "countPromptPosted" })}>
                # Completed
                <br />
                Prompts
              </Table.HeaderCell>
              <Table.HeaderCell
                width={3}
                sorted={column === "countPromptRemaining" ? direction : null}
                onClick={() => dispatch({ type: "CHANGE_SORT", column: "countPromptRemaining" })}>
                # Remaining
                <br />
                Prompts
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{data.map((playerR: DTO.PlayerWithStats) => contentSimple(playerR))}</Table.Body>
        </Table>
      ) : (
        <Message
          icon="search"
          header="No results"
          content={formatMessage({ ...descriptors[PlayersType.noResultsMessage] })}
          size="large"
        />
      )}
    </div>
  );
};

export const PlayersListWithCampaignStats = PlayersListWithCampaignFC
