import { defineMessages } from "react-intl";

export enum ErrorType {
  title = "SocialForm.title",
  socialNetworksRequired = "SocialForm.socialnetworksRequired",
  invalidUser = "SocialForm.invalidUser",
}

export const en = {
  [ErrorType.title]: "Title is Required",
  [ErrorType.socialNetworksRequired]: "You must be connected at least to one social network",
  [ErrorType.invalidUser]: "Invalid user",
};

export const descriptors = defineMessages({
  [ErrorType.title]: {
    id: ErrorType.title,
    defaultMessage: en[ErrorType.title],
    description: "Title",
  },
  [ErrorType.socialNetworksRequired]: {
    id: ErrorType.socialNetworksRequired,
    defaultMessage: en[ErrorType.socialNetworksRequired],
    description: "social Networks required",
  },
  [ErrorType.invalidUser]: {
    id: ErrorType.invalidUser,
    defaultMessage: en[ErrorType.invalidUser],
    description: "invalidUser text",
  },
});
