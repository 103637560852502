import { defineMessages } from "react-intl";

export enum IGPagesModalType {
  title = "IGPagesModalType.title",

  selectYourIGPage = "IGPagesModalType.selectYourIGPage",
  yesBtn = "IGPagesModalType.yesBtn",
  noBtn = "IGPagesModalType.noBtn",
}

export const en = {
  [IGPagesModalType.title]: "",
  [IGPagesModalType.selectYourIGPage]: "Select an Instagram account to link with SocialCoach",
  [IGPagesModalType.yesBtn]: "OK",
  [IGPagesModalType.noBtn]: "Cancel",
};

export const descriptors = defineMessages({
  [IGPagesModalType.title]: {
    id: IGPagesModalType.title,
    defaultMessage: en[IGPagesModalType.title],
    description: "-",
  },

  [IGPagesModalType.selectYourIGPage]: {
    id: IGPagesModalType.selectYourIGPage,
    defaultMessage: en[IGPagesModalType.selectYourIGPage],
    description: "-",
  },

  [IGPagesModalType.yesBtn]: {
    id: IGPagesModalType.yesBtn,
    defaultMessage: en[IGPagesModalType.yesBtn],
    description: "-",
  },
  [IGPagesModalType.noBtn]: {
    id: IGPagesModalType.noBtn,
    defaultMessage: en[IGPagesModalType.noBtn],
    description: "-",
  },
});
