import * as React from "react";
import { Grid, Icon, TextArea } from "semantic-ui-react";
import Linkify from "react-linkify";
import { CustomModal } from "../../../../CustomModal";
import { VideoProjectServices } from "../../../../../services";
import { ClientNotes } from "../../../../../interfaces";
import TextareaAutosize from "react-textarea-autosize";

import "./styles.scss";

interface Props {
  playerId?: number;
  clientNotes?: ClientNotes;
}

const ClientNotesFC: React.FC<Props> = ({ playerId, clientNotes }) => {
  const [showNotesModal, setShowNotesModal] = React.useState<boolean>(false);
  const [notes, setNotes] = React.useState<string>(clientNotes ? clientNotes.notes : "");
  const [savedNotes, setSavedNotes] = React.useState<string>(clientNotes ? clientNotes.notes : "");

  const clientNotesTextArea = (disabled: boolean): JSX.Element => {
    return disabled ? (
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target="blank" className={"clientNotesLink"} href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )}
      >
        {notes}
      </Linkify>
    ) : (
      <TextareaAutosize
        data-elm-id={"clientNotesInput"}
        className="clientNotesField"
        placeholder={"Add client notes..."}
        rows={3}
        disabled={disabled}
        value={notes}
        onChange={data => {
          setNotes(data.currentTarget.value);
        }}
      />
    );
  };

  const updateClientNotes = () => {
    VideoProjectServices.addClientNotes(playerId!!, notes).then(() => {
      setShowNotesModal(false);
      setSavedNotes(notes);
    });
  };

  const withNotes = savedNotes !== "";
  return (
    <>
      <Grid.Row className={"notesSection"}>
        {!withNotes && (
          <span
            className={"addClientNotesBtn"}
            data-elm-id={"addClientNotesBtn"}
            onClick={() => setShowNotesModal(true)}
          >
            Add Client Notes
          </span>
        )}
        {withNotes && (
          <Grid>
            <Grid.Row className="clientNotesTitleGrid noPadding">
              <label className={"small"}>Notes</label>
              <Icon
                className={"editNotesIcon"}
                size={"small"}
                name={"pencil"}
                onClick={() => setShowNotesModal(true)}
              />
            </Grid.Row>
            <Grid.Row className="clientNotesClickable">{clientNotesTextArea(true)}</Grid.Row>
          </Grid>
        )}
      </Grid.Row>

      {showNotesModal && (
        <CustomModal
          title="Edit Client Notes"
          acceptLabel="Save"
          acceptHandler={updateClientNotes}
          rejectHandler={() => setShowNotesModal(false)}
        >
          {clientNotesTextArea(false)}
        </CustomModal>
      )}
    </>
  );
};

export const ClientNotesComponent = ClientNotesFC;
