import * as React from "react";
import { Loader } from "semantic-ui-react";

import { PostFeedCell } from "../PostFeedCell";
import * as DTO from "../../../../interfaces";
import { useSocialNetworks } from "../../../../reducers";
import { PostServices } from "../../../../services";

import { RequestChangesModal } from "../RequestChangesModal";

import "./styles.scss";
import { getFullName } from "../../../../utils";
import { PostReviewEvent } from "../../../../interfaces";
import { ReviewHistoryModal } from "../ReviewHistoryModal";

interface ReviewModalElement {
  postId: number;
  playerFullName: string;
  postSnUrls: string[];
}

interface OwnProps {
  postList: DTO.Post[];
  players: { [key: string]: DTO.PlayerAccount };
  exportFilters?: DTO.SearchFilter;
  exportOrder?: DTO.OrderType;

  refresh: () => void;
}

export const PostFeed: React.FC<OwnProps> = ({ postList, players, refresh }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [reviewModalElement, setReviewModalElements] = React.useState<ReviewModalElement | undefined>(undefined);
  const [historyEvents, setHistoryEvents] = React.useState<PostReviewEvent[] | undefined>(undefined);
  const [showHistoryModal, setShowHistoryModal] = React.useState<boolean>(false);

  const { sns } = useSocialNetworks();
  const changeReviewStatus = (postId: number, status: any, changesRequested?: string): void => {
    setLoading(true);
    PostServices.setReviewStatus(postId, status, changesRequested)
      .then(() => {
        alert("Post Status Updated");
      })
      .catch(e => {
        alert(e.message);
      })
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };

  return (
    <div className="postFeedContainer">
      {postList.map(post => (
        <PostFeedCell
          key={post.postId}
          player={players[post.playerId]}
          post={post}
          activeSocialNetworks={sns}
          onShowHistory={events => {
            setHistoryEvents(events);
            setShowHistoryModal(true);
          }}
          changeReviewStatus={(status, postSnUrls) => {
            if (status === "REVIEWED") {
              changeReviewStatus(post.postId, status);
            } else if (status === "REQUEST_CHANGES") {
              setReviewModalElements({
                postId: post!.postId,
                playerFullName: getFullName(players[post.playerId]?.firstName, players[post.playerId]?.lastName),
                postSnUrls,
              });
            } else if (status === "COMPLETED") {
              if (window.confirm("Are you sure you want to complete this post review?")) {
                changeReviewStatus(post.postId, status);
              } else {
                refresh();
              }
            }
          }}
        />
      ))}

      <ReviewHistoryModal
        opened={showHistoryModal}
        title={"Compliance Review History"}
        events={historyEvents}
        closeHandler={() => setShowHistoryModal(false)}
      />

      {reviewModalElement && (
        <RequestChangesModal
          opened={true}
          title={"Enter the changes your Player must make to this post."}
          postId={reviewModalElement!.postId}
          playerName={reviewModalElement!.playerFullName}
          postLinks={reviewModalElement.postSnUrls || []}
          rejectHandler={() => {
            setReviewModalElements(undefined);
            refresh();
          }}
          acceptHandler={(requestedChanges, postId) => {
            setReviewModalElements(undefined);
            changeReviewStatus(postId, "REQUEST_CHANGES", requestedChanges);
          }}
        />
      )}
      {loading && <Loader active size="large" />}
    </div>
  );
};
