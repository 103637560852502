import * as React from "react";

import { Grid, Image } from "semantic-ui-react";
import { Svg } from "../../../../assets";

import { ConfigContext } from "../../../../contexts/appContexts";
import { ConfigProperties } from "../../../../interfaces";

interface OwnProps {}

const IntegratedDownloadAppInformation: React.FC<OwnProps> = ({}) => {
  const config: ConfigProperties = React.useContext(ConfigContext);

  return (
    <Grid className={"marginCentered basicPadding"}>
      <Grid.Row centered={true}>
        <Grid.Column width={7}>
          <a href={config.iOSUrl} data-elm-id="playerVerifiediOSIcon" target="_blank">
            <Image src={Svg.AppStore} className={"storeIcons centered"} />
          </a>
        </Grid.Column>
        <Grid.Column width={7}>
          <a href={config.androidUrl} data-elm-id="playerVerifiedAndroidIcon" target="_blank">
            <Image src={Svg.PlayStore} className={"storeIcons centered"} />
          </a>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered={true}>
        {" "}
        <p>Download the app to start posting!</p>
      </Grid.Row>
    </Grid>
  );
};

export const DownloadAppInformation = IntegratedDownloadAppInformation
