import * as React from "react";
import { Header, Table, Icon, Label } from "semantic-ui-react";

import { textWithLimit } from "../../../utils";

interface Props {
  title?: string;
  subtitle?: string;

  clickeable?: boolean;
  tag?: string;
  subcontent?: any;
  maxLength?: number;
  onClick?: () => void;
}

const TitleSubtitleFC: React.FC<Props> = ({
  title,
  subtitle,
  subcontent,
  tag,
  onClick,
  clickeable = true,
  maxLength = 65,
}) => {
  return (
    <Table.Cell
      data-elm-id={`titleCellUserInfo`}
      className={clickeable ? "titleClickable flexCell" : "flexCell"}
      onClick={onClick}>
      <Header as="h4" image>
        <Header.Content data-elm-id={`titleCellHeader`}>
          {title && textWithLimit(title, maxLength)}
          {subtitle && (
            <Header.Subheader>
              {textWithLimit(subtitle, 60)}{" "}
              {tag && (
                <Label className="leftMargin" as="a" tag>
                  {tag}
                </Label>
              )}{" "}
            </Header.Subheader>
          )}
          {subcontent}
        </Header.Content>
        {onClick && <Icon className={"miniPencil"} size={"mini"} name={"pencil"} />}
      </Header>
    </Table.Cell>
  );
};

export const TitleSubtitleCell = TitleSubtitleFC;
