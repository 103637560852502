import { defineMessages } from "react-intl";

export enum CampaignValidation {
  title = "CampaignValidation.title",
  subtitle = "CampaignValidation.subtitle",
  description = "CampaignValidation.description",
}

export const en = {
  [CampaignValidation.title]: "Campaign Segmentation -",
  [CampaignValidation.subtitle]: " Auto Subscribe Players",

  [CampaignValidation.description]: "Do you want to manually subscribe any players to this campaign?",
};

export const descriptors = defineMessages({
  [CampaignValidation.title]: {
    id: CampaignValidation.title,
    defaultMessage: en[CampaignValidation.title],
    description: "-",
  },
  [CampaignValidation.subtitle]: {
    id: CampaignValidation.subtitle,
    defaultMessage: en[CampaignValidation.subtitle],
    description: "-",
  },
  [CampaignValidation.description]: {
    id: CampaignValidation.description,
    defaultMessage: en[CampaignValidation.description],
    description: "-",
  },
});
