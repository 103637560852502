import * as React from "react";
import { useIntl } from "react-intl";
import { Header } from "semantic-ui-react";
import { head } from "lodash";

import * as DTO from "../../interfaces";
import { PlayersListWithBranch } from "..";
import { PlayersServices } from "../../services";
import {
  TransparentContainer,
  ContentGrid,
  SocialGrid,
  SegmentedGrid,
} from "../../styling/baseStyle";

import { BranchSearch } from "../BranchSearch";

import { CampaignValidation, descriptors } from "./descriptors";
import "./styles.scss";

interface OwnProps {
  selectedPlayers: number[];
  segmentedPlayers?: number[];
  isInclude?: boolean;
  disabled: boolean;
  onChange: (selectedPlayers: number[]) => void;
}

type Props = OwnProps; 

const AutoSubscriptionsFC: React.FC<Props> = ({
  segmentedPlayers,
  selectedPlayers,
  isInclude = true,
  disabled,
  onChange,
}) => {
  const [playersProcessList, setPlayersProcessList] = React.useState<
    DTO.PlayerWithBranch[]
  >([]);
  const { formatMessage } = useIntl();
  const preparePlayerList = (
    playersList: DTO.SubscribedPlayerSearchResponse[]
  ): void => {
    const playerWithStatsResponse: DTO.PlayerWithBranch[] = playersList.map(
      (player) => {
        return {
          info: player.playerAccount,
          photoUrl: player.playerAccount.photoUrl,
          email: player.playerAccount.email,
          branch: head(player.branchNames),
          region: head(player.regionNames),
          state: head(player.stateCodes),
        } as DTO.PlayerWithBranch;
      }
    );
    setPlayersProcessList(() => playerWithStatsResponse);
  };

  return (
    <TransparentContainer>
      <SegmentedGrid className={"subscriptionContent"} columns={1}>
        <SocialGrid.Row>
          <Header as="h4">
            {" "}
            {formatMessage({ ...descriptors[CampaignValidation.title] })}
            <span>
              {formatMessage({ ...descriptors[CampaignValidation.subtitle] })}
            </span>
          </Header>
        </SocialGrid.Row>
        <SocialGrid.Row>
          <p>
            {formatMessage({ ...descriptors[CampaignValidation.description] })}
          </p>
        </SocialGrid.Row>
        <ContentGrid.Row className={"playersListRowContainer"}>
          <SocialGrid.Column>
            <SocialGrid>
              <SocialGrid.Row className={disabled ? "disabledForm" : ""}>
                <ContentGrid.Column>
                  <BranchSearch
                    refreshData={preparePlayerList}
                    segmentedPlayers={segmentedPlayers}
                    isInclude={isInclude}
                    content={
                      <PlayersListWithBranch
                        players={playersProcessList}
                        selectedPlayers={selectedPlayers}
                        showState={true}
                        onChangeSelectedPlayer={(
                          playerIds: number[],
                          checked: boolean
                        ) => {
                          let newSelected = selectedPlayers;
                          if (checked) {
                            playerIds.forEach((playerId) =>
                              newSelected.push(playerId)
                            );
                          } else {
                            newSelected = newSelected?.filter((pId) => {
                              return !playerIds.includes(pId);
                            });
                          }
                          onChange(newSelected);
                        }}
                      />
                    }
                    searchFunction={
                      PlayersServices.searchSubscribedPlayersByTag
                    }
                    multiple={true}
                  />
                </ContentGrid.Column>
              </SocialGrid.Row>
            </SocialGrid>
          </SocialGrid.Column>
        </ContentGrid.Row>
      </SegmentedGrid>
    </TransparentContainer>
  );
};

export const AutoSubscriptions = AutoSubscriptionsFC;
