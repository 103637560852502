import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { Checkbox, Grid } from "semantic-ui-react";

import { URLS } from "../../utils";

import { TACType, descriptors } from "./descriptors";

import "./styles.scss";

interface OwnProps {
  /**
   * Accept terms and conditions handler
   */
  handleTacChange: (checked: boolean) => void;
  style?: "link" | "normal";
  dataElmId?: string;
}

type Props = OwnProps & RouteComponentProps;

const TACFC: React.FC<Props> = ({ dataElmId = "-", style = "link", handleTacChange }) => {
  const { formatMessage } = useIntl();
  const toTerms = (): void => {
    window.open(URLS.terms);
  };

  const intl = useIntl();
  return (
    <Grid.Row className={"termsContainer"}>
      <Checkbox
        className={"checkboxTerms"}
        label={""}
        data-elm-id={dataElmId + "-checkboxTerms"}
        onChange={(_, data) => {
          handleTacChange(data.checked || false);
        }}
      />

      <span data-elm-id={dataElmId + "LinkTerms"} onClick={toTerms}>
        <FormattedMessage
           {...descriptors[style === "link" ? TACType.tacLink : TACType.tac]}
          //  id={style === "link" ? "TACType.tacLink" : "TACType.tac"}
          //  defaultMessage={formatMessage({ ...descriptors[style === "link" ? TACType.tacLink : TACType.tac] })}
          values={{ link: <span className={style === "link" ? "underline" : "blueLink"}>Terms & Conditions</span> }}
        />
      </span>
    </Grid.Row>
  );
};

export const TacSection = withRouter(TACFC);
