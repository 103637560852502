import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { URLS } from "../../../utils";

import { AppContext, PlayerAppContext } from "../../../providers";
import { PlayerSignUpStep, PlayersServices } from "../../../services";

import { FloatedContainer } from "../../../styling/baseStyle";
import { FirstSocialNetworksForm } from "../../FormikForms/FirstSocialNetworks";
import { BubbleDetails } from "../../../components";

const IntegratedFirstSocialNetworksPage: React.FC<RouteComponentProps> = () => {
  const { userContext } = React.useContext(AppContext);
  const playerId = userContext?.player?.id;
  const { coachInformation, setCoachInformation } = React.useContext(PlayerAppContext);

  React.useEffect(() => {
    if (userContext?.signUpStep! === PlayerSignUpStep[PlayerSignUpStep.PLAYER_BUSINESS_DATA]) {
      window.location.assign(URLS.player.dashboard);
    }
    // Get Coach Information
    PlayersServices.getCoachData(userContext!.player!.id!).then(coachInfo => {
      setCoachInformation(coachInfo);
    });
  }, [playerId]);

  const successHandler = () => {
    // history.push(URLS.player.profile);
  };

  return (
    <FloatedContainer>
      <div className={"socialContainer"}>
        <FirstSocialNetworksForm coachInfo={coachInformation} successHandler={successHandler} />
      </div>
      {coachInformation && (
        <BubbleDetails
          messages={[
            {
              message: "Awesome! Before you begin posting, we’ll need to connect your social accounts.",
            },
            {
              message:
                "Keep in mind that in order to post to Facebook and Instagram, you’ll need to have a Facebook Business Page and an Instagram Professional account.",
            },
          ]}
          currentPosition={0}
          coachInfo={coachInformation?.coach}
        />
      )}
    </FloatedContainer>
  );
};

export const FirstSocialNetworksPage = withRouter(IntegratedFirstSocialNetworksPage);
