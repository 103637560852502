import React, { useState } from "react";
import { Form } from "formik";
import { Button, Message, Modal } from "semantic-ui-react";

import "./styles.scss";

interface OwnProps {
  children?: any;
  formikRef: any;
  errors?: string[];

  updateStep: (step: number) => void;
}

export const FormStepper: React.FC<OwnProps> = ({ children, formikRef, errors, updateStep }) => {
  const stepsArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const [showErrors, setShowErrors] = useState(false);
  const currentStep = stepsArray[step];
  const totalSteps = stepsArray.length - 1;
  const formSubmitRef = React.useRef<HTMLFormElement>(null);

  const filteredErrors = errors?.filter(e => !e.includes("detected"));

  return (
    <div className={"rePostFormContainer rePostFormStepsContainer"}>
      {showErrors && filteredErrors && filteredErrors?.length > 0 && <Message error list={filteredErrors} />}
      <Form ref={formSubmitRef} className={"ui large form rePostFormStepsContainer rePostFormContainer"}>
        {/* CONTENT */}
        <div className="currentStepContainer">{currentStep}</div>
        <Modal.Actions className="actionsContainer">
          {step > 0 && (
            <Button
              data-elm-id={"backBtn"}
              secondary={true}
              onClick={() => {
                setStep(step - 1);
                updateStep(step - 1);
                setShowErrors(false);
              }}
            >
              Back
            </Button>
          )}

          {step < totalSteps && (
            <Button
              data-elm-id={"nextBtn"}
              primary
              onClick={async () => {
                formikRef.current.validateForm().then((errors: any) => {
                  if (Object.values(errors).length === 0) {
                    setStep(step + 1);
                    updateStep(step + 1);
                    setShowErrors(false);
                  } else {
                    setShowErrors(true);
                  }
                });
              }}
            >
              Next
            </Button>
          )}
          {step === totalSteps && (
            <Button primary type="submit">
              Next
            </Button>
          )}
        </Modal.Actions>
      </Form>
    </div>
  );
};
