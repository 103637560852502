import { defineMessages } from "react-intl";

export enum PromptsType {
  createdTitle = "CoachingProfile.createdTitle",
  specificDateTitleFuture = "CoachingProfile.specificDateTitleFuture",
  specificDateTitlePass = "CoachingProfile.specificDateTitlePass",
  randomTitle = "CoachingProfile.randomTitle",
  welcomePromptTitle = "CoachingProfile.welcomePromptTitle",
  welcomePromptSubtitle = "CoachingProfile.welcomePromptSubtitle",
}

export const en = {
  [PromptsType.createdTitle]: "Created ",
  [PromptsType.specificDateTitleFuture]: "To be sent on:",
  [PromptsType.specificDateTitlePass]: "Sent on:",
  [PromptsType.randomTitle]: "Sent randomly",
  [PromptsType.welcomePromptTitle]: "First prompt seen by all new players",
  [PromptsType.welcomePromptSubtitle]: "Welcome Prompt",
};

export const descriptors = defineMessages({
  [PromptsType.welcomePromptTitle]: {
    id: PromptsType.welcomePromptTitle,
    defaultMessage: en[PromptsType.welcomePromptTitle],
    description: "-",
  },
  [PromptsType.welcomePromptSubtitle]: {
    id: PromptsType.welcomePromptSubtitle,
    defaultMessage: en[PromptsType.welcomePromptSubtitle],
    description: "-",
  },
  [PromptsType.createdTitle]: {
    id: PromptsType.createdTitle,
    defaultMessage: en[PromptsType.createdTitle],
    description: "-",
  },
  [PromptsType.specificDateTitleFuture]: {
    id: PromptsType.specificDateTitleFuture,
    defaultMessage: en[PromptsType.specificDateTitleFuture],
    description: "-",
  },
  [PromptsType.specificDateTitlePass]: {
    id: PromptsType.specificDateTitlePass,
    defaultMessage: en[PromptsType.specificDateTitlePass],
    description: "-",
  },
  [PromptsType.randomTitle]: {
    id: PromptsType.randomTitle,
    defaultMessage: en[PromptsType.randomTitle],
    description: "-",
  },
});
