import * as React from "react";

import { Table, Image, Icon, Button } from "semantic-ui-react";
 import { PatternFormat } from "react-number-format";

import * as DTO from "../../../../interfaces";
import { getLargeSubscriptionType, getStatusText, printBasicDate, userFullName } from "../../../../utils";
import { TitleSubtitleCell } from "../../../Cells";
import { Svg } from "../../../../assets";

import "./styles.scss";
import { SocialNetworksSection } from "../../../SocialNetworksSection";
import { SubscriptionServices } from "../../../../services";
import { AppContext } from "../../../../providers";

interface OwnProps {
  player: DTO.SubscribedPlayerSearchResponse;
  isBasic: boolean;
  isCancelled: boolean;
  keyId?: string | number;
  allSocialNetworks: DTO.SocialNetworksId[];

  scPlusTier?: boolean;
  onClick: (playerId: number | string) => void;
}

const PlayerCellFC: React.FC<OwnProps> = ({
  player: { playerAccount, lastPaymentStatus, pricePlanSubscription, branchNames, regionNames, canceledDate },
  scPlusTier = false,
  isBasic,
  isCancelled,
  keyId,
  onClick,
  allSocialNetworks,
}) => {
  const { userContext } = React.useContext(AppContext);
  const { phone, email, photoUrl, socialNetworks, createdDate, playerId } = playerAccount;
  const playerSnIds = socialNetworks?.map(sn => sn.networkId);
  const elementId = keyId ? keyId : playerId;
  const [reactivating, setReactivating] = React.useState<boolean>(false);
  const [reactivated, setReactivated] = React.useState<boolean>(false);

  const reactivateSubscription = () => {
    if (window.confirm("Are you sure you want to reactivate this Player?")) {
      setReactivating(true);
      SubscriptionServices.reactivateSubscription(playerId!!, userContext!.coach!.id)
        .then(() => {
          setReactivated(true);
          window.confirm("This player has been reactivated. They will receive an email shortly.");
        })
        .catch(e => {
          alert(`Error reactivating player: ${e}`);
        })
        .finally(() => {
          setReactivating(false);
        });
    }
  };

  return (
    (!reactivated && (
      <Table.Row
        key={`player_list_row_${elementId}`}
        className={lastPaymentStatus === "FAILED" ? "playerDisable" : ""}
        onClick={() => onClick(playerId!!)}>
        <Table.Cell>
          <Image className={"titleClickable"} circular size="mini" src={photoUrl ? photoUrl : Svg.noImageuser} />
        </Table.Cell>
        <TitleSubtitleCell
          maxLength={30}
          title={email}
          subtitle={userFullName(playerAccount)}
          subcontent={<SocialNetworksSection actives={playerSnIds} allSocialNetworks={allSocialNetworks} />}
        />

        <Table.Cell>
          {isBasic ? (
            <PatternFormat
              disabled={true}
              format="+1 (###) ###-####"
              type={"tel"}
              className={"phoneSection"}
              defaultValue={phone ? phone : ""}
            />
          ) : (
            branchNames
          )}
        </Table.Cell>

        {isBasic ? (
          <Table.Cell>
            {pricePlanSubscription!!.status!! === "ACTIVE" && <Icon color="green" name="checkmark" size="small" />}
            {getStatusText(pricePlanSubscription!!.status!!)}
          </Table.Cell>
        ) : (
          <Table.Cell>{regionNames}</Table.Cell>
        )}

        <Table.Cell>{createdDate && printBasicDate(createdDate!)}</Table.Cell>
        {isCancelled && <Table.Cell>{canceledDate && printBasicDate(canceledDate!)}</Table.Cell>}
        {isCancelled && (
          <Table.Cell onClick={(e: Event) => e.stopPropagation()}>
            <Button
              basic
              data-elm-id={`playersListReactivateBtn-${elementId}`}
              className={"noBorder"}
              loading={reactivating}
              disabled={reactivating}
              onClick={reactivateSubscription}>
              Reactivate
            </Button>
          </Table.Cell>
        )}
        {/* <Table.Cell>{socialNetworks?.length}</Table.Cell> */}
        {scPlusTier && <Table.Cell>{getLargeSubscriptionType(pricePlanSubscription?.pricePlan.tier)}</Table.Cell>}
      </Table.Row>
    )) ||
    null
  );
};
export const PlayerCell = PlayerCellFC;
