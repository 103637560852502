import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Formik } from "formik";

import { SocialCoachSessionService } from "../../../services";
import { validationSchema } from "./validations";
import { ProspectResponse } from "../../../interfaces/dtos";

import { PlayerSignUp } from "../SignUp";

interface OwnProps {
  referredFromUserId: string;
  successHandler: (user: ProspectResponse) => void;
  userRegisteredHandler: (email: string) => void;
}

type Props = OwnProps & RouteComponentProps;

const IntegratedRegister: React.FC<Props> = ({ successHandler, userRegisteredHandler, referredFromUserId }) => {
  return (
    <div className={"playerSignUpDiv"}>
      <Formik
        validateOnBlur={false}
        validateOnChange={true}
        initialValues={{
          email: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async ({ email }, { setSubmitting, setErrors, validateForm }) => {
          try {
            const errors = await validateForm({
              email,
            });

            if (errors && Object.values(errors).length > 0) {
              setErrors(errors);
            } else {
              let response;

              response = await SocialCoachSessionService.createProspect({
                email: email!,
                coachId: referredFromUserId,
              });

              successHandler(response);
            }
          } catch (e: any) {
            if (e === "USER_EXISTS") {
              userRegisteredHandler(email);
            } else {
              setErrors({
                email: e,
              });
            }
          }
          setSubmitting(false);
        }}>
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
          <PlayerSignUp
            messages={{ error: Object.values(errors) as string[] }}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            values={values}
          />
        )}
      </Formik>
    </div>
  );
};

export const PlayerRegisterForm = withRouter(IntegratedRegister)
