import PromptMTD from "./icPromptMTD.svg";
import PostGoals from "./icPostGoals.svg";
import PostGoals2 from "./icPostGoalsNew.svg";
import PromptCompleted from "./icPromptCompleted.svg";
import PromptPublished from "./icPromptPublished.svg";

import RemittanceLifetime from "./icRemittanceLifetime.svg";
import RemittanceMRR from "./icRemittanceMRR.svg";
import RemittanceMTD from "./icRemittanceMTD.svg";
import RemittanceNext from "./icRemittanceNext.svg";

import PlayerCanceled from "./icPlayerCanceled.svg";
import PlayerActive from "./icPlayerActive.svg";
import PlayerActive2 from "./icPlayerActiveNew.svg";
import PlayerLifetime from "./icPlayerLifetime.svg";
import PlayerTrouble from "./icPlayerTrouble.svg";

import noImageuser from "./noImageUser.svg";
import Clicks from "./icClicks.svg";
import EditBanner from "./icEditBanner.svg";
import Followers from "./icFollowers.svg";
import Views from "./icViews.svg";
import Likes from "./icLikes.svg";

import PlayStore from "./playStore.svg";
import AppStore from "./appStore.svg";
import Rocket from "./rocket.svg";

import Campaing from "./icCampaign.svg";
import PromptList from "./promptList.svg";
import Library from "./library.svg";
import PromptPlaceholder from "./promptPlaceholder.svg";
import UserBorder from "./userBorder.svg";
import Camera from "./camera.svg";
import Reels from "./reels.svg";

export const Svg = {
  PostGoals,
  PostGoals2,
  PromptMTD,
  PromptCompleted,
  PromptPublished,
  PlayerCanceled,
  PlayerActive,
  PlayerActive2,
  PlayerLifetime,
  PlayerTrouble,
  RemittanceLifetime,
  RemittanceMRR,
  RemittanceMTD,
  RemittanceNext,
  Rocket,
  noImageuser,
  Clicks,
  Followers,
  EditBanner,
  Views,
  Likes,
  PlayStore,
  AppStore,
  Library,
  PromptList,
  Campaing,
  PromptPlaceholder,
  Camera,
  UserBorder,
  Reels,
};
