/*

  Author: Drew Carson
  © Copyright SocialCoach

  TODO: this component really should handle its own media objects, but given the context
  within the prompt creation form, this is a quick and dirty solution. Refactor later!

*/

import * as React from "react";

import "./styles.scss";
import { DraggableMediaContainer } from "../";
import { UploadTask } from "firebase/storage";
import { getDownloadURLFirebase, saveElementToFirebase, saveElementToFirebaseCancellable } from "../../utils/firebase";
import styled from "styled-components";
import { Media, UploadProgressMap, FirebaseConfig, FileErrorDescription } from "../../interfaces";
import {
  createVideoThumbnailBlob,
  dataURLtoBlob,
  checkFileDuration2,
  getVideoDimensions,
  mediaFirebaseUrl,
  validAspectRatio,
  validDimensions,
  getValidFiles,
  reorder,
} from "../../utils";

const MAXREELSDURATION = 900;
const MINREELSDURATION = 3;
const MAXMEDIAREELS = 1;
const MAXMEDIA = 10;

const UploadedFilesManager = styled.div<{ medialoaded: any }>`
  ${(props: any) =>
    props.medialoaded
      ? `
      background-color: transparent;
      border: 0 solid transparent;
      padding: 0;
    `
      : `
      background-color: white;
      border: 1px solid #e9e9e9;
      padding: 10px 0 0 15px;
    `}
`;

interface MultiImageUploaderProps {
  handleMediaUpdated: (newMedia: Media[]) => void;
  handleAttachedFileUploadStarted: () => void;
  handleAttachedFileUploadFinished: () => void;

  firebase: FirebaseConfig;
  coachId: string;
  postAsReel?: boolean;
  media: Media[];
}

interface MultiImageUploaderState {
  mediaLoaded: boolean;
  numberOfFiles: number;
}

/*
 *
 * This Multi-Image uploader is designed to allow users to upload variable image and video media
 * types and reorder them using the DraggableMediaContainer.
 *
 */

export class MultiImageUploader extends React.Component<MultiImageUploaderProps, MultiImageUploaderState> {
  uploadProgress: UploadProgressMap;
  hiddenFileInput: React.RefObject<HTMLInputElement> = React.createRef(); // so that the browse button can trigger the sibling file input click

  constructor(props: MultiImageUploaderProps) {
    super(props);

    // bind all functions at construction time
    this.handleBrowseButtonClicked = this.handleBrowseButtonClicked.bind(this);
    this.handleFilesSelected = this.handleFilesSelected.bind(this);
    this.beginFirebaseUpload = this.beginFirebaseUpload.bind(this);
    this.handleFirebaseUploadProgress = this.handleFirebaseUploadProgress.bind(this);
    this.handleFirebaseUploadComplete = this.handleFirebaseUploadComplete.bind(this);
    this.handleUpdateMediaObject = this.handleUpdateMediaObject.bind(this);
    this.handleDeletedMedia = this.handleDeletedMedia.bind(this);
    this.handleUpdateMedia = this.handleUpdateMedia.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);

    // set initial state
    this.state = {
      mediaLoaded: false,
      numberOfFiles: 0,
    };

    // store upload progress as an instance variable so we don't have to update state
    this.uploadProgress = {};
  }

  componentDidMount() {
    if (this.props.media.length > 0) {
      this.setState({ mediaLoaded: true });
      this.props.media.forEach(item => {
        this.uploadProgress[item.id] = { progress: 1, task: undefined };
      });
    }
  }
  componentDidUpdate(
    prevProps: Readonly<MultiImageUploaderProps>,
    prevState: Readonly<MultiImageUploaderState>,
    snapshot?: any
  ): void {
    if (prevProps.postAsReel !== this.props.postAsReel) {
      this.hiddenFileInput.current!.value = "";
      this.setState({ mediaLoaded: false, numberOfFiles: 0 });
    }
  }

  /**
   * handles browse button clicks
   */
  handleBrowseButtonClicked() {
    if (
      (this.props.postAsReel && this.props.media.length <= MAXMEDIAREELS) ||
      (!this.props.postAsReel && this.props.media.length <= MAXMEDIA)
    ) {
      this.hiddenFileInput.current?.click();
    } else if (this.props.postAsReel) {
      alert(
        `We're only allowed to send ${MAXMEDIAREELS} media items at a time to social media for Reels. Sorry about that!`
      );
    } else if (!this.props.postAsReel) {
      alert(`We're only allowed to send ${MAXMEDIA} media items at a time to social media. Sorry about that!`);
    }
  }

  /**
   * Occurs directly after the user selects files to upload from their local device.
   */
  handleFilesSelected(e: React.ChangeEvent<HTMLInputElement>) {
    const inputFiles = e.target.files || new FileList();
    let validFiles = getValidFiles(Array.from(inputFiles));

    // no files provided
    if (validFiles.length === 0) {
      return;
    }

    // too many files? clip the last ones
    const maxMediaFile = this.props.postAsReel ? MAXMEDIAREELS : MAXMEDIA;
    const diff = validFiles.length + this.props.media.length - maxMediaFile;
    if (diff > 0) {
      alert(
        `We're only allowed to send ${maxMediaFile} media items at a time to social media ${
          this.props.postAsReel ? "for Reels" : ""
        }. Sorry about that!`
      );
      const validRange = maxMediaFile - this.props.media.length;
      validFiles = validFiles.splice(0, validRange);
    }

    // all file types were valid
    this.beginFirebaseUpload(validFiles);
  }

  /**
   *  Converts File objects to image data for the component to use.
   */
  beginFirebaseUpload(files: File[]) {
    // get the next available id
    const existingMedia = this.props.media;
    const ids = this.props.media.map(item => item.id);
    let nextId = ids.length > 0 ? Math.max(...ids) + 1 : 1;
    const sortOrders = this.props.media.map(item => item.sortOrder);
    let nextSortOrder = sortOrders.length > 0 ? Math.max(...sortOrders) + 1 : 1;

    this.setState({
      mediaLoaded: true,
      numberOfFiles: files.length,
    });

    const uploadPromises: UploadTask[] = [];
    // pass each new media object to the parent component
    files.forEach(file => {
      this.props.handleAttachedFileUploadStarted();
      if (file.type.includes("video")) {
        checkFileDuration2(
          file,
          (errors?: FileErrorDescription) => {
            // pass placeholder element to parent component via handleMediaUpdated handler
            const mediaCategory = file.type.includes("image/") ? "IMAGE" : "VIDEO";
            const timestamp = new Date().getTime();
            const fileName = file.name.split(".")[0] + timestamp;

            const mediaObject: Media = {
              id: nextId++,
              sortOrder: nextSortOrder++,
              category: mediaCategory,
              uri: "",
              type: file.type,
              extension: file.type.split("/")[1],
              fileName,
              errors,
            };
            const newMedia = [...existingMedia, mediaObject];
            this.props.handleMediaUpdated(newMedia);

            // save file to firebase asynchronously
            const uploadTask = saveElementToFirebaseCancellable(
              this.props.firebase,
              mediaFirebaseUrl(`${this.props.coachId}`),
              file,
              fileName
            );

            this.uploadProgress[mediaObject!.id!] = { progress: 0, task: uploadTask };

            // set Firebase callback functions
            uploadTask.on(
              "state_changed",
              snapshot => {
                this.handleFirebaseUploadProgress(snapshot, mediaObject!.id!);
              }, // on progress update
              null, // TODO: on upload failure
              () => this.handleFirebaseUploadComplete(file, mediaObject) // on upload complete
            );

            uploadPromises.push(uploadTask);
          },
          MAXREELSDURATION,
          MINREELSDURATION,
          true
        );
      } else {
        // pass placeholder element to parent component via handleMediaUpdated handler
        const mediaCategory = file.type.includes("image/") ? "IMAGE" : "VIDEO";
        const timestamp = new Date().getTime();
        const fileName = file.name.split(".")[0] + timestamp;
        const errors: FileErrorDescription = {
          wrongBitrate: false,
          wrongSize: false,
          wrongLength: false,
        };

        const mediaObject: Media = {
          id: nextId++,
          sortOrder: nextSortOrder++,
          category: mediaCategory,
          uri: "",
          type: file.type,
          extension: file.type.split("/")[1],
          fileName,
          errors,
        };
        const newMedia = [...existingMedia, mediaObject];
        this.props.handleMediaUpdated(newMedia);

        // save file to firebase asynchronously
        const uploadTask = saveElementToFirebaseCancellable(
          this.props.firebase,
          mediaFirebaseUrl(`${this.props.coachId}`),
          file,
          fileName
        );
        this.uploadProgress[mediaObject!.id!] = { progress: 0, task: uploadTask };

        // set Firebase callback functions
        uploadTask.on(
          "state_changed",
          snapshot => {
            this.handleFirebaseUploadProgress(snapshot, mediaObject!.id!);
          }, // on progress update
          null, // TODO: on upload failure
          () => this.handleFirebaseUploadComplete(file, mediaObject) // on upload complete
        );

        uploadPromises.push(uploadTask);
      }
    });

    Promise.all(uploadPromises).then(values => {
      this.props.handleAttachedFileUploadFinished();
    });
  }

  uploadThumbnailToFirebase(
    uri: string,
    mediaObject: Media,
    isValidAspectRatio: boolean,
    isValidDimensions: boolean
  ): Promise<Media> {
    return new Promise<Media>((resolve, reject) => {
      createVideoThumbnailBlob(uri)
        .then(videoThumbnailUri => {
          const blob = dataURLtoBlob(videoThumbnailUri);

          saveElementToFirebase(
            this.props.firebase,
            "thumbnail/" + mediaObject.fileName,
            blob,
            "thumbnail_" + mediaObject.fileName
          )
            .then(urlThumba => {
              resolve({
                ...mediaObject,
                uri,
                downloadLink: uri,
                thumbnailUri: urlThumba,
                validAspectRatio: isValidAspectRatio,
                validDimension: isValidDimensions,
              });
            })
            .catch(e => {
              resolve({
                ...mediaObject,
                uri,
                downloadLink: uri,
                validAspectRatio: isValidAspectRatio,
              });
            });
        })
        .catch(() => {
          resolve({
            ...mediaObject,
            uri,
            downloadLink: uri,
            validAspectRatio: isValidAspectRatio,
          });
        });
    });
  }

  handleFirebaseUploadProgress(snapshot: any, mediaId: number) {
    const uploadProgress = snapshot.bytesTransferred / snapshot.totalBytes;
    this.uploadProgress[mediaId] = { ...this.uploadProgress[mediaId], progress: uploadProgress };
    this.forceUpdate();
  }

  handleFirebaseUploadComplete(file: File, mediaObject: Media) {
    getDownloadURLFirebase(
      this.props.firebase,
      mediaFirebaseUrl(`${this.props.coachId}`),
      file,
      mediaObject.fileName!
    ).then(uri => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = uri || "";

      if (mediaObject.category === "VIDEO") {
        const urlPromise = getVideoDimensions(uri);
        urlPromise
          .then(dimension => {
            this.uploadThumbnailToFirebase(
              uri,
              mediaObject,
              validAspectRatio(dimension.height, dimension.width, this.props.postAsReel),
              validDimensions(dimension.height, dimension.width, this.props.postAsReel)
            ).then(finalMedia => {
              this.handleUpdateMediaObject(finalMedia);
            });
          })
          .catch(() => {
            this.uploadThumbnailToFirebase(uri, mediaObject, true, true).then(finalMedia => {
              this.handleUpdateMediaObject(finalMedia);
            });
          });
      } else {
        img.onload = () => {
          this.handleUpdateMediaObject({
            ...mediaObject,
            uri,
            downloadLink: uri,
            validAspectRatio: validAspectRatio(img.height, img.width),
            validDimension: true,
            imageFile: file,
          });
        };
      }
    });
  }

  handleUpdateMediaObject(mediaObject: Media) {
    const otherMediaObjects = this.props.media.filter(item => item.id !== mediaObject.id);
    const newMedia = [...otherMediaObjects, mediaObject];
    this.props.handleMediaUpdated(newMedia);
  }

  /**
   *  Updates media state when user deletes a media object.
   */
  handleDeletedMedia(id: number) {
    // update state
    this.uploadProgress[id]?.task?.cancel();
    const newMedia = this.props.media.filter(mediaItem => mediaItem.id !== id);
    this.props.handleMediaUpdated(newMedia);

    // reset mediaLoaded if there are no more media items
    if (newMedia.length === 0 && this.state.numberOfFiles - 1 === 0) {
      this.setState({
        mediaLoaded: false,
        numberOfFiles: newMedia.length,
      });
      this.hiddenFileInput.current!.value = "";
    } else {
      this.setState({
        numberOfFiles: this.state.numberOfFiles - 1,
      });
    }
  }

  /**
   *  Updates media state when user update a media object.
   */
  handleUpdateMedia(id: number, uri: string) {
    const newMedia = this.props.media.map(mediaItem => {
      if (mediaItem.id === id) {
        return {
          ...mediaItem,
          uri,
          validAspectRatio: true,
          downloadLink: uri,
        };
      }

      return mediaItem;
    });

    this.props.handleMediaUpdated(newMedia);
  }

  /**
   * Passes the drag n drop result to the parent component
   * @param result the result sent to us by react-beautiful-dnd DragDropContext object when the user drops an item
   */
  onDragEnd(result: any) {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    const rowSize = 5;
    const sInd = source.droppableId;
    const dInd = destination.droppableId;
    const realDestinationIndex = dInd * rowSize + result.destination.index;
    const realSourceIndex = sInd * rowSize + result.source.index;
    const newMedia = reorder(this.props.media, realSourceIndex, realDestinationIndex);
    this.props.handleMediaUpdated(newMedia);
  }

  render() {
    const filesLoader = this.state.numberOfFiles > this.props.media.length;
    return (
      <div className="multi-image-uploader-wrapper">
        <form className="multi-image-uploader-input-form">
          <input
            className="custom-file-input"
            type="file"
            multiple
            accept={this.props.postAsReel ? "video/*" : "image/*, video/*"}
            onChange={this.handleFilesSelected}
            ref={this.hiddenFileInput}
          />
          <UploadedFilesManager
            medialoaded={this.state.mediaLoaded ? 1 : 0}
            onClick={() => {
              if (!this.state.mediaLoaded) {
                this.handleBrowseButtonClicked();
              }
            }}
            className="uploaded-files-manager"
          >
            {
              /* Pass selected media items to the interactive media manager; otherwise, show prompt */
              this.state.mediaLoaded ? (
                <div className={filesLoader ? "loader" : ""}>
                  <DraggableMediaContainer
                    media={this.props.media}
                    uploadProgress={this.uploadProgress}
                    handleClose={this.handleDeletedMedia}
                    handleUpdate={this.handleUpdateMedia}
                    onDragEnd={this.onDragEnd}
                  />
                </div>
              ) : (
                <span className="files-manager-placeholder">Select one or more files...</span>
              )
            }
          </UploadedFilesManager>
          <div className="browse-button" onClick={this.handleBrowseButtonClicked}>
            Browse
          </div>
        </form>
      </div>
    );
  }
}
