import * as React from "react";
import { get, join } from "lodash";

import { ConfigContext } from "../../../contexts/appContexts";
import { ConfigProperties, SocialNetworksName } from "../../../interfaces";
import SocialNetworkButton from "../../../components/SocialNetworkButton";

import { SocialNetworkCustomProps } from "../SocialProps";
import CoachSocialNetworkButton from "../../Coaches/CoachSocialNetworkButton";

const LinkedInFC: React.FC<SocialNetworkCustomProps> = ({
  title,
  username,
  viewName = "Register",
  handleLogin,
  handleDelete,
  inProfile,
  asACoach = false,
  loading,
  connected = false,
}) => {
  const config: ConfigProperties = React.useContext(ConfigContext);
  const LIURL = "https://www.linkedin.com";

  const [loginInProgress, setLoginInProgress] = React.useState<boolean>(false);

  React.useEffect(() => {
    window.addEventListener("message", handlePostMessage);
  }, []);

  React.useEffect(() => {
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, []);

  const handlePostMessage = (event: any) => {
    if (event.data.type === "profile-linkedIn") {
      const vanityName = get(event.data.profile, "vanityName", undefined);
      const profile = {
        username:
          get(event.data.profile, "localizedFirstName", "") + " " + get(event.data.profile, "localizedLastName", ""),
        code: get(event.data.profile, "code", ""),
        expiresIn: get(event.data.profile, "expiresIn", "5184000"),
        link: vanityName ? `${LIURL}/in/${vanityName}` : undefined,
        refreshToken: get(event.data.profile, "refreshToken", ""),
        refreshTokenExpiresIn: get(event.data.profile, "refreshTokenExpiresIn", "5184000"),
        organizationIds: event.data.profile.organizations,
      };
      setLoginInProgress(true);
      setTimeout(() => {
        handleLogin(null, profile);
        setLoginInProgress(false);
      }, 5000);
    }
  };

  const handlerAction = (sn: SocialNetworksName) => {
    if (username && asACoach) {
      disconnectHandlerAction(sn);
    } else {
      requestProfile();
    }
  };

  const disconnectHandlerAction = (sn: SocialNetworksName) => {
    if (username && handleDelete) {
      handleDelete(sn);
    }
  };

  const requestProfile = () => {
    const { permissions, api_key, callback } = config.socialNetworks.linkedin;
    const scope = join(permissions, "%20");
    const oauthUrl = `${LIURL}/oauth/v2/authorization?response_type=code&client_id=${api_key}&scope=${scope}&state=11223344&redirect_uri=${encodeURIComponent(
      callback!
    )}`;
    const width = 420;
    const height = 500;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    window.open(
      oauthUrl,
      "Linkedin",
      "menubar=no,location=no,resizable=no,scrollbars=no,status=no,width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  const ButtomComponent = asACoach ? CoachSocialNetworkButton : SocialNetworkButton;
  return (
    <ButtomComponent
      title={title || ""}
      data-elm-id={`social${viewName}ButtonLinkedIn${connected}`}
      account={username ?? ""}
      handler={handlerAction}
      disconnectHandler={disconnectHandlerAction}
      inProfile={inProfile}
      socialNetwork={"linkedIn"}
      loading={asACoach ? loading : loginInProgress}
      connected={connected}
    />
  );
};

export const LinkedIn = LinkedInFC;
