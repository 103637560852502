import { useState, useEffect, useMemo } from "react";
import { AdminWebSetting, WebSettings, SocialNetworksId } from "../interfaces";
import { availableFunctionality } from "../utils";
import { SettingsServices } from "../services";

export const useCoachSettings = (defaultCoach: string, withIGStory: boolean = true) => {
  const [settings, setSettings] = useState<{
    displayTikTok: boolean;
  }>({
    displayTikTok: true,
  });

  const coachId = defaultCoach;

  useEffect(() => {
    const setTikTokPermission = (adminSettings: AdminWebSetting): void => {
      setSettings({
        displayTikTok: availableFunctionality(adminSettings, WebSettings.TIKTOK),
      });
    };

    const getSettings = async () => {
      SettingsServices.getAdminSettingsByCoachId(coachId).then(setTikTokPermission);
    };

    getSettings();
  }, [coachId]);

  const sns: SocialNetworksId[] = useMemo(() => {
    const baseSns: SocialNetworksId[] = ["FB", "LI", "IG"];

    if (withIGStory) {
      baseSns.push("IG_STORY");
    }

    baseSns.push("TW");
    baseSns.push("TT");

    return baseSns;
  }, [settings.displayTikTok]);

  return { sns, settings };
};
