import styled from "styled-components";
import { Grid, Icon, Loader } from "semantic-ui-react";

import { theme } from "./../../../styling/theme";

export const RowContainer = styled(Grid.Row)({
  maxWidth: "1300px",
});

export const CompleteIcon = styled(Icon)({
  color: theme.buttonColor,
  paddingLeft: "10px",
  float: "right",
  paddingRight: "20px",
  right: 0,
});

export const ErrorIcon = styled(Icon)({
  color: theme.alertColor,
  paddingLeft: "10px",
  float: "right",
  paddingRight: "20px",
  right: 0,
});

export const PromptLoader = styled(Loader)`
  padding-left: 20px;
  float: right;
`;

export const SCSubtitle = styled.p`
  color: ${theme.lineColor};
  font-family: "Open Sans", sans-serif;
  font-size: 1.15em;
  letter-spacing: -0.24px;
  line-height: 21.79px;
  text-align: left;
  white-space: pre-line;
`;
export const LoadingSection = styled.div`
  background-color: white;
  width: 300px;
  max-height: 400px;
  font-family: "Open Sans", sans-serif;
  position: fixed;
  z-index: 100;
  bottom: 0;
  right: 0;
  padding-bottom: 15px;
  overflow-x: auto;

  &&& {
    padding-left: 0;
    border-radius: 10px;
  }
`;

export const PromptUploadingSection = styled(Grid)({
  backgroundColor: "white",
  height: "60px",
  width: "100%",
  fontFamily: '"Open Sans", sans-serif',
});
export const PromptTitle = styled(Grid.Column)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  fontFamily: '"Open Sans", sans-serif',
});
export const LoadingSectionTitle = styled(Grid.Row)({
  backgroundColor: theme.boxColor,
  height: "60px",
  width: "100%",
  fontFamily: '"Open Sans", sans-serif',
  fontSize: "1.5em",
  padding: "10px 2px",
  borderTopRightRadius: "10px",
  borderTopLeftRadius: "10px",
  marginRight: "20px",
});
