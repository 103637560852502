import { defineMessages } from "react-intl";

export enum ThankYouType {
  title = "ThankYouType.title",
  description = "ThankYouType.description",
  done = "ThankYouType.done",
}

export const en = {
  [ThankYouType.title]: "Thank You!\nWe have received your application",

  [ThankYouType.description]:
    "We'll reach out to you by the end of the next business day.\n A confirmation email has been sent to you. ",
  [ThankYouType.done]: "Done",
};

export const descriptors = defineMessages({
  [ThankYouType.title]: {
    id: ThankYouType.title,
    defaultMessage: en[ThankYouType.title],
    description: "title",
  },
  [ThankYouType.description]: {
    id: ThankYouType.description,
    defaultMessage: en[ThankYouType.description],
    description: "description",
  },
  [ThankYouType.done]: {
    id: ThankYouType.done,
    defaultMessage: en[ThankYouType.done],
    description: "done",
  },
});
