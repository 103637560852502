import * as React from "react";
import { Form, Segment, Grid, Loader, Message } from "semantic-ui-react";
import "./styles.scss";
import { SegmentHeader } from "../SegmentHeader";

interface Props {
  /**
   * Form title (big)
   */
  title?: string;
  /**
   * Form subtitle (small)
   */
  subtitle?: string;
  /**
   * Component
   */
  content: JSX.Element;
  /**
   * Error messages list
   */
  messages?: { info?: string[]; error?: string[]; warn?: string[] };
  /**
   * Is form submitting
   */
  isSubmitting?: boolean;
  /**
   * Size of card
   */
  size: "small" | "big" | "bigCentered" | "bigCenteredNoPadding";
  /**
   * Padding of description
   */
  padding?: "none" | "small";

  /**
   * Container padding
   */
  containerPadding?: "" | "small" | "completed";

  /**
   * Hide SocialCoach logo
   */
  showLogo?: boolean;
}

const CardFC: React.FC<Props> = ({
  title,
  subtitle,
  content,
  messages,
  isSubmitting,
  showLogo = true,
  size = "small",
  padding = "none",
  containerPadding = "",
}) => {
  const errors = messages && messages.error && messages.error.length > 0;
  const warnings = messages && messages.warn && messages.warn.length > 0;

  return (
    <Grid
      columns={16}
      verticalAlign="middle"
      stretched
      container
      centered
      className={"floatedGrid " + containerPadding}
    >
      <Grid.Column mobile={16} tablet={10} computer={8} largescreen={6} className={size}>
        <Form size="large" error={errors} warning={warnings} className={"floatedFormBox"}>
          <Segment padded={"very"} centered={"true"} className={"segmentContainer " + size}>
            {isSubmitting && <Loader active size="large" />}

            <SegmentHeader showLogo={showLogo} title={title} subtitle={subtitle} padding={padding} />

            {content}

            {messages && messages.error && messages.error.length > 0 && (
              <Message error header={"Errors:"} list={messages && messages.error} />
            )}
            {messages && messages.warn && messages.warn.length > 0 && (
              <Message warning header={"Warnings: "} list={messages && messages.warn} />
            )}
            {messages && messages.info && messages.info.length > 0 && (
              <Message header={"Info: "} list={messages.info} />
            )}
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export const FloatedCard = CardFC;
