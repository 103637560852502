import styled from "styled-components";
import { Grid } from "semantic-ui-react";
import { theme } from "../../../styling/theme";

export const DigestEmailGrid = styled(Grid)`
  min-width: 100%;
`;

export const DigestEmailContainer = styled(Grid.Row)({
  display: "flex !important",
  marginTop: 0,
  padding: "22px 0px 27px 14px !important",
  backgroundColor: `${theme.backgroundColor}`,
});
