import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Grid, Table, Pagination, Loader } from "semantic-ui-react";

import { AlertsServices } from "../../../services";
import * as DTO from "../../../interfaces";

import { AlertCell } from "./AlertCell";
import { AppContext } from "../../../providers";
import { AlertType } from "../../../interfaces";
import { EmptyView } from "../../EmptyView";

interface OwnProps {
  type: DTO.AlertType;
  defaultPage: number;
  withWritePermission: boolean;
}

type Props = OwnProps & RouteComponentProps;

const AlertListFC: React.FC<Props> = ({
  type,
  withWritePermission,
  defaultPage,
}) => {
  const { userContext } = React.useContext(AppContext);

  const [alerts, setAlerts] = React.useState<DTO.AlertResponse[]>([]);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [pageNumber, setPageNumber] = React.useState<number | undefined>(
    defaultPage
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const getTableData = async () => {
    setIsLoading(true);
    const alertsPage = await AlertsServices.get(
      userContext!.coach!.id,
      type,
      pageNumber
    );
    setAlerts(alertsPage.content);
    setTotalPages(alertsPage.totalPages!);
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (!pageNumber || pageNumber === 0) {
      getTableData();
    } else {
      setPageNumber(0);
    }
  }, [type]);

  React.useEffect(() => {
    getTableData();
  }, [pageNumber]);

  const deleteAlert = (alertId: number): void => {
    AlertsServices.delete(alertId)
      .then(() => {
        getTableData();
      })
      .catch(() => {
        alert("Error removing this alert");
      });
  };
  const isCampaignSection = type === AlertType.CAMPAIGN_ABOUT_TO_EXPIRED;
  return (
    <Grid centered>
      {alerts.length > 0 && !isLoading && (
        <>
          <Grid.Row>
            <Grid.Column width={16}>
              <Table
                striped
                celled
                compact="very"
                size="small"
                data-elm-id="adminDashboardCoachesTable"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={4}>Detail</Table.HeaderCell>
                    <Table.HeaderCell>
                      {!isCampaignSection ? "Info" : "Players with problems"}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      {!isCampaignSection ? "Date" : "Campaign Info"}
                    </Table.HeaderCell>
                    {!isCampaignSection && withWritePermission && (
                      <Table.HeaderCell width={1}>Action</Table.HeaderCell>
                    )}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {!isLoading &&
                    alerts.map((alertNotification, index) => {
                      return (
                        <AlertCell
                          key={
                            "coach_alert_cell_" +
                            index +
                            alertNotification.alertId
                          }
                          alert={alertNotification}
                          removeAlert={deleteAlert}
                          withWritePermission={withWritePermission}
                        />
                      );
                    })}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid centered>
              <Pagination
                data-elm-id="alert-paginator"
                activePage={pageNumber ? pageNumber + 1 : 1}
                boundaryRange={3}
                pointing
                secondary
                totalPages={totalPages}
                onPageChange={(_, data) => {
                  setPageNumber(Number(data.activePage) - 1);
                }}
              />
            </Grid>
          </Grid.Row>
        </>
      )}
      {!isLoading && alerts.length === 0 && (
        <EmptyView text={"You have no alerts"} />
      )}

      {isLoading && <Loader active size="large" />}
    </Grid>
  );
};

export const AlertList = withRouter(AlertListFC);
