import * as React from "react";
import{ useIntl } from "react-intl";
import { Grid, Header } from "semantic-ui-react";

import { SearchComponent } from "../../../../../components";
import * as DTO from "../../../../../interfaces";
import { CategoriesServices, CampaignServices } from "../../../../../services";
import { categoriesToFilter } from "../../../../../utils";

import { CampaignPlayerType, descriptors } from "./descriptors";
import CampaignSearchList from "./components/CampaignSearchList";
import CampaignList from "./components/CampaignList";

import { BlueTag, InternalPaddingGrid } from "../../../../../styling/baseStyle";
import "./styles.scss";

interface OwnProps {
  coachId: string;
  campaignIds?: DTO.SelectedCampaign; // TODO: REMOVE
  onChangeHandler: (selectedPrompts: DTO.SelectedCampaign) => void;
}

type Props = OwnProps 

const CampaignPromptFC: React.FC<Props> = ({ coachId, campaignIds = {}, onChangeHandler }) => {
  const { formatMessage } = useIntl();
  const [searchActive, setSearchActive] = React.useState<boolean>(false);

  const [searchedCampaignList, setSearchedCampaignList] = React.useState<DTO.CampaignResume[]>([]);
  const [categories, setCategories] = React.useState<DTO.Category[]>([]);
  const [categoriesFilters, setCategoriesFilters] = React.useState<DTO.FilterType[]>([]);
  const [selectedCampaigns, setSelectedCampaigns] = React.useState<DTO.SelectedCampaign>(campaignIds);

  React.useEffect(() => {
    return function cleanup() {
      // Specify how to clean up after this effect:
    };
  }, []);

  React.useEffect(() => {
    const getCategories = async () => {
      const result = await CategoriesServices.get(coachId, "CAMPAIGN");
      setCategories(() => result);
      setCategoriesFilters(categoriesToFilter(result));
    };

    getCategories();
  }, [coachId]);

  React.useEffect(() => {
    onChangeHandler(selectedCampaigns);
  }, [selectedCampaigns]);

  const addCampaignHandler = (campaign: DTO.CampaignResume) => {
    const { campaignId, categoryIds, campaignName, firstPromptMedia } = campaign;
    const currentCampaigns: DTO.SelectedCampaign = {
      ...selectedCampaigns,
      [campaignId!]: {
        categoryIds,
        title: campaignName,
        post: firstPromptMedia,
      },
    };

    setSelectedCampaigns(currentCampaigns);
  };

  const removeCampaignHandler = (campaign: DTO.CampaignResume) => {
    const { campaignId } = campaign;
    setSelectedCampaigns(finalCampaigns => {
      delete finalCampaigns[campaignId!];
      return { ...finalCampaigns };
    });
  };

  const promptsCounter = Object.keys(selectedCampaigns).length;
  return (
    <InternalPaddingGrid className={"activeCampaignComponentContainer"}>
      <Grid.Row className={"noPaddingBottom"}>
        <Grid.Column width={10}>
          <Header as="h4"> {formatMessage({ ...descriptors[CampaignPlayerType.title] })}</Header>
        </Grid.Column>
        <Grid.Column width={6}>
          <BlueTag hidden={promptsCounter === 0}>
            {formatMessage(
              {
                ...descriptors[CampaignPlayerType[promptsCounter > 1 ? "multipleSelectedPrompts" : "selectedPrompt"]],
              },
              { promptsCounter }
            )}
          </BlueTag>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <SearchComponent
            refreshData={setSearchedCampaignList}
            hideWhenNotSearch={true}
            filterPlaceholder={formatMessage({ ...descriptors[CampaignPlayerType.filterPlaceHolder] })}
            content={
              <CampaignSearchList
                categories={categories}
                coachId={coachId}
                promptList={searchedCampaignList}
                campaignPrompts={selectedCampaigns}
                onAddCampaignHandler={addCampaignHandler}
                onRemovePromptHandler={removeCampaignHandler}
              />
            }
            searchFunction={CampaignServices.getForPlayers}
            showSortBy={false}
            filters={categoriesFilters}
            pageable={false}
            onSearchedHandler={setSearchActive}
          />
          {categories && !searchActive && (
            <CampaignList
              categories={categories}
              coachId={coachId}
              campaignPrompts={selectedCampaigns}
              onAddCampaignHandler={addCampaignHandler}
              onRemoveCampaignHandler={removeCampaignHandler}
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </InternalPaddingGrid>
  );
};

export const ActiveCampaigns = CampaignPromptFC
