import styled from "styled-components";
import { Grid, Card, Header } from "semantic-ui-react";
import { Svg } from "../../../assets";
import { theme } from "../../../styling/theme";

const defaultImage = Svg.PromptPlaceholder;

const thumbnailSize = (thumbnail?: string): string => {
  if (thumbnail) {
    return `url("${thumbnail}") no-repeat center top`;
  }

  return `url(${defaultImage}) no-repeat center center`;
};

export const PromptCardStyle = styled(Card)({
  background: `${theme.lightBackgroundColor} !important`,
  borderRadius: "6.3px !important",
  border: `1px solid ${theme.backgroundColor} !important`,
  height: "138px !important",
  minWidth: "836px !important", // ojo dani
});

export const PromptHeader = styled(PromptCardStyle.Header)({
  overflow: "hidden",
  color: theme.titleColor,
  fontWeight: "bold",
  letterSpacing: "-0.24px",
  lineHeight: "19px",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  width: "100%",
  paddingTop: theme.size.s,
});

export const PromptMessage = styled(PromptCardStyle.Description)({
  overflow: "hidden",
  color: theme.titleColor,
  letterSpacing: "-0.24px",
  lineHeight: "19px",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  width: "100%",
  paddingTop: theme.size.s,
});

export const AutoPostLabel = styled(Header)({
  marginBottom: "0px !important",
});

export const MultipleImagesCannotAutopostLabel = styled.span`
  font-style: italic;
  font-size: 12px;
  display: block;
  margin: 40px 0;
  font-weight: 600;
`;

export const ThumbnailPreview = styled(Grid.Column)`
  width: 179px;
  height: 100px;
  margin: 8px;
  background: ${(props: any) => thumbnailSize(props.thumbnailurl)};
  background-size: ${(props: any) => (props.thumbnailurl ? "cover" : "inherit")};
  background-color: #eaeaf0;
`;
