import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  userPassword: Yup.string().required("Current password is required"),
  newPassword: Yup.string()
    .matches(/(?=.*\d)/, "Password must be at least 1 numeric value")
    .matches(/(?=.*[A-Z])/, "Password must be at least 1 capital letter")
    .matches(/(?=.*[-+_!@#$%^&*.,?])/, "Password must be at least 1 special character")
    .min(8, "Password must be at least 8 characters")
    .required("New password is required"),
});
