import * as React from "react";
import { Formik } from "formik";
import { useIntl } from "react-intl";
import { Types } from "@socialcoach/services";

import { UsersService } from "../../services/AccountsService";
import { Login } from "../../components";
import { descriptors, ErrorType } from "./descriptors";
import { URLS, validEmail } from "../../utils";
import { AppContext, AppUserContext, PlayerAppContext } from "../../providers";
import { UserGroups } from "../../interfaces";

export interface Props {
  username?: string;
  password?: string;
  group: Types.UserGroups;
  playerMode?: boolean;
  successHandler: (context: AppUserContext) => void;
}

const IntegratedLogin: React.FC<Props> = ({
  username = "",
  password = "",
  playerMode = false,
  group,
  successHandler,
}) => {
  const { formatMessage } = useIntl();

  const defaultErrorMessage = `Invalid Login Type, trying to login as admin? go to ${URLS.admin.login} =) Hola`;
  const [invalidLoginTypeError, setInvalidLoginTypeError] = React.useState<boolean>(false);
  const [invalidLoginError, setInvalidLoginError] = React.useState<string>(defaultErrorMessage);
  const { setUserContext } = React.useContext(AppContext);
  const { setCoachInformation } = React.useContext(PlayerAppContext);

  // Synchronous validation
  const validate = (values: any) => {
    const errors: Partial<Props> = {};

    if (!values.username) {
      errors.username = formatMessage({ ...descriptors[ErrorType.email] });
    } else if (validEmail(values.username)) {
      errors.username = formatMessage({ ...descriptors[ErrorType.invalidEmail] });
    }

    if (!values.password) {
      errors.password = formatMessage({ ...descriptors[ErrorType.password] });
    }

    return errors;
  };

  return (
    <div>
      <Formik
        validateOnBlur={false}
        validateOnChange={true}
        initialValues={{ username, password }}
        validate={validate}
        onSubmit={async ({ username: usr, password: pwd }, { setSubmitting, setErrors, validateForm }) => {
          try {
            const errors = await validateForm({ username: usr, password: pwd });
            if (errors && Object.values(errors).length > 0) {
              setErrors(errors);
            } else {
              const userContextResponse = (await UsersService.login(usr!, pwd!)) as AppUserContext;
              if (userContextResponse.account.groups.includes(group) || userContextResponse.player) {
                if (userContextResponse.player) {
                  userContextResponse.account.groups = [Types.UserGroups.USER];
                }

                setUserContext(userContextResponse);
                successHandler(userContextResponse);
              } else if (userContextResponse.account.groups.includes(Types.UserGroups.ADMIN)) {
                window.location.href = URLS.admin.dashboard;
              } else if (userContextResponse.account.scGroups?.some(g => g === UserGroups.EDITOR)) {
                window.location.href = URLS.editor.editQueueList;
              } else {
                setInvalidLoginError(defaultErrorMessage);
                setInvalidLoginTypeError(true);
              }
            }
          } catch (e) {
            setInvalidLoginTypeError(false);
            if (e === "BAD_CREDENTIALS") {
              setErrors({
                username: formatMessage({ ...descriptors[ErrorType.invalidUser] }),
                password: undefined,
              });
            } else if (e === "PLAYER_WITHOUT_REFERRED") {
              setErrors({
                username: formatMessage({ ...descriptors[ErrorType.playerWithoutReferred] }),
                password: undefined,
              });
            } else if (e === "WEB_NO_AVAILABLE_FOR_PLAYER") {
              setErrors({
                username: formatMessage({
                  ...descriptors[playerMode ? ErrorType.playerWithSubscription : ErrorType.playerRegistered],
                }),
                password: undefined,
              });
            } else if (e === "NO_ACTIVE_PLAYER") {
              setErrors({
                username: formatMessage({
                  ...descriptors[ErrorType.noActivePlayer],
                }),
                password: undefined,
              });
            } else if (e === "LOCKED_USER") {
              setErrors({
                username: formatMessage({ ...descriptors[ErrorType.inactiveAccount] }),
                password: undefined,
              });
            } else if (e === "NO_ACTIVE_COACH") {
              setErrors({
                username: formatMessage({ ...descriptors[ErrorType.inactiveCoach] }),
                password: undefined,
              });
            } else {
              setErrors({
                username: String(e),
                password: undefined,
              });
            }
          }
          setSubmitting(false);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
          <Login
            messages={{
              error: !invalidLoginTypeError ? (Object.values(errors) as string[]) : [invalidLoginError],
            }}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            values={values}
            playerMode={playerMode}
          />
        )}
      </Formik>
    </div>
  );
};

export const LoginForm = IntegratedLogin;
