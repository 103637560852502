import * as React from "react";
import{ useIntl, FormattedMessage } from "react-intl";
import { Form, InputOnChangeData, Header } from "semantic-ui-react";
import MaskedInput from "react-text-mask";
import { Field } from "formik";

import { phoneNumberMask } from "../../../../utils";

import { descriptors, ProfileContactInformationType } from "./descriptors";
import { contactInformationFields } from "./formFields";
import "./styles.scss";

interface OwnProps {
  isSubmitting?: boolean;
  contactEmail: string;
  contactPhone: string;

  handleChange?: (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
  handleSetFieldValue?: (field: string, value: any) => void;
  handleClear?: () => void;
}

type Props = OwnProps 

const CoachContactInformationFC: React.FC<Props> = props => {
  const {
    handleChange,
    isSubmitting = false,
  } = props;
  const { formatMessage } = useIntl();
  return (
    <div>
      <Header as="h4" textAlign={"center"}>
        <FormattedMessage {...descriptors[ProfileContactInformationType.title]} />
      </Header>
      <span className={"columnTitle"}>
        <FormattedMessage {...descriptors[ProfileContactInformationType.subtitle]} />
      </span>
      <div className={"profileInformation"}>
        {contactInformationFields.map((field, index) => {
          return (
            <Form.Group widths="equal" key={"group_contact_info" + index}>
              {field.type !== "phone" ? (
                <Form.Input
                  key={field.id + index}
                  fluid
                  data-elm-id={"profileContactInformationInput" + field.id}
                  id={field.id}
                  type={field.type}
                  name={field.name}
                  value={props[field.id]}
                  placeholder={formatMessage({ ...descriptors[field.placeholder] })}
                  onChange={handleChange}
                  disabled={isSubmitting}
                />
              ) : (
                <div className="field">
                  <Field
                    name={field.name}
                    data-elm-id={"profileInformationPhoneNumber"}
                    render={({ phoneField }: { phoneField: any }) => (
                      <MaskedInput
                        {...phoneField}
                        value={props[field.id]}
                        mask={phoneNumberMask}
                        id={field.id}
                        placeholder={formatMessage({ ...descriptors[field.placeholder] })}
                        type="text"
                        onChange={handleChange}
                        disabled={isSubmitting}
                      />
                    )}
                  />
                </div>
              )}
            </Form.Group>
          );
        })}
      </div>
    </div>
  );
};

export const CoachContactInformation = CoachContactInformationFC
