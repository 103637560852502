import * as React from "react";
import{ useIntl } from "react-intl";
import { Form, Message, Button, Input, Popup, Icon } from "semantic-ui-react";
import DatePicker from "react-datepicker";

import { BasicFormProps, Category, PromptCampaign } from "../../../interfaces";
import {
  serverDateUTCToDate,
  dateToTimeString,
  getDateSearchFormat,
  Rules,
  stringTimeToDate,
  addDays,
  sortPrompts,
} from "../../../utils";
import { MultipleDaySelectorComponent, AddCategoryComponent, PromptCards } from "../../../components";

import { RowBasicPadding, SocialGrid, SocialGridWithPadding } from "../../../styling/baseStyle";
import { CampaignType, descriptors } from "./descriptors";
import "./styles.scss";

interface OwnProps extends BasicFormProps {
  /**
   * Handle delivery mode changes
   */
  prompts: PromptCampaign[];
  submitRef: any;
  /**
   * To indicate if this section needs to be disabled for the current coach
   */
  disabled: boolean;
  /**
   * To indicate if this section can edit dalivery date,
   * time and social networks in current prompts.
   */
  editable: boolean;

  /**
   * Id of prompts that were included previously
   */
  initialPromptsIds: string[];

  handleSetFieldValue?: (field: string, value: any) => void;
  onDeleteHandler: () => void;
  addPromptHandler: () => void;
  handlePromptsSorted?: (campaignPrompts: PromptCampaign[]) => void;
}

type Props = OwnProps 

/**
 * Basic Info Step.
 */
const CampaignClass: React.FC<Props> = ({
 
  messages,
  values,
  prompts,
  submitRef,
  disabled,
  editable = true,
  initialPromptsIds,
  handleChange,
  handleSetFieldValue,
  handleSubmit,
  onDeleteHandler,
  addPromptHandler,
  handlePromptsSorted,
}) => {
  const { deliveryMode, isAutoPost } = values;
  const deliveryRandom = deliveryMode !== "SPECIFIC_DATE";
  const { formatMessage } = useIntl();
  const [categories, setCategories] = React.useState<Category[]>(values.categories);
  const errors = messages && messages.error && messages.error.length > 0;

  const [promptsSorted, setPromptsSorted] = React.useState<PromptCampaign[]>(sortPrompts(prompts, deliveryRandom));

  React.useEffect(() => {
    handleSetFieldValue!("categories", categories);
  }, [categories]);

  const elegibleDaysAndTime = (): JSX.Element => {
    const selectedDays = values.randomScheduleDays;
    return (
      <RowBasicPadding>
        <SocialGridWithPadding.Row bottom={"20"}>
          <SocialGrid.Column width={7}>
            <MultipleDaySelectorComponent
              editable={editable}
              description={""}
              selected={selectedDays}
              onChange={days => editable && handleSetFieldValue!("randomScheduleDays", days)}
            />
          </SocialGrid.Column>
        </SocialGridWithPadding.Row>
        {deliveryTime()}
      </RowBasicPadding>
    );
  };

  const deliveryTime = (): JSX.Element => {
    let promptTime = values.deliveryTimeDatePicker;
    const deliveryTimeKey = "randomScheduleDeliveryTimePst";
    const deliveryTimeString = values[deliveryTimeKey];
    if (deliveryTimeString && !values.deliveryTimeDatePicker) {
      promptTime = stringTimeToDate(deliveryTimeString);
    }
    return (
      <SocialGrid.Row className={"paddingTop"}>
        <SocialGrid.Column width={8}>
          <DatePicker
            customInput={<Input icon="clock outline" />}
            data-elm-id={"campaignInputSelectedTime"}
            id={deliveryTimeKey}
            name={deliveryTimeKey}
            selected={promptTime}
            onChange={(date: any) => {
              if (editable) {
                handleSetFieldValue!("deliveryTimeDatePicker", date);
                handleSetFieldValue!(deliveryTimeKey, dateToTimeString(date));
              }
            }}
            disabled={!editable}
            showTimeSelect
            showTimeSelectOnly
            dateFormat="h:mm aa"
            autoComplete={"off"}
            placeholderText={formatMessage({ ...descriptors[CampaignType.randomTimePickerPlaceholder] })}
          />
          <span className={"dateSpan"}>{formatMessage({ ...descriptors[CampaignType.pstZoneMessage] })}</span>
        </SocialGrid.Column>
      </SocialGrid.Row>
    );
  };

  const customStartEnd = (): JSX.Element => {
    const startDate = values.startDatePicker ? serverDateUTCToDate(values.startDatePicker) : values.startDatePicker;
    const endDate = values.endDatePicker ? serverDateUTCToDate(values.endDatePicker) : values.endDatePicker;
    const startDateKey = "startDate";
    const endDateKey = "endDate";
    return (
      <SocialGrid.Row className={"startCampaignSection"}>
        <RowBasicPadding>
          <Popup
            hoverable
            trigger={
              <label>
                {formatMessage({ ...descriptors[CampaignType.durationSectionLabel] })}
                <Icon name="info circle" className={"infoIcon"} link />
              </label>
            }
            content={formatMessage({ ...descriptors[CampaignType.campaignDurationHoverDescription] })}
          />
        </RowBasicPadding>
        <SocialGrid columns={2}>
          <SocialGrid.Column>
            <DatePicker
              customInput={<Input icon="calendar outline" />}
              data-elm-id={"campaignInputStartDate"}
              id={startDateKey}
              name={startDateKey}
              selected={startDate}
              onChange={(date: any) => {
                handleSetFieldValue!("startDatePicker", date);
                handleSetFieldValue!(startDateKey, getDateSearchFormat(date));
              }}
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={15}
              minDate={new Date()}
              maxDate={endDate ? addDays(endDate, -1) : null}
              autoComplete={"off"}
              placeholderText={formatMessage({ ...descriptors[CampaignType.startDateLabel] })}
            />
            <span className={"dateSpan"}>{formatMessage({ ...descriptors[CampaignType.startDateLabel] })}</span>
          </SocialGrid.Column>

          <SocialGrid.Column>
            <DatePicker
              customInput={<Input icon="calendar outline" />}
              data-elm-id={"campaignInputEndDate"}
              id={endDateKey}
              name={endDateKey}
              selected={endDate}
              onChange={(date: any) => {
                handleSetFieldValue!("endDatePicker", date);
                handleSetFieldValue!(endDateKey, getDateSearchFormat(date));
              }}
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={15}
              minDate={addDays(startDate, 1) || new Date()}
              autoComplete={"off"}
              placeholderText={formatMessage({ ...descriptors[CampaignType.endDateLabel] })}
            />
            <span className={"dateSpan"}>{formatMessage({ ...descriptors[CampaignType.endDateLabel] })}</span>
          </SocialGrid.Column>
        </SocialGrid>
      </SocialGrid.Row>
    );
  };

  const deliverySection = (): JSX.Element => {
    return (
      <SocialGrid.Column width={11}>
        <RowBasicPadding>
          {deliveryRandom && <label>{formatMessage({ ...descriptors[CampaignType.deliveryLabel] })}</label>}
        </RowBasicPadding>
        {deliveryRandom && elegibleDaysAndTime()}
        {deliveryRandom && customStartEnd()}
      </SocialGrid.Column>
    );
  };

  // const isEditable =
  //   editable ||
  //   promptsSorted.reduce(
  //     (accumulator: boolean, currentValue) =>
  //       accumulator || initialPromptsIds.includes(`${currentValue.prompt!.promptId}`) === false,
  //     false
  //   );

  return (
    <Form size="large" error={errors} ref={submitRef} onSubmit={handleSubmit} className={"campaignInformationForm"}>
      <div className={disabled ? "formDiv disabledForm" : "formDiv"}>
        {messages && messages.error && messages.error.length > 0 && <Message error list={messages && messages.error} />}
        <Form.Group>
          <Form.Input
            width={10}
            key={"campaignNameInput"}
            label={formatMessage({ ...descriptors[CampaignType.campaignTitle] })}
            data-elm-id={"campaignNameInput"}
            fluid
            id={"name"}
            type={"text"}
            name={"name"}
            placeholder={formatMessage({ ...descriptors[CampaignType.titlePlaceholder] })}
            onChange={(e, data) => {
              if (e.target.value.length < Rules.MAX_CAMPAIGN_TITLE) {
                handleChange!(e, data);
              }
            }}
            value={values.name}
          />

          {!disabled && (
            <SocialGrid.Column className={"deleteSection"} width={6} floated={"right"}>
              <Button
                data-elm-id={`campaignInfoDeleteBtn`}
                type={"button"}
                onClick={onDeleteHandler}
                floated={"right"}
                className={"secondary rounded"}>
                Delete Campaign
              </Button>
            </SocialGrid.Column>
          )}
        </Form.Group>

        <SocialGrid>
          <SocialGrid.Column width={11}>
            <AddCategoryComponent
              selectedCategories={categories}
              handleOnChange={setCategories}
              type={"CAMPAIGN"}
              multiple={false}
            />
          </SocialGrid.Column>
        </SocialGrid>
        <div className={"greyLine"} />
        <SocialGrid className={"noMarginBottom"}>
          {deliverySection()}
          {/* <SocialGrid.Column width={4}>
            <RowBasicPadding>
              <label>{formatMessage({ ...descriptors[CampaignType.autoPostLabel] })}</label>
            </RowBasicPadding>
            <GridNoMargin>
              <label>{formatMessage({ ...descriptors[CampaignType.autoPost] })}</label>
              <Checkbox
                id={"isAutoPost"}
                name={"isAutoPost"}
                checked={isAutoPost}
                toggle
                onChange={(e, data) => {
                  handleSetFieldValue!(`${data.id}`, data.checked);
                }}
              />
            </GridNoMargin>
          </SocialGrid.Column> */}
          <SocialGrid.Column className={"deleteSection"} width={5} floated={"left"}>
            <Button
              data-elm-id={`campaignInfoEditPromptsBtn`}
              type={"button"}
              onClick={addPromptHandler}
              floated={"right"}
              className={"secondary rounded"}>
              Edit Prompts
            </Button>
          </SocialGrid.Column>
        </SocialGrid>
        <PromptCards
          fromEdit={true}
          editable={editable}
          initialPromptsIds={initialPromptsIds}
          promptsSorted={promptsSorted}
          isAutoPost={isAutoPost}
          deliveryMode={deliveryMode}
          values={values.prompts}
          handleSetFieldValue={(field: string, value: any) => {
            handleSetFieldValue!(`prompts.${field}`, value);
          }}
          handlePromptsSorted={final => {
            setPromptsSorted(final);
            if (handlePromptsSorted) {
              handlePromptsSorted(final);
            }
          }}
        />
      </div>
    </Form>
  );
};

export const CampaignInformationForm = CampaignClass
