import { Grid } from "semantic-ui-react";
import styled from "styled-components";
import { theme } from "../../styling/theme";

const thumbnailSize = (thumbnail?: string): string => {
  if (thumbnail) {
    return `url("${thumbnail}") no-repeat center center`;
  }

  return `${theme.backgroundColor} no-repeat center center`;
};

export const ImagePreview = styled(Grid.Column)`
  width: 320px;
  height: 320px;
  background: ${(props: any) => thumbnailSize(props.imageurl)};
  background-size: ${(props: any) => (props.imageurl ? "contain" : "inherit")};
`;

export const UploadProgressDisplay = styled.div<{ active: any; progress: number; invalid: any }>`
  display: ${(props: any) => (props.active ? "block" : "none")};
  height: 100%;
  width: ${(props: any) => props.progress * (props.invalid ? 50 : 60)}px;
  position: relative;
  top: ${(props: any) => (props.invalid ? "-70px" : "-81px")};
  border-radius: ${(props: any) => (props.invalid ? 5 : 6)}px;
  background-color: rgba(0, 0, 0, 0.5);
`;
