import React from "react";
import styled from "styled-components";
import { ConfigContext } from "../../../../../../contexts/appContexts";

import { AppContext } from "../../../../../../providers";
import * as DTO from "../../../../../../interfaces";
import { coachWithWritePermission, removeWhiteSpace } from "../../../../../../utils";

import { Tag, Wrapper, Input, TagDelete } from "./styled";

import "./../styles.scss";
import { TagElement } from "../../../../../../interfaces";
interface Props {
  tags: TagElement[];

  placeholder?: string;
  dataElmId: string;
  tagDeleteIcon?: string;
  addTagOnEnterKeyPressed?: boolean;

  tagDeleteStyle?: string;
  tagStyle?: string;
  inputStyle?: string;
  wrapperStyle?: string;

  onTagsChanged: (tags: TagElement[]) => void;
  onInputChanged?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const HashTag: React.FC<Props> = ({
  tags,
  tagDeleteIcon = "x",
  dataElmId,
  placeholder = "Type some Tags...",
  addTagOnEnterKeyPressed = true,
  tagDeleteStyle,
  tagStyle,
  inputStyle,
  wrapperStyle,
  onTagsChanged,
  onInputChanged,
}) => {
  const input = React.useRef<HTMLInputElement | null>(null);
  const { keywordMaxLength } = React.useContext(ConfigContext);
  const { userContext } = React.useContext(AppContext);
  const withWritePermission = coachWithWritePermission(DTO.WebComponents.PLAYERS, userContext);

  const addTags = (newTags: string[]) => {
    let i = tags.length + 1;
    onTagsChanged(
      tags.concat(
        newTags.map(newTag => {
          return {
            index: i++,
            displayValue: removeWhiteSpace(newTag).replace(/#/g, ""),
          };
        })
      )
    );
    clearInput();
  };

  const onInputKeyUp = (e: any): void => {
    if (withWritePermission) {
      if (e.key === "Enter") {
        e.preventDefault();
        const inputValue = e.target.value || "";
        if (addTagOnEnterKeyPressed) {
          onNewInput(inputValue);
        }
      }
    }
  };

  const onNewInput = (newInputValue: string): void => {
    if (withWritePermission && newInputValue.length > 0) {
      const inputValues = newInputValue.split(/[ ,]/);

      const tagsToAdd: string[] = [];
      const lowercaseTags: string[] = [];
      inputValues.forEach(value => {
        const inputValue = removeWhiteSpace(value).replace(/#/g, "");
        const lowercaseTag = inputValue.toLowerCase();
        const notAddedSoFar = lowercaseTags.indexOf(lowercaseTag) === -1;
        const inputNotEmpty = inputValue !== "";
        if (inputNotEmpty && addTagOnEnterKeyPressed && notAddedSoFar) {
          tagsToAdd.push(inputValue);
          lowercaseTags.push(lowercaseTag);
        }
      });
      if (tagsToAdd.length > 0) {
        addTags(tagsToAdd);
      }
    }
  };

  const inputWillChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onInputChanged && withWritePermission) {
      onInputChanged(e);
    }
  };

  const onInputKeyDown = (e: any): void => {
    if (withWritePermission) {
      if (e.key === "Enter") {
        e.preventDefault();
      }
      if (e.key === "Backspace" && e.target.selectionStart === 0) {
        onTagsChanged([...tags.splice(0, tags.length - 1)]);
      } else if (e.key !== "Backspace" && e.target.value.length >= keywordMaxLength) {
        e.preventDefault();
      }
    }
  };

  const clearInput = (): void => {
    if (input?.current) input.current.value = "";
  };
  const focusInput = (): void => {
    input!.current!.focus();
  };

  const removeTag = (index: number): void => {
    if (withWritePermission) {
      onTagsChanged([...tags.filter(tag => tag.index !== index)]);
    }
  };

  const renderTags = (): any => {
    const TagComponent = getTagStyledComponent();
    const Delete = getTagDeleteComponent();
    const DeleteIcon = getDeleteIcon();

    return tags.length > 0
      ? tags.map((tag, index) => (
          <TagComponent key={index}>
            {tag && tag.displayValue}
            <Delete onClick={() => removeTag(tag.index)}>{DeleteIcon}</Delete>
          </TagComponent>
        ))
      : null;
  };

  const renderPlaceholder = (): string | undefined => {
    return tags && tags.length > 0 ? undefined : placeholder;
  };

  const getDeleteIcon = (): string => {
    return tagDeleteIcon;
  };

  const getTagDeleteComponent = () => {
    return tagDeleteStyle
      ? styled(TagDelete)`
          ${tagDeleteStyle}
        `
      : TagDelete;
  };

  const getTagStyledComponent = () => {
    return tagStyle
      ? styled(Tag)`
          ${tagStyle}
        `
      : Tag;
  };

  const getInputWrapperStyledComponent = () => {
    return wrapperStyle
      ? styled(Wrapper)`
          ${wrapperStyle}
        `
      : Wrapper;
  };

  const getInputStyledComponent = () => {
    return inputStyle
      ? styled(Input)`
          ${inputStyle}
        `
      : Input;
  };

  const InputWrapper = getInputWrapperStyledComponent();
  const InputComponent = getInputStyledComponent();
  return (
    <InputWrapper onClick={focusInput}>
      {renderTags()}
      <InputComponent
        className={"noBorderInput"}
        ref={input}
        data-elm-id={dataElmId}
        onChange={inputWillChange}
        placeholder={renderPlaceholder()}
        type="text"
        onKeyUp={onInputKeyUp}
        onKeyDown={onInputKeyDown}
        disabled={!withWritePermission}
        onPaste={text => {
          onNewInput(text.clipboardData.getData("Text"));
          text.preventDefault();
        }}
      />
    </InputWrapper>
  );
};
