import * as React from "react";
import { Modal, Button, Input, Header } from "semantic-ui-react";

import { AppContext } from "../../../../../providers";
import { CategoriesServices } from "../../../../../services";
import { Rules } from "../../../../../utils";

import { modalReducer } from "./modalReducer";
import "./styles.scss";
import { UserGroups } from "../../../../../interfaces";
import { get } from "lodash";

interface OwnProps {
  opened: boolean;
  coachCategories: string[];
  scriptCategories: string[];
  acceptHandler: (category: string) => void;
  rejectHandler: () => void;
}

type Props = OwnProps;

const dataElmId = "addScriptCategory";

/**
 * Add script Category Components
 */
const AddScriptCategoryComponentClass: React.FC<Props> = ({
  opened,
  coachCategories,
  scriptCategories,
  acceptHandler,
  rejectHandler,
}) => {
  const { userContext } = React.useContext(AppContext);
  const [state, dispatch] = React.useReducer(modalReducer, { open: opened, loading: false });
  const [category, setCategory] = React.useState<string>("");
  const isAdmin = userContext?.account.groups.some(g => g === UserGroups.ADMIN);
  const coachId = get(userContext, "coach.id", "");

  React.useEffect(() => {
    if (opened) {
      dispatch({ type: "open" });
    } else {
      dispatch({ type: "close" });
    }
  }, [opened]);

  const saveCategory = (): void => {
    const isANewCategory =
      coachCategories.filter(cat => {
        return cat.toLowerCase() === category.toLowerCase();
      }).length === 0;

    const includedOnScriptCategories =
      scriptCategories.filter(cat => {
        return cat.toLowerCase() === category.toLowerCase();
      }).length !== 0;

    if (isANewCategory && !includedOnScriptCategories) {
      const addNewCategory = async () => {
        dispatch({ type: "loading" });
        CategoriesServices.add(
          isAdmin ? -1 : coachId,
          {
            name: category.trim(),
          },
          "VIDEO_SCRIPT"
        ).finally(() => {
          dispatch({ type: "loaded" });
          acceptHandler(category);
        });
      };
      addNewCategory();
    } else if (includedOnScriptCategories) {
      rejectHandler();
    } else {
      acceptHandler(category);
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter" && category.length >= 1) {
      saveCategory();
    }
  };

  return (
    <Modal
      closeIcon
      size={"small"}
      open={state.open}
      onClose={() => {
        dispatch({ type: "close" });
        rejectHandler();
      }}
    >
      <Modal.Header className="aiModalHeader">
        <Header.Content>
          <label>Create Script Category</label>
        </Header.Content>
      </Modal.Header>

      <Modal.Content>
        <Input
          fluid
          placeholder={"Enter a new category"}
          key={dataElmId + "Input"}
          data-elm-id={dataElmId + "Input"}
          value={category}
          onKeyUp={handleKeyPress}
          className={"categoryText"}
          onChange={(e, data) => {
            if (data!.value.length <= Rules.MAX_CATEGORY) {
              setCategory(`${data!.value}`);
            }
          }}
          loading={state.loading}
        />
      </Modal.Content>

      <Modal.Actions className="actionsSection">
        <Button
          data-elm-id={dataElmId + "saveBtn"}
          primary
          disabled={state.loading || category.length < Rules.MIN_CATEGORY}
          onClick={saveCategory}
        >
          Save
        </Button>

        <Button data-elm-id={dataElmId + "cancelBtn"} secondary onClick={rejectHandler}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const AddScriptCategoryComponent = AddScriptCategoryComponentClass;
