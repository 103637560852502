import styled from "styled-components";
import { Grid } from "semantic-ui-react";
import { theme } from "../../styling/theme";

export const StickersContainer = styled(Grid.Row)({
  display: "flex !important",
  marginTop: 0,
  padding: "22px 14px 27px 14px !important",
  maxWidth: "995px !important",
  minWidth: "922px !important",
  backgroundColor: `${theme.backgroundColor}`,
});

export const StickerListGrid = styled(Grid)({
  backgroundColor: "#fff",
  display: "flex !important",
  marginTop: "0px !important",
  marginLeft: "0px !important",
  width: "100%",
  maxWidth: `${theme.maxWidthSizeContainer}px`,
  padding: "30px 0px !important",
  flexWrap: "wrap",
});

export const TabContentGrid = styled(Grid)({
  backgroundColor: "#fff",
  display: "flex!important",
  marginTop: "0!important",
  marginLeft: "0!important",
  width: "100%",
  padding: "60px 73px 60px 41px !important",
  flexWrap: "wrap",
  maxWidth: `${theme.maxWidthSizeContainer}px`,
  marginBottom: "0 !important",
});
