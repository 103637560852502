import * as React from "react";

import { Header, Table } from "semantic-ui-react";
import { ConfigContext } from "../../../contexts/appContexts";

import { ProspectResponse } from "../../../interfaces";
import { printDate } from "../../../utils";

interface Props {
  prospect: ProspectResponse;
  isAdmin?: boolean;
}

const ProspectFC: React.FC<Props> = ({ prospect, isAdmin = false }) => {
  const { email, createdDate, sharedLinkId } = prospect;
  const { deployBaseUrl } = React.useContext(ConfigContext);

  return (
    <Table.Row>
      <Table.Cell />
      <Table.Cell className={"flexCell"}>
        <Header as="h4" image>
          <Header.Content>{email}</Header.Content>
        </Header>
      </Table.Cell>
      <Table.Cell>{printDate(createdDate!)}</Table.Cell>
      {isAdmin && sharedLinkId && (
        <Table.Cell>
          <a href={deployBaseUrl + sharedLinkId} data-elm-id="adminCoachLink" target="_blank">
            {sharedLinkId}
          </a>
        </Table.Cell>
      )}
    </Table.Row>
  );
};

export const ProspectCell = ProspectFC;
