import styled from "styled-components";

export const Input = styled.input`
  background: white;
  border: none;
  border-radius: 3px;
  outline: none;
  font-size: large;
  display: inline-block;
  width: 100%;
  color: #474c58;
  font-weight: 400;
  &::-webkit-input-placeholder {
    font-weight: 100;
    font-style: italic;
    color: #474c58;
  }
`;

export const Tag = styled.span`
  float: left;
  background: #77b6ea;
  color: #474c58;
  border-radius: 5px;
  padding: 5px;
  margin: 0 5px 5px 0;
  letter-spacing: 1px;
  cursor: pointer;
`;

export const TagDelete = styled.span`
  color: white;
  background: #474c58;
  font-size: 12px;
  font-weight: bold;
  padding: 0px 5px 1px 5px;
  border-radius: 50%;
  margin: 5px;
  &:hover {
    color: gray;
  }
`;

export const Wrapper = styled.div`
  background: white;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  font-family: "Open Sans", sans-serif;
  height: 111px;
  max-height: 111px;
  overflow-y: auto;
  font-weight: 400;
  border-radius: 7px;
  padding: 10px;
  font-size: large;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;
