interface TermsElement {
  title?: string;
  subtitle?: string;
  h3?: string;
  description?: string[];
  bullets?: any[];
  innerBullets?: any[];
}

const supportEmail =
  '<a href="mailto:support@socialCoach.io" style="padding: 0;color: #1890ff;">support@socialcoach.io</a>';
export const privacyPolicy: TermsElement[] = [
  {
    subtitle: "1. Scope of Privacy Statement",
    description: [
      "This Privacy Statement (“Privacy Statement”) describes how SocialCoach, LLC and its affiliates and subsidiaries (collectively, “we,” “us,” “our,” “SocialCoach” or the “Company”) handle your personal information in connection with the following (collectively, the “Services”): the Company’s websites (including www.SocialCoach any other website where this Privacy Statement is posted), SocialCoach tools, documents, emails, and applications (including mobile applications), and other services. By using the Services, you expressly consent to the collection, storage, use and disclosure of your personal information as described in this Privacy Statement.",
    ],
  },
  {
    subtitle: "2. Commitment to Privacy",
    description: [
      "The Company has a strong commitment to providing information tailored to your individual needs while providing excellent service to all of our visitors and customers, including respecting concerns about privacy. We understand that you may have questions about whether and how we collect and use information. This Privacy Statement details the steps we take to respect your privacy concerns. If, after reviewing this Privacy Statement, you have any questions or privacy concerns, please send an email to privacy@SocialCoach.io or a letter to:",
      "SocialCoach",
      "Privacy – Legal Department",
      "3857 Birch Street #75, Newport Beach, CA 92660",
    ],
  },
  {
    subtitle: "3. Collection of Your Information",
    description: [
      "Personal Information. You may provide us with personal information in a variety of circumstances, e.g., by creating an account through the Services (“SocialCoach Account”), updating your SocialCoach Account profile, registering for or participating in a marketing or transaction event, submitting an employment application, completing online forms, or entering into a transaction with us. This information may include, without limitation, information such as your first and last name, e-mail address, mailing address, telephone number, username, password, billing information, financial information and other information exchanged in connection with real estate transactions and related financing transactions. In some cases, this information includes government-issued identification documents or related data (such as a driver license number). We also may acquire personal information from other sources such as offline records, publicly available information, or from third parties. We use personal information to provide the Services and as discussed in this Privacy Statement.",
      "Automatically-Collected Information. We may automatically collect information about the computer or devices (including mobile devices) you use to access the Services, and your interactions with the Services. For example, we may collect and store information such as your browser type, IP address, language, operating system, location of your wireless device (e.g., latitude and longitude), the state or country from which you accessed the Services, unique device identifier (e.g., an IDFA on Apple devices like the iPhone or iPad, or the Android Ad ID), the pages you view, length of time spent on pages, communications with other users through the Services, the Services you use and the manner in which you use such Services (e.g., the content you access, view, click on, search for, post, favorite, vote, follow, share, upload, or tag), the date and time of your visit, the websites you visited immediately before and after visiting our Company websites, error logs, and other hardware and software information, as well as other geographic and demographic information. We may use third party analytics providers and technologies, including cookies and similar tools, to assist in collecting this information. We may use this information to formulate statistical models about use of the Services, enhance the Services for our users, and provide you with information about new opportunities, and tailored content, advertising, marketing and as otherwise discussed in this Privacy Statement.",
      "SocialCoach Account. Certain information specified in the preceding paragraph (such as what content you access, view, click on or search for on the Services, the devices from which you access such content and the length of time for which you view same), may be associated with your SocialCoach Account. SocialCoach may use the account details from your SocialCoach Account across all the Services that SocialCoach offers. Accordingly, if you have created a SocialCoach Account for one Company website or aspect of the Services, you may be able to use the same username and password for accounts on other Company websites or with respect to other aspects of the Services, without creating accounts for each such Company website or aspect of the Services. Related information may be used as permitted by this Privacy Statement.",
      "How to Disable the Collection of Location Information. If you are using an Apple device, click on “General,” then “Reset,” then “Reset Location and Privacy.” This will turn off location functionality for all of your apps so that the next time you download or use an app, you will be asked again to provide your consent for us and others to access your location information.",
      "If you are using an Android device, visit the device settings, click “Location,” and then click the boxes for the particular applications for which you would like to disable location services.",
      "Our Use of Cookies. The Services use “cookie” technology and similar online tools, such as web beacons and web pixels. “Cookies” are small files that a website stores on a user’s computer or device. The Services use cookies for various purposes, including to keep the information you enter on multiple pages together. Some of cookies we use are “session” cookies, meaning that they are automatically deleted from your hard drive after you close your browser at the end of your session. Session cookies are used to optimize performance of the Services and to limit the amount of redundant data that is downloaded during a single session. We also use “persistent” cookies, which remain on your computer or device unless deleted by you (or by your browser settings). We use persistent cookies for various purposes, such as statistical analysis of performance to ensure the ongoing quality of our services. We and third parties also use session and persistent cookies for analytics and advertising purposes, as described below. Most web browsers automatically accept cookies, but you may set your browser to block certain cookies (consult the instructions for your particular browser on how to do this). Please note that if you decide to block cookies, this may interfere with your ability to perform certain transactions, use certain functionality, and access certain content on the Services.",
      "Google Analytics. The Company websites may use Google Analytics, a vendor’s service that uses cookies, web beacons, web pixels and/or similar technology to collect and store anonymous information about you, which may include non-personal information described above. You can read Google Analytics’ privacy policy at http://www.google.com/analytics/learn/privacy.html. You can opt-out from being tracked by Google Analytics in a particular browser on a particular device by downloading and installing the Google Analytics Opt-out Browser Add-on for that browser at http://tools.google.com/dlpage/gaoptout?hl=en.",
      "Adobe Analytics. The Company websites may use Adobe’s analytics and on-site personalization services, which use cookies, web beacons, web pixels and/or similar technology to collect and store information about you or your device or browser, which may include non-personal information described above. For information regarding how Adobe may handle information collected through our use of its services, and to learn about your options for controlling this activity, please visit http://www.adobe.com/privacy/marketing-cloud.html.",
      "User-Initiated Communication. From time to time, portions of the Services may enable you to send emails and other types of messages to us or to third parties and to participate in bulletin boards and discussion groups. We have the right to use reproduce, modify, adapt, publish, translate, create derivative works from, distribute, copy, and display all such emails, messages, postings to bulletin boards and discussion groups throughout the world in any form, pursuant to Section 6 of the Website Terms of Use. Among other things, this right allows us to review your messages with other users to enforce our Terms of Use. Whenever you choose to initiate these kinds of communication with us, or anyone else, you may be contacted in return. Such communications you send, when viewed by others, may be identified by your actual name and/or your username. Also, remember that our bulletin boards and discussion groups are open to the public, and, therefore, your postings can be seen by anyone, may be indexable by search engines, and are not protected by us or any other entity. Please use your discretion when deciding whether and what to post and whom to contact and message. We reserve the right, in our sole discretion, to monitor, edit or delete postings from our bulletin boards and discussion groups. This reservation of rights shall not under any circumstances obligate us to conduct such edits or deletions, nor shall it cause us to be liable for any such edits or deletions.",
      "The Services may also contain plugins for other websites, such as Facebook, Twitter, YouTube, LinkedIn and Google. We recommend reviewing the privacy policies with respect to the social plugins for each of these websites, prior to clicking on such plugins.",
    ],
  },
  {
    subtitle: "4. Use of Your Information",
    description: [
      "We will use your information for the purpose for which you provided it or for which we collected it, and for other appropriate purposes. For example, we may use your information to:",
    ],
    bullets: [
      "Create and maintain your SocialCoach Account and account profile, registration information and communication preferences;",
      "Enhance the user experience;",
      "Perform research and analytics;",
      "Customize, personalize and deliver the content and advertising that you see on the Services and elsewhere;",
      "Respond to and fulfill your requests for Services or other inquiries; Determine your eligibility for certain marketing or transaction events, services, gifts, prizes, and special features of the Services;",
      {
        main: "Call, email or otherwise contact you:",
        innerBullets: [
          "to facilitate marketing or transaction events for which you are registered; regarding new opportunities relating to marketing or transaction events or our other Services;",
          "to send you information about topics that may be of interest to you; to communicate in relation to your SocialCoach Account;",
          "to alert you to the latest developments and features of the Services; and",
          "for other marketing, transactional, informational or research purposes.",
        ],
      },
      "Administer, register or enroll you in, or facilitate your participation in recreational, educational or entertainment activities; surveys or questionnaires; promotions or sweepstakes, or any other services, events or activities sponsored by us or third parties, or offered in connection with our Services;",
      "Send you prizes and gifts;",
      "Enforce our Terms of Use and other contractual rights;",
      "Protect our rights and property and the rights and property of others; and Comply with law.",
    ],
  },
  {
    subtitle: "5. Tailored Advertising",
    description: [
      "The Services may include third party tailored ad technology which enables customized and targeted ads to be displayed to you through the Services and on third party websites, apps and services. When you use the Services, we or third parties operating ad technology may use information that is collected through cookies, identifiers such as an IDFA on iOS devices or Android Advertising IDs, web beacons, pixels, or clear GIFs to ensure that appropriate ads are presented and to perform analytics concerning your use of the Services and other websites, apps and services tracked by these third parties. These technologies also may help control the number of times you see a given ad, deliver ads that relate to your interests, and measure the effectiveness of ad campaigns. To the extent any of this information is collected by third parties, you acknowledge and agree that such collection and use is governed by those third parties’ privacy policies and we are not responsible for the privacy practices of such third parties.",
      "If you are interested in more information about tailored ads and your choices to prevent certain third parties from delivering tailored ads, you may visit the following third party websites: the Network Advertising Initiative Consumer Opt-Out Page or the Digital Advertising Alliance’s Consumer Opt-Out Page. Please note that you will still receive ads even if you opt-out of tailored ads. In that case, the ads will just not be tailored to your interests by participating companies.",
      "If you are using an iOS device and you do not want to receive tailored in-application ads that relate to your interests and are based on your mobile device’s identifier, you may opt-out by following the instructions at http://oo.apple.com. If you are using an Android device and you do not wish to receive tailored in-application ads that are based on your mobile device’s identifier, you can use the opt-out methods described by Google.",
    ],
  },
  {
    subtitle: "6. Do-Not-Track Signals",
    description: [
      "We do not respond to or alter our practices detailed herein based upon your selection of the “Do Not Track” setting or other “opt out” setting or feature that may be offered by your browser; however, we reserve the right to do so in the future.",
    ],
  },
  {
    subtitle: "7. Disclosure of Your Information",
    description: [
      "We may share your information in the following ways:",
      "We may make information collected through the Services available to subsidiaries and affiliated companies that are under common ownership or control within the Company family. If you express an interest on a particular selling method, the SocialCoach business partner and its affiliates and representatives, and potentially other parties related to the Services, are given access to your information and may contact you.",
      "We may share information about our visitors, customers, or former customers with the following types of companies that perform services on our behalf or with whom we have joint marketing agreements:",
      "Non-financial companies such as envelope stuffers, fulfillment service providers, payment processors, data processors, customer/support services, etc.",
      "Financial service providers such as companies engaged in selling real property, banking, mortgage lending, consumer finance, securities, and insurance.",
      "We may share or sell, as allowed by law, information about you with other companies who we believe may have products and services of interest to you. If you would like to exercise a legal right to opt-out of our sharing of your information with these other companies for their direct marketing purposes, please follow our Third Party Opt-Out Policy described in Section 9 below or contact us as described in Section 16.",
      "We may share your information with any person or entity where we believe in good faith that such disclosure is necessary or otherwise appropriate to: (a) comply with the law or in response to a subpoena, court order, government request, or other legal process; (b) produce relevant documents or information in connection with litigation, arbitration, mediation, adjudication, government or internal investigations, or other legal or administrative proceedings; (c) protect the interests, rights, safety, or property of the Company or others; (d) enforce the Terms of Use on the Services or other contractual obligations; (e) provide users of the Services with the services they request; (f) provide you with special offers or promotions from us; (g) allow another company that acquires the Company or some or all of its assets to continue to serve you; or (h) operate the Company’s systems properly. We may share your information with any person or entity when we have your consent. We may use and share non-personal information about you or your use of the Services, including any de-identified and aggregated data with third parties without limitation.",
    ],
  },
  {
    subtitle: "8. Opting Out of Communications from Us",
    description: [
      "By creating a SocialCoach Account or registering for or participating in any marketing or transaction event on one of our Company websites, you agree to receive direct mail, email newsletters and other promotional email communications, as well as promotional telephone communications. For example, from time to time we may send you email notices or news updates alerting you to new features, products, promotions, or services pertaining to offerings from us, new opportunities, surveys or our other Services, and, if you have created a SocialCoach Account or registered for marketing or a transaction on one of our Company websites, we may send you direct mail or call you with respect to same.",
      "When you provide your telephone number or text JOIN to a number we give you, you are subscribing to receive Alerts and/or Notifications by text message from us to the phone number you provide, and you are authorizing our use of your phone number (which you confirm that you own) to send you text messages with marketing messages, alerts and updates about property selling methods you are interested in. You also consent to our use of an automatic telephone dialing system for telephone calls and texts (if you have opted-in to receive text message marketing).",
    ],
    bullets: [
      "You may alter your preferences with respect to promotional SocialCoach Communications by contacting us at privacy@SocialCoach.io",
      "If you no longer wish to receive direct mail Auction.com Communications, you may opt out by contacting us at privacy@SocialCoach.io",
      "If you no longer wish to receive promotional email SocialCoach Communications, you may opt out of receiving these communications by choosing the “Unsubscribe” option available as a link in every promotional email or by contacting us at privacy@SocialCoach.io",
      "If you no longer wish to receive promotional telephone SocialCoach Communications, you may opt out of receiving these communications by telling the representative who calls you with such SocialCoach Communication or by contacting us at privacy@SocialCoach.io.",
      `If you no longer wish to receive text message SocialCoach Communications, you may opt out by replying with the word "STOP" to a text message from us or calling us at (949) 723-9634. You may request help by replying with the word "HELP" to a text message from us, or calling (949) 723-9634, or going to http://www.SocialCoach.io`,
    ],
    // description: [
    //   "<br>",
    //   "You may revoke your consent to our use of an automatic telephone dialing system for telephone calls and texts at any time through reasonable means.",
    //   "We will gladly comply with all requests to opt out of or unsubscribe from promotional communications, but reserve the maximum amount of time allowed by law to process such requests. You cannot opt out of certain transactional emails or telephone calls.",
    // ],
  },
  {
    subtitle: "9. Notice of Privacy Rights of California Residents",
    description: [
      "We have adopted a policy of not sharing the personal information of customers with third parties for their direct marketing purposes if those customers request that we do not do so, subject to the exceptions provided under California Civ. Code § 1798.83 (“Third Party Opt-Out Policy”). By “customer,” we mean an individual who is a resident of California and who provides personal information to us during the creation of, or throughout the duration of, an “established business relationship” (as defined in California Civ. Code § 1798.83), where the business relationship is primarily for personal, family, or household purposes. Eligible customers may make such a request by sending us an email at privacy@SocialCoach. When contacting us, please indicate your name, address, and email address. Please note that there is no charge for this request.",
    ],
  },
  {
    subtitle: "10. Security and Account Protection",
    description: [
      "We have implemented commercially reasonable physical, administrative, technical, and electronic security measures to protect against the loss, misuse, and alteration of your personal information. Despite our efforts, however, no security measures are perfect or impenetrable, and we do not promise that your information will remain secure. We appreciate your help in safeguarding the integrity of your own and others’ privacy. We encourage you to let us know immediately if you suspect that any personal information you shared with us is being used contrary to this Privacy Statement.",
    ],
  },
  {
    subtitle: "11. Children's Information",
    description: [
      "The Services are not directed toward persons under 18 years of age. We do not knowingly market to or collect any personal information from children under the age of 18. If you are under 18, you are not permitted to submit any personal information to us or the Services. If you provide information to us through the Services, you represent that you are 18 years of age or older. If a parent or guardian believes that we have in our database the personal information of a child under the age of 13, please contact us immediately at privacy@SocialCoach.io to request that we remove such information from our records.",
    ],
  },
  {
    subtitle: "12. Accessing Personal Information, and Related Options",
    description: [
      "If personal information you provided changes or is inaccurate, you agree to update your information by updating the account information located in your SocialCoach Account profile. Individuals in some countries outside the United States have certain legal rights to access certain information we hold about them and to obtain its correction, amendment or deletion (in addition to the self-serve option described in the previous sentence), as well as to object to the processing of their personal information or withdraw previously provided consent to such processing. (Withdrawal of consent does not affect the lawfulness of data processing based on consent before its withdrawal, and in some cases we may continue to process personal data on legal grounds other than consent, such as compliance with law.) To exercise those rights, they may contact us as described below.",
    ],
  },
  {
    subtitle: "13. Third Party Links and Services",
    description: [
      "The Services may contain links to third-party websites, including identity verification and social networking websites. Your use of these features may result in the collection or sharing of information about you, depending on the feature. Please be aware that we are not responsible for the content or privacy practices of other websites or services, including those to which we link. We do not endorse or make any representations about third-party websites or services. The personal information you choose to provide to or that is collected by these third parties is not covered by our Privacy Statement. We strongly encourage you to read such third parties’ privacy statements.",
    ],
  },
  {
    subtitle: "14. Amendments to Privacy Statement",
    description: [
      "We may amend this Privacy Statement at any time by posting the amended terms on the Services. Unless a different effective date is specified in the update, any changes to this Privacy Statement will become effective upon posting. Your continued use of the Services following the effective date means that you accept such revisions. If we make any material changes to this Privacy Statement, we will post the changes here and notify you in the manner and to the extent required by law.",
    ],
  },

  {
    subtitle: "15. International Transfers of Information",
    description: [
      "Some of the uses and disclosures mentioned in this Privacy Statement may involve the transfer of your personal information to various countries around the world that may have different levels of privacy protection than your country. By submitting your personal information, you consent to such transfers. By using the Services, or by submitting your personal information to us, you expressly consent to the storage and processing of your personal information in the United States and in other jurisdictions.",
    ],
  },
  {
    subtitle: "16. Contacting Us",
    description: [
      "Should you have any questions about this Privacy Statement, contact us by sending an email to privacy@SocialCoach.io. Please provide your name, mailing address, telephone number and email address so we can service you more quickly. You can also call us at (949) 723-9634 or write to us at Privacy – Legal Department, Office: 3857 Birch Street #75, Newport Beach, CA 92660.",
      "We may record the conversations our agents or employees have with you, including without limitation telephone conversations. By engaging in any telephone conversation with our agents or employees, you consent to our recording such telephone call. We do this from time to time to monitor the quality of service and accuracy of information our agents and employees give to you, to ensure that your instructions are followed, and to ensure compliance with the Terms of Use and applicable law. No agent or employee of the Company is authorized to waive this or any other provisions of our Company policies and procedures.",
    ],
  },
];
