import * as React from "react";
import { Formik } from "formik";
import { get } from "lodash";

import { ShortLinksServices } from "../../../services";

import { validationSchema } from "./validations";

import { FirstLoginWebAddressMobile } from "../../../components/Players/FirstLogin/Mobile/WebAddress";
import { CoachBasic, ShortLinkResponse } from "../../../interfaces";
import { manageWebAddress } from "../../../utils";

interface Props {
  playerId?: number;
  coachInformation?: CoachBasic;
  successHandler: (webAddress: string) => void;
}

export const FirstWebAddressMobileForm: React.FC<Props> = ({ successHandler, playerId, coachInformation }) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [shortLink, setShortLink] = React.useState<ShortLinkResponse | undefined>(undefined);
  const [randomSlug, setRandomSlug] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (playerId) {
      ShortLinksServices.getByPlayerId(playerId)
        .then(response => {
          setShortLink(response);
        })
        .finally(() => {
          setLoading(false);
        });

      ShortLinksServices.getRandomSlug()
        .then(response => {
          setRandomSlug(response);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [playerId]);

  return (
    <div>
      {!loading && (
        <Formik
          validateOnBlur={false}
          validateOnChange={true}
          initialValues={{
            slug: get(shortLink, "slug", randomSlug),
            webAddress: get(shortLink, "webAddress", "https://"),
            step: 0,
          }}
          validationSchema={validationSchema}
          onSubmit={async ({ slug, webAddress }, { setSubmitting, setErrors }) => {
            try {
              const webAddressWithFormat = await manageWebAddress(webAddress!);
              await ShortLinksServices.updateShortLinkInfo(playerId!, slug!, webAddressWithFormat!);
              successHandler(webAddress!);
            } catch (e) {
              setErrors({
                slug: String(e),
              });
            }
            setSubmitting(false);
          }}
        >
          {({ values, errors, setFieldValue, handleSubmit, isSubmitting }) => (
            <FirstLoginWebAddressMobile
              messages={{ error: Object.values(errors) as string[] }}
              handleSetFieldValue={(field, value) => {
                setFieldValue(field, value);
              }}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              values={values}
              coachInformation={coachInformation}
            />
          )}
        </Formik>
      )}
    </div>
  );
};
