import * as React from "react";
import { Field } from "formik";
import MaskedInput from "react-text-mask";
import { FormattedMessage, useIntl } from "react-intl";
import { descriptors, ProfileInformationType } from "./descriptors";
import { fields } from "./formFields";
import { Form, Grid, Image, InputOnChangeData, Loader, Modal, Button } from "semantic-ui-react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Png } from "../../../../assets";
import { phoneNumberMask, dateMask, validAge, getImageAsBase64 } from "../../../../utils";

import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { UserChangePasswordForm } from "../../../../containers/UserChangePasswordForm";

import "../../../../styling/datepicker.scss";
import "../../../../styling/antd.scss";
import "./styles.scss";

interface OwnProps {
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;

  handleChangePassword?: () => void;

  handleSetFieldValue?: (field: string, value: any) => void;

  handleBack?: () => void;

  messages?: { info?: string[]; error?: string[]; warn?: string[] };

  firstName?: string;
  lastName?: string;
  title?: string;
  company?: string;
  birthday?: string;
  phoneNumber?: string;

  photoUrl?: string;
  isSubmitting?: boolean;
  onChangeImage: (img: File) => void;
}

type Props = OwnProps;

const ProfileInformationClass: React.FC<Props> = props => {
  const [imageFile, setImageFile] = React.useState<string | undefined>();

  const { handleChange, handleChangePassword, handleSetFieldValue, isSubmitting } = props;

  const { formatMessage } = useIntl();

  const startDate = props.birthday ? moment(props.birthday, "YYYY-MM-DD").toDate() : undefined;

  return (
    <Grid.Row>
      <div className={"profileInformation"}>
        {isSubmitting && <Loader active size="large" />}
        <span className={"columnTitle"}>
          <FormattedMessage {...descriptors[ProfileInformationType.columnTitle]} />
        </span>
        <div>
          <ImgCrop
            showGrid
            onModalOk={value => {
              getImageAsBase64(value, (base64Image: any) => {
                setImageFile(base64Image);
                props.onChangeImage(value as File);
              });
            }}
          >
            <Upload
              action={(file): string => {
                // getImageAsBase64(file, (base64Image: any) => {
                //   setImageFile(base64Image);
                //   props.onChangeImage(file);
                // });
                return Png.Profile;
              }}
              showUploadList={false}
              listType="picture"
              className="avatar-uploader"
            >
              {imageFile ? (
                <Image src={imageFile} className="profilePicture" />
              ) : (
                <Image src={props.photoUrl || Png.Profile} className="profilePicture" />
              )}
            </Upload>
          </ImgCrop>
        </div>

        {fields.map((field, index) => {
          return (
            <Form.Group widths="equal" key={"coach_profile_information_group" + index}>
              <Form.Input
                key={field.id + index}
                fluid
                data-elm-id={"profileInformationInput" + field.id}
                id={field.id}
                type={field.type}
                name={field.name}
                placeholder={formatMessage({ ...descriptors[field.placeholder] })}
                onChange={handleChange}
                value={props[field.id]}
                disabled={isSubmitting}
              />
            </Form.Group>
          );
        })}

        <Form.Group widths="equal" key={"group4"}>
          <DatePicker
            data-elm-id={"profileInformationBirthday"}
            id={"birthday"}
            name={"birthday"}
            selected={startDate}
            placeholderText={formatMessage({ ...descriptors[ProfileInformationType.birthday] })}
            onChange={(date: any) => handleSetFieldValue!("birthday", date ? date : "")}
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={15}
            maxDate={new Date()}
            minDate={validAge(new Date(), -110)}
            disabled={isSubmitting}
            customInput={<MaskedInput mask={dateMask} />}
          />
        </Form.Group>
        <Form.Group widths="equal" key={"group5"}>
          <div className="field">
            <Field
              name="phoneNumber"
              data-elm-id={"profileInformationPhoneNumber"}
              render={({ phoneField }: { phoneField: any }) => (
                <MaskedInput
                  {...phoneField}
                  value={props.phoneNumber}
                  mask={phoneNumberMask}
                  id="phoneNumber"
                  placeholder={formatMessage({ ...descriptors[ProfileInformationType.phoneNumber] })}
                  type="text"
                  onChange={handleChange}
                  disabled={isSubmitting}
                />
              )}
            />
          </div>
        </Form.Group>
        <Modal
          trigger={
            <Button data-elm-id={`profileInfoChangePwdBtn`} size="large" className={"profileInformationButton"}>
              <FormattedMessage {...descriptors[ProfileInformationType.changePassword]} />
            </Button>
          }
          closeIcon={true}
          size={"tiny"}
        >
          <Modal.Header centered className="aiModalHeader">
            Change Password
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <UserChangePasswordForm />
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </div>
    </Grid.Row>
  );
};

export const ProfileInformation = ProfileInformationClass;
