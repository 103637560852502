import { defineMessages } from "react-intl";

export enum SocialMediaStepType {
  title = "SocialMediaStepType.title",
  subtitle = "SocialMediaStepType.subtitle",

  company = "SocialMediaStepType.company",
  titleField = "SocialMediaStepType.titleField",
  submit = "SocialMediaStepType.submit",
  back = "SocialMediaStepType.back",

  warningTitle = "SocialMediaStepType.warningTitle",
  errorTitle = "SocialMediaStepType.errorTitle",
  infoTitle = "SocialMediaStepType.infoTitle",
}

export const en = {
  [SocialMediaStepType.title]: "Let's accept Terms and Conditions:",
  [SocialMediaStepType.subtitle]: " ",

  [SocialMediaStepType.titleField]: "Title",
  [SocialMediaStepType.company]: "Company",

  [SocialMediaStepType.submit]: "Next",
  [SocialMediaStepType.back]: "Back",

  [SocialMediaStepType.warningTitle]: "Warning",
  [SocialMediaStepType.errorTitle]: "Sign Up error:",
  [SocialMediaStepType.infoTitle]: "Hey",
};

export const descriptors = defineMessages({
  [SocialMediaStepType.title]: {
    id: SocialMediaStepType.title,
    defaultMessage: en[SocialMediaStepType.title],
    description: "Social Network title",
  },
  [SocialMediaStepType.subtitle]: {
    id: SocialMediaStepType.subtitle,
    defaultMessage: en[SocialMediaStepType.subtitle],
    description: "Social Network subtitle",
  },
  [SocialMediaStepType.titleField]: {
    id: SocialMediaStepType.titleField,
    defaultMessage: en[SocialMediaStepType.titleField],
    description: "title",
  },
  [SocialMediaStepType.company]: {
    id: SocialMediaStepType.company,
    defaultMessage: en[SocialMediaStepType.company],
    description: "company text",
  },

  [SocialMediaStepType.submit]: {
    id: SocialMediaStepType.submit,
    defaultMessage: en[SocialMediaStepType.submit],
    description: "Submit text",
  },
  [SocialMediaStepType.back]: {
    id: SocialMediaStepType.back,
    defaultMessage: en[SocialMediaStepType.back],
    description: "Back",
  },
  [SocialMediaStepType.warningTitle]: {
    id: SocialMediaStepType.warningTitle,
    defaultMessage: en[SocialMediaStepType.warningTitle],
    description: "Warning messages title",
  },
  [SocialMediaStepType.errorTitle]: {
    id: SocialMediaStepType.errorTitle,
    defaultMessage: en[SocialMediaStepType.errorTitle],
    description: "Error Messages title",
  },
  [SocialMediaStepType.infoTitle]: {
    id: SocialMediaStepType.infoTitle,
    defaultMessage: en[SocialMediaStepType.infoTitle],
    description: "Info messages title",
  },
});
