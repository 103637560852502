import { defineMessages } from "react-intl";

export enum PlayerDetailType {
  resetPassword = "PlayerDetailType.resetPassword",
  subscriptionTitle = "PlayerDetailType.subscriptionTitle",
  addPromptBtn = "PlayerDetailType.addPromptBtn",
  uploadFilesBtn = "PlayerDetailType.uploadFilesBtn",
  title = "PlayerDetailType.title",
  createCampaignBtn = "PlayerDetailType.createCampaignBtn",
  welcomePromptRequired = "PlayerDetailType.welcomePromptRequired",
}

export const en = {
  [PlayerDetailType.resetPassword]: "Reset Password",
  [PlayerDetailType.subscriptionTitle]: "Subscription",
  [PlayerDetailType.addPromptBtn]: "Add Prompt",
  [PlayerDetailType.createCampaignBtn]: "Create a campaign",
  [PlayerDetailType.uploadFilesBtn]: "Upload Files",
  [PlayerDetailType.welcomePromptRequired]: "A welcome prompt is required to complete your account.",
  [PlayerDetailType.title]: "Library",
};

export const descriptors = defineMessages({
  [PlayerDetailType.welcomePromptRequired]: {
    id: PlayerDetailType.welcomePromptRequired,
    defaultMessage: en[PlayerDetailType.welcomePromptRequired],
    description: "",
  },
  [PlayerDetailType.resetPassword]: {
    id: PlayerDetailType.resetPassword,
    defaultMessage: en[PlayerDetailType.resetPassword],
    description: "",
  },
  [PlayerDetailType.subscriptionTitle]: {
    id: PlayerDetailType.subscriptionTitle,
    defaultMessage: en[PlayerDetailType.subscriptionTitle],
    description: "",
  },
  [PlayerDetailType.addPromptBtn]: {
    id: PlayerDetailType.addPromptBtn,
    defaultMessage: en[PlayerDetailType.addPromptBtn],
    description: "",
  },
  [PlayerDetailType.uploadFilesBtn]: {
    id: PlayerDetailType.uploadFilesBtn,
    defaultMessage: en[PlayerDetailType.uploadFilesBtn],
    description: "",
  },
  [PlayerDetailType.createCampaignBtn]: {
    id: PlayerDetailType.createCampaignBtn,
    defaultMessage: en[PlayerDetailType.createCampaignBtn],
    description: "createCampaignBtn",
  },
  [PlayerDetailType.title]: {
    id: PlayerDetailType.title,
    defaultMessage: en[PlayerDetailType.title],
    description: "title",
  },
});
