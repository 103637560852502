import { actions } from "./actions";
import { CampaignState } from "./initialState";

export const CampaignReducer = (state: CampaignState, action: { name: string }) => {
  const { name } = action;

  switch (name) {
    case actions.CANCEL:
      return {
        ...state,
        openConfirmationModal: false,
        prepareConfirmationModal: false,
      };
    case actions.CLOSE_CONFIRMATION_MODAL:
      return {
        ...state,
        openConfirmationModal: false,
      };
    case actions.OPEN_CONFIRMATION_MODAL:
      return {
        ...state,
        openConfirmationModal: true,
        prepareConfirmationModal: true,
      };
    case actions.PREPARE_TO_SAVE:
      return {
        ...state,
        openConfirmationModal: false,
        prepareConfirmationModal: true,
      };
  }
  return { ...state };
};
