import * as React from "react";
import { Formik } from "formik";
import { get } from "lodash";

import { EditorVideoProjectComponent } from "../../../../../components";
import { Media, CoachSettings, VideoProjectDetail, VideoProjectStatus } from "../../../../../interfaces";
import { SettingsServices } from "../../../../../services";

import { validationSchema } from "./validations";
export interface OwnProps {
  /**
   * Video Project Info
   */
  videoProject?: VideoProjectDetail;
  pageErrors?: string[];

  updatingHandler: (values: any) => void;
}

type Props = OwnProps;

const IntegratedVideoProject: React.FC<Props> = ({ videoProject, updatingHandler, pageErrors }) => {
  const [showHashtagToggle, setShowHashtagToggle] = React.useState(false);
  const [transcript, setTranscript] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    const getSettings = async () => {
      SettingsServices.getAppSettingsByCoachId(`${videoProject!.coachId}`).then(response => {
        const hashtagSettingStatus =
          response.settings.find(s => s.appSettingId === CoachSettings.PLAYER_HASHTAG)?.status || "ACTIVE";
        setShowHashtagToggle(hashtagSettingStatus === "ACTIVE");
      });
    };

    if (videoProject!.coachId) {
      getSettings();
    }

    getSettings();
  }, [videoProject!.coachId]);

  const formDisabled = !(
    videoProject?.status === VideoProjectStatus.SUBMITTED_FOR_EDITING ||
    (videoProject?.status !== VideoProjectStatus.POSTED && videoProject?.editable)
  );

  React.useEffect(() => {
    const transcripts = videoProject?.post?.mediaGroup?.media
      ?.flatMap(m => m.transcripts?.map(t => t.content))
      ?.sort(t => t?.length || 0)
      ?.reverse();
    if (transcripts && transcripts.length > 0) {
      setTranscript(transcripts[0]);
    }
  }, [videoProject?.post?.mediaGroup?.media]);

  console.warn("form clientNotes", videoProject?.clientNotes);
  return (
    <div>
      <Formik
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={false}
        initialValues={{
          videoProjectId: get(videoProject, "videoProjectId", null),
          playerId: get(videoProject, "playerId", null),
          coachId: get(videoProject, "coachId", null),
          createdDate: videoProject!!.createdDate,
          editedDate: get(videoProject, "editedDate", null),
          postedDate: get(videoProject, "postedDate", null),
          statusDate: get(videoProject, "statusDate", null),
          statusLabel: get(videoProject, "statusLabel", null),
          message: get(videoProject, "message", ""),
          hashtags: get(videoProject, "post.hashtags", ""),
          editable: get(videoProject, "editable", false),
          allowShortLink: get(videoProject, "allowShortLink", false),
          allowComplianceDisclaimer: get(videoProject, "allowComplianceDisclaimer", false),
          allowHashtags: get(videoProject, "allowHashtags", false),
          originalMedia: get(videoProject, "originalMedia", []),
          editedMedia: get(videoProject, "editedMedia", []),
          withMediaReady: get(videoProject, "editedMedia.length", 0),
          withMessage: get(videoProject, "message", ""),
          messageToPost: get(videoProject, "messageToPost", ""),
          status: get(videoProject, "status", "SUBMITTED_FOR_EDITING"),
          isAutoPost: get(videoProject, "isAutoPost", false),
          preferredPostDate: get(videoProject, "preferredPostDate", undefined),
          socialNetworks: get(videoProject, "socialNetworks", []),
          socialNetworksPosted: get(videoProject, "post.socialNetworks", []),
          socialNetworksCounter: get(videoProject, "socialNetworksCounter", 0),
          autoCaption: get(videoProject, "autoCaption", false),
          messages: get(videoProject, "messages", []),
          clientNotes: get(videoProject, "clientNotes", undefined),
        }}
        validationSchema={validationSchema}
        onSubmit={async () => {
          // Action removed because the client wants
          // to use TitleSection action to save the prompt
        }}
      >
        {({ values, errors, isSubmitting, handleChange, setFieldValue, handleSubmit }) => {
          updatingHandler(values);

          return (
            <EditorVideoProjectComponent
              messages={{
                error: pageErrors && pageErrors.length > 0 ? pageErrors : (Object.values(errors) as string[]),
              }}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              handleSetFieldValue={setFieldValue}
              handleMediaUpdated={(newMedia: Media[]) => {
                setFieldValue("withMediaForPost", newMedia.length);
                setFieldValue("editedMedia", {
                  media: newMedia.map((item, sortOrder) => {
                    const newMediaObj = {
                      ...item,
                      sortOrder,
                    };
                    return newMediaObj;
                  }),
                  mediaGroupId: get(values.editedMedia, "mediaGroupId", null),
                  type: "REELS",
                });
              }}
              showHashtagToggle={showHashtagToggle}
              disabled={formDisabled}
              transcript={transcript}
              values={values}
              isSubmitting={isSubmitting}
            />
          );
        }}
      </Formik>
    </div>
  );
};

export const VideoProjectInQueueForm = IntegratedVideoProject;
