import { client as axios } from "./axiosClient";
import { Constants, catchServiceException } from "@socialcoach/services";
import * as DTO from "../interfaces";

const COACH_POST_SERVICE = `${Constants.API_URL}/coaches/posts`;
const POST_SERVICE = `${Constants.API_URL}/posts`;

const apiEndpoints = {
  reviewStatus: POST_SERVICE + "/review-status/:postId",
  info: POST_SERVICE + "/:postId",
  removeFromFailed: POST_SERVICE + "/:postId/acknowledge-failures",
  readyToPost: Constants.API_URL + "/players/:playerId/post-ready-content",
};
export class PostServices {
  @catchServiceException()
  static async search(
    coachId: string,
    sort: string = "",
    filters?: DTO.SearchFilter | null,
    page: number | string = 0,
    size: number = 15
  ): Promise<DTO.Page<DTO.Post>> {
    const sortArray = sort.split(",");
    const { data } = await axios.post<DTO.Page<DTO.Post>>(
      `${COACH_POST_SERVICE}/coaches/${coachId}/subscribers?page=${page}&size=${size}`,
      {
        ...filters,
        sortDirection: sortArray.length > 1 ? sortArray[1] : "DESC",
      },
      {
        withCredentials: true,
      }
    );

    return data;
  }

  @catchServiceException()
  static async setReviewStatus(
    postId: number,
    reviewStatus: DTO.ReviewStatus | any,
    changeRequested?: string
  ): Promise<DTO.Post> {
    const { data } = await axios.put<DTO.Post>(
      `${POST_SERVICE}/review-status/${postId}`,
      {
        postReviewStatus: reviewStatus,
        message: changeRequested ? changeRequested.trim() : changeRequested,
      },
      {
        withCredentials: true,
      }
    );

    return data;
  }

  @catchServiceException()
  static async create(params: DTO.PostRequest): Promise<void> {
    const response = await axios.post<void>(
      `${COACH_POST_SERVICE}/bulk`,
      {
        ...params,
      },
      {
        withCredentials: true,
      }
    );

    if (!(response?.status === 201 || response?.status === 202)) {
      return Promise.reject("ERROR POSTING " + response.status);
    }

    return response.data;
  }

  @catchServiceException()
  static async createWithBrand(params: DTO.PostWithBrandRequest): Promise<void> {
    const response = await axios.post<void>(
      `${POST_SERVICE}/brand`,
      {
        ...params,
      },
      {
        withCredentials: true,
      }
    );

    if (!(response?.status === 201 || response?.status === 202)) {
      return Promise.reject("ERROR POSTING " + response.status);
    }

    return response.data;
  }

  @catchServiceException()
  static async createAsPlayer(params: DTO.PostPlayerRequest): Promise<void> {
    const response = await axios.post<void>(
      `${POST_SERVICE}`,
      {
        ...params,
      },
      {
        withCredentials: true,
      }
    );

    if (!(response?.status === 201 || response?.status === 202)) {
      return Promise.reject("ERROR POSTING AS PLAYER" + response.status);
    }

    return response.data;
  }

  @catchServiceException()
  static async needAttentionPost(playerId: number, page: number = 0): Promise<DTO.Page<DTO.PlayerPost>> {
    let body: any = {
      playerId,
      socialNetworkPostStatuses: [DTO.SocialNetworkPostStatus.FAILED],
      postReviewStatuses: [DTO.ReviewStatus.REQUEST_CHANGES],
    };
    const { data } = await axios.post<DTO.Page<DTO.PlayerPost>>(
      `${POST_SERVICE}/search?page=${page}&size=100&sort=createdDate,DESC`,
      body,
      {
        withCredentials: true,
      }
    );

    return data;
  }

  @catchServiceException()
  static async readyToPost(playerId: number): Promise<DTO.PostReadyContent[]> {
    const { data } = await axios.get<DTO.PostReadyContent[]>(
      `${apiEndpoints.readyToPost.replace(":playerId", `${playerId}`)}`,
      {
        withCredentials: true,
      }
    );

    return data;
  }

  @catchServiceException()
  static async getById(postId: number): Promise<DTO.PlayerPost> {
    const { data } = await axios.get<DTO.PlayerPost>(`${POST_SERVICE}/${postId}`, {
      withCredentials: true,
    });

    return data;
  }

  @catchServiceException()
  static async dismissPost(postId: number): Promise<any> {
    const response = await axios.post<any>(`${POST_SERVICE}/${postId}/acknowledge-failures`, {
      withCredentials: true,
    });

    return response;
  }
}
