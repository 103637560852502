import styled from "styled-components";
import { Grid, Card } from "semantic-ui-react";
import { Svg } from "../../../assets";
import { theme } from "../../../styling/theme";

const defaultImage = Svg.PromptPlaceholder;

const thumbnailSize = (thumbnail?: string): string => {
  if (thumbnail) {
    return `url("${thumbnail}") no-repeat center top`;
  }

  return `url(${defaultImage}) no-repeat center center`;
};

export const PromptCardStyle = styled(Card)({
  background: `${theme.lightBackgroundColor} !important`,
  borderRadius: "6.3px !important",
  border: `1px solid ${theme.backgroundColor} !important`,
  boxShadow: `none !important`,
  width: "150px !important",
  height: "160px !important",
  margin: "0px 10px 0 13px !important",
  display: "inline-block",
});

export const WelcomePromptCardStyle = styled(Card)({
  background: `${theme.lightBackgroundColor} !important`,
  border: `1px solid ${theme.buttonColor} !important`,
  borderRadius: "6.3px !important",
  boxShadow: `none !important`,
  width: "150px !important",
  height: "160px !important",
  margin: " 0 15px !important",
  display: "inline-block",
});

export const PromptHeader = styled(PromptCardStyle.Header)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  paddingRight: "7px",
});
export const ThumbnailPreview = styled(Grid.Column)`
  width: 134px;
  height: 75px;
  margin: 8px;
  background: ${(props: any) => thumbnailSize(props.thumbnailurl)};
  background-size: ${(props: any) => (props.thumbnailurl ? "cover" : "inherit")};
  background-color: #eaeaf0;
`;
