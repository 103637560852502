import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Grid, Loader } from "semantic-ui-react";
import { get } from "lodash";
import { Types } from "@socialcoach/services";

import { LoginForm } from "../../LoginForm";
import { AppUserContext } from "../../../providers";

import { LandingResponse } from "../../../interfaces/dtos";
import { CoachingProfileService, PlayerSignUpStep } from "../../../services";
import { redirectToPlayerStep, URLS } from "../../../utils";
import { FloatedContainer } from "../../../styling/baseStyle";

import "./styles.scss";

interface PathProps {
  coachId: string;
}
interface OwnProps {
  group: Types.UserGroups;
}

type Props = OwnProps & RouteComponentProps<PathProps>;

const PlayerSignInPageFC: React.FC<Props> = ({
  match: {
    params: { coachId },
  },
  location,
  history,
  group,
}) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [coachInfo, setCoachInfo] = React.useState<LandingResponse>();

  const bannerURL = get(coachInfo, "bannerUrl", undefined);
  const bannerStyle = bannerURL
    ? {
        backgroundImage: "url(" + bannerURL + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundSize: "cover",
        width: "100%",
        margin: "0 auto",
      }
    : {};

  React.useEffect(() => {
    getPublicInfo(coachId);
  }, []);

  const getPublicInfo = async (identifier: string) => {
    await CoachingProfileService.landingPagePublic(identifier)
      .then(response => {
        setLoading(false);
        setCoachInfo(response);
      })
      .catch(e => {
        setLoading(false);
        setCoachInfo(undefined);
      });
  };

  const content: JSX.Element = (
    <FloatedContainer>
      <div className={"playerWhiteContainer"}>
        <Grid className={"playerContainer"} columns={1}>
          <Grid columns={1} className={"noPaddingMobile gridCentered"}>
            <div className={"userBanner"} style={bannerStyle}>
              {bannerURL && (
                <img
                  src={bannerURL!!}
                  style={{
                    opacity: 0,
                    width: "100%",
                    visibility: "hidden",
                  }}
                />
              )}
            </div>
            <Grid.Column width={16} centered className={"playerSignUpContent noLateralPaddingMobile"}>
              <LoginForm
                group={group}
                successHandler={(context: AppUserContext) => {
                  const { profile, player, signUpStep } = context;
                  if (player && PlayerSignUpStep[signUpStep!] >= PlayerSignUpStep.PLAYER_FINISH_SIGN_UP) {
                    return history.push(redirectToPlayerStep(signUpStep!!));
                  } else {
                    return history.push(URLS.player.signUpBasic + profile.referredFromUserId);
                  }
                }}
                username={get(location, "state.username", "")}
                playerMode={true}
              />
            </Grid.Column>
          </Grid>
        </Grid>
      </div>
    </FloatedContainer>
  );

  return loading ? <Loader active size="large" /> : content;
};

export const PlayerLoginPage = withRouter(PlayerSignInPageFC);
