import * as React from "react";
import { Segment, Image, Header, Grid } from "semantic-ui-react";
import "./styles.scss";
import { Png } from "../../assets/png";

interface Props {
  /**
   * Title
   */
  title?: string;
  /**
   * Subtitle
   */
  subtitle?: string;
  /**
   * Padding of subtitle
   */
  padding?: "none" | "small";
  /**
   * Hide SocialCoach logo
   */
  showLogo?: boolean;
}

const SegmentHeaderFC: React.FC<Props> = ({ title, subtitle, padding = "none", showLogo = true }) => {
  return (
    <Grid centered columns={1}>
      <Grid.Column width={16}>
        <Header as="h2" icon textAlign="center" className={"titleSection"}>
          {showLogo && <Image src={Png.Logo} size={"large"} />}
          {title && <Header.Content className={"subtitle"}>{title}</Header.Content>}
        </Header>
        {subtitle && (
          <Header as="h4" textAlign="center" className={"subtitleSection padding-" + padding}>
            <Header.Content>{subtitle}</Header.Content>
          </Header>
        )}
      </Grid.Column>
    </Grid>
  );
};

export const SegmentHeader = SegmentHeaderFC;
