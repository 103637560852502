import * as React from "react";
import { Formik } from "formik";
import { RouteComponentProps, withRouter } from "react-router";
import { get } from "lodash";

import { PostComponent } from "../../../../../components";
import { Prompt } from "../../../../../interfaces";
import { URLS } from "../../../../../utils";

export interface OwnProps {
  /**
   * Prompt Info
   */
  prompt?: Prompt;
  pageErrors?: string[];
  updatingHandler: (values: any) => void;
}

type Props = OwnProps & RouteComponentProps;

const IntegratePost: React.FC<Props> = ({ prompt, pageErrors, history, updatingHandler }) => {
  return (
    <div>
      <Formik
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={true}
        initialValues={{
          promptId: get(prompt, "promptId", null),
          deliveryMode: "IMMEDIATE",
          deliveryDateUtc: undefined,
          deliveryTimeUtc: undefined,
          isAutoPost: false,
          socialNetworks: [],
          socialNetworksCounter: 0,
        }}
        onSubmit={async () => {
          // Action removed because the client wants
          // to use TitleSection action to save the prompt
        }}
      >
        {({ values, errors, isSubmitting, handleChange, setFieldValue, handleSubmit }) => {
          updatingHandler(values);

          return (
            <PostComponent
              messages={{
                error: pageErrors && pageErrors.length > 0 ? pageErrors : (Object.values(errors) as string[]),
              }}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              handleSetFieldValue={setFieldValue}
              values={values}
              isSubmitting={isSubmitting}
              prompt={prompt}
            />
          );
        }}
      </Formik>
    </div>
  );
};

export const PostForm = withRouter(IntegratePost);
