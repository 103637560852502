import * as React from "react";
import { useIntl } from "react-intl";
import { Grid } from "semantic-ui-react";

import { NumericFormat } from "react-number-format";
import { PriceElement } from "../../../interfaces";
import { capitalizeFormatter } from "../../../utils";

import { PlanSummaryType, descriptors } from "../descriptors";
import "../styles.scss";

interface OwnProps {
  intervalPrice: PriceElement;
}

type Props = OwnProps;

const IntervalPriceRow: React.FC<Props> = ({ intervalPrice }) => {
  const { formatMessage } = useIntl();
  return (
    <Grid.Row centered columns={2}>
      <Grid centered className={"amountBox"} width={16}>
        <Grid.Column floated={"left"} width={9}>
          <p className={"amountFrecuencyLabel"}>
            {formatMessage(
              {
                ...descriptors[PlanSummaryType.monthlyPayment],
              },
              { interval: capitalizeFormatter(intervalPrice.interval || "Monthly") }
            )}
          </p>
        </Grid.Column>
        <Grid.Column floated={"right"} width={7}>
          <p className={"amountLabel"}>
            <NumericFormat
              className={"feesDesc"}
              decimalScale={2}
              displayType={"text"}
              thousandSeparator
              prefix="$"
              value={intervalPrice?.price}
            />
          </p>
        </Grid.Column>
      </Grid>
    </Grid.Row>
  );
};

export const IntervalPrice = IntervalPriceRow;
