export const campaignSteps = ["BASIC_INFO", "PROMPTS", "REVIEW", "SEGMENTATION", "MANUAL_SUBCRIPTION"];

export const actions = {
  NEXT_STEP: "NEXT_STEP",
  PREVIOUS_STEP: "PREVIOUS_STEP",
  CANCEL: "CANCEL",
  ENABLE_NEXT: "ENABLE_NEXT",
  DISABLE_NEXT: "DISABLE_NEXT",
  CLEAN_CONFIRMATION_MODAL: "CLEAN_CONFIRMATION_MODAL",
  OPEN_CONFIRMATION_MODAL: "OPEN_CONFIRMATION_MODAL",
  CLOSE_CONFIRMATION_MODAL: "CLOSE_CONFIRMATION_MODAL",
  ACCEPT_CONFIRMATION_MODAL: "ACCEPT_CONFIRMATION_MODAL",
};
