import * as React from "react";
import { Grid, Button } from "semantic-ui-react";
import { get } from "lodash";

import { PromptCard } from "../../../../Cells";
import { EditableField } from "../../../../../components";
import * as DTO from "../../../../../interfaces";

import { RepostModal } from "./components/RepostModal";
import "./styles.scss";
interface OwnProps {
  promptList: DTO.PlayerPrompt[];
  name: string;
  category?: DTO.Category;
  disable?: boolean;
  deliveryMode?: DTO.DeliveryMode;
}

type Props = OwnProps;

const CategorySectionFC: React.FC<Props> = ({ category, promptList, name }) => {
  const [display, setDisplay] = React.useState<boolean>(true);
  const [showPostModal, setShowPostModal] = React.useState<boolean>(false);
  const [selectedPrompt, setSelectedPrompt] = React.useState<DTO.Prompt | undefined>(undefined);
  const [thumbnail, setThumbnail] = React.useState<string>("");

  const actionTitle = (): string => {
    if (!display) {
      return "Expand (" + promptList.length + ")";
    }
    return "Collapse (" + promptList.length + ")";
  };

  const categoryName = get(category, "categoryId", name);
  return (
    <Grid
      columns={3}
      data-elm-id={"library-list-element-" + categoryName}
      key={"library-list-element-" + categoryName}
      className={display ? "libraryListCategoryGrid" : "libraryListCategoryGrid collapsed"}
    >
      <Grid.Row columns={3} className={display ? "libraryListCategoryGrid" : "libraryListCategoryGrid collapsed"}>
        <EditableField
          headerType={"h2"}
          dataElmId={"playerLibraryCategoryId" + categoryName}
          currentValue={name}
          handleChangeValue={() => {}}
          disable={true}
        />

        <Grid.Column width={3} floated="right" className={"noBorder"}>
          <Button
            basic
            data-elm-id={`librarySectionDisplayBtn`}
            content={actionTitle()}
            icon={!display ? "triangle right" : "triangle down"}
            className={"noBorder floatRight"}
            labelPosition={"right"}
            onClick={() => setDisplay(!display)}
          />
        </Grid.Column>
      </Grid.Row>

      <div key={name} className={display ? "ui container" : "ui container cateroryCollapsed"}>
        {promptList.map((prompt, index) => {
          return (
            <PromptCard
              key={`player_library_prompt_card_${index}_${prompt.prompt.promptId}`}
              prompt={{ ...prompt.prompt, creationDate: prompt.deliveryDateTime }}
              onClick={thumbnail => {
                setThumbnail(thumbnail);
                setShowPostModal(true);
                setSelectedPrompt(prompt.prompt);
              }}
            />
          );
        })}
      </div>
      {showPostModal && selectedPrompt && (
        <RepostModal
          opened={showPostModal}
          prompt={selectedPrompt}
          closeHandler={() => {
            setShowPostModal(false);
            setSelectedPrompt(undefined);
          }}
          thumbnail={thumbnail}
        />
      )}
    </Grid>
  );
};

export const CategorySection = CategorySectionFC;
