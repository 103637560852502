import * as React from "react";
import{ useIntl, FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { Header, Form, Input, Grid, Message } from "semantic-ui-react";

import { fields } from "./formFields";
import { FloatedCard } from "../../../../../containers";
import { BasicFormProps, CoachBasic, ConfigProperties } from "../../../../../interfaces";

import "./styles.scss";
import { FirstLoginType, descriptors } from "./descriptors";
import { noSpaces, removeSpecialCharacters, useCheckMobileScreen } from "../../../../../utils";
import { FloatedContainer } from "../../../../../styling/baseStyle";
import { ConfigContext } from "../../../../../contexts/appContexts";
import { BubbleMobileDetails } from "../../BubbleMobileDetails";

interface OwnProps extends BasicFormProps {
  handleSetFieldValue: (field: string, value: any) => void;
  coachInformation?: CoachBasic;
}

type Props = OwnProps   & RouteComponentProps;

/**
 * Represents a Player First login screen - Step 3.
 */

const IntegratedFirstLoginWebAddress: React.FC<Props> = ({

  messages,
  handleSetFieldValue,
  handleSubmit,
  isSubmitting,
  values,
  coachInformation,
}) => {
  const { formatMessage } = useIntl();
  const { slugBaseUrl }: ConfigProperties = React.useContext(ConfigContext);
  const mobileContent = (
    <FloatedContainer>
      {coachInformation && (
        <BubbleMobileDetails
          messages={[
            {
              title: "Perfect!",
              message: "Your account is almost ready.",
            },
            {
              message: "What website would you like to drive traffic to? This will likely be your business website.",
            },
          ]}
          currentPosition={0}
          coachInfo={coachInformation}
        />
      )}
      <Form.Group widths="equal" key={"firstLogin-" + fields[0].id + "group0"}>
        <Form.Field>
          <Input
            key={fields[0].id}
            className={"inputWithLabel"}
            label={fields[0].id !== "webAddress" && `${slugBaseUrl}`}
            fluid
            data-elm-id={"firstLoginInput" + fields[0].id}
            type={"text"}
            id={fields[0].id}
            name={fields[0].name}
            placeholder={formatMessage({ ...descriptors[fields[0].placeholder] })}
            onChange={e => {
              const newValue = noSpaces(e.currentTarget.value);
              handleSetFieldValue(e.currentTarget.id, newValue);
              handleSetFieldValue("step", newValue !== "" ? 1 : 0);
            }}
            value={values[fields[0].id]}
            disabled={isSubmitting}
          />
        </Form.Field>
      </Form.Group>

      {values.step !== 0 && (
        <Form.Group widths="equal" key={"firstLogin-" + fields[1].id + "group1"}>
          {coachInformation && (
            <BubbleMobileDetails
              messages={[
                {
                  message:
                    "I’ve created a Short Link for you below. This link will act as a proxy for your website’s URL. It’s meant to be short so it will easily fit in your social posts.  ",
                },
                {
                  message:
                    "We will add this to all your posts to track engagement. Feel free to customize it, but keep it short and simple.",
                },
              ]}
              currentPosition={0}
              coachInfo={coachInformation}
              className={"bubblePaddingTop"}
            />
          )}
          <Form.Field>
            <Input
              key={fields[1].id}
              className={"inputWithLabel"}
              label={slugBaseUrl}
              fluid
              data-elm-id={"firstLoginInput" + fields[1].id}
              type={"text"}
              id={fields[1].id}
              name={fields[1].name}
              placeholder={formatMessage({ ...descriptors[fields[1].placeholder] })}
              onChange={e => {
                const newValue = removeSpecialCharacters(e.currentTarget.value);
                handleSetFieldValue(e.currentTarget.id, newValue);
              }}
              value={values[fields[1].id]}
              disabled={isSubmitting}
            />
          </Form.Field>
        </Form.Group>
      )}

      {values.step !== 0 && coachInformation && (
        <BubbleMobileDetails
          messages={[
            {
              message: "Great! Almost there. I just have a few questions about your business...",
            },
          ]}
          currentPosition={0}
          coachInfo={coachInformation}
          className={"bubblePaddingTop"}
        />
      )}

      <Form.Group widths="equal" className={"buttonContainer"}>
        <Form.Button size="large" type="submit" disabled={isSubmitting} onClick={handleSubmit} className={"signUp"}>
          <FormattedMessage {...descriptors[FirstLoginType.submit]} />
        </Form.Button>
      </Form.Group>

      {messages && messages.error && messages.error.length > 0 && (
        <Message error header={"Errors:"} list={messages && messages.error} />
      )}
      {messages && messages.warn && messages.warn.length > 0 && (
        <Message warning header={"Warnings: "} list={messages && messages.warn} />
      )}
      {messages && messages.info && messages.info.length > 0 && <Message header={"Info: "} list={messages.info} />}
    </FloatedContainer>
  );

  return <Grid className="webAddressContainer">{mobileContent}</Grid>;
  // (
  // <FloatedCard
  //   // subtitle={formatMessage({ ...descriptors[FirstLoginType.subtitle] })}
  //   content={mobileContent}
  //   messages={messages}
  //   isSubmitting={isSubmitting}
  //   size={"bigCentered"}
  // />
  // );
};

export const FirstLoginWebAddressMobile = withRouter(IntegratedFirstLoginWebAddress)
