import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useIntl } from "react-intl";
import { Grid, Header, Divider, Icon, Loader } from "semantic-ui-react";
import { SemanticWIDTHS } from "semantic-ui-react/dist/commonjs/generic";
import moment from "moment";

import { Svg } from "../../../../../assets/svg";
import { MiniCard } from "../../../../../components";
import { PlayerStatisticType, StatisticResponse, StatisticElement } from "../../../../../interfaces";
import { CoachSignUpStep, CoachService } from "../../../../../services";
import { URLS, getPlayerValue } from "../../../../../utils";

import { descriptors, DashboardType } from "../../descriptors";
import { InfoPopup } from "../../../../../components/AddCategoryComponent/styled";

import "./styles.scss";

interface OwnProps {
  coachId: string;
  currentStep?: string;
  withReadPermission: boolean;
}
type Props = OwnProps & RouteComponentProps;

const StatisticsFC: React.FC<Props> = ({ history, currentStep, coachId, withReadPermission }) => {
  const { formatMessage } = useIntl();
  const [latestUpdate, setLatestUpdate] = React.useState<string | undefined>(undefined);
  const [playersElements, setPlayersElements] = React.useState<StatisticElement[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getStatistics = async () => {
      try {
        setLoading(true);
        const stats = await CoachService.statistics(coachId);
        loadData(stats);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    if (currentStep && CoachSignUpStep[currentStep] > CoachSignUpStep.COACH_FINISH_SIGN_UP) {
      getStatistics();
    }
  }, [coachId]);

  const getDescColor = (amount?: number | string): "positive" | "negative" | undefined => {
    if (amount === undefined || Number(amount) === 0) {
      return undefined;
    }
    return Number(amount) > 0 ? "positive" : "negative";
  };

  const loadData = (stats: StatisticResponse): void => {
    const { playersReport } = stats;
    const playerLifetime = playersReport
      ? getPlayerValue(PlayerStatisticType.LIFETIME_PLAYERS, playersReport.summaries)
      : undefined;
    const playersActive = playersReport
      ? (getPlayerValue(PlayerStatisticType.ACTIVE_PLAYERS, playersReport.summaries) || 0) +
        (getPlayerValue(PlayerStatisticType.TRIAL_PLAYERS, playersReport.summaries) || 0)
      : undefined;

    const playersActiveTotal = playersReport
      ? (getPlayerValue(PlayerStatisticType.ACTIVE_PLAYERS, playersReport.stats) || 0) +
        (getPlayerValue(PlayerStatisticType.TRIAL_PLAYERS, playersReport.stats) || 0)
      : "--";

    const playersCanceledTotal = playersReport
      ? (getPlayerValue(PlayerStatisticType.CANCELED_PLAYERS, playersReport.stats) || 0) +
        (getPlayerValue(PlayerStatisticType.INACTIVE_PLAYERS, playersReport.stats) || 0)
      : "--";

    const playersCanceled = playersReport
      ? getPlayerValue(PlayerStatisticType.CANCELED_PLAYERS, playersReport.summaries)
      : undefined;

    const sorted = playersReport.summaries
      .slice()
      .sort((a, b) => new Date(b.lastUpdateDatetime).valueOf() - new Date(a.lastUpdateDatetime).valueOf());

    const latestPlayersUpdate =
      playersReport && sorted[0] && sorted[0].lastUpdateDatetime
        ? moment.utc(sorted[0].lastUpdateDatetime, "YYYY-MM-DDTHH:mm:ssZ").calendar()
        : undefined;

    setLatestUpdate(latestPlayersUpdate);

    setPlayersElements(
      playersReport
        ? [
            {
              title: formatMessage({ ...descriptors[DashboardType.lifetime] }),
              value: getPlayerValue(PlayerStatisticType.LIFETIME_PLAYERS, playersReport.stats) || "--",
              description: "",
              descColor: getDescColor(playerLifetime),
              iconName: Svg.PlayerLifetime,
              disable: true,
              type: "LIFETIME",
            },
            {
              title: formatMessage({ ...descriptors[DashboardType.playersActive] }),
              value: playersActiveTotal,
              description: "",
              descColor: getDescColor(playersActive),
              iconName: Svg.PlayerActive,
              disable: !withReadPermission,
              type: "ACTIVE",
            },
            {
              title: formatMessage({ ...descriptors[DashboardType.playersCanceled] }),
              value: playersCanceledTotal,
              description: "",
              descColor: getDescColor(playersCanceled),
              iconName: Svg.PlayerCanceled,
              disable: !withReadPermission,
              type: "CANCELLED",
            },
          ]
        : []
    );
  };

  const miniCardOnClick = (disable: boolean, type: string): (() => void) => {
    return () => {
      if (!disable) {
        history.push(URLS.coach.players, {
          selectedTab: type,
        });
      }
    };
  };

  const statisticsElement = (
    sectionName: string,
    stats: StatisticElement[],
    titleSize: SemanticWIDTHS = 5,
    smallTitleSize: SemanticWIDTHS = 4,
    subtitle: string | undefined = undefined
  ): JSX.Element => {
    return (
      <Grid className={"statistics"}>
        <Grid.Row>
          <Grid.Column computer={smallTitleSize} largescreen={titleSize} widescreen={titleSize}>
            <Header as="h2">{formatMessage({ ...descriptors[DashboardType[`${sectionName}Title`]] })}</Header>
          </Grid.Column>
          <Grid.Column computer={6} largescreen={7} widescreen={7}>
            <Header as="p">
              {formatMessage({ ...descriptors[DashboardType[`${sectionName}Subtitle`]] })}
              <InfoPopup
                content={formatMessage({ ...descriptors[DashboardType.playerSectionInfo] })}
                trigger={<Icon name="info circle" className={"infoIcon"} link />}
              />
            </Header>
          </Grid.Column>
          {subtitle && (
            <Grid.Column width={5}>
              <Header as="p">{subtitle}</Header>
            </Grid.Column>
          )}
        </Grid.Row>
        <Grid.Row>
          {stats.map(
            (
              { title, iconName, value, description, descColor, additionalDesc, additionalValue, disable, type },
              index
            ) => {
              return (
                <Grid.Column key={index} widescreen={4} computer={4} largescreen={4} tablet={5} mobile={12}>
                  <MiniCard
                    iconName={iconName}
                    title={title}
                    value={value}
                    description={description}
                    additionalValue={additionalValue}
                    additionalDesc={additionalDesc}
                    descType={descColor}
                    clickable={!disable}
                    onClick={miniCardOnClick(disable!, type || "")}
                  />
                </Grid.Column>
              );
            }
          )}
        </Grid.Row>
      </Grid>
    );
  };

  const latest = latestUpdate ? "  Last Update: " + latestUpdate : undefined;

  return (
    <Grid.Row columns={1} className={"statisticsContent"}>
      <Grid.Column>
        <Grid columns={1}>
          <Grid.Row columns={1}>
            <Grid.Column width={16}>
              {loading && <Loader active size="large" />}
              {statisticsElement("playerSection", playersElements, 2, 4, latest)}
            </Grid.Column>
          </Grid.Row>
          <Divider />
        </Grid>
      </Grid.Column>
    </Grid.Row>
  );
};

export const Statistics = withRouter(StatisticsFC);
