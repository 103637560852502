import * as React from "react";
import { useIntl } from "react-intl";
import { Table } from "semantic-ui-react";
import { ProspectResponse } from "../../../interfaces";

import { descriptors, PlayersType } from "./descriptors";
import { ProspectCell } from "../..";

import "./styles.scss";

interface OwnProps {
  prospectsList: ProspectResponse[];
  isAdmin?: boolean;
}

type Props = OwnProps; 

const ProspectsFC: React.FC<Props> = ({ prospectsList, isAdmin }) => {
  const { formatMessage } = useIntl();
  return (
    <div className="listProspectsListContainer">
      {prospectsList && prospectsList.length > 0 && (
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1} />
              <Table.HeaderCell width={5}>{formatMessage({ ...descriptors[PlayersType.nameHeader] })}</Table.HeaderCell>
              <Table.HeaderCell>{formatMessage({ ...descriptors[PlayersType.createdDateHeader] })}</Table.HeaderCell>
              {isAdmin && (
                <Table.HeaderCell>
                  {formatMessage({ ...descriptors[PlayersType.coachRequestedHeader] })}
                </Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {prospectsList.map((prospect, index) => (
              <ProspectCell isAdmin={isAdmin} key={"prospect_list_cell_" + index} prospect={prospect} />
            ))}
          </Table.Body>
        </Table>
      )}
    </div>
  );
};

export const ProspectList = ProspectsFC;
