import { client as axios } from "./axiosClient";
import { Constants } from "@socialcoach/services";
import { catchServiceException } from "@socialcoach/services";
import * as DTO from "../interfaces/dtos";
import { AxiosError } from "axios";

const PROMPT_SERVICE = `${Constants.API_URL}/coaches/prompts`;
const PLAYER_SERVICE = `${Constants.API_URL}/players`;
export class PromptServices {
  @catchServiceException()
  static async create(prompt: DTO.PromptRequest, coachId: string): Promise<DTO.Prompt> {
    const response = await axios.post<DTO.Prompt>(
      `${PROMPT_SERVICE}`,
      {
        coachId,
        ...prompt,
      },
      {
        withCredentials: true,
      }
    );

    return response.data;
  }

  @catchServiceException()
  static async createBulk(prompts: DTO.PromptBulkRequest): Promise<DTO.PromptBulkResponse> {
    const response = await axios.post<DTO.PromptBulkResponse>(`${PROMPT_SERVICE}/bulk`, prompts, {
      withCredentials: true,
    });

    return response.data;
  }

  @catchServiceException()
  static async update(prompt: DTO.PromptRequest, coachId: string): Promise<DTO.Prompt> {
    const response = await axios.put<DTO.Prompt>(
      `${PROMPT_SERVICE}/${prompt.promptId}`,
      {
        coachId,
        ...prompt,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async get(promptId: string | number): Promise<DTO.Prompt> {
    const response = await axios.get<DTO.Prompt>(`${PROMPT_SERVICE}/${promptId}`, {
      withCredentials: true,
    });
    return response.data;
  }

  // DEPRECATED
  @catchServiceException()
  static async searchPrompts(
    coachId: string,
    sort: string,
    filters?: DTO.SearchFilter | null,
    page: number | string = 0,
    size: number = 15
  ): Promise<DTO.Page<DTO.Prompt>> {
    const response = await axios.post<DTO.Page<DTO.Prompt>>(
      `${PROMPT_SERVICE}/coaches/${coachId}?page=${page}&size=${size}&sort=${sort}`,
      {
        deliveryModes: ["RANDOM_DELIVERY", "SPECIFIC_DATE", "WELCOME_PROMPT"],
        ...filters,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  // DEPRECATED
  @catchServiceException()
  static async getTotalActivePrompts(coachId: string): Promise<number> {
    const { totalElements } = await PromptServices.searchPrompts(
      coachId,
      "",
      {
        status: ["ACTIVE"],
        deliveryModes: ["RANDOM_DELIVERY", "SPECIFIC_DATE"],
      },
      0,
      1
    );

    return totalElements!;
  }

  @catchServiceException()
  static async getWelcomePrompt(coachId: string): Promise<DTO.Prompt | undefined> {
    const { content } = await PromptServices.searchPrompts(
      coachId,
      "",
      {
        status: ["ACTIVE"],
        deliveryModes: ["WELCOME_PROMPT"],
      },
      0,
      1
    );

    return content.length > 0 ? content[0] : undefined;
  }

  /**
   * @deprecated This function is deprecated since prompt RANDOM_DELIVERY was removed
   */
  @catchServiceException()
  static async checkSend(promptId: string): Promise<boolean> {
    const response = await axios
      .get<string>(`${PROMPT_SERVICE}/${promptId}/check-send`, {
        withCredentials: true,
      })
      .catch((reason: AxiosError) => {
        return reason.response;
      });

    return response!.status === 409;
  }

  @catchServiceException()
  static async library(
    coachId: string,
    sort: string,
    filters?: DTO.SearchFilter | null,
    page: number | string = 0,
    size: number = 2000
  ): Promise<DTO.Page<DTO.Prompt>> {
    const customFilters = {
      searchTerm: filters?.title,
      categories: filters?.status && filters?.status?.length > 0 ? filters?.status : undefined,
      status: ["ACTIVE", "DRAFT"],
      promptTypes: filters?.promptTypes ? filters?.promptTypes : undefined,
    };

    const response = await axios.post<DTO.Page<DTO.Prompt>>(
      `${PROMPT_SERVICE}/coaches/${coachId}/library?page=${page}&size=${size}&sort=${sort}`,
      {
        deliveryModes: ["LIBRARY", "WELCOME_PROMPT"],
        ...customFilters,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async playerLibrary(
    playerId: string,
    sort: string,
    filters?: DTO.SearchFilter | null,
    page: number | string = 0,
    size: number = 2000
  ): Promise<DTO.Page<DTO.PlayerPrompt>> {
    const customFilters = {
      searchTerm: filters?.title,
      categories: filters?.status && filters?.status?.length > 0 ? filters?.status : undefined,
      promptTypes: filters?.promptTypes ? filters?.promptTypes : undefined,
      includeFromCampaign: false,
    };

    const response = await axios.post<DTO.Page<DTO.PlayerPrompt>>(
      `${PLAYER_SERVICE}/${playerId}/prompts?page=${page}&size=${size}&sort=prompt.createdDate,DESC`,
      {
        promptDeliveryModes: ["LIBRARY"],
        ...customFilters,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async libraryByCategory(
    coachId: string,
    categoryId?: string,
    page: number | string = 0,
    size: number = 4,
    sort: string = "createdDate,DESC",
    status: string[] = ["ACTIVE"]
  ): Promise<DTO.Page<DTO.Prompt>> {
    const customFilters = {
      deliveryModes: ["LIBRARY"],
      status,
      categories: categoryId === "uncategorized" ? [null] : [categoryId],
      searchTerm: null,
    };
    const response = await axios.post<DTO.Page<DTO.Prompt>>(
      `${PROMPT_SERVICE}/coaches/${coachId}/library?page=${page}&size=${size}&sort=${sort}`,
      {
        ...customFilters,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }
}
