import * as React from "react";
import { useIntl } from "react-intl";
import { Message } from "semantic-ui-react";
import { Svg } from "../../../../../assets";
import * as DTO from "../../../../../interfaces";
import { validateLong } from "../../../../../utils";
import { CategoriesServices } from "../../../../../services";
import { EmptyView } from "../../../../EmptyView";

import { CategorySection } from "../CategorySection";

interface OwnProps {
  promptList: DTO.PlayerPrompt[];
  categories: DTO.Category[];
  coachId: string;

  isEmptySection: (isEmpty: boolean) => void;
}

type Props = OwnProps;

export default ({ promptList, categories, coachId, isEmptySection }: Props) => {
  const [categoriesHash, setCategoriesHash] = React.useState<{ [key: string]: string }>({});
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [withCategorizedLibraryPrompts, setWithCategorizedLibraryPrompts] = React.useState<boolean>(false);

  React.useEffect(() => {
    const convertToHash = (result: DTO.Category[]): { [key: string]: string } => {
      let hash = {};
      result.map(category => {
        hash = {
          ...hash,
          [category.categoryId!]: category.name,
        };
      });

      return hash;
    };

    setCategoriesHash(convertToHash(categories));
  }, [categories]);

  const uncategorized = React.useMemo(() => {
    return promptList.filter(prompt => prompt.prompt.categories?.length === 0);
  }, [promptList]);

  React.useEffect(() => {
    const emptySection: boolean = !withCategorizedLibraryPrompts && uncategorized.length === 0;
    isEmptySection(emptySection);
  }, [withCategorizedLibraryPrompts]);

  const promptFilteredByCategory = (categoryId: string): DTO.PlayerPrompt[] => {
    const promptsFiltered = promptList.filter(prompt => {
      return prompt.prompt.categories?.map(c => c.categoryId!).includes(categoryId);
    });

    if (!withCategorizedLibraryPrompts && promptsFiltered.length > 0) {
      setWithCategorizedLibraryPrompts(true);
    }
    return promptsFiltered;
  };

  const showPrompts = (prompts: DTO.PlayerPrompt[], category: DTO.Category): JSX.Element | undefined => {
    if (prompts.length === 0) {
      return undefined;
    }
    return (
      <CategorySection
        key={"player_prompt_list_cat_section_" + category.categoryId}
        category={category}
        promptList={promptFilteredByCategory(category.categoryId!)}
        name={categoriesHash[category.categoryId!]}
      />
    );
  };

  const showEmptySection = !withCategorizedLibraryPrompts && uncategorized.length === 0;
  return (
    <div data-elm-id={"promptListLibrarySection"} className="promptListContainer socialContent">
      {error && <Message error content={error} />}
      {uncategorized.length > 0 && <CategorySection name={"Uncategorized"} promptList={uncategorized} disable={true} />}

      {categories.map(category => {
        return showPrompts(promptFilteredByCategory(category.categoryId!), category);
      })}

      {showEmptySection && <EmptyView text={"You have no Library Prompts"} customIconImage={Svg.Library} />}
    </div>
  );
};
