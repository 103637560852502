import * as React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { ConfigContext } from "../../contexts/appContexts";

import "./styles.scss";

interface Props {
  handleChangeValue: (token: string | null) => void;
}

const CaptchaFC: React.FC<Props> = ({ handleChangeValue }) => {
  const config = React.useContext(ConfigContext);
  const recaptchaRef = React.useRef(null);

  return (
    <div className={"customRecapcha"}>
      <div className="mobileHidden">
        <ReCAPTCHA ref={recaptchaRef} sitekey={config.captchaSiteKey} onChange={handleChangeValue} />
      </div>

      <div className="desktopHidden">
        <ReCAPTCHA ref={recaptchaRef} size={"compact"} sitekey={config.captchaSiteKey} onChange={handleChangeValue} />
      </div>
    </div>
  );
};

export const Captcha = CaptchaFC;
