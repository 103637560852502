export enum CoachSignUpStep {
  COACH_EMAIL_PASSWORD = 1, // when the user only has been register the email and the password -- Step 2
  COACH_PROFILE_DATA = 2, // When the coach has all the profile data (first name, last name, phone....) -- Step 2
  COACH_COMPANY_INFORMATION = 3, // the coach has added the company and social networks information -- DASHBOARD

  COACH_FINISH_SIGN_UP = 4, // coach has finished its sign up, and now start its first login -- DASHBOARD
  COACH_DASHBOARD = 5,
  COACH_BANK_INFORMATION = 6,
  COACH_PRICE_PLAN = 7,
  COACH_COACHING_PROFILE = 8,
  COACH_CUSTOMER_QUESTIONNAIRE = 9,
  COACH_PROMPTS = 10,
  COACH_INVITES = 11,
  COACH_LIBRARY = 12,
}

export enum PlayerSignUpStep {
  PLAYER_EMAIL_PASSWORD = 1, // when the user only has been register the email and the password
  PLAYER_PAYMENT_INFORMATION = 2,
  PLAYER_FINISH_SIGN_UP = 3,
  PLAYER_PROFILE_DATA = 4,
  PLAYER_BUSINESS_DATA = 5,
}
