import React, { useState, useCallback, useContext, useMemo, ChangeEvent, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Form, Message } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { values as getValues } from "lodash";

import { ProspectLoginInformationWithValidation, Captcha, TacSection } from "../../../components";
import { BillingPeriod, Plan, PlayerRegistered } from "../../../interfaces/dtos";
import { ConfigContext } from "../../../contexts/appContexts";
import { CoachService, SocialCoachSessionService } from "../../../services";
import { descriptors, CoachSummaryType } from "../PlanSection/descriptors";
import { AppContext } from "../../../providers";

import {
  LabelTitle,
  FormTitle,
  NameInput,
  InputWrapper,
  PayButton,
  CARD_ELEMENT_OPTIONS,
  CvsWrapper,
  ExpirationWrapper,
} from "./styles";

export interface CheckoutFormProps {
  plan?: Plan;
  email: string;
  selectedBillingPeriod: BillingPeriod;
  referredFromUserId: number;
  sharedLink: string;
  onSuccess?: () => void;
}

export function CheckoutForm({
  plan,
  email,
  referredFromUserId,
  sharedLink,
  selectedBillingPeriod,
  onSuccess,
}: CheckoutFormProps) {
  const config = useContext(ConfigContext);
  const stripePromise = useMemo(() => config && loadStripe(config.stripe.public_key, { locale: "en" }), [config]);

  if (!stripePromise) {
    return null;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc: "https://fonts.googleapis.com/css?family=Lato&display=swap",
            family: "Lato",
            style: "normal",
          },
        ],
      }}
    >
      <InnerForm
        plan={plan}
        email={email}
        referredFromUserId={referredFromUserId}
        sharedLink={sharedLink}
        selectedBillingPeriod={selectedBillingPeriod}
        onSuccess={onSuccess}
      />
    </Elements>
  );
}

function InnerForm({
  plan,
  email,
  referredFromUserId,
  sharedLink,
  selectedBillingPeriod,
  onSuccess,
}: CheckoutFormProps) {
  const config = useContext(ConfigContext);
  const { userContext } = useContext(AppContext);

  const [cardFocus, setCardFocus] = useState(false);
  const [expirationFocus, setExpirationFocus] = useState(false);
  const [cvsFocus, setCvsFocus] = useState(false);

  const [name, setName] = useState("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<{
    stripeSection: string | undefined;
    password: string | undefined;
  }>({
    stripeSection: undefined,
    password: undefined,
  });
  const [tacAccepted, setTacAccepted] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [pwdReady, setPwdReady] = useState<boolean>(userContext ? true : false);
  const [newUser, setNewUser] = useState<PlayerRegistered | undefined>(undefined);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [readyToCaptcha, setReadyToCaptcha] = useState<boolean>(true);

  const stripe = useStripe();
  const elements = useElements();
  const cardNumberElement = elements?.getElement(CardNumberElement);
  const cardCvsElement = elements?.getElement(CardCvcElement);
  const cardExpirationElement = elements?.getElement(CardExpiryElement);

  useEffect(() => {
    if (pwdReady && name.length > 0 && cardNumberElement && cardCvsElement && cardExpirationElement) {
      setReadyToCaptcha(true);
    } else {
      setCaptchaToken(null);
      setReadyToCaptcha(false);
    }
  }, [password, name, error, cardNumberElement, pwdReady, cardExpirationElement, cardCvsElement]);

  const executePayment = useCallback(async () => {
    if (!cardNumberElement) {
      return;
    }

    if (name.length === 0) {
      setError({
        stripeSection: "You must enter the name on the card.",
        password: undefined,
      });
      return;
    }

    setLoading(true);
    setError({
      stripeSection: undefined,
      password: undefined,
    });

    try {
      if (captchaToken) {
        let playerId: number | undefined;
        let userId: number | undefined;

        if (userContext) {
          playerId = userContext.player?.id;
          userId = userContext!.account.userId;
        } else {
          if (!newUser) {
            const user = await SocialCoachSessionService.createBasicUser({
              password,
              email,
              userRole: "PLAYER",
              referredFromUserId,
              token: captchaToken,
            });
            playerId = user!.player!.id;
            userId = user!.user.userId;
            setNewUser(user);
          } else {
            playerId = newUser!.player!.id;
            userId = newUser!.user.userId;
          }
        }

        const secret = await CoachService.createPaymentIntent(playerId!);

        const result = await stripe!.confirmCardSetup(secret, {
          payment_method: {
            billing_details: {
              name,
            },
            card: cardNumberElement!!,
          },
        });

        const { error: cardError } = result;

        if (cardError) {
          setError({
            stripeSection: cardError.message || "An error occurred while processing your payment information.",
            password: undefined,
          });

          setLoading(false);
          return;
        }

        await CoachService.createSubscription({
          playerId: playerId!,
          userId,
          pricePlanId: plan!!.id!!,
          billingPeriod: selectedBillingPeriod,
        });

        if (onSuccess) {
          onSuccess();
        }
      } else {
        setError({
          stripeSection: "Invalid captcha",
          password: undefined,
        });
      }
    } catch (e: any) {
      if (e === "Username already taken") {
        setError({
          stripeSection:
            "Sorry. It looks like this email is already associated with an account. Please log in to purchase a new subscription. " +
            sharedLink,
          password: undefined,
        });
      } else if (e.includes("Password is required and can't be empty")) {
        setError({
          stripeSection: "Password is required and can't be empty ",
          password: undefined,
        });
      } else {
        setError({
          stripeSection: "An unexpected error ocurred while setting up your plan. ",
          password: undefined,
        });
      }
    }

    setLoading(false);
  }, [stripe, cardNumberElement, name, plan!!.id, captchaToken, sharedLink, newUser]);

  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  }, []);

  const cardOptions = useMemo(() => ({ ...CARD_ELEMENT_OPTIONS, disabled: loading }), [loading]);
  const errors = getValues(error).filter(e => e !== undefined) as string[];

  return (
    <Form error={errors.length > 0}>
      <FormTitle>
        <LabelTitle>Payment Information</LabelTitle>
      </FormTitle>
      <Form.Field>
        <NameInput disabled={loading} placeholder="First and last name" onChange={handleNameChange} />
      </Form.Field>
      <Form.Field>
        <InputWrapper focus={cardFocus}>
          {/* <CardElement options={cardOptions} onFocus={() => setCardFocus(true)} onBlur={() => setCardFocus(false)} /> */}
          <CardNumberElement
            options={cardOptions}
            onFocus={() => setCardFocus(true)}
            onBlur={() => setCardFocus(false)}
          />
        </InputWrapper>
      </Form.Field>
      <Form.Group>
        <Form.Field>
          <ExpirationWrapper focus={expirationFocus}>
            <CardExpiryElement
              options={cardOptions}
              onFocus={() => setExpirationFocus(true)}
              onBlur={() => setExpirationFocus(false)}
            />
          </ExpirationWrapper>
        </Form.Field>
        <Form.Field>
          <CvsWrapper focus={cvsFocus}>
            <CardCvcElement options={cardOptions} onFocus={() => setCvsFocus(true)} onBlur={() => setCvsFocus(false)} />
          </CvsWrapper>
        </Form.Field>
      </Form.Group>

      {!userContext && (
        <>
          <FormTitle>
            <LabelTitle>Login Information</LabelTitle>
          </FormTitle>
          <ProspectLoginInformationWithValidation
            email={email}
            onChange={(pwd, errorPassword) => {
              setPassword(pwd);
              setError({
                stripeSection: error.stripeSection,
                password: errorPassword,
              });
              if (errorPassword) {
                setPwdReady(false);
              }
            }}
            onChangeConfirmPassword={errorPassword => {
              setError({
                stripeSection: error.stripeSection,
                password: errorPassword,
              });
              setPwdReady(errorPassword === undefined);
            }}
            disabled={loading}
          />
        </>
      )}
      {readyToCaptcha && (
        <Captcha
          handleChangeValue={value => {
            setCaptchaToken(value);
          }}
        />
      )}
      <TacSection handleTacChange={checked => setTacAccepted(checked)} dataElmId={"paymentView"} style={"normal"} />

      <PayButton
        centered
        className={"primary"}
        disabled={loading || !tacAccepted || !pwdReady || !captchaToken}
        onClick={executePayment}
      >
        {!loading && (
          <FormattedMessage
            {...descriptors[plan!!.hasFreeTrial ? CoachSummaryType.startTrialButton : CoachSummaryType.payButton]}
            values={{ trialDays: config.trialDays }}
          />
        )}
        {loading && <FormattedMessage {...descriptors[CoachSummaryType.processing]} />}
      </PayButton>

      {errors.map(e => (
        <Message key={e} error content={e} />
      ))}
    </Form>
  );
}
