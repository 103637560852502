import * as React from "react";
import { Media, UploadProgressMap } from "../../interfaces";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import "./styles.scss";
import { BrandMediaItem } from "../BrandMediaItem";

interface BrandMediaContainerProps {
  media: Media[];
  uploadProgress: UploadProgressMap;
  handleUpdate: (id: number, url: string) => void;
}

const BrandMediaContainerFC: React.FC<BrandMediaContainerProps> = ({
  media,
  uploadProgress,
  handleUpdate,
}: BrandMediaContainerProps) => {
  return (
    <div>
      <DragDropContext onDragEnd={() => {}}>
        {media.map((item, index) => (
          <Droppable key={"brand_droppable_media_" + index} droppableId={`${index}`} direction="horizontal">
            {(provided: any) => (
              <div ref={provided.innerRef} className="draggable-media-container-wrapper" {...provided.droppableProps}>
                <BrandMediaItem
                  src={item}
                  key={"brand_media_item_" + item.id}
                  id={item.id}
                  index={index}
                  handleUpdate={handleUpdate}
                  uploadProgress={uploadProgress[item.id] && uploadProgress[item.id].progress}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>
    </div>
  );
};

export const BrandMediaContainer = BrandMediaContainerFC;
