import { defineMessages } from "react-intl";

export enum CatalystType {
  cancelBtn = "CatalystType.cancelBtn",
  addProjectBtn = "CatalystType.addCampaignBtn",
  title = "CatalystType.title",
}

export const en = {
  [CatalystType.cancelBtn]: "Cancel",
  [CatalystType.addProjectBtn]: "New Project",
  [CatalystType.title]: "Video Catalyst",
};

export const descriptors = defineMessages({
  [CatalystType.cancelBtn]: {
    id: CatalystType.cancelBtn,
    defaultMessage: en[CatalystType.cancelBtn],
    description: "",
  },
  [CatalystType.addProjectBtn]: {
    id: CatalystType.addProjectBtn,
    defaultMessage: en[CatalystType.addProjectBtn],
    description: "",
  },

  [CatalystType.title]: {
    id: CatalystType.title,
    defaultMessage: en[CatalystType.title],
    description: "title",
  },
});
