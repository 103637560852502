import * as React from "react";
import{ useIntl } from "react-intl";
import { Table, Message, Icon } from "semantic-ui-react";

import * as DTO from "../../interfaces";
import { descriptors, PlayersType } from "./descriptors";
import { playerListReducer } from "./reducer";

import "./styles.scss";
import { PlayerStatisticCell } from "./components/PlayerStatisticCell";
import { SocialNetworkStatisticCell } from "./components/SocialNetworkStatisticCell";
import { getDefaultTotal } from "../../utils";
import { useSocialNetworks } from"../../reducers";

interface OwnProps {
  playerResult: DTO.PlayerWithStats[];
  availableSocialNetworks: DTO.SocialNetworksId[];
}

type Props = OwnProps 

const PlayersStatsFC: React.FC<Props> = ({ playerResult, availableSocialNetworks }) => {
  const [totals, setTotals] = React.useState<DTO.PlayerResumeStats>(getDefaultTotal(playerResult));
  const [display, setDisplay] = React.useState<boolean>(false);
  const { formatMessage } = useIntl();
  const { sns } = useSocialNetworks();

  const [state, dispatch] = React.useReducer(playerListReducer, {
    column: "followers",
    data: playerResult,
    direction: "descending",
  });

  React.useEffect(() => {
    dispatch({ type: "REFRESH_DATA", column: "followers", data: playerResult });
    setTotals(getDefaultTotal(playerResult));
  }, [playerResult]);

  const { column, data, direction } = state;
  return (
    <div className="playerDashboardListContainer">
      {data && data.length > 0 ? (
        <Table sortable striped compact="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="tableIconCell"/>
              <Table.HeaderCell width={1}/>
              <Table.HeaderCell width={2}>{formatMessage({ ...descriptors[PlayersType.nameHeader] })}</Table.HeaderCell>
              <Table.HeaderCell
                width={1}
                sorted={column === "followers" ? direction : null}
                onClick={() => dispatch({ type: "CHANGE_SORT", column: "followers" })}>
                Followers
              </Table.HeaderCell>
              <Table.HeaderCell
                width={1}
                sorted={column === "countPromptPosted" ? direction : null}
                onClick={() => dispatch({ type: "CHANGE_SORT", column: "countPromptPosted" })}>
                Posts
              </Table.HeaderCell>
              <Table.HeaderCell
                width={1}
                sorted={column === "likes" ? direction : null}
                onClick={() => dispatch({ type: "CHANGE_SORT", column: "likes" })}>
                Likes
              </Table.HeaderCell>
              <Table.HeaderCell
                width={1}
                sorted={column === "comments" ? direction : null}
                onClick={() => dispatch({ type: "CHANGE_SORT", column: "comments" })}>
                Comments
              </Table.HeaderCell>
              <Table.HeaderCell
                width={1}
                sorted={column === "shares" ? direction : null}
                onClick={() => dispatch({ type: "CHANGE_SORT", column: "shares" })}>
                Shares
              </Table.HeaderCell>
              <Table.HeaderCell
                width={1}
                sorted={column === "click" ? direction : null}
                onClick={() => dispatch({ type: "CHANGE_SORT", column: "click" })}>
                Clicks
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((playerR: DTO.PlayerWithStats, index: number) => (
              <PlayerStatisticCell
                key={playerR.email}
                player={playerR}
                index={index}
                availableSocialNetworks={availableSocialNetworks}
              />
            ))}
          </Table.Body>
          <Table.Footer onClick={() => setDisplay(!display)}>
            <Table.Row className={display ? "rowDisplayed" : ""}>
              <Table.HeaderCell className={"playerStatsCellShowMoreBtn tableIconCell"}>
                <Icon name={display ? "chevron up" : "chevron right"} className={"playerStatsCellShowMoreBtn"} link />
              </Table.HeaderCell>
              <Table.HeaderCell/>
              <Table.HeaderCell>
                <strong>Total</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>{totals.followers}</Table.HeaderCell>
              <Table.HeaderCell>{totals.countPromptPosted}</Table.HeaderCell>
              <Table.HeaderCell>{totals.likes}</Table.HeaderCell>
              <Table.HeaderCell>{totals.comments}</Table.HeaderCell>
              {/* <Table.HeaderCell>{totals.views}</Table.HeaderCell> */}
              <Table.HeaderCell>{totals.shares}</Table.HeaderCell>
              <Table.HeaderCell>{totals.click}</Table.HeaderCell>
            </Table.Row>
            {/* Expanded section */}
            {display &&
              availableSocialNetworks.map(networkId => (
                <SocialNetworkStatisticCell
                  key={"totalStats" + networkId}
                  networkId={networkId}
                  index={1}
                  player={totals.statsBySocialNetwork!![networkId]}
                  keyId={"totalStats" + networkId}
                />
              ))}
            {display && availableSocialNetworks.length % 2 !== 0 && <Table.Row className="invisibleRow" />}
          </Table.Footer>
        </Table>
      ) : (
        <Message
          icon="search"
          header="No results"
          content={formatMessage({ ...descriptors[PlayersType.noResultsMessage] })}
          size="large"
        />
      )}
    </div>
  );
};

export const PlayersListWithStats = PlayersStatsFC
