import * as React from "react";
import { capitalize } from "lodash";
import { TableRow, TableCell } from "semantic-ui-react";

import { SocialNetworkMessage, socialNetworkNames } from "../../../../../../../../../interfaces";

import "./styles.scss";
import moment from "moment";

interface OwnProps {
  socialNetwork: SocialNetworkMessage;
}

type Props = OwnProps;

/**
 * PostErrorCell
 */
const PostErrorCellFC: React.FC<Props> = ({ socialNetwork }) => {
  const timestamp = socialNetwork.postedTimestamp || socialNetwork.createdDate;
  return (
    <TableRow className="postErrorCell" key={socialNetwork.socialNetworkId!}>
      <TableCell className="socialNetworkCell">{socialNetworkNames["_" + socialNetwork.socialNetworkId!!]}</TableCell>
      <TableCell textAlign="right">
        <p>{capitalize(socialNetwork.status!!)}</p>
        <p>{timestamp ? moment(timestamp).format("MM/DD/YYYY hh:mm a") : ""}</p>
        {socialNetwork.failedReason && <div className="errorLabel">{socialNetwork.failedReason}</div>}
      </TableCell>
    </TableRow>
  );
};

export const PostErrorCell = PostErrorCellFC;
