export function deliveryStatus(deliveryMode: string): any[] {
  return [
    {
      key: 0,
      text: "postNow",
      value: "IMMEDIATE",
      selected: deliveryMode === "IMMEDIATE",
    },
    {
      key: 1,
      text: "postScheduled",
      value: "SCHEDULED",
      selected: deliveryMode === "SCHEDULED",
    },
  ];
}
