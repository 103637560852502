import { defineMessages } from "react-intl";

export enum QuestionnaireType {
  title = "QuestionnaireType.title",
  subtitle = "QuestionnaireType.subtitle",

  addQuestion = "QuestionnaireType.addQuestion",
  whatIsThis = "QuestionnaireType.whatIsThis",
  myQuestions = "QuestionnaireType.myQuestions",
  unknownError = "CoachingProfile.unknownError",
}

export const en = {
  [QuestionnaireType.title]: "Create Onboarding Questionnaire",
  [QuestionnaireType.subtitle]: "These are questions that your Players will answer as they sign up.",

  [QuestionnaireType.addQuestion]: "Add Question",
  [QuestionnaireType.whatIsThis]: "These are questions that your new customers will answer as they sign up.",
  [QuestionnaireType.myQuestions]: "My Questions",
  [QuestionnaireType.unknownError]: "Unknown Error.",
};

export const descriptors = defineMessages({
  [QuestionnaireType.title]: {
    id: QuestionnaireType.title,
    defaultMessage: en[QuestionnaireType.title],
    description: "Title for questionnaire configuration",
  },
  [QuestionnaireType.subtitle]: {
    id: QuestionnaireType.subtitle,
    defaultMessage: en[QuestionnaireType.subtitle],
    description: "Subtitle for questionnaire configuration",
  },
  [QuestionnaireType.addQuestion]: {
    id: QuestionnaireType.addQuestion,
    defaultMessage: en[QuestionnaireType.addQuestion],
    description: "Label for add question button",
  },
  [QuestionnaireType.whatIsThis]: {
    id: QuestionnaireType.whatIsThis,
    defaultMessage: en[QuestionnaireType.whatIsThis],
    description: "Text for the 'what is this?' box",
  },
  [QuestionnaireType.myQuestions]: {
    id: QuestionnaireType.myQuestions,
    defaultMessage: en[QuestionnaireType.myQuestions],
    description: "My Questions title",
  },
  [QuestionnaireType.unknownError]: {
    id: QuestionnaireType.unknownError,
    defaultMessage: en[QuestionnaireType.unknownError],
    description: "Default message for unknown error",
  },
});
