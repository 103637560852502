import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Form, Popup, Icon, Header } from "semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";

import { BasicFormProps } from "../../../interfaces";
import { FloatedCard } from "../../../containers";
import { URLS } from "../../../utils";

import { descriptors, RecoverPasswordType } from "./descriptors";

interface OwnProps extends BasicFormProps {
  passwordInputId?: string;
  confirmPasswordInputId?: string;
  companyName?: string;
  isPlayer?: boolean;
}

type Props = OwnProps & RouteComponentProps;

/**
 * Represents a ChangePassword content.
 */
const ChangePasswordFC: React.FC<Props> = ({
  history,
  passwordInputId = "password",
  confirmPasswordInputId = "confirmPassword",
  messages,
  isPlayer = false,
  companyName,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => {
  const { formatMessage } = useIntl();

  const toLogin = (): void => {
    history.push(URLS.login);
  };

  const content = (
    <React.Fragment>
      {isPlayer && (
        <Header as="h4" textAlign="left" className={"subtitleSection"}>
          <Header.Content>{formatMessage({ ...descriptors[RecoverPasswordType.playerDescription] })}</Header.Content>
        </Header>
      )}
      <Form.Input
        id={passwordInputId}
        name="password"
        data-elm-id={"changePasswordInputPassword"}
        fluid
        icon={
          <Popup trigger={<Icon name="info circle" className={"infoIcon"} link />}>
            <FormattedMessage {...descriptors[RecoverPasswordType.passwordWithNumber]} />
            <br />
            <FormattedMessage {...descriptors[RecoverPasswordType.passwordWithCapital]} />
            <br />
            <FormattedMessage {...descriptors[RecoverPasswordType.passwordWithSpecialCharacter]} />
            <br />
            <FormattedMessage {...descriptors[RecoverPasswordType.passwordMin]} />
          </Popup>
        }
        type="password"
        placeholder={formatMessage({ ...descriptors[RecoverPasswordType.password] })}
        onChange={handleChange}
        disabled={isSubmitting}
      />
      <Form.Input
        id={confirmPasswordInputId}
        name="confirmPassword"
        data-elm-id={"changePasswordInputConfirm"}
        type="password"
        fluid
        placeholder={formatMessage({ ...descriptors[RecoverPasswordType.confirmPassword] })}
        onChange={handleChange}
        disabled={isSubmitting}
      />

      <Form.Button fluid size="large" type="submit" disabled={isSubmitting} onClick={handleSubmit}>
        <FormattedMessage {...descriptors[RecoverPasswordType[isPlayer ? "playerSubmit" : "submit"]]} />
      </Form.Button>

      {!isPlayer && (
        <div className="buttons-link">
          <span onClick={toLogin}>
            <FormattedMessage {...descriptors[RecoverPasswordType.logIn]} />
          </span>
        </div>
      )}
    </React.Fragment>
  );

  return (
    <FloatedCard
      title={isPlayer ? companyName : formatMessage({ ...descriptors[RecoverPasswordType.title] })}
      subtitle={isPlayer ? formatMessage({ ...descriptors[RecoverPasswordType.subtitle] }) : ""}
      content={content}
      messages={messages}
      isSubmitting={isSubmitting}
      size={"small"}
      containerPadding={"small"}
    />
  );
};

export const ChangePassword = withRouter(ChangePasswordFC);
