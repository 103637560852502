export const Constants: any = {
  SOCIAL_URLS: {
    FB: "https://www.facebook.com/",
    TW: "https://www.twitter.com/",
    LI: "https://www.linkedin.com/",
    IG: "https://www.instagram.com/",
    TT: "https://www.tiktok.com/",
  },
};

export const SOCIAL_TITLES = {
  FB: "Facebook",
  TW: "X (Twitter)",
  LI: "LinkedIn",
  IG: "Instagram",
  TT: "TikTok",
  IG_STORY: "Instagram Story",
};
