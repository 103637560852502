import * as React from "react";
import { Modal, Button, TextArea } from "semantic-ui-react";

import { modalReducer } from "./modalReducer";
import "../styles.scss";

interface Props {
  /**
   * Modal Title
   */
  title?: string;

  /**
   * Modal custom value
   */
  customValue?: any;

  postId?: number;
  opened: boolean;
  playerName?: string;
  postLinks: string[];

  acceptHandler: (requestedChanges: any, postId: number) => void;
  rejectHandler: () => void;
}

const RequestChangesModalFC: React.FC<Props> = ({
  title,
  opened,
  postId,
  // playerName = "",
  // postLinks = [],
  acceptHandler,
  rejectHandler,
}) => {
  const [state, dispatch] = React.useReducer(modalReducer, { open: opened });

  const [requestedChanges, setRequestedChanges] = React.useState<string>("");

  React.useEffect(() => {
    if (opened) {
      dispatch({ type: "open" });
    } else {
      dispatch({ type: "close" });
    }
  }, [opened]);

  return (
    <Modal
      size={"small"}
      open={state.open}
      onClose={() => {
        dispatch({ type: "close" });
        rejectHandler();
      }}>
      <Modal.Header>{title}</Modal.Header>

      <Modal.Content>
        <TextArea
          key={"requestedChangesMessageInput"}
          data-elm-id={"requestedChangesMessageInput"}
          className={"requestedChangesTextArea"}
          fluid="true"
          label={"Tell more about the changes"}
          id={"requestedChanges"}
          name={"requestedChanges"}
          placeholder={""}
          value={requestedChanges}
          rows={15}
          onChange={(e, data) => setRequestedChanges(`${data!.value}`)}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button
          data-elm-id={`requestChangesModalSendBtn`}
          primary
          onClick={() => {
            dispatch({ type: "close" });
            if (postId) {
              acceptHandler(requestedChanges, postId);
            }
          }}>
          Send
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const RequestChangesModal = RequestChangesModalFC;
