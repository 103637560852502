import { defineMessages } from "react-intl";

export enum PlayersType {
  resetPwdBtn = "PlayersType.resetPwdBtn",

  goalTitle = "PlayersType.goalTitle",
  followersTitle = "PlayersType.followersTitle",
  likesTitle = "PlayersType.likesTitle",
  clicksTitle = "PlayersType.clicksTitle",

  nameHeader = "PlayersType.nameHeader",
  phoneHeader = "PlayersType.phoneHeader",
  tagsHeader = "PlayersType.tagsHeader",
  lastPaymentHeader = "PlayersType.lastPaymentHeader",
  activeFrom = "PlayersType.activeFrom",

  noResultsMessage = "PlayersType.noResultsMessage",
}

export const en = {
  [PlayersType.resetPwdBtn]: "Reset Password",

  [PlayersType.goalTitle]: "POST GOAL",
  [PlayersType.followersTitle]: "FOLLOWERS",
  [PlayersType.likesTitle]: "LIKES",
  [PlayersType.clicksTitle]: "CLICKS",
  [PlayersType.nameHeader]: "Name",
  [PlayersType.phoneHeader]: "Phone ",
  [PlayersType.tagsHeader]: "Tags",
  [PlayersType.lastPaymentHeader]: "Last Payment",
  [PlayersType.activeFrom]: "Active from",

  [PlayersType.noResultsMessage]: "There are no players in this section",
};

export const descriptors = defineMessages({
  [PlayersType.resetPwdBtn]: {
    id: PlayersType.resetPwdBtn,
    defaultMessage: en[PlayersType.resetPwdBtn],
    description: "-",
  },
  [PlayersType.goalTitle]: {
    id: PlayersType.goalTitle,
    defaultMessage: en[PlayersType.goalTitle],
    description: "-",
  },
  [PlayersType.followersTitle]: {
    id: PlayersType.followersTitle,
    defaultMessage: en[PlayersType.followersTitle],
    description: "-",
  },
  [PlayersType.likesTitle]: {
    id: PlayersType.likesTitle,
    defaultMessage: en[PlayersType.likesTitle],
    description: "-",
  },
  [PlayersType.clicksTitle]: {
    id: PlayersType.clicksTitle,
    defaultMessage: en[PlayersType.clicksTitle],
    description: "-",
  },
  [PlayersType.nameHeader]: {
    id: PlayersType.nameHeader,
    defaultMessage: en[PlayersType.nameHeader],
    description: "-",
  },
  [PlayersType.phoneHeader]: {
    id: PlayersType.phoneHeader,
    defaultMessage: en[PlayersType.phoneHeader],
    description: "-",
  },
  [PlayersType.tagsHeader]: {
    id: PlayersType.tagsHeader,
    defaultMessage: en[PlayersType.tagsHeader],
    description: "-",
  },
  [PlayersType.lastPaymentHeader]: {
    id: PlayersType.lastPaymentHeader,
    defaultMessage: en[PlayersType.lastPaymentHeader],
    description: "-",
  },
  [PlayersType.activeFrom]: {
    id: PlayersType.activeFrom,
    defaultMessage: en[PlayersType.activeFrom],
    description: "-",
  },

  [PlayersType.noResultsMessage]: {
    id: PlayersType.noResultsMessage,
    defaultMessage: en[PlayersType.noResultsMessage],
    description: "-",
  },
});
