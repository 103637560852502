import * as React from "react";
import { Input, Menu, Icon, InputOnChangeData, Loader, Dropdown, DropdownItemProps } from "semantic-ui-react";
import { get, debounce } from "lodash";

import { AppContext } from "../../providers";
import { FilterType, OrderType, Page, PlayerAccount, SearchFilter } from "../../interfaces";

import "./styles.scss";
import { CustomDropDownComponent } from "../CustomDropDown";

const DefaultDebounceTime = 1000;

interface Props {
  /**
   * Component
   */
  content: JSX.Element;

  showSortBy?: boolean;
  customCoachId?: number | string;

  /**
   * Handle to refresh content with the result
   */
  refreshData: (result: any[]) => void;

  /**
   * Search function name
   */
  searchFunction: (
    coachId: string,
    sort: string,
    filters?: SearchFilter | null,
    page?: number | string,
    size?: number
  ) => Promise<Page<any>>;

  filters?: FilterType[];
  defaultFilters?: string[];
  filterPlaceholder?: string;
  searchAttribute?: string;
  displaySusbcriptionFilter?: boolean;

  filterCondition?: (element: any, searchTerm: string) => boolean;
}

const LocalSearchFC: React.FC<Props> = ({
  content,
  showSortBy = true,
  searchAttribute = "email",
  displaySusbcriptionFilter = false,
  defaultFilters,
  filters,
  filterCondition,
  filterPlaceholder = "Filter by",
  refreshData,
  searchFunction,
}) => {
  const { userContext } = React.useContext(AppContext);
  const coachId = get(userContext, "coach.id", "");

  const [loading, setLoading] = React.useState<boolean>(false);
  const [order, setOrder] = React.useState<OrderType>("DESC");
  const [searchTitle, setSearchTitle] = React.useState<string | null>(null);
  const [results, setResults] = React.useState<PlayerAccount[]>([]);
  const ascOrder = order === "ASC";

  const [searchStatus, setSearchStatus] = React.useState<string | undefined>(undefined);
  const [selectedSubscriptions, setSelectedSubscriptions] = React.useState<string[] | undefined>(undefined);

  const [subscriptionTypeOptions] = React.useState<DropdownItemProps[]>([
    { key: "PLAYER_BASIC", text: "Basic", value: "PLAYER_BASIC" },
    {
      key: "PLAYER_PLUS",
      text: "SocialCoach+",
      value: "PLAYER_PLUS",
    },
  ]);

  const promptStatus = filters;

  React.useEffect(() => {
    if (!loading) {
      loadAllData();
    }
  }, []);

  React.useEffect(() => {
    refreshData(results);
  }, [results]);

  React.useEffect(() => {
    loadAllData();
  }, [searchStatus, selectedSubscriptions]);

  const loadAllData = async () => {
    setLoading(true);

    let page = 0;
    let total = 1;

    const filterBody: SearchFilter = {
      status: searchStatus !== undefined ? [searchStatus] : defaultFilters ? defaultFilters : searchStatus,
      subscriptionTypes: selectedSubscriptions ? selectedSubscriptions : undefined,
    };

    while (page < total) {
      const response = await searchFunction(coachId, "ASC", filterBody, page);
      if (page === 0) {
        total = response.totalPages!!;
        setResults(() => response.content);
      } else {
        setResults(currentResult => currentResult.concat(response.content));
      }

      page++;
    }

    setLoading(false);
  };

  const inputOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
    searchByContent(data.value);
  };

  const searchByContent = (newValue: string): void => {
    let result = results.sort((a, b) => new Date(b.createdDate!).valueOf() - new Date(a.createdDate!).valueOf());
    result = newValue && newValue !== "" ? result.filter(r => filterValidation(r, newValue)) : result;
    result = ascOrder ? result : result.reverse();

    refreshData(result);
    setSearchTitle(newValue);
  };
  const changeOrder = (): void => {
    const newOrder = ascOrder ? "DESC" : "ASC";
    let result = results.sort((a, b) => new Date(b.createdDate!).valueOf() - new Date(a.createdDate!).valueOf());
    result =
      searchTitle && searchTitle !== ""
        ? result.filter(r => filterValidation(r, searchTitle))
        : result.filter(r => true);
    result = !ascOrder ? result : result.reverse();

    refreshData(result);
    setOrder(newOrder);
  };

  const filterValidation = (element: any, searchTerm: string): boolean => {
    if (filterCondition) {
      return filterCondition(element, searchTerm);
    }
    return get(element, searchAttribute, "").includes(searchTerm);
  };

  return (
    <div>
      <Menu secondary className={"playerInfinityFilterMenu"}>
        {/* SEARCH BY ... */}
        {!loading && (
          <Menu.Item className={"searchMenu"}>
            <Input
              className={"searchInput"}
              icon="search"
              placeholder="Search..."
              onChange={debounce(
                e => {
                  inputOnChangeHandler(e, e.target);
                },
                DefaultDebounceTime,
                {
                  leading: true,
                }
              )}
            />
          </Menu.Item>
        )}
        {/* SORT BY  */}
        {showSortBy && (
          <Menu.Menu floated="right">
            {/* ORDER BY */}
            <Menu.Item className={"menuIcon"} icon onClick={changeOrder}>
              <Icon name={ascOrder ? "arrow up" : "arrow down"} />
            </Menu.Item>
          </Menu.Menu>
        )}

        {/* FILTER BY STATUS */}
        {promptStatus && promptStatus?.length > 0 && (
          <Menu.Item>
            <Dropdown
              search
              selection
              placeholder={filterPlaceholder}
              className="searchInput"
              multiple={true}
              selectOnBlur={false}
              onChange={(e, data) => {
                setSearchStatus(data.value !== "" ? (data.value as string) : undefined);
              }}
              clearable
              options={promptStatus}
            />
          </Menu.Item>
        )}

        {/* FILTER BY Review Subscription */}
        {displaySusbcriptionFilter && (
          <CustomDropDownComponent
            defaultValue={undefined}
            options={subscriptionTypeOptions}
            placeholder={"Subscription Type"}
            multiple={true}
            onChangeHandler={setSelectedSubscriptions}
          />
        )}
      </Menu>

      {loading && <Loader active size="large" />}
      {/* List of elements */}
      {content}
    </div>
  );
};

export const PlayersInfinitySearch = React.memo(LocalSearchFC);
