import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import { Header, Grid, Loader } from "semantic-ui-react";
import { get } from "lodash";
import ReactHtmlParser from "react-html-parser";

import { ConfigContext } from "../../../contexts/appContexts";
import { LandingResponse, Plan } from "../../../interfaces/dtos";
import { PlayerRegisterForm } from "../../../components";
import { CoachingProfileService } from "../../../services";
import { AppContext } from "../../../providers";
import { URLS } from "../../../utils";
import * as gtag from "../../../utils/gtag";

import { CoachSummary } from "../PlanSection";
import { SuccessSection } from "../SignupSuccess";

import "./styles.scss";

// TODO: Migracion gtag
interface PathProps {
  coachId: string;
  step: string;
}

type Props = RouteComponentProps<PathProps>;

const PlayerSignUpPageFC: React.FC<Props> = ({
  match: {
    params: { coachId },
  },
  history,
}) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [coachInfo, setCoachInfo] = React.useState<LandingResponse>();
  const [step, setStep] = React.useState<0 | 1 | 2>(0);
  const [prospectId, setProspectId] = React.useState<number | undefined>();
  const [playerEmail, setPlayerEmail] = React.useState("");
  const [referredFromUserId, setReferredFromUserId] = React.useState<
    number | undefined
  >(undefined);

  const location = useLocation();
  const selectedPlanId = new URLSearchParams(location.search).get("planId");

  const [plan, setPlan] = React.useState<Plan>();

  const sessionContext = React.useContext(AppContext);
  const { deployBaseUrl } = React.useContext(ConfigContext);

  const bannerURL = get(coachInfo, "bannerUrl", undefined);
  const bannerStyle = bannerURL
    ? {
        backgroundImage: "url(" + bannerURL + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundSize: "cover",
        // backgroundSize: "contain",
        width: "100%",
        margin: "0 auto",
      }
    : {};

  React.useEffect(() => {
    if (
      sessionContext.userContext !== undefined &&
      sessionContext.userContext.player
    ) {
      setProspectId(sessionContext.userContext?.player?.id);
      setPlayerEmail(sessionContext.userContext?.profile.email!);
      setStep(1);
      getCoachInfo(coachId);
    } else {
      getPublicInfo(coachId);
    }
  }, []);

  const findCorrectPlan = (
    pricePlans: Plan[] | undefined
  ): Plan | undefined => {
    if (!pricePlans) {
      return undefined;
    }
    if (selectedPlanId) {
      const selectedPlan = pricePlans?.find(
        (p) => p.id?.toString() === selectedPlanId
      );
      if (selectedPlan) {
        return selectedPlan;
      }
    }
    if (pricePlans.length > 0) {
      return pricePlans[0];
    }
    return undefined;
  };

  const getPublicInfo = async (identifier: string) => {
    await CoachingProfileService.landingPagePublic(identifier)
      .then((response) => {
        setLoading(false);
        setCoachInfo(response);
        setPlan(findCorrectPlan(response.pricePlans));
      })
      .catch((e) => {
        setLoading(false);
        setCoachInfo(undefined);
        setPlan(undefined);
      });
  };

  const getCoachInfo = async (identifier: string) => {
    await CoachingProfileService.landingPagePublicById(identifier)
      .then((response) => {
        setLoading(false);
        setCoachInfo(response);
        setReferredFromUserId(Number(response!.coach!.userId!));
        setPlan(findCorrectPlan(response.pricePlans));
      })
      .catch((e) => {
        setLoading(false);
        setCoachInfo(undefined);
        setPlan(undefined);
      });
  };

  const leftSection: JSX.Element = (
    <Grid.Column
      only={"computer"}
      floated="left"
      width={7}
      className={"coachSection"}
    >
      <Grid centered className={"infoSection"}>
        <Grid.Row>
          <Header as="h1">
            {coachInfo?.coach.firstName + " " + coachInfo?.coach.lastName}{" "}
          </Header>
        </Grid.Row>
        {coachInfo?.salesMessage && (
          <Grid.Row>
            <div>{ReactHtmlParser(coachInfo!.salesMessage)}</div>
          </Grid.Row>
        )}
      </Grid>
    </Grid.Column>
  );

  const rightSection: JSX.Element = (
    <Grid.Column
      floated="right"
      mobile={16}
      tablet={16}
      computer={9}
      largescreen={9}
      centered
      className={"noPaddingMobile"}
    >
      <Grid className={"rightSection"}>
        {step === 0 && (
          <PlayerRegisterForm
            referredFromUserId={coachInfo ? coachInfo!.coachId! : ""}
            successHandler={(response) => {
              setProspectId(response.prospectivePlayerId);
              setReferredFromUserId(Number(coachInfo!.coach!.userId!));
              setPlayerEmail(response.email || "");
              setStep(1);
              gtag.config({ user_id: response.prospectivePlayerId });
              // gtag.event("sign_up");
              gtag.eventTrack("sign_up", {});
            }}
            userRegisteredHandler={(email) => {
              history.push(URLS.player.login.replace(":coachId", coachId), {
                username: email,
              });
            }}
          />
        )}
        {step === 1 && prospectId && (
          <CoachSummary
            playerEmail={playerEmail}
            referredFromUserId={referredFromUserId!}
            plan={plan}
            sharedLink={
              coachInfo
                ? `${deployBaseUrl}player/sign-in/${coachInfo!.sharedLinkId}`
                : ""
            }
            onPaymentSuccess={() => {
              setStep(2);
              gtag.eventTrack("purchase", {
                items: plan?.planName,
                value: plan?.price,
              });
              // gtag.event("purchase", {
              //   items: plan?.planName,
              //   value: plan?.price,
              // });
            }}
          />
        )}
        {step === 2 && <SuccessSection playerEmail={playerEmail} />}
      </Grid>
    </Grid.Column>
  );

  const content: JSX.Element = (
    <div className={"playerWhiteContainer"}>
      <Grid className={"playerContainer"} columns={1}>
        <Grid columns={1} className={"noPaddingMobile gridCentered"}>
          <div className={"userBanner"} style={bannerStyle}>
            {bannerURL && (
              <img
                src={bannerURL!!}
                style={{
                  opacity: 0,
                  width: "100%",
                  visibility: "hidden",
                }}
              />
            )}
          </div>
          <Grid className={"playerSignUpContent"}>
            {leftSection}
            {rightSection}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );

  return <div>{loading ? <Loader active size="large" /> : content}</div>;
};

export const PlayerSignUpPage = withRouter(PlayerSignUpPageFC);
