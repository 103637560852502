import { useState, useEffect } from 'react';
import { TiktokServices } from '../../services';
import { TiktokQueryInfoResponse } from '../../interfaces';

// Get player tiktok QueryInfo
const useQueryInfo = (playerId?: number) => {
  const [loadingQueryInfo, setLoadingQueryInfo] = useState<boolean>(false);
  const [queryInfo, setQueryInfo] = useState<
    TiktokQueryInfoResponse | undefined
  >(undefined);

  useEffect(() => {
    if (playerId) {
      setLoadingQueryInfo(true);
      TiktokServices.queryInfo(playerId)
        .then(reponse => {
          if (reponse.data) {
            setQueryInfo(reponse);
          }
        })
        .finally(() => {
          setLoadingQueryInfo(false);
        });
    }
  }, [playerId]);

  return { queryInfo, loadingQueryInfo };
};

export { useQueryInfo };
