import * as React from "react";
import { Draggable } from "react-beautiful-dnd";
import { Icon } from "semantic-ui-react";
import { PromptCampaign, CampaignDeliveryMode } from "../../interfaces";
import { PromptLargeCard } from "../Cells";

import "./styles.scss";

interface DraggablePromptItemProps {
  src: PromptCampaign;
  id: number;
  isAutoPost?: boolean;
  editable?: boolean;
  campaignDeliveryMode: CampaignDeliveryMode;
  socialNetworks: string[];

  handleSetFieldValue: (field: string, value: any) => void;
  index: number;
}

const DraggablePromptItemFC: React.FC<DraggablePromptItemProps> = ({
  src,
  id,
  index,
  isAutoPost = true,
  editable = true,
  campaignDeliveryMode,
  socialNetworks,
  handleSetFieldValue,
}: DraggablePromptItemProps) => {
  const props = {
    prompt: src.prompt!,
    campaignDeliveryMode,
    isAutoPost,
    socialNetworks,
    handleSetFieldValue,
    editable: true,
  };

  return (
    <>
      <Draggable key={id} draggableId={`campaign-prompt-item-${id}`} index={index}>
        {(provided: any, snapshot: any) => (
          <div
            ref={provided.innerRef}
            className={"mediumPaddingTop"}
            {...provided.draggableProps}
            {...provided.dragHandleProps}>
            <div className={"no-overflow no-pointer-events"}>
              <PromptLargeCard key={src.promptId} {...props} isDragging={snapshot.isDragging} editable={editable} />
            </div>
          </div>
        )}
      </Draggable>
    </>
  );
};

export const DraggablePromptItem = DraggablePromptItemFC;
