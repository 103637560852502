import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useIntl } from "react-intl";
import { useCookies } from "react-cookie";
import { Formik } from "formik";
import { isNil } from "lodash";

import { SocialMediaStep } from "../../../components";
import { AppContext } from "../../../providers";
import { SocialCoachSessionService } from "../../../services";

import { ErrorType, descriptors } from "./descriptors";
interface OwnProps {
  fields?: {
    title: string;
    company: string;
  };

  userId?: number;
  nextHandler: (data?: any) => void;
  backHandler: () => void;
}

type Props = OwnProps & RouteComponentProps;
const SocialRegisterFC: React.FC<Props> = ({
  fields = {
    company: "",
    title: "",
  },
  nextHandler,
  backHandler,
  history,
}) => {
  const { formatMessage } = useIntl();
  const [cookies] = useCookies(["user-sc-register"]);
  const [socialNetworks, setSocialNetworks] = React.useState({});
  const { userContext } = React.useContext(AppContext);

  React.useEffect(() => {
    if (!userContext && (cookies["user-sc-register"] === "null" || !cookies["user-sc-register"])) {
      backHandler();
    }
  }, []);

  return (
    <div>
      <Formik
        validateOnBlur={false}
        validateOnChange={true}
        initialValues={{
          title: "",
          company: "",
        }}
        onSubmit={async (formFields, { setSubmitting, setErrors, validateForm }) => {
          try {
            const errors = await validateForm(formFields);

            if (errors && Object.values(errors).length > 0) {
              setErrors(errors);
            } else if (isNil(cookies["user-sc-register"].account.userId)) {
              errors["social"] = formatMessage({ ...descriptors[ErrorType.invalidUser] });
              setErrors(errors);
            } else {
              await SocialCoachSessionService.createUserStep2(
                {
                  title: formFields!.title,
                  companyName: formFields!.company ? formFields!.company : "",
                  socialNetworks: Object.values(socialNetworks),
                },
                cookies["user-sc-register"].account.userId
              ).then(() => {
                nextHandler();
              });
            }
          } catch (e) {
            setErrors({
              title: String(e),
              company: undefined,
            });
          }
          setSubmitting(false);
        }}
      >
        {({ errors, handleChange, handleSubmit, isSubmitting, setErrors }) => (
          <SocialMediaStep
            messages={{
              error: Object.values(errors) as string[],
            }}
            handleBack={backHandler}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            socialNetworks={socialNetworks}
            handleSocialNetworks={networks => {
              errors["social"] = null;
              setErrors(errors.title ? errors : {});
              setSocialNetworks(networks);
            }}
            step={2}
          />
        )}
      </Formik>
    </div>
  );
};

export const SocialForm = withRouter(SocialRegisterFC);
