import * as React from "react";
import { Grid, Input, Label, SemanticWIDTHS } from "semantic-ui-react";
import "./styles.scss";

interface Props {
  currentValue: string;
  customIconImage?: string;
  dataElmId: string;
  headerType: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  maxLength?: number;
  width?: SemanticWIDTHS;
  handleChangeValue?: (value: string) => void;
  cancelable?: boolean;
  disable?: boolean;
}

const EditableFieldFC: React.FC<Props> = ({
  currentValue,
  disable = false,
  dataElmId,
  headerType,
  maxLength,
  cancelable = false,
  width = 5,
  handleChangeValue,
}) => {
  const [editable, setEditable] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(currentValue);

  React.useEffect(() => {
    setInputValue(currentValue);
  }, [currentValue]);

  let iconProps = {};
  if (!disable) {
    iconProps = {
      icon: {
        name: !editable ? "pencil" : "save",
        link: true,
        onClick: () => {
          if (editable) {
            if (handleChangeValue) {
              handleChangeValue(inputValue);
            }
            setInputValue(currentValue);
          }
          setEditable(!editable);
        },
      },
    };
  }
  if (cancelable) {
    iconProps = {
      ...iconProps,
      style: { width: `${(currentValue.length + 1) * 8.5}px` },
    };
  }

  return (
    <Grid.Column width={width} floated="left" className={"editableField"}>
      <Input
        {...iconProps}
        name={dataElmId}
        disabled={disable}
        data-elm-id={dataElmId}
        value={!editable ? currentValue : inputValue}
        id={dataElmId}
        className={"inputSection" + headerType + (!editable ? " noBorderField" : " withBorder")}
        onChange={e => {
          if (!maxLength || e.target.value.length < maxLength) {
            setInputValue(e.target.value);
          }
        }}
      />
      {cancelable && editable && (
        <Label
          basic
          onClick={() => {
            setEditable(!editable);
          }}>
          Cancel
        </Label>
      )}
    </Grid.Column>
  );
};

export const EditableField = EditableFieldFC;
