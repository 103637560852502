import * as Yup from "yup";
import { QuestionLimitsConfig } from "../../../interfaces";
import { phoneRegExp, validAge } from "../../../utils";
import { keys } from "lodash";

export const validationProfileSchema = (
  questions: { [key: string]: string },
  questionsLimits: QuestionLimitsConfig,
  configQuestions: { [key: string]: string }
) => {
  return Yup.object().shape(
    // keys(questions).reduce(
    // (accum, questionKey) => ({
    //   ...accum,
    //   [questionKey]: Yup.number()
    //     .required(configQuestions[questionKey] + " is required")
    //     .lessThan(
    //       Number(Number(questionsLimits[questionKey]) + 1),
    //       configQuestions[questionKey] + " must be less than " + Number(Number(questionsLimits[questionKey]) + 1)
    //     )
    //     .moreThan(0, configQuestions[questionKey] + " must be greater than 0. "),
    // }),
    {
      firstName: Yup.string().trim().required("First Name is required"),
      lastName: Yup.string().trim().required("Last Name is required"),
      birthday: Yup.date()
        .max(validAge(new Date(), -13), "The minimum age for using SocialCoach is 13 years old")
        .required("Birthday is required"),
      phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid").required("Phone number is required"),
    }
  );
  // );
};
