import { defineMessages } from "react-intl";

export enum FirstLoginType {
  title = "FirstLoginType.title",
  subtitle = "FirstLoginType.subtitle",

  submit = "FirstLoginType.submit",

  warningTitle = "FirstLoginType.warningTitle",
  errorTitle = "FirstLoginType.errorTitle",
  infoTitle = "FirstLoginType.infoTitle",
}

export const en = {
  [FirstLoginType.title]: "-",
  [FirstLoginType.subtitle]: "Tell us more about your business.\n",

  [FirstLoginType.submit]: "Next",

  [FirstLoginType.warningTitle]: "Warning",
  [FirstLoginType.errorTitle]: "Error:",
  [FirstLoginType.infoTitle]: "Hey",
};

export const descriptors = defineMessages({
  [FirstLoginType.title]: {
    id: FirstLoginType.title,
    defaultMessage: en[FirstLoginType.title],
    description: "Log In title",
  },
  [FirstLoginType.subtitle]: {
    id: FirstLoginType.subtitle,
    defaultMessage: en[FirstLoginType.subtitle],
    description: "Log In subtitle",
  },
  [FirstLoginType.submit]: {
    id: FirstLoginType.submit,
    defaultMessage: en[FirstLoginType.submit],
    description: "Sign In text",
  },
  [FirstLoginType.warningTitle]: {
    id: FirstLoginType.warningTitle,
    defaultMessage: en[FirstLoginType.warningTitle],
    description: "Warning messages title",
  },
  [FirstLoginType.errorTitle]: {
    id: FirstLoginType.errorTitle,
    defaultMessage: en[FirstLoginType.errorTitle],
    description: "Error Messages title",
  },
  [FirstLoginType.infoTitle]: {
    id: FirstLoginType.infoTitle,
    defaultMessage: en[FirstLoginType.infoTitle],
    description: "Info messages title",
  },
});
