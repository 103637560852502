import { defineMessages } from "react-intl";

export enum PlayerPostingType {
  title = "PlayerPostingType.title",
  subtitle = "PlayerPostingType.subtitle",
}

export const en = {
  [PlayerPostingType.title]: "Who is this post for?",
  [PlayerPostingType.subtitle]: " ",
};

export const descriptors = defineMessages({
  [PlayerPostingType.title]: {
    id: PlayerPostingType.title,
    defaultMessage: en[PlayerPostingType.title],
    description: "-",
  },
  [PlayerPostingType.subtitle]: {
    id: PlayerPostingType.subtitle,
    defaultMessage: en[PlayerPostingType.subtitle],
    description: "-",
  },
});
