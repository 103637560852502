import { defineMessages } from "react-intl";

export enum PlayersType {
  nameHeader = "PlayersType.nameHeader",
  createdDateHeader = "PlayersType.createdDateHeader",
  coachRequestedHeader = "PlayersType.coachRequestedHeader",
}

export const en = {
  [PlayersType.nameHeader]: "Name",
  [PlayersType.createdDateHeader]: "Date",
  [PlayersType.coachRequestedHeader]: "Coach Requested",
};

export const descriptors = defineMessages({
  [PlayersType.nameHeader]: {
    id: PlayersType.nameHeader,
    defaultMessage: en[PlayersType.nameHeader],
    description: "-",
  },
  [PlayersType.createdDateHeader]: {
    id: PlayersType.createdDateHeader,
    defaultMessage: en[PlayersType.createdDateHeader],
    description: "-",
  },
  [PlayersType.coachRequestedHeader]: {
    id: PlayersType.coachRequestedHeader,
    defaultMessage: en[PlayersType.coachRequestedHeader],
    description: "-",
  },
});
