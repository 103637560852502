import { defineMessages } from "react-intl";

export enum EditingQueueType {
  cancelBtn = "EditingQueueType.cancelBtn",
  title = "EditingQueueType.title",
}

export const en = {
  [EditingQueueType.cancelBtn]: "Cancel",
  [EditingQueueType.title]: "Editing Queue",
};

export const descriptors = defineMessages({
  [EditingQueueType.cancelBtn]: {
    id: EditingQueueType.cancelBtn,
    defaultMessage: en[EditingQueueType.cancelBtn],
    description: "",
  },
  [EditingQueueType.title]: {
    id: EditingQueueType.title,
    defaultMessage: en[EditingQueueType.title],
    description: "title",
  },
});
