import * as React from "react";

import { Form, Grid, Button, Header } from "semantic-ui-react";
import { DayOfWeek } from "../../../../interfaces/dtos";
import "./styles.scss";

const ArrayOfDays = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
  DayOfWeek.Sunday,
];

const getDayOfWeekState = (selected: DayOfWeek[]) => {
  const defaultSelected = selected.length === 0;
  const state = {
    [DayOfWeek.Monday]: defaultSelected,
    [DayOfWeek.Tuesday]: false,
    [DayOfWeek.Wednesday]: defaultSelected,
    [DayOfWeek.Thursday]: false,
    [DayOfWeek.Friday]: defaultSelected,
    [DayOfWeek.Saturday]: false,
    [DayOfWeek.Sunday]: false,
  };

  selected.forEach((dayOfWeek: DayOfWeek) => {
    state[dayOfWeek] = true;
  });

  return state;
};

interface Props {
  onChange: (value: DayOfWeek[]) => any;
  selected: DayOfWeek[];
}

export default ({ selected, onChange }: Props) => {
  const selectedDays = getDayOfWeekState(selected);

  const toggleSelectedDay = (dayOfWeek: DayOfWeek) => {
    selectedDays[dayOfWeek] = !selectedDays[dayOfWeek];
    onChange(ArrayOfDays.filter(day => selectedDays[day]));
  };

  React.useEffect(() => {
    if (selected.length > 0) {
      onChange(selected);
    } else {
      onChange([DayOfWeek.Monday, DayOfWeek.Wednesday, DayOfWeek.Friday]);
    }
  }, [selectedDays]);

  return (
    <Form>
      <Grid columns="7" className={"dayOfWeekGrid"}>
        <Grid.Row>
          <Header as="span" floated={"right"}>
            {"Days we should send randomized prompts to your players"}
          </Header>
        </Grid.Row>
        <Grid.Row>
          {ArrayOfDays.map(dayOfWeek => (
            <Grid.Column key={dayOfWeek} textAlign={"center"}>
              <Button
                data-elm-id={`multiDaysSellectorDayOfWeekBtn`}
                toggle
                name={dayOfWeek}
                active={selectedDays[dayOfWeek]}
                onClick={(e: any) => {
                  toggleSelectedDay(e.target.name as DayOfWeek);
                }}>
                {dayOfWeek.substring(0, 3)}
              </Button>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Form>
  );
};
