import * as React from "react";
import {
  Input,
  Menu,
  Icon,
  InputOnChangeData,
  Loader,
} from "semantic-ui-react";
import { get, debounce } from "lodash";

import { AppContext } from "../../providers";
import { OrderType, Page, PlayerAccount } from "../../interfaces";

import "./styles.scss";

const DefaultDebounceTime = 1000;

interface Props {
  /**
   * Component
   */
  content: JSX.Element;

  showSortBy?: boolean;
  customCoachId?: number | string;

  /**
   * Handle to refresh content with the result
   */
  refreshData: (result: any[]) => void;

  /**
   * Search function name
   */
  searchFunction: (coachId: string, searchTerm?: string) => Promise<Page<any>>;

  searchAttribute?: string;
  filterCondition?: (element: any, searchTerm: string) => boolean;
}

const LocalSearchFC: React.FC<Props> = ({
  content,
  customCoachId,
  showSortBy = true,
  searchAttribute = "email",
  filterCondition,
  refreshData,
  searchFunction,
}) => {
  const { userContext } = React.useContext(AppContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [order, setOrder] = React.useState<OrderType>("DESC");
  const [searchTitle, setSearchTitle] = React.useState<string | null>(null);
  const [results, setResults] = React.useState<PlayerAccount[]>([]);

  const ascOrder = order === "ASC";

  React.useEffect(() => {
    search();
  }, [searchFunction]);

  React.useEffect(() => {
    search();
  }, []);

  React.useEffect(() => {
    refreshData(results);
  }, [results]);

  const search = () => {
    setLoading(true);

    const coachId = customCoachId
      ? customCoachId
      : get(userContext, "coach.id", "");

    searchFunction(coachId.toString())
      .then((response) => {
        let result = response.content.sort(
          (a, b) =>
            new Date(b.createdDate).valueOf() -
            new Date(a.createdDate).valueOf()
        );
        result = ascOrder ? result : result.reverse();
        result =
          searchTitle && searchTitle !== ""
            ? result.filter((r) => filterValidation(r, searchTitle))
            : result;
        refreshData(result);
        setResults(response.content);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const searchByContent = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ): void => {
    let result = results.sort(
      (a, b) =>
        new Date(b.createdDate!).valueOf() - new Date(a.createdDate!).valueOf()
    );
    result =
      data.value && data.value !== ""
        ? result.filter((r) => filterValidation(r, data.value))
        : result;
    result = ascOrder ? result : result.reverse();

    refreshData(result);
    setSearchTitle(data.value);
  };

  const changeOrder = (): void => {
    const newOrder = ascOrder ? "DESC" : "ASC";
    let result = results.sort(
      (a, b) =>
        new Date(b.createdDate!).valueOf() - new Date(a.createdDate!).valueOf()
    );
    result =
      searchTitle && searchTitle !== ""
        ? result.filter((r) => filterValidation(r, searchTitle))
        : result.filter((r) => true);
    result = !ascOrder ? result : result.reverse();

    refreshData(result);
    setOrder(newOrder);
  };

  const filterValidation = (element: any, searchTerm: string): boolean => {
    if (filterCondition) {
      return filterCondition(element, searchTerm);
    }
    return get(element, searchAttribute, "").includes(searchTerm);
  };

  return (
    <div>
      <Menu secondary className={"localSearchFilterMenu"}>
        {/* SEARCH BY ... */}
        <Menu.Item className={"searchMenu"}>
          <Input
            className={"searchInput"}
            icon="search"
            placeholder="Search..."
            onChange={debounce(
              (e) => {
                searchByContent(e, e.target);
              },
              DefaultDebounceTime,
              {
                leading: true,
              }
            )}
          />
        </Menu.Item>

        {/* SORT BY  */}
        {showSortBy && (
          <Menu.Menu floated="right">
            {/* ORDER BY */}
            <Menu.Item className={"menuIcon"} icon onClick={changeOrder}>
              <Icon name={ascOrder ? "arrow up" : "arrow down"} />
            </Menu.Item>
          </Menu.Menu>
        )}
      </Menu>

      {loading && <Loader active size="large" />}
      {/* List of elements */}
      {content}
    </div>
  );
};

export const LocalSearch = LocalSearchFC;
