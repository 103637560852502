import * as React from "react";
import { Image } from "semantic-ui-react";
import { Svg } from "../../../assets";

interface OwnProps {
  onClick?: () => void;
  bannerUrl?: string;
}

type Props = OwnProps;

const EditSectionFC: React.FC<Props> = ({ bannerUrl, onClick }) => {
  return (
    <div className="selectedImageSection">
      <div className="selectedImageBox">
        <img src={bannerUrl} alt="" className="uploadImage" />
        <Image src={Svg.EditBanner} className={"editIcon"} onClick={onClick} />
      </div>
      <span>Web Browser view</span>
    </div>
  );
};

export const EditSection = EditSectionFC;
