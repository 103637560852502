import { defineMessages } from "react-intl";

export enum CampaignPlayerType {
  title = "CampaignPlayerType.title",
  filterPlaceHolder = "CampaignPlayerType.filterPlaceHolder",
  selectedPrompt = "CampaignPlayerType.selectedPrompt",
  multipleSelectedPrompts = "CampaignPlayerType.multipleSelectedPrompts",
}

export const en = {
  [CampaignPlayerType.title]: "Select the Campaigns in which you'd like to enroll this Player (optional)",
  [CampaignPlayerType.selectedPrompt]: "{promptsCounter} Campaign selected for this player",
  [CampaignPlayerType.multipleSelectedPrompts]: "{promptsCounter} Campaigns selected for this player",
  [CampaignPlayerType.filterPlaceHolder]: "Group by categories",
};

export const descriptors = defineMessages({
  [CampaignPlayerType.title]: {
    id: CampaignPlayerType.title,
    defaultMessage: en[CampaignPlayerType.title],
    description: "-",
  },
  [CampaignPlayerType.selectedPrompt]: {
    id: CampaignPlayerType.selectedPrompt,
    defaultMessage: en[CampaignPlayerType.selectedPrompt],
    description: "-",
  },
  [CampaignPlayerType.multipleSelectedPrompts]: {
    id: CampaignPlayerType.multipleSelectedPrompts,
    defaultMessage: en[CampaignPlayerType.multipleSelectedPrompts],
    description: "-",
  },
  [CampaignPlayerType.filterPlaceHolder]: {
    id: CampaignPlayerType.filterPlaceHolder,
    defaultMessage: en[CampaignPlayerType.filterPlaceHolder],
    description: "-",
  },
});
