/* tslint:disable:no-namespace */
import axios from "axios";
import querystring from "querystring";

export interface InstagramAccessTokenResponse {
  accessToken: string;
  userId: string;
}
namespace InstagramSC {
  export const getInstagramAccessToken = async (
    code: string,
    clientId: string,
    clientSecret: string,
    redirectUri: string,
    grantType: string = "authorization_code"
  ): Promise<InstagramAccessTokenResponse> => {
    const data = {
      code,
      client_id: clientId,
      client_secret: clientSecret,
      grant_type: grantType,
      redirect_uri: redirectUri,
    };

    const response = await axios.post("https://api.instagram.com/oauth/access_token", querystring.stringify(data));
    const { access_token, user_id } = response.data;
    return Promise.resolve({
      accessToken: access_token,
      userId: user_id,
    });
  };

  export const getInstagramUserData = async (accessToken: string, fields: string = "id,username"): Promise<any> => {
    const response = await axios.get(`https://graph.instagram.com/me?access_token=${accessToken}&fields=${fields}`);
    return Promise.resolve(response.data);
  };
}

export const Instagram = InstagramSC;
