import * as React from "react";
import { Button, Header, Image } from "semantic-ui-react";

import { Png } from "../../../../assets";
import { AppContext } from "../../../../providers";
import { BasicWhiteContainer } from "../../../../styling/baseStyle";

interface OwnProps {
  returnUrl: string;
  brandName: string;
  isAutoPost?: boolean;
  sendMode: string;
}

const SuccessPageFC: React.FC<OwnProps> = ({ returnUrl, brandName, isAutoPost = false, sendMode }) => {
  const { userContext } = React.useContext(AppContext);

  const successHandler = (): void => {
    window.location.replace(returnUrl);
  };

  return (
    <BasicWhiteContainer>
      <Header as={"h4"} className="centered">
        {isAutoPost
          ? `Congrats! Your post has been ${sendMode === "SCHEDULED" ? "scheduled!" : "published!"}`
          : `Congrats! Your post has been sent to ${userContext?.coach?.id ? "the player’s" : "your"} mobile device!`}
      </Header>
      <Image className={"centered"} src={Png.BrandPostSuccess} size={"medium"} />
      <Button className={"primary rounded"} onClick={successHandler}>
        Return to {brandName}
      </Button>
    </BasicWhiteContainer>
  );
};

export const SuccessPage = SuccessPageFC;
