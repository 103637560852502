import * as React from "react";
import { CampaignResume } from "../../../interfaces";
import { SelectableCard } from "../SelectableCard";

import "./styles.scss";

interface OwnProps {
  campaign: CampaignResume;
  selected?: boolean;
  editable: boolean;
  onChange?: (checked: boolean) => void;
}

type Props = OwnProps;

const CampaignSelectableCardFC: React.FC<Props> = ({
  campaign: { campaignName, createdDate, firstPromptMedia },
  selected = false,
  editable = true,
  onChange,
}: Props) => {
  return (
    <SelectableCard
      post={firstPromptMedia}
      title={campaignName}
      creationDate={createdDate}
      onChange={onChange}
      selected={selected}
      editable={editable}
    />
  );
};

export const CampaignSelectableCard = CampaignSelectableCardFC;
