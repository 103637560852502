import React, { useState } from "react";
import { Form } from "formik";
import { Button, Grid, GridColumn, Message } from "semantic-ui-react";

import "./styles.scss";

interface OwnProps {
  children?: any;
  formikRef: any;
  errors?: string[];
  warnings?: string[];

  updateStep: (step: number) => void;
}

export const FormStepper: React.FC<OwnProps> = ({ children, formikRef, errors, warnings, updateStep }) => {
  const stepsArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const [showErrors, setShowErrors] = useState(false);
  const currentStep = stepsArray[step];
  const totalSteps = stepsArray.length - 1;
  const formSubmitRef = React.useRef<HTMLFormElement>(null);

  return (
    <div className={"brandPostFormContainer brandPostFormStepsContainer"}>
      {showErrors && errors && errors?.length > 0 && <Message error list={errors} />}
      {warnings && warnings?.length > 0 && <Message warning list={warnings} />}
      <Form ref={formSubmitRef} className={"ui large form brandPostFormStepsContainer brandPostFormContainer"}>
        {currentStep}
        <Grid className="withPaddingBottom centered stepperButtons" columns={step > 0 ? 2 : 1}>
          {step > 0 && (
            <GridColumn>
              <Button
                type="button"
                className={"secondary rounded"}
                onClick={() => {
                  setStep(step - 1);
                  updateStep(step - 1);
                  setShowErrors(false);
                }}>
                {"Back"}
              </Button>
            </GridColumn>
          )}
          <GridColumn>
            {step < totalSteps && (
              <Button
                className={"primary rounded"}
                type="button"
                onClick={async () => {
                  formikRef.current.validateForm().then(errors => {
                    if (Object.values(errors).length === 0) {
                      setStep(step + 1);
                      updateStep(step + 1);
                      setShowErrors(false);
                    } else {
                      setShowErrors(true);
                    }
                  });
                }}>
                Next
              </Button>
            )}
            {step === totalSteps && (
              <Button className={"primary rounded"} type="submit">
                Next
              </Button>
            )}
          </GridColumn>
        </Grid>
      </Form>
    </div>
  );
};
