import * as React from "react";
import { useIntl } from "react-intl";
import { Grid, Header, Loader, Icon } from "semantic-ui-react";

import { PlayerPost } from "../../../../../interfaces";
import { PostServices } from "../../../../../services";

import { NeedsAttentionCard } from "./components/NeedsAttentionCard";

import { descriptors, DashboardType } from "../../descriptors";
import "./styles.scss";

interface OwnProps {
  playerId: number;
}
type Props = OwnProps;

const NeedsAttentionFC: React.FC<Props> = ({ playerId }) => {
  const { formatMessage } = useIntl();

  const [posts, setPosts] = React.useState<PlayerPost[]>([]);

  const [loading, setLoading] = React.useState<boolean>(false);

  const getInfo = async () => {
    try {
      setLoading(true);
      PostServices.needAttentionPost(playerId)
        .then(response => {
          setPosts(response.content);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getInfo();
  }, [playerId]);

  return (
    <Grid.Row columns={1} className={"needsAttentionContent"}>
      <Grid.Column>
        <Grid columns={1}>
          <Grid.Row columns={1}>
            <Grid.Column width={16}>
              {loading && <Loader active size="large" />}
              {posts && posts.length > 0 && (
                <Grid className={"needsAttention"}>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Header as="h2">
                        {formatMessage({ ...descriptors[DashboardType.needsAttentionSectionTitle] })}
                        <Icon size="tiny" color={"red"} name="warning sign" />
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                  <div className="postCardContainer">
                    {posts.map((post, index) => (
                      <NeedsAttentionCard key={index} post={post} refreshHandler={getInfo} />
                    ))}
                  </div>
                </Grid>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid.Row>
  );
};

export const NeedsAttention = NeedsAttentionFC;
