import * as React from "react";
import{ useIntl } from "react-intl";

import { PromptCell } from "../../../../Cells";
import * as DTO from "../../../../../interfaces/dtos";

interface OwnProps {
  promptList: DTO.Prompt[];
}

type Props = OwnProps 

export default ({ promptList }: Props) => {
  return (
    <div className="promptListContainer">
      {promptList.map(prompt => {
        return <PromptCell key={"prompt_list_cell_" + prompt.promptId} prompt={prompt} />;
      })}
    </div>
  );
}
