import * as React from "react";
import { Grid, Progress, Button, Image, Label, Icon, Message, Header, Input, Popup } from "semantic-ui-react";
import{ useIntl } from "react-intl";
import { ConfigContext } from "../../contexts/appContexts";
import { Upload } from "antd";

import { upload } from "../../utils/vimeo";
import "./styles.scss";

const bigFileError = "Video should have 5 mins max duration. Unable to upload a video.";
const genericError = "Upload Error";
const recommendMessage = "We recommend your video duration to be no more than 5 min";

interface OwnProps {
  dataElmId?: string;
  accept?: string;
  label?: string;
  fileName: string;
  buttonName?: string;
  className?: string;
  placeholder?: string;
  /**
   * To show errors in to the component (default false)
   */
  showErrors?: boolean;

  showSubtitle?: boolean;

  /**
   *
   */
  disabled?: boolean;

  videoInfo?: string;
  /**
   * Handler called when the upload video is success.
   */
  handleSuccess: (videoUrl: string, extension: string) => void;
  /**
   * Handler called when the upload video is fail or the video has some problem.
   */
  handleError: (error?: string) => void;

  handleUploadStarted?: () => void;
  /**
   * Handler called when the preview is ready.
   */
  onChangeImage: (file: File) => void;
}

type Props = OwnProps 

const UploadVideoFC: React.FC<Props> = ({
  dataElmId = "string",
  accept = "video/mp4",
  fileName = "video",
  buttonName = "Upload Video",
  className = "primary",
  showSubtitle = false,
  showErrors = false,
  disabled = false,
  label,
  placeholder,
  videoInfo,
  handleSuccess,
  handleError,
  onChangeImage,
}) => {
  const [file, setFile] = React.useState<string | null>("");
  const [percent, setPercent] = React.useState<number>(0);
  const { vimeo: vimeoConfig } = React.useContext(ConfigContext);

  const [error, setError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(genericError);
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <div className="uploadSection">
      <Grid.Row>
        <Grid.Column>
          <label>{label}</label>
          {videoInfo && <Popup content={videoInfo} trigger={<Icon name="info circle" className={"infoIcon"} link />} />}
          <br />
          {showSubtitle && <label className={"title small"}>{recommendMessage}</label>}
        </Grid.Column>
        <Grid.Column className={label ? "uploadContainer" : ""}>
          <Upload
            accept={accept}
            data-elm-id={dataElmId}
            showUploadList={false}
            action={async fileToUpload => {
              onChangeImage(fileToUpload);
              setFile(fileToUpload.name);
              setLoading(true);
              return new Promise(resolve1 => {
                upload(
                  vimeoConfig.access_token,
                  fileToUpload,
                  `${fileName}_${fileToUpload.name}`,
                  vimeoConfig.folder_id || "2066860",
                  ({ url, html, extension }) => {
                    handleSuccess(url, extension);
                    setPercent(0);
                    setLoading(false);
                    setError(false);
                  },
                  e => {
                    setErrorMessage(genericError);
                    setError(true);
                  },
                  progress => {
                    setPercent(progress);
                  }
                );
              });
            }}>
            {percent > 0 && loading && <Progress percent={percent} indicating />}
            {!loading && (
              <Input value={file} disabled={disabled} className={"uploadInput"} placeholder={placeholder}>
                <input />
                <Button
                  data-elm-id={`uploadVideo${buttonName.replace(" ", "")}Btn`}
                  type={"button"}
                  className={className}>
                  {buttonName}
                </Button>
              </Input>
            )}
            {error && <Message error content={errorMessage} />}
          </Upload>
        </Grid.Column>
      </Grid.Row>
    </div>
  );
};

export const UploadVideo = UploadVideoFC
