import { defineMessages } from "react-intl";

export enum ErrorType {
  email = "LoginType.email",
  password = "LoginType.password",
  invalidUser = "LoginType.invalidUser",
  invalidEmail = "LoginType.invalidEmail",
  playerRegistered = "LoginType.playerRegistered",
  playerWithoutReferred = "LoginType.playerWithoutReferred",
  playerWithSubscription = "LoginType.playerWithSubscription",
  inactiveAccount = "LoginType.inactiveAccount",
  noActivePlayer = "LoginType.noActivePlayer",
  inactiveCoach = "LoginType.inactiveCoach",
}

export const en = {
  [ErrorType.email]: "Email is Required",
  [ErrorType.password]: "Password is Required",
  [ErrorType.invalidUser]: "Invalid User or password",
  [ErrorType.invalidEmail]: "Invalid email format",
  [ErrorType.playerRegistered]:
    "Oops! This section is for Coaches Only. Please download or open your SocialCoach app to enjoy the service.",
  [ErrorType.playerWithoutReferred]: "playerWithoutReferred TBD",
  [ErrorType.inactiveAccount]: " Account not activated. Please click email link or email support@socialcoach.io ",
  [ErrorType.inactiveCoach]: " Your coach account is not active ",
  [ErrorType.playerWithSubscription]:
    "You can only have one active subscription at a time. You can register with a new email or contact us at support@socialcoach.io",
  [ErrorType.noActivePlayer]:
    "Your account is no longer active. In order to activate your account, please contact us at support@socialcoach.io",
};

export const descriptors = defineMessages({
  [ErrorType.email]: {
    id: ErrorType.email,
    defaultMessage: en[ErrorType.email],
    description: "Email address",
  },
  [ErrorType.password]: {
    id: ErrorType.password,
    defaultMessage: en[ErrorType.password],
    description: "Password text",
  },
  [ErrorType.invalidUser]: {
    id: ErrorType.invalidUser,
    defaultMessage: en[ErrorType.invalidUser],
    description: "Invalid credentials",
  },
  [ErrorType.noActivePlayer]: {
    id: ErrorType.noActivePlayer,
    defaultMessage: en[ErrorType.noActivePlayer],
    description: "noActivePlayer",
  },
  [ErrorType.invalidEmail]: {
    id: ErrorType.invalidEmail,
    defaultMessage: en[ErrorType.invalidEmail],
    description: "Invalid email format",
  },
  [ErrorType.playerRegistered]: {
    id: ErrorType.playerRegistered,
    defaultMessage: en[ErrorType.playerRegistered],
    description: "Player login error",
  },
  [ErrorType.playerWithoutReferred]: {
    id: ErrorType.playerWithoutReferred,
    defaultMessage: en[ErrorType.playerWithoutReferred],
    description: "Player login error",
  },
  [ErrorType.inactiveAccount]: {
    id: ErrorType.inactiveAccount,
    defaultMessage: en[ErrorType.inactiveAccount],
    description: "inactiveAccount error",
  },
  [ErrorType.inactiveCoach]: {
    id: ErrorType.inactiveCoach,
    defaultMessage: en[ErrorType.inactiveCoach],
    description: "inactiveCoach error",
  },
  [ErrorType.playerWithSubscription]: {
    id: ErrorType.playerWithSubscription,
    defaultMessage: en[ErrorType.playerWithSubscription],
    description: "playerWithSubscription error",
  },
});
