import React from "react";
import { Grid, Image } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { descriptors, SignupSuccessType } from "./descriptors";
import { Png } from "../../../assets";

import { Title, SuccessMessage, EmailMessage } from "./styles";

interface OwnProps {
  playerEmail: string;
}
type Props = OwnProps 

const SuccessSectionFC: React.FC<Props> = ({ playerEmail }) => {
  const { formatMessage } = useIntl();
  return (
    <Grid centered style={{ width: "100%" }}>
      <Grid.Row>
        <Grid.Column width={4}>
          <Image src={Png.Check} size={"large"} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Title as="h2">{formatMessage({ ...descriptors[SignupSuccessType.title] })}</Title>
      </Grid.Row>
      <Grid.Row>
        <SuccessMessage>
          <FormattedMessage {...descriptors[SignupSuccessType.accountCreated]} values={{ email: playerEmail }} />
        </SuccessMessage>
      </Grid.Row>
      <Grid.Row>
        <EmailMessage>
          <FormattedMessage {...descriptors[SignupSuccessType.checkEmail]} />
        </EmailMessage>
      </Grid.Row>
    </Grid>
  );
};

export const SuccessSection = SuccessSectionFC
