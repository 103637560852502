import styled from "styled-components";
import { Form, Grid, Checkbox, Icon, Dropdown } from "semantic-ui-react";

import { theme } from "../../../styling/theme";

export const ContainerGrid = styled(Grid.Row)`
  background: ${(props: any) => (props.color ? props.color : "transparent")};
`;

export const ContainerContent = styled(Grid.Column)`
  background: ${(props: any) => (props.color ? props.color : "transparent")};
`;

export const PreviewInput = styled(Form.Input)({
  background: `${theme.lightBackgroundColor} !important`,
  borderRadius: "6.3px",
  border: `0.9px solid ${theme.boxLightBorder}`,
});

export const ThumbnailPreview = styled(Grid.Column)`
    max-width: 89px;
    min-width: 89px;
    height: 50px;
    border-radius: 10px;
    margin: 5px;
    background: url("${(props: any) => (props.thumbnailurl ? props.thumbnailurl : "")}") no-repeat center center;
    background-size: cover;
`;

export const SelectRadio = styled(Checkbox)``;

export const DeleteIcon = styled(Icon)`
  color: ${theme.iconLightColor};
`;

export const CategoriesDropDown = styled(Dropdown)({
  minWidth: "12em !important",
});
