export enum QuestionSetType {
  ONBOARDING = "ONBOARDING",
}

export interface Question {
  questionId?: number;
  title: string;
  sortOrder: number;
}

export interface QuestionSet {
  questionSetId?: number;
  coachId: string;
  type: QuestionSetType;
  questions: Question[];
}
