import { defineMessages } from "react-intl";

export enum FeeType {
  planDetails = "FeeType.planDetails",
  feesDesc = "FeeType.feesDesc",
  socialFees = "FeeType.socialFees",
  tdcFees = "FeeType.tdcFees",
  incomeDesc = "FeeType.incomeDesc",
}

export const en = {
  [FeeType.planDetails]: "We take care of all the billing. You will receive a direct deposit each {interval}.",
  [FeeType.feesDesc]: " Fees",
  [FeeType.socialFees]: " - Platform Fee",
  [FeeType.tdcFees]: "% - Credit Card Processing Fee",
  [FeeType.incomeDesc]: "{intervalType} income per player",
};

export const descriptors = defineMessages({
  [FeeType.planDetails]: {
    id: FeeType.planDetails,
    defaultMessage: en[FeeType.planDetails],
    description: "-",
  },
  [FeeType.feesDesc]: {
    id: FeeType.feesDesc,
    defaultMessage: en[FeeType.feesDesc],
    description: "-",
  },
  [FeeType.socialFees]: {
    id: FeeType.socialFees,
    defaultMessage: en[FeeType.socialFees],
    description: "-",
  },
  [FeeType.tdcFees]: {
    id: FeeType.tdcFees,
    defaultMessage: en[FeeType.tdcFees],
    description: "-",
  },
  [FeeType.incomeDesc]: {
    id: FeeType.incomeDesc,
    defaultMessage: en[FeeType.incomeDesc],
    description: "-",
  },
});
