import * as React from "react";
import { Loader } from "semantic-ui-react";

import * as DTO from "../../interfaces";
import { SearchComponent } from "../Search";
import { CampaignServices } from "../../services";
import { TransparentContainer, ContentGrid, SegmentedGrid } from "../../styling/baseStyle";

import { PlayersListWithCampaignStats } from "../PlayersListWithCampaignStats";

import "./styles.scss";
import { useSocialNetworks } from"../../reducers";

interface OwnProps {
  campaignId?: string;
  subscribedIds: number[];
}
type Props = OwnProps 

const EnrolledStatisticsFC: React.FC<Props> = ({ campaignId, subscribedIds }) => {
  const [playersProcessList, setPlayersProcessList] = React.useState<DTO.PlayerWithCampaignStats[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const { sns } = useSocialNetworks();

  const preparePlayerList = (playersList: DTO.CampaignSubscribedStatistic[]): void => {
    const playerWithStatsResponse: DTO.PlayerWithCampaignStats[] = playersList.map(player => {
      return {
        info: player.playerAccount,
        photoUrl: player.playerAccount.photoUrl,
        email: player.playerAccount.email,
        countPromptReceived: player.countPromptReceived,
        countPromptPosted: player.countPromptPosted,
        countPromptRemaining: player.countPromptRemaining,
      } as DTO.PlayerWithCampaignStats;
    });
    setPlayersProcessList(() => playerWithStatsResponse);
  };

  return (
    <TransparentContainer>
      <SegmentedGrid className={"subscriptionContent"} columns={1}>
        <ContentGrid.Row className={"playersListStatsContainer"}>
          {loading ? (
            <Loader active size="large" />
          ) : (
            <SearchComponent
              refreshData={preparePlayerList}
              content={<PlayersListWithCampaignStats playerResult={playersProcessList} socialNetworks={sns} />}
              searchFunction={CampaignServices.statistics}
              showSortBy={false}
              filters={[]}
              showSearch={false}
              campaignId={campaignId!}
            />
          )}
        </ContentGrid.Row>
      </SegmentedGrid>
    </TransparentContainer>
  );
};

export const EnrolledStatistics = EnrolledStatisticsFC
