import * as React from "react";

import { Table, Image, Icon } from "semantic-ui-react";

import * as DTO from "../../../../interfaces";
import { userFullName } from "../../../../utils";
import { TitleSubtitleCell } from "../../../Cells";
import { Svg } from "../../../../assets";

import "./styles.scss";
import { SocialNetworkStatisticCell } from "../SocialNetworkStatisticCell";

interface OwnProps {
  player: DTO.PlayerWithStats;
  index: number;
  availableSocialNetworks: DTO.SocialNetworksId[];
}

const PlayerStatisticCellFC: React.FC<OwnProps> = ({ player, index, availableSocialNetworks }) => {
  const [display, setDisplay] = React.useState<boolean>(false);
  const { email, photoUrl, info, countPromptPosted, likes, click, followers, comments, shares, views } = player;
  return (
    <React.Fragment key={info.playerId}>
      <Table.Row
        key={info.playerId + "row"}
        className={display ? "rowDisplayed" : "dashboardStatsCell"}
        onClick={() => setDisplay(!display)}>
        <Table.Cell className={"playerStatsCellShowMoreBtn"}>
          <Icon name={display ? "chevron up" : "chevron right"} className={"playerStatsCellShowMoreBtn"} link />
        </Table.Cell>
        <Table.Cell>
          <Image circular size="mini" src={photoUrl ? photoUrl : Svg.noImageuser} />
        </Table.Cell>
        <TitleSubtitleCell maxLength={30} title={email} subtitle={userFullName(info)} />
        <Table.Cell>{followers || 0}</Table.Cell>
        <Table.Cell>{countPromptPosted && countPromptPosted}</Table.Cell>
        <Table.Cell>{likes || 0}</Table.Cell>
        <Table.Cell>{comments || 0}</Table.Cell>
        {/* <Table.Cell>{views || 0}</Table.Cell> */}
        <Table.Cell>{shares || 0}</Table.Cell>
        <Table.Cell>{click || 0}</Table.Cell>
      </Table.Row>
      {/* Expanded section */}
      {display &&
        availableSocialNetworks.map(networkId => {
          return (
            <SocialNetworkStatisticCell
              key={player.info.playerId + networkId}
              networkId={networkId}
              index={index}
              player={player.statsBySocialNetwork[networkId]}
              keyId={player.info.playerId + networkId}
            />
          );
        })}
      {display && availableSocialNetworks.length % 2 !== 0 && <Table.Row className="invisibleRow" />}
    </React.Fragment>
  );
};
export const PlayerStatisticCell = PlayerStatisticCellFC;
