import { DurationOnSuccess, FileErrorDescription } from "../interfaces";

const MAX_BITRATE = 25;
const MAX_MB_FILE = 100;

const getBitrate = (videoSize: number, durationInMinutes: number): number => {
  return videoSize / (durationInMinutes * 0.0075);
};

const checkFileDuration2 = async (
  file: File,
  callback: (errorDescription?: FileErrorDescription) => void,
  maxDuration: number = 300,
  minDuration: number = 0,
  isAPromptVideo: boolean = false
): Promise<any> => {
  const video = document.createElement("video");
  const url = URL.createObjectURL(file);

  video.addEventListener("loadedmetadata", () => {
    URL.revokeObjectURL(url);
    const fileSizeInMG = file.size / 1000000;
    const videoSize = fileSizeInMG / 1024;
    const minutes = video.duration / 60;
    // console.log("videoSize", videoSize)
    // console.log("fileSizeInMG", fileSizeInMG)
    // console.log("minutes", minutes)
    // console.log("video.duration", video.duration)
    // Bitrate = file size / (number of minutes * .0075).
    const bitrate = getBitrate(videoSize, minutes); // videoSize / (minutes * 0.0075);
    // console.log("BITRATE", bitrate)
    // console.log("Valide file size", fileSizeInMG < MAX_MB_FILE)
    if (
      video.duration <= maxDuration &&
      video.duration >= minDuration &&
      ((bitrate < MAX_BITRATE && fileSizeInMG < MAX_MB_FILE) || !isAPromptVideo)
    ) {
      return callback();
    } else {
      return callback({
        wrongBitrate: bitrate > MAX_BITRATE,
        wrongLength: video.duration > maxDuration || video.duration < minDuration,
        wrongSize: fileSizeInMG > MAX_MB_FILE,
      });
    }
  });

  video.preload = "metadata";
  video.src = url;
};

const checkFileDuration = async (
  file: File,
  onSuccess: (body: DurationOnSuccess) => any,
  onError: (errorDescription: FileErrorDescription) => void,
  maxDuration: number = 300,
  minDuration: number = 0,
  isAPromptVideo: boolean = false
) => {
  const video = document.createElement("video");
  const url = URL.createObjectURL(file);

  video.addEventListener("loadedmetadata", () => {
    URL.revokeObjectURL(url);
    const fileSizeInMG = file.size / 1000000;
    const videoSize = fileSizeInMG / 1024;
    const minutes = video.duration / 60;
    // console.log("videoSize", videoSize)
    // console.log("fileSizeInMG", fileSizeInMG)
    // console.log("minutes", minutes)
    // console.log("video.duration", video.duration)
    // Bitrate = file size / (number of minutes * .0075).
    const bitrate = getBitrate(videoSize, minutes); // videoSize / (minutes * 0.0075);
    // console.log("BITRATE", bitrate)
    // console.log("Valide file size", fileSizeInMG < MAX_MB_FILE)
    if (
      video.duration <= maxDuration &&
      video.duration >= minDuration &&
      ((bitrate < MAX_BITRATE && fileSizeInMG < MAX_MB_FILE) || !isAPromptVideo)
    ) {
      return onSuccess({
        duration: video.duration,
      });
    } else {
      return onError({
        wrongBitrate: bitrate > MAX_BITRATE,
        wrongLength: minutes > 90,
        wrongSize: videoSize > MAX_MB_FILE,
      });
    }
  });

  video.preload = "metadata";
  video.src = url;
};

export { checkFileDuration2 };
