import * as React from "react";
import { Grid } from "semantic-ui-react";

import "./styles.scss";

interface Props {
  requestTokenUrl: string;
  loginUrl: string;
  credentials: "omit" | "same-origin" | "include";
  forceLogin: boolean;
  screenName: string;
  customHeaders?: any;
  children: React.ReactNode;
  tag: "div" | "button";

  onFailure: (e: any) => void;
  onSuccess: (response: any) => void;
}

export const SCTwitterLogin: React.FC<Props> = ({
  loginUrl,
  customHeaders,
  onFailure,
  onSuccess,
  screenName,
  forceLogin,
  requestTokenUrl,
  credentials = "same-origin",
  children,
  tag = "div"
}) => {
  const openPopup = (): any => {
    const w = 600;
    const h = 400;
    const left = screen.width / 2 - w / 2;
    const top = screen.height / 2 - h / 2;

    return window.open(
      "",
      "",
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  const getHeaders = (): any => {
    const headers = Object.assign({}, customHeaders);
    headers["Content-Type"] = "application/json";
    return headers;
  };

  const getOauthToken = (oAuthVerifier: string | null, oauthToken: string | null): any => {
    return window
      .fetch(`${loginUrl}?oauth_verifier=${oAuthVerifier}&oauth_token=${oauthToken}`, {
        method: "POST",
        credentials: credentials,
        headers: getHeaders(),
      })
      .then(response => {
        onSuccess(response);
      })
      .catch(error => {
        return onFailure(error);
      });
  };

  const polling = (popup: any): any => {
    const polling = setInterval(() => {
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(polling);
        onFailure(new Error("Popup has been closed by user"));
      }

      const closeDialog = () => {
        clearInterval(polling);
        popup.close();
      };

      try {
        if (!popup.location.hostname.includes("api.twitter.com") && popup.location.hostname !== "") {
          if (popup.location.search) {
            const query = new URLSearchParams(popup.location.search);

            const oauthToken = query.get("oauth_token");
            const oauthVerifier = query.get("oauth_verifier");

            closeDialog();
            return getOauthToken(oauthVerifier, oauthToken);
          } else {
            closeDialog();
            return onFailure(
              new Error(
                "OAuth redirect has occurred but no query or hash parameters were found. " +
                  "They were either not set during the redirect, or were removed—typically by a " +
                  "routing library—before Twitter react component could read it."
              )
            );
          }
        }
      } catch (error) {
        // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
        // A hack to get around same-origin security policy errors in IE.
      }
    }, 500);
  };

  const getRequestToken = (): any => {
    var popup = openPopup();
    return window
      .fetch(requestTokenUrl, {
        method: "POST",
        credentials: credentials,
        headers: getHeaders(),
      })
      .then(response => {
        return response.json();
      })
      .then(data => {
        let authenticationUrl = `https://api.twitter.com/oauth/authenticate?oauth_token=${data.oauth_token}&force_login=${forceLogin}`;
        if (screenName) {
          authenticationUrl = `${authenticationUrl}&screen_name=${screenName}`;
        }

        popup.location = authenticationUrl;
        polling(popup);
      })
      .catch(error => {
        popup.close();
        return onFailure(error);
      });
  };

  const onButtonClick = (e: any): any => {
    e.preventDefault();
    return getRequestToken();
  };

  const test =(): any =>{
    const twitterButton = React.createElement(
      tag,
      {
        onClick: onButtonClick,
        style: {},
        disabled: false,
        className: ""
      },
      children
    );
    return twitterButton;
  }


  return <Grid.Row columns={2} className={"socialLinkRow"}>{test()}</Grid.Row>;
};
