import * as React from "react";
import {
  Modal,
  Button,
  CardGroup,
  Card,
  CardContent,
  CardHeader,
  CardMeta,
  CardDescription,
  Icon,
  Grid,
} from "semantic-ui-react";

import "./styles.scss";
import { useState } from "react";
import classnames from "classnames";
import { AppContext } from "../../../../../providers";
import { PlayersServices } from "../../../../../services";

interface OwnProps {
  open: boolean;

  hasSCPlus: boolean;
  onClose?: () => void;
  okHandler: (option: "project" | "post") => void;
}
type Props = OwnProps;

const DestinationChoicesModalFC: React.FC<Props> = ({ open, hasSCPlus, onClose, okHandler }) => {
  const [selectedOption, setSelectedOption] = useState<"post" | "project">(hasSCPlus ? "project" : "post");
  const { userContext } = React.useContext(AppContext);
  const playerId = userContext?.player?.id;

  const addUpgradeLead = () => {
    if (playerId) PlayersServices.addPlayerUpgradeLead(playerId);
  };

  return (
    <Modal open={open} onClose={onClose} className={"destChoicesModal"} size={"small"}>
      <Modal.Header>Post to Social or Edit First?</Modal.Header>

      <Modal.Description className={"modalDescription"}>
        <CardGroup centered>
          <Card
            onClick={() => setSelectedOption("post")}
            className={classnames("destinationChoiceCard", {
              selected: selectedOption === "post",
            })}
          >
            <CardContent>
              <CardHeader content="Ready to post" />
              <CardMeta content="No edits needed" />
              <CardDescription>
                Share your video directly to social media. Hit 'Next' to customize and schedule your post.
              </CardDescription>
            </CardContent>
          </Card>

          <Card
            onClick={() => setSelectedOption("project")}
            className={classnames("destinationChoiceCard", {
              selected: selectedOption === "project",
              disabled: !hasSCPlus,
            })}
          >
            <CardContent>
              <CardHeader content="Submit for editing" />
              <CardMeta content="Professionally enhance your video" />
              <CardDescription>
                We'll refine your video with captions, music, and more. Click 'Next' to customize your posting
                preferences.
              </CardDescription>
            </CardContent>
          </Card>
        </CardGroup>
        {selectedOption === "project" && !hasSCPlus && (
          <Grid className={"warningMessage"}>
            <Grid.Row>
              <Icon name={"warning sign"} color={"orange"} className={"warningIcon"} />
              You are not currently subscribed to SocialCoach+
              <div>
                <a
                  href="https://www.socialcoach.io/features/video-catalyst"
                  target="_blank"
                  onClick={() => addUpgradeLead()}
                >
                  Click here
                </a>{" "}
                to learn more about Video Catalyst.
              </div>
            </Grid.Row>
          </Grid>
        )}
      </Modal.Description>

      <Modal.Actions>
        <Button data-elm-id={`confirmationModalNoBtn`} className={"secondary rounded padded"} onClick={onClose}>
          Cancel
        </Button>
        <Button
          data-elm-id={`confirmationModalYesBtn`}
          className={"primary rounded"}
          onClick={() => okHandler(selectedOption)}
          disabled={!hasSCPlus && selectedOption === "project"}
        >
          Next
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const DestinationChoicesModal = DestinationChoicesModalFC;
