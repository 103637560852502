import React from "react";
import styled from "styled-components";

import { Tag, Wrapper, TagDelete } from "./styled";

interface Props {
  tags: any[];
  tagDeleteIcon?: string;

  tagDeleteStyle?: string;
  tagStyle?: string;
  inputStyle?: string;
  wrapperStyle?: string;

  onDelete: (index: number, tag: any) => void;
}

export const CategoriesTags: React.FC<Props> = ({
  tags,
  tagDeleteIcon = "x",
  tagDeleteStyle,
  tagStyle,
  wrapperStyle,
  onDelete,
}) => {
  const [selectedTags, setSelectedTags] = React.useState(tags);

  React.useEffect(() => {
    setSelectedTags(() => tags);
  }, [tags]);

  const removeTag = (index: number): void => {
    onDelete(index, selectedTags.filter(tag => tag.index === index)[0]);
  };

  const renderTags = (): any => {
    const TagComponent = getTagStyledComponent();
    const Delete = getTagDeleteComponent();
    const DeleteIcon = getDeleteIcon();

    return selectedTags.length > 0
      ? selectedTags.map((tag, index) => (
          <TagComponent key={"categoryTag_" + index}>
            {tag && tag.displayValue}
            <Delete onClick={() => removeTag(tag.index)}>{DeleteIcon}</Delete>
          </TagComponent>
        ))
      : null;
  };

  const getDeleteIcon = (): string => {
    return tagDeleteIcon;
  };

  const getTagDeleteComponent = (): any => {
    return tagDeleteStyle
      ? styled(TagDelete)`
          ${tagDeleteStyle}
        `
      : TagDelete;
  };

  const getTagStyledComponent = (): any => {
    return tagStyle
      ? styled(Tag)`
          ${tagStyle}
        `
      : Tag;
  };

  const getInputWrapperStyledComponent = (): any => {
    return wrapperStyle
      ? styled(Wrapper)`
          ${wrapperStyle}
        `
      : Wrapper;
  };

  const InputWrapper = getInputWrapperStyledComponent();
  return <InputWrapper>{renderTags()}</InputWrapper>;
};
