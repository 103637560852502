export function modalReducer(state: any, action: any) {
  switch (action.type) {
    case "close":
      return { open: false };
    case "open_duplicated":
      return { open: true, status: action.status, duplicated: true, action: "duplicated" };
    case "open_cancel":
      return { open: true, status: action.status, duplicated: false, action: "cancel" };
    default:
      throw new Error("Unsupported action...");
  }
}
