import { useState, useEffect } from "react";
import { KeywordServices } from "../../../../services";
import { KeywordsType, TagElement } from "../../../../interfaces";
import { tagsByPolicy } from "../../../../utils";

const useBlockedWords = (coachId?: string, playerId?: number) => {
  const [blockedList, setBlockedList] = useState<TagElement[]>([]);
  const [flaggedList, setFlaggedList] = useState<TagElement[]>([]);
  const [blockedNamesList, setBlockedNamesList] = useState<string[]>([]);
  const [flaggedNamesList, setFlaggedNamesList] = useState<string[]>([]);

  useEffect(() => {
    const getKeywords = async () => {
      if (playerId || coachId) {
        const action = coachId ? KeywordServices.get(coachId) : KeywordServices.getAsPlayer(playerId!!)
      
        const result = await action
        setBlockedList(() => tagsByPolicy(result, KeywordsType.Blocked));
        setFlaggedList(() => tagsByPolicy(result, KeywordsType.Flagged));
      }
    };

    getKeywords();
  }, [coachId, playerId]);

  useEffect(() => {
    setBlockedNamesList(blockedList.map(a => a.displayValue.toLowerCase()));
  }, [blockedList]);

  useEffect(() => {
    setFlaggedNamesList(flaggedList.map(a => a.displayValue.toLowerCase()));
  }, [flaggedList]);

  return { blockedList, blockedNamesList, flaggedList, flaggedNamesList };
};

export default useBlockedWords;
