import * as React from "react";
import { Grid, Form, Button, Header, Icon, Message, Checkbox } from "semantic-ui-react";
import { Field, Formik } from "formik";
import { Profile, BaseCoach, CoachStatuses, UserGroups } from "../../../interfaces";
import { CoachingProfileService, CoachService, SocialCoachSessionService, UsersService } from "../../../services";
import { finishStep, phoneNumberMask } from "../../../utils";

import { validationSchema } from "./validations";

import "./styles.scss";
import { ConfigContext } from "../../../contexts/appContexts";
import MaskedInput from "react-text-mask";
import { SCAccount } from "../../../providers";

interface OwnProps {
  profile: Profile;
  user: SCAccount;
  coach: BaseCoach;
  signUpStep: string;
  handleUpdateSuccess?: () => void;
}

const CoachInformationFC: React.FC<OwnProps> = ({ profile, user, coach, signUpStep, handleUpdateSuccess }) => {
  const [coachStatus, setCoachStatus] = React.useState<CoachStatuses>(coach.coachStatus!!);
  const [sharedLink, setSharedLink] = React.useState<string | undefined>(undefined);
  const [confirmed, setConfirmed] = React.useState<boolean>(user.status !== "PENDING");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isDemo, setIsDemo] = React.useState<boolean>(user.isDemo || false);
  const [isEditor, setIsEditor] = React.useState<boolean>(false);
  const [userGroup, setUserGroups] = React.useState<any[]>(user!!.groups || []);

  const { deployBaseUrl } = React.useContext(ConfigContext);
  const updateCoachStatusRequest = async (status: CoachStatuses) => {
    await CoachService.updateCoachStatus(coach.id, status);
    setCoachStatus(status);
  };

  React.useEffect(() => {
    CoachingProfileService.getFinal(coach.id)
      .then(response => {
        setSharedLink(response.sharedLinkId);
      })
      .catch(e => {
        setSharedLink(undefined);
      });
  }, []);

  React.useEffect(() => {
    const editor = userGroup.some(g => g === UserGroups.EDITOR);
    setIsEditor(editor);
  }, [userGroup]);

  let marketingSiteUnavailable = sharedLink ? " " + sharedLink : " No Available ";

  if (!finishStep(signUpStep) && sharedLink) {
    marketingSiteUnavailable += " - The first login has not been completed";
  }

  const authenticateUser = (): void => {
    if (user.status === "PENDING") {
      UsersService.adminAuthenticateCoachEmail(user.userId).then(response => {
        setConfirmed(true);
      });
    }
  };

  const updateCoachType = (type: "BASIC" | "ENTERPRISE"): void => {
    setLoading(true);
    CoachingProfileService.updateCoachType(coach.id, type).finally(() => setLoading(false));
  };

  const updateUserGroup = (groups: UserGroups[]): void => {
    setLoading(true);
    const update = {
      ...user,
      groups,
    };

    UsersService.updateUserGroup(update).finally(() => setLoading(false));
  };

  return (
    <Grid columns="1">
      <Grid.Row>
        <Grid.Column>
          <Formik
            initialValues={{
              firstName: profile.firstName,
              lastName: profile.lastName,
              title: coach.title,
              company: coach.companyName,
              phone: profile.phone,
              birthday: profile.birthday,
              email: user.email,
              status: coach.coachStatus,
              type: coach.type || "",
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setErrors }) => {
              const updatedUser = {
                coach: {
                  ...coach,
                  title: values.title,
                  companyName: values.company,
                },
                profile: {
                  ...profile,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  phone: values.phone,
                },
                user: {
                  ...user,
                  email: values.email,
                  username: values.email,
                },
              };

              SocialCoachSessionService.updateCoach(coach.id, updatedUser, user.userId).then(response => {
                if (response.user && response.user["code"] === "account_http_409") {
                  setErrors({
                    email: "Username already taken",
                  });
                } else {
                  if (handleUpdateSuccess) {
                    handleUpdateSuccess();
                  }
                }
              });
            }}
          >
            {({ values, errors, handleChange, handleSubmit, setFieldValue }) => {
              const errorList = Object.values(errors) as string[];
              const basicCoach = values.type === "BASIC";
              return (
                <Form data-elm-id="coach-information-form" onSubmit={handleSubmit} error={errorList.length > 0}>
                  <Grid className={"coachSocialNetwork"}>
                    <Message error list={errorList} />
                  </Grid>
                  <Form.Group widths="equal">
                    <Form.Input
                      value={values.firstName}
                      data-elm-id="firstname-input"
                      type="text"
                      size="mini"
                      id="firstName"
                      name="firstName"
                      onChange={handleChange}
                      fluid
                      label="First name"
                      placeholder="First name"
                    />
                    <Form.Input
                      value={values.lastName}
                      onChange={handleChange}
                      data-elm-id="lastname-input"
                      type="text"
                      size="mini"
                      id="lastName"
                      name="lastName"
                      fluid
                      label="Last name"
                      placeholder="Last name"
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Input
                      value={values.title}
                      onChange={handleChange}
                      data-elm-id="title-input"
                      type="text"
                      size="mini"
                      id="title"
                      name="title"
                      fluid
                      label="Title"
                      placeholder="Title"
                    />
                    <Form.Input
                      value={values.company}
                      onChange={handleChange}
                      data-elm-id="company-input"
                      type="text"
                      size="mini"
                      id="company"
                      name="company"
                      fluid
                      label="Company"
                      placeholder="Company"
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Field
                      name="phoneNumber"
                      data-elm-id={"coach-infor-phone-number"}
                      render={({ phoneField }: { phoneField: any }) => (
                        <MaskedInput
                          {...phoneField}
                          value={values.phone}
                          mask={phoneNumberMask}
                          id="phone"
                          size="mini"
                          placeholder={"Phone"}
                          type="text"
                          onChange={handleChange}
                          data-elm-id="phone-input"
                        />
                      )}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Input
                      value={values.birthday}
                      onChange={handleChange}
                      data-elm-id="birthday-input"
                      type="text"
                      size="mini"
                      id="birthday"
                      name="birthday"
                      fluid
                      label="Birthday"
                      disabled
                      placeholder="Birthday"
                    />
                    <Form.Input
                      value={values.email}
                      onChange={handleChange}
                      data-elm-id="email-input"
                      type="text"
                      size="mini"
                      id="email"
                      name="email"
                      fluid
                      label="Email"
                      placeholder="Email"
                    />
                    <Form.Input
                      value={coachStatus}
                      onChange={handleChange}
                      data-elm-id="status-input"
                      type="text"
                      size="mini"
                      id="status"
                      disabled
                      name="status"
                      fluid
                      label="Status"
                      placeholder="Status"
                    />
                  </Form.Group>
                  <Grid className={"coachSocialNetwork"}>
                    <Grid.Row
                      columns={2}
                      onClick={() => {
                        const newValue = basicCoach ? "ENTERPRISE" : "BASIC";
                        setFieldValue("type", newValue);
                        updateCoachType(newValue);
                      }}
                    >
                      <Grid.Column width={3}>
                        <Header as="h4">Coach Type:</Header>
                      </Grid.Column>
                      <Grid.Column className={"coachTypeColumn"}>
                        <p className={"capitalized"}>{values.type!.toLowerCase() + " " + "Coach "}</p>
                        <Icon
                          loading={loading}
                          size={"small"}
                          flipped={basicCoach ? "vertically" : undefined}
                          name="refresh"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Grid className={"coachSocialNetwork"}>
                    <Grid.Row>
                      <Grid.Column>
                        {" "}
                        <Header as="h4">
                          Marketing Site:
                          {sharedLink && finishStep(signUpStep) ? (
                            <a
                              data-elm-id={"marketingSiteLink" + coach.id}
                              target="_blank"
                              href={`${deployBaseUrl}${sharedLink}`}
                            >
                              {sharedLink}
                            </a>
                          ) : (
                            marketingSiteUnavailable
                          )}
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Grid className={"coachSocialNetwork"}>
                    <Grid.Row>
                      <Grid.Column>
                        {" "}
                        <Header as="h4">
                          Authenticate Email:
                          <span className="buttons-link" onClick={authenticateUser}>
                            {"  " + user.email} {confirmed && <Icon color="green" name="checkmark" size="small" />}
                          </span>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Button.Group floated={"right"}>
                    <Button.Group floated={"left"}>
                      <Checkbox
                        className={"mediumMarginTop mediumMarginRight"}
                        checked={isDemo}
                        label="Is a demo/test account"
                        onChange={(_, e) => {
                          setIsDemo(e.checked || false);
                          UsersService.setDemoAccount(user.userId, e.checked || false).then(() => {
                            if (handleUpdateSuccess) {
                              handleUpdateSuccess();
                            }
                          });
                        }}
                      />
                    </Button.Group>

                    <Button.Group floated={"left"}>
                      <Checkbox
                        className={"mediumMarginTop mediumMarginRight"}
                        checked={isEditor}
                        label="Is an Editor"
                        onChange={(_, e) => {
                          const groups = e.checked ? [UserGroups.EDITOR] : [UserGroups.COACH];
                          setUserGroups(() => groups);
                          updateUserGroup(groups);
                        }}
                      />
                    </Button.Group>

                    <Button.Group floated={"right"}>
                      <Button
                        data-elm-id={`coachInfoActiveOrApproveBtn`}
                        compact
                        disabled={coachStatus === CoachStatuses.ACTIVE}
                        color="green"
                        onClick={async () => {
                          updateCoachStatusRequest(CoachStatuses.ACTIVE);
                        }}
                      >
                        {coachStatus === CoachStatuses.PENDING_FOR_APPROVAL ? "Approve Coach" : "Active Coach"}
                      </Button>
                      <Button.Or />
                      <Button
                        data-elm-id={`coachInfoDisableBtn`}
                        compact
                        disabled={coachStatus !== CoachStatuses.ACTIVE}
                        color="red"
                        onClick={async () => {
                          updateCoachStatusRequest(CoachStatuses.INACTIVE);
                        }}
                      >
                        Disable Coach
                      </Button>
                    </Button.Group>

                    <Form.Button
                      data-elm-id={`coachInfoSaveBtn`}
                      compact
                      className={"leftPadding"}
                      disabled={errorList.length > 0}
                      type="submit"
                    >
                      Save Coach
                    </Form.Button>
                  </Button.Group>
                </Form>
              );
            }}
          </Formik>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const CoachInformation = CoachInformationFC;
