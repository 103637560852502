import * as React from "react";
import{ useIntl, FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { Form } from "semantic-ui-react";

import { FloatedCard } from "../../../../containers";
import { BasicFormProps, LandingResponse, PlayerQuestion } from "../../../../interfaces";
import { ConfigContext } from "../../../../contexts/appContexts";

import { FirstLoginType, descriptors } from "./descriptors";
import { BubbleDetails } from "../BubbleDetails";
import { AppContext } from "../../../../providers";
import { PlayersServices } from "../../../../services";

import "./styles.scss";
 import {NumericFormat} from "react-number-format";

interface OwnProps extends BasicFormProps {
  handleSetFieldValue?: (field: string, value: any) => void;
}

type Props = OwnProps   & RouteComponentProps;

/**
 * Represents a Player First login screen - Step 3.
 */

const IntegratedFirstLoginQuestions: React.FC<Props> = ({

  messages,
  handleSetFieldValue,
  handleSubmit,
  isSubmitting,
  values,
}) => {
  const { formatMessage } = useIntl();
  const { questions: configQuestions } = React.useContext(ConfigContext);
  const { userContext } = React.useContext(AppContext);

  const [questions, setQuestions] = React.useState<PlayerQuestion[] | undefined>();
  const [coachInfo, setCoachInfo] = React.useState<LandingResponse>();

  React.useEffect(() => {
    if (userContext && userContext.player) {
      PlayersServices.getCoachData(userContext.player.id).then(response => {
        setCoachInfo(response);
      });
    }
  }, []);

  React.useEffect(() => {
    setQuestions(configQuestions.slice().reverse());
  }, []);

  const content = (
    <div className={"questionsDiv"}>
      {configQuestions &&
        configQuestions.length > 0 &&
        configQuestions.map((field, index) => {
          const isAFloat = field.answerType === "float";
          return (
            <Form.Group widths="equal" key={field.key + "_group_" + index}>
              <div className={"field"}>
                <label className={"profileInputLabel"}>{field.content}</label>
                <NumericFormat
                  key={field.key + index}
                  data-elm-id={"playerProfileInformation-" + field.key}
                  className={"questionInputs"}
                  decimalScale={isAFloat ? 2 : 0}
                  allowNegative={false}
                  thousandSeparator
                  onValueChange={value => {
                    handleSetFieldValue!(field.key, value.floatValue);
                  }}
                  placeholder={field.content}
                  value={values[field.key]}
                  disabled={isSubmitting}
                />
              </div>
            </Form.Group>
          );
        })}

      <Form.Group widths="equal" className={"buttonContainer"}>
        <Form.Button
          size="large"
          type="submit"
          disabled={isSubmitting}
          loading={isSubmitting}
          onClick={handleSubmit}
          className={"signUp"}>
          <FormattedMessage {...descriptors[FirstLoginType.submit]} />
        </Form.Button>
      </Form.Group>
    </div>
  );

  return (
    <>
      <FloatedCard
        subtitle={formatMessage({ ...descriptors[FirstLoginType.subtitle] })}
        content={content}
        messages={messages}
        isSubmitting={isSubmitting}
        size={"bigCentered"}
      />
      {coachInfo && (
        <BubbleDetails
          messages={[
            {
              message: "Great! Almost there. I just have a few questions about your business...",
            },
            // {
            //   message: questions?.reduce((accumulator, msg) => msg.content + "\n\n" + accumulator, "") || "",
            // },
          ]}
          currentPosition={0}
          coachInfo={coachInfo.coach}
        />
      )}
    </>
  );
};

export const FirstLoginQuestions = withRouter(IntegratedFirstLoginQuestions)
