import { Category, FilterType } from "../interfaces";

export function categoriesToFilter(result: Category[]): FilterType[] {
  return result.map((category, index) => {
    return {
      key: index,
      text: category.name || "",
      value: category.categoryId || "",
    };
  });
}
