import styled from "styled-components";
import { Card } from "semantic-ui-react";
import { theme } from "../../../styling/theme";
export const VideoScriptCardStyle = styled(Card)({
  background: `${theme.lightBackgroundColor} !important`,
  borderRadius: "6.3px !important",
  border: `1px solid ${theme.lineColor} !important`,
  boxShadow: `none !important`,
  width: "250px !important",
  margin: "6px !important",
  display: "inline-block",
});

export const VideoScriptDescription = styled(VideoScriptCardStyle.Description)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  "-webkit-line-clamp": "5",
  "-webkit-box-orient": "vertical",
  lineHeight: "1.5em !important",
  height: "7.5em",
  fontSize: "0.9em !important",
});
export const VideoScriptHeader = styled(VideoScriptCardStyle.Header)({
  fontSize: "1em !important",
});
export const VideoScriptMeta = styled(VideoScriptCardStyle.Meta)({
  marginTop: "8px",
});
