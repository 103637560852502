import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Grid, Tab } from "semantic-ui-react";

import { AppContext } from "../../../providers";
import { WebComponents, AlertType } from "../../../interfaces";
import { SideBar, TitleSection } from "../../../components";
import { AlertList } from "../../../components/Lists/AlertList";
import { coachWithWritePermission } from "../../../utils";

import { WhiteContainer, ContentGrid } from "../../../styling/baseStyle";
import "./styles.scss";

type Props = RouteComponentProps;

const AlertsFC: React.FC<Props> = ({ history }) => {
  const { userContext } = React.useContext(AppContext);
  const withWritePermission = coachWithWritePermission(WebComponents.ALERTS, userContext);

  const panes = [
    {
      menuItem: "Player not posting",
      render: () => (
        <Tab.Pane>
          <AlertList type={AlertType.PLAYER_NO_POSTING} withWritePermission={withWritePermission} defaultPage={0} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Campaign about to expire",
      render: () => (
        <Tab.Pane>
          <AlertList
            type={AlertType.CAMPAIGN_ABOUT_TO_EXPIRED}
            withWritePermission={withWritePermission}
            defaultPage={0}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Social network problems",
      render: () => (
        <Tab.Pane>
          <AlertList type={AlertType.SN_ERROR} withWritePermission={withWritePermission} defaultPage={0} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Post failed",
      render: () => (
        <Tab.Pane>
          <AlertList type={AlertType.POST_FAILED} withWritePermission={withWritePermission} defaultPage={0} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <WhiteContainer>
      <SideBar history={history} />

      <ContentGrid className={"listManagerContent"} columns={1}>
        <Grid columns={1}>
          <TitleSection title={"Alerts"} ready={true} showNextOption={false} showLinkOption={false} />

          <Grid.Row className={"alertsListRowContainer leftPadding coachBaseContainer"}>
            <Grid.Column>
              <Grid>
                <Grid.Row>
                  <Tab className={"basicPadding"} panes={panes} />
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const AlertsSection = withRouter(AlertsFC);
