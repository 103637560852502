import * as React from "react";
import { Grid, Header, Image } from "semantic-ui-react";

interface Props {
  text: string;
  customIconImage?: string;
}

export const EmptyView: React.FC<Props> = ({ text, customIconImage }) => {
  return (
    <Grid centered columns={2}>
      <Grid.Column>
        <Image src={customIconImage} className={"marginCentered"} size={"small"} />
      </Grid.Column>

      <Grid.Row centered columns={2}>
        <Grid.Column>
          <Header as={"h3"} textAlign={"center"}>
            {text}
          </Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
