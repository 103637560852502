import { HashTagElementResponse, KeywordElement, KeywordPhrase, KeywordsType } from "../../../../../interfaces";
import * as React from "react";
import { useEffect, useMemo } from "react";
import "./styles.scss";
import { HashTagsServices, KeywordServices } from "../../../../../services";
import { descriptors, VideoProjectComponentType } from "../../descriptors";
import TextareaAutosize from "react-textarea-autosize";
import { Form, Grid } from "semantic-ui-react";
import { useIntl } from "react-intl";
import { PredictionsComponent } from "../PredictionsComponent";
import { HashTagSet } from "../HashTagSet";

interface ProjectCaptionParams {
  coachId?: string;
  playerId?: number;
  role: "PLAYER" | "COACH";
  includePlayerHashtags: boolean;
  captionValue: string;
  disabled: boolean;
  onUpdateCaption: (caption: string) => void;
  onUpdateHashtags: (hashtags: string) => void;
  onUpdateWarnings: (warnings: string[]) => void;
  onUpdateErrors: (errors: string[]) => void;
}

type Props = ProjectCaptionParams;

export const ProjectCaption: React.FC<Props> = ({
  coachId,
  playerId,
  role,
  includePlayerHashtags,
  captionValue,
  disabled,
  onUpdateCaption,
  onUpdateHashtags,
  onUpdateWarnings,
  onUpdateErrors,
}) => {
  const { formatMessage } = useIntl();
  const [showAIModal, setShowAIModal] = React.useState<boolean>(false);
  const [caption, setCaption] = React.useState<string>(captionValue);
  const [defaultHashtags, setDefaultHashtags] = React.useState<HashTagElementResponse[]>([]);
  const [hashtags, setHashtags] = React.useState<string[]>(["videocatalyst"]);
  const [keywords, setKeywords] = React.useState<KeywordElement[]>([]);
  const MAX_HASHTAGS = 30;

  useEffect(() => {
    const getKeywords = async () => {
      if (role == "COACH" && coachId) return await KeywordServices.get(coachId);
      else if (role == "PLAYER" && playerId) return await KeywordServices.getAsPlayer(playerId);
      else return [];
    };

    getKeywords().then(setKeywords);
  }, [coachId, playerId, role]);

  const phraseList = useMemo(() => {
    return keywords.map(keyword => {
      return {
        content: keyword.content,
        regex: new RegExp("\\b" + keyword.content + "\\b", "i"),
        policy: keyword.policy,
      } as KeywordPhrase;
    });
  }, [keywords]);

  useEffect(() => {
    const getDefaultHashtags = async (): Promise<HashTagElementResponse[]> => {
      switch (role) {
        case "COACH":
          if (playerId) {
            return await HashTagsServices.get(playerId, coachId);
          } else if (coachId) {
            return await HashTagsServices.getCoachHashtags(coachId);
          }
          break;
        case "PLAYER":
          if (playerId) {
            return await HashTagsServices.get(playerId!!, coachId);
          }
          break;
      }
      return [];
    };

    getDefaultHashtags().then(setDefaultHashtags);
  }, [coachId, playerId, role, includePlayerHashtags]);

  useEffect(() => {
    setHashtags(() => {
      const tagsToExclude = (includePlayerHashtags ? [] : defaultHashtags.filter(t => !!t.playerId)).map(t =>
        t.content.toLowerCase()
      );
      const defaultTags = defaultHashtags.map(t => t.content);

      const newTags: string[] = [];
      [...hashtags, ...defaultTags].forEach(tag => {
        if (!tagsToExclude.includes(tag.toLowerCase()) && !newTags.some(t => t.toLowerCase() === tag.toLowerCase())) {
          newTags.push(tag);
        }
      });
      return newTags;
    });
  }, [defaultHashtags, includePlayerHashtags]);

  const buildMatchedWordsError = (content: string, label: string, policy: KeywordsType): string => {
    const matchedWords = phraseList
      .filter(phrase => {
        return phrase.policy === policy && phrase.regex.test(content);
      })
      .map(phrase => phrase.content)
      .join(", ");
    if (matchedWords.length > 0) {
      const policyLabel = policy === KeywordsType.Blocked ? "blocked" : "flagged";
      return `${label} contains ${policyLabel} words: ${matchedWords}`;
    } else {
      return "";
    }
  };

  const maxHashtagsError = useMemo(() => {
    return hashtags.length > MAX_HASHTAGS ? "You can only add a maximum of 30 hashtags" : "";
  }, [hashtags.length]);

  const validateContent = () => {
    const errors = [
      buildMatchedWordsError(caption, "Caption", KeywordsType.Blocked),
      buildMatchedWordsError(hashtags.join(" "), "Hashtags", KeywordsType.Blocked),
      maxHashtagsError,
    ].filter(v => v && v.length > 0);
    onUpdateErrors(errors);

    const warnings = [
      buildMatchedWordsError(caption, "Caption", KeywordsType.Flagged),
      buildMatchedWordsError(hashtags.join(" "), "Hashtags", KeywordsType.Flagged),
    ].filter(v => v && v.length > 0);
    if (warnings.length > 0) {
      warnings.push("Please review carefully before submitting.");
    }
    onUpdateWarnings(warnings);
  };

  useEffect(() => {
    onUpdateCaption(caption);
    validateContent();
  }, [caption]);

  useEffect(() => {
    onUpdateHashtags(hashtags.map(t => `#${t}`).join(" "));
    validateContent();
  }, [hashtags]);

  const hashtagSection = () => {
    return (
      <>
        <label>{formatMessage({ ...descriptors[VideoProjectComponentType.hashtags] })}</label>
        <Grid className={"marginBottom paddingTop"}>
          <HashTagSet
            title={"Hashtags..."}
            customTagStyle={`background: rgba(61, 174, 245, 0.2);`}
            defaultHashtagList={hashtags}
            onSuccess={setHashtags}
          />
        </Grid>
      </>
    );
  };

  const captionSection = () => {
    return (
      <Form.Field className={"captionSection"}>
        <div className={"captionHeader"}>
          <label>{formatMessage({ ...descriptors[VideoProjectComponentType.message] })}</label>
          <a className="projectAiButton" onClick={() => setShowAIModal(true)}>
            {formatMessage({ ...descriptors[VideoProjectComponentType.aiButton] })}
          </a>
        </div>
        <TextareaAutosize
          data-elm-id={"projectCaptionInput"}
          id="messageToPost"
          name="messageToPost"
          className="captionField"
          placeholder={formatMessage({ ...descriptors[VideoProjectComponentType.messagePlaceholder] })}
          rows={3}
          disabled={disabled}
          value={caption}
          onChange={data => {
            setCaption(data.currentTarget.value);
          }}
        />
      </Form.Field>
    );
  };

  return (
    <div className={"captionContainer"}>
      {captionSection()}
      {hashtagSection()}

      {showAIModal && (
        <PredictionsComponent
          opened={true}
          rejectHandler={() => {
            setShowAIModal(false);
          }}
          acceptHandler={(prediction, addedHashtags) => {
            setShowAIModal(false);
            setCaption(prediction);

            if (addedHashtags && addedHashtags.length > 0) {
              const newTags: string[] = [];
              if (hashtags.length > 0) {
                hashtags.forEach(tag => newTags.push(tag));
              }

              addedHashtags.forEach(tag => {
                if (newTags.indexOf(tag) === -1) {
                  newTags.push(tag);
                }
              });
            }
          }}
        />
      )}
    </div>
  );
};
