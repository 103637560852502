import * as React from "react";
import { Image, Card, Grid } from "semantic-ui-react";

import "./styles.scss";

interface Props {
  title: string;
  iconName: string;
  valueSymbol?: string;
  value?: string | number;
  description?: string | number;
  descType?: "positive" | "negative" | "";
  additionalValue?: string | number;
  additionalDesc?: string | number;

  clickable?: boolean;
  onClick: () => void;
}

const MiniCardFC: React.FC<Props> = ({
  iconName,
  title,
  value = "--",
  valueSymbol = "",
  description = "",
  descType = "",
  additionalValue,
  additionalDesc,
  clickable,
  onClick,
}) => {
  const simple = (): JSX.Element => {
    return (
      <Card className={clickable ? "miniCard" : "miniCard noClickeable"} onClick={onClick}>
        <Card.Content>
          <Card.Meta className={"statsTitle"}>
            <Image src={iconName} size="tiny" />
            {title}
          </Card.Meta>
          <Card.Description className={"statsInfo"} content={value} />
          {description && (
            <Card.Meta textAlign="right" className={descType}>
              {description}
            </Card.Meta>
          )}
        </Card.Content>
      </Card>
    );
  };

  const doubleCard = (): JSX.Element => {
    return (
      <Card className={"miniCard"}>
        <Card.Content>
          <Card.Meta className={"statsTitle"}>
            <Image src={iconName} size="tiny" />
            {title}
          </Card.Meta>
          <Card.Description className={"statsInfo"}>
            <Grid className="multipleValues" divided={"vertically"}>
              <Grid.Column floated="right" width={7}>
                {value}
              </Grid.Column>
              <Grid.Column width={1}>
                <div className={"verticalDivider"} />
              </Grid.Column>
              <Grid.Column className={"noPadding"} width={6}>
                {additionalValue}
              </Grid.Column>
            </Grid>
          </Card.Description>

          <Card.Meta>
            <Grid stretched className={"multipleValues"}>
              <Grid.Column className={"descLabel"} floated="left" width={7}>
                {description}
              </Grid.Column>
              <Grid.Column className={"descLabel"} floated="right" width={7}>
                {additionalDesc}
              </Grid.Column>
            </Grid>
          </Card.Meta>
        </Card.Content>
      </Card>
    );
  };

  return additionalValue ? doubleCard() : simple();
};

export const MiniCard = MiniCardFC;
