import * as React from "react";
import { useIntl } from "react-intl";
import {
  Checkbox,
  Dropdown,
  DropdownItemProps,
  Form,
  Header,
  Loader,
  Message,
} from "semantic-ui-react";
import { union, map } from "lodash";
import states from "states-us";

import { BasicFormProps, FormField } from "../../../interfaces";
import { CoachService } from "../../../services";

import { AddPlayerType, descriptors } from "./descriptors";
import { fields, segmentationFields } from "./formFields";

import "./styles.scss";
import { SocialGridWithPadding } from "../../../styling/baseStyle";
import useAdminCoachSettings from "../../AppSettingsTab/adminSettingsHook";

interface OwnProps extends BasicFormProps {
  coachId: string;
  disabled: boolean;
  formSubmitRef?: any;
  /**
   * Handle dropdowns changes
   */
  handleSetFieldValue: (field: string, value: any) => void;
}

type Props = OwnProps; 

/**
 * Add enterprise player internal form
 */

const AddEnterprisePlayerFC: React.FC<Props> = ({
  coachId,
  messages,
  values,
  isSubmitting = true,
  formSubmitRef,
  disabled,

  handleChange,
  handleSubmit,
  handleSetFieldValue,
}) => {
  const { formatMessage } = useIntl();
  const { displayAdminSettings } = useAdminCoachSettings(coachId);
  const stateOptions = [
    {
      key: "all",
      text: " Select All",
      value: "Select All",
    },
  ].concat(
    map(
      states.filter((state) => !state.territory),
      (state) => ({
        key: state.abbreviation,
        text: " " + state.abbreviation + " - " + state.name,
        value: state.abbreviation,
      })
    )
  );

  const [loading, setLoading] = React.useState<boolean>(false);

  const [branchesOptions, setBranchesOptions] = React.useState<
    DropdownItemProps[]
  >([]);
  const [regionOptions, setRegionsOptions] = React.useState<
    DropdownItemProps[]
  >([]);
  const [costCenterOptions, setCostCenterOptions] = React.useState<
    DropdownItemProps[]
  >([]);

  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      CoachService.branches(coachId),
      CoachService.regions(coachId),
      CoachService.costCenters(coachId),
    ])
      .then((responses) => {
        const branchResponse = responses[0];
        const bFilters: DropdownItemProps[] = map(branchResponse, (branch) => ({
          key: branch.name,
          text: branch.name,
          value: branch.name,
        }));
        setBranchesOptions(bFilters);

        const regionResponse = responses[1];
        const rFilters: DropdownItemProps[] = map(regionResponse, (region) => ({
          key: region.name,
          text: region.name,
          value: region.name,
        }));
        setRegionsOptions(rFilters);

        const costCenterResponse = responses[2];
        const cFilters: DropdownItemProps[] = map(
          costCenterResponse,
          (region) => ({
            key: region.name,
            text: region.name,
            value: region.name,
          })
        );
        setCostCenterOptions(cFilters);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [coachId]);

  const handleAdditionRegion = (e: any, data: any) => {
    const { value } = data;
    const newCat = value.trim();
    if (newCat !== "") {
      setRegionsOptions((olds) => [{ text: value, value }, ...olds]);
    }
  };
  const handleAdditionBranch = (e: any, data: any) => {
    const { value } = data;
    const newCat = value.trim();
    if (newCat !== "") {
      setBranchesOptions((olds) => [{ text: value, value }, ...olds]);
    }
  };

  const handleAdditionCostCenter = (e: any, data: any) => {
    const { value } = data;
    const newCat = value.trim();
    if (newCat !== "") {
      setCostCenterOptions((olds) => [{ text: value, value }, ...olds]);
    }
  };

  const segmentationDropDown = (
    field: FormField,
    index: number
  ): JSX.Element => {
    const multipleDropDown = field.id === "state";
    const props = {
      search: true,
      selection: true,
      fluid: true,
      id: field.id,
      multiple: multipleDropDown,
      disabled: isSubmitting || disabled,
      "data-elm-id": "addEnterprisePlayerDropdown" + field.id,
    };

    let options;
    let handler;
    switch (field.id) {
      case "region":
        options = regionOptions;
        handler = handleAdditionRegion;
        break;
      case "branch":
        options = branchesOptions;
        handler = handleAdditionBranch;
        break;
      case "costCenter":
        options = costCenterOptions;
        handler = handleAdditionCostCenter;
        break;
      default:
        break;
    }

    return multipleDropDown ? (
      <Dropdown
        {...props}
        placeholder={
          values[field.id].length === 0
            ? formatMessage({ ...descriptors[field.placeholder] })
            : values[field.id]
                .filter((state: string) => state !== "SELECT ALL")
                .join(", ")
        }
        className={
          values.state.length === 0
            ? "addPlayerDrowpdown"
            : values[field.id].length > 20
            ? "addPlayerMoreElementsDrowpdown withElements"
            : "addPlayerDrowpdown withElements"
        }
      >
        <Dropdown.Menu>
          {stateOptions.map((stateFilter, i) => {
            return (
              <Dropdown.Item key={"add_player_state_" + i}>
                <Checkbox
                  id={stateFilter.key}
                  checked={values.state?.includes(
                    `${stateFilter!.value!}`.toUpperCase()
                  )}
                  onChange={(a, data) => {
                    const selectedState = `${stateFilter!
                      .value!}`.toUpperCase();
                    let fregions: string[] = values.state;
                    if (data.checked) {
                      if (stateFilter.key === "all") {
                        fregions = stateOptions.map((state) => {
                          return `${state!.value!}`.toUpperCase();
                        });
                      } else {
                        fregions = union(fregions, [selectedState]);

                        if (fregions.length === stateOptions.length - 1) {
                          fregions = union(fregions, ["SELECT ALL"]);
                        }
                      }
                    } else {
                      if (stateFilter.key === "all") {
                        fregions = [];
                      } else {
                        fregions = fregions?.filter(
                          (r) => r !== selectedState && r !== "SELECT ALL"
                        );
                      }
                    }
                    handleSetFieldValue(field.id, fregions);
                  }}
                />
                {stateFilter.text}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    ) : (
      <Dropdown
        {...props}
        icon=""
        className={"addPlayerDrowpdown"}
        placeholder={
          loading ? "" : formatMessage({ ...descriptors[field.placeholder] })
        }
        allowAdditions
        loading={loading}
        value={values[field.id]}
        options={options}
        onAddItem={handler}
        onChange={(e, data) => {
          handleSetFieldValue(field.id, data.value);
        }}
      />
    );
  };

  const segmentationSection = (
    <div className="paddingTop">
      <SocialGridWithPadding.Row className="tinyPaddingLeft mediumPaddingBottom">
        <Header as="h4">
          {formatMessage({ ...descriptors[AddPlayerType.segmentationTitle] })}
        </Header>
      </SocialGridWithPadding.Row>

      <div>
        {segmentationFields
          .filter((e) => {
            return (
              displayAdminSettings.costCenter ||
              (displayAdminSettings.costCenter === false &&
                e.id !== "costCenter")
            );
          })
          .map((field, index) => {
            return (
              <Form.Group
                widths="equal"
                className={"inputsContainer"}
                key={`add_player_${field.id}_group`}
              >
                {segmentationDropDown(field, index)}
              </Form.Group>
            );
          })}
      </div>
    </div>
  );

  const content = (
    <React.Fragment>
      <SocialGridWithPadding.Row className="tinyPaddingLeft mediumPaddingBottom">
        <Header as="h4">
          {formatMessage({ ...descriptors[AddPlayerType.title] })}
        </Header>
      </SocialGridWithPadding.Row>

      {fields.map((field, index) => {
        return (
          <Form.Group
            widths="equal"
            className={"inputsContainer"}
            key={"add_player_" + field.id + "_" + index + "_group"}
          >
            <Form.Input
              key={field.id + index + "_l"}
              data-elm-id={"addEnterprisePlayerInput" + field.id}
              fluid
              id={field.id}
              type={field.type}
              name={field.name}
              placeholder={formatMessage({ ...descriptors[field.placeholder] })}
              onChange={handleChange}
              value={values[field.id]}
              disabled={isSubmitting || disabled}
            />
          </Form.Group>
        );
      })}

      {segmentationSection}
    </React.Fragment>
  );

  const errors = messages && messages.error && messages.error.length > 0;
  const warnings = messages && messages.warn && messages.warn.length > 0;
  const info = messages && messages.info && messages.info.length > 0;
  return (
    <SocialGridWithPadding>
      <SocialGridWithPadding.Row className={"enterprisePlayerRow"}>
        <Form
          ref={formSubmitRef}
          onSubmit={handleSubmit}
          size="large"
          error={errors}
          warning={warnings}
          className={"enterprisePlayerForm"}
        >
          {errors && (
            <Message error header={"Errors:"} list={messages!!.error!!} />
          )}
          {warnings && (
            <Message warning header={"Warnings: "} list={messages!!.warn!!} />
          )}
          {info && <Message header={"Info: "} list={messages!!.info!!} />}

          {isSubmitting ? <Loader active size="large" /> : content}
        </Form>
      </SocialGridWithPadding.Row>
    </SocialGridWithPadding>
  );
};

export const AddEnterprisePlayer = AddEnterprisePlayerFC;
