import * as React from "react";
import { Form, Segment, Grid, Loader, Message, Header } from "semantic-ui-react";
import "./styles.scss";

interface Props {
  /**
   * Title
   */
  title?: string;

  /**
   * Footer component
   */
  footer?: JSX.Element;
  /**
   * Component
   */
  content: JSX.Element;
  /**
   * Error messages list
   */
  messages?: { info?: string[]; error?: string[]; warn?: string[] };
  /**
   * Is form submitting
   */
  isSubmitting?: boolean;
  /**
   * Size of card
   */
  size: "small" | "big" | "xsmall";
}

const BasicCardFC: React.FC<Props> = ({ title, footer, content, messages, isSubmitting, size }) => {
  const errors = messages && messages.error && messages.error.length > 0;
  const warnings = messages && messages.warn && messages.warn.length > 0;

  return (
    <Grid columns={1} stretched container className={"basicGrid"}>
      {title && (
        <Header floated={"left"} as="h2">
          {title}
        </Header>
      )}
      <Grid.Column computer={16} largescreen={16} tablet={15} widescreen={16} mobile={16} className={size}>
        <Form size="large" error={errors} warning={warnings} className={"basicFormBox"}>
          <Segment padded={"very"} centered={"true"} className={"segmentBasicContainer"}>
            {isSubmitting && <Loader active size="large" />}

            {content}

            {messages && messages.error && messages.error.length > 0 && (
              <Message error header={"Errors:"} list={messages && messages.error} />
            )}
            {messages && messages.warn && messages.warn.length > 0 && (
              <Message warning header={"Warnings: "} list={messages && messages.warn} />
            )}
            {messages && messages.info && messages.info.length > 0 && (
              <Message header={"Info: "} list={messages.info} />
            )}
          </Segment>
        </Form>
      </Grid.Column>

      {footer}
    </Grid>
  );
};

export const BasicCard = BasicCardFC;
