import * as React from "react";
import { useIntl } from "react-intl";
import { Grid, Header, Divider, Loader, CardGroup } from "semantic-ui-react";

import { PlayerQuickAction } from "../../../../../interfaces";
import { PlayersServices } from "../../../../../services";

import { descriptors, DashboardType } from "../../descriptors";

import "./styles.scss";
import { QuickActionCard } from "./components/QuickActionCard";
import { URLS } from "../../../../../utils";

interface OwnProps {
  playerId: number;
}
type Props = OwnProps;

const QuickActionsFC: React.FC<Props> = ({ playerId }) => {
  const { formatMessage } = useIntl();

  const [actions, setActions] = React.useState<PlayerQuickAction[]>([]);

  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getStatistics = async () => {
      try {
        setLoading(true);
        PlayersServices.quickActions(playerId).then(actions => {
          // TODO: Remove filter when all actions are availables
          setActions(
            actions.filter(
              a =>
                a.actionTarget === "CUSTOMIZATIONS" ||
                a.actionTarget === "SOCIAL_NETWORKS" ||
                a.actionTarget === "PROMPT_LIBRARY" ||
                a.actionTarget === "SCRIPT_LIBRARY" ||
                a.actionTarget === "PROJECT_LIBRARY" ||
                a.actionTarget === "NEW_VIDEO_PROJECT"
            )
          );
        });
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    getStatistics();
  }, [playerId]);

  return (
    <Grid.Row columns={1} className={"statisticsContent"}>
      <Grid.Column>
        <Grid columns={1}>
          <Grid.Row columns={1}>
            <Grid.Column width={16}>
              {loading && <Loader active size="large" />}
              {actions && actions.length > 0 && (
                <Grid className={"statistics"}>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Header as="h2">
                        {formatMessage({ ...descriptors[DashboardType.quickActionsSectionTitle] })}
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                  <CardGroup>
                    {actions.map((action, index) => {
                      return <QuickActionCard key={index} action={action} />;
                    })}
                  </CardGroup>
                </Grid>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid.Row>
  );
};

export const QuickActions = QuickActionsFC;
