import * as React from "react";
import { useIntl } from "react-intl";
import { Form, Message, Loader, Grid, Icon, Popup, Radio } from "semantic-ui-react";
import { get } from "lodash";
import TextareaAutosize from "react-textarea-autosize";

import { UploadVideo } from "../../UploadVideo";
import { BasicFormProps, Category, Media, MediaType, PromptType } from "../../../interfaces";
import { ConfigContext } from "../../../contexts/appContexts";
import { AppContext } from "../../../providers";
import { Rules } from "../../../utils";

// import { joditConfig, disableJoditConfig } from "./components/joditConfig";
import { MultiImageUploader } from "../../MultiImageUploader";
import { ConfigContextProvider } from "../../../contexts/appContexts";
import defaultConfigProperties from "../../../defaultConfigProperties";

import { PredictionsComponent } from "./components/PredictionsComponent";
import { SegmentedControl } from "./components/SegmentedController";
import { HashTagSet } from "./components/HashTagSet";

import { PromptComponentType, descriptors } from "./descriptors";
import { AddCategoryComponent } from "../../AddCategoryComponent";
import "./styles.scss";
import "../../../styling/datepicker.scss";

// const JoditEditorClientSideOnlyLazy = React.lazy(() => import("jodit-react"));

interface OwnProps extends BasicFormProps {
  /**
   * Handle attached file is uploaded
   */
  handleMediaUpdated: (newMedia: Media[]) => void;

  /**
   * Handle video file is uploaded
   */
  handleMessageVideoUploaded: (videoUrl: string, extension: string) => void;
  /**
   * Handle video file preview
   */
  handleMessageVideoPreview: (fileUrl: string, extension: string, type: MediaType) => void;

  handleAttachedFileUploadStarted: () => void;
  handleAttachedFileUploadFinished: () => void;

  /**
   * Handle datePickers changes
   */
  handleSetFieldValue?: (field: string, value: any) => void;
  /**
   * Handle cancel action
   */
  handleCancel?: () => void;
  /**
   * Handle upload
   */
  handleUploadVideo?: () => void;

  modeEditable: boolean;
  /**
   * To Indicate if the form is disabled
   */
  disabled: boolean;
  /**
   * To show or hide the Player Custom Hashtags Toggle
   */
  showHashtagToggle: boolean;
}

type Props = OwnProps;

/**
 * Represents a Prompt content.
 */
const PromptClass: React.FC<Props> = ({
  handleChange,
  handleSetFieldValue,
  handleMessageVideoUploaded,
  handleMessageVideoPreview,
  handleAttachedFileUploadStarted,
  handleAttachedFileUploadFinished,
  handleMediaUpdated,
  messages,
  isSubmitting = false,
  values,
  disabled,
  showHashtagToggle,
}) => {
  const { firebase } = React.useContext(ConfigContext);
  const { userContext } = React.useContext(AppContext);
  const coachId = get(userContext, "coach.id", "");
  const { formatMessage } = useIntl();
  const errors = messages && messages.error && messages.error.length > 0;
  const [categories, setCategories] = React.useState<Category[]>(values.categories);
  const [hashtags, setHashtags] = React.useState<string>(values.hashtags);

  const [showAIModal, setShowAIModal] = React.useState<boolean>(false);
  const [hashtagsListError, setHashtagsListError] = React.useState<string[] | undefined>();

  React.useEffect(() => {
    handleSetFieldValue!("categories", categories);
  }, [categories]);

  React.useEffect(() => {
    return () => {
      window.removeEventListener("loadedmetadata", () => {
        // Nothing
      });
    };
  }, []);

  const reelsSection = (): JSX.Element => {
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={9} className={"postAsReelColumn floatedRight"}>
              <SegmentedControl
                name="promptType"
                callback={(val: string) => {
                  handleSetFieldValue!("type", val);
                  handleMediaUpdated([]);
                }}
                defaultIndex={values.type === PromptType.REELS ? 1 : 0}
                controlRef={React.useRef()}
                segments={[
                  {
                    label: `${formatMessage({ ...descriptors[PromptComponentType.feedRadioLabel] })}`,
                    value: PromptType.FEED,
                    ref: React.useRef(),
                  },
                  {
                    label: `${formatMessage({ ...descriptors[PromptComponentType.reelRadioLabel] })}`,
                    value: PromptType.REELS,
                    ref: React.useRef(),
                  },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  };

  const promptSettingsSection = (): JSX.Element => {
    const { allowShortLink, allowHashtags, allowComplianceDisclaimer } = values;

    const promptSettings: {
      label: string;
      checked: boolean;
      id: string;
    }[] = [];

    if (showHashtagToggle) {
      promptSettings.push({
        label: "Allow player custom hashtags",
        checked: allowHashtags,
        id: "allowHashtags",
      });
    }

    promptSettings.push({
      label: "Include player short link",
      checked: allowShortLink,
      id: "allowShortLink",
    });

    promptSettings.push({
      label: "Include Compliance Disclaimer",
      checked: allowComplianceDisclaimer,
      id: "allowComplianceDisclaimer",
    });

    return (
      <React.Fragment>
        <Grid padded>
          <Grid.Column floated="right" width={10}>
            <Form.Group grouped key={"promptPermissions"} className={"floatedRight"}>
              {promptSettings.map(appSetting => {
                return (
                  <div key={"promptPermissions" + appSetting.id}>
                    <Form.Group inline key={appSetting.id} className={"floatedRight"}>
                      <label>{appSetting.label}</label>
                      <Radio
                        toggle
                        className={"settingsLevelLabel"}
                        onChange={(e: any, data: any) => {
                          handleSetFieldValue!(data.name, data.checked);
                        }}
                        name={appSetting.id}
                        checked={appSetting.checked}
                      />
                    </Form.Group>
                  </div>
                );
              })}
            </Form.Group>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  };

  const basicMessageTextField = (
    <Form.Field>
      <div>
        <label>{formatMessage({ ...descriptors[PromptComponentType.promptMessage] })}</label>
        <a className="aiButton" onClick={() => setShowAIModal(true)}>
          {formatMessage({ ...descriptors[PromptComponentType.aiButton] })}
        </a>
      </div>
      <TextareaAutosize
        data-elm-id="promptInputMessage"
        id="message"
        className="captionField"
        name="message"
        placeholder={formatMessage({ ...descriptors[PromptComponentType.messagePlaceholder] })}
        rows={3}
        disabled={disabled}
        value={values.message}
        onChange={data => {
          handleSetFieldValue!("withMessage", data.currentTarget.value + hashtags);
          handleSetFieldValue!("message", data.currentTarget.value);
          handleSetFieldValue!("richMessage", data.currentTarget.value);
        }}
      />
    </Form.Field>
  );

  const hashtagTextField = (
    <>
      <label>{formatMessage({ ...descriptors[PromptComponentType.promptHashtags] })}</label>
      <Popup
        className="customPopup"
        content={formatMessage({ ...descriptors[PromptComponentType.hashtagsInfo] })}
        trigger={<Icon name="info circle" className={"infoIcon"} link />}
      />

      {hashtagsListError && hashtagsListError.length > 0 && (
        <Grid className={"paddingTop leftPadding"}>
          {hashtagsListError.map(error => (
            <Grid.Row className={"noPadding"} columns={1}>
              <div className="labelWithError">{error}</div>
            </Grid.Row>
          ))}
        </Grid>
      )}
      <Grid className={"marginBottom " + (hashtagsListError && hashtagsListError?.length > 0 ? "" : "paddingTop")}>
        <HashTagSet
          title={"Hashtags..."}
          coachId={coachId}
          onError={setHashtagsListError}
          customTagStyle={`background: rgba(61, 174, 245, 0.2);`}
          defaultHashtagList={hashtags}
          onSuccess={newTags => {
            const hashtagsString = newTags.reduce(
              (accumulator, hashtag) => accumulator + "#" + hashtag.displayValue + " ",
              ""
            );
            if (newTags.length <= 30) {
              handleSetFieldValue!("hashtags", hashtagsString);
              handleSetFieldValue!("withMessage", values.message + hashtagsString);
            }
          }}
        />
      </Grid>
    </>
  );

  return (
    <>
      <Form size="large" error={errors} className={"promptComponentContainer"}>
        {isSubmitting && <Loader active size="large" />}

        <div key={"promptFormDiv"} className={disabled ? "formDiv disabledForm" : "formDiv"}>
          {messages && messages.error && messages.error.length > 0 && (
            <Message error list={messages && messages.error} />
          )}

          {reelsSection()}

          <AddCategoryComponent selectedCategories={categories} handleOnChange={setCategories} />

          <Form.Input
            key={"groupTitleInput"}
            label={formatMessage({ ...descriptors[PromptComponentType.promptTitle] })}
            data-elm-id={"promptInputTitle"}
            fluid
            id={"title"}
            type={"text"}
            name={"title"}
            placeholder={formatMessage({ ...descriptors[PromptComponentType.titlePlaceholder] })}
            onChange={(e, data) => {
              if (e.target.value.length < Rules.MAX_TITLE) {
                handleChange!(e, data);
              }
            }}
            value={values.title}
            disabled={disabled}
          />
          {basicMessageTextField}
          {hashtagTextField}

          {promptSettingsSection()}
          <br />

          {/* "Image/Video for Post" section */}
          <div className={"attachPromptSection"}>
            <label>{formatMessage({ ...descriptors[PromptComponentType.attachDesc] })}</label>
            <Popup
              className="customPopup"
              content={formatMessage({
                ...descriptors[
                  values.type === PromptType.FEED ? PromptComponentType.attachInfo : PromptComponentType.reelsAttachInfo
                ],
              })}
              trigger={<Icon name="info circle" className={"infoIcon"} link />}
            />
            <ConfigContextProvider value={defaultConfigProperties}>
              <MultiImageUploader
                coachId={coachId}
                firebase={firebase}
                postAsReel={values.type === PromptType.REELS}
                handleMediaUpdated={handleMediaUpdated}
                handleAttachedFileUploadFinished={handleAttachedFileUploadFinished}
                handleAttachedFileUploadStarted={handleAttachedFileUploadStarted}
                media={values.post?.media || []}
              />
            </ConfigContextProvider>
          </div>

          {/* "Instructional Video" section */}

          <Grid className={"buttonContainer"}>
            <Grid.Column floated="left">
              <UploadVideo
                disabled={disabled}
                videoInfo={formatMessage({ ...descriptors[PromptComponentType.videoMessageInfo] })}
                placeholder={formatMessage({ ...descriptors[PromptComponentType.uploadVideoPlaceholder] })}
                className={"primary"}
                label={formatMessage({ ...descriptors[PromptComponentType.videoMessage] })}
                fileName={`promptVideo${new Date().toUTCString}`}
                buttonName={formatMessage({ ...descriptors[PromptComponentType.browseBtn] })}
                handleSuccess={(url, extension) => {
                  handleMessageVideoUploaded(url, extension);
                }}
                onChangeImage={file => {
                  const mediaType = file.type.includes("video") ? "VIDEO" : "IMAGE";
                  handleMessageVideoPreview(URL.createObjectURL(file), file.type, mediaType);
                }}
                handleError={() => {
                  if (handleMessageVideoPreview) {
                    handleMessageVideoPreview("", "", "VIDEO");
                  }
                }}
              />
            </Grid.Column>
          </Grid>
        </div>
      </Form>

      {showAIModal && (
        <PredictionsComponent
          opened={true}
          rejectHandler={() => {
            setShowAIModal(false);
          }}
          acceptHandler={(prediction, addedHashtags) => {
            setShowAIModal(false);
            handleSetFieldValue!("message", prediction);
            handleSetFieldValue!("richMessage", prediction);

            if (addedHashtags && addedHashtags.length > 0) {
              const newTags: string[] = [];
              if (hashtags.length > 0) {
                hashtags.split(" ").forEach(tag => newTags.push(tag));
              }

              addedHashtags.forEach(tag => {
                if (newTags.indexOf(tag) === -1) {
                  newTags.push(tag);
                }
              });
              setHashtags(newTags.join(" "));
            }
          }}
        />
      )}
    </>
  );
};

export const PromptComponent = PromptClass;
