export function modalReducer(state: any, action: any) {
  switch (action.type) {
    case "close":
      return { open: false };
    case "open":
      return { open: true, status: action.status };
    default:
      throw new Error("Unsupported action...");
  }
}
