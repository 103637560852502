import * as React from "react";
import "./styles.scss";
import { SocialNetworksName } from "../../interfaces";
import { Button, Grid, Icon, Image, Loader } from "semantic-ui-react";
import { Png } from "../../assets";
import { TiktokPreferences } from "../TiktokPreferences";

interface Props {
  /**
   * Social network title
   */
  title?: string;
  /**
   * User account name (username)
   */
  account: string;

  /**
   * Defines how the button will look
   */
  inProfile?: boolean;
  /**
   * Defines how the button will look
   */
  socialNetwork?: SocialNetworksName;
  /**
   * Connect handler
   */
  handler?: (socialNetwork: SocialNetworksName) => void;
  /**
   * Disconnect handler
   */
  disconnectHandler?: (socialNetwork: SocialNetworksName) => void;

  loading?: boolean;
  connected?: boolean;
  displayPreferences?: boolean;
}

const SocialNetworkButton: React.FC<Props> = ({
  title = "Connect",
  connected = false,
  account,
  handler,
  disconnectHandler,
  inProfile,
  socialNetwork,
  loading = false,
  displayPreferences = false
}) => {
  const [showTiktokModal, setShowTiktokModal] = React.useState<boolean>(false);
  const socialIcons = {
    "{facebook}": Png.FBLogo,
    "{twitter}": Png.TWLogo,
    "{linkedIn}": Png.LIMedLogo,
    "{instagram}": Png.IGLogo,
    "{tiktok}": Png.TTLogo,
  };


  React.useEffect(() => {
    if(displayPreferences) {
      setShowTiktokModal(displayPreferences)
    }
  }, [displayPreferences])
  const onClickHandler = () => {
    if (handler && !connected) {
      handler!(socialNetwork!);
    }
  };

  const onClickDisconnectHandler = () => {
    if (disconnectHandler && connected) {
      disconnectHandler!(socialNetwork!);
    }
  };

  const tiktokConnected = socialNetwork && socialNetwork === "tiktok" && connected;
  return (
    <div>
      {!inProfile && (
        <div
          onClick={onClickHandler}
          className={socialNetwork + " new-profile-social-button" + (connected ? " selected" : " bordered")}>
          <Grid className={"profile-social-button-content"}>
            <Grid.Row>
              <Grid.Column width={8}>
                <Image
                  className={socialNetwork === "tiktok" ? "social-icon with-border-radius" : "social-icon"}
                  src={socialIcons["{" + socialNetwork + "}"]}
                  size="small"
                />
              </Grid.Column>
              <Grid.Column
                width={8}
                className={tiktokConnected ? "social-network-name-col-right" : "social-network-name-col"}>
                {loading && <Loader active size="small" />}

                {tiktokConnected ? (
                  <Button className={"tiktok-cog"} onClick={() => setShowTiktokModal(true)}>
                    <Icon size={"small"} name="cog" />
                  </Button>
                ) : (
                  <i className={"social-network-name" + (connected ? " selected" : "")}>
                    {connected ? "Connected" : loading ? "Connecting" : "Connect"}
                  </i>
                )}
              </Grid.Column>
            </Grid.Row>
            <br />
            {connected && (
              <Grid.Row centered className="disconnect-row">
                <div
                  className="profile-social-network-name profile-social-network-name-disconnect"
                  onClick={onClickDisconnectHandler}>
                  Disconnect
                </div>
              </Grid.Row>
            )}
          </Grid>
        </div>
      )}
      {inProfile && (
        <div
          onClick={onClickHandler}
          className={socialNetwork + " profile-social-button" + (account !== "" ? " selected" : "")}>
          <div className={"profile-social-button-content"}>
            {loading ? <Loader active size="small" /> : <span className="profile-social-network-name">{title}</span>}
          </div>
        </div>
      )}

      {showTiktokModal && (
        <TiktokPreferences
          opened={true}
          rejectHandler={() => {
            setShowTiktokModal(false);
          }}
          acceptHandler={() => {
            setShowTiktokModal(false);
          }}
        />
      )}
    </div>
  );
};

export default SocialNetworkButton;
