import * as React from "react";
import styled from "styled-components";
import { Grid, Icon } from "semantic-ui-react";
import { createVideoThumbnailBlob } from "../../utils/vimeo";
import { Media } from "../../interfaces";

import "./styles.scss";

const ThumbnailContainer = styled.div<{ uri: string }>`
  position: relative;
  height: 240px;
  width: 100%;
  border-radius: 10px;
  margin: 5px;
  background: url("${(props: any) => props.uri}") no-repeat center center;
  background-size: contain;
`;
const ThumbnailCarouselDots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 100%;
  position: absolute;
  bottom: -20px;
  left: 0;
`;
const Dot = styled.div<{ active: any }>`
  width: 5px;
  height: 5px;
  margin: 3px;
  border-radius: 5px;
  background-color: ${(props: any) => (props.active ? "#3DAEF7" : "rgba(0,0,0,0.15)")};
`;
interface ThumbnailCarouselProps {
  media: Media[];
}

const ThumbnailCarouselFC: React.FC<ThumbnailCarouselProps> = ({ media }: ThumbnailCarouselProps) => {
  const [activeSlide, setActiveSlide] = React.useState<number>(0);
  const [currentThumbnail, setCurrentThumbnail] = React.useState<string>("");

  const advanceCarousel = (dir: number): void => {
    const currentSlide = activeSlide;
    const numSlides = media.length || 1;
    const nextPosition = (currentSlide + numSlides + dir) % numSlides;

    const currentMedia = media && media[nextPosition];
    if (currentMedia && currentMedia.uri) {
      const urlPromise =
        currentMedia.category === "IMAGE"
          ? Promise.resolve(currentMedia.uri)
          : createVideoThumbnailBlob(currentMedia.downloadLink!);
      urlPromise.then(url => {
        setCurrentThumbnail(url);
      });
    }

    setActiveSlide((currentSlide + numSlides + dir) % numSlides);
  };

  // reset the state if the current activeSlide gets deleted
  if (activeSlide >= (media.length || 1)) {
    advanceCarousel(0);
  }

  React.useEffect(() => {
    const currentMedia = media && media[activeSlide];

    if (currentMedia && currentMedia.uri) {
      const urlPromise =
        currentMedia.category === "IMAGE"
          ? Promise.resolve(currentMedia.uri)
          : createVideoThumbnailBlob(currentMedia.downloadLink!);
      urlPromise.then(url => {
        setCurrentThumbnail(url);
      });
    }
  }, [media[activeSlide]]);

  return (
    <>
      {(!media || media.length === 0) && (
        <Grid.Row columns={1} className={"attachSection"}>
          <Grid.Column>
            <Icon name="rocket" size={"large"} />
          </Grid.Column>
        </Grid.Row>
      )}
      {media && media.length > 0 && (
        <ThumbnailContainer className="thumbnail-carousel" uri={currentThumbnail}>
          <div className="right-arrow" onClick={() => advanceCarousel(1)}>
            <Icon name="angle right" />
          </div>
          <div className="left-arrow" onClick={() => advanceCarousel(-1)}>
            <Icon name="angle left" />
          </div>
          <ThumbnailCarouselDots>
            {media.map((item, i) => (
              <Dot active={i === activeSlide ? 1 : 0} key={"dot_thumbnail_" + i + item.id} />
            ))}
          </ThumbnailCarouselDots>
        </ThumbnailContainer>
      )}
    </>
  );
};

export const ThumbnailCarousel = ThumbnailCarouselFC;
