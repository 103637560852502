import { defineMessages } from "react-intl";

export enum NotFoundPageType {
  title = "NotFoundPageType.title",
  subtitle = "NotFoundPageType.subtitle",
}

export const en = {
  [NotFoundPageType.title]: "Oops! Somethig went wrong… ",
  [NotFoundPageType.subtitle]:
    "It looks like the page you’re looking for is missing or does not exist. Please contact your coach.",
};

export const descriptors = defineMessages({
  [NotFoundPageType.title]: {
    id: NotFoundPageType.title,
    defaultMessage: en[NotFoundPageType.title],
    description: "title",
  },
  [NotFoundPageType.subtitle]: {
    id: NotFoundPageType.subtitle,
    defaultMessage: en[NotFoundPageType.subtitle],
    description: "Subtitle",
  },
});
