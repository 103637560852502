export function permissionsByCoachType(isBasicCoach?: boolean): { [key: string]: string } {
  return isBasicCoach
    ? {
        marketing: "NONE",
        pricePlan: "NONE",
        library: "NONE",
        campaigns: "NONE",
        compliance: "NONE",
        players: "NONE",
        admin: "NONE",
        alerts: "NONE",
        digestEmail: "NONE",
        settings: "NONE",
      }
    : {
        library: "NONE",
        campaigns: "NONE",
        compliance: "NONE",
        players: "NONE",
        admin: "NONE",
        alerts: "NONE",
        digestEmail: "NONE",
        settings: "NONE",
      };
}
