import { defineMessages } from "react-intl";

export enum PreviewPromptType {
  title = "PreviewPromptType.title",
  postBtn = "PreviewPromptType.postBtn",
  skipBtn = "PreviewPromptType.skipBtn",
}

export const en = {
  [PreviewPromptType.title]: "Example Post",
  [PreviewPromptType.postBtn]: "Post This Now",
  [PreviewPromptType.skipBtn]: "Skip, I'll do this later",
};

export const descriptors = defineMessages({
  [PreviewPromptType.postBtn]: {
    id: PreviewPromptType.postBtn,
    defaultMessage: en[PreviewPromptType.postBtn],
    description: "-",
  },
  [PreviewPromptType.title]: {
    id: PreviewPromptType.title,
    defaultMessage: en[PreviewPromptType.title],
    description: "-",
  },
  [PreviewPromptType.skipBtn]: {
    id: PreviewPromptType.skipBtn,
    defaultMessage: en[PreviewPromptType.skipBtn],
    description: "-",
  },
});
