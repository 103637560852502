import * as React from "react";
import { Modal, Button, Header, Table, TableBody } from "semantic-ui-react";

import { PlayerPost, socialNetworkNames } from "../../../../../../../interfaces";
import { PostServices } from "../../../../../../../services";

import { PostErrorCell } from "./components/PostErrorCell";
import { modalReducer } from "./modalReducer";

import "./styles.scss";
import { ThumbnailPreview } from "./styled";

interface OwnProps {
  opened: boolean;
  selectedPost: PlayerPost;
  thumbnail: string;

  closeHandler: () => void;
  repostHandler: (selectedPost: PlayerPost) => void;
}

type Props = OwnProps;

/**
 * PostFailedModal Components
 */
const PostFailedModalFC: React.FC<Props> = ({ opened, selectedPost, thumbnail, repostHandler, closeHandler }) => {
  const [state, dispatch] = React.useReducer(modalReducer, { open: opened, loading: false });

  const dismissAlert = (): void => {
    dispatch({ type: "loading" });
    PostServices.dismissPost(selectedPost.postId)
      .then(close)
      .catch(e => {
        alert(e.message);
      })
      .finally(() => {
        dispatch({ type: "loaded" });
      });
  };

  const repost = (): void => {
    repostHandler(selectedPost);
  };

  const decorated = selectedPost.socialNetworks.map(sn => {
    return { ...sn, createdDate: selectedPost.createdDate };
  });

  const failed = selectedPost.socialNetworks.filter(sn => sn.status === "FAILED");

  const close = () => {
    dispatch({ type: "close" });
    closeHandler();
  };

  return (
    <Modal closeIcon size={"small"} open={state.open} onClose={close} key={selectedPost.postId}>
      <Modal.Header>
        <Header.Content>
          <label className="postFailedTitle">Failed Post</label>
        </Header.Content>
      </Modal.Header>

      <Modal.Content>
        <div className="postFailedMessageContainer">
          <p className="postFailedMessage">{selectedPost.message}</p>

          <ThumbnailPreview thumbnailurl={thumbnail} />
        </div>

        <div className="postFailedStatusContainer">
          <Header as={"h5"}>Post Status</Header>
          <Table striped>
            <TableBody>
              {decorated.map((item, index) => (
                <PostErrorCell socialNetwork={item} key={`${index}_${item.code}_${selectedPost.postId}`} />
              ))}
            </TableBody>
          </Table>
        </div>
      </Modal.Content>

      <Modal.Actions className="postFailedActions">
        <Button data-elm-id={"dismissBtn"} secondary={true} disabled={state.loading} onClick={dismissAlert}>
          Dismiss Alert
        </Button>

        <Button data-elm-id={"rePostBtn"} primary disabled={state.loading} onClick={repost}>
          {failed.length > 1
            ? "Re-Post to Failed Networks"
            : `Re-Post to ${socialNetworkNames["_" + failed[0].socialNetworkId!!]}`}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const PostFailedModal = PostFailedModalFC;
