import * as React from "react";
import { Dropdown, DropdownItemProps, Checkbox, Menu } from "semantic-ui-react";
import { union } from "lodash";

import "./styles.scss";

interface Props {
  /**
   * List of options
   */
  options: DropdownItemProps[];
  /**
   * Default selected options
   */
  defaultSelectedOptions?: any[];
  /**
   * Indicate if is multiple
   */
  multiple?: boolean;
  /**
   * Input content
   */
  placeholder?: string;
  /**
   * Key id
   */
  dropDownKey?: string;
  /**
   * Custom className
   */
  customClassName?: string;
  /**
   * Handle on change action
   */
  onChange: (result: any[]) => void;
}

const CheckDropDownFC: React.FC<Props> = ({
  options,
  onChange,
  defaultSelectedOptions = [],
  multiple = true,
  placeholder = "Options...",
  dropDownKey = "dropDownOption",
  customClassName = "searchInput",
}) => {
  const [selecteableOptions, setSelecteableOptions] =
    React.useState<DropdownItemProps[]>(options);
  const [selectedOptions, setSelectedOptions] = React.useState<any | undefined>(
    defaultSelectedOptions
  );

  const dropDownProps = {
    className: customClassName,
    multiple,
    placeholder,
  };

  React.useEffect(() => {
    setSelecteableOptions(options);
  }, [options]);

  React.useEffect(() => {
    setSelectedOptions(defaultSelectedOptions);
  }, [defaultSelectedOptions]);

  React.useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions]);

  return (
    <Menu.Item className={"dropDownMenu"}>
      <Dropdown {...dropDownProps} search>
        <Dropdown.Menu>
          {selecteableOptions.map((option, index) => {
            return (
              <Dropdown.Item key={dropDownKey + index}>
                <Checkbox
                  id={option.key}
                  checked={selectedOptions?.includes(`${option!.value!}`)}
                  onChange={(a, data) => {
                    setSelectedOptions((filters: any) => {
                      if (data.checked) {
                        return union(filters, [`${option!.value!}`]);
                      }
                      return filters?.filter(
                        (r: any) => r !== `${option!.value!}`
                      );
                    });
                  }}
                />
                <p className="cheackBoxItem">{option.text}</p>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>
  );
};

export const CheckDropDown = React.memo(CheckDropDownFC);
