import { EligibilityCriteria, Media, Prompt, PromptType } from "../../../interfaces";

export function validateSegmentation(isSegmented: boolean, eligibilityCriteria?: EligibilityCriteria[]): boolean {
  const segmenationOK =
    !isSegmented ||
    Boolean(eligibilityCriteria && eligibilityCriteria.length > 0 && eligibilityCriteria![0].values!.length > 0);
  return segmenationOK;
}

export function validateAspectRatio(prompt?: Prompt): boolean {
  if (!prompt) {
    return true;
  }
  // const invalidAspectRatio = prompt?.post
  //   ? prompt?.post!.media.filter(mediaElement => {
  //       return !mediaElement.validAspectRatio;
  //     }).length
  //   : 0;

  const invalidAspectRatio = prompt?.post
    ? prompt?.post!.media.some(mediaElement => {
        return !mediaElement.validAspectRatio;
      })
    : false;

  return !invalidAspectRatio;
}

export function validateMediasRequiremenstByPrompt(prompt?: Prompt): boolean {
  if (!prompt) {
    return true;
  }
  // const invalidAspectRatio = prompt?.post
  //   ? prompt?.post!.media.filter(mediaElement => {
  //       return mediaWithErrors(mediaElement);
  //     }).length
  //   : 0;
  const invalidMedia = prompt?.post
    ? prompt?.post!.media.some(mediaElement => {
        return mediaWithErrors(mediaElement);
      })
    : false;

  return !invalidMedia;
}

export function mediaWithErrors(mediaElement: Media): boolean {
  const withRequirementErrors = mediaElement.errors ? Object.values(mediaElement.errors).some(error => error) : false;
  return (!mediaElement.validAspectRatio && mediaElement.validAspectRatio !== undefined) || withRequirementErrors;
}

export function validateFeedPromptWithMultimedia(prompt?: Prompt): boolean {
  if (!prompt) {
    return true;
  }
  const promptFeed = prompt!.type ? prompt!.type === PromptType.FEED : true;
  const multipleMedias = prompt?.post && prompt?.post!.media ? prompt?.post!.media!.length > 1 : false;
  return promptFeed && multipleMedias;
}
