import {MutableRefObject, useEffect, useRef} from "react";
import {Timeout} from "react-number-format/types/types";

const useDebounce = (callback: () => void, delay: number) => {
  const timeoutRef: MutableRefObject<Timeout | undefined> = useRef(undefined);

  useEffect(() => {
    // Cleanup the previous timeout on re-render
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      callback();
    }, delay);
  };
};

export { useDebounce }
