import { defineMessages } from "react-intl";

export enum SocialNetworksProfile {
  title = "SocialNetworksProfile.title",
  confirmDeleteMessage = "SocialNetworksProfile.tac",
}

export const en = {
  [SocialNetworksProfile.title]: "My Social Accounts",
  [SocialNetworksProfile.confirmDeleteMessage]: "Are you sure you want to delete this social network?",
};

export const descriptors = defineMessages({
  [SocialNetworksProfile.title]: {
    id: SocialNetworksProfile.title,
    defaultMessage: en[SocialNetworksProfile.title],
    description: "-",
  },

  [SocialNetworksProfile.confirmDeleteMessage]: {
    id: SocialNetworksProfile.confirmDeleteMessage,
    defaultMessage: en[SocialNetworksProfile.confirmDeleteMessage],
    description: "-",
  },
});
