import { useState, useEffect } from "react";
import { HashTagsServices } from "../../../../../../../services";
import { PlayerPost, Prompt } from "../../../../../../../interfaces";
import { AppUserContext } from "../../../../../../../providers";

// assemble a list of default hashtags to add to the caption.
// Only add hashtags that are not already included in the caption passed to the component
// - so that hashtags aren’t added twice automatically.
// add the coach’s default hashtags
// if the coach allows player hashtags, add the player’s hashtags
// when the coach has the setting enabled to add the compliance disclaimer by default,
// or if the promptId’s prompt has the compliance disclaimer flag enabled,
//  add the player’s compliance disclaimer (unless it’s already been added in the caption passed to the component)
export const useMessageToRepost = (post: PlayerPost, userContext: AppUserContext, prompt?: Prompt) => {
  const [messageToPost, setMessageToPost] = useState<string | undefined>(undefined);
  const playerId = userContext.player?.id;

  useEffect(() => {
    if (playerId) {
      HashTagsServices.get(playerId).then(response => {
        const hashtags = response.map(hashtag => hashtag.content);
        let message = post.message;

        const hashtagsToInclude = hashtags?.filter(hashtag => !message.includes(hashtag));
        if (
          hashtagsToInclude &&
          hashtagsToInclude.length > 0 &&
          userContext?.player!!.addHashTagAutomatically &&
          (prompt?.allowHashtags !== false || !post.promptId)
        ) {
          message = message + `\n#${hashtagsToInclude.join(" #")}`;
        }
        if (
          userContext?.profile?.complianceDisclaimer &&
          (prompt?.allowComplianceDisclaimer !== false || !post.promptId) &&
          !message.includes(userContext?.profile?.complianceDisclaimer)
        ) {
          message = message + `\n${userContext?.profile?.complianceDisclaimer}`;
        }

        setMessageToPost(message);
      });
    }
  }, [playerId, prompt]);

  return { messageToPost };
};
