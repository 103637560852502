import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { AppContext } from "../../providers";
import { Grid, Button, Icon } from "semantic-ui-react";

import { VideoProjectCard } from "../Cells";
import * as DTO from "../../interfaces";
import { URLS, coachWithWritePermission } from "../../utils";

import "./styles.scss";

interface OwnProps {
  name: string;
  videoProjectList: DTO.BaseVideoProject[];
  player?: DTO.PlayerResumeResponse;

  pageable?: boolean;
  totalElements?: number;
}

type Props = OwnProps & RouteComponentProps;

const PlayerVideoProjectSectionFC: React.FC<Props> = ({
  player,
  videoProjectList,
  name,
  totalElements = 0,
  history,
}) => {
  const [display, setDisplay] = React.useState<boolean>(true);
  const { userContext } = React.useContext(AppContext);
  const withWritePermission = coachWithWritePermission(DTO.WebComponents.VIDEO_CATALYST, userContext);

  const actionTitle = (): string => {
    if (!display) {
      return "Collapsed (" + totalElements + ")";
    }
    return `Show all (${totalElements})`;
  };

  return (
    <Grid columns={3} data-elm-id="campaignListElement" className={"campaignsCategoryGrid"}>
      <Grid.Row columns={3} className={display ? "" : "collapsedSection"}>
        <Grid.Column width={16} floated="left" className={"subSectionTitle"}>
          <label>{name}</label>
        </Grid.Column>
        <Grid.Column width={4} floated="right" className={"noBorder"}>
          <Button
            data-elm-id={`videoProjectSearchListSectionDisplayBtn`}
            basic
            content={actionTitle()}
            icon={!display ? "triangle right" : "triangle down"}
            className={"noBorder"}
            labelPosition={"right"}
            onClick={() => setDisplay(!display)}
          />
        </Grid.Column>
      </Grid.Row>

      {display && withWritePermission && (
        <Button
          className={"addButton"}
          data-elm-id={`videoProjectSearchListSectionAddBtn`}
          onClick={() => {
            history.push(URLS.coach.newVideoProject + "?playerId=" + player?.playerId);
          }}>
          <Icon circular name="add" /> Add new
        </Button>
      )}

      {display && (
        <div key={name} className={"ui container middlePage"}>
          {videoProjectList.map(vp => {
            return <VideoProjectCard key={"video_project_card_" + vp.videoProjectId} videoProject={vp} />;
          })}
        </div>
      )}
    </Grid>
  );
};

export const PlayerVideoProjectSearchSection = withRouter(React.memo(PlayerVideoProjectSectionFC));
