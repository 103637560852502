import React from "react";
import { Button, Grid, Image } from "semantic-ui-react";
import{ useIntl } from "react-intl";
import { descriptors, PlayerVerifiedSuccessType } from "./descriptors";
import { ConfigContext } from "../../contexts/appContexts";

import { Svg } from "../../assets";
import { FloatedCard } from "../../containers";

import "./styles.scss";
import { FloatedContainer } from "../../styling/baseStyle";

const PlayerVerifiedFC: React.FC = () => {
  const config = React.useContext(ConfigContext);
  const { formatMessage } = useIntl();
  const content = (
    <Grid>
      <Grid.Row centered={true} className={"playerVerificationContent"}>
        <Grid.Column width={7}>
          <a href={config.iOSUrl} data-elm-id="playerVerifiediOSIcon" target="_blank">
            <Image src={Svg.AppStore} className={"storeIcons"} />
          </a>
        </Grid.Column>
        <Grid.Column width={7}>
          <a href={config.androidUrl} data-elm-id="playerVerifiedAndroidIcon" target="_blank">
            <Image src={Svg.PlayStore} className={"storeIcons"} />
          </a>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
  return (
    <FloatedContainer>
      <FloatedCard
        title={formatMessage({ ...descriptors[PlayerVerifiedSuccessType.title] })}
        subtitle={formatMessage({ ...descriptors[PlayerVerifiedSuccessType.accountCreated] })}
        content={content}
        isSubmitting={false}
        size={"bigCentered"}
        padding={"small"}
        containerPadding={"completed"}
      />
    </FloatedContainer>
  );
};

export const PlayerVerifiedSection = PlayerVerifiedFC
