import styled from "styled-components";
import { Grid } from "semantic-ui-react";
import { theme } from "../../../styling/theme";

export const SubHeaderRowGrid = styled(Grid.Row)({
  paddingBottom: "5px !important",
  paddingLeft: "0px",
  color: `${theme.subtitleColor}`,
});

export const CaptionSection = styled.div`
  background-color: white;
  border: 0.9px solid ${theme.boxLightBorder};
  border-radius: 10px;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  min-height: 200px;
  max-width: 500px;
  margin-top: 20px;
  padding: 10px;
  white-space: pre-wrap;
`;
