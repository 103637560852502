import * as React from "react";
import{ useIntl } from "react-intl";
import { Form, Message, Input, Icon, Popup, Radio } from "semantic-ui-react";
import DatePicker from "react-datepicker";

import { BasicFormProps, Category } from "../../../interfaces";
import { Rules, dateToTimeString, getDateSearchFormat, addDays } from "../../../utils";
import { MultipleDaySelectorComponent, AddCategoryComponent } from "../../../components";
import { SocialNetworkIconButton } from "../../SocialNetworkIconButton";

import { CampaignType, descriptors } from "./descriptors";

import "./styles.scss";
import { RowBasicPadding, SocialGrid, TitleLabel } from "../../../styling/baseStyle";

import { useSocialNetworks } from"../../../reducers";

interface OwnProps extends BasicFormProps {
  /**
   * Handle delivery mode changes
   */
  handleSetFieldValue?: (field: string, value: any) => void;
  basicInfoSubmitRef: any;
}

type Props = OwnProps 

/**
 * Basic Info Step.
 */
const CampaignClass: React.FC<Props> = ({
  handleChange,
  handleSetFieldValue,
  handleSubmit,
 
  messages,
  values,
  basicInfoSubmitRef,
}) => {
  const { formatMessage } = useIntl();
  const errors = messages && messages.error && messages.error.length > 0;
  const { deliveryMode, socialNetworks } = values;
  const deliveryRandom = deliveryMode === "RANDOM_DELIVERY" || deliveryMode === "ORDERED_RANDOM";
  const [categories, setCategories] = React.useState<Category[]>(values.categories);

  const { sns } = useSocialNetworks();

  React.useEffect(() => {
    handleSetFieldValue!("categories", categories);
  }, [categories]);

  React.useEffect(() => {
    handleSetFieldValue!("socialNetworksCounter", Object.values(socialNetworks).filter(Boolean).length);
  }, [socialNetworks]);

  const elegibleDays = (): JSX.Element => {
    const selectedDays = values.randomScheduleDays;
    return (
      <SocialGrid.Row className="bigPaddingLeft">
        <SocialGrid.Column width={6}>
          <MultipleDaySelectorComponent
            description={formatMessage({ ...descriptors[CampaignType.randomDaysDescription] })}
            selected={selectedDays}
            onChange={days => handleSetFieldValue!("randomScheduleDays", days)}
          />
        </SocialGrid.Column>
      </SocialGrid.Row>
    );
  };

  const deliveryTime = (): JSX.Element => {
    const promptTime = values.deliveryTimeDatePicker;
    const deliveryTimeKey = "randomScheduleDeliveryTimePst";
    return (
      <SocialGrid.Row className={"paddingTop bigPaddingLeft"}>
        <SocialGrid.Column width={10}>
          <DatePicker
            customInput={<Input icon="clock outline" />}
            data-elm-id={"campaignInputSelectedTime"}
            id={deliveryTimeKey}
            name={deliveryTimeKey}
            selected={promptTime}
            onChange={(date: any) => {
              handleSetFieldValue!("deliveryTimeDatePicker", date);
              handleSetFieldValue!(deliveryTimeKey, dateToTimeString(date));
            }}
            showTimeSelect
            showTimeSelectOnly
            dateFormat="h:mm aa"
            autoComplete={"off"}
            placeholderText={formatMessage({ ...descriptors[CampaignType.deliveryTimeDescription] })}
          />
          <span className={"dateSpan"}>{formatMessage({ ...descriptors[CampaignType.pstZoneMessage] })}</span>
        </SocialGrid.Column>
      </SocialGrid.Row>
    );
  };

  /*
   * Auto Post or Push Notification
   */
  const deliveryType = (): JSX.Element => {
    return (
      <SocialGrid.Row className={"xbigPaddingTop mediumPaddingBottom"}>
        <SocialGrid.Column width={10}>
          <RowBasicPadding>
            <TitleLabel>{formatMessage({ ...descriptors[CampaignType.deliveryTypeLabel] })}</TitleLabel>
            <br />
            <label className="descriptionLabel">
              {formatMessage({ ...descriptors[CampaignType.deliveryTypeDescription] })}
            </label>
          </RowBasicPadding>

          <SocialGrid columns={1} className={"bigPaddingLeft"}>
            <SocialGrid.Row key={"campaign_basic_row_auto_post"}>
              <Radio
                data-elm-id={`campaignBasicInfoAutoPostRadioBtn`}
                className={"campaignRadio secondary rounded"}
                id={"isAutoPost"}
                name={"isAutoPost"}
                onChange={(e: any, data: any) => {
                  handleSetFieldValue!(`${data.id}`, data.checked);
                }}
                checked={values.isAutoPost}
                label={"AutoPost"}
              />

              {values.isAutoPost && (
                <>
                  <br />
                  <label className="descriptionLabel xbigPaddingLeft">
                    {formatMessage({ ...descriptors[CampaignType.socialNetworksDescription] })}
                  </label>
                </>
              )}
              {values.isAutoPost && (
                <SocialGrid className={"socialSection"}>
                  <RowBasicPadding className={"socialIconsSection"}>
                    {sns.map((sn, index) => {
                      return (
                        <SocialNetworkIconButton
                          disabled={false}
                          withPadding={false}
                          size={"noMargin"}
                          key={"campaign_sn_icon_" + index}
                          active={socialNetworks[sn]}
                          networkId={sn}
                          onClickHandler={checked => {
                            handleSetFieldValue!("socialNetworks", { ...socialNetworks, [sn]: checked });
                          }}
                        />
                      );
                    })}
                  </RowBasicPadding>
                </SocialGrid>
              )}
            </SocialGrid.Row>

            <SocialGrid.Row key={"campaign_basic_row_push_notification"}>
              <Radio
                data-elm-id={`campaignBasicInfoPushNotificationRadioBtn`}
                className={"campaignRadio secondary rounded"}
                id={"isPushPost"}
                name={"isPushPost"}
                onChange={(e: any, data: any) => handleSetFieldValue!(`isAutoPost`, false)}
                checked={!values.isAutoPost}
                label={"Push Notification"}
              />
            </SocialGrid.Row>
          </SocialGrid>
        </SocialGrid.Column>
      </SocialGrid.Row>
    );
  };

  const customStartEnd = (): JSX.Element => {
    const startDate = values.startDatePicker ? new Date(values.startDatePicker) : values.startDatePicker;
    const endDate = values.endDatePicker ? new Date(values.endDatePicker) : values.endDatePicker;
    const startDateKey = "startDate";
    const endDateKey = "endDate";
    return (
      <SocialGrid.Row className={"xbigPaddingTop"}>
        <RowBasicPadding>
          <Popup
            hoverable
            trigger={
              <TitleLabel>
                {formatMessage({ ...descriptors[CampaignType.customDurationLabel] })}
                <Icon name="info circle" className={"infoIcon"} link />
              </TitleLabel>
            }
            content={formatMessage({ ...descriptors[CampaignType.campaignDurationHoverDescription] })}
          />
          <br />
          <label className="descriptionLabel">
            {formatMessage({ ...descriptors[CampaignType.campaignDurationDescription] })}
          </label>
        </RowBasicPadding>
        <SocialGrid columns={2}>
          <SocialGrid.Column>
            <DatePicker
              customInput={<Input icon="calendar outline" />}
              data-elm-id={"campaignInputStartDate"}
              id={startDateKey}
              name={startDateKey}
              selected={startDate}
              onChange={(date: any) => {
                handleSetFieldValue!("startDatePicker", date);
                handleSetFieldValue!(startDateKey, getDateSearchFormat(date));
              }}
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={15}
              minDate={new Date()}
              maxDate={endDate ? addDays(endDate, -1) : null}
              autoComplete={"off"}
              placeholderText={formatMessage({ ...descriptors[CampaignType.startDateLabel] })}
            />
            <span className={"dateSpan"}>{formatMessage({ ...descriptors[CampaignType.startDateLabel] })}</span>
          </SocialGrid.Column>

          <SocialGrid.Column>
            <DatePicker
              customInput={<Input icon="calendar outline" />}
              data-elm-id={"campaignInputEndDate"}
              id={endDateKey}
              name={endDateKey}
              selected={endDate}
              onChange={(date: any) => {
                handleSetFieldValue!("endDatePicker", date);
                handleSetFieldValue!(endDateKey, getDateSearchFormat(date));
              }}
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={15}
              minDate={addDays(startDate, 1) || new Date()}
              autoComplete={"off"}
              placeholderText={formatMessage({ ...descriptors[CampaignType.endDateLabel] })}
            />
            <span className={"dateSpan"}>{formatMessage({ ...descriptors[CampaignType.endDateLabel] })}</span>
          </SocialGrid.Column>
        </SocialGrid>
      </SocialGrid.Row>
    );
  };

  const deliveryModeSection = (): JSX.Element => {
    const deliveryStatus = [
      {
        key: 0,
        text: formatMessage({ ...descriptors[CampaignType.randomDelivery] }),
        value: "RANDOM_DELIVERY",
        selected: deliveryMode === "RANDOM_DELIVERY",
      },
      {
        key: 1,
        text: formatMessage({ ...descriptors[CampaignType.orderedRandomDelivery] }),
        value: "ORDERED_RANDOM",
        selected: deliveryMode === "ORDERED_RANDOM",
      },
      {
        key: 2,
        text: formatMessage({ ...descriptors[CampaignType.specificDate] }),
        value: "SPECIFIC_DATE",
        selected: deliveryMode === "SPECIFIC_DATE",
      },
    ];

    return (
      <SocialGrid.Row className={"xbigPaddingTop"}>
        <RowBasicPadding>
          <TitleLabel>{formatMessage({ ...descriptors[CampaignType.deliveryLabel] })}</TitleLabel>
          <br />
          <label className="descriptionLabel">
            {formatMessage({ ...descriptors[CampaignType.deliveryModeDescription] })}
          </label>
        </RowBasicPadding>

        <SocialGrid columns={1} className={"bigPaddingLeft mediumPaddingBottom"}>
          {deliveryStatus.map((button, index) => {
            return (
              <SocialGrid.Row key={"campaign_basic_column" + index}>
                <Radio
                  data-elm-id={`campaignBasicInfoDeliveryModeBtn`}
                  className={"campaignRadio secondary rounded"}
                  id={button.value}
                  name={button.value}
                  onChange={(e: any, data: any) => {
                    handleSetFieldValue!("deliveryMode", data.name);
                  }}
                  checked={button.selected}
                  label={button.text}
                />
                {deliveryRandom && button.value === deliveryMode && elegibleDays()}
                {deliveryRandom && button.value === deliveryMode && deliveryTime()}
              </SocialGrid.Row>
            );
          })}
        </SocialGrid>
      </SocialGrid.Row>
    );
  };

  const deliverySection = (): JSX.Element => {
    return (
      <SocialGrid.Column width={10}>
        {deliveryType()}
        {deliveryModeSection()}
        {deliveryRandom && customStartEnd()}
      </SocialGrid.Column>
    );
  };

  return (
    <Form
      size="large"
      error={errors}
      ref={basicInfoSubmitRef}
      onSubmit={handleSubmit}
      className={"campaignBasicInfoForm"}>
      <div className={"formDiv"}>
        {messages && messages.error && messages.error.length > 0 && <Message error list={messages && messages.error} />}
        <Form.Input
          key={"campaignNameInput"}
          label={formatMessage({ ...descriptors[CampaignType.campaignTitle] })}
          data-elm-id={"campaignNameInput"}
          fluid
          id={"name"}
          type={"text"}
          name={"name"}
          placeholder={formatMessage({ ...descriptors[CampaignType.titlePlaceholder] })}
          onChange={(e, data) => {
            if (e.target.value.length < Rules.MAX_CAMPAIGN_TITLE) {
              handleChange!(e, data);
            }
          }}
          value={values.name}
        />

        <AddCategoryComponent
          customTitleStyle={"titleLabel"}
          selectedCategories={categories}
          handleOnChange={setCategories}
          type={"CAMPAIGN"}
          multiple={false}
        />

        <SocialGrid>{deliverySection()}</SocialGrid>
      </div>
    </Form>
  );
};

export const CampaignBasicInfo = CampaignClass;
