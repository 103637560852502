import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import{ useIntl } from "react-intl";

import { AdminSideBar, EditingQueueListContent, SideBar, TitleSection } from "../../../components";
import { AppContext, AppContextProvider } from "../../../providers";
import { WhiteContainer, ContentGrid, SocialGrid } from "../../../styling/baseStyle";

import { EditingQueueType, descriptors } from "./descriptors";
import { RowContainer } from "./styled";
import "./styles.scss";
import { UserGroups } from "../../../interfaces";

type Props = RouteComponentProps;

const EditingQueueListFC: React.FC<Props> = ({ history }) => {
  const { formatMessage } = useIntl();
  return (
    <WhiteContainer>
      <AppContextProvider>
        <AppContext.Consumer>
          {({ userContext }) => {
            const isEditor = userContext?.account.scGroups!!.some(g => g === UserGroups.EDITOR);

            if (isEditor) {
              return <SideBar history={history} />;
            } else {
              return <AdminSideBar history={history} />;
            }
          }}
        </AppContext.Consumer>
      </AppContextProvider>

      <ContentGrid className={"listManagerContent"} columns={1}>
        <SocialGrid columns={1}>
          <TitleSection
            title={formatMessage({ ...descriptors[EditingQueueType.title] })}
            ready={true}
            showNextOption={false}
            showLinkOption={false}
            customLinkSection={undefined}
          />
          <RowContainer>
            <SocialGrid.Column>
              <EditingQueueListContent />
            </SocialGrid.Column>
          </RowContainer>
        </SocialGrid>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const EditorEditingQueueList = withRouter(EditingQueueListFC)