import { defineMessages } from "react-intl";

export enum BrandPostComponentType {
  message = "BrandPostComponentType.message",
  messagePlaceholder = "BrandPostComponentType.messagePlaceholder",
  aiButton = "BrandPostComponentType.aiButton",
  hashtags = "BrandPostComponentType.hashtags",

  postSectionTitle = "BrandPostComponentType.postSectionTitle",
  autoPost = "BrandPostComponentType.autoPost",
  pushNotification = "BrandPostComponentType.pushNotification",

  allowHashtags = "BrandPostComponentType.allowHashtags",
  allowShortLink = "BrandPostComponentType.allowShortLink",
  allowComplianceDisclaimer = "BrandPostComponentType.allowComplianceDisclaimer",
  postStatusLabel = "BrandPostComponentType.postStatusLabel",
}

export const en = {
  [BrandPostComponentType.message]: "Caption",
  [BrandPostComponentType.messagePlaceholder]: "Write Message",
  [BrandPostComponentType.aiButton]: "Generate Caption",
  [BrandPostComponentType.hashtags]: "Hashtags",
  [BrandPostComponentType.postSectionTitle]: "Posting Preferences",

  [BrandPostComponentType.autoPost]: "Auto Post",
  [BrandPostComponentType.pushNotification]: "Push Notification",

  [BrandPostComponentType.allowShortLink]: "Include {owner} short link",
  [BrandPostComponentType.allowComplianceDisclaimer]: "Include Compliance Disclaimer",
  [BrandPostComponentType.allowHashtags]: "Include {owner} hashtags",

  [BrandPostComponentType.postStatusLabel]: "Post Status",
};

export const descriptors = defineMessages({
  [BrandPostComponentType.aiButton]: {
    id: BrandPostComponentType.aiButton,
    defaultMessage: en[BrandPostComponentType.aiButton],
    description: "-",
  },
  [BrandPostComponentType.autoPost]: {
    id: BrandPostComponentType.autoPost,
    defaultMessage: en[BrandPostComponentType.autoPost],
    description: "-",
  },
  [BrandPostComponentType.pushNotification]: {
    id: BrandPostComponentType.pushNotification,
    defaultMessage: en[BrandPostComponentType.pushNotification],
    description: "-",
  },
  [BrandPostComponentType.allowHashtags]: {
    id: BrandPostComponentType.allowHashtags,
    defaultMessage: en[BrandPostComponentType.allowHashtags],
    description: "-",
  },
  [BrandPostComponentType.allowShortLink]: {
    id: BrandPostComponentType.allowShortLink,
    defaultMessage: en[BrandPostComponentType.allowShortLink],
    description: "-",
  },
  [BrandPostComponentType.allowComplianceDisclaimer]: {
    id: BrandPostComponentType.allowComplianceDisclaimer,
    defaultMessage: en[BrandPostComponentType.allowComplianceDisclaimer],
    description: "-",
  },
  [BrandPostComponentType.postSectionTitle]: {
    id: BrandPostComponentType.postSectionTitle,
    defaultMessage: en[BrandPostComponentType.postSectionTitle],
    description: "-",
  },
  [BrandPostComponentType.message]: {
    id: BrandPostComponentType.message,
    defaultMessage: en[BrandPostComponentType.message],
    description: "-",
  },
  [BrandPostComponentType.messagePlaceholder]: {
    id: BrandPostComponentType.messagePlaceholder,
    defaultMessage: en[BrandPostComponentType.messagePlaceholder],
    description: "-",
  },
  [BrandPostComponentType.hashtags]: {
    id: BrandPostComponentType.hashtags,
    defaultMessage: en[BrandPostComponentType.hashtags],
    description: "-",
  },
  [BrandPostComponentType.postStatusLabel]: {
    id: BrandPostComponentType.postStatusLabel,
    defaultMessage: en[BrandPostComponentType.postStatusLabel],
    description: "-",
  },
});
