import { defineMessages } from "react-intl";

export enum ScriptDetailsType {
  title = "ScriptDetailsType.title",
  stats = "ScriptDetailsType.stats",
  posts = "ScriptDetailsType.posts",

  createdLabel = "ScriptDetailsType.createdLabel",
  lastEditedLabel = "ScriptDetailsType.lastEditedLabel",
  lastVideosLabel = "ScriptDetailsType.lastVideosLabel",
  totalVideosLabel = "ScriptDetailsType.totalVideosLabel",
}

export const en = {
  [ScriptDetailsType.title]: " Published By",
  [ScriptDetailsType.stats]: "Stats",
  [ScriptDetailsType.posts]: "Latest Posts",

  [ScriptDetailsType.createdLabel]: "Created ",
  [ScriptDetailsType.lastEditedLabel]: "Last Edited ",

  [ScriptDetailsType.lastVideosLabel]: "Videos in the last 30 days: ",
  [ScriptDetailsType.totalVideosLabel]: "Total Videos: ",
};

export const descriptors = defineMessages({
  [ScriptDetailsType.title]: {
    id: ScriptDetailsType.title,
    defaultMessage: en[ScriptDetailsType.title],
    description: "-",
  },
  [ScriptDetailsType.stats]: {
    id: ScriptDetailsType.stats,
    defaultMessage: en[ScriptDetailsType.stats],
    description: "-",
  },
  [ScriptDetailsType.posts]: {
    id: ScriptDetailsType.posts,
    defaultMessage: en[ScriptDetailsType.posts],
    description: "-",
  },
  [ScriptDetailsType.createdLabel]: {
    id: ScriptDetailsType.createdLabel,
    defaultMessage: en[ScriptDetailsType.createdLabel],
    description: "-",
  },
  [ScriptDetailsType.lastEditedLabel]: {
    id: ScriptDetailsType.lastEditedLabel,
    defaultMessage: en[ScriptDetailsType.lastEditedLabel],
    description: "-",
  },
  [ScriptDetailsType.lastVideosLabel]: {
    id: ScriptDetailsType.lastVideosLabel,
    defaultMessage: en[ScriptDetailsType.lastVideosLabel],
    description: "-",
  },
  [ScriptDetailsType.totalVideosLabel]: {
    id: ScriptDetailsType.totalVideosLabel,
    defaultMessage: en[ScriptDetailsType.totalVideosLabel],
    description: "-",
  },
});
