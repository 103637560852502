import { SignUpType } from "./descriptors";
import * as MODEL from "../../../interfaces/models";

export const fields: MODEL.FormField[] = [
  {
    id: "email",
    name: "email",
    type: "email",
    label: "Email",
    placeholder: SignUpType.email,
    disabled: false,
  },
];
