import { SubscriptionStatus, SubscriptionType } from "./Player";

export enum BillingPeriod {
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export enum PricePlanTier {
  PLAYER_BASIC = "PLAYER_BASIC",
  PLAYER_PLUS = "PLAYER_PLUS",
}

export interface Plan {
  id?: number;
  coachId?: string;
  planName: string;
  planStatements: string[];
  price: number;
  hasFreeTrial: boolean;
  baseInterval?: BillingPeriod;
  baseActive?: boolean;
  intervalPrices?: PriceElement[];
  fees?: Fee[];
  tier?: PricePlanTier;
}

export interface PriceElement {
  price: number;
  hasFreeTrial: boolean;
  active?: boolean;
  interval?: BillingPeriod;
}

export interface Subscription {
  id?: number;
  playerId?: number;
  pricePlan: Plan;
  status: SubscriptionStatus;
  type: SubscriptionType;
  endPeriodDate?: string;
}

export interface Fee {
  code: string;
  amount: number;
  description: string;
  type: "PERCENTAGE" | "FLAT";
}
