import * as React from "react";
import { Dropdown, Menu, Loader, DropdownItemProps, Checkbox } from "semantic-ui-react";
import { get, map, includes, head, filter, union } from "lodash";

import { AppContext } from "../../providers";
import { SearchFilter, Page } from "../../interfaces";
import { CoachService } from "../../services";

import "./styles.scss";

interface Props {
  /**
   * Component
   */
  content: JSX.Element;
  /**
   * Handle to refresh content with the result
   */
  refreshData: (result: any[]) => void;

  /**
   * Search function name
   */
  searchFunction: (coachId: string, filters?: SearchFilter | null) => Promise<Page<any>>;

  customSort?: DropdownItemProps[] | undefined;
  defaultCustomSort?: string;

  multiple?: boolean;
  segmentedPlayers?: number[];
  isInclude?: boolean;

  /**
   * Custom Filter label
   */
  customFilter?: JSX.Element;
}

const BranchSearchFC: React.FC<Props> = ({
  content,
  customFilter,
  multiple = false,
  refreshData,
  searchFunction,
  segmentedPlayers,
  isInclude = true,
}) => {
  const { userContext } = React.useContext(AppContext);
  const coachId = get(userContext, "coach.id", "");

  const [loading, setLoading] = React.useState<boolean>(true);

  const [selectedBranches, setSelectedBranches] = React.useState<string[] | undefined>(undefined);
  const [selectedRegions, setSelectedRegions] = React.useState<string[] | undefined>(undefined);
  const [selectedStates, setSelectedStates] = React.useState<string[] | undefined>(undefined);

  const [branchesFilters, setBranchesFilters] = React.useState<DropdownItemProps[]>([]);
  const [regionFilters, setRegionsFilters] = React.useState<DropdownItemProps[]>([]);
  const [stateFilters, setStatesFilters] = React.useState<DropdownItemProps[]>([]);

  React.useEffect(() => {
    CoachService.branches(coachId).then(response => {
      const filters: DropdownItemProps[] = map(response, branch => ({
        key: branch.branchId,
        text: branch.name,
        value: branch.branchId,
      }));
      setBranchesFilters(filters);
    });
    CoachService.regions(coachId).then(response => {
      const filters: DropdownItemProps[] = map(response, region => ({
        key: region.regionId,
        text: region.name,
        value: region.regionId,
      }));
      setRegionsFilters(filters);
    });
    CoachService.states(coachId).then(response => {
      const filters: DropdownItemProps[] = map(response, state => ({
        key: state.stateId,
        text: state.stateCode,
        value: state.stateId,
      }));
      setStatesFilters(filters);
    });
  }, [coachId]);

  React.useEffect(() => {
    search();
  }, [selectedBranches, searchFunction, selectedRegions, selectedStates]);

  React.useEffect(() => {
    search();
  }, []);

  const search = () => {
    setLoading(true);
    let filterBody: SearchFilter = {
      branchIds: selectedBranches ? selectedBranches : undefined,
      regionIds: selectedRegions ? selectedRegions : undefined,
      stateIds: selectedStates ? selectedStates : undefined,
    };

    if (segmentedPlayers && segmentedPlayers.length > 0 && isInclude) {
      filterBody = {
        ...filterBody,
        playerIds: segmentedPlayers!,
      };
    }

    searchFunction(coachId, filterBody)
      .then(response => {
        let result = response.content;
        if (!isInclude) {
          result = filter(result, player => !includes(segmentedPlayers, player.playerAccount.playerId!));
        }
        refreshData(result);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filterActive = head(branchesFilters) || head(regionFilters) || head(stateFilters);

  const dropDownProps = {
    className: "searchInput",
    multiple,
  };

  const withFilters =
    (stateFilters && stateFilters?.length > 0) ||
    (branchesFilters && branchesFilters?.length > 0) ||
    (regionFilters && regionFilters?.length > 0);

  return (
    <div>
      {filterActive && (customFilter ? customFilter : "Filter By:")}
      {withFilters && (
        <Menu secondary className={"branchFilterMenu"}>
          {/* BRANCH */}
          {branchesFilters && branchesFilters?.length > 0 && (
            <Menu.Item>
              <Dropdown placeholder={"Branch"} {...dropDownProps} search>
                <Dropdown.Menu>
                  {branchesFilters.map((fbranches, index) => {
                    return (
                      <Dropdown.Item key={"branch" + index}>
                        <Checkbox
                          id={fbranches.key}
                          checked={selectedBranches?.includes(`${fbranches!.value!}`)}
                          onChange={(a, data) => {
                            setSelectedBranches(filters => {
                              if (data.checked) {
                                return union(filters, [`${fbranches!.value!}`]);
                              }
                              return filters?.filter(r => r !== `${fbranches!.value!}`);
                            });
                          }}
                        />
                        {fbranches.text}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          )}

          {/* REGION */}
          {regionFilters && regionFilters?.length > 0 && (
            <Menu.Item>
              <Dropdown placeholder={"Region"} {...dropDownProps} search>
                <Dropdown.Menu>
                  {regionFilters.map((fbranches, index) => {
                    return (
                      <Dropdown.Item key={"selectedRegions" + index}>
                        <Checkbox
                          checked={selectedRegions?.includes(`${fbranches!.value!}`)}
                          onChange={(a, data) => {
                            setSelectedRegions(fregions => {
                              if (data.checked) {
                                return union(fregions, [`${fbranches!.value!}`]);
                              }
                              return fregions?.filter(r => r !== `${fbranches!.value!}`);
                            });
                          }}
                        />
                        {fbranches.text}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          )}

          {/* LICENSE STATE */}
          {stateFilters && stateFilters?.length > 0 && (
            <Menu.Item>
              <Dropdown scrolling placeholder={"License State"} {...dropDownProps}>
                <Dropdown.Menu>
                  {stateFilters.map((stateFilter, index) => {
                    return (
                      <Dropdown.Item key={"state" + index}>
                        <Checkbox
                          checked={selectedStates?.includes(`${stateFilter!.value!}`)}
                          onChange={(a, data) => {
                            setSelectedStates(fregions => {
                              if (data.checked) {
                                return union(fregions, [`${stateFilter!.value!}`]);
                              }
                              return fregions?.filter(r => r !== `${stateFilter!.value!}`);
                            });
                          }}
                        />
                        {stateFilter.text}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          )}
        </Menu>
      )}
      {loading ? <Loader active size="large" /> : content}
    </div>
  );
};

export const BranchSearch = BranchSearchFC;
