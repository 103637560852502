import * as React from "react";
import { Grid, Header } from "semantic-ui-react";
import "./styles.scss";
import { privacyPolicy } from "./privacypolicy";

export const PrivacyPage: React.FC = () => {
  return (
    <div>
      <Grid>
        <Grid.Row>
          <div className={"titleSection"}>
            <Header textAlign="center" as="h1">
              PRIVACY POLICY
            </Header>
          </div>
        </Grid.Row>
      </Grid>

      <Grid.Row className={"termsGrid"}>
        {privacyPolicy.map((option, index) => {
          return (
            <Grid.Row key={index} className={"termSection"}>
              <Header as="h1">{option.title}</Header>
              <Header as="h2">{option.subtitle}</Header>
              <Header as="h3">{option.h3}</Header>

              {option.description &&
                option.description.map((desc, descIndex) => {
                  return <p key={"desc" + index + descIndex} dangerouslySetInnerHTML={{ __html: desc }} />;
                })}

              <ul>
                {option.bullets &&
                  option.bullets.map((bullet, bulletsIndex) => {
                    if (bullet.main) {
                      return (
                        <>
                          <li
                            key={"bullets" + index + bulletsIndex}
                            dangerouslySetInnerHTML={{ __html: bullet.main }}
                          />
                          <ul>
                            {bullet.innerBullets &&
                              bullet.innerBullets.map((innerBullet: string, innerBulletsIndex: number) => {
                                return (
                                  <li
                                    key={"bullets" + index + innerBulletsIndex}
                                    dangerouslySetInnerHTML={{ __html: innerBullet }}
                                  />
                                );
                              })}
                          </ul>
                        </>
                      );
                    } else {
                      return <li key={"bullets" + index + bulletsIndex} dangerouslySetInnerHTML={{ __html: bullet }} />;
                    }
                  })}
              </ul>
            </Grid.Row>
          );
        })}
      </Grid.Row>
    </div>
  );
};
