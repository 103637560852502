import { Campaign } from "../../../../interfaces";
import { actions } from "./actions";
import { CampaignState } from "./initialState";

export const CampaignReducer = (state: CampaignState, action: { name: string }) => {
  const { name } = action;
  switch (name) {
    case actions.NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep < 4 ? state.currentStep + 1 : state.currentStep,
        enableToNext: false,
        prepareConfirmationModal: false,
      };
    case actions.PREVIOUS_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
        prepareConfirmationModal: false,
      };
    case actions.CANCEL:
      return {
        ...state,
        openConfirmationModal: false,
        prepareConfirmationModal: false,
        currentStep: -1,
      };
    case actions.ENABLE_NEXT:
      return { ...state, enableToNext: true };
    case actions.DISABLE_NEXT:
      return { ...state, enableToNext: false };
    case actions.ACCEPT_CONFIRMATION_MODAL:
      return {
        ...state,
        openConfirmationModal: false,
        prepareConfirmationModal: true,
      };
    case actions.CLOSE_CONFIRMATION_MODAL:
      return {
        ...state,
        openConfirmationModal: false,
        prepareConfirmationModal: false,
      };
    case actions.OPEN_CONFIRMATION_MODAL:
      return {
        ...state,
        openConfirmationModal: true,
        prepareConfirmationModal: true,
      };
    case actions.CLEAN_CONFIRMATION_MODAL:
      return {
        ...state,
        prepareConfirmationModal: false,
        currentStep: state.currentStep > 4 ? state.currentStep - 1 : state.currentStep,
      };
  }
  return { ...state };
};
