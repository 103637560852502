import { defineMessages } from "react-intl";

export enum PostType {
  createdTitle = "PostType.createdTitle",
  keyword = "PostType.specificDateTitle",
  showPost = "PostType.showPost",
  hidePost = "PostType.welcomePromptTitle",
  welcomePromptSubtitle = "PostType.welcomePromptSubtitle",
  requestedDate = "PostType.requestedDate",
  requestedBy = "PostType.requestedBy",
}

export const en = {
  [PostType.createdTitle]: "Posted ",
  [PostType.keyword]: "Keywords",
  [PostType.showPost]: "Show Post",
  [PostType.hidePost]: "Hide Post",

  [PostType.welcomePromptSubtitle]: "Welcome Prompt",
  [PostType.requestedDate]: "Status Updated: ",
  [PostType.requestedBy]: "Updated By: ",
};

export const descriptors = defineMessages({
  [PostType.hidePost]: {
    id: PostType.hidePost,
    defaultMessage: en[PostType.hidePost],
    description: "-",
  },
  [PostType.welcomePromptSubtitle]: {
    id: PostType.welcomePromptSubtitle,
    defaultMessage: en[PostType.welcomePromptSubtitle],
    description: "-",
  },
  [PostType.requestedBy]: {
    id: PostType.requestedBy,
    defaultMessage: en[PostType.requestedBy],
    description: "-",
  },
  [PostType.requestedDate]: {
    id: PostType.requestedDate,
    defaultMessage: en[PostType.requestedDate],
    description: "-",
  },
  [PostType.createdTitle]: {
    id: PostType.createdTitle,
    defaultMessage: en[PostType.createdTitle],
    description: "-",
  },
  [PostType.keyword]: {
    id: PostType.keyword,
    defaultMessage: en[PostType.keyword],
    description: "-",
  },
  [PostType.showPost]: {
    id: PostType.showPost,
    defaultMessage: en[PostType.showPost],
    description: "-",
  },
});
