import { defineMessages } from "react-intl";

export enum ResendType {
  email = "ResendType.email",
  submit = "ResendType.submit",
  titleResendVerify = "ResendType.title",
  titleForgotPassword = "ResendType.titleForgot",
  errorTitle = "ResendType.errorTitle",
  infoTitle = "ResendType.infoTitle",
}

export const en = {
  [ResendType.email]: "Email Address",
  [ResendType.submit]: "Send",
  [ResendType.titleResendVerify]: "Resend verification email",
  [ResendType.titleForgotPassword]: "Recover your Password",
  [ResendType.errorTitle]: "  ",
  [ResendType.infoTitle]: "Info:",
};

export const descriptors = defineMessages({
  [ResendType.email]: {
    id: ResendType.email,
    defaultMessage: en[ResendType.email],
    description: "Email address",
  },
  [ResendType.submit]: {
    id: ResendType.submit,
    defaultMessage: en[ResendType.submit],
    description: "Sign In text",
  },
  [ResendType.titleResendVerify]: {
    id: ResendType.titleResendVerify,
    defaultMessage: en[ResendType.titleResendVerify],
    description: " ",
  },
  [ResendType.titleForgotPassword]: {
    id: ResendType.titleForgotPassword,
    defaultMessage: en[ResendType.titleForgotPassword],
    description: "titleForgotPassword",
  },
  [ResendType.errorTitle]: {
    id: ResendType.errorTitle,
    defaultMessage: en[ResendType.errorTitle],
    description: "Error Messages title",
  },
  [ResendType.infoTitle]: {
    id: ResendType.infoTitle,
    defaultMessage: en[ResendType.infoTitle],
    description: "Info messages title",
  },
});
