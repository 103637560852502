import { ProfileInformationType } from "./descriptors";
import { FormField } from "../../../../interfaces/models";

export const fields: FormField[] = [
  {
    id: "firstName",
    name: "firstName",
    type: "text",
    label: "firstName",
    placeholder: ProfileInformationType.firstName,
    disabled: false,
  },
  {
    id: "lastName",
    name: "lastName",
    type: "text",
    label: "lastName",
    placeholder: ProfileInformationType.lastName,
    disabled: false,
  },
  {
    id: "title",
    name: "title",
    type: "text",
    label: "Title",
    placeholder: ProfileInformationType.title,
    disabled: false,
  },
  {
    id: "company",
    name: "company",
    type: "text",
    label: "Company",
    placeholder: ProfileInformationType.company,
    disabled: false,
  },
];
