import styled from "styled-components";
import { Card, Grid } from "semantic-ui-react";

export const ThumbnailPreview = styled(Grid.Column)`
  height: 200px;
  min-width: 148px;
  width: ${(props: any) => (props.imagewidth ? props.imagewidth + "px" : "100%")};
  background: url("${(props: any) => (props.thumbnailurl ? props.thumbnailurl : "")}") no-repeat top center;
  background-size: ${(props: any) => props.resizemode};
`;

export const NeedAttentionCard = styled(Card)`
  width: ${(props: any) => (props.imagewidth ? props.imagewidth + 2 + "px !important" : "100%")};
`;
