import { defineMessages } from "react-intl";

export enum CatalystType {
  addProjectBtn = "CatalystType.addCampaignBtn",
  creditsRemaining = "CatalystType.creditsRemaining",
  unlimitedCredits = "CatalystType.unlimitedCredits",
}

export const en = {
  [CatalystType.addProjectBtn]: "New Project",
  [CatalystType.creditsRemaining]: "{credits} Video Credits Remaining",
  [CatalystType.unlimitedCredits]: "Unlimited Video Credits",
};

export const descriptors = defineMessages({
  [CatalystType.addProjectBtn]: {
    id: CatalystType.addProjectBtn,
    defaultMessage: en[CatalystType.addProjectBtn],
    description: "",
  },
  [CatalystType.creditsRemaining]: {
    id: CatalystType.creditsRemaining,
    defaultMessage: en[CatalystType.creditsRemaining],
    description: "",
  },
  [CatalystType.unlimitedCredits]: {
    id: CatalystType.unlimitedCredits,
    defaultMessage: en[CatalystType.unlimitedCredits],
    description: "",
  },
});
