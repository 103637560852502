import { client as axios } from "./axiosClient";
import { Constants, catchServiceException } from "@socialcoach/services";
import { BrandResponse } from "../interfaces";

const BRAND_SERVICE = `${Constants.API_URL}/brands`;

export class BrandServices {
  @catchServiceException()
  static async get(brandId: string): Promise<BrandResponse> {
    const { data } = await axios.get<BrandResponse>(`${BRAND_SERVICE}/${brandId}`, {
      withCredentials: true,
    });

    return data;
  }
}
