import * as Yup from "yup";
import { QuestionLimitsConfig } from "../../../interfaces";
import { phoneRegExp, Rules, validAge, webUrlRegExp } from "../../../utils";
import { keys } from "lodash";
import { shortLink } from "../../../validations";

export const validationProfileSchema = () => {
  return Yup.object().shape({
    webAddress: Yup.string()
      .lowercase()
      .trim()
      .required("Web address is required")
      .matches(webUrlRegExp, "Web address is invalid"),
    slug: shortLink.slug,
  });
};
