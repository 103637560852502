import * as Yup from "yup";
import { simplePassword } from "../../../validations";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required("First Name is required"),
  lastName: Yup.string()
    .trim()
    .required("Last Name is required"),
  email: Yup.string()
    .email("Email is invalid")
    .required("Email is required"),
  ...simplePassword,
});
