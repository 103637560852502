import { defineMessages } from "react-intl";

export enum FBPagesModalType {
  title = "FBPagesModalType.title",

  selectYourFBPage = "FBPagesModalType.selectYourFBPage",
  yesBtn = "FBPagesModalType.yesBtn",
  noBtn = "FBPagesModalType.noBtn",
}

export const en = {
  [FBPagesModalType.title]: "",
  [FBPagesModalType.selectYourFBPage]: "Select a Facebook page to link with SocialCoach",
  [FBPagesModalType.yesBtn]: "OK",
  [FBPagesModalType.noBtn]: "Cancel",
};

export const descriptors = defineMessages({
  [FBPagesModalType.title]: {
    id: FBPagesModalType.title,
    defaultMessage: en[FBPagesModalType.title],
    description: "-",
  },
  [FBPagesModalType.selectYourFBPage]: {
    id: FBPagesModalType.selectYourFBPage,
    defaultMessage: en[FBPagesModalType.selectYourFBPage],
    description: "-",
  },

  [FBPagesModalType.yesBtn]: {
    id: FBPagesModalType.yesBtn,
    defaultMessage: en[FBPagesModalType.yesBtn],
    description: "-",
  },
  [FBPagesModalType.noBtn]: {
    id: FBPagesModalType.noBtn,
    defaultMessage: en[FBPagesModalType.noBtn],
    description: "-",
  },
});
