import * as React from "react";
import { Grid, Header } from "semantic-ui-react";

import "./styles.scss";

interface Props {
  title: string;
}
const dataElmId = "controlOptionSection";

const ControlOptionFC: React.FC<React.PropsWithChildren<Props>> = ({ children, title }) => {
  return (
    <Grid.Row className="controlOptionGridRow">
      <Grid className="controlOptionGrid">
        <Grid.Row className="controlOptionSection">
          <Header as="h6" data-elm-id={`${dataElmId}_title_${title.replace(" ", "_")}`}>
            {title}
          </Header>
        </Grid.Row>
        <Grid.Row className={"noPadding"}>{children}</Grid.Row>
      </Grid>
    </Grid.Row>
  );
};

export const ControlOption = ControlOptionFC;
