import { ProfileContactInformationType } from "./descriptors";
import { FormField } from "../../../../interfaces/models";

export const contactInformationFields: FormField[] = [
  {
    id: "contactEmail",
    name: "contactEmail",
    type: "text",
    label: "Email",
    placeholder: ProfileContactInformationType.contactEmail,
    disabled: false,
  },
  {
    id: "contactPhone",
    name: "contactPhone",
    type: "phone",
    label: "Phone",
    placeholder: ProfileContactInformationType.contactPhone,
    disabled: false,
  },
];
