import mime from "mime";
import { Media, MediaGroup } from "../interfaces";

const mediaCategoryChecker = (value: string) => ["png", "jpg", "jpeg"].some(element => value.includes(element));

export function toMedia(url: string, filenamePrefix?: string, index: number = 0): Media {
  const timestamp = new Date().getTime();
  const baseFileName: string = `${filenamePrefix}_${timestamp}`;
  const mediaCategory = mediaCategoryChecker(url) ? "IMAGE" : "VIDEO";

  return {
    id: index,
    sortOrder: index,
    category: mediaCategory,
    fileName: baseFileName + index,
    uri: url,
    type: mime.getType(url) || "image/png",
    extension: mime.getExtension(url) || ".png",
  };
}

export function toMediaGroup(url: string, filenamePrefix?: string, index: number = 0): MediaGroup {
  const mediaType = mediaCategoryChecker(url) ? "IMAGE" : "VIDEO";

  return {
    type: mediaType,
    media: [toMedia(url, filenamePrefix, index)],
  };
}

/**
 * A helper function to integrate react-beautiful-dnd
 *
 * @param list the list being reordered
 * @param startIndex the 'source' parameter from react-beautiful-dnd
 * @param endIndex the 'destination' parameter from react-beautiful-dnd
 */
export function reorder(list: any[], startIndex: number, endIndex: number): any[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}
