import * as React from "react";
import{ useIntl } from "react-intl";
import { Modal, Button } from "semantic-ui-react";
import { PlayerOrganizationList } from "./components/PagesList";

import { LIOrganizationModalType, descriptors } from "./descriptors";

import "./styles.scss";

interface OwnProps {
  openConfirmationModal: boolean;
  size?: "mini" | "tiny" | "small" | "large" | "fullscreen";

  title?: string;
  message?: string;

  organizations: any[];
  onClose: () => void;
  okHandler: (selectedPage: any) => void;
  rejectHandler: () => void;
}
type Props = OwnProps

const LinkedInOrganizationModalFC: React.FC<Props> = ({
  openConfirmationModal,
  size = "mini",
  message,
  organizations,
  onClose,
  okHandler,
  rejectHandler,
 
}) => {
  const [selectedOrganization, setSelectedOrganization] = React.useState<any>(undefined);
  const { formatMessage } = useIntl();
  const headerLabel = formatMessage({
    ...descriptors[LIOrganizationModalType.selectYourLIOrganization],
  });

  React.useEffect(() => {
    setSelectedOrganization(undefined);
  }, []);

  const closeAction = (): void => {
    setSelectedOrganization(undefined);
    onClose();
  };

  const rejectAction = (): void => {
    setSelectedOrganization(undefined);
    rejectHandler();
  };
  return (
    <Modal
      key={"organization-modal-LI"}
      open={openConfirmationModal}
      onClose={closeAction}
      className={"confirmationModal"}
      size={size}>
      <Modal.Header>{headerLabel}</Modal.Header>

      {message && <Modal.Description>{message}</Modal.Description>}

      <Modal.Content>
        <PlayerOrganizationList organizations={organizations} selectedOrganization={setSelectedOrganization} />
      </Modal.Content>
      <Modal.Actions>
        <Button
          data-elm-id={`linkedinOrganizationModalNoBtn`}
          className={"secondary rounded padded"}
          onClick={rejectAction}>
          {formatMessage({ ...descriptors[LIOrganizationModalType.noBtn] })}
        </Button>
        <Button
          className={"primary rounded"}
          data-elm-id={`linkedInOrganizationModalYesBtn`}
          onClick={() => {
            if (selectedOrganization) {
              okHandler(selectedOrganization);
            } else {
              alert("You need to select an organization");
            }
          }}>
          {formatMessage({ ...descriptors[LIOrganizationModalType.yesBtn] })}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const LinkedInOrganizationModal = LinkedInOrganizationModalFC
