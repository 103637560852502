import * as React from "react";
import { Loader } from "semantic-ui-react";

import * as DTO from "../../../../../../../../../interfaces";
import { CampaignServices } from "../../../../../../../../../services";

import { CategoryRow } from "../../../CategoryRow";

interface OwnProps {
  coachId: string;
  category: DTO.Category;
  campaignPrompts: DTO.SelectedCampaign;

  onAddPrompt: (prompt: DTO.CampaignResume) => void;
  onRemovePrompt: (prompt: DTO.CampaignResume) => void;
}

type Props = OwnProps;

const CampaignByCategoryFC: React.FC<Props> = ({
  category,
  coachId,
  campaignPrompts = {},
  onAddPrompt,
  onRemovePrompt,
}) => {
  const [categoryPrompts, setCategoryPrompts] = React.useState<DTO.Page<DTO.CampaignResume> | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    return function cleanup() {
      // Specify how to clean up after this effect:
    };
  }, []);

  React.useEffect(() => {
    async function initialLoad() {
      const { categoryId } = category;
      setLoading(true);
      const promptsResponse = await CampaignServices.publicCampaignsByCategory(coachId, categoryId).finally(() => {
        setLoading(false);
      });
      setCategoryPrompts(() => {
        return {
          ...promptsResponse,
        };
      });
    }

    if (category && coachId && !categoryPrompts) {
      initialLoad();
    }
  }, [coachId, category, categoryPrompts]);

  const loadMore = React.useCallback(
    async (nextPage: number) => {
      const { categoryId } = category;
      if (
        !loading &&
        ((categoryPrompts && categoryPrompts.totalPages && categoryPrompts.number! < categoryPrompts.totalPages!) ||
          !categoryPrompts)
      ) {
        setLoading(true);

        const promptsResponse = await CampaignServices.publicCampaignsByCategory(coachId, categoryId, nextPage);

        setLoading(false);
        setCategoryPrompts(cpages => {
          return {
            ...cpages,
            ...promptsResponse,
            content: promptsResponse.content,
          };
        });
      }
    },
    [coachId, category, loading]
  );

  const promptsFiltered = categoryPrompts ? categoryPrompts.content : [];

  return (
    <div>
      {loading && <Loader active />}
      {promptsFiltered.length > 0 && (
        <CategoryRow
          key={category.categoryId}
          name={category.name!}
          category={category}
          campaignList={promptsFiltered}
          totalElements={categoryPrompts?.totalElements}
          page={categoryPrompts?.number}
          pageable={true}
          firstPage={categoryPrompts?.first}
          latestPage={categoryPrompts?.last}
          campaignPrompts={campaignPrompts}
          onNext={loadMore}
          onAddPrompt={onAddPrompt}
          onRemovePrompt={onRemovePrompt}
        />
      )}
    </div>
  );
};

export const CampaignByCategory = React.memo(CampaignByCategoryFC);
