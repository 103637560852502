import { defineMessages } from "react-intl";

export enum DigestEmailType {
  title = "DigestEmailType.title",
  subtitle = "DigestEmailType.subtitle",
  emailSectionTitle = "DigestEmailType.emailSectionTitle",
  updateBtn = "DigestEmailType.updateBtn",
  tagsHeader = "DigestEmailType.tagsHeader",
  maximunEmailsError = "DigestEmailType.maximunEmailsError",
}

export const en = {
  [DigestEmailType.title]: "Get Digest Emails",
  [DigestEmailType.subtitle]: "",
  [DigestEmailType.emailSectionTitle]: "Emails",
  [DigestEmailType.updateBtn]: "Save",
  [DigestEmailType.tagsHeader]: "Tags",
  [DigestEmailType.maximunEmailsError]: "Only 100 emails allowed",
};

export const descriptors = defineMessages({
  [DigestEmailType.title]: {
    id: DigestEmailType.title,
    defaultMessage: en[DigestEmailType.title],
    description: "-",
  },
  [DigestEmailType.subtitle]: {
    id: DigestEmailType.subtitle,
    defaultMessage: en[DigestEmailType.subtitle],
    description: "-",
  },
  [DigestEmailType.emailSectionTitle]: {
    id: DigestEmailType.emailSectionTitle,
    defaultMessage: en[DigestEmailType.emailSectionTitle],
    description: "-",
  },
  [DigestEmailType.updateBtn]: {
    id: DigestEmailType.updateBtn,
    defaultMessage: en[DigestEmailType.updateBtn],
    description: "-",
  },
  [DigestEmailType.maximunEmailsError]: {
    id: DigestEmailType.maximunEmailsError,
    defaultMessage: en[DigestEmailType.maximunEmailsError],
    description: "-",
  },
});
