export const joditConfig: any = {
  zIndex: 0,
  readonly: false,
  activeButtonsInReadOnly: [],
  toolbarButtonSize: "small",
  theme: "default",
  saveModeInCookie: false,
  spellcheck: false,
  editorCssClass: false,
  // triggerChangeEvent: true,
  width: "auto",
  height: "auto",
  minHeight: 200,
  direction: "",
  language: "auto",
  debugLanguage: false,
  i18n: "en",
  tabIndex: 1,
  toolbar: true,
  enter: "P",
  // defaultMode: Jodit.MODE_WYSIWYG,
  showPlaceholder: true,
  placeholder: "Write Message", // formatMessage({ ...descriptors[PromptType.messagePlaceholder] }),
  useSplitMode: false,
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  removeButtons: [],
  disablePlugins: [],
  extraButtons: [],
  sizeLG: 900,
  sizeMD: 700,
  sizeSM: 500,
  buttons: ["bold", "italic", "underline", "link", "fullsize"],
  buttonsXS: ["bold", "italic", "underline", "link", "fullsize"],
  buttonsLG: ["bold", "italic", "underline", "link", "fullsize"],
  buttonsSM: ["bold", "italic", "underline", "link", "fullsize"],
  buttonsMD: ["bold", "italic", "underline", "link", "fullsize"],
  events: {},
  textIcons: false,
};

export const disableJoditConfig: any = {
  zIndex: 0,
  readonly: true,
  activeButtonsInReadOnly: [],
  toolbarButtonSize: "small",
  theme: "default",
  saveModeInCookie: false,
  spellcheck: false,
  editorCssClass: false,
  width: "auto",
  height: "auto",
  minHeight: 200,
  direction: "",
  language: "auto",
  debugLanguage: false,
  i18n: "en",
  tabIndex: 1,
  toolbar: true,
  enter: "P",
  showPlaceholder: true,
  placeholder: "Write Message",
  useSplitMode: false,
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  removeButtons: [],
  disablePlugins: [],
  extraButtons: [],
  sizeLG: 900,
  sizeMD: 700,
  sizeSM: 500,
  buttons: ["bold", "italic", "underline", "link", "fullsize"],
  buttonsXS: ["bold", "italic", "underline", "link", "fullsize"],
  buttonsLG: ["bold", "italic", "underline", "link", "fullsize"],
  buttonsSM: ["bold", "italic", "underline", "link", "fullsize"],
  buttonsMD: ["bold", "italic", "underline", "link", "fullsize"],
  events: {},
  textIcons: false,
};
