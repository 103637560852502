import * as React from "react";
import { useIntl } from "react-intl";
import { Button, Grid } from "semantic-ui-react";
import moment from "moment";

import { descriptors, RecordPageType } from "../../descriptors";
import { useMemo } from "react";

interface Props {
  recordingState: "toStart" | "Paused" | "Recording" | "Done";
  disabled: boolean;
  doneDisabled: boolean;
  startHandler: () => void;
  pauseHandler: () => void;
  resumeHandler: () => void;
  startOverHandler: () => void;
  doneHandler: () => void;
  secondsRecorded: number;
}

const dataElmId = "recordActionSection";

const RecordActionSectionFC: React.FC<Props> = ({
  recordingState,
  disabled,
  doneDisabled,
  startHandler,
  pauseHandler,
  resumeHandler,
  startOverHandler,
  doneHandler,
  secondsRecorded,
}) => {
  const { formatMessage } = useIntl();

  const isRecording = useMemo(() => {
    return recordingState === "Recording";
  }, [recordingState]);

  const recordingStatusLabel = useMemo(() => {
    return isRecording ? "Recording" : "Paused";
  }, [isRecording]);

  const recordAction = (): void => {
    switch (recordingState) {
      case "toStart":
        startHandler();
        break;
      case "Paused":
      case "Done":
        resumeHandler();
        break;
      case "Recording":
        pauseHandler();
        break;
    }
  };

  const startOverAction = (): void => {
    startOverHandler();
  };

  const doneRecordingAction = async () => {
    doneHandler();
  };

  const actionButtonLabel = useMemo(() => {
    switch (recordingState) {
      case "toStart":
        return "Start Recording";
      case "Paused":
      case "Done":
        return "Resume Recording";
      case "Recording":
        return "Stop Recording";
    }
  }, [recordingState]);

  return (
    <>
      <Grid.Column width={8} className="recordInfoColumn">
        <Grid.Row centered>
          <p>
            {formatMessage(
              { ...descriptors[RecordPageType.counterControl] },
              {
                status: recordingStatusLabel,
                time: moment.utc(secondsRecorded * 1000).format("mm:ss"),
              }
            )}
          </p>
          <Button
            disabled={disabled}
            primary={recordingState === "toStart" || recordingState === "Recording"}
            secondary={!(recordingState === "toStart" || recordingState === "Recording")}
            data-elm-id={`${dataElmId}ActionBtn`}
            onClick={recordAction}
          >
            {actionButtonLabel}
          </Button>
          <Button
            secondary
            data-elm-id={`${dataElmId}StartOverBtn`}
            className={"startOverBtn"}
            onClick={startOverAction}
            disabled={recordingState === "toStart" || isRecording}
          >
            {formatMessage({ ...descriptors[RecordPageType.startOverBtn] })}
          </Button>
        </Grid.Row>
      </Grid.Column>

      <Grid.Column width={4}>
        {(recordingState === "Paused" || recordingState === "Done") && (
          <Button
            primary
            className={"doneBtn"}
            data-elm-id={`${dataElmId}DoneBtn`}
            disabled={doneDisabled}
            onClick={doneRecordingAction}
          >
            {formatMessage({ ...descriptors[RecordPageType.doneBtn] })}
          </Button>
        )}
      </Grid.Column>
    </>
  );
};

export const RecordActionSection = RecordActionSectionFC;
