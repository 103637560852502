import { keys } from "lodash";
import * as Yup from "yup";
import { QuestionLimitsConfig } from "../../../interfaces";

export const validationSchemaBuilder = (
  questions: { [key: string]: string },
  questionsLimits: QuestionLimitsConfig,
  configQuestions: { [key: string]: string }
) => {
  return Yup.object().shape(
    keys(questions).reduce(
      (accum, questionKey) => ({
        ...accum,
        [questionKey]: Yup.number()
          .required(configQuestions[questionKey] + " is required")
          .lessThan(
            Number(Number(questionsLimits[questionKey]) + 1),
            configQuestions[questionKey] + " must be less than " + Number(Number(questionsLimits[questionKey]) + 1)
          )
          .moreThan(0, configQuestions[questionKey] + " must be greater than 0. "),
      }),
      {}
    )
  );
};
