import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useIntl } from "react-intl";
import { Grid, Loader, Message } from "semantic-ui-react";

import { PlayersPosting, SideBar, TitleSection } from "../../../components";
import { PromptPreviewObject, WebComponents, PostRequest, dateKey, timeKey } from "../../../interfaces";
import { AppContext } from "../../../providers";
import { PostServices, PromptServices } from "../../../services";
import {
  URLS,
  finishStep,
  coachWithWritePermission,
  promptSpecificDate,
  getSocialNetworkMessages,
} from "../../../utils";

import { HeaderActionButtons } from "./components/HeaderActionButtons";
import { PostForm } from "./components/PostForm";

import { PostPageType, descriptors } from "./descriptors";
import { WhiteContainer } from "../../../styling/baseStyle";
import "./styles.scss";

type Props = RouteComponentProps;

const PostFC: React.FC<Props> = ({ history, match: { params } }) => {
  const { formatMessage } = useIntl();
  const promptId = params["promptId"];

  const { userContext } = React.useContext(AppContext);
  const writePermission = coachWithWritePermission(WebComponents.LIBRARY, userContext);

  // Status properties
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [formErrorMessages, setFormErrorMessages] = React.useState<string[] | undefined>(undefined);
  const [erroCounter, setErrorCounter] = React.useState<number>(0);

  const [incomplete, setIncomplete] = React.useState(false);

  // Prompt info properties
  const [prompt, setPrompt] = React.useState<PromptPreviewObject | undefined>(undefined);
  const [postInfo, setPostInfo] = React.useState<any>({});

  // Selected players
  const [playerIds, setPlayerIds] = React.useState<number[]>([]);

  // Messages
  const titleMessage: string = formatMessage({
    ...descriptors[PostPageType.title],
  });

  const generalError = formatMessage({
    ...descriptors[PostPageType["segmentationRequired"]],
  });

  React.useEffect(() => {
    if (promptId) {
      setLoading(true);
      PromptServices.get(promptId)
        .then(response => {
          if (response.status !== "ACTIVE") {
            alert("Inactive prompt");
            history.push(URLS.coach.library);
          }
          setPrompt(response);
        })
        .catch(response => {
          if (response === "This prompt was deleted") {
            alert(response);
            history.push(URLS.coach.library);
          }
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      alert("Invalid Prompt");
      history.push(URLS.coach.library);
    }
  }, [promptId]);

  React.useEffect(() => {
    const { isAutoPost, socialNetworksCounter, deliveryMode } = postInfo;
    const scheduledIncomplete = deliveryMode === "SCHEDULED" && !(postInfo[dateKey] && postInfo[timeKey]);
    setIncomplete(playerIds.length < 1 || (isAutoPost && socialNetworksCounter < 1) || scheduledIncomplete);
  }, [playerIds, postInfo]);

  const createPost = async (): Promise<any> => {
    try {
      if (prompt) {
        const { message } = prompt;
        const { isAutoPost, deliveryMode, socialNetworks } = postInfo;
        const snFiltered = Object.keys(socialNetworks).filter(key => socialNetworks[key]);
        const socialNetworkMessages = getSocialNetworkMessages(snFiltered);

        let sheduleSection = {};
        if (deliveryMode === "SCHEDULED") {
          sheduleSection = {
            scheduledDatetimeUtc:
              postInfo[dateKey] && postInfo[timeKey]
                ? promptSpecificDate(postInfo[dateKey], postInfo[timeKey])!
                : undefined,
          };
        }

        const postRequest: PostRequest = {
          ...sheduleSection,
          sendMode: deliveryMode,
          coachId: `${userContext!!.coach!!.id!!}`,
          promptId,
          playerIds,
          messageToPost: message || "",
          socialNetworkMessages,
          isAutoPost,
        };

        setLoading(true);
        setError(false);
        setFormErrorMessages(undefined);

        await PostServices.create(postRequest)
          .then(() => {
            history.push(URLS.coach.library);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } catch (e) {
      setFormErrorMessages(["Unable to complete your post. Please try again."]);
      if (erroCounter < 2) {
        alert("Unable to complete your post. Please try again.");
        setErrorCounter(value => value + 1);
      } else {
        alert(
          "We are unable to complete your post at this time. Please reach out to support@socialcoach.io for support."
        );
        setErrorCounter(0);
      }
    }
  };

  const cancelAction = () => {
    history.goBack();
  };

  return (
    <WhiteContainer>
      <SideBar history={history} readyToContinue={!writePermission} />
      {loading || prompt === undefined ? (
        <Loader active size="large" />
      ) : (
        <Grid columns={1} className={"contentSection"}>
          <TitleSection
            title={titleMessage}
            ready={finishStep(userContext?.signUpStep)}
            showLinkOption={true}
            customLinkSection={<HeaderActionButtons goBack={cancelAction} />}
            customNextLabel={formatMessage({ ...descriptors[PostPageType.postBtn] })}
            disableButtons={incomplete}
            titleSize={8}
            buttonsSize={8}
            nextButtonSize={8}
            handleNextAction={createPost}
          />
          {error && <Message error content={generalError} />}

          <Grid.Row columns={1} className={"formSection coachBaseContainer"}>
            <PostForm prompt={prompt} updatingHandler={setPostInfo} pageErrors={formErrorMessages} />

            <PlayersPosting disabled={false} selectedPlayers={playerIds.concat([])} onChange={setPlayerIds} />
          </Grid.Row>
        </Grid>
      )}
    </WhiteContainer>
  );
};

export const PostPage = withRouter(PostFC);
