import * as React from "react";
import{ useIntl } from "react-intl";

import * as DTO from "../../../interfaces";
import { printOnlyDate, createVideoThumbnailBlob } from "../../../utils";

import { descriptors, SelectedCardType } from "./descriptors";

import { ThumbnailPreview, PromptCardStyle, PromptHeader, SelectPrompt } from "./styled";
import "./styles.scss";

interface OwnProps {
  title: string;
  creationDate?: string;
  post?: DTO.Media;
  selected?: boolean;
  editable: boolean;
  onChange?: (checked: boolean) => void;
}

type Props = OwnProps 

const SelectableCardFC: React.FC<Props> = ({
  title,
  creationDate,
  post,
  selected = false,
  editable = true,
 
  onChange,
}: Props) => {
  const [thumbnail, setThumbnail] = React.useState<string | undefined>();
  const [isSelected, setIsSelected] = React.useState<boolean>(selected);
  const { formatMessage } = useIntl();
  React.useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  React.useEffect(() => {
    if (post && post?.uri) {
      const creativeUrl = post?.uri;
      const urlPromise =
        post?.category === "IMAGE" ? Promise.resolve(creativeUrl) : createVideoThumbnailBlob(creativeUrl);
      urlPromise.then(url => {
        setThumbnail(url);
      });
    }
  }, [post]);

  return (
    <PromptCardStyle
      className={isSelected ? "promptSelectableCard cardSelected" : "promptSelectableCard"}
      selected={isSelected}
      onClick={() => {
        if (editable && onChange) {
          setIsSelected(!isSelected);
          onChange(!isSelected);
        }
      }}>
      <ThumbnailPreview width={3} thumbnailurl={thumbnail} />

      {editable && onChange && (
        <SelectPrompt
          radio
          checked={isSelected}
          onClick={() => {
            setIsSelected(!isSelected);
            onChange!(!isSelected);
          }}
        />
      )}
      <PromptCardStyle.Content>
        <PromptHeader>{title}</PromptHeader>
        <PromptCardStyle.Description>
          {formatMessage({ ...descriptors[SelectedCardType.createdTitle] }) + printOnlyDate(creationDate!!)}
        </PromptCardStyle.Description>
      </PromptCardStyle.Content>
    </PromptCardStyle>
  );
};

export const SelectableCard = SelectableCardFC
