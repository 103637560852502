import React from "react";
import { DropdownItemProps, Grid, Header } from "semantic-ui-react";
import { CustomDropDownComponent } from "../../../CustomDropDown";

interface TiktokPrivacyLevelProps {
  privacyLevelSelected?: DropdownItemProps;
  privacyLevelOptions: DropdownItemProps[];
  onPressAction: (selected: string) => void;
}

export const TiktokPrivacyLevel: React.FC<TiktokPrivacyLevelProps> = ({
  privacyLevelSelected,
  privacyLevelOptions,
  onPressAction,
}: TiktokPrivacyLevelProps) => {
  return (
    <Grid.Row>
    <Grid>
      <Grid.Row>
        <Header as="h5">
          Privacy Settings
          {/* CustomText xsmall light gray style={styles.title} */}
        </Header>
      </Grid.Row>
      <Grid.Row>
          <CustomDropDownComponent
            defaultValue={privacyLevelSelected?.key}
            options={privacyLevelOptions}
            placeholder={privacyLevelSelected && privacyLevelSelected!.key !== "none" ? `${privacyLevelSelected!!.text}` : "Select a privacy setting"}
            multiple={false}
            clearable={false}
            onChangeHandler={onPressAction}
          />
      </Grid.Row>
    </Grid>
    </Grid.Row>
  );
};
