import * as React from "react";
import { RouteComponentProps, withRouter, StaticContext } from "react-router";
import { Segment, Image, Header, Grid, Button } from "semantic-ui-react";
import { URLS } from "../../utils/routes";
import "./styles.scss";
import { Png } from "../../assets/png";
import { FloatedContainer } from "../../styling/baseStyle";
import { ButtonLink } from "../../components";
// import { ButtonLink } from "../../components";

interface Props extends RouteComponentProps<any, StaticContext> {}

class HomeClass extends React.Component<Props> {
  handleSignIn = (): void => {
    const { history } = this.props;
    history.push(URLS.login);
  };

  handleSignUp = (): void => {
    const { history } = this.props;
    history.push(URLS.register);
  };

  render() {
    return (
      <FloatedContainer className={"homeContainer"}>
        <Grid.Column width={11} centered className={"columnContainer"}>
          <Segment padded={"very"} centered={"true"} className={"segmentContainer"}>
            <Grid centered={true} columns={2}>
              <Header as="h2" icon textAlign="center" className={"titleSection"}>
                <Image src={Png.Logo} size={"large"} />
                <Header.Content className={"subtitle"}>Welcome back</Header.Content>
              </Header>

              <Grid.Row centered columns={2}>
                <Grid.Column width={8}>
                  <Button
                    data-elm-id={`homeSignInBtn`}
                    className={"primary centered"}
                    fluid
                    size="medium"
                    type="submit"
                    onClick={this.handleSignIn}>
                    Sign In
                  </Button>
                </Grid.Column>
              </Grid.Row>

              <div className="buttons-link">
                <a id="home_contactUs" href="mailto:support@socialcoach.io">
                  Need help? Contact us here
                </a>
              </div>
            </Grid>
          </Segment>
        </Grid.Column>
      </FloatedContainer>
    );
  }
}

export const Home = withRouter(HomeClass);
