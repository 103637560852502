import * as React from "react";
import { Grid } from "semantic-ui-react";

import { HashTag } from "./HashTag";
import { TagElement } from "../../../../../interfaces";

import "./styles.scss";
import { removeWhiteSpace } from "../../../../../utils";
import { useMemo } from "react";

interface OwnProps {
  title: string;
  defaultHashtagList?: string[];
  onSuccess: (newTags: string[]) => void;
  addHashtagOnChange?: (checked: boolean) => void;
  customTagStyle: string;
}

type Props = OwnProps;

export const HashTagSet: React.FC<Props> = ({ title, defaultHashtagList, customTagStyle, onSuccess }) => {
  const hashTagsAsTags = (hashtags?: string[]): TagElement[] => {
    let hashtagsElements: TagElement[] = [];

    if (hashtags && hashtags.length > 0) {
      hashtagsElements = hashtags.map((hashtag, index) => {
        const tagElement: TagElement = {
          index,
          displayValue: removeWhiteSpace(hashtag.replace("#", "")),
          id: `${index}`,
        };
        return tagElement;
      });

      return hashtagsElements;
    }

    return hashtagsElements;
  };

  const tags = useMemo(() => {
    return hashTagsAsTags(defaultHashtagList);
  }, [defaultHashtagList]);

  return (
    <Grid className={"promptHashTagSetContainer"} data-elm-id={"promptHashtagsSet"}>
      <Grid.Row className={"hashTagSetTagRow"}>
        <HashTag
          dataElmId={"coachPromptHashtags"}
          placeholder={title}
          tagStyle={customTagStyle}
          tags={tags}
          onTagsChanged={newTags => {
            onSuccess(newTags.map(t => t.displayValue));
          }}
        />
      </Grid.Row>
    </Grid>
  );
};
