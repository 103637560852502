import { Plan } from "./Plan";

export enum CoachingProfileRequestType {
  FINAL = "FINAL",
  PREVIEW = "PREVIEW",
}

export interface CoachingProfileData {
  salesMessage: string;
  videoUrl?: string;
  bannerUrl?: string;
  sharedLinkId: string;
  pricePlans?: Plan[] | [];
}
