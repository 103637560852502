import * as React from "react";
import { Button, Grid, Input, Pagination, Table } from "semantic-ui-react";
import { PlayerSearchResponse } from "../../../interfaces";

import { AdminPlayerCell } from "../../Cells";

import "./styles.scss";

import { debounce } from "lodash";
import { SubscriptionServices } from "../../../services";
import { convertToAdminPlayerExport, exportAdminPlayersToCsv } from "../../../utils";

interface OwnProps {}

type Props = OwnProps;

const PlayersFC: React.FC<Props> = () => {
  const [players, setPlayers] = React.useState<PlayerSearchResponse[]>([]);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [pageNumber, setPageNumber] = React.useState<number>(0);
  const [userQuery, setUserQuery] = React.useState("");
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [exportLoading, setExportLoading] = React.useState<boolean>(false);

  const delayedQuery = React.useRef(debounce((q: string) => sendQuery(q), 500)).current;
  const onSearchChange = (e: any) => {
    setUserQuery(e.target.value);
    delayedQuery(e.target.value);
  };

  const getTableData = async () => {
    setIsLoading(true);
    const playersResponse = await SubscriptionServices.searchAllSubscriptions(searchTerm, pageNumber, 20);
    setPlayers(playersResponse.content);
    setTotalPages(playersResponse.totalPages!);
    setIsLoading(false);
  };

  React.useEffect(() => {
    getTableData().catch(console.error);
  }, [pageNumber, searchTerm]);

  const sendQuery = (query: string) => {
    setSearchTerm(query);
  };

  const exportPlayers = async () => {
    setExportLoading(true);
    let page = 0;
    let last = false;
    let allPlayers: PlayerSearchResponse[] = [];
    while (last === false) {
      const response = await SubscriptionServices.searchAllSubscriptions(searchTerm, page, 100);
      last = response.last || false;
      allPlayers = allPlayers.concat(response.content);
      page++;
    }

    const data = convertToAdminPlayerExport(allPlayers);
    exportAdminPlayersToCsv("SocialCoachPlayerList", data);
    setExportLoading(false);
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={6}>
          <Input
            loading={isLoading}
            data-elm-id="admin-all-players-text-search"
            value={userQuery}
            fluid
            icon="search"
            placeholder="Search by Name or Email..."
            onChange={onSearchChange}
          />
        </Grid.Column>
        <Grid.Column width={2}>
          <Button
            data-elm-id={`adminDashboardPlayerListExportBtn`}
            fluid
            className={"secondary rounded buttonLeft"}
            disabled={exportLoading}
            loading={exportLoading}
            onClick={exportPlayers}
          >
            Export
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Table striped celled compact="very" size="small" data-elm-id="admin-dashboard-coaches-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={3}>Player</Table.HeaderCell>
                <Table.HeaderCell width={1}>Subscription</Table.HeaderCell>
                <Table.HeaderCell width={3}>Coach</Table.HeaderCell>
                <Table.HeaderCell width={2}> Actions </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!isLoading &&
                players.map((player, index) => {
                  return <AdminPlayerCell key={"admin_player_cell_" + index} player={player} />;
                })}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid centered>
          <Pagination
            data-elm-id="admin-dashboard-paginator"
            defaultActivePage={1}
            boundaryRange={3}
            pointing
            secondary
            totalPages={totalPages}
            onPageChange={(_, data) => {
              setPageNumber(Number(data.activePage) - 1);
            }}
          />
        </Grid>
      </Grid.Row>
    </Grid>
  );
};

export const AdminPlayerList = PlayersFC;
