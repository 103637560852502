import * as Yup from "yup";
import { times } from "lodash";
import { getQuestionId } from ".";

export const validationSchemaBuilder = (total: number) =>
  Yup.object().shape(
    times(total).reduce(
      (accum, index) => ({
        ...accum,
        [getQuestionId(index)]: Yup.string()
          .trim()
          .required(`Question ${index + 1} is required`)
          .max(120, `Question ${index + 1} must be less than 120 characters`)
          .min(3, `Question ${index + 1} must be at least 3 characters long`),
      }),
      {}
    )
  );
