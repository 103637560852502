/* tslint:disable:no-namespace */
import axios from "axios";

namespace TwitterSC {
  const URL = "https://api.twitter.com/oauth/";

  export const requestAccessToken = (token: string, verifier: string): Promise<any> => {
    const accessParams = `access_token?oauth_token=${token}&oauth_verifier=${verifier}`;
    return axios({
      method: "post",
      url: URL + accessParams,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
    });
  };
  export const getUserInfo = (response: string): { [key: string]: string } => {
    const url = new URLSearchParams(response);
    return {
      oauth_token: url.get("oauth_token") || "",
      oauth_token_secret: url.get("oauth_token_secret") || "",
      screen_name: url.get("screen_name") || "",
    };
  };
}

export const Twitter = TwitterSC;
