import * as React from "react";
import { useIntl } from "react-intl";
import { Modal, Button } from "semantic-ui-react";
import { FacebookPagesList } from "./components/FacebookPagesList";

import { FBPagesModalType, descriptors } from "./descriptors";

import "./styles.scss";
import { FacebookPage } from "../../../interfaces";

interface OwnProps {
  openConfirmationModal: boolean;
  size?: "mini" | "tiny" | "small" | "large" | "fullscreen";

  title?: string;
  message?: string;

  pages: FacebookPage[];
  onClose: () => void;
  okHandler: (selectedPage?: FacebookPage) => void;
  rejectHandler: () => void;
}
type Props = OwnProps;

const FacebookPagesModalFC: React.FC<Props> = ({
  openConfirmationModal,
  size = "tiny",
  message,
  pages,
  onClose,
  okHandler,
  rejectHandler,
}) => {
  const [selectedPage, setSelectedPage] = React.useState<any>(undefined);
  const { formatMessage } = useIntl();

  const headerLabel = formatMessage({
    ...descriptors[FBPagesModalType.selectYourFBPage],
  });

  const closeAction = (): void => {
    setSelectedPage(undefined);
    onClose();
  };

  const rejectAction = (): void => {
    setSelectedPage(undefined);
    rejectHandler();
  };
  return (
    <Modal open={openConfirmationModal} onClose={closeAction} className={"fbPagesModal"} size={size}>
      <Modal.Header>{headerLabel}</Modal.Header>

      {message && <Modal.Description>{message}</Modal.Description>}

      <Modal.Content>
        <FacebookPagesList pages={pages} selectedPage={setSelectedPage} />
      </Modal.Content>
      <Modal.Actions>
        <Button data-elm-id={`facebookPageModalNoBtn`} className={"secondary rounded padded"} onClick={rejectAction}>
          {formatMessage({ ...descriptors[FBPagesModalType.noBtn] })}
        </Button>
        <Button
          className={"primary rounded"}
          data-elm-id={`facebookPageModalYesBtn`}
          disabled={!selectedPage}
          onClick={() => {
            if (selectedPage) {
              okHandler(selectedPage);
            } else {
              alert("You need to select a Facebook page");
            }
          }}
        >
          {formatMessage({ ...descriptors[FBPagesModalType.yesBtn] })}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const FacebookPagesModal = FacebookPagesModalFC;
