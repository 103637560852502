import { defineMessages } from "react-intl";

export enum PromptComponentType {
  welcomePrompt = "PromptComponentType.welcomePrompt",
  deliveryLabel = "PromptComponentType.deliveryLabel",
  randomDelivery = "PromptComponentType.randomDelivery",
  specificDate = "PromptComponentType.specificDate",
  library = "PromptComponentType.library",
  promptTitle = "PromptComponentType.promptTitle",
  promptMessage = "PromptComponentType.promptMessage",
  aiButton = "PromptComponentType.aiButton",
  promptHashtags = "PromptComponentType.promptHashtags",
  videoMessage = "PromptComponentType.videoMessage",
  attachDesc = "PromptComponentType.attachDesc",
  attachInfo = "PromptComponentType.attachInfo",
  reelsAttachInfo = "PromptComponentType.reelsAttachInfo",
  videoMessageInfo = "PromptComponentType.videoMessageInfo",
  hashtagsInfo = "PromptComponentType.hashtagsInfo",
  reelRadioLabel = "PromptComponentType.reelRadioLabel",
  feedRadioLabel = "PromptComponentType.feedRadioLabel",

  categoryTitle = "PromptComponentType.categoryTitle",
  datePlaceholder = "PromptComponentType.datePlaceholder",
  timePlaceholder = "PromptComponentType.timePlaceholder",
  titlePlaceholder = "PromptComponentType.titlePlaceholder",
  messagePlaceholder = "PromptComponentType.messagePlaceholder",
  hashtagsPlaceholder = "PromptComponentType.hashtagsPlaceholder",
  uploadFilePlaceholder = "PromptComponentType.uploadFilePlaceholder",
  uploadVideoPlaceholder = "PromptComponentType.uploadVideoPlaceholder",
  categoryPlaceholder = "PromptComponentType.categoryPlaceholder",

  saveBtn = "PromptComponentType.saveBtn",
  videoBtn = "PromptComponentType.videoBtn",
  uploadBtn = "PromptComponentType.uploadBtn",
  browseBtn = "PromptComponentType.browseBtn",
  cancelBtn = "PromptComponentType.cancel",
  enableBtn = "PromptComponentType.enable",
  addCategoryBtn = "PromptComponentType.addCategory",

  categoryInfo = "PromptComponentType.categoryInfo",
  categoryNotSaved = "PromptComponentType.categoryNotSaved",
}

export const en = {
  [PromptComponentType.datePlaceholder]: "Select a date",
  [PromptComponentType.timePlaceholder]: "Select a time",
  [PromptComponentType.deliveryLabel]: "Delivery mode",
  [PromptComponentType.welcomePrompt]: "Welcome Prompt",
  [PromptComponentType.randomDelivery]: "Random Delivery Date",
  [PromptComponentType.specificDate]: "Specific Date",
  [PromptComponentType.library]: "Library",

  [PromptComponentType.reelRadioLabel]: "Reel Prompt",
  [PromptComponentType.feedRadioLabel]: "Feed Prompt",

  [PromptComponentType.promptTitle]: "Prompt Title",
  [PromptComponentType.promptMessage]: "Prompt Caption",
  [PromptComponentType.promptHashtags]: "Hashtags",
  [PromptComponentType.videoMessage]: "Instructional Video (optional)",
  [PromptComponentType.videoMessageInfo]:
    "This video is meant to allow you to provide coaching and instructions about this prompt for your players. It will only be visible to them and not postable. Please keep it under 2 minutes. Landscape orientation is best.",
  [PromptComponentType.titlePlaceholder]: "Enter title",
  [PromptComponentType.messagePlaceholder]: "Write Message",
  [PromptComponentType.hashtagsPlaceholder]: "Write Hashtags",
  [PromptComponentType.uploadFilePlaceholder]: "Select a file",
  [PromptComponentType.uploadVideoPlaceholder]: "Select a video",
  [PromptComponentType.categoryPlaceholder]: "Add Category",
  [PromptComponentType.attachDesc]: "Image/Video for Post",
  [PromptComponentType.attachInfo]:
    "All videos and images must meet the following requirements:\n• File Type: .jpg/.png or .mp4\n• Aspect Ratio: 1:1, 4:5, 16:9, 1.91:1\n• Duration: 3–90 seconds\n• File Size: < 100MB",
  [PromptComponentType.reelsAttachInfo]:
    "All Reel files must meet the following requirements: \n• File Type: .mp4\n• Aspect Ratio: 9:16\n• Resolution: >/= 540 x 960 pixels (1080 x 1920 is recommended)\n• Frame Rate: 24–60 frames per second\n• Duration: 3–90 seconds\n• Bitrate: 25Mbps max\n• File Size: < 100MB",

  [PromptComponentType.hashtagsInfo]: "This section should only include hashtags relative to this post.", // . Please include a # symbol with each hashtag, and include a space between each of them.",
  [PromptComponentType.videoBtn]: "Add your Video Message",
  [PromptComponentType.uploadBtn]: "Upload",
  [PromptComponentType.browseBtn]: "Browse",
  [PromptComponentType.saveBtn]: "Done",
  [PromptComponentType.cancelBtn]: "Cancel",
  [PromptComponentType.enableBtn]: "Publish",
  [PromptComponentType.addCategoryBtn]: "Add Category",
  [PromptComponentType.aiButton]: "Use AI to help write your caption",

  [PromptComponentType.categoryTitle]: "Category",

  [PromptComponentType.categoryInfo]:
    "Assign a category to your prompt to keep your prompts organized within the Library",
  [PromptComponentType.categoryNotSaved]: 'Please click "Add Category" to save your category',
};

export const descriptors = defineMessages({
  [PromptComponentType.aiButton]: {
    id: PromptComponentType.aiButton,
    defaultMessage: en[PromptComponentType.aiButton],
    description: "-",
  },
  [PromptComponentType.reelRadioLabel]: {
    id: PromptComponentType.reelRadioLabel,
    defaultMessage: en[PromptComponentType.reelRadioLabel],
    description: "-",
  },
  [PromptComponentType.feedRadioLabel]: {
    id: PromptComponentType.feedRadioLabel,
    defaultMessage: en[PromptComponentType.feedRadioLabel],
    description: "-",
  },
  [PromptComponentType.reelsAttachInfo]: {
    id: PromptComponentType.reelsAttachInfo,
    defaultMessage: en[PromptComponentType.reelsAttachInfo],
    description: "-",
  },
  [PromptComponentType.categoryNotSaved]: {
    id: PromptComponentType.categoryNotSaved,
    defaultMessage: en[PromptComponentType.categoryNotSaved],
    description: "-",
  },
  [PromptComponentType.categoryInfo]: {
    id: PromptComponentType.categoryInfo,
    defaultMessage: en[PromptComponentType.categoryInfo],
    description: "-",
  },
  [PromptComponentType.deliveryLabel]: {
    id: PromptComponentType.deliveryLabel,
    defaultMessage: en[PromptComponentType.deliveryLabel],
    description: "-",
  },
  [PromptComponentType.datePlaceholder]: {
    id: PromptComponentType.datePlaceholder,
    defaultMessage: en[PromptComponentType.datePlaceholder],
    description: "-",
  },
  [PromptComponentType.timePlaceholder]: {
    id: PromptComponentType.timePlaceholder,
    defaultMessage: en[PromptComponentType.timePlaceholder],
    description: "-",
  },
  [PromptComponentType.welcomePrompt]: {
    id: PromptComponentType.welcomePrompt,
    defaultMessage: en[PromptComponentType.welcomePrompt],
    description: "-",
  },
  [PromptComponentType.randomDelivery]: {
    id: PromptComponentType.randomDelivery,
    defaultMessage: en[PromptComponentType.randomDelivery],
    description: "-",
  },
  [PromptComponentType.specificDate]: {
    id: PromptComponentType.specificDate,
    defaultMessage: en[PromptComponentType.specificDate],
    description: "-",
  },
  [PromptComponentType.library]: {
    id: PromptComponentType.library,
    defaultMessage: en[PromptComponentType.library],
    description: "-",
  },
  [PromptComponentType.promptTitle]: {
    id: PromptComponentType.promptTitle,
    defaultMessage: en[PromptComponentType.promptTitle],
    description: "-",
  },
  [PromptComponentType.promptMessage]: {
    id: PromptComponentType.promptMessage,
    defaultMessage: en[PromptComponentType.promptMessage],
    description: "-",
  },
  [PromptComponentType.promptHashtags]: {
    id: PromptComponentType.promptHashtags,
    defaultMessage: en[PromptComponentType.promptHashtags],
    description: "-",
  },
  [PromptComponentType.videoMessage]: {
    id: PromptComponentType.videoMessage,
    defaultMessage: en[PromptComponentType.videoMessage],
    description: "-",
  },
  [PromptComponentType.videoBtn]: {
    id: PromptComponentType.videoBtn,
    defaultMessage: en[PromptComponentType.videoBtn],
    description: "-",
  },
  [PromptComponentType.attachDesc]: {
    id: PromptComponentType.attachDesc,
    defaultMessage: en[PromptComponentType.attachDesc],
    description: "-",
  },
  [PromptComponentType.messagePlaceholder]: {
    id: PromptComponentType.messagePlaceholder,
    defaultMessage: en[PromptComponentType.messagePlaceholder],
    description: "-",
  },
  [PromptComponentType.hashtagsPlaceholder]: {
    id: PromptComponentType.hashtagsPlaceholder,
    defaultMessage: en[PromptComponentType.hashtagsPlaceholder],
    description: "-",
  },
  [PromptComponentType.titlePlaceholder]: {
    id: PromptComponentType.titlePlaceholder,
    defaultMessage: en[PromptComponentType.titlePlaceholder],
    description: "-",
  },
  [PromptComponentType.uploadFilePlaceholder]: {
    id: PromptComponentType.uploadFilePlaceholder,
    defaultMessage: en[PromptComponentType.uploadFilePlaceholder],
    description: "-",
  },
  [PromptComponentType.uploadVideoPlaceholder]: {
    id: PromptComponentType.uploadVideoPlaceholder,
    defaultMessage: en[PromptComponentType.uploadVideoPlaceholder],
    description: "-",
  },
  [PromptComponentType.uploadBtn]: {
    id: PromptComponentType.uploadBtn,
    defaultMessage: en[PromptComponentType.uploadBtn],
    description: "-",
  },
  [PromptComponentType.browseBtn]: {
    id: PromptComponentType.browseBtn,
    defaultMessage: en[PromptComponentType.browseBtn],
    description: "-",
  },
  [PromptComponentType.saveBtn]: {
    id: PromptComponentType.saveBtn,
    defaultMessage: en[PromptComponentType.saveBtn],
    description: "-",
  },
  [PromptComponentType.cancelBtn]: {
    id: PromptComponentType.cancelBtn,
    defaultMessage: en[PromptComponentType.cancelBtn],
    description: "-",
  },
  [PromptComponentType.enableBtn]: {
    id: PromptComponentType.enableBtn,
    defaultMessage: en[PromptComponentType.enableBtn],
    description: "-",
  },
  [PromptComponentType.categoryPlaceholder]: {
    id: PromptComponentType.categoryPlaceholder,
    defaultMessage: en[PromptComponentType.categoryPlaceholder],
    description: "-",
  },
  [PromptComponentType.addCategoryBtn]: {
    id: PromptComponentType.addCategoryBtn,
    defaultMessage: en[PromptComponentType.addCategoryBtn],
    description: "-",
  },
  [PromptComponentType.categoryTitle]: {
    id: PromptComponentType.categoryTitle,
    defaultMessage: en[PromptComponentType.categoryTitle],
    description: "-",
  },
  [PromptComponentType.videoMessageInfo]: {
    id: PromptComponentType.videoMessageInfo,
    defaultMessage: en[PromptComponentType.videoMessageInfo],
    description: "-",
  },
  [PromptComponentType.attachInfo]: {
    id: PromptComponentType.attachInfo,
    defaultMessage: en[PromptComponentType.attachInfo],
    description: "-",
  },
  [PromptComponentType.hashtagsInfo]: {
    id: PromptComponentType.hashtagsInfo,
    defaultMessage: en[PromptComponentType.hashtagsInfo],
    description: "-",
  },
});
