import * as React from "react";
import { useIntl } from "react-intl";
import { useFormikContext } from "formik";

import { BasicFormProps } from "../../../../../interfaces";
import { CaptionField, VideoPreviewActionable } from "../../../../../components";

import { PredictionsComponent } from "./components/PredictionsComponent";

import { BrandPostComponentType, descriptors } from "./descriptors";
import { ThumbnailPreview } from "./styled";
import "./styles.scss";

interface OwnProps extends BasicFormProps {
  errors?: string[];
  warnings?: string[];
  blockedNamesList: string[];
  flaggedNamesList: string[];

  /**
   * Handle datePickers changes
   */
  handleSetFieldValue?: (field: string, value: any) => void;
}

type Props = OwnProps;

/**
 * Represents a CaptionStep
 */
const CaptionStepFC: React.FC<Props> = ({
  blockedNamesList = [],
  flaggedNamesList = [],
  handleSetFieldValue,
  errors,
  warnings,
}) => {
  const { formatMessage } = useIntl();
  const formik = useFormikContext();
  const values: any = formik.values;

  const [showAIModal, setShowAIModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    const blocked = blockedWords(values.messageToPost).join(", ");
    const flagged = flaggedWords(values.messageToPost).join(", ");
    handleSetFieldValue!!("blockedWords", blocked);
    handleSetFieldValue!!("flaggedWords", flagged);
  }, [blockedNamesList, flaggedNamesList]);

  const blockedWords = (messageToPost: string): string[] => {
    const content: string[] = [];

    if (messageToPost && messageToPost.length > 0) {
      content.push(messageToPost);
    }
    return blockedNamesList.filter(blockedPhrase => {
      const blockedRegex = new RegExp("\\b" + blockedPhrase + "\\b", "i");
      return content.some(stringToCheck => {
        return blockedRegex.test(stringToCheck);
      });
    });
  };

  const flaggedWords = (messageToPost: string): string[] => {
    const content: string[] = [];

    if (messageToPost && messageToPost.length > 0) {
      content.push(messageToPost);
    }
    return flaggedNamesList.filter(flaggedPhrase => {
      const blockedRegex = new RegExp("\\b" + flaggedPhrase + "\\b", "i");
      return content.some(stringToCheck => {
        return blockedRegex.test(stringToCheck);
      });
    });
  };

  return (
    <>
      <div key={"rePostFormDiv"} className={"whiteFormDiv rePostInfo"}>
        {values.mediaGroup?.media && values.mediaGroup.type !== "IMAGE" ? (
          <VideoPreviewActionable videos={values.mediaGroup?.media!!} />
        ) : (
          <ThumbnailPreview thumbnailurl={values.thumbnail} />
        )}
        <CaptionField
          title={formatMessage({ ...descriptors[BrandPostComponentType.message] })}
          error={errors ? errors[0] : ""}
          warning={warnings ? warnings[0] : ""}
          messagePlaceholder={formatMessage({ ...descriptors[BrandPostComponentType.messagePlaceholder] })}
          aiButtonText={formatMessage({ ...descriptors[BrandPostComponentType.aiButton] })}
          currentValue={values.messageToPost}
          dataElmId="rePostCaption"
          disabled={false}
          handleSetFieldValue={(field, value) => {
            const blocked = blockedWords(value).join(", ");
            const flagged = flaggedWords(value).join(", ");
            handleSetFieldValue!!("blockedWords", blocked);
            handleSetFieldValue!!("flaggedWords", flagged);

            handleSetFieldValue!!(field, value);
          }}
          setShowAIModal={setShowAIModal}
          hashtags={""}
          id="messageToPost"
        />
        <br />
      </div>

      {showAIModal && (
        <PredictionsComponent
          opened={true}
          rejectHandler={() => {
            setShowAIModal(false);
          }}
          acceptHandler={prediction => {
            setShowAIModal(false);
            handleSetFieldValue!("messageToPost", prediction);
          }}
        />
      )}
    </>
  );
};

export const CaptionStep = CaptionStepFC;
