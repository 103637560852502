import * as React from "react";
import * as DTO from "../../interfaces";

import { CampaignListComponent } from "../../components/Lists/CampaignListComponent";

interface OwnProps {
  categories: DTO.Category[];
  coachId: string;
}

type Props = OwnProps

export const CampaignsSection = ({ categories, coachId }: Props) => {
  const uncategorized: DTO.Category = {
    categoryId: "uncategorized",
    name: "Uncategorized",
  };

  const unpublished: DTO.Category = {
    categoryId: "unpublished",
    name: "Unpublished",
  };

  const pending: DTO.Category = {
    categoryId: "pending",
    name: "Pendings",
  };

  return (
    <div className="promptListContainer">
      <CampaignListComponent coachId={coachId} key={"uncategorized"} category={uncategorized} />

      <CampaignListComponent coachId={coachId} key={"unpublished"} category={unpublished} />

      <CampaignListComponent coachId={coachId} key={"pending"} category={pending} />

      {categories.map(category => {
        return (
          <CampaignListComponent
            coachId={coachId}
            key={"campaign_list_c_" + category.categoryId}
            category={category}
            showError={error => {
              alert(error);
            }}
          />
        );
      })}
      {/* {showEmptySection && <EmptyView text={"You have no Library Prompts"} customIconImage={Svg.Library} />} */}
    </div>
  );
};
