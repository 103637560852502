import { defineMessages } from "react-intl";

export enum UserChangePasswordType {
  password = "UserChangePasswordType.password",
  confirmPassword = "UserChangePasswordType.confirmPassword",
  submit = "UserChangePasswordType.submit",

  passwordMin = "UserChangePasswordType.passwordMin",
  passwordWithNumber = "UserChangePasswordType.passwordNumber",
  passwordWithSpecialCharacter = "UserChangePasswordType.passwordSpecialCharacter",
  passwordWithCapital = "UserChangePasswordType.passwordCapital",
}

export const en = {
  [UserChangePasswordType.password]: "Current Password",
  [UserChangePasswordType.confirmPassword]: "New Password",
  [UserChangePasswordType.submit]: "Change",

  [UserChangePasswordType.passwordMin]: "Password must be at least 8 characters.",
  [UserChangePasswordType.passwordWithCapital]: "Password must be at least 1 capital letter.",
  [UserChangePasswordType.passwordWithNumber]: "Password must be at least 1 numeric value.",
  [UserChangePasswordType.passwordWithSpecialCharacter]: "Password must be at least 1 special character.",
};

export const descriptors = defineMessages({
  [UserChangePasswordType.password]: {
    id: UserChangePasswordType.password,
    defaultMessage: en[UserChangePasswordType.password],
    description: "Password string",
  },
  [UserChangePasswordType.confirmPassword]: {
    id: UserChangePasswordType.confirmPassword,
    defaultMessage: en[UserChangePasswordType.confirmPassword],
    description: "Password string Confirmation",
  },
  [UserChangePasswordType.submit]: {
    id: UserChangePasswordType.submit,
    defaultMessage: en[UserChangePasswordType.submit],
    description: "Button text",
  },

  [UserChangePasswordType.passwordMin]: {
    id: UserChangePasswordType.passwordMin,
    defaultMessage: en[UserChangePasswordType.passwordMin],
    description: "passwordMin",
  },
  [UserChangePasswordType.passwordWithCapital]: {
    id: UserChangePasswordType.passwordWithCapital,
    defaultMessage: en[UserChangePasswordType.passwordWithCapital],
    description: "passwordWithCapital text",
  },
  [UserChangePasswordType.passwordWithNumber]: {
    id: UserChangePasswordType.passwordWithNumber,
    defaultMessage: en[UserChangePasswordType.passwordWithNumber],
    description: "passwordWithNumber",
  },
  [UserChangePasswordType.passwordWithSpecialCharacter]: {
    id: UserChangePasswordType.passwordWithSpecialCharacter,
    defaultMessage: en[UserChangePasswordType.passwordWithSpecialCharacter],
    description: "passwordWithSpecialCharacter",
  },
});
