import * as React from "react";

import { SliderInput } from "../../../../../../../styling/baseStyle";

const dataElmId = "sliderComponent";

interface Props {
  min: number;
  max: number;
  name: string;
  step: number;
  value: string | number;
  onChange: (value: number) => void;
}
const SliderComponentFC: React.FC<Props> = ({ min, max, name, step, value, onChange }) => {
  return (
    <SliderInput
      data-elm-id={`${dataElmId}_${name}`}
      id={name + "_id"}
      type="range"
      min={min}
      max={max}
      name={name}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(Number(e.target.value));
      }}
      step={step}
      value={value}
    />
  );
};

export const SliderComponent = SliderComponentFC;
