import * as React from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";

import "./styles.scss";

const VideoThumbnail = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
const FullHeightImg = styled.img`
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  overflow: hidden;
`;
const CenteredIcon = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface VideoPreviewProps {
  thumbnailSrc?: string;
}

export class VideoPreview extends React.Component<VideoPreviewProps> {
  constructor(props: VideoPreviewProps) {
    super(props);
  }

  render() {
    return (
      <VideoThumbnail className="video-preview">
        <FullHeightImg src={this.props.thumbnailSrc} />
        <CenteredIcon>
          <Icon name="play" />
        </CenteredIcon>
      </VideoThumbnail>
    );
  }
}
