import * as React from "react";
import { ConfigProperties } from "../interfaces/customTypes/configProperties";
import defaultConfigProperties from "../defaultConfigProperties";

export const ConfigContext = React.createContext<ConfigProperties>(defaultConfigProperties);
export const ConfigContextProvider = ConfigContext.Provider;
export const ConfigContextConsumer = ConfigContext.Consumer;

export const FORM_STATE = {
    selectedIndex: 0,
    steps: {
      details: {
        valid: false,
        dirty: false,
        value: {
          name: "",
          dueDate: "",
        },
      },
      preferences: {
        valid: false,
        dirty: false,
        value: {
          receiveEmails: false,
          receiveNotifications: false,
        },
      },
    },
  };
  
  
  export const FormStateContext = React.createContext({
    form: FORM_STATE,
    setForm: (form: typeof FORM_STATE | ((form: typeof FORM_STATE) => typeof FORM_STATE)) => {},
  });
  
  export const FormStateContextProvider = FormStateContext.Provider;
  
  