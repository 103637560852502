import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Grid } from "semantic-ui-react";

import {
  TitleSection,
  AdminSideBar,
  AdminPlayerList,
} from "../../../components";

import { WhiteContainer } from "../../../styling/baseStyle";

import "./styles.scss";

type Props = RouteComponentProps;

const AdminAllPlayersListFC: React.FC<Props> = ({ history }) => {

  return (
    <WhiteContainer>
      <AdminSideBar history={history} />
      <div className={"hugePadding playerAdminList"}>
        <TitleSection
          title={"Players List"}
          ready={true}
          showNextOption={false}
          showLinkOption={true}
          customLinkSection={
            <></>
          }
        />
        <Grid.Row className={"coachBaseContainer"}>
          <Grid.Column>
            <AdminPlayerList />
          </Grid.Column>
        </Grid.Row>
      </div>
    </WhiteContainer>
  );
};

export const AdminAllPlayersList = AdminAllPlayersListFC;
