import { AccountResumen, PlayerAccount } from "../interfaces";

export function appUserContextToPlayerAccount(account: AccountResumen): PlayerAccount {
  return {
    userId: account.user.userId,
    playerId: account!!.player!!.id,
    username: account.user.username,
    email: account.user.email,
    firstName: account.profile.firstName,
    lastName: account.profile.lastName,
    phone: account.profile.phone,
    photoUrl: account.profile.photoUrl,
  };
}
