import { defineMessages } from "react-intl";

export enum VideoProjectComponentType {
  message = "VideoProjectComponentType.message",
  aiButton = "VideoProjectComponentType.aiButton",
  hashtags = "VideoProjectComponentType.hashtags",
  originalFilesLabel = "VideoProjectComponentType.originalFilesLabel",
  editedFilesLabel = "VideoProjectComponentType.editedFilesLabel",

  postSectionTitle = "VideoProjectComponentType.postSectionTitle",
  playerSectionTitle = "VideoProjectComponentType.playerSectionLabel",
  projectFilesTitle = "VideoProjectComponentType.projectFilesTitle",
  postInfoSectionTitle = "VideoProjectComponentType.postInfoSectionTitle",
  autoPost = "VideoProjectComponentType.autoPost",
  pushNotification = "VideoProjectComponentType.pushNotification",

  allowHashtags = "VideoProjectComponentType.allowHashtags",
  allowShortLink = "VideoProjectComponentType.allowShortLink",
  allowComplianceDisclaimer = "VideoProjectComponentType.allowComplianceDisclaimer",
  postStatusLabel = "VideoProjectComponentType.postStatusLabel",

  datePlaceholder = "VideoProjectComponentType.datePlaceholder",
  timePlaceholder = "VideoProjectComponentType.timePlaceholder",
}

export const en = {
  [VideoProjectComponentType.originalFilesLabel]: "Original Files",
  [VideoProjectComponentType.editedFilesLabel]: "Final Edit",
  [VideoProjectComponentType.message]: "Caption",
  [VideoProjectComponentType.aiButton]: "Generate Caption",
  [VideoProjectComponentType.hashtags]: "Hashtags",
  [VideoProjectComponentType.postSectionTitle]: "Posting Preferences",
  [VideoProjectComponentType.playerSectionTitle]: "Client Info",
  [VideoProjectComponentType.projectFilesTitle]: "Project Files",
  [VideoProjectComponentType.postInfoSectionTitle]: "Post Info",

  [VideoProjectComponentType.autoPost]: "Auto Post",
  [VideoProjectComponentType.pushNotification]: "Push Notification",

  [VideoProjectComponentType.datePlaceholder]: "Select a date",
  [VideoProjectComponentType.timePlaceholder]: "Select a time",

  [VideoProjectComponentType.allowShortLink]: "Include player's short link",
  [VideoProjectComponentType.allowComplianceDisclaimer]: "Include Compliance Disclaimer",
  [VideoProjectComponentType.allowHashtags]: "Include player's hashtags",

  [VideoProjectComponentType.postStatusLabel]: "Social Networks",
};

export const descriptors = defineMessages({
  [VideoProjectComponentType.aiButton]: {
    id: VideoProjectComponentType.aiButton,
    defaultMessage: en[VideoProjectComponentType.aiButton],
    description: "-",
  },
  [VideoProjectComponentType.autoPost]: {
    id: VideoProjectComponentType.autoPost,
    defaultMessage: en[VideoProjectComponentType.autoPost],
    description: "-",
  },
  [VideoProjectComponentType.pushNotification]: {
    id: VideoProjectComponentType.pushNotification,
    defaultMessage: en[VideoProjectComponentType.pushNotification],
    description: "-",
  },
  [VideoProjectComponentType.editedFilesLabel]: {
    id: VideoProjectComponentType.editedFilesLabel,
    defaultMessage: en[VideoProjectComponentType.editedFilesLabel],
    description: "-",
  },
  [VideoProjectComponentType.allowHashtags]: {
    id: VideoProjectComponentType.allowHashtags,
    defaultMessage: en[VideoProjectComponentType.allowHashtags],
    description: "-",
  },
  [VideoProjectComponentType.datePlaceholder]: {
    id: VideoProjectComponentType.datePlaceholder,
    defaultMessage: en[VideoProjectComponentType.datePlaceholder],
    description: "-",
  },
  [VideoProjectComponentType.timePlaceholder]: {
    id: VideoProjectComponentType.timePlaceholder,
    defaultMessage: en[VideoProjectComponentType.timePlaceholder],
    description: "-",
  },
  [VideoProjectComponentType.allowShortLink]: {
    id: VideoProjectComponentType.allowShortLink,
    defaultMessage: en[VideoProjectComponentType.allowShortLink],
    description: "-",
  },
  [VideoProjectComponentType.allowComplianceDisclaimer]: {
    id: VideoProjectComponentType.allowComplianceDisclaimer,
    defaultMessage: en[VideoProjectComponentType.allowComplianceDisclaimer],
    description: "-",
  },
  [VideoProjectComponentType.postSectionTitle]: {
    id: VideoProjectComponentType.postSectionTitle,
    defaultMessage: en[VideoProjectComponentType.postSectionTitle],
    description: "-",
  },
  [VideoProjectComponentType.playerSectionTitle]: {
    id: VideoProjectComponentType.playerSectionTitle,
    defaultMessage: en[VideoProjectComponentType.playerSectionTitle],
    description: "-",
  },
  [VideoProjectComponentType.projectFilesTitle]: {
    id: VideoProjectComponentType.projectFilesTitle,
    defaultMessage: en[VideoProjectComponentType.projectFilesTitle],
    description: "-",
  },
  [VideoProjectComponentType.postInfoSectionTitle]: {
    id: VideoProjectComponentType.postInfoSectionTitle,
    defaultMessage: en[VideoProjectComponentType.postInfoSectionTitle],
    description: "-",
  },
  [VideoProjectComponentType.message]: {
    id: VideoProjectComponentType.message,
    defaultMessage: en[VideoProjectComponentType.message],
    description: "-",
  },
  [VideoProjectComponentType.hashtags]: {
    id: VideoProjectComponentType.hashtags,
    defaultMessage: en[VideoProjectComponentType.hashtags],
    description: "-",
  },
  [VideoProjectComponentType.originalFilesLabel]: {
    id: VideoProjectComponentType.originalFilesLabel,
    defaultMessage: en[VideoProjectComponentType.originalFilesLabel],
    description: "-",
  },
  [VideoProjectComponentType.postStatusLabel]: {
    id: VideoProjectComponentType.postStatusLabel,
    defaultMessage: en[VideoProjectComponentType.postStatusLabel],
    description: "-",
  },
});
