import { defineMessages } from "react-intl";

export enum VerificationCoachType {
  title = "VerificationCoachType.title",
  subtitle = "VerificationCoachType.subtitle",
}

export const en = {
  [VerificationCoachType.title]: "Account successfully verified",
  [VerificationCoachType.subtitle]: "Your user was confirmed ",
};

export const descriptors = defineMessages({
  [VerificationCoachType.title]: {
    id: VerificationCoachType.title,
    defaultMessage: en[VerificationCoachType.title],
    description: "title",
  },
  [VerificationCoachType.subtitle]: {
    id: VerificationCoachType.subtitle,
    defaultMessage: en[VerificationCoachType.subtitle],
    description: "Subtitle",
  },
});
