import * as React from "react";
import{ useIntl } from "react-intl";
import { Grid, Image } from "semantic-ui-react";

import * as DTO from "../../../interfaces";
import { printDatePST, printDate, future } from "../../../utils";

import { descriptors, PromptsType } from "./descriptors";
import "./styles.scss";

interface OwnProps {
  prompt: DTO.Prompt;
}

type Props = OwnProps 

const PromptCellFC: React.FC<Props> = ({
  prompt: { promptId, title, deliveryMode, status, creationDate, deliveryDatetimeUtc },
 
}: Props) => {
  const welcomePrompt = deliveryMode === "WELCOME_PROMPT";
  const { formatMessage } = useIntl();
  const getPromptType = (): string => {
    switch (deliveryMode) {
      case "SPECIFIC_DATE":
        return formatMessage({
          ...descriptors[
            PromptsType[
              deliveryDatetimeUtc && future(deliveryDatetimeUtc!) ? "specificDateTitleFuture" : "specificDateTitlePass"
            ]
          ],
        });
      case "WELCOME_PROMPT":
        return formatMessage({ ...descriptors[PromptsType.welcomePromptTitle] });
      default:
        return formatMessage({ ...descriptors[PromptsType.randomTitle] });
    }
  };

  const promptStatus = (): string => {
    return status === "ACTIVE" ? "Published" : " Unpublished ";
  };

  return (
    <Grid
      columns={3}
      data-elm-id="promptListElement"
      key={promptId}
      href={`/prompt/${promptId}`}
      className="promptListBody welcomeCell">
      <Grid.Column width={10} floated="left">
        <Grid.Row className={"promptHeader"}>
          {formatMessage({ ...descriptors[PromptsType.createdTitle] }) + printDate(creationDate!!)}
        </Grid.Row>
        <Grid.Row className={"smallTitle"}>{title}</Grid.Row>
      </Grid.Column>

      <Grid.Column width={welcomePrompt ? 5 : 6} floated="right">
        <Grid.Row className={"promptHeader status"}>{promptStatus()}</Grid.Row>

        <Grid.Row className={"promptHeader"}>
          {getPromptType()}
          {deliveryMode === "SPECIFIC_DATE" && deliveryDatetimeUtc && ` ${printDatePST(deliveryDatetimeUtc)}`}
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
};

export const PromptCell = PromptCellFC
