import * as React from "react";
import { Dropdown, Menu, DropdownItemProps, DropdownProps } from "semantic-ui-react";

import "../../styles.scss";

interface Props {
  options: DropdownItemProps[];
  placeholder: string;
  multiple?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  value?: any;
  onChangeHandler: (data: any) => void;
}

const PlayerDropDownFC: React.FC<Props> = ({
  options,
  placeholder,
  multiple = false,
  clearable = false,
  disabled = false,
  value,
  onChangeHandler,
}) => {
  const onChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps): void => {
    onChangeHandler(`${data.value}`);
  };
  const props = {
    className: "filterByInput",
    search: false,
    selection: true,
    fluid: true,
    multiple,
    clearable,
    labeled: true,
    disabled,
    placeholder,
    "data-elm-id": `player_dropdown`,
  };
  return (
    <Menu.Item>
      <Dropdown {...props} value={value} options={options} onChange={onChange} />
    </Menu.Item>
  );
};

export const PlayerDropDown = PlayerDropDownFC;
