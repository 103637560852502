import * as React from "react";
import{ useIntl } from "react-intl";

import * as DTO from "../../../interfaces";
import { Rules } from "../../../utils";

import {
  ContainerGrid,
  ContainerContent,
  PreviewInput,
  ThumbnailPreview,
  SelectRadio,
  DeleteIcon,
  CategoriesDropDown,
} from "./styled";
import { DropdownItemProps } from "semantic-ui-react";

/**
 * Prompt Preview for upload in bulk.
 * It includes radio button + prompt image + category dropdown + title +  delete button
 */
interface OwnProps {
  /**
   * Prompt information
   */
  prompt: DTO.PromptPreview;
  /**
   * List of coach categories
   */
  categories: DTO.Category[];

  /**
   * Called when some property change
   */
  onChange(prompt: DTO.PromptPreview): void;
  onDelete(uid: string): void;
}

type Props = OwnProps 

const PromptPreview: React.FC<Props> = ({ prompt, categories, onDelete, onChange }) => {
  const [title, setTitle] = React.useState<string>(prompt.title);
  const [categoryIds, setCategoryIds] = React.useState<string[] | undefined>(prompt.categoryIds);
  const [selected, changeSelected] = React.useState<boolean>(prompt?.selected || false);
  const [withError, setWithError] = React.useState<boolean>(prompt?.withError || false);

  const categoryOptions: DropdownItemProps[] = categories.map(cat => ({
    key: cat.categoryId,
    text: cat.name,
    value: cat.categoryId,
  }));

  React.useEffect(() => {
    changeSelected(prompt?.selected || false);
  }, [prompt]);

  React.useEffect(() => {
    setCategoryIds(prompt.categoryIds);
  }, [prompt.categoryIds]);

  React.useEffect(() => {
    onChange({ ...prompt, title, selected, categoryIds, withError });
  }, [categoryIds, selected]);

  return (
    <ContainerGrid color={"white"}>
      <ContainerContent width={1}>
        <SelectRadio
          data-elm-id={"promptPreviewSelectRadio"}
          checked={selected}
          onChange={() => {
            changeSelected(!selected);
          }}
        />
      </ContainerContent>

      <ThumbnailPreview width={4} thumbnailurl={prompt.thumbnail} />
      <ContainerContent width={5}>
        <CategoriesDropDown
          search
          selection
          data-elm-id={"promptPreviewCategoriesDropDown"}
          placeholder={"Category"}
          className="searchInput"
          multiple={false}
          onChange={(e: any, data: { value: string }) => {
            if (data.value !== "") {
              setCategoryIds(() => [data.value as string]);
            } else {
              setCategoryIds([]);
            }
          }}
          value={categoryIds ? categoryIds[0] : null}
          clearable
          options={categoryOptions}
        />
      </ContainerContent>
      <ContainerContent width={6}>
        <PreviewInput
          key={"promptPreviewTitleInput"}
          data-elm-id={"promptPreviewInputTitle"}
          fluid
          id={"title"}
          type={"text"}
          name={"title"}
          placeholder={"Add title"}
          onChange={(e: any) => {
            let error = false;
            if (e.target.value.length === 0) {
              alert("Title is required!");
              error = true;
            }
            if (e.target.value.length < Rules.MAX_TITLE) {
              setTitle(e.target.value);
              setWithError(error);
              onChange({ ...prompt, title: e.target.value, categoryIds, withError: error });
            }
          }}
          value={prompt.title}
        />
      </ContainerContent>
      <ContainerContent width={1}>
        <DeleteIcon
          data-elm-id={"promptPreviewDeleteIcon"}
          name="trash alternate outline"
          onClick={() => onDelete(prompt.uid)}
        />
      </ContainerContent>
    </ContainerGrid>
  );
};

export const PromptPreviewCell = PromptPreview
