import * as React from "react";
import {Grid, Button, Icon, Popup} from "semantic-ui-react";
import copy from "copy-to-clipboard";

import { KeywordTag } from "./KeywordTag";
import { KeywordsType, TagElement } from "../../../../interfaces";

import "./styles.scss";

interface OwnProps {
  title: string;
  tooltip: string;
  type: KeywordsType;
  keywords: TagElement[];
  unavailableKeywords: string[];
  customTagStyle?: string;

  onError: (keywords: string[]) => void;
  onSuccess: (newTags: TagElement[]) => void;
}

type Props = OwnProps;

export const KeywordSet: React.FC<Props> = ({
  title,
  tooltip,
  type,
  keywords,
  unavailableKeywords,
  customTagStyle = "",
  onError,
  onSuccess,
}) => {
  const validateNewInput = (newValues: TagElement[]): void => {
    onSuccess(newValues);
  };

  const copyKeywords = (): void => {
    copy(
      keywords
        .map(keyword => {
          return keyword.displayValue;
        })
        .toString()
    );
  };

  return (
    <Grid className={"keyboardSetContainer"}>
      <Grid.Row>
        <Grid.Column width={7} className={"noPadding keywordSetTitle"}>
          <div className={"dot " + type.toLocaleLowerCase()} />
          {title}
          <Popup trigger={<Icon name="info circle" className={"infoIcon"} link />}>
            {tooltip}
          </Popup>
        </Grid.Column>
        <Grid.Column width={7} className={"noPadding keywordCopyLabel"} floated={"right"}>
          {keywords.length > 0 && (
            <span
              onClick={copyKeywords}
            >
              {`Copy ${keywords.length} Keyword${keywords.length > 1 ? 's' : ''}`}
              <Icon name={"copy outline"} className={"keywordCopyIcon"}/>
            </span>
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className={"keywordSetTagRow"}>
        <KeywordTag
          dataElmId={title + "complianceTags"}
          placeholder={title + " Keywords"}
          tags={keywords}
          unavailableTags={unavailableKeywords}
          onTagsChanged={validateNewInput}
          onInputRepeatedError={onError}
          tagStyle={customTagStyle}
          wrapperStyle={"width: 100%; height: 300px;"}
        />
      </Grid.Row>
    </Grid>
  );
};
