import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { debounce } from "lodash";
import { Grid, Table, Pagination, Input, Dropdown, Checkbox, Button } from "semantic-ui-react";

import { UsersService, SocialCoachSessionService } from "../../../services";
import * as DTO from "../../../interfaces";
import { exportCoachesList, exportCoachesToCsv, URLS } from "../../../utils";

import { selectOptions } from "./filters";
import { CoachCell } from "./CoachCell";
import { ExportCoachObject } from "../../../interfaces";

const searchAccountPage = (
  pageNumber: number = 0,
  searchTerm: string = "",
  coachStatuses?: DTO.CoachStatuses[],
  size: number = 20,
  hideDemoAccounts: boolean = false
) =>
  UsersService.searchAccounts(
    {
      profileTypes: [DTO.UserGroups.COACH],
      searchTerm,
      coachStatuses,
      hideDemoAccounts,
    },
    pageNumber,
    size
  );

interface OwnProps {}

type Props = OwnProps & RouteComponentProps;

const CoachListFC: React.FC<Props> = ({ history }) => {
  const [coaches, setCoaches] = React.useState<DTO.CompleteAccount[]>([]);
  const [coachesStatsById, setCoachesStatsById] = React.useState<{ [key: string]: DTO.CoachStatistic }>({});
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [pageNumber, setPageNumber] = React.useState<number | undefined>();
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [exportLoading, setExportLoading] = React.useState<boolean>(false);
  const [hideDemoAccounts, setHideDemoAccounts] = React.useState<boolean>(false);
  const [userQuery, setUserQuery] = React.useState("");

  const [statuses, setStatuses] = React.useState<DTO.CoachStatuses[]>([
    DTO.CoachStatuses.PENDING_FOR_APPROVAL,
    DTO.CoachStatuses.ACTIVE,
  ]);

  const coachesIds = coaches.map(account => {
    return account.coach?.id;
  }) as string[];

  const getTableData = async () => {
    setIsLoading(true);
    const coachStatus = statuses.length > 0 ? statuses : undefined;
    const accountsPage = await searchAccountPage(pageNumber, searchTerm, coachStatus, 20, hideDemoAccounts);
    setCoaches(accountsPage.content);
    setTotalPages(accountsPage.totalPages!);
    setIsLoading(false);
  };

  const exportCoaches = async () => {
    setExportLoading(true);
    let page = 0;
    let last = false;
    let totalResult: any[] = [];
    const coachStatus = statuses.length > 0 ? statuses : undefined;
    while (last === false) {
      const response = await searchAccountPage(page, searchTerm, coachStatus, 200, hideDemoAccounts);
      last = response.last || false;
      totalResult = totalResult.concat(response.content);
      page++;
    }

    const data: ExportCoachObject[] = exportCoachesList(totalResult);
    exportCoachesToCsv("Admin dashboard", data);
    setExportLoading(false);
  };

  const searchQuery = async () => {
    setIsLoading(true);
    const coachStatus = statuses.length > 0 ? statuses : undefined;
    const accountsPage = await searchAccountPage(pageNumber, userQuery, coachStatus, 20, hideDemoAccounts);
    setCoaches(accountsPage.content);
    setTotalPages(accountsPage.totalPages!);
    setIsLoading(false);
  };

  const getStatistics = async () => {
    if (coachesIds.length > 0) {
      const statistics = await UsersService.searchCoachesStatistics(coachesIds);
      const statisticsById = {};
      statistics.forEach(coachStat => {
        statisticsById[coachStat.coachId] = coachStat;
      });

      setCoachesStatsById(statisticsById);
    }
  };

  const impersonateCoach = async (username: string) => {
    // Set coach auth cookie
    await SocialCoachSessionService.impersonate(username);
    // Redirect to coach dashboard
    window.location.assign(URLS.coach.dashboard);
  };

  React.useEffect(() => {
    getTableData();
  }, [pageNumber, searchTerm, statuses, hideDemoAccounts]);

  const sendQuery = (query: string) => {
    setSearchTerm(query);
  };

  const delayedQuery = React.useRef(debounce((q: string) => sendQuery(q), 500)).current;

  const onChange = (e: any) => {
    setUserQuery(e.target.value);
    delayedQuery(e.target.value);
  };

  React.useEffect(() => {
    getStatistics();
  }, [coaches]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={4}>
          <Input
            loading={isLoading}
            data-elm-id="admin-dashboard-text-search"
            value={userQuery}
            fluid
            icon="search"
            placeholder="Search Anything..."
            onChange={onChange}
          />
        </Grid.Column>
        <Grid.Column width={5}>
          <Dropdown
            onChange={(_, data) => {
              const value = data.value as DTO.CoachStatuses[];
              setStatuses(value);
            }}
            placeholder="Select a Status"
            fluid
            multiple
            data-elm-id="admin-dashboard-status-selector"
            selection
            value={statuses}
            options={selectOptions}
          />
        </Grid.Column>
        <Grid.Column width={3}>
          <Checkbox
            label="Show demo/test accounts"
            defaultChecked
            onChange={(data, e) => {
              setHideDemoAccounts(!e.checked);
            }}
          />
        </Grid.Column>
        <Grid.Column width={2}>
          <Button
            data-elm-id={`adminDashboardPlayerListExportBtn`}
            fluid
            className={"secondary rounded buttonLeft"}
            disabled={exportLoading}
            loading={exportLoading}
            onClick={exportCoaches}
          >
            Export
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Table striped celled compact="very" size="small" data-elm-id="admin-dashboard-coaches-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Created Date</Table.HeaderCell>
                <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                <Table.HeaderCell width={1}>Published prompts</Table.HeaderCell>
                <Table.HeaderCell width={1}>Sent prompts</Table.HeaderCell>
                <Table.HeaderCell width={1}>Prompt remaining</Table.HeaderCell>
                <Table.HeaderCell width={1}>Nro. of players</Table.HeaderCell>
                <Table.HeaderCell width={1}>Random Schedule</Table.HeaderCell>
                <Table.HeaderCell width={1}>Last Sent</Table.HeaderCell>
                <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!isLoading &&
                coaches.map((coachAccount, index) => {
                  const { coach } = coachAccount;
                  return (
                    <CoachCell
                      key={"coach_cell_" + index}
                      coachIndex={index}
                      coachAccount={coachAccount}
                      coachStats={coachesStatsById[coach!.id]}
                      handlerRefresh={getTableData}
                      handlerImpersonate={impersonateCoach}
                    />
                  );
                })}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid centered>
          <Pagination
            data-elm-id="admin-dashboard-paginator"
            defaultActivePage={1}
            boundaryRange={3}
            pointing
            secondary
            totalPages={totalPages}
            onPageChange={(_, data) => {
              setPageNumber(Number(data.activePage) - 1);
            }}
          />
        </Grid>
      </Grid.Row>
    </Grid>
  );
};

export const CoachList = withRouter(CoachListFC);
