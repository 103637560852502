import * as React from "react";
import{ useIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Grid, Button, Loader, Header, Card, Message } from "semantic-ui-react";
import { CoachService, CoachSignUpStep } from "../../services";
import { QuestionSetType } from "../../interfaces/dtos";
import { isUndefined, isEmpty, times, values } from "lodash";
import { TitleSection } from "../TitleSection";
import { descriptors, QuestionnaireType } from "./descriptors";
import { AppContext } from "../../providers";
import QuestionList from "./components/QuestionList";
import { validationSchemaBuilder } from "./validations";
import { Formik } from "formik";

const MaxQuestionsAllowed = 5;

export const getQuestionId = (index: number) => `question-${index}`;

export interface Question {
  disabled: boolean;
  text: string;
}

const getEmptyQuestion = () => ({ disabled: true, text: "" });

interface OwnProps {
  coachId: string;
}

type Props = OwnProps & RouteComponentProps;

export const transformQuestions = (questions: Question[]) =>
  times(questions.length).reduce(
    (accum, index) => ({ ...accum, [getQuestionId(index)]: questions[index].text || "" }),
    {}
  );

const Questionnaire: React.FC<Props> = ({ coachId, history }) => {
  const [questions, setQuestions] = React.useState<Question[]>([getEmptyQuestion()]);
  const [setId, setQuestionSetId] = React.useState<number | undefined>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [unknownError, setUnknownError] = React.useState<boolean>(false);

  const { formatMessage } = useIntl();

  React.useEffect(() => {
    const getInitialData = async () => {
      setLoading(true);
      try {
        if (coachId) {
          const savedQuestions = await CoachService.getQuestionSetByCoachId(coachId, QuestionSetType.ONBOARDING);
          setQuestions(
            savedQuestions.questions
              .sort(({ sortOrder: sortOrderA }, { sortOrder: sortOrderB }) => sortOrderA - sortOrderB)
              .map(question => ({ ...getEmptyQuestion(), text: question.title }))
          );
          setQuestionSetId(savedQuestions.questionSetId);
        }
      } catch (e: any) {
        if (!(e.status && e.status === 404)) {
          setUnknownError(true);
        }
      } finally {
        setLoading(false);
      }
    };

    getInitialData();
  }, [coachId]);

  const addEmptyQuestion = () => {
    if (questions.length < MaxQuestionsAllowed) {
      questions.push(getEmptyQuestion());
      setQuestions([...questions]);
    }
  };

  return loading ? (
    <Loader active size="large" />
  ) : (
    <Formik
      validateOnChange={true}
      initialValues={transformQuestions(questions)}
      onSubmit={() => {
        return;
      }}
      validationSchema={() => validationSchemaBuilder(questions.length)}>
      {({ handleChange, errors, setValues }) => {
        const errorList: string[] = values(errors);
        return (
          <Grid columns={1}>
            <TitleSection
              title={formatMessage({ ...descriptors[QuestionnaireType.title] })}
              subtitle={formatMessage({ ...descriptors[QuestionnaireType.subtitle] })}
              currentStep={CoachSignUpStep.COACH_CUSTOMER_QUESTIONNAIRE}
              ready={true}
              handleNextAction={async () => {
                if (isEmpty(errorList)) {
                  const questionSetBody = {
                    coachId,
                    type: QuestionSetType.ONBOARDING,
                    questions: questions.map((localQuestion, index) => ({
                      title: localQuestion.text,
                      sortOrder: index,
                    })),
                  };
                  if (!isUndefined(setId)) {
                    await CoachService.updateQuestionSetBySetId(setId!!, questionSetBody);
                  } else {
                    await CoachService.saveQuestionSetByCoachId(coachId, questionSetBody);
                  }
                }
              }}
            />
            <Grid.Row className={"coachBaseContainer"}>
              <Grid.Column width={12}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Header size="tiny">
                        {formatMessage({ ...descriptors[QuestionnaireType.addQuestion] })} ({questions.length})
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <QuestionList
                      handleChange={handleChange}
                      setValues={setValues}
                      questions={questions}
                      setQuestions={setQuestions}
                    />
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width={4}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Button
                        data-elm-id={"addQuestionBtn"}
                        onClick={addEmptyQuestion}
                        className="rounded"
                        primary
                        disabled={questions.length === MaxQuestionsAllowed}>
                        {formatMessage({ ...descriptors[QuestionnaireType.addQuestion] })}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column widescreen={8} largescreen={12} style={{ padding: "0" }}>
                      <Card fluid>
                        <Card.Content header="What is this?" />
                        <Card.Content>{formatMessage({ ...descriptors[QuestionnaireType.whatIsThis] })}</Card.Content>
                      </Card>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <Message error hidden={isEmpty(errorList)} list={values(errorList)} />
                  </Grid.Column>
                  <Grid.Column>
                    {unknownError && (
                      <Message
                        error
                        content={formatMessage({
                          ...descriptors[QuestionnaireType.unknownError],
                        })}
                      />
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Row>
          </Grid>
        );
      }}
    </Formik>
  );
};

export const QuestionnaireForm = withRouter(Questionnaire)
