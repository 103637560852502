import * as React from "react";
import{ useIntl, FormattedMessage } from "react-intl";
import { Form, Popup, Icon, Message, Button } from "semantic-ui-react";
import { BasicFormProps } from "../../interfaces/customTypes";
import { UserChangePasswordType, descriptors } from "./descriptors";

type Props = BasicFormProps 

/**
 * Represents a User Change Password content.
 */
const ChangePasswordFC: React.FC<Props> = ({
 
  messages,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => {
  const { formatMessage } = useIntl();
  const noErrors = (messages && messages.error && messages.error.length === 0) || !messages || !messages?.error;
  const content = (
    <React.Fragment>
      <Form.Input
        name="userPassword"
        className={"basicPadding"}
        data-elm-id={"changePasswordInputPassword"}
        fluid
        type="password"
        placeholder={formatMessage({ ...descriptors[UserChangePasswordType.password] })}
        onChange={handleChange}
        disabled={isSubmitting}
      />
      <Form.Input
        name="newPassword"
        data-elm-id={"changePasswordInputConfirm"}
        type="password"
        fluid
        placeholder={formatMessage({ ...descriptors[UserChangePasswordType.confirmPassword] })}
        onChange={handleChange}
        disabled={isSubmitting}
        icon={
          <Popup trigger={<Icon name="info circle" className={"infoIcon"} link />}>
            <FormattedMessage {...descriptors[UserChangePasswordType.passwordWithNumber]} />
            <br />
            <FormattedMessage {...descriptors[UserChangePasswordType.passwordWithCapital]} />
            <br />
            <FormattedMessage {...descriptors[UserChangePasswordType.passwordWithSpecialCharacter]} />
            <br />
            <FormattedMessage {...descriptors[UserChangePasswordType.passwordMin]} />
          </Popup>
        }
      />

      <Button
        data-elm-id={`userChangePwdSubmitBtn`}
        loading={isSubmitting}
        fluid
        size="large"
        type="submit"
        disabled={isSubmitting}
        onClick={handleSubmit}>
        <FormattedMessage {...descriptors[UserChangePasswordType.submit]} />
      </Button>

      {messages && messages.error && messages.error.length > 0 && (
        <Message error header={"Errors:"} list={messages && messages.error} />
      )}

      {messages && messages.info && noErrors && messages.info.length > 0 && (
        <Message positive list={messages && messages.info} />
      )}
    </React.Fragment>
  );

  return content;
};

export const UserChangePassword = ChangePasswordFC