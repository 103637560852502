import React from "react";
import { map } from "lodash";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";

interface Props {
  /**
   * Player Organizations
   */
  organizations: any[];

  /**
   * Handle function when some element is selected on the dropdown
   * @param selectedPage
   * @returns
   */
  selectedOrganization: (selectedPage: any) => void;
}

const LinkedInOrganizationClass: React.FC<Props> = ({
  organizations: pages,
  selectedOrganization: selectedPage,
}) => {
  const [pagesOptions, setPagesOptions] = React.useState<DropdownItemProps[]>(
    []
  );

  React.useEffect(() => {
    if (pages) {
      const filters: DropdownItemProps[] = map(pages, (page) => ({
        key: page.id,
        text: page.name,
        value: page,
      }));
      setPagesOptions(filters);
    }
  }, [pages]);

  return (
    <Dropdown
      placeholder="Select your LinkedIn Organization"
      fluid
      onChange={(e, data) => {
        selectedPage(data.value);
      }}
      selection
      options={pagesOptions}
    />
  );
};

export const PlayerOrganizationList = LinkedInOrganizationClass;
