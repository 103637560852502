import { defineMessages } from "react-intl";

export enum FirstPictureType {
  title = "FirstPictureType.title",
  subtitle = "FirstPictureType.subtitle",

  submit = "FirstPictureType.submit",
  skip = "FirstPictureType.skip",

  warningTitle = "FirstPictureType.warningTitle",
  errorTitle = "FirstPictureType.errorTitle",
  infoTitle = "FirstPictureType.infoTitle",
}

export const en = {
  [FirstPictureType.title]: "",
  [FirstPictureType.subtitle]: "Add your profile picture.\n",

  [FirstPictureType.submit]: "Save Picture",
  [FirstPictureType.skip]: "Do it later",

  [FirstPictureType.warningTitle]: "Warning",
  [FirstPictureType.errorTitle]: "Error:",
  [FirstPictureType.infoTitle]: "Hey",
};

export const descriptors = defineMessages({
  [FirstPictureType.title]: {
    id: FirstPictureType.title,
    defaultMessage: en[FirstPictureType.title],
    description: "-",
  },
  [FirstPictureType.subtitle]: {
    id: FirstPictureType.subtitle,
    defaultMessage: en[FirstPictureType.subtitle],
    description: "-",
  },
  [FirstPictureType.submit]: {
    id: FirstPictureType.submit,
    defaultMessage: en[FirstPictureType.submit],
    description: "-",
  },
  [FirstPictureType.skip]: {
    id: FirstPictureType.skip,
    defaultMessage: en[FirstPictureType.skip],
    description: "-",
  },
  [FirstPictureType.warningTitle]: {
    id: FirstPictureType.warningTitle,
    defaultMessage: en[FirstPictureType.warningTitle],
    description: "-",
  },
  [FirstPictureType.errorTitle]: {
    id: FirstPictureType.errorTitle,
    defaultMessage: en[FirstPictureType.errorTitle],
    description: "-",
  },
  [FirstPictureType.infoTitle]: {
    id: FirstPictureType.infoTitle,
    defaultMessage: en[FirstPictureType.infoTitle],
    description: "-",
  },
});
