import * as React from "react";
import { Form, Grid, Header, Image } from "semantic-ui-react";
import{ useIntl } from "react-intl";

import { Png } from "../../../assets";
import { FloatedCard } from "../../FloatedCard";

import { descriptors, VerificationCoachType } from "./descriptors";

interface CoachVerifiedProps {
  onClick: () => void;
}
type Props = CoachVerifiedProps 

const CoachVerifiedFC: React.FC<Props> = ({ onClick }) => {
  const { formatMessage } = useIntl();
  const content = (
    <Grid.Row centered>
      <Image centered={true} src={Png.Check} size={"tiny"} />

      <Header as="h6" className="centered paddingTop">
        Please click below to be redirected to our login page.
      </Header>
      <Form.Button
        data-elm-id={"verificationSectionLoginButton"}
        size="large"
        type="text"
        onClick={onClick}
        className={"centered"}>
        Login
      </Form.Button>
    </Grid.Row>
  );
  return (
    <FloatedCard
      title={formatMessage({ ...descriptors[VerificationCoachType.title] })}
      subtitle={formatMessage({ ...descriptors[VerificationCoachType.subtitle] })}
      content={content}
      isSubmitting={false}
      size={"bigCentered"}
      padding={"small"}
    />
  );
};

export const CoachVerifiedSection = CoachVerifiedFC;
