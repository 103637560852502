import { defineMessages } from "react-intl";

export enum VideoProjectComponentType {
  message = "VideoProjectComponentType.message",
  aiButton = "VideoProjectComponentType.aiButton",
  hashtags = "VideoProjectComponentType.hashtags",
  attachDesc = "VideoProjectComponentType.attachDesc",
  playerSection = "VideoProjectComponentType.playerSection",

  postSectionTitle = "VideoProjectComponentType.postSectionTitle",
  autoPost = "VideoProjectComponentType.autoPost",
  pushNotification = "VideoProjectComponentType.pushNotification",

  hashtagsInfo = "VideoProjectComponentType.hashtagsInfo",

  allowHashtags = "VideoProjectComponentType.allowHashtags",
  allowShortLink = "VideoProjectComponentType.allowShortLink",
  allowComplianceDisclaimer = "VideoProjectComponentType.allowComplianceDisclaimer",
  socialNetworksDescription = "VideoProjectComponentType.socialNetworksDescription",

  specialRequests = "VideoProjectComponentType.specialRequests",
  stylePrefs = "VideoProjectComponentType.stylePrefs",
  messagePlaceholder = "VideoProjectComponentType.messagePlaceholder",
  specialRequestsPlaceholder = "VideoProjectComponentType.specialRequestsPlaceholder",
  //////

  videoMessageInfo = "VideoProjectComponentType.videoMessageInfo",

  datePlaceholder = "VideoProjectComponentType.datePlaceholder",
  timePlaceholder = "VideoProjectComponentType.timePlaceholder",

  uploadFilePlaceholder = "VideoProjectComponentType.uploadFilePlaceholder",
  uploadVideoPlaceholder = "VideoProjectComponentType.uploadVideoPlaceholder",

  saveBtn = "VideoProjectComponentType.saveBtn",
  deliveryLabel = "VideoProjectComponentType.deliveryLabel",
  uploadBtn = "VideoProjectComponentType.uploadBtn",
  browseBtn = "VideoProjectComponentType.browseBtn",
  cancelBtn = "VideoProjectComponentType.cancel",
  enableBtn = "VideoProjectComponentType.enable",
}

export const en = {
  [VideoProjectComponentType.attachDesc]: "Files",
  [VideoProjectComponentType.message]: "Caption",
  [VideoProjectComponentType.aiButton]: "Generate Caption",
  [VideoProjectComponentType.hashtags]: "Hashtags",
  [VideoProjectComponentType.postSectionTitle]: "Posting Preferences",
  [VideoProjectComponentType.playerSection]: "Player",

  [VideoProjectComponentType.autoPost]: "Auto Post",
  [VideoProjectComponentType.pushNotification]: "Push Notification",

  [VideoProjectComponentType.allowHashtags]: "Include player's hashtags",

  [VideoProjectComponentType.datePlaceholder]: "Select a date",
  [VideoProjectComponentType.timePlaceholder]: "Select a time",

  [VideoProjectComponentType.allowShortLink]: "Include player's short link",
  [VideoProjectComponentType.allowComplianceDisclaimer]: "Include Compliance Disclaimer",

  [VideoProjectComponentType.socialNetworksDescription]: "Select the social network(s) you would like to Auto Post to:",
  [VideoProjectComponentType.deliveryLabel]: "Scheduling options",
  ///////

  [VideoProjectComponentType.videoMessageInfo]:
    "This video is meant to allow you to provide coaching and instructions about this prompt for your players. It will only be visible to them and not postable. Please keep it under 2 minutes. Landscape orientation is best.",
  [VideoProjectComponentType.messagePlaceholder]: "Write Message",
  [VideoProjectComponentType.specialRequestsPlaceholder]: "Add a short message for the editing team...",
  [VideoProjectComponentType.uploadFilePlaceholder]: "Select a file",
  [VideoProjectComponentType.uploadVideoPlaceholder]: "Select a video",

  [VideoProjectComponentType.specialRequests]: "Special Requests",
  [VideoProjectComponentType.stylePrefs]: "Style Preferences",

  [VideoProjectComponentType.hashtagsInfo]: "This section should only include hashtags relative to this post.", // . Please include a # symbol with each hashtag, and include a space between each of them.",

  [VideoProjectComponentType.uploadBtn]: "Upload",
  [VideoProjectComponentType.browseBtn]: "Browse",
  [VideoProjectComponentType.saveBtn]: "Done",
  [VideoProjectComponentType.cancelBtn]: "Cancel",
  [VideoProjectComponentType.enableBtn]: "Publish",
};

export const descriptors = defineMessages({
  [VideoProjectComponentType.aiButton]: {
    id: VideoProjectComponentType.aiButton,
    defaultMessage: en[VideoProjectComponentType.aiButton],
    description: "-",
  },
  [VideoProjectComponentType.autoPost]: {
    id: VideoProjectComponentType.autoPost,
    defaultMessage: en[VideoProjectComponentType.autoPost],
    description: "-",
  },
  [VideoProjectComponentType.pushNotification]: {
    id: VideoProjectComponentType.pushNotification,
    defaultMessage: en[VideoProjectComponentType.pushNotification],
    description: "-",
  },
  [VideoProjectComponentType.specialRequests]: {
    id: VideoProjectComponentType.specialRequests,
    defaultMessage: en[VideoProjectComponentType.specialRequests],
    description: "-",
  },
  [VideoProjectComponentType.stylePrefs]: {
    id: VideoProjectComponentType.stylePrefs,
    defaultMessage: en[VideoProjectComponentType.stylePrefs],
    description: "-",
  },
  [VideoProjectComponentType.allowHashtags]: {
    id: VideoProjectComponentType.allowHashtags,
    defaultMessage: en[VideoProjectComponentType.allowHashtags],
    description: "-",
  },
  [VideoProjectComponentType.datePlaceholder]: {
    id: VideoProjectComponentType.datePlaceholder,
    defaultMessage: en[VideoProjectComponentType.datePlaceholder],
    description: "-",
  },
  [VideoProjectComponentType.timePlaceholder]: {
    id: VideoProjectComponentType.timePlaceholder,
    defaultMessage: en[VideoProjectComponentType.timePlaceholder],
    description: "-",
  },
  [VideoProjectComponentType.allowShortLink]: {
    id: VideoProjectComponentType.allowShortLink,
    defaultMessage: en[VideoProjectComponentType.allowShortLink],
    description: "-",
  },
  [VideoProjectComponentType.allowComplianceDisclaimer]: {
    id: VideoProjectComponentType.allowComplianceDisclaimer,
    defaultMessage: en[VideoProjectComponentType.allowComplianceDisclaimer],
    description: "-",
  },
  [VideoProjectComponentType.postSectionTitle]: {
    id: VideoProjectComponentType.postSectionTitle,
    defaultMessage: en[VideoProjectComponentType.postSectionTitle],
    description: "-",
  },
  [VideoProjectComponentType.message]: {
    id: VideoProjectComponentType.message,
    defaultMessage: en[VideoProjectComponentType.message],
    description: "-",
  },
  [VideoProjectComponentType.hashtags]: {
    id: VideoProjectComponentType.hashtags,
    defaultMessage: en[VideoProjectComponentType.hashtags],
    description: "-",
  },
  [VideoProjectComponentType.playerSection]: {
    id: VideoProjectComponentType.playerSection,
    defaultMessage: en[VideoProjectComponentType.playerSection],
    description: "-",
  },
  [VideoProjectComponentType.deliveryLabel]: {
    id: VideoProjectComponentType.deliveryLabel,
    defaultMessage: en[VideoProjectComponentType.deliveryLabel],
    description: "-",
  },
  [VideoProjectComponentType.attachDesc]: {
    id: VideoProjectComponentType.attachDesc,
    defaultMessage: en[VideoProjectComponentType.attachDesc],
    description: "-",
  },
  [VideoProjectComponentType.messagePlaceholder]: {
    id: VideoProjectComponentType.messagePlaceholder,
    defaultMessage: en[VideoProjectComponentType.messagePlaceholder],
    description: "-",
  },
  [VideoProjectComponentType.specialRequestsPlaceholder]: {
    id: VideoProjectComponentType.specialRequestsPlaceholder,
    defaultMessage: en[VideoProjectComponentType.specialRequestsPlaceholder],
    description: "-",
  },
  [VideoProjectComponentType.uploadFilePlaceholder]: {
    id: VideoProjectComponentType.uploadFilePlaceholder,
    defaultMessage: en[VideoProjectComponentType.uploadFilePlaceholder],
    description: "-",
  },
  [VideoProjectComponentType.uploadVideoPlaceholder]: {
    id: VideoProjectComponentType.uploadVideoPlaceholder,
    defaultMessage: en[VideoProjectComponentType.uploadVideoPlaceholder],
    description: "-",
  },
  [VideoProjectComponentType.uploadBtn]: {
    id: VideoProjectComponentType.uploadBtn,
    defaultMessage: en[VideoProjectComponentType.uploadBtn],
    description: "-",
  },
  [VideoProjectComponentType.browseBtn]: {
    id: VideoProjectComponentType.browseBtn,
    defaultMessage: en[VideoProjectComponentType.browseBtn],
    description: "-",
  },
  [VideoProjectComponentType.saveBtn]: {
    id: VideoProjectComponentType.saveBtn,
    defaultMessage: en[VideoProjectComponentType.saveBtn],
    description: "-",
  },
  [VideoProjectComponentType.cancelBtn]: {
    id: VideoProjectComponentType.cancelBtn,
    defaultMessage: en[VideoProjectComponentType.cancelBtn],
    description: "-",
  },
  [VideoProjectComponentType.enableBtn]: {
    id: VideoProjectComponentType.enableBtn,
    defaultMessage: en[VideoProjectComponentType.enableBtn],
    description: "-",
  },
  [VideoProjectComponentType.videoMessageInfo]: {
    id: VideoProjectComponentType.videoMessageInfo,
    defaultMessage: en[VideoProjectComponentType.videoMessageInfo],
    description: "-",
  },
  [VideoProjectComponentType.socialNetworksDescription]: {
    id: VideoProjectComponentType.socialNetworksDescription,
    defaultMessage: en[VideoProjectComponentType.socialNetworksDescription],
    description: "-",
  },
  [VideoProjectComponentType.hashtagsInfo]: {
    id: VideoProjectComponentType.hashtagsInfo,
    defaultMessage: en[VideoProjectComponentType.hashtagsInfo],
    description: "-",
  },
});
