import * as React from "react";
import { Grid } from "semantic-ui-react";
import { SocialNetworksId } from "../../interfaces";
import { SocialNetworkIconButton } from "../SocialNetworkIconButton";

import "./styles.scss";

interface Props {
  /**
   * List of active Social network identifier (IG, FB, LI, TW, TT)
   */
  actives?: SocialNetworksId[];
  /**
   * List of valid social network identifier (IG, FB, LI, TW, TT)
   */
  allSocialNetworks: SocialNetworksId[];
  /**
   * Hash of Social network post urls by SN (IG, FB, LI, TW, TT)
   */
  links?: { [key: string]: string };
  onClick?: (snId: SocialNetworksId) => void;
  size?: "mini" | "big" | "large";
  showTiktok?: boolean;
}

export const SocialNetworksSection: React.FC<Props> = ({ actives, links, allSocialNetworks, size = "mini" }) => {
  return (
    <Grid className={`mediumPadding playerSocialList socialNetworkSectionGrid_${size}`}>
      {allSocialNetworks.map((sn, index) => {
        return (
          <SocialNetworkIconButton
            active={actives?.includes(sn)}
            key={index}
            size={size}
            disabled={!actives?.includes(sn)}
            networkId={sn}
            link={links ? links[sn] : undefined}
          />
        );
      })}
    </Grid>
  );
};
