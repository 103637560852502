import * as React from "react";

import * as DTO from "../../../interfaces";
import { VideoProjectServices } from "../../../services";

import { PlayerSection } from "../../PlayerSection";

interface OwnProps {
  coachId: string;
  player: DTO.PlayerResumeResponse;

  showError?: (ErrorMessage: string) => void;
}

type Props = OwnProps;

const VideoCatalystListComponentFC: React.FC<Props> = ({ player, coachId }) => {
  const [videoProjects, setVideoProjects] = React.useState<DTO.Page<DTO.PlayerVideoProjectResponse> | undefined>(
    undefined
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (player && coachId) {
      loadAll(); // loadBase();
    }
  }, [coachId, player]);

  // TODO: Select the function that we need (loadAll or loadBase + loadMore)
  async function loadAll() {
    setLoading(true);

    const response = await VideoProjectServices.getAll(coachId, player.playerId).finally(() => {
      setLoading(false);
    });

    setVideoProjects(cpages => {
      return {
        ...cpages!,
        ...response,
      };
    });
  }

  async function loadBase() {
    setLoading(true);

    const response = await VideoProjectServices.videoProjectByPlayer(coachId, player.playerId).finally(() => {
      setLoading(false);
    });

    setVideoProjects(cpages => {
      return {
        ...cpages!,
        ...response,
      };
    });
  }

  const loadMore = React.useCallback(
    async (nextPage: number) => {
      if (
        !loading &&
        ((videoProjects && videoProjects.totalPages && videoProjects.number! < videoProjects.totalPages!) ||
          !videoProjects)
      ) {
        setLoading(true);
        const videoProjectResponse = await VideoProjectServices.videoProjectByPlayer(
          coachId,
          player.playerId,
          nextPage
        );

        setLoading(false);
        setVideoProjects(cpages => {
          return {
            ...cpages,
            ...videoProjectResponse,
            content: videoProjectResponse.content,
          };
        });
      }
    },
    [coachId, player, loading]
  );

  const videoProjectsFiltered = videoProjects ? videoProjects.content : [];

  return videoProjects && videoProjects.content.length > 0 ? (
    <PlayerSection
      key={player.playerId}
      name={player.fullName}
      player={player}
      videoProjects={videoProjectsFiltered}
      totalElements={videoProjects?.totalElements}
      page={videoProjects?.number}
      firstPage={videoProjects?.first} // TODO: REMOVE
      latestPage={videoProjects?.last} // TODO: REMOVE
      onNext={nextPage => loadMore(nextPage)} // TODO: REMOVE
    />
  ) : null;
};

export const VideoCatalystListComponent = VideoCatalystListComponentFC;
