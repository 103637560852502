import { defineMessages } from "react-intl";

export enum FirstLoginType {
  title = "FirstLoginType.title",
  subtitle = "FirstLoginType.subtitle",

  webAddress = "FirstLoginType.webAddress",
  shortLink = "FirstLoginType.shortLink",

  submit = "FirstLoginType.submit",

  warningTitle = "FirstLoginType.warningTitle",
  errorTitle = "FirstLoginType.errorTitle",
  infoTitle = "FirstLoginType.infoTitle",
}

export const en = {
  [FirstLoginType.title]: "-",
  [FirstLoginType.subtitle]: "Each post will direct viewers to your website. What site would you like them to go to?\n",
  [FirstLoginType.webAddress]: "Web Address",
  [FirstLoginType.shortLink]: "Short Link",

  [FirstLoginType.submit]: "Next",

  [FirstLoginType.warningTitle]: "Warning",
  [FirstLoginType.errorTitle]: "Error:",
  [FirstLoginType.infoTitle]: "Hey",
};

export const descriptors = defineMessages({
  [FirstLoginType.title]: {
    id: FirstLoginType.title,
    defaultMessage: en[FirstLoginType.title],
    description: "Log In title",
  },
  [FirstLoginType.subtitle]: {
    id: FirstLoginType.subtitle,
    defaultMessage: en[FirstLoginType.subtitle],
    description: "Log In subtitle",
  },
  [FirstLoginType.webAddress]: {
    id: FirstLoginType.webAddress,
    defaultMessage: en[FirstLoginType.webAddress],
    description: "firstName text",
  },
  [FirstLoginType.shortLink]: {
    id: FirstLoginType.shortLink,
    defaultMessage: en[FirstLoginType.shortLink],
    description: "lastName text",
  },
  [FirstLoginType.submit]: {
    id: FirstLoginType.submit,
    defaultMessage: en[FirstLoginType.submit],
    description: "Sign In text",
  },
  [FirstLoginType.warningTitle]: {
    id: FirstLoginType.warningTitle,
    defaultMessage: en[FirstLoginType.warningTitle],
    description: "Warning messages title",
  },
  [FirstLoginType.errorTitle]: {
    id: FirstLoginType.errorTitle,
    defaultMessage: en[FirstLoginType.errorTitle],
    description: "Error Messages title",
  },
  [FirstLoginType.infoTitle]: {
    id: FirstLoginType.infoTitle,
    defaultMessage: en[FirstLoginType.infoTitle],
    description: "Info messages title",
  },
});
