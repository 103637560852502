import * as React from "react";
import { useIntl } from "react-intl";
import { Grid } from "semantic-ui-react";
import { MenuTitleSection, PlayerVideoCatalystListComponent } from "../../../components";
import { VideoProjectServices } from "../../../services";
import { VideoCatalystStatus } from "../../../interfaces";

import { SocialGrid } from "../../../styling/baseStyle";
import { CatalystType, descriptors } from "./descriptors";
import "./styles.scss";

interface Props {
  myCoachId: string;
  playerId: number;
  addCampaignHandler: () => void;
}

const VideoCatalystFC: React.FC<Props> = ({ addCampaignHandler, playerId, myCoachId }) => {
  const { formatMessage } = useIntl();

  const [status, setStatus] = React.useState<VideoCatalystStatus | undefined>(undefined);

  React.useEffect(() => {
    VideoProjectServices.getVideoCatalystStatus(playerId).then(setStatus);
  }, []);

  const customLinkSection = (): JSX.Element => {
    return (
      <SocialGrid.Column width={9} floated={"right"} className={"creditsRemainingGrid"}>
        <label>
          {formatMessage(
            {
              ...descriptors[status?.unlimitedCredits ? CatalystType.unlimitedCredits : CatalystType.creditsRemaining],
            },
            { credits: status.creditsRemaining }
          )}
        </label>
      </SocialGrid.Column>
    );
  };

  const canCreateNewProject = (): boolean => {
    return !!(
      status &&
      status.enabled &&
      (status.unlimitedCredits || (status.creditsRemaining && status.creditsRemaining > 0))
    );
  };

  return (
    <div>
      {status && (
        <MenuTitleSection
          floatedRight
          buttonsSize={7}
          nextButtonSize={7}
          disableButtons={!canCreateNewProject()}
          handleNextAction={addCampaignHandler}
          customLinkSection={customLinkSection()}
          customNextLabel={formatMessage({ ...descriptors[CatalystType.addProjectBtn] })}
        />
      )}
      <Grid className={"videoCatalystListComponentContainer"} data-elm-id="playerVideoCatalystListComponent">
        <Grid.Row>
          <Grid.Column>
            <PlayerVideoCatalystListComponent
              coachId={myCoachId}
              playerId={playerId}
              key={"player_video_catalyst_list_c_" + playerId}
              showError={error => {
                alert(error);
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export const PlayerVideoCatalyst = VideoCatalystFC;
