import * as React from "react";
import { Loader } from "semantic-ui-react";

import * as DTO from "../../../../../../../../../interfaces";
import { PromptServices } from "../../../../../../../../../services";

import { CategoryRow } from "../../../CategoryRow";

interface OwnProps {
  coachId: string;
  category: DTO.Category;
  campaignPrompts: DTO.SelectedPrompts;

  onAddPrompt: (prompt: DTO.Prompt) => void;
  onRemovePrompt: (prompt: DTO.Prompt) => void;
}

type Props = OwnProps;

const PromptsByCategoryFC: React.FC<Props> = ({
  category,
  coachId,
  campaignPrompts = {},
  onAddPrompt,
  onRemovePrompt,
}) => {
  const [categoryPrompts, setCategoryPrompts] = React.useState<DTO.Page<DTO.Prompt> | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (category && coachId && !categoryPrompts) {
      initialLoad();
    }
  }, [coachId, category, categoryPrompts]);

  async function initialLoad() {
    const { categoryId } = category;
    setLoading(true);
    const promptsResponse = await PromptServices.libraryByCategory(coachId, categoryId).finally(() => {
      setLoading(false);
    });
    setCategoryPrompts(() => {
      return {
        ...promptsResponse,
      };
    });
  }

  const loadMore = React.useCallback(
    async (nextPage: number) => {
      const { categoryId } = category;
      if (
        !loading &&
        ((categoryPrompts && categoryPrompts.totalPages && categoryPrompts.number! < categoryPrompts.totalPages!) ||
          !categoryPrompts)
      ) {
        setLoading(true);

        const promptsResponse = await PromptServices.libraryByCategory(coachId, categoryId, nextPage);

        setLoading(false);
        setCategoryPrompts(cpages => {
          return {
            ...cpages,
            ...promptsResponse,
            content: promptsResponse.content,
          };
        });
      }
    },
    [coachId, category, loading]
  );

  const promptsFiltered = categoryPrompts ? categoryPrompts.content : [];

  return (
    <div>
      {loading && <Loader active />}
      {promptsFiltered.length > 0 && (
        <CategoryRow
          key={category.categoryId}
          name={category.name!}
          category={category}
          promptList={promptsFiltered}
          totalElements={categoryPrompts?.totalElements}
          page={categoryPrompts?.number}
          pageable={true}
          firstPage={categoryPrompts?.first}
          latestPage={categoryPrompts?.last}
          campaignPrompts={campaignPrompts}
          onNext={loadMore}
          onAddPrompt={onAddPrompt}
          onRemovePrompt={onRemovePrompt}
        />
      )}
    </div>
  );
};

export const PromptsByCategory = React.memo(PromptsByCategoryFC);
