import * as React from "react";
import { Formik } from "formik";
import { useIntl } from "react-intl";
import { SocialCoachSessionService } from "../../services";
import { validationSchema } from "./validations";
import { UserChangePassword } from "../../components/UserChangePassword";
import { textWithLimit } from "../../utils";
interface OwnProps {
  userPassword?: string;
  newPassword?: string;
  handleSuccess?: () => void;
}

type Props = OwnProps;

const IntegratedChangePassword: React.FC<Props> = ({ userPassword = "", newPassword = "" }) => {
  const [sucessMessage, setSucessMessage] = React.useState<string | undefined>(undefined);
  return (
    <Formik
      validateOnBlur={true}
      validateOnChange={true}
      initialValues={{ userPassword, newPassword }}
      validationSchema={validationSchema}
      onSubmit={async ({ userPassword: pwd, newPassword: newpwd }, { setSubmitting, setErrors }) => {
        try {
          setSubmitting(true);
          setSucessMessage(undefined);
          SocialCoachSessionService.userChangePassword(pwd!, newpwd!)
            .then(() => {
              setSucessMessage("Password changed successfully");
            })
            .catch(e => {
              setErrors({
                userPassword: textWithLimit(e, 80),
                newPassword: undefined,
              });
            })
            .finally(() => {
              setSubmitting(false);
            });
        } catch (e: any) {
          setErrors({
            userPassword: e,
            newPassword: undefined,
          });
          setSucessMessage(undefined);
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleChange, handleSubmit, isSubmitting }) => {
        return (
          <UserChangePassword
            messages={{
              error: Object.values(errors) as string[],
              info: sucessMessage ? [sucessMessage] : [],
            }}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
        );
      }}
    </Formik>
  );
};

export const UserChangePasswordForm = IntegratedChangePassword;
