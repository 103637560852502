import { defineMessages } from "react-intl";

export enum VideoProjectComponentType {
  message = "VideoProjectComponentType.message",
  hashtags = "VideoProjectComponentType.hashtags",
  projectFilesTitle = "VideoProjectComponentType.projectFilesTitle",
  originalVideosDesc = "VideoProjectComponentType.originalVideosDesc",
  editedVideosDesc = "VideoProjectComponentType.editedVideosDesc",

  ////
  playerSection = "VideoProjectComponentType.playerSection",
  postSectionTitle = "VideoProjectComponentType.postSectionTitle",

  allowHashtags = "VideoProjectComponentType.allowHashtags",
  allowShortLink = "VideoProjectComponentType.allowShortLink",
  allowComplianceDisclaimer = "VideoProjectComponentType.allowComplianceDisclaimer",
  socialNetworksDescription = "VideoProjectComponentType.socialNetworksDescription",
}

export const en = {
  [VideoProjectComponentType.projectFilesTitle]: "Project Files",
  [VideoProjectComponentType.originalVideosDesc]: "Original Files",
  [VideoProjectComponentType.editedVideosDesc]: "Final Edit",
  [VideoProjectComponentType.message]: "Caption",
  [VideoProjectComponentType.hashtags]: "Hashtags",
  [VideoProjectComponentType.postSectionTitle]: "Project Status",
  [VideoProjectComponentType.playerSection]: "Player Info",

  [VideoProjectComponentType.allowHashtags]: "Include player's hashtags",

  [VideoProjectComponentType.allowShortLink]: "Include player's short link",
  [VideoProjectComponentType.allowComplianceDisclaimer]: "Include Compliance Disclaimer",

  [VideoProjectComponentType.socialNetworksDescription]: "Select the social network(s) you would like to Auto Post to:",
};

export const descriptors = defineMessages({
  [VideoProjectComponentType.allowHashtags]: {
    id: VideoProjectComponentType.allowHashtags,
    defaultMessage: en[VideoProjectComponentType.allowHashtags],
    description: "-",
  },
  [VideoProjectComponentType.allowShortLink]: {
    id: VideoProjectComponentType.allowShortLink,
    defaultMessage: en[VideoProjectComponentType.allowShortLink],
    description: "-",
  },
  [VideoProjectComponentType.allowComplianceDisclaimer]: {
    id: VideoProjectComponentType.allowComplianceDisclaimer,
    defaultMessage: en[VideoProjectComponentType.allowComplianceDisclaimer],
    description: "-",
  },
  [VideoProjectComponentType.postSectionTitle]: {
    id: VideoProjectComponentType.postSectionTitle,
    defaultMessage: en[VideoProjectComponentType.postSectionTitle],
    description: "-",
  },
  [VideoProjectComponentType.message]: {
    id: VideoProjectComponentType.message,
    defaultMessage: en[VideoProjectComponentType.message],
    description: "-",
  },
  [VideoProjectComponentType.hashtags]: {
    id: VideoProjectComponentType.hashtags,
    defaultMessage: en[VideoProjectComponentType.hashtags],
    description: "-",
  },
  [VideoProjectComponentType.playerSection]: {
    id: VideoProjectComponentType.playerSection,
    defaultMessage: en[VideoProjectComponentType.playerSection],
    description: "-",
  },
  [VideoProjectComponentType.originalVideosDesc]: {
    id: VideoProjectComponentType.originalVideosDesc,
    defaultMessage: en[VideoProjectComponentType.originalVideosDesc],
    description: "-",
  },
  [VideoProjectComponentType.projectFilesTitle]: {
    id: VideoProjectComponentType.projectFilesTitle,
    defaultMessage: en[VideoProjectComponentType.projectFilesTitle],
    description: "-",
  },
  [VideoProjectComponentType.socialNetworksDescription]: {
    id: VideoProjectComponentType.socialNetworksDescription,
    defaultMessage: en[VideoProjectComponentType.socialNetworksDescription],
    description: "-",
  },
  [VideoProjectComponentType.editedVideosDesc]: {
    id: VideoProjectComponentType.editedVideosDesc,
    defaultMessage: en[VideoProjectComponentType.editedVideosDesc],
    description: "-",
  },
});
