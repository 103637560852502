import { defineMessages } from "react-intl";

export enum SettingsType {
  title = "SettingsType.title",
  subtitle = "SettingsType.subtitle",
  emailSectionTitle = "SettingsType.emailSectionTitle",
  updateBtn = "SettingsType.updateBtn",
  tagsHeader = "SettingsType.tagsHeader",
  maximunStickersError = "SettingsType.maximunStickersError",
}

export const en = {
  [SettingsType.title]: "Settings",
  [SettingsType.subtitle]: "",
  [SettingsType.emailSectionTitle]: "Emails",
  [SettingsType.updateBtn]: "Save",
  [SettingsType.tagsHeader]: "Tags",
  [SettingsType.maximunStickersError]: "Only 100 stickers are allowed",
};

export const descriptors = defineMessages({
  [SettingsType.title]: {
    id: SettingsType.title,
    defaultMessage: en[SettingsType.title],
    description: "-",
  },
  [SettingsType.subtitle]: {
    id: SettingsType.subtitle,
    defaultMessage: en[SettingsType.subtitle],
    description: "-",
  },
  [SettingsType.emailSectionTitle]: {
    id: SettingsType.emailSectionTitle,
    defaultMessage: en[SettingsType.emailSectionTitle],
    description: "-",
  },
  [SettingsType.updateBtn]: {
    id: SettingsType.updateBtn,
    defaultMessage: en[SettingsType.updateBtn],
    description: "-",
  },
  [SettingsType.maximunStickersError]: {
    id: SettingsType.maximunStickersError,
    defaultMessage: en[SettingsType.maximunStickersError],
    description: "-",
  },
});
