import { client as axios } from "./axiosClient";
import { Constants, catchServiceException } from "@socialcoach/services";
import * as DTO from "../interfaces/dtos";

const SHORTLINKS_SERVICE = `${Constants.API_URL}/accounts/players/short-links`;
export class ShortLinksServices {
  static async getByPlayerLink(playerLinkId: number): Promise<DTO.ShortLinkResponse> {
    const response = await axios.get<DTO.ShortLinkResponse>(`${SHORTLINKS_SERVICE}/${playerLinkId}`, {
      withCredentials: true,
    });
    return response.data;
  }

  static async getByPlayerId(playerId: number): Promise<DTO.ShortLinkResponse> {
    const response = await axios.get<DTO.ShortLinkResponse>(`${SHORTLINKS_SERVICE}/player/${playerId}`, {
      withCredentials: true,
    });
    return response.data;
  }

  static async update(playerId: number, shortLink: DTO.ShortLinkRequest): Promise<DTO.ShortLinkResponse> {
    const response = await axios.put<DTO.ShortLinkResponse>(
      `${SHORTLINKS_SERVICE}/address/${playerId}`,
      {
        ...shortLink,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  static async checkSlug(slug: string): Promise<any> {
    const response = await axios.get<any>(`${SHORTLINKS_SERVICE}/check/${slug}`, {
      withCredentials: true,
    });
    return response.data;
  }

  static async updateShortLinkInfo(playerId: number, slug: string, webAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      ShortLinksServices.checkSlug(slug)
        .then(() => {
          const updateShortLink: DTO.ShortLinkRequest = {
            slug,
            webAddress,
          };
          ShortLinksServices.update(playerId, updateShortLink).then(resolve).catch(reject);
        })
        .catch(() => reject("Short link is already taken"));
    });
  }

  @catchServiceException()
  static async getRandomSlug(): Promise<string> {
    const response = await axios.get<any>(`${SHORTLINKS_SERVICE}/random`, {
      withCredentials: true,
    });
    return response.data.slug as string;
  }
}
