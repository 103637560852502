import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Grid, Loader } from "semantic-ui-react";
import { get } from "lodash";

import { SideBar, TitleSection } from "../../../components";
import { WebComponents, EnterprisePlayerRequest } from "../../../interfaces";
import { AppContext } from "../../../providers";
import {
  CampaignServices,
  CoachService,
  PlayersServices,
} from "../../../services";
import { coachWithWritePermission, URLS } from "../../../utils";

import { ActiveCampaigns } from "./components/ActiveCampaigns";
import { AddEnterprisePlayerViewForm } from "./components/EnterprisePlayerForm";

import {
  ContentGrid,
  SocialGrid,
  WhiteContainer,
} from "../../../styling/baseStyle";

type Props = RouteComponentProps;

/**
 * Add enterprise player screen
 */

const AddPlayerFC: React.FC<Props> = ({ history }) => {
  const formSubmitRef = React.useRef<HTMLFormElement>(null);
  const { userContext } = React.useContext(AppContext);

  const withWritePermission = coachWithWritePermission(
    WebComponents.PLAYERS,
    userContext
  );

  const coachId = get(userContext, "coach.id", "");

  const [loading, setLoading] = React.useState<boolean>(false);
  const [planId, setPlanId] = React.useState<number | undefined>(undefined);

  const [playerToCreate, setPlayerToCreate] = React.useState<
    EnterprisePlayerRequest | undefined
  >(
    localStorage.getItem("createdPlayer") &&
      localStorage.getItem("createdPlayer") !== undefined &&
      localStorage.getItem("createdPlayer") !== null
      ? JSON.parse(localStorage.getItem("createdPlayer")!)
      : undefined
  );
  const [selectedCampaignIds, setSelectedCampaignIds] = React.useState<
    string[]
  >([]);

  const [currentStep, setCurrentStep] = React.useState<0 | 1>(
    playerToCreate ? 1 : 0
  );

  React.useEffect(() => {
    return function cleanup() {
      localStorage.removeItem("createdPlayer");
    };
  }, []);

  React.useEffect(() => {
    if (playerToCreate !== null && playerToCreate !== undefined) {
      localStorage.setItem("createdPlayer", JSON.stringify(playerToCreate));
    }
  }, [playerToCreate]);

  React.useEffect(() => {
    setLoading(true);
    CoachService.getPlans(coachId)
      .then((response) => {
        if (response.length > 0) {
          const planResponse = response[0];
          setPlanId(planResponse.id);
        } else {
          alert(
            "Your coach account doesn't have a plan, please contact with our admin"
          );
        }
      })
      .catch(() => {
        alert(
          "Your coach account doesn't have a plan, please contact with our admin"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [coachId]);

  const nextOnClickHandler = () => {
    if (currentStep === 0) {
      // Execute handleSubmit action on create player form
      formSubmitRef!.current!.handleSubmit();
    } else if (currentStep === 1) {
      // Call endpoint to create player and subcribe player in campaigns
      setLoading(true);
      PlayersServices.subscribeEnterprisePlayers(
        coachId,
        playerToCreate!!.pricePlanId,
        [playerToCreate!!]
      )
        .then((response) => {
          if (response.error.length > 0) {
            const errorMessage = response.error.map((e) => e.error).toString();
            const errorAlert =
              errorMessage ===
              "Email has an active subscription with this coach"
                ? "This player is active "
                : errorMessage;

            alert(errorAlert);
            setLoading(false);
          } else {
            const playerIds = response.successCreated.map(
              (newPlayer) => newPlayer.account.playerId
            );
            // Subcribe player in a list of campaigns
            if (selectedCampaignIds.length > 0) {
              CampaignServices.subscribePlayerInCampaigns(
                playerIds[0],
                selectedCampaignIds
              )
                .then(() => {
                  alert(
                    "Player created successfully. They should receive a welcome email shortly. Please have them check their spam and/or email filtering tool if they do not see it in their inbox."
                  );
                })
                .catch((error) => {
                  alert(`Error subcribing player in to campaigns: ${error}`);
                })
                .finally(() => {
                  setLoading(false);
                  history.push(URLS.coach.players);
                });
            } else {
              history.push(URLS.coach.players);
            }
          }
        })
        .catch((e) => {
          alert(e);
          setLoading(false);
        });
    }
  };

  const customLinkSection = (): JSX.Element => {
    return (
      <SocialGrid.Column width={16} floated="right">
        <SocialGrid.Column width={8} floated="right" className={"buttonLeft"}>
          {currentStep === 1 && (
            <Button
              data-elm-id={`editPlayerBackBtn`}
              fluid
              className={"secondary rounded buttonRight"}
              onClick={() => setCurrentStep(0)}
              disabled={loading}
            >
              Back
            </Button>
          )}
        </SocialGrid.Column>
        {withWritePermission && (
          <SocialGrid.Column
            width={8}
            floated="right"
            className={"buttonRight"}
          >
            <Button
              type="submit"
              data-elm-id={"editPlayerSaveBtn"}
              loading={loading}
              fluid
              className={"primary rounded buttonRight"}
              onClick={nextOnClickHandler}
            >
              {currentStep === 0 ? "Next" : "Add Player"}
            </Button>
          </SocialGrid.Column>
        )}
      </SocialGrid.Column>
    );
  };
  return (
    <WhiteContainer>
      <SideBar history={history} />

      <ContentGrid className={"listManagerContent"} columns={1}>
        <Grid columns={1}>
          <TitleSection
            title={"Add Player"}
            ready={true}
            showNextOption={false}
            titleSize={10}
            buttonsSize={6}
            nextButtonSize={6}
            disableButtons={loading}
            showLinkOption={withWritePermission}
            customLinkSection={customLinkSection()}
          />

          {currentStep === 0 ? (
            <AddEnterprisePlayerViewForm
              disabled={!withWritePermission}
              formSubmitRef={formSubmitRef}
              coachId={coachId}
              planId={planId!}
              preloadValues={playerToCreate}
              successNextHandler={(enterprisePlayerInfo) => {
                setPlayerToCreate(enterprisePlayerInfo[0]);
                setCurrentStep(1);
              }}
            />
          ) : (
            <ActiveCampaigns
              coachId={coachId}
              onChangeHandler={(response) => {
                setSelectedCampaignIds(() => Object.keys(response));
              }}
            />
          )}

          {loading && <Loader active size="large" />}
        </Grid>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const AddPlayer = withRouter(AddPlayerFC);
