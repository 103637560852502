import { defineMessages } from "react-intl";

export enum LoginType {
  email = "LoginType.email",
  password = "LoginType.password",
  submit = "LoginType.submit",
  submitPlayers = "LoginType.submitPlayers",
  forgotPassword = "LoginType.forgotPassword",
  title = "LoginType.title",
  titlePlayers = "LoginType.titlePlayers",
  subtitle = "LoginType.subtitle",
  sessionExpired = "LoginType.sessionExpired",
  signUp = "LoginType.signUp",
  warningTitle = "LoginType.warningTitle",
  errorTitle = "LoginType.errorTitle",
  infoTitle = "LoginType.infoTitle",
}

export const en = {
  [LoginType.email]: "Email Address",
  [LoginType.password]: "Password",
  [LoginType.submit]: "Next",
  [LoginType.submitPlayers]: "Sign In",
  [LoginType.forgotPassword]: "Forgot your password?",
  [LoginType.title]: "Sign in to SocialCoach",
  [LoginType.titlePlayers]: "Sign in",
  [LoginType.subtitle]: "Welcome back",
  [LoginType.sessionExpired]: "Your session has expired. Please log in again.",
  [LoginType.signUp]: 'Don’t have an account yet? Apply Here',
  [LoginType.warningTitle]: "Warning",
  [LoginType.errorTitle]: "Login error:",
  [LoginType.infoTitle]: "Hey",
};

export const descriptors = defineMessages({
  [LoginType.email]: {
    id: LoginType.email,
    defaultMessage: en[LoginType.email],
    description: "Email address",
  },
  [LoginType.password]: {
    id: LoginType.password,
    defaultMessage: en[LoginType.password],
    description: "Password text",
  },
  [LoginType.submit]: {
    id: LoginType.submit,
    defaultMessage: en[LoginType.submit],
    description: "Sign In text",
  },
  [LoginType.submitPlayers]: {
    id: LoginType.submitPlayers,
    defaultMessage: en[LoginType.submitPlayers],
    description: "submitPlayers",
  },
  [LoginType.forgotPassword]: {
    id: LoginType.forgotPassword,
    defaultMessage: en[LoginType.forgotPassword],
    description: "Link to forgot your password",
  },
  [LoginType.signUp]: {
    id: LoginType.signUp,
    defaultMessage: en[LoginType.signUp],
    description: "Link to sign up page",
  },
  [LoginType.title]: {
    id: LoginType.title,
    defaultMessage: en[LoginType.title],
    description: "Log In title",
  },
  [LoginType.titlePlayers]: {
    id: LoginType.titlePlayers,
    defaultMessage: en[LoginType.titlePlayers],
    description: "titlePlayers",
  },
  [LoginType.subtitle]: {
    id: LoginType.subtitle,
    defaultMessage: en[LoginType.subtitle],
    description: "Log In subtitle",
  },
  [LoginType.sessionExpired]: {
    id: LoginType.sessionExpired,
    defaultMessage: en[LoginType.sessionExpired],
    description: "Session Expired",
  },
  [LoginType.warningTitle]: {
    id: LoginType.warningTitle,
    defaultMessage: en[LoginType.warningTitle],
    description: "Warning messages title",
  },
  [LoginType.errorTitle]: {
    id: LoginType.errorTitle,
    defaultMessage: en[LoginType.errorTitle],
    description: "Error Messages title",
  },
  [LoginType.infoTitle]: {
    id: LoginType.infoTitle,
    defaultMessage: en[LoginType.infoTitle],
    description: "Info messages title",
  },
});
