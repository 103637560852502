import * as React from "react";
import { useIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { List, Grid, Header, Button } from "semantic-ui-react";

import { PlanSummaryType, descriptors } from "./descriptors";
import { BasicCard } from "../../containers/BasicCard";
import { URLS } from "../../utils/routes";
import { ConfigContext } from "../../contexts/appContexts";
import * as gtag from "../../utils/gtag";
import { BillingPeriod, PriceElement } from "../../interfaces";

import { IntervalPrice } from "./components";
import "./styles.scss";

interface OwnProps {
  /**
   * Plan title
   */
  title?: string;
  /**
   * Plan Offers
   */
  offers?: string[];
  /**
   * Plan cost
   */
  amount?: number;
  /**
   * With trial
   */
  trialAvailable?: boolean;

  /**
   * Base price is active
   */
  baseActive?: boolean;

  /**
   * Preview mode
   */
  preview: boolean;

  intervalPrices?: PriceElement[];
  /**
   * Coach code
   */
  coachCode: string;
  /**
   * Plan id
   */
  planId?: number;
}

type Props = OwnProps & RouteComponentProps;

const PlanSummaryFC: React.FC<Props> = ({
  title,
  offers,
  amount,
  baseActive = true,
  preview = false,
  trialAvailable = true,

  intervalPrices,
  coachCode,
  planId,
  history,
}) => {
  const configContext = React.useContext(ConfigContext);
  const { formatMessage } = useIntl();
  const activeIntervals = React.useMemo(() => {
    const basePrice: PriceElement[] = baseActive
      ? [
          {
            price: amount || 0,
            hasFreeTrial: trialAvailable,
            active: baseActive,
            interval: BillingPeriod.MONTHLY,
          },
        ]
      : [];

    const intervalPricesActives = intervalPrices
      ? intervalPrices!.filter((interval) => interval.active)
      : [];

    return basePrice.concat(intervalPricesActives);
  }, [intervalPrices, amount, trialAvailable, baseActive]);

  const planContent = (): JSX.Element => {
    return (
      <Grid className={"coachPlanContainer"} width={2}>
        <Grid.Row centered columns={2} className={"borderBox"}>
          <Grid.Column
            className={"coachPlanTitleSection"}
            mobile={16}
            tablet={16}
            computer={16}
            largescreen={16}
          >
            <Header as="h3" textAlign={"center"}>
              {title}
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid className={"coachPlanOffers"}>
          <Grid.Row className={"smallPaddingBottom"}>
            <Grid.Column className={"coachPlanLabel"}>
              {formatMessage({ ...descriptors[PlanSummaryType.planTitle] })}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className={"noPaddingTop"}>
            <Grid.Column className={"noPaddingLateral"}>
              <List className={"offerSection"}>
                {offers &&
                  offers.map((offer, index) => {
                    return (
                      <List.Item
                        className={"offerLabel"}
                        key={"offer_label_item" + index}
                      >
                        <List.Icon name="check" className={"primaryIcon"} />
                        <List.Content> {offer}</List.Content>
                      </List.Item>
                    );
                  })}
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {activeIntervals &&
          activeIntervals.map((intervalPrice) => {
            return (
              <IntervalPrice
                key={intervalPrice.interval}
                intervalPrice={intervalPrice}
              />
            );
          })}
        <Grid.Row centered className={"smallLateralMargin"}>
          <Button
            disabled={preview}
            fluid
            centered
            className={"primary bordered bigSize"}
            data-elm-id={`planSummaryTrialDaysBtn`}
            onClick={(e) => {
              // gtag.event("begin_sign_up", {
              //   event_label: e.currentTarget.innerText,
              // });
              gtag.eventTrack(e.currentTarget.innerText, {});

              history.push(
                URLS.player.signUpBasic +
                  coachCode +
                  ((planId && `?planId=${planId}`) || "")
              );
            }}
          >
            {formatMessage(
              {
                ...descriptors[
                  trialAvailable
                    ? PlanSummaryType.trialDescription
                    : PlanSummaryType.startNow
                ],
              },
              {
                trialDays: configContext.trialDays,
                s:
                  configContext.trialDays && configContext.trialDays > 1
                    ? "s"
                    : "",
              }
            )}
          </Button>
        </Grid.Row>
      </Grid>
    );
  };

  return <BasicCard title={""} size={"big"} content={planContent()} />;
};

export const PlanSummary = withRouter(PlanSummaryFC);
