import * as React from "react";
import { ScheduleService } from "../../../../services";
import { DayOfWeek, ScheduleType, Prompt } from "../../../../interfaces/dtos";
import MultipleDaySelector from "../MultipleDaySelector";
import { Grid, Message, Header, Button, Loader, Icon } from "semantic-ui-react";
import { URLS } from "../../../../utils/routes";
import { descriptors, PromptsType, getDefaultInfoMessages } from "../../descriptors";
import{ useIntl } from "react-intl";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { isNumber } from "lodash";
import "./styles.scss";

interface OwnProps {
  coachId: string;
  minimumDaysconfigurable: number;
  defaultDeliveryTime: string;
  activePrompts: number | undefined;
  minActivePrompts: number;
  welcomePromptRequired: boolean;
  activeWelcomePrompt: Prompt | undefined;
}

type Props = OwnProps   & RouteComponentProps;

type State = DayOfWeek[];

const saveWeeklySchedule = (coachId: string, selectedDays: State, scheduleId: number | undefined) => {
  const schedule = {
    coachId,
    type: ScheduleType.RANDOM_PROMPTS_DELIVERY,
    schedule: selectedDays.map(dayOfWeek => ({
      dayOfWeek,
    })),
  };
  if (!scheduleId) {
    return ScheduleService.saveRandomWeeklySchedule(schedule);
  } else {
    return ScheduleService.updateRandomWeeklySchedule(scheduleId, schedule);
  }
};

export default withRouter(
  (
    ({
      coachId,
      history,
      defaultDeliveryTime,
      activePrompts,
      minActivePrompts,
      minimumDaysconfigurable,
      welcomePromptRequired,
      activeWelcomePrompt,
    }: Props) => {
      const [selectedDays, setSelectedDays] = React.useState<State>([]);
      const [loading, setLoading] = React.useState<boolean>(false);
      const [scheduleId, setScheduleId] = React.useState<number | undefined>();
      const [error, setError] = React.useState<boolean>(false);
      const { formatMessage } = useIntl();

      React.useEffect(() => {
        if (coachId && selectedDays.length >= minimumDaysconfigurable) {
          saveWeeklySchedule(coachId, selectedDays, scheduleId).then(scheduleSaved => {
            setScheduleId(scheduleSaved.id!!);
          });
        }
      }, [selectedDays]);

      React.useEffect(() => {
        const getInitialData = async () => {
          setLoading(true);
          if (coachId) {
            try {
              const { schedule, id } = await ScheduleService.getRandomWeeklySchedule(
                coachId,
                ScheduleType.RANDOM_PROMPTS_DELIVERY
              );
              setScheduleId(id);
              setSelectedDays(schedule.map(({ dayOfWeek }) => dayOfWeek));
            } catch (e: any) {
              if (!(e.status && e.status === 404)) {
                setError(true);
              }
            } finally {
              setLoading(false);
            }
          }
        };

        getInitialData();
      }, [coachId]);

      const topSection: JSX.Element = (
        <Grid.Row>
          <Grid.Column floated={"left"} width={10} data-elm-id="dayListSelector" className={"greyBox daysBox"}>
            {loading ? (
              <Loader active size="large" />
            ) : (
              <MultipleDaySelector selected={selectedDays} onChange={setSelectedDays} />
            )}
            <br />
            <Header as="span" floated={"right"} className={"paddinBottom"}>
              {formatMessage({ ...descriptors[PromptsType.limitDaysMessage] })}
            </Header>
          </Grid.Column>

          <Grid.Column floated={"right"} width={5}>
            <Button
              data-elm-id={"addPromptButton"}
              className={"primary marginBottom"}
              onClick={() => history.push(URLS.coach.newPrompt)}
              compact
              fluid
              icon>
              {formatMessage({ ...descriptors[PromptsType.addPrompt] })}
            </Button>
            <Header as="h6" className={"importantMessages"}>
              <Icon name="clock outline" />
              {getDefaultInfoMessages(defaultDeliveryTime)[0]}
            </Header>
            <Header as="h6" className={"importantMessages"}>
              <Icon name="calendar outline" />
              {getDefaultInfoMessages(defaultDeliveryTime)[1]}
            </Header>
          </Grid.Column>
        </Grid.Row>
      );

      return (
        <Grid className={"scheduleGrid"} columns={2}>
          {isNumber(activePrompts) && activePrompts < minActivePrompts && (
            <Message negative>
              <Message.Header>
                {minActivePrompts - activePrompts} {formatMessage({ ...descriptors[PromptsType.morePromptsRequired] })}
              </Message.Header>
            </Message>
          )}

          {!activeWelcomePrompt && welcomePromptRequired && (
            <Message negative>
              <Message.Header>{formatMessage({ ...descriptors[PromptsType.welcomePromptRequired] })}</Message.Header>
            </Message>
          )}

          {topSection}
        </Grid>
      );
    }
  )
);
