import { defineMessages } from "react-intl";

export enum ProfileInformationType {
  columnTitle = "ProfileInformation.columnTitle",
  firstName = "ProfileInformation.firstName",
  lastName = "ProfileInformation.lastName",
  title = "ProfileInformation.title",
  company = "ProfileInformation.company",
  birthday = "ProfileInformation.birthday",
  phoneNumber = "ProfileInformation.phoneNumber",
  changePassword = "ProfileInformation.changePassword",
}

export const en = {
  [ProfileInformationType.columnTitle]: "Upload your photo",
  [ProfileInformationType.firstName]: "First Name",
  [ProfileInformationType.lastName]: "Last Name",
  [ProfileInformationType.title]: "Title",
  [ProfileInformationType.company]: "Company",
  [ProfileInformationType.birthday]: "Birthday",
  [ProfileInformationType.phoneNumber]: "Phone Number",
  [ProfileInformationType.changePassword]: "Change Password",
};

export const descriptors = defineMessages({
  [ProfileInformationType.columnTitle]: {
    id: ProfileInformationType.columnTitle,
    defaultMessage: en[ProfileInformationType.columnTitle],
    description: "columnTitle text",
  },
  [ProfileInformationType.firstName]: {
    id: ProfileInformationType.firstName,
    defaultMessage: en[ProfileInformationType.firstName],
    description: "firstName text",
  },
  [ProfileInformationType.lastName]: {
    id: ProfileInformationType.lastName,
    defaultMessage: en[ProfileInformationType.lastName],
    description: "lastName text",
  },
  [ProfileInformationType.title]: {
    id: ProfileInformationType.title,
    defaultMessage: en[ProfileInformationType.title],
    description: "title text",
  },
  [ProfileInformationType.company]: {
    id: ProfileInformationType.company,
    defaultMessage: en[ProfileInformationType.company],
    description: "company text",
  },
  [ProfileInformationType.birthday]: {
    id: ProfileInformationType.birthday,
    defaultMessage: en[ProfileInformationType.birthday],
    description: "birthday text",
  },
  [ProfileInformationType.phoneNumber]: {
    id: ProfileInformationType.phoneNumber,
    defaultMessage: en[ProfileInformationType.phoneNumber],
    description: "phoneNumber text",
  },
  [ProfileInformationType.changePassword]: {
    id: ProfileInformationType.changePassword,
    defaultMessage: en[ProfileInformationType.changePassword],
    description: "changePassword text",
  },
});
