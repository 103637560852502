import { defineMessages } from "react-intl";

export enum ProfileContactInformationType {
  title = "ProfileContactInformationType.title",
  hashtagsTitle = "ProfileContactInformationType.hashtagsTitle",
  subtitle = "ProfileContactInformationType.subtitle",
  customSlug = "ProfileContactInformationType.customSlug",
  webAddress = "ProfileContactInformationType.webAddress",
  customSlugInfo = "ProfileContactInformationType.customSlugInfo",
  transactionsClosed = "ProfileContactInformationType.transactionsClosed",
}

export const en = {
  [ProfileContactInformationType.title]: "Short Link",
  [ProfileContactInformationType.hashtagsTitle]: "Hashtags",

  [ProfileContactInformationType.subtitle]: "",

  [ProfileContactInformationType.customSlug]: "Short Link",
  [ProfileContactInformationType.webAddress]: "Web Address",

  [ProfileContactInformationType.transactionsClosed]: "Number of transactions you closed last year",

  [ProfileContactInformationType.customSlugInfo]:
    "This Short Link acts as a proxy for the web address you’ve chosen and redirects those who click on it to your site. It’s meant to easily fit in your social posts, so it should be kept short and simple",
};

export const descriptors = defineMessages({
  [ProfileContactInformationType.title]: {
    id: ProfileContactInformationType.title,
    defaultMessage: en[ProfileContactInformationType.title],
    description: "title text",
  },
  [ProfileContactInformationType.hashtagsTitle]: {
    id: ProfileContactInformationType.hashtagsTitle,
    defaultMessage: en[ProfileContactInformationType.hashtagsTitle],
    description: "hashtagsTitle text",
  },
  [ProfileContactInformationType.subtitle]: {
    id: ProfileContactInformationType.subtitle,
    defaultMessage: en[ProfileContactInformationType.subtitle],
    description: "subtitle text",
  },
  [ProfileContactInformationType.customSlug]: {
    id: ProfileContactInformationType.customSlug,
    defaultMessage: en[ProfileContactInformationType.customSlug],
    description: "text",
  },
  [ProfileContactInformationType.customSlugInfo]: {
    id: ProfileContactInformationType.customSlugInfo,
    defaultMessage: en[ProfileContactInformationType.customSlugInfo],
    description: "",
  },
  [ProfileContactInformationType.webAddress]: {
    id: ProfileContactInformationType.webAddress,
    defaultMessage: en[ProfileContactInformationType.webAddress],
    description: "",
  },
  [ProfileContactInformationType.transactionsClosed]: {
    id: ProfileContactInformationType.transactionsClosed,
    defaultMessage: en[ProfileContactInformationType.transactionsClosed],
    description: "",
  },
});
