import * as React from "react";
import { Field } from "formik";
import MaskedInput from "react-text-mask";
import{ useIntl, FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { Header, Form, Popup, Icon } from "semantic-ui-react";
import { isNil, isEqual } from "lodash";

import { UserRegistered, BasicFormProps } from "../../../interfaces";
import { phoneNumberMask, dateMask, UIDate } from "../../../utils";
import { FloatedCard } from "../../../containers";

import { SignUpType, descriptors } from "./descriptors";
import { fields, passwordFields } from "./formFields";
import "./styles.scss";

interface OwnProps extends BasicFormProps {
  userRegistered?: UserRegistered;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  birthdate?: string;
  password?: string;
  confirmPassword?: string;

  handleSetFieldValue?: (field: string, value: any) => void;
  toSignIn?: () => void;
}

type Props = OwnProps   & RouteComponentProps;

/**
 * Represents a SignUp screen.
 */
const SignUpFC: React.FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  const {
    messages,
      handleChange,
      handleSubmit,
      userRegistered,
      birthdate,
      phoneNumber,
      password,
      confirmPassword,
      toSignIn,
      isSubmitting = false,
  } = props;
  const startDate = birthdate ? UIDate(birthdate) : birthdate;

  const content = (
    <React.Fragment>
      <Header textAlign={"center"} as="h2">
        {formatMessage({ ...descriptors[SignUpType.title] })}
      </Header>

      {fields.map((field, index) => {
        return (
          <Form.Group widths="equal" key={"group" + index}>
            <Form.Input
              key={field[0].id + index + "_l"}
              data-elm-id={"registerInput" + field[0].id}
              fluid
              id={field[0].id}
              type={field[0].type}
              name={field[0].name}
              placeholder={formatMessage({ ...descriptors[field[0].placeholder] })}
              onChange={handleChange}
              value={props[field[0].id]}
              disabled={isSubmitting || (!isNil(userRegistered?.account) && isEqual(field[0].id, "email"))}
            />
            {field.length > 1 && (
              <Form.Input
                key={field[1].id + index + "_r"}
                data-elm-id={"registerInput" + field[1].id}
                fluid
                id={field[1].id}
                type={field[1].type}
                name={field[1].name}
                placeholder={formatMessage({ ...descriptors[field[1].placeholder] })}
                value={props[field[1].id]}
                onChange={handleChange}
                disabled={isSubmitting}
              />
            )}
          </Form.Group>
        );
      })}

      <Form.Group widths="equal" key={"group3"}>
        <div className="field">
          <Field
            data-elm-id={"registerInputBirthdate"}
            name={"birthdate"}
            render={({ birthdateField }: { birthdateField: any }) => (
              <MaskedInput
                {...birthdateField}
                value={startDate}
                mask={dateMask}
                id="birthdate"
                placeholder={formatMessage({ ...descriptors[SignUpType.birthdate] })}
                type="text"
                disabled={isSubmitting}
                onChange={handleChange}
              />
            )}
          />
        </div>
        <div className="field">
          <Field
            name="phoneNumber"
            data-elm-id={"registerInputPhoneNumber"}
            render={({ phoneField }: { phoneField: any }) => (
              <MaskedInput
                {...phoneField}
                value={phoneNumber}
                mask={phoneNumberMask}
                id="phoneNumber"
                placeholder={formatMessage({ ...descriptors[SignUpType.phoneNumber] })}
                type="text"
                onChange={handleChange}
                disabled={isSubmitting}
              />
            )}
          />
        </div>
      </Form.Group>

      {passwordFields.map((field, index) => {
        return (
          <Form.Group widths="equal" key={"group" + index}>
            <Form.Input
              key={field[0].id + index + "_l"}
              data-elm-id={"registerInput" + field[0].id}
              fluid
              icon={
                <Popup trigger={<Icon name="info circle" className={"infoIcon"} link />}>
                  <FormattedMessage {...descriptors[SignUpType.passwordWithNumber]} />
                  <br />
                  <FormattedMessage {...descriptors[SignUpType.passwordWithCapital]} />
                  <br />
                  <FormattedMessage {...descriptors[SignUpType.passwordWithSpecialCharacter]} />
                  <br />
                  <FormattedMessage {...descriptors[SignUpType.passwordMin]} />
                </Popup>
              }
              id={field[0].id}
              type={field[0].type}
              name={field[0].name}
              placeholder={formatMessage({ ...descriptors[field[0].placeholder] })}
              onChange={handleChange}
              value={password}
              disabled={isSubmitting || !isNil(userRegistered?.account)}
            />
            <Form.Input
              key={field[1].id + index + "_r"}
              data-elm-id={"registerInput" + field[1].id}
              fluid
              id={field[1].id}
              type={field[1].type}
              name={field[1].name}
              placeholder={formatMessage({ ...descriptors[field[1].placeholder] })}
              onChange={handleChange}
              value={confirmPassword}
              disabled={isSubmitting || !isNil(userRegistered?.account)}
            />
          </Form.Group>
        );
      })}

      <Header as="h5" textAlign="center">
        1 of 2
      </Header>
      <Form.Group widths="equal" className={"buttonContainer"}>
        <Form.Button
          data-elm-id={"registerButtonToSignIn"}
          color={"grey"}
          size="large"
          type="button"
          disabled={isSubmitting}
          onClick={toSignIn}
          className={"signUp"}>
          <FormattedMessage {...descriptors[SignUpType.signIn]} />
        </Form.Button>

        <Form.Button
          data-elm-id={"registerButtonSubmit"}
          size="large"
          type="submit"
          disabled={isSubmitting}
          onClick={handleSubmit}
          className={"signUp"}>
          <FormattedMessage {...descriptors[SignUpType.submit]} />
        </Form.Button>
      </Form.Group>
    </React.Fragment>
  );

  return (
    <FloatedCard
      subtitle={formatMessage({ ...descriptors[SignUpType.subtitle] })}
      content={content}
      messages={messages}
      isSubmitting={isSubmitting}
      size={"bigCentered"}
    />
  )
}

export const SignUp = withRouter(SignUpFC)
