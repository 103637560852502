import * as React from "react";

import { UserContext } from "@socialcoach/services";

import { PlayerSocialAccounts } from "../../../components";

import { WhiteContainer } from "../../../styling/baseStyle";
import "./styles.scss";

interface OwnProps {
  successHandler: (user: UserContext) => void;
}

type Props = OwnProps;

const EmbeddedSocialAccountsScreen: React.FC<Props> = ({}) => {
  return (
    <WhiteContainer className={"embeddedSocialNetworks"}>
      <PlayerSocialAccounts type={"PROFILE"} showTitle={false} deleteEnable={true} />
    </WhiteContainer>
  );
};

export const EmbeddedPlayerSocialAccounts = EmbeddedSocialAccountsScreen;
