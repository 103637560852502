import * as React from "react";
import { Grid, Form, Message } from "semantic-ui-react";
import { Formik } from "formik";
import { UsersService } from "../../../services";

import "./styles.scss";

interface OwnProps {
  toUpdateEmail: string;
  userId: number;
  handleUpdateSuccess?: (email: string) => void;
}

export const PlayerInformation: React.FC<OwnProps> = ({ userId, toUpdateEmail, handleUpdateSuccess }) => {
  return (
    <Grid columns="1">
      <Grid.Row>
        <Grid.Column>
          <Formik
            initialValues={{
              email: toUpdateEmail || "",
            }}
            onSubmit={async ({ email }, { setErrors }) => {
              try {
                const updatedUser = {
                  email,
                  username: email,
                };
                UsersService.updateUserCredentials(userId, updatedUser)
                  .then(() => {
                    if (handleUpdateSuccess) {
                      handleUpdateSuccess(email);
                    }
                  })
                  .catch(e => {
                    setErrors({
                      email: "Invalid email address",
                    });
                  });
              } catch (e) {
                setErrors({
                  email: "Invalid email address",
                });
              }
            }}>
            {({ values, errors, handleChange, handleSubmit }) => {
              const errorList = Object.values(errors) as string[];
              return (
                <Form data-elm-id="player-information-form" error={errorList.length > 0} onSubmit={handleSubmit}>
                  <Form.Group widths="equal">
                    <Form.Input
                      value={values.email}
                      onChange={handleChange}
                      data-elm-id="email-input"
                      type="text"
                      size="mini"
                      id="email"
                      name="email"
                      fluid
                      label="Email"
                      required
                      placeholder="Email"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Button compact type="submit" floated={"right"}>
                      Save Player
                    </Form.Button>
                  </Form.Group>

                  <Message error list={errorList} />
                </Form>
              );
            }}
          </Formik>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
