import * as Yup from "yup";
import { Rules } from "../../../../../utils/rules";
import { CampaignCustomForm } from ".";

export const validationSchemaBuilder = (customForm: CampaignCustomForm) => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("Title is required")
      .max(Rules.MAX_TITLE, "Title is too long"),
    deliveryMode: Yup.string()
      .trim()
      .required("Delivery type is required"),
    randomScheduleDays: Yup.array().when("deliveryMode", {
      is: (a: any) => a === "ORDERED_RANDOM" || a === "RANDOM_DELIVERY",
      then: () => Yup.array().min(1, "You must select at least one day"),
    }),
  });
};
