import * as React from "react";
import { Grid, Header, Image } from "semantic-ui-react";

import { fullNameFrom, getSubscriptionTypeWithPlayerStatus, printOnlyDate } from "../../../../../utils";
import { Svg } from "../../../../../assets";
import { PlayerAccount, PricePlanTier } from "../../../../../interfaces";

import { BasicContainer } from "../../../../../styling/baseStyle";
import { ButtonLink, EditableField } from "../../../../../components";
import{ useIntl } from "react-intl";
import { PlayerDetailType, descriptors } from "./descriptors";

import "../../styles.scss";
import { ActionList } from "../../../../Admin/MergedActions";
interface OwnProps {
  playerAccount: PlayerAccount;
  verificationDate?: string;
  subscriptionType?: PricePlanTier;

  showResetPassword: boolean;
  isActivePlayer: boolean;
  scPlusFeatureActive?: boolean;
  handleChangeEmailValue: (value: string) => void;
  resetPasswordHandler: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  handleCancelPlayer: () => void;
  handleChangeSubscriptionType?: (type: PricePlanTier) => void;
}

type Props = OwnProps 

interface MenuElement {
  content: string;
  icon: string;
}
const PlayerInfoFC: React.FC<Props> = ({
  showResetPassword,
  playerAccount,
  verificationDate,
  isActivePlayer,
  subscriptionType,
  scPlusFeatureActive,
  resetPasswordHandler,
  handleChangeEmailValue,
  handleCancelPlayer,
  handleChangeSubscriptionType,
}) => {
  const { formatMessage } = useIntl();
  
  const [playerEmailUpdated, setPlayerEmailUpdated] = React.useState<string>(playerAccount.email || "");
  const [options, setOptions] = React.useState<MenuElement[]>([]);

  const withFullName = Boolean(playerAccount.firstName || playerAccount.lastName);
  const emailLink = <a href={`mailto:${playerAccount.email}`}>{playerAccount.email}</a>;

  React.useEffect(() => {
    if (playerEmailUpdated !== playerAccount.email && playerEmailUpdated !== "") {
      handleChangeEmailValue(playerEmailUpdated);
    }
  }, [playerEmailUpdated]);

  React.useEffect(() => {
    const menuElements = [];
    const isBasic = subscriptionType === PricePlanTier.PLAYER_BASIC;

    if (scPlusFeatureActive) {
      if (isBasic) {
        menuElements.push({
          content: "Upgrade to SocialCoach+",
          icon: "level up",
        });
      } else {
        menuElements.push({
          content: "Downgrade to SocialCoach Basic",
          icon: "level down",
        });
      }
    }

    if (isActivePlayer) {
      menuElements.push({
        content: "Deactivate Player",
        icon: "user cancel",
      });
    }

    setOptions(menuElements);
  }, [isActivePlayer, scPlusFeatureActive, subscriptionType]);

  const handleThreeDotsMenuAction = (index: number): void => {
    switch (index) {
      case options.length - 1:
        handleCancelPlayer();
        break;
      case 0 && options.length > 1:
        const newTier =
          subscriptionType === PricePlanTier.PLAYER_BASIC ? PricePlanTier.PLAYER_PLUS : PricePlanTier.PLAYER_BASIC;
        if (handleChangeSubscriptionType) {
          handleChangeSubscriptionType(newTier);
        }
        break;
      default:
        break;
    }
  };

  return (
    <BasicContainer>
      <Grid.Row className={"playerInfoSection"}>
        <Image circular wrapped size="tiny" src={playerAccount.photoUrl || Svg.noImageuser} />

        <Header as="h2" textAlign="left">
          {withFullName && playerAccount ? fullNameFrom(playerAccount!) : emailLink}
        </Header>
      </Grid.Row>
      {withFullName && (
        <Grid.Row className={"playerEmailSection "}>
          <Grid container={true}>
            <EditableField
              width={10}
              headerType={"h3"}
              dataElmId={`playerEmail${playerAccount.playerId}`}
              currentValue={playerEmailUpdated}
              maxLength={200}
              handleChangeValue={setPlayerEmailUpdated}
              cancelable={true}
              disable={false}
            />
          </Grid>
        </Grid.Row>
      )}

      {showResetPassword && (
        <Grid.Row className="resetPlayerPassword">
          <ButtonLink
            message={descriptors[PlayerDetailType.resetPassword]}
            onClick={resetPasswordHandler}
            type={"underline"}
          />
        </Grid.Row>
      )}
      {verificationDate && (
        <Grid.Row className={"playerInfoSection"}>
          <p className={"marginTop"}>
            <Grid.Column className={"subscriptionTypeSection noPaddingLeft"} floated="left">
              <Header as="h3" className={"permissionTitle"}>
                {formatMessage({ ...descriptors[PlayerDetailType.subscriptionTitle] })}
              </Header>
              <div>
                <Header as="h3" className={"subscriptionLabel"}>
                  {getSubscriptionTypeWithPlayerStatus(isActivePlayer, scPlusFeatureActive, subscriptionType)}
                </Header>
                {isActivePlayer && (
                  <span className={"subscriptionMenu"}>
                    <ActionList
                      items={options}
                      handleAction={async index => handleThreeDotsMenuAction(index)}
                      iconName={"ellipsis vertical"}
                    />
                  </span>
                )}
              </div>
              <div className={"subscriptionDate"}>{"Subscribed " + printOnlyDate(verificationDate)}</div>
            </Grid.Column>
          </p>
        </Grid.Row>
      )}
    </BasicContainer>
  );
};

export const PlayerInfo = PlayerInfoFC
