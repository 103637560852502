import React from "react";

const useInterval = (callback: () => void, delay: number | undefined) => {
  const intervalRef = React.useRef<any | undefined>();
  const callbackRef = React.useRef(callback);

  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  React.useEffect(() => {
    if (typeof delay === "number" && !isNaN(delay)) {
      intervalRef.current = setInterval(() => callbackRef.current(), delay);

      return () => clearInterval(intervalRef.current || 0);
    }
  }, [delay]);

  return intervalRef;
};

export { useInterval };
