import * as React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { map, get } from "lodash";
import {
  Form,
  Popup,
  Icon,
  Grid,
  Message,
  Header,
  DropdownItemProps,
} from "semantic-ui-react";

import {
  fields,
  passwordFields,
  basicPermissions,
  enterprisePermissions,
} from "./formFields";

import { BasicFormProps } from "../../interfaces";
import { CoachService, SubCoachesServices } from "../../services";
import { SegmentationDropDown } from "./components/SegmenationDropDown";

import { SubCoachSignUpType, descriptors } from "./descriptors";
import "./styles.scss";
import { InfoPopup } from "../AddCategoryComponent/styled";

interface OwnProps extends BasicFormProps {
  /**
   * Subcoach creation date / null when on creation view
   */
  createdDate?: string;

  /**
   * Subcoach latest login date
   */
  latestLoginDate?: string;

  /**
   * Forms ref - calls submit outside form
   */
  formSubmitRef: any;

  /*
   * Indicate if is a basic coach
   */
  basicCoach?: boolean;

  coachId: string;
  userId?: number;
  /*
   * Indicate if coach has write permissions
   */
  withWritePermission?: boolean;
  /**
   * Handle radio button changes - Permission's changes
   */
  handleSetFieldValue: (field: string, value: any) => void;
}

type Props = OwnProps; 

/**
 * Represents a Sub coach SignUp screen.
 */
const SubCoachSignUpFC: React.FC<Props> = ({
  values,
  messages,
  isSubmitting,
  formSubmitRef,
  createdDate,
  latestLoginDate,
  basicCoach = true,
  coachId,
  userId,

  handleChange,
  handleSubmit,
  handleSetFieldValue,
  withWritePermission,
}) => {
  const { formatMessage } = useIntl();
  const errors = messages && messages.error && messages.error.length > 0;
  const warnings = messages && messages.warn && messages.warn.length > 0;
  const permissions = basicCoach ? basicPermissions : enterprisePermissions;

  const [selectedBranches, setSelectedBranches] = React.useState<
    string[] | undefined
  >(values.branches);
  const [selectedRegions, setSelectedRegions] = React.useState<
    string[] | undefined
  >(values.regions);

  const [defaultBranches, setDefaultBranches] = React.useState<
    DropdownItemProps[] | undefined
  >(undefined);
  const [defaultRegions, setDefaultRegions] = React.useState<
    DropdownItemProps[] | undefined
  >(undefined);

  const [branchesFilters, setBranchesFilters] = React.useState<
    DropdownItemProps[]
  >([]);
  const [regionFilters, setRegionsFilters] = React.useState<
    DropdownItemProps[]
  >([]);

  React.useEffect(() => {
    handleSetFieldValue("branches", selectedBranches);
  }, [selectedBranches]);

  React.useEffect(() => {
    handleSetFieldValue("regions", selectedRegions);
  }, [selectedRegions]);

  React.useEffect(() => {
    CoachService.branches(coachId).then((response) => {
      const filters: DropdownItemProps[] = map(response, (branch) => ({
        key: branch.branchId,
        text: branch.name,
        value: branch.branchId,
      }));
      setBranchesFilters(filters);
    });
    CoachService.regions(coachId).then((response) => {
      const filters: DropdownItemProps[] = map(response, (region) => ({
        key: region.regionId,
        text: region.name,
        value: region.regionId,
      }));
      setRegionsFilters(filters);
    });
  }, [coachId]);

  React.useEffect(() => {
    if (userId) {
      SubCoachesServices.getSegmentation(coachId, userId).then((response) => {
        const subcoachBranches = response.filter(
          (segmentation) => segmentation.type === "BRANCH"
        );
        const subcoachRegions = response.filter(
          (segmentation) => segmentation.type === "REGION"
        );

        let filters: DropdownItemProps[] = map(subcoachBranches, (branch) => ({
          key: branch.segmentationDetailId,
          text: branch.name,
          value: branch.segmentationDetailId,
        }));
        setDefaultBranches(filters);

        filters = map(subcoachRegions, (branch) => ({
          key: branch.segmentationDetailId,
          text: branch.name,
          value: branch.segmentationDetailId,
        }));

        setDefaultRegions(filters);

        setSelectedBranches(() => {
          return subcoachBranches.map(
            (segmentation) => `${segmentation.segmentationDetailId}`
          );
        });
        setSelectedRegions(() => {
          return subcoachRegions.map(
            (segmentation) => `${segmentation.segmentationDetailId}`
          );
        });
      });
    }
  }, [userId]);

  const permissionsHandleChange = (e: any, data: any) => {
    handleSetFieldValue("permissions." + data.name, data.value);
  };

  const fieldsSection = (
    <Grid.Column>
      <Header as="h4" className={"permissionTitle"}>
        Account Information
      </Header>
      {fields.map((field, index) => {
        return (
          <Form.Group
            widths="equal"
            key={"subcoachSignUpGroup_" + field[0].id + "_" + index}
          >
            <Form.Input
              className={"scFormInput"}
              key={field[0].id + index + "_l"}
              data-elm-id={"subcoachSignUpInput" + field[0].id}
              fluid
              id={field[0].id}
              type={field[0].type}
              label={field[0].label}
              name={field[0].name}
              placeholder={formatMessage({
                ...descriptors[field[0].placeholder],
              })}
              onChange={handleChange}
              value={values[field[0].id]}
              disabled={isSubmitting}
            />
          </Form.Group>
        );
      })}

      {passwordFields.map((field, index) => {
        return (
          <Form.Group widths="equal" key={"subcoachSignUpGroup_" + field[0].id}>
            <Form.Input
              className={"scFormInput"}
              key={field[0].id + index + "_l"}
              data-elm-id={"subcoachSignUpInput" + field[0].id}
              fluid
              label={field[0].label}
              icon={
                <Popup
                  trigger={
                    <Icon name="info circle" className={"infoIcon"} link />
                  }
                >
                  <FormattedMessage
                    {...descriptors[SubCoachSignUpType.passwordWithNumber]}
                  />
                  <br />
                  <FormattedMessage
                    {...descriptors[SubCoachSignUpType.passwordWithCapital]}
                  />
                  <br />
                  <FormattedMessage
                    {...descriptors[
                      SubCoachSignUpType.passwordWithSpecialCharacter
                    ]}
                  />
                  <br />
                  <FormattedMessage
                    {...descriptors[SubCoachSignUpType.passwordMin]}
                  />
                </Popup>
              }
              id={field[0].id}
              type={field[0].type}
              name={field[0].name}
              placeholder={formatMessage({
                ...descriptors[field[0].placeholder],
              })}
              onChange={handleChange}
              value={values[field[0].id]}
              disabled={isSubmitting}
            />
          </Form.Group>
        );
      })}
      {createdDate && (
        <>
          <Grid.Row>
            <label className={"permissionLabel"}>
              Date Added {createdDate}
            </label>
          </Grid.Row>
          <Grid.Row>
            <label className={"permissionLabel"}>
              Last Login {latestLoginDate}
            </label>
          </Grid.Row>
        </>
      )}
    </Grid.Column>
  );

  const permissionsSection = (
    <Grid.Column className={isSubmitting ? "disabledForm" : ""}>
      <Form.Group grouped key={"subcoachSignUpGroup2Permissions"}>
        <Header as="h4" className={"permissionTitle"}>
          Access
        </Header>
        {permissions.map((permission) => {
          return (
            <>
              <label className={"permissionLabel"}>{permission.label}</label>
              <Form.Group inline key={permission.id}>
                <Form.Radio
                  className={"permissionLevelLabel"}
                  label="Read"
                  value={"READ"}
                  onChange={permissionsHandleChange}
                  name={permission.id}
                  checked={values.permissions[permission.id] === "READ"}
                />
                <Form.Radio
                  className={"permissionLevelLabel"}
                  label="Write"
                  value={"WRITE"}
                  onChange={permissionsHandleChange}
                  name={permission.id}
                  checked={values.permissions[permission.id] === "WRITE"}
                />
                <Form.Radio
                  className={"permissionLevelLabel"}
                  label="None"
                  value={"NONE"}
                  onChange={permissionsHandleChange}
                  name={permission.id}
                  checked={values.permissions[permission.id] === "NONE"}
                />
              </Form.Group>
            </>
          );
        })}
      </Form.Group>
    </Grid.Column>
  );

  const segmentationSection = (
    <>
      <Grid.Row floated="left" className="segmentationDiv">
        <Header as="h4" className={"permissionTitle"}>
          {formatMessage({
            ...descriptors[SubCoachSignUpType.segmenationSectionTitle],
          })}
          <InfoPopup
            content={formatMessage({
              ...descriptors[SubCoachSignUpType.segmenationPopUpInfo],
            })}
            trigger={<Icon name="info circle" className={"infoIcon"} link />}
          />
        </Header>
      </Grid.Row>
      <Grid.Row
        className={isSubmitting ? "disabledForm noPadding" : "noPadding"}
        floated="left"
      >
        <Grid columns={2}>
          {/* BRANCH */}
          <SegmentationDropDown
            placeHolder={formatMessage({
              ...descriptors[SubCoachSignUpType.branches],
            })}
            type={formatMessage({
              ...descriptors[SubCoachSignUpType.branches],
            })}
            elements={branchesFilters}
            selectedElements={selectedBranches}
            setFunction={setSelectedBranches}
            defaultElementsItems={defaultBranches}
          />
          {/* REGION */}
          <SegmentationDropDown
            placeHolder={formatMessage({
              ...descriptors[SubCoachSignUpType.regions],
            })}
            type={formatMessage({ ...descriptors[SubCoachSignUpType.regions] })}
            elements={regionFilters}
            selectedElements={selectedRegions}
            setFunction={setSelectedRegions}
            defaultElementsItems={defaultRegions}
          />
        </Grid>
      </Grid.Row>
    </>
  );

  const infoMessages = get(messages, "info", []);
  const warningMessages = get(messages, "warn", []);
  const errorMessages = get(messages, "error", []);

  return (
    <Grid
      columns={16}
      verticalAlign="middle"
      stretched
      container
      textAlign={"left"}
      className={
        isSubmitting || !withWritePermission
          ? "floatedGrid disabledForm"
          : "floatedGrid"
      }
    >
      <Grid.Column width={16}>
        <Form
          size="large"
          error={errors}
          warning={warnings}
          ref={formSubmitRef}
          onSubmit={handleSubmit}
          className={"floatedFormBox"}
          loading={isSubmitting}
          disabled={!withWritePermission}
        >
          {errorMessages.length > 0 && (
            <Message error header={"Errors:"} list={errorMessages} />
          )}
          {warningMessages.length > 0 && (
            <Message warning header={"Warnings: "} list={warningMessages} />
          )}
          {infoMessages.length > 0 && (
            <Message header={"Info: "} list={infoMessages} />
          )}

          <Grid columns={2} padded>
            {fieldsSection}
            {permissionsSection}
          </Grid>
          <Grid columns={1} padded>
            {segmentationSection}
          </Grid>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export const SubCoachSignUp = SubCoachSignUpFC;
