import * as React from "react";
import { useIntl } from "react-intl";
import { DropdownItemProps, Icon } from "semantic-ui-react";

import { get, map } from "lodash";

import { CategoriesTags } from "./TagSection";
import { AppContext } from "../../providers";
import { CategoriesServices } from "../../services";
import * as DTO from "../../interfaces";

import { PromptType, descriptors } from "./descriptors";
import { Category, CategoryInput, AddButton, InfoPopup } from "./styled";
import { emptyField, validateLong } from "../../utils";

interface OwnProps {
  handleOnChange: (categories: DTO.Category[]) => void;
  selectedCategories: DTO.Category[];

  type?: "LIBRARY" | "CAMPAIGN";
  multiple?: boolean;

  customTitleStyle?: string;
}

type Props = OwnProps;

/**
 * Categories Section component.
 */
const CategoriesFC: React.FC<Props> = ({
  selectedCategories,
  handleOnChange,
  type = "LIBRARY",
  customTitleStyle = "",
}) => {
  const { formatMessage } = useIntl();
  const { userContext } = React.useContext(AppContext);
  const coachId = get(userContext, "coach.id", "");
  const [categoryInput, setCategoryInput] = React.useState<string>("");

  const [coachCategories, setCoachCategories] = React.useState<DTO.Category[]>(
    []
  );
  const [coachCategoriesDropdown, setCoachCategoriesDropdown] = React.useState<
    DropdownItemProps[]
  >([]);
  const [adding, setAdding] = React.useState<boolean>(false);
  const [defaultValue, setDefaultValue] = React.useState<boolean>(true);

  const [selected, setSelected] =
    React.useState<DTO.Category[]>(selectedCategories);
  const [categoriesTags, setCategoriesTag] = React.useState<DTO.TagElement[]>(
    []
  );

  const isValidCategoryName =
    categoryInput.length >= 2 && categoryInput.length <= 25;

  React.useEffect(() => {
    const getCategories = async () => {
      const result = await CategoriesServices.getAll(coachId, type);
      setCoachCategories(() => result);

      const keywordsOptions: DropdownItemProps[] = map(result, (keyword) => ({
        key: keyword.categoryId!,
        text: keyword.name,
        value: keyword.name,
      }));

      setCoachCategoriesDropdown(() => keywordsOptions);
    };

    getCategories();
  }, [coachId]);

  React.useEffect(() => {
    const convertToTag = (result: DTO.Category[]): DTO.TagElement[] => {
      return result.map((category, index) => {
        return {
          index,
          displayValue: category.name,
          id: category.categoryId,
        };
      });
    };

    setCategoriesTag(() => convertToTag(selected));
    if (selectedCategories !== selected) {
      handleOnChange(selected);
    }
  }, [selected]);

  const addCategory = (newCategory: string = categoryInput): void => {
    const categoryToAdd = coachCategories.filter((category) => {
      return category.name.toLowerCase() === newCategory.toLowerCase();
    });

    if (categoryToAdd.length === 0) {
      const addNewCategory = async () => {
        setAdding(true);
        const result = await CategoriesServices.add(
          coachId,
          {
            name: newCategory.trim(),
          },
          type
        ).finally(() => {
          setAdding(false);
        });
        setSelected((old) => old.concat([result]));
        setCategoryInput("");
      };

      addNewCategory();
    } else {
      const isNew = !selectedCategories.includes(categoryToAdd[0]);
      if (isNew) {
        setSelected((old) => old.concat(categoryToAdd));
        setCategoryInput("");
      }
    }
  };

  const includeCategory = (categoryName: string): void => {
    const categoryToAdd = coachCategories.filter((category) => {
      return category.name.trim() === categoryName;
    });
    const isNew = !selectedCategories.includes(categoryToAdd[0]);
    if (isNew && categoryToAdd.length > 0) {
      setSelected((old) => old.concat(categoryToAdd));
      setCategoryInput("");
    }
  };

  const updateCategories = (index: number, tag: DTO.TagElement): void => {
    setSelected((oldTags) => {
      return oldTags.filter((cat) => {
        return cat.categoryId !== tag.id!;
      });
    });
  };

  const handleAddition = (e: any, data: any) => {
    const { value } = data;
    const newCat = value.trim();
    const addButtonPressed =
      e.relatedTarget && e.relatedTarget.id === "addCategoryBtn";
    const hasAValidSize = validateLong(2, 25, newCat);
    if (
      hasAValidSize &&
      (isValidCategoryName || categoryInput.length === 0) &&
      (addButtonPressed ||
        e.key === "Enter" ||
        (e.nativeEvent && e.nativeEvent.type === "click"))
    ) {
      setCategoryInput(newCat);
      if (e.currentTarget.id !== "categories") {
        setCoachCategoriesDropdown((olds) => [{ text: value, value }, ...olds]);
        addCategory(newCat);
      }
    } else if (
      !emptyField(newCat) &&
      categoryInput.length === 0 &&
      !addButtonPressed
    ) {
      alert(formatMessage({ ...descriptors[PromptType.categoryNotSaved] }));
    } else if (!hasAValidSize) {
      alert("Category name should have 2-25 characters.");
    }
  };
  const handleCategoryChange = (e: any, data: any) => {
    const { value } = data;
    setCategoryInput(value);
    setDefaultValue(false);
    if (
      ((e.key !== "ArrowDown" && e.key !== "ArrowUp") ||
        (e.nativeEvent && e.nativeEvent.type === "click")) &&
      !defaultValue
    ) {
      includeCategory(value);
    }
  };

  const isSelectedAlready = () => {
    return (
      selectedCategories.filter((element) => element.name === categoryInput)
        .length > 0
    );
  };

  const isCampaign = type === "CAMPAIGN";
  return (
    <Category>
      <label className={customTitleStyle}>
        {formatMessage({ ...descriptors[PromptType.categoryTitle] })}
      </label>
      <InfoPopup
        content={formatMessage({
          ...descriptors[
            PromptType[isCampaign ? "campaignCategoryInfo" : "categoryInfo"]
          ],
        })}
        trigger={<Icon name="info circle" className={"infoIcon"} link />}
      />
      <div>
        <CategoryInput
          icon=""
          key={"promptInputCategories"}
          data-elm-id={"promptInputCategories"}
          id={"categories"}
          type={"text"}
          name={"categories"}
          placeholder={formatMessage({
            ...descriptors[PromptType.categoryPlaceholder],
          })}
          search
          selection
          allowAdditions
          value={categoryInput}
          onAddItem={handleAddition}
          onChange={handleCategoryChange}
          options={coachCategoriesDropdown}
          noResultsMessage={"No categories found."}
          loading={adding}
          onBlur={(e: any) => {
            const addButtonPressed =
              e.relatedTarget && e.relatedTarget.id === "addCategoryBtn";
            if (categoryInput !== "" && !addButtonPressed) {
              alert(
                formatMessage({ ...descriptors[PromptType.categoryNotSaved] })
              );
              setDefaultValue(false);
            } else if (categoryInput === "") {
              setDefaultValue(true);
            }
          }}
        />
        <AddButton
          type={"button"}
          id={"addCategoryBtn"}
          className={"primary"}
          onClick={() => {
            if (isValidCategoryName && isSelectedAlready()) {
              alert("Category selected already.");
            } else if (isValidCategoryName) {
              addCategory();
            } else {
              alert("Category name should have 2-25 characters.");
            }
          }}
        >
          {formatMessage({ ...descriptors[PromptType.addCategoryBtn] })}
        </AddButton>
      </div>

      {categoriesTags.length > 0 && (
        <CategoriesTags onDelete={updateCategories} tags={categoriesTags} />
      )}
    </Category>
  );
};

export const AddCategoryComponent = CategoriesFC;
