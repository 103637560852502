import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Grid } from "semantic-ui-react";

import { SearchComponent, TitleSection, ProspectList, AdminSideBar } from "../../../components";
import { ProspectResponse } from "../../../interfaces";
import { AppContext } from "../../../providers";
import { PlayersServices } from "../../../services";

import "./styles.scss";

type Props = RouteComponentProps;

const AdminProspectListFC: React.FC<Props> = ({ location, history }) => {
  const [prospects, setProspects] = React.useState<ProspectResponse[]>([]);
  const { userContext } = React.useContext(AppContext);

  return (
    <div className={"whiteContainer"}>
      <AdminSideBar history={history} />
      <div className={"hugePadding playerAdminList"}>
        <TitleSection title={"Prospects List"} ready={true} showNextOption={false} />
        <Grid.Row>
          <Grid.Column>
            <SearchComponent
              refreshData={setProspects}
              content={<ProspectList prospectsList={prospects} isAdmin={true} />}
              searchFunction={PlayersServices.searchAdminProspects}
              filters={[]}
              customSort={[
                { key: 1, text: "Email", value: "email" },
                { key: 2, text: "Created Date", value: "createdDate" },
              ]}
              customCoachId={userContext && userContext?.account?.userId?.toString()}
            />
          </Grid.Column>
        </Grid.Row>
      </div>
    </div>
  );
};

export const AdminProspectList = AdminProspectListFC;
