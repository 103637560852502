import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import{ useIntl } from "react-intl";
import { Grid, Loader, Message, Button, Tab } from "semantic-ui-react";
import _, { get } from "lodash";

import {
  EditableCampaign,
  SelectedPrompts,
  CampaignEditRequest,
  PromptCampaign,
  Category,
  CampaignStep,
  WebComponents,
} from "../../../interfaces";

import {
  SideBar,
  TitleSection,
  ConfirmationModal,
  PlayersSegmentation,
  AutoSubscriptions,
  EnrolledStatistics,
} from "../../../components";

import { AppContext } from "../../../providers";
import { CampaignServices } from "../../../services";
import { coachWithWritePermission, URLS } from "../../../utils";

import { CampaignReducer } from "./reducers";
import { actions } from "./reducers/actions";
import { CampaignInitialState } from "./reducers/initialState";

import { InformationComponent, PromptsStep } from "./components";
import { CampaignPageType, descriptors } from "./descriptors";

import { WhiteContainer, ContentGridSection } from "../../../styling/baseStyle";
import "./styles.scss";

type Props = RouteComponentProps

const campaignCookie = "coachEditCampaign";
const campaignTab = "coachEditCampaignTab";

const CampaignFC: React.FC<Props> = ({ history, match: { params } }) => {
  const [state, dispatch] = React.useReducer(CampaignReducer, CampaignInitialState);
  const { formatMessage } = useIntl();

  const { userContext } = React.useContext(AppContext);
  const withWritePermission = coachWithWritePermission(WebComponents.CAMPAIGNS, userContext);

  const coachId = _.get(userContext, "coach.id", undefined);
  const [campaign, setCampaign] = React.useState<EditableCampaign | undefined>(undefined);
  const infoFormRef = React.useRef<HTMLFormElement>(null);

  const [submitError, setSubmitError] = React.useState<string | undefined>(undefined);
  const [stepError, setStepError] = React.useState<string | undefined>(undefined);
  const [status, setStatus] = React.useState<string | undefined>(undefined);
  const [initialPrompts, setInitialPrompts] = React.useState<string[]>([]);

  const segmentation = _.get(campaign, "eligibilityCriteria[0]", undefined);
  const [isSegmented, setIsSegmented] = React.useState<boolean>(Boolean(segmentation));
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [promptsUpdated, setPromptsUpdated] = React.useState<boolean>(false);

  const [showPromptSection, setShowPromptSection] = React.useState<boolean>(false);

  const [segmentedPlayers, setSegmentedPlayers] = React.useState<number[]>(_.get(segmentation, "values", []));
  const [autoSubscribedPlayers, setAutoSubscribedPlayers] = React.useState<number[]>(
    _.get(campaign, "subscribedPlayerIds", [])
  );

  const campaignId = params["campaignId"];
  const isActive = campaign?.status === "ACTIVE";

  const saveButtonTitle: string = formatMessage({
    ...descriptors[CampaignPageType[isActive ? "saveBtn" : "publishBtn"]],
  });
  const saveSecondayButtonTitle: string = formatMessage({
    ...descriptors[CampaignPageType[isActive ? "saveUnPublishBtn" : "saveBtn"]],
  });

  const tabPosition = {
    INFO: 0,
    SEGMENTS: 1,
    SUBSCRIBERS: 2,
  };

  const selectedTab = localStorage.getItem(campaignTab) ? localStorage.getItem(campaignTab)! : "INFO";
  const [selectedIndex, setSelectedIndex] = React.useState<number>(tabPosition[selectedTab] || 0);

  let nextSelectedIndex = selectedIndex;

  const stepsValidations: { [key: string]: CampaignStep } = {
    0: {
      validations: () =>
        new Promise((resolve, reject) => {
          if (infoFormRef.current) {
            infoFormRef!.current!.handleSubmit();
          }
          reject();
        }),
    },
    1: {
      validations: () =>
        new Promise((resolve, reject) => {
          if (
            !isSegmented ||
            (campaign?.eligibilityCriteria &&
              campaign?.eligibilityCriteria.length > 0 &&
              campaign?.eligibilityCriteria![0].values!.length > 0)
          ) {
            resolve("");
          } else {
            reject(formatMessage({ ...descriptors[CampaignPageType.segmentationStepError] }));
          }
        }),
    },
    2: {
      validations: () =>
        new Promise(resolve => {
          resolve("");
        }),
    },
    3: {
      validations: () =>
        new Promise(resolve => {
          resolve("");
        }),
    },
  };

  React.useEffect(() => {
    if (campaignId) {
      setIsSubmitting(true);
      CampaignServices.get(campaignId)
        .then(response => {
          const cPrompts = response.campaignPrompts || [];
          setCampaign(response);
          setAutoSubscribedPlayers(_.get(response, "subscribedPlayerIds", []).map((id: number) => Number(id)));
          const withSegmentation = _.get(response, "eligibilityCriteria[0]", undefined);
          setIsSegmented(Boolean(withSegmentation));
          setSegmentedPlayers(_.get(withSegmentation, "values", []));
          setInitialPrompts(cPrompts.map((p: PromptCampaign) => String(p.prompt!.promptId)));
        })
        .catch(() => {
          setSubmitError("Error loading this campaign");
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  }, [params["campaignId"]]);

  React.useEffect(() => {
    if (status) {
      save();
    }
  }, [status]);

  React.useEffect(() => {
    if (selectedIndex !== undefined) {
      localStorage.setItem(campaignTab, `${selectedIndex}`);
    }
  }, [selectedIndex]);

  React.useEffect(() => {
    setSubmitError(undefined);
  }, [showPromptSection]);

  React.useEffect(() => {
    if (!isSegmented) {
      setStepError(undefined);
    }
  }, [isSegmented]);

  React.useEffect(() => {
    localStorage.setItem(campaignCookie, JSON.stringify(campaign));
  }, [campaign]);

  const saveCampaign = async (
    campaignStatus: string = "ACTIVE",
    campaignToSave: EditableCampaign,
    shuffle: boolean = promptsUpdated
  ): Promise<any> => {
    try {
      if (campaignToSave) {
        const {
          name,
          isAutoPost,
          campaignPrompts,
          deliveryMode,
          randomScheduleDays,
          eligibilityCriteria,
          categoryIds,
          randomScheduleDeliveryTimePst,
          startDate,
          endDate,
        } = campaignToSave;

        const newCampaignPrompts = campaignPrompts!.map((cprompt: PromptCampaign, index: number) => {
          const { prompt, socialNetworks, campaignPromptId, deliveryDatetimeUtc } = cprompt;
          const newPrompt = {
            promptId: prompt!.promptId,
            campaignId: campaignPromptId,
            socialNetworks,
            sortOrder: cprompt.sortOrder,
            deliveryDatetimeUtc: deliveryMode === "SPECIFIC_DATE" ? deliveryDatetimeUtc : undefined,
          } as PromptCampaign;

          return newPrompt;
        });

        let campaignRequest: CampaignEditRequest = {
          campaignId,
          name,
          isAutoPost,
          campaignPrompts: newCampaignPrompts,
          categoryIds,
          deliveryMode,
          status: campaignStatus,
          subscriptionPlayerIds: autoSubscribedPlayers,
        };

        if (deliveryMode !== "SPECIFIC_DATE") {
          campaignRequest = {
            ...campaignRequest,
            randomScheduleDays,
            randomScheduleDeliveryTimePst,
            shuffle,
            startDate,
            endDate,
          };
        }
        if (isSegmented) {
          campaignRequest = {
            ...campaignRequest,
            eligibilityCriteria,
          };
        }
        setIsSubmitting(true);
        CampaignServices.update(campaignRequest, coachId!)
          .then(response => {
            closeAndClean();
            setSubmitError(undefined);
          })
          .catch(e => {
            setSubmitError(e.length < 150 ? e : formatMessage({ ...descriptors[CampaignPageType.savingError] }));
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }
    } catch (e: any) {
      setSubmitError(e);
    }
  };

  const onCancelAction = () => {
    dispatch({ name: actions.OPEN_CONFIRMATION_MODAL });
  };

  const save = (): void => {
    stepsValidations[selectedIndex]
      .validations()
      .then(e => {
        saveCampaign(status, campaign!);
      })
      .catch(e => {
        if (e && e !== "") {
          setStepError(e);
        }
      });
  };

  const saveCampaignAction = (campaignStatus: "ACTIVE" | "UNPUBLISHED" | "DELETED"): void => {
    if (status === campaignStatus) {
      save();
    } else {
      setStatus(campaignStatus);
    }
  };

  const onSaveDraftAction = () => {
    saveCampaignAction("UNPUBLISHED");
  };

  const onNextButtonAction = () => {
    saveCampaignAction("ACTIVE");
  };

  const onDeleteAction = () => {
    saveCampaignAction("DELETED");
  };

  const onModalSave = () => {
    tabValidationsPromise()
      .then(e => {
        saveCampaign(status, campaign!);
      })
      .catch(e => {
        if (e && e !== "") {
          setStepError(e);
        }
      });
    dispatch({ name: actions.CLOSE_CONFIRMATION_MODAL });
  };

  const closeAndClean = () => {
    localStorage.removeItem(campaignCookie);
    localStorage.removeItem(campaignTab);

    dispatch({ name: actions.CANCEL });
    history.push(URLS.coach.campaigns);
  };

  const campaignsPromptsConverted = (): SelectedPrompts => {
    if (campaign?.campaignPrompts) {
      let selectedPrompts: SelectedPrompts = {};
      campaign?.campaignPrompts.map((cprompt: PromptCampaign) => {
        const { promptId, title, post, categories } = cprompt.prompt!;
        selectedPrompts = {
          ...selectedPrompts,
          [promptId!]: {
            title,
            post,
            prompt: cprompt.prompt,
            categoryIds: categories ? categories!.map((category: Category) => category!.categoryId!) : [],
          },
        };
      });
      return selectedPrompts;
    }

    return {};
  };

  const tabValidationsPromise = (): Promise<any> => {
    return stepsValidations[selectedIndex].validations();
  };

  const tabOnChangeHandler = (nextIndex: number) => {
    nextSelectedIndex = nextIndex;
    if (selectedIndex <= 4 && selectedIndex >= 0 && nextSelectedIndex !== selectedIndex) {
      tabValidationsPromise()
        .then(e => {
          setSelectedIndex(nextIndex || 0);
          setStepError(undefined);
        })
        .catch(e => {
          if (e && e !== "") {
            setStepError(e);
          }
        });
    }
  };

  const promptsSuccessHandler = (selectedPrompts: SelectedPrompts) => {
    if (Object.keys(selectedPrompts).length > 0) {
      setStepError(undefined);
    }
    const firstPrompt = campaign?.campaignPrompts[0];

    const campaignPrompts = Object.keys(selectedPrompts).map(promptId => {
      const isPrevious = campaign?.campaignPrompts.filter(c => `${c.prompt!.promptId}` === `${promptId}`);
      return {
        promptId: promptId!,
        socialNetworks:
          isPrevious && isPrevious!.length > 0 ? isPrevious[0].socialNetworks : firstPrompt?.socialNetworks,
        prompt: {
          ...selectedPrompts[promptId].prompt,
          deliveryDatetimeUtc: isPrevious && isPrevious!.length > 0 ? isPrevious[0].deliveryDatetimeUtc : undefined,
        },
        deliveryDatetimeUtc: isPrevious && isPrevious!.length > 0 ? isPrevious[0].deliveryDatetimeUtc : undefined,
      } as PromptCampaign;
    });

    setShowPromptSection(false);
    setCampaign({ ...campaign!, campaignPrompts });
    setPromptsUpdated(true);
  };

  const informationOnSuccessHandler = (updatedCampaign: EditableCampaign) => {
    const shufflePrompts = _.isEqual(updatedCampaign.campaignPrompts, campaign?.campaignPrompts) === false;
    if (shufflePrompts) {
      setPromptsUpdated(true);
    }

    setCampaign(updatedCampaign);

    if (nextSelectedIndex === selectedIndex) {
      saveCampaign(status, updatedCampaign, shufflePrompts);
    } else {
      setSelectedIndex(nextSelectedIndex);
    }
  };

  const segmentationOnChangeHandler = (playersId: number[], isInclude: boolean) => {
    const segmentedConverted = segmentedPlayers.map(s => Number(s));
    const currentSegmentationType = get(campaign, "eligibilityCriteria[0].isInclude", isInclude);

    // TODO: Optional
    //    - si el include cambia -> se borran los autoSubscribedPlayers
    //    - si la nueva segmentacion incluye a los autoSubscribedPlayers no borrarlos
    //    - si la nueva segmentacion no incluye a los autoSubscribedPlayers borrarlos
    if (isInclude !== currentSegmentationType || !_.isEqual(_.sortBy(playersId), _.sortBy(segmentedConverted))) {
      const validSubscribers =
        isInclude === currentSegmentationType ? _.intersection(autoSubscribedPlayers, playersId) : [];
      setSegmentedPlayers(playersId);
      setAutoSubscribedPlayers(validSubscribers);
      setCampaign({
        ...campaign!,
        eligibilityCriteria: [
          {
            values: playersId.map(id => Number(id)),
            level: "PLAYER_ID",
            isInclude,
          },
        ],
        subscribedPlayers: validSubscribers,
      });
    }
  };

  const subscriptionOnChangeHandler = (playersId: number[]) => {
    setAutoSubscribedPlayers(playersId.map(id => Number(id)));
    setCampaign({
      ...campaign!,
      subscribedPlayers: playersId.map(id => Number(id)),
      subscriptionPlayerIds: playersId.map(id => Number(id)),
    });
  };

  const customLinkSection = (): JSX.Element => {
    return (
      <Grid.Column width={isActive ? 11 : 10} floated="right" className="noMarginTop">
        <Button
          data-elm-id={`editCampaignCancelBtn`}
          className={"secondary buttonWithLateralPadding"}
          onClick={onCancelAction}>
          {formatMessage({ ...descriptors[CampaignPageType.cancelBtn] })}
        </Button>

        <Button
          data-elm-id={`editCampaignSaveBtn`}
          className={"secondary buttonWithLateralPadding"}
          onClick={onSaveDraftAction}>
          {saveSecondayButtonTitle}
        </Button>
      </Grid.Column>
    );
  };

  const confirmationModal = (): JSX.Element => {
    return (
      <ConfirmationModal
        openConfirmationModal={state.openConfirmationModal}
        onClose={() => {
          dispatch({ name: actions.CLOSE_CONFIRMATION_MODAL });
        }}
        okHandler={onModalSave}
        rejectHandler={closeAndClean}
      />
    );
  };

  const panes = [
    {
      menuItem: "Edit Campaign",
      render: () => (
        <Tab.Pane loading={isSubmitting}>
          {campaign && (
            <InformationComponent
              disabled={!withWritePermission}
              submitRef={infoFormRef}
              campaign={campaign!}
              initialPromptsIds={initialPrompts}
              editable={
                !(
                  campaign.deliveryMode !== "SPECIFIC_DATE" &&
                  _.get(campaign, "subscribedPlayerIds", []).length > 0 &&
                  isActive
                )
              }
              onSuccessHandler={informationOnSuccessHandler}
              onDeleteHandler={() => {
                if (window.confirm("Are you sure you would like to remove this campaign?")) {
                  onDeleteAction();
                }
              }}
              addPromptHandler={() => {
                setShowPromptSection(true);
              }}
            />
          )}
        </Tab.Pane>
      ),
    },
    {
      menuItem: formatMessage({
        ...descriptors[CampaignPageType.segmentationSectionTitle],
      }),
      render: () => (
        <Tab.Pane loading={isSubmitting}>
          <PlayersSegmentation
            disabled={!withWritePermission}
            segmentedCampaign={isSegmented}
            selectedPlayers={segmentedPlayers.map(id => Number(id))}
            onChange={segmentationOnChangeHandler}
            isSegmentedOnChange={segmented => {
              setIsSegmented(segmented);
              if (!segmented) {
                setSegmentedPlayers([]);
              }
            }}
            criteria={_.get(segmentation, "isInclude", true) ? "include" : "exclude"}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: formatMessage({
        ...descriptors[CampaignPageType.autoSubcribeSectionTitle],
      }),
      render: () => (
        <Tab.Pane loading={isSubmitting}>
          <AutoSubscriptions
            disabled={!withWritePermission}
            segmentedPlayers={segmentedPlayers.map(id => Number(id))}
            isInclude={_.get(segmentation, "isInclude", true)}
            selectedPlayers={autoSubscribedPlayers}
            onChange={subscriptionOnChangeHandler}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: formatMessage({
        ...descriptors[CampaignPageType.enrolledSectionTitle],
      }),
      render: () => (
        <Tab.Pane loading={isSubmitting}>
          <EnrolledStatistics
            campaignId={campaign?.campaignId}
            subscribedIds={_.get(campaign, "subscribedPlayerIds", [])}
          />
        </Tab.Pane>
      ),
    },
  ];

  const content = (): JSX.Element => {
    return showPromptSection ? (
      <PromptsStep
        coachId={coachId!}
        campaignPrompts={campaignsPromptsConverted()}
        onChangeHandler={promptsSuccessHandler}
        onCancel={() => setShowPromptSection(false)}
      />
    ) : (
      <Tab
        panes={panes}
        activeIndex={selectedIndex}
        onTabChange={(e, data) => {
          tabOnChangeHandler(Number(data.activeIndex!));
        }}
      />
    );
  };
  return (
    <WhiteContainer>
      <SideBar history={history} />

      <ContentGridSection columns={1}>
        <TitleSection
          title={formatMessage({ ...descriptors[CampaignPageType.title] })}
          ready={true}
          customLinkSection={customLinkSection()}
          customNextLabel={saveButtonTitle}
          titleSize={6}
          buttonsSize={10}
          nextButtonSize={isActive ? 5 : 6}
          handleNextAction={onNextButtonAction}
          showLinkOption={withWritePermission}
          showNextOption={withWritePermission}
        />
        <div>
          {(stepError || submitError) && (
            <Grid.Row>
              <Message className={"leftMargin"} error list={[submitError, stepError]} />
            </Grid.Row>
          )}
          <Grid.Row className={"campaignEditContainer"}>
            <Grid.Column width={16} className={"editColumn"}>
              {isSubmitting ? <Loader active size="large" /> : content()}
            </Grid.Column>
          </Grid.Row>

          {confirmationModal()}
        </div>
      </ContentGridSection>
    </WhiteContainer>
  );
};

export const EditCampaignPage = withRouter(CampaignFC)
