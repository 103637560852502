import * as React from "react";
import { useIntl } from "react-intl";
import { Grid, Header } from "semantic-ui-react";
import { get, isNil, join, groupBy } from "lodash";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { LinkedIn, TikTok, Twitter } from "../../../SocialNetworkProviders";
import { ConfigContext } from "../../../../contexts/appContexts";
import {
  SocialNetworksId,
  SocialNetworksName,
  ConfigProperties,
  DataNetwork,
  InstagramPage,
  FacebookPage,
  FacebookCredentials,
  FacebookAccountInfo,
  InstagramCredentials,
  CoachSettings,
  CoachAppSetting,
  LinekdInCredentials,
  LinkedInOrganizationBaseInfo,
  UpdateUserPayload,
  AddSocialTokenRequest,
  SocialNetwork,
  socialNamesKeys,
} from "../../../../interfaces";
import { AppContext, LinkedIn as LinkedInCalls, AppUserContext } from "../../../../providers";
import { useSocialNetworks } from "../../../../reducers";
import {
  SettingsServices,
  FacebookServices,
  PlayersServices,
  SocialCoachSessionService,
  UsersService,
} from "../../../../services";
import SocialNetworkButton from "../../../SocialNetworkButton";
import { LinkedInOrganizationModal, FacebookPagesModal, InstagramPagesModal } from "../../../Modals";

import { SocialNetworksProfile, descriptors } from "./descriptors";
import "./styles.scss";

interface Props {
  /**
   * Parent indicator
   */
  type: "PROFILE" | "FIRSTLOGIN";
  /**
   * Enable to delete option
   */
  deleteEnable: boolean;
  /**
   * Show the title
   */
  showTitle: boolean;
}

let displayTiktokPreferences = false;
const PlayerSocialAccountsClass: React.FC<Props> = ({ deleteEnable, showTitle, type }) => {
  const { userContext, setUserContext } = React.useContext(AppContext);
  const config: ConfigProperties = React.useContext(ConfigContext);
  const { formatMessage } = useIntl();

  const inProfileStyle = false;
  const viewName = type === "PROFILE" ? "Profile" : "FirstLogin";

  // const [usersn, setUsersn] = React.useState<Social>({});

  const [fbPages, setFBPages] = React.useState<FacebookPage[]>([]);
  const [fbAccountInfo, setFBAccountInfo] = React.useState<FacebookAccountInfo | undefined>(undefined);
  const [showFBModal, setShowFBModal] = React.useState<boolean>(false);

  const [igPages, setIGPages] = React.useState<InstagramPage[]>([]);
  const [showIGModal, setShowIGModal] = React.useState<boolean>(false);

  const [liOrganizations, setLIOrganizations] = React.useState<LinkedInOrganizationBaseInfo[]>([]);
  const [liAccountInfo, setLinkedInAccountInfo] = React.useState<LinekdInCredentials | undefined>(undefined);
  const [showLinkedInModal, setShowLinkedInModal] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState<boolean>(false);
  // const [displayTiktokPreferences, setDisplayTiktokPreferences] = React.useState<boolean>(false);

  const [coachAppSettings, setCoachAppSettings] = React.useState<CoachAppSetting | undefined>(undefined);
  const [showSocialNetworks, setShowSocialNetworks] = React.useState<{
    [key: string]: string;
  }>({});

  const { displayTikTok } = useSocialNetworks();

  const [showSN, setShowSN] = React.useState<boolean>(false);
  const [showFB, setShowFB] = React.useState<boolean>(false);
  const [showLI, setShowLI] = React.useState<boolean>(false);
  const [showIG, setShowIG] = React.useState<boolean>(false);
  const [showTW, setShowTW] = React.useState<boolean>(false);
  const [showTT, setShowTT] = React.useState<boolean>(false);

  const [numberOfAvailableSocialNetworks, setNumberOfAvailableSocialNetworks] = React.useState<number>(
    Object.values(socialNamesKeys).length
  ); // 5

  React.useEffect(() => {
    if (userContext && userContext.player) {
      PlayersServices.getCoachData(userContext.player.id).then(response => {
        setLoading(true);
        SettingsServices.getAppSettingsByCoachId(response.coachId)
          .then(setCoachAppSettings)
          .finally(() => setLoading(false));
      });
    }
  }, []);

  React.useEffect(() => {
    console.log("displayTiktokPreferences changed", displayTiktokPreferences);
  }, [displayTiktokPreferences]);

  React.useEffect(() => {
    if (coachAppSettings) {
      const coachAppSettingsGrouped = groupBy(coachAppSettings?.settings, b => b.appSettingId);
      let settingsHash = {};
      Object.keys(coachAppSettingsGrouped).forEach(setting => {
        settingsHash = {
          ...settingsHash,
          [setting]: get(coachAppSettingsGrouped[setting], "[0].status", "ACTIVE") as string,
        };
      });
      const socialNetworkSettings = [
        CoachSettings.POST_IN_FB,
        CoachSettings.POST_IN_LI,
        CoachSettings.POST_IN_TW,
        CoachSettings.POST_IN_IG,
      ];

      if (displayTikTok) {
        socialNetworkSettings.push(CoachSettings.POST_IN_TT);
      }
      const tshowSN = socialNetworkSettings.reduce(
        (accumulator, setting) =>
          get(coachAppSettingsGrouped[setting], "[0].status", "ACTIVE") === "ACTIVE" || accumulator,
        false
      );

      const numberOfSn = socialNetworkSettings.reduce(
        (accumulator, setting) =>
          get(coachAppSettingsGrouped[setting], "[0].status", "ACTIVE") === "ACTIVE" ? accumulator + 1 : accumulator,
        0
      );

      setNumberOfAvailableSocialNetworks(numberOfSn);
      setShowSN(tshowSN);
      setShowFB(settingsHash[CoachSettings.POST_IN_FB] !== "INACTIVE");
      setShowLI(settingsHash[CoachSettings.POST_IN_LI] !== "INACTIVE");
      setShowTW(settingsHash[CoachSettings.POST_IN_TW] !== "INACTIVE");
      setShowIG(settingsHash[CoachSettings.POST_IN_IG] !== "INACTIVE");
      setShowTT(settingsHash[CoachSettings.POST_IN_TT] !== "INACTIVE" && displayTikTok);
      setShowSocialNetworks(settingsHash);
    }
  }, [coachAppSettings, displayTikTok]);

  const getUser = (sn: SocialNetworksName): string => {
    const socialNetworkInfo = userContext!.profile.socialNetworks?.filter(
      currentSn => socialNamesKeys["_" + currentSn.networkId] === sn
    );

    return socialNetworkInfo ? get(socialNetworkInfo[0], "username", "") : ""; // get(snElement, "username", "");
  };

  const closeFBPageModal = (): void => {
    setShowFBModal(false);
    setFBPages([]);
  };

  const closeIGPageModal = () => {
    setShowIGModal(false);
    setIGPages([]);
  };

  const closeOrganizationModal = (): void => {
    setShowLinkedInModal(false);
    setLIOrganizations([]);
  };

  const deleteSocialNetwork = (sn: SocialNetworksName): void => {
    if (confirm(formatMessage({ ...descriptors[SocialNetworksProfile.confirmDeleteMessage] }))) {
      setLoading(true);
      const socialNetworkInfo = userContext!.profile.socialNetworks?.filter(
        currentSn => socialNamesKeys["_" + currentSn.networkId] === sn
      );

      SocialCoachSessionService.removeSocialNetwork(socialNetworkInfo!![0].networkId, userContext!.profile!.profileId!)
        .then(() => {
          UsersService.getUserContext().then(setUserContext);
          if (sn === "linkedIn") {
            LinkedInCalls.logout();
          } else if ((sn === "facebook" || sn === "instagram") && window && window["FB"]) {
            window["FB"].logout();
          }
        })
        .catch(() => {
          alert("Unknow error. Try again");
        })
        .finally(() => setLoading(false));
    }
  };

  const updateSocialNetworksHandler = (sn: SocialNetwork, includeSocialNetworkId: SocialNetworksId): void => {
    const request: AddSocialTokenRequest = {
      ...sn,
    };

    SocialCoachSessionService.addSocialNetwork(request)
      .then(() => {
        UsersService.getUserContext().then((response: AppUserContext) => {
          setUserContext(response);
          displayTiktokPreferences = includeSocialNetworkId === "TT";
        });
      })
      .catch(e => {
        const errorMessage = e.includes("Error validating access token")
          ? "Your Facebook session is invalid because your user logged out. Please try to renew your session "
          : String(e);
        displayTiktokPreferences = includeSocialNetworkId === "TT";
        alert(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateSocialNetworksHandlerOLD = (sn: any, includeSocialNetworkId: SocialNetworksId): void => {
    let playerUpdateRequest: UpdateUserPayload = {
      ...userContext?.profile!!,
    };
    if (sn.length > 0) {
      playerUpdateRequest = {
        ...playerUpdateRequest,
        socialNetworks: sn,
      };
    } else {
      playerUpdateRequest["socialNetworks"] = undefined;
    }

    SocialCoachSessionService.updatePlayer(playerUpdateRequest)
      .then(() => {
        UsersService.getUserContext().then((response: AppUserContext) => {
          setUserContext(response);
          displayTiktokPreferences = includeSocialNetworkId === "TT";
        });
      })
      .catch(e => {
        const errorMessage = e.includes("Error validating access token")
          ? "Your Facebook session is invalid because your user logged out. Please try to renew your session "
          : String(e);
        displayTiktokPreferences = includeSocialNetworkId === "TT";
        alert(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const socialNetworkSuccessResponse = (
    username: string,
    networkId: SocialNetworksId,
    dataNetwork: DataNetwork,
    publicData: string | null = null
  ): void => {
    const socialNetwork = {
      username,
      networkId,
      dataNetwork,
      publicData,
    };
    updateSocialNetworksHandler(socialNetwork, networkId);
  };

  const responseTwitter = (error: any, data: any): void => {
    if (!error) {
      const { oauth_token, oauth_token_secret, screen_name } = data;
      socialNetworkSuccessResponse(screen_name, "TW", {
        type: "TW",
        token: oauth_token,
        tokenSecret: oauth_token_secret,
      });
    }
  };

  const responseFacebook = (response: any): void => {
    if (!isNil(response) && response.accessToken) {
      const { accessToken, name } = response;
      SocialCoachSessionService.saveAccessToken(accessToken, "FB").then(r => {
        FacebookServices.getPages().then(pages => {
          setFBAccountInfo(() => {
            return {
              accessToken,
              name,
              type: "FB",
            };
          });
          setFBPages(pages);
          setShowFBModal(true);
        });
      });
    }
  };

  const responseFacebookIG = (response: any): void => {
    if (!isNil(response) && response.accessToken) {
      const { accessToken, name } = response;
      SocialCoachSessionService.saveAccessToken(accessToken, "IG").then(r => {
        FacebookServices.getInstagramPages().then(pages => {
          setFBAccountInfo(() => {
            return {
              accessToken,
              name,
              type: "IG",
            };
          });
          setIGPages(pages);
          setShowIGModal(true);
        });
      });
    }
  };

  const fbPageSelected = (selectedPage?: FacebookPage): void => {
    closeFBPageModal();
    if (!selectedPage) {
      return;
    }

    const facebookCredentials: FacebookCredentials = {
      type: "FB",
      accessToken: fbAccountInfo!.accessToken,
      pageId: selectedPage.id,
      pageToken: selectedPage.access_token,
    };

    socialNetworkSuccessResponse(
      fbAccountInfo!.name,
      "FB",
      facebookCredentials,
      `{"link": "https://facebook.com/${selectedPage.id}"}`
    );
  };

  const igPageSelected = (selectedPage?: InstagramPage): void => {
    closeIGPageModal();
    if (!selectedPage) {
      return;
    }

    const igCredentials: InstagramCredentials = {
      type: "IG",
      accessToken: fbAccountInfo!.accessToken,
      pageToken: selectedPage.access_token,
      igAccountId: selectedPage.instagramId,
    };

    socialNetworkSuccessResponse(selectedPage.instagramUsername, "IG", igCredentials);
  };

  const liOrganizationSelected = (selectedOrganization: LinkedInOrganizationBaseInfo): void => {
    // 4 - After the organization was selected -> include organizationUrn on datanetwork
    closeOrganizationModal();
    socialNetworkSuccessResponse(
      selectedOrganization.name,
      "LI",
      {
        type: "LI",
        accessToken: liAccountInfo?.accessToken,
        refreshToken: liAccountInfo?.refreshToken,
        refreshTokenExpiresIn: liAccountInfo?.refreshTokenExpiresIn,
        expiresIn: liAccountInfo?.expiresIn,
        organizationUrn:
          selectedOrganization.id !== "personalId" ? `urn:li:organization:${selectedOrganization.id}` : undefined,
      },
      `{'link': ${liAccountInfo?.link}}`
    );
  };

  const responseLinkedIn = (error: any, response: any): void => {
    if (!isNil(response)) {
      const { code, username, link, refreshToken, refreshTokenExpiresIn, expiresIn, organizationIds } = response;

      if (organizationIds && organizationIds.length > 0) {
        const liInfo: LinekdInCredentials = {
          accessToken: code,
          name: username,
          refreshToken,
          refreshTokenExpiresIn,
          expiresIn,
          link,
          type: "LI",
        };

        if (organizationIds) {
          setLIOrganizations(organizationIds);
        } else {
          alert("Something went wrong obtaining organization info");
        }
        setShowLinkedInModal(true);
        setLinkedInAccountInfo(liInfo);
      } else {
        socialNetworkSuccessResponse(
          username,
          "LI",
          { type: "LI", accessToken: code, refreshToken, refreshTokenExpiresIn, expiresIn },
          `{'link': '${link}'}`
        );
      }
    }
  };

  const responseTikTok = (error: any, response: any): void => {
    if (!isNil(response)) {
      const { code, username, refreshToken, refreshTokenExpiresIn, expiresIn, openId } = response;
      socialNetworkSuccessResponse(
        username,
        "TT",
        { type: "TT", accessToken: code, refreshToken, refreshTokenExpiresIn, expiresIn },
        `{'openId': '${openId}'}`
      );
    }
  };

  const facebookButton = (connected: boolean): JSX.Element => {
    return (
      <Grid.Row className={"socialButtonRow"}>
        <Grid.Column width={16}>
          <FacebookLogin
            appId={config.socialNetworks.facebook.api_key}
            version={FacebookServices.graphApiVersionNumber()}
            fields="name,email,picture"
            callback={responseFacebook}
            scope={config.playerFacebookScopes || "email"}
            authType={"reauthenticate"}
            render={(renderProps: any) => {
              return (
                <SocialNetworkButton
                  data-elm-id={`social${viewName}ButtonFacebook${connected}`}
                  connected={connected}
                  account={getUser("facebook")}
                  inProfile={inProfileStyle}
                  socialNetwork={"facebook"}
                  handler={sn => renderProps.onClick()}
                  disconnectHandler={sn => deleteSocialNetwork(sn)}
                  loading={loading}
                />
              );
            }}
          />
        </Grid.Column>
      </Grid.Row>
    );
  };

  const instagramButton = (connected: boolean): JSX.Element => {
    return (
      <Grid.Row className={"socialButtonRow"}>
        <Grid.Column width={16}>
          <FacebookLogin
            appId={config.socialNetworks.facebook.api_key}
            version={FacebookServices.graphApiVersionNumber()}
            fields="name,email,picture"
            callback={responseFacebookIG}
            scope={join(config.socialNetworks.instagram.permissions, ",")}
            authType={"reauthenticate"}
            render={(renderProps: any) => {
              return (
                <SocialNetworkButton
                  data-elm-id={`social${viewName}ButtonInstagram${connected}`}
                  account={getUser("instagram")}
                  connected={connected}
                  inProfile={inProfileStyle}
                  socialNetwork={"instagram"}
                  handler={sn => renderProps.onClick()}
                  disconnectHandler={sn => deleteSocialNetwork(sn)}
                  loading={loading}
                />
              );
            }}
          />
        </Grid.Column>
      </Grid.Row>
    );
  };

  const twitterButton = (connected: boolean): JSX.Element => {
    return (
      <Grid.Row className={"socialButtonRow"}>
        <Grid.Column width={16}>
          <Twitter
            handleLogin={responseTwitter}
            handleDelete={deleteSocialNetwork}
            username={getUser("twitter")}
            inProfile={inProfileStyle}
            loading={loading}
            connected={connected}
          />
        </Grid.Column>
      </Grid.Row>
    );
  };

  const linkedInButton = (connected: boolean): JSX.Element => {
    return (
      <Grid.Row className={"socialButtonRow"}>
        <Grid.Column width={16}>
          <LinkedIn
            handleLogin={responseLinkedIn}
            handleDelete={deleteSocialNetwork}
            username={getUser("linkedIn")}
            inProfile={inProfileStyle}
            loading={loading}
            connected={connected}
          />
        </Grid.Column>
      </Grid.Row>
    );
  };

  const tiktokButton = (connected: boolean): JSX.Element => {
    return (
      <Grid.Row className={"socialButtonRow"}>
        <Grid.Column width={16}>
          <TikTok
            handleLogin={responseTikTok}
            handleDelete={deleteSocialNetwork}
            username={getUser("tiktok")}
            inProfile={inProfileStyle}
            loading={loading}
            connected={connected}
            displayTiktokPreferences={displayTiktokPreferences}
          />
        </Grid.Column>
      </Grid.Row>
    );
  };

  const numberOfConnectedSocialNetworks = Object.values(userContext!.profile.socialNetworks!!).filter(
    sn => showSocialNetworks["POST_IN_" + sn.networkId] !== "INACTIVE"
  ).length;

  return showSN ? (
    <Grid.Row className={type === "PROFILE" ? "profileSocialRow" : "profileSocialRow profileSocialRowWhite"}>
      {showTitle && (
        <Header as="h4" textAlign={"center"}>
          {formatMessage({ ...descriptors[SocialNetworksProfile.title] })}
        </Header>
      )}
      {numberOfConnectedSocialNetworks < numberOfAvailableSocialNetworks && (
        <div className={"profileSocialAccounts"}>
          <div className="alignLeft socialAccountsCategoryLabel">NOT CONNECTED</div>
          <Grid centered columns={1} className={"newProfileSocialButtons"}>
            {showFB && getUser("facebook") === "" && facebookButton(false)}
            {showLI && getUser("linkedIn") === "" && linkedInButton(false)}
            {showTW && getUser("twitter") === "" && twitterButton(false)}
            {showIG && getUser("instagram") === "" && instagramButton(false)}
            {showTT && getUser("tiktok") === "" && tiktokButton(false)}
          </Grid>
        </div>
      )}

      {numberOfConnectedSocialNetworks > 0 && (
        <div className={"profileSocialAccounts smallTop"}>
          <div className="alignLeft socialAccountsCategoryLabel">CONNECTED</div>
          <Grid centered columns={1} className={"newProfileSocialButtons"}>
            {showFB && getUser("facebook") !== "" && facebookButton(true)}
            {showLI && getUser("linkedIn") !== "" && linkedInButton(true)}
            {showTW && getUser("twitter") !== "" && twitterButton(true)}
            {showIG && getUser("instagram") !== "" && instagramButton(true)}
            {showTT && getUser("tiktok") !== "" && tiktokButton(true)}
          </Grid>
        </div>
      )}

      <FacebookPagesModal
        pages={fbPages}
        openConfirmationModal={showFBModal}
        onClose={closeFBPageModal}
        okHandler={fbPageSelected}
        rejectHandler={closeFBPageModal}
      />

      <InstagramPagesModal
        pages={igPages}
        openConfirmationModal={showIGModal}
        onClose={closeIGPageModal}
        okHandler={igPageSelected}
        rejectHandler={closeIGPageModal}
      />

      <LinkedInOrganizationModal
        organizations={liOrganizations}
        openConfirmationModal={showLinkedInModal}
        onClose={closeOrganizationModal}
        okHandler={liOrganizationSelected}
        rejectHandler={closeOrganizationModal}
      />
    </Grid.Row>
  ) : (
    <div />
  );
};

export const PlayerSocialAccounts = PlayerSocialAccountsClass;
