import { SubCoachSignUpType } from "./descriptors";
import * as MODEL from "../../interfaces/models";

export const fields: MODEL.FormField[][] = [
  [
    {
      id: "firstName",
      name: "firstName",
      type: "text",
      label: "First Name",
      placeholder: SubCoachSignUpType.firstName,
      disabled: false,
    },
  ],
  [
    {
      id: "lastName",
      name: "lastName",
      type: "text",
      label: "Last Name",
      placeholder: SubCoachSignUpType.lastName,
      disabled: false,
    },
  ],
  [
    {
      id: "email",
      name: "email",
      type: "email",
      label: "Email",
      placeholder: SubCoachSignUpType.email,
      disabled: false,
    },
  ],
];

export const passwordFields: MODEL.FormField[][] = [
  [
    {
      id: "password",
      name: "password",
      type: "password",
      label: "Password",
      placeholder: SubCoachSignUpType.password,
      disabled: false,
    },
  ],
];

export const enterprisePermissions: MODEL.PermissionField[] = [
  {
    id: "library",
    name: "LIBRARY",
    label: "Library",
  },
  {
    id: "campaigns",
    name: "CAMPAIGNS",
    label: "Campaigns",
  },
  {
    id: "videoCatalyst",
    name: "VIDEO_CATALYST",
    label: "Video Catalyst",
  },
  {
    id: "scriptLibrary",
    name: "MANAGE_SCRIPT_LIBRARY",
    label: "Script Library",
  },
  {
    id: "players",
    name: "PLAYERS",
    label: "Players",
  },
  {
    id: "compliance",
    name: "COMPLIANCE",
    label: "Compliance",
  },
  {
    id: "admin",
    name: "ADMIN",
    label: "Admin",
  },
  {
    id: "alerts",
    name: "ALERTS",
    label: "Alerts",
  },
  {
    id: "digestEmail",
    name: "DIGEST_EMAIL",
    label: "Digest Email",
  },
  {
    id: "settings",
    name: "APP_SETTINGS",
    label: "Settings",
  },
];

export const basicPermissions: MODEL.PermissionField[] = [
  {
    id: "marketing",
    name: "Marketing",
    label: "Marketing",
  },
  {
    id: "pricePlan",
    name: "Price",
    label: "Price Plan",
  },
  {
    id: "library",
    name: "LIBRARY",
    label: "Library",
  },
  {
    id: "campaigns",
    name: "CAMPAIGNS",
    label: "Campaigns",
  },
  {
    id: "videoCatalyst",
    name: "VIDEO_CATALYST",
    label: "Video Catalyst",
  },
  {
    id: "scriptLibrary",
    name: "MANAGE_SCRIPT_LIBRARY",
    label: "Script Library",
  },
  {
    id: "players",
    name: "PLAYERS",
    label: "Players",
  },
  {
    id: "compliance",
    name: "COMPLIANCE",
    label: "Compliance",
  },
  {
    id: "admin",
    name: "ADMIN",
    label: "Admin",
  },
  {
    id: "alerts",
    name: "ALERTS",
    label: "Alerts",
  },
  {
    id: "digestEmail",
    name: "DIGEST_EMAIL",
    label: "Digest Email",
  },
  {
    id: "settings",
    name: "APP_SETTINGS",
    label: "Settings",
  },
];
