import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required("First Name is required"),
  lastName: Yup.string()
    .trim()
    .required("Last Name is required"),
  email: Yup.string()
    .email("Email is invalid")
    .required("Email is required"),
  complianceDisclaimer: Yup.string().max(255, "Comliance Disclaimer max size is 255"),
});
