import * as React from "react";
import { Field } from "formik";
import MaskedInput from "react-text-mask";
import{ useIntl, FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { Form } from "semantic-ui-react";

import { FirstLoginType, descriptors } from "./descriptors";
import { fields } from "./formFields";
import { phoneNumberMask, dateMask } from "../../../../utils";
import { FloatedCard } from "../../../../containers";
import { BasicFormProps } from "../../../../interfaces/customTypes";
import { UIDate } from "../../../../utils";
import { Chat, CoachBasic } from "../../../../interfaces";
import { BubbleMobileDetails } from "../BubbleMobileDetails";

interface OwnProps extends BasicFormProps {
  handleSetFieldValue?: (field: string, value: any) => void;

  chatMessages: Chat[];
  coachInfo?: CoachBasic;
}

type Props = OwnProps   & RouteComponentProps;

/**
 * Represents a Player First login screen - Step 1.
 */
const FirstLoginPersonalInfoClass: React.FC<Props> = ({

  messages,
  handleChange,
  handleSubmit,
  isSubmitting,
  values,
  chatMessages,
  coachInfo,
}) => {
  const { formatMessage } = useIntl();
  const startDate = values.birthdate ? UIDate(values.birthdate) : values.birthdate;

  const content = (
    <React.Fragment>
      {coachInfo && <BubbleMobileDetails messages={chatMessages} coachInfo={coachInfo} />}
      {fields.map((field, index) => {
        return (
          <Form.Group widths="equal" key={"personal_info_group_" + field[0].id + index}>
            <Form.Input
              key={field[0].id + index + "_l"}
              data-elm-id={"firstLoginInput" + field[0].id}
              fluid
              id={field[0].id}
              type={field[0].type}
              name={field[0].name}
              placeholder={formatMessage({ ...descriptors[field[0].placeholder] })}
              onChange={handleChange}
              value={values[field[0].id]}
              disabled={isSubmitting}
            />
            {field.length > 1 && (
              <Form.Input
                key={field[1].id + index + "_r"}
                data-elm-id={"firstLoginInput" + field[1].id}
                fluid
                id={field[1].id}
                type={field[1].type}
                name={field[1].name}
                placeholder={formatMessage({ ...descriptors[field[1].placeholder] })}
                value={values[field[1].id]}
                onChange={handleChange}
                disabled={isSubmitting}
              />
            )}
          </Form.Group>
        );
      })}

      <Form.Group widths="equal" key={"personal_info_group_birthdate"}>
        <div className="field">
          <Field
            data-elm-id={"firstLoginInputBirthdate"}
            name={"birthdate"}
            render={({ birthdateField }: { birthdateField: any }) => (
              <MaskedInput
                {...birthdateField}
                value={startDate}
                mask={dateMask}
                id="birthdate"
                placeholder={formatMessage({ ...descriptors[FirstLoginType.birthdate] })}
                type="text"
                disabled={isSubmitting}
                onChange={handleChange}
              />
            )}
          />
        </div>
      </Form.Group>
      <Form.Group widths="equal" key={"personal_info_group_phone_number"}>
        <div className="field">
          <Field
            name="phoneNumber"
            data-elm-id={"firstLoginInputPhoneNumber"}
            render={({ phoneField }: { phoneField: any }) => (
              <MaskedInput
                {...phoneField}
                value={values.phoneNumber}
                mask={phoneNumberMask}
                id="phoneNumber"
                placeholder={formatMessage({ ...descriptors[FirstLoginType.phoneNumber] })}
                type="text"
                onChange={handleChange}
                disabled={isSubmitting}
              />
            )}
          />
        </div>
      </Form.Group>
      <Form.Group widths="equal" key={"personal_info_group_buttom"} className={"buttonContainer"}>
        <Form.Button size="large" type="submit" disabled={isSubmitting} onClick={handleSubmit} className={"signUp"}>
          <FormattedMessage {...descriptors[FirstLoginType.submit]} />
        </Form.Button>
      </Form.Group>
    </React.Fragment>
  );

  return (
    <FloatedCard
      // subtitle={formatMessage({ ...descriptors[FirstLoginType.subtitle] })}
      content={content}
      messages={messages}
      isSubmitting={isSubmitting}
      size={"bigCentered"}
    />
  );
};

export const FirstLoginPersonalInfo = withRouter(FirstLoginPersonalInfoClass)
