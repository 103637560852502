import * as React from "react";
import { Input, Menu, Loader } from "semantic-ui-react";
import { debounce } from "lodash";

import { SearchFilter } from "../../../../../interfaces";

import "./styles.scss";

interface Props {
  children: JSX.Element;

  /**
   * Handle to refresh content with the result
   */
  refreshData: (result: any) => void;

  /**
   * Search function name
   */
  searchFunction: (filters?: SearchFilter | null) => Promise<any>;

  /**
   * Handle to refresh content with the result
   */
  onSearchedHandler?: (searchActive: boolean) => void;
}

const SearchFC: React.FC<Props> = ({
  children,

  refreshData,
  searchFunction,
  onSearchedHandler,
}) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [searchTitle, setSearchTitle] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (searchTitle !== "" && searchTitle !== null) {
      search();
    }
  }, [searchTitle, searchFunction]);

  const search = () => {
    setLoading(true);

    const filterBody: SearchFilter = {
      title: searchTitle,
    };

    searchFunction(filterBody)
      .then(refreshData)
      .finally(() => {
        setLoading(false);
      });
  };

  const searchByContent = (query: string): void => {
    setSearchTitle(query);
  };

  const delayedQuery = React.useRef(debounce((q: string) => searchByContent(q), 500)).current;

  const onChange = (e: any) => {
    delayedQuery(e.target.value);
  };

  const searchActive = searchTitle !== "" && searchTitle !== null;

  React.useEffect(() => {
    if (onSearchedHandler) {
      onSearchedHandler(searchActive);
    }
  }, [searchActive]);

  return (
    <div>
      <Menu secondary className={"filterMenu"}>
        {/* SEARCH BY ... */}
        <Menu.Item className={"searchMenu"}>
          <Input className={"searchInput"} icon="search" placeholder="Search..." onChange={onChange} />
        </Menu.Item>
      </Menu>
      {loading ? <Loader active size="large" /> : searchActive && children}
    </div>
  );
};

export const SearchComponent = SearchFC;
