import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Grid, Tab } from "semantic-ui-react";
import _, { get } from "lodash";

import { AppContext } from "../../../providers";
import * as DTO from "../../../interfaces";
import { SideBar, TitleSection, CoachesList, LocalSearch } from "../../../components";
import { coachWithWritePermission, URLS } from "../../../utils";

import { WhiteContainer, ContentGrid, SocialGrid } from "../../../styling/baseStyle";
import "./styles.scss";
import { SubCoachesServices } from "../../../services/SubCoachesServices";
import { WebComponents } from "../../../interfaces";

type Props = RouteComponentProps;

const AdminFC: React.FC<Props> = ({ history, location }) => {
  const { userContext } = React.useContext(AppContext);
  const withWritePermission = coachWithWritePermission(WebComponents.ADMIN, userContext);

  const [subcoaches, setSubcoaches] = React.useState<DTO.SubCoach[] | undefined>(undefined);
  const [cancelled, setCancelled] = React.useState<DTO.SubCoach[]>([]);
  const [pending, setPending] = React.useState<DTO.SubCoach[]>([]);

  const [loading, setLoading] = React.useState<boolean>(true);

  const tabPosition = {
    ACTIVE: 0,
    INACTIVE: 1,
  };

  const selectedTab = get(location, "state.selectedTab", "ACTIVE");
  const [selectedIndex, setSelectedIndex] = React.useState<number>(tabPosition[selectedTab]);

  React.useEffect(() => {
    if (subcoaches) {
      setLoading(false);
    }
  }, [subcoaches]);

  const panes = [
    {
      menuItem: "Active",
      render: () => (
        <Tab.Pane>
          <LocalSearch
            refreshData={setSubcoaches}
            content={<CoachesList history={history} loading={loading} coaches={subcoaches} />}
            searchFunction={SubCoachesServices.get}
            showSortBy={false}
            searchAttribute={"user.email"}
            filterCondition={(element, searchTerm) => {
              const lowerTerm = searchTerm.toLowerCase().trim();
              const fullName =
                _.get(element, "profile.firstName", "").toLowerCase() +
                " " +
                _.get(element, "profile.lastName", "").toLowerCase();
              return _.get(element, "user.email", "").includes(searchTerm) || fullName.includes(lowerTerm);
            }}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Inactive",
      render: () => (
        <Tab.Pane>
          <LocalSearch
            refreshData={setCancelled}
            content={<CoachesList history={history} loading={loading} coaches={cancelled} />}
            searchFunction={SubCoachesServices.getInactive}
            showSortBy={false}
            searchAttribute={"user.email"}
            filterCondition={(element, searchTerm) => {
              const lowerTerm = searchTerm.toLowerCase().trim();
              const fullName =
                _.get(element, "profile.firstName", "").toLowerCase() +
                " " +
                _.get(element, "profile.lastName", "").toLowerCase();
              return _.get(element, "user.email", "").includes(searchTerm) || fullName.includes(lowerTerm);
            }}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Pending",
      render: () => (
        <Tab.Pane>
          <LocalSearch
            refreshData={setPending}
            content={<CoachesList history={history} loading={loading} coaches={pending} />}
            searchFunction={SubCoachesServices.getPending}
            showSortBy={false}
            searchAttribute={"user.email"}
            filterCondition={(element, searchTerm) => {
              const lowerTerm = searchTerm.toLowerCase().trim();
              const fullName =
                _.get(element, "profile.firstName", "").toLowerCase() +
                " " +
                _.get(element, "profile.lastName", "").toLowerCase();
              return _.get(element, "user.email", "").includes(searchTerm) || fullName.includes(lowerTerm);
            }}
          />
        </Tab.Pane>
      ),
    },
  ];

  const customLinkSection = (): JSX.Element => {
    return (
      <SocialGrid.Column width={12} floated="right">
        <SocialGrid.Column floated="right" className={"buttonRight"}>
          <Button
            data-elm-id={`adminSubCoachesAddNewBtn`}
            onClick={() => history.push(URLS.coach.newSubCoach)}
            fluid
            className={"primary rounded buttonLeft mediumSize"}>
            Add a New User
          </Button>
        </SocialGrid.Column>
      </SocialGrid.Column>
    );
  };

  return (
    <WhiteContainer>
      <SideBar history={history} />

      <ContentGrid className={"listManagerContent"} columns={1}>
        <Grid columns={1}>
          <TitleSection
            title={"Admin"}
            ready={true}
            showNextOption={false}
            showLinkOption={withWritePermission}
            customLinkSection={customLinkSection()}
          />

          <Grid.Row className={"subcoachesListRowContainer coachBaseContainer"}>
            <Grid.Column>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Tab
                      defaultActiveIndex={tabPosition[selectedTab]}
                      panes={panes}
                      onTabChange={(e, data) => {
                        setSelectedIndex(Number(data.activeIndex) || 0);
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const AdminSubCoaches = withRouter(AdminFC);
