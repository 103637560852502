import * as React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Form, Header } from "semantic-ui-react";

import { FirstSocialNetworkType, descriptors } from "./descriptors";
import { FloatedCard } from "../../../../containers";
import { PlayerSocialAccounts } from "../../../../components";

import { CoachBasic } from "../../../../interfaces";
import { AppContext } from "../../../../providers";

import { BubbleMobileDetails } from "../BubbleMobileDetails";
import "./styles.scss";

interface OwnProps {
  /**
   * Submit handler
   */
  handleSubmit?: () => void;

  messages?: { info?: string[]; error?: string[]; warn?: string[] };

  coachInfo?: CoachBasic;
  isSubmitting?: boolean;
}

type Props = OwnProps;

/**
 * Represents a SignUp screen.
 */
const FirstSocialNetworksFC: React.FC<Props> = ({ messages, handleSubmit, isSubmitting, coachInfo }) => {
  const { formatMessage } = useIntl();
  const { userContext } = React.useContext(AppContext);
  const withSocialNetworks = userContext?.profile.socialNetworks && userContext?.profile.socialNetworks?.length > 0;
  
  const content = (
    <React.Fragment>
      <Header as="h4" textAlign="center" className={"firstSocialNetworkTitle"}>
        <Header.Content> {formatMessage({ ...descriptors[FirstSocialNetworkType.subtitle] })}</Header.Content>
      </Header>

      {coachInfo && (
        <BubbleMobileDetails
          messages={[
            {
              message: formatMessage({ ...descriptors[FirstSocialNetworkType.bubbleMessage1] }),
            },
            {
              message: formatMessage({ ...descriptors[FirstSocialNetworkType.bubbleMessage2] }),
            },
          ]}
          className={"noPadding"}
          currentPosition={0}
          coachInfo={coachInfo}
        />
      )}
      <PlayerSocialAccounts type={"FIRSTLOGIN"} showTitle={false} deleteEnable={false} />

      <Form.Group widths="equal" className={"buttonContainer"}>
        <Form.Button
          size="large"
          type="submit"
          data-elm-id={"firstLoginSocialButtonAction"}
          disabled={isSubmitting}
          onClick={handleSubmit}
          className={"signUp"}>
          <FormattedMessage {...descriptors[FirstSocialNetworkType[withSocialNetworks ? "submit" : "skip"]]} />
        </Form.Button>
      </Form.Group>
    </React.Fragment>
  );

  return (
    <FloatedCard content={content} messages={messages} isSubmitting={isSubmitting} size={"bigCenteredNoPadding"} />
  );
};

export const FirstSocialNetworks = FirstSocialNetworksFC;
