import * as React from "react";
import {
  Modal,
  Button,
  Menu,
  DropdownItemProps,
  Checkbox,
  Grid,
  Segment,
  Input,
} from "semantic-ui-react";
import { map } from "lodash";
import DatePicker from "react-datepicker";

import { AllFilter } from "../../../../../../interfaces";
import { CoachService } from "../../../../../../services";

import { CustomDropDownComponent } from "../../../../../../components";

import "./styles.scss";
import { modalReducer } from "./modalReducer";

interface Props {
  /**
   * Modal Title
   */
  title?: string;
  /**
   * Modal custom value
   */
  customValue?: any;

  coachId: string;
  opened: boolean;

  filters?: AllFilter;
  multiple: boolean;
  loading: boolean;
  keywordsOptions: DropdownItemProps[];
  reviewStatusOptions: DropdownItemProps[];
  playerList: DropdownItemProps[];
  hasSavedKeywords: boolean;

  acceptHandler: (updatedFilters?: AllFilter) => void;
  rejectHandler: () => void;
  clearHandler: () => void;
}

const FilterModalFC: React.FC<Props> = ({
  title,
  opened,
  coachId,
  filters,
  multiple,
  loading,
  keywordsOptions,
  reviewStatusOptions,
  playerList,
  hasSavedKeywords,
  acceptHandler,
  rejectHandler,
  clearHandler,
}) => {
  const [state, dispatch] = React.useReducer(modalReducer, { open: opened });

  const [allFilters, setAllFilters] = React.useState<AllFilter | undefined>(
    filters
  );

  const [branchesFilters, setBranchesFilters] = React.useState<
    DropdownItemProps[]
  >([]);
  const [regionFilters, setRegionsFilters] = React.useState<
    DropdownItemProps[]
  >([]);
  const [stateFilters, setStatesFilters] = React.useState<DropdownItemProps[]>(
    []
  );

  React.useEffect(() => {
    CoachService.branches(coachId).then((response) => {
      const filtersB: DropdownItemProps[] = map(response, (branch) => ({
        key: branch.branchId,
        text: branch.name,
        value: branch.branchId,
      }));
      setBranchesFilters(filtersB);
    });
    CoachService.regions(coachId).then((response) => {
      const filtersR: DropdownItemProps[] = map(response, (region) => ({
        key: region.regionId,
        text: region.name,
        value: region.regionId,
      }));
      setRegionsFilters(filtersR);
    });
    CoachService.states(coachId).then((response) => {
      const filtersStates: DropdownItemProps[] = map(response, (fstate) => ({
        key: fstate.stateId,
        text: fstate.stateCode,
        value: fstate.stateId,
      }));
      setStatesFilters(filtersStates);
    });
  }, [coachId]); // forceRefresh

  React.useEffect(() => {
    dispatch({ type: opened ? "open" : "close" });
  }, [opened]);

  React.useEffect(() => {
    setAllFilters(filters);
  }, [filters]);

  return (
    <Modal
      size={"small"}
      open={state.open}
      className="filterModal"
      onClose={() => {
        dispatch({ type: "close" });
        rejectHandler();
      }}
    >
      <Modal.Header>{title}</Modal.Header>

      <Modal.Content className="filterModalContent">
        <Menu secondary className={"allFilterSearchSetFilterMenu"}>
          <Grid>
            {/*  Keyword */}
            <Grid.Row>
              <label className="filterDesc">Keyword</label>
            </Grid.Row>
            <Grid.Row>
              <CustomDropDownComponent
                defaultValue={allFilters?.searchKeywords}
                options={keywordsOptions}
                placeholder={"-"}
                multiple={true}
                onChangeHandler={(data) => {
                  setAllFilters((cFilter) => {
                    return {
                      ...cFilter,
                      searchKeywords:
                        data !== undefined ? (data as string[]) : [],
                    };
                  });
                }}
              />
            </Grid.Row>

            {/*  Review Status */}
            <Grid.Row>
              <label className="filterDesc">Review Status</label>
            </Grid.Row>
            <Grid.Row>
              {/* FILTER BY Review Status */}
              <CustomDropDownComponent
                defaultValue={allFilters?.coachPostReviewStatus}
                options={reviewStatusOptions}
                placeholder={"-"}
                multiple={true}
                onChangeHandler={(data) => {
                  setAllFilters((cFilter) => {
                    return {
                      ...cFilter,
                      searchKeywords: cFilter?.searchKeywords,
                      coachPostReviewStatus: data as any[],
                    };
                  });
                }}
              />
            </Grid.Row>

            {/* Player */}
            <Grid.Row>
              <label className="filterDesc">Player</label>
            </Grid.Row>
            <Grid.Row>
              {/* FILTER BY PLAYER */}
              {playerList && playerList?.length > 0 && (
                <CustomDropDownComponent
                  defaultValue={allFilters?.playerIds}
                  options={playerList}
                  placeholder={"-"}
                  multiple={multiple}
                  onChangeHandler={(data) => {
                    setAllFilters((cFilter) => {
                      return {
                        ...cFilter,
                        searchKeywords: cFilter?.searchKeywords,
                        playerIds: data as string[],
                      };
                    });
                  }}
                />
              )}
            </Grid.Row>

            {/* BRANCH */}
            {branchesFilters && branchesFilters?.length > 0 && (
              <>
                <Grid.Row>
                  <label className="filterDesc">Branch</label>
                </Grid.Row>
                <Grid.Row>
                  <CustomDropDownComponent
                    defaultValue={allFilters?.branchIds}
                    options={branchesFilters}
                    placeholder={"-"}
                    multiple={multiple}
                    onChangeHandler={(data) => {
                      setAllFilters((cFilter) => {
                        return {
                          ...cFilter,
                          searchKeywords: cFilter?.searchKeywords,
                          branchIds: data as string[],
                        };
                      });
                    }}
                  />
                </Grid.Row>
              </>
            )}

            {/* REGION */}
            {regionFilters && regionFilters?.length > 0 && (
              <>
                <Grid.Row>
                  <label className="filterDesc">Region</label>
                </Grid.Row>
                <Grid.Row>
                  {/* <Menu.Item> */}
                  <CustomDropDownComponent
                    defaultValue={allFilters?.regionIds}
                    options={regionFilters}
                    placeholder={"-"}
                    multiple={multiple}
                    onChangeHandler={(data) => {
                      setAllFilters((cFilter) => {
                        return {
                          ...cFilter,
                          searchKeywords: cFilter?.searchKeywords,
                          regionIds: data as string[],
                        };
                      });
                    }}
                  />
                </Grid.Row>
              </>
            )}

            {/* LICENSE STATE */}
            {stateFilters && stateFilters?.length > 0 && (
              <>
                <Grid.Row>
                  <label className="filterDesc">License State</label>
                </Grid.Row>
                <Grid.Row>
                  {/* <Menu.Item> */}
                  <CustomDropDownComponent
                    defaultValue={allFilters?.stateIds}
                    options={stateFilters}
                    placeholder={"-"}
                    multiple={multiple}
                    onChangeHandler={(data) => {
                      setAllFilters((cFilter) => {
                        return {
                          ...cFilter,
                          searchKeywords: cFilter?.searchKeywords,
                          stateIds: data as string[],
                        };
                      });
                    }}
                  />
                </Grid.Row>
              </>
            )}

            <Grid.Row>
              <label className="filterDesc">Date Range</label>
            </Grid.Row>
            <Grid.Row>
              {/* RANGE  */}
              <Menu.Item className={"rangeMenuItem"}>
                <DatePicker
                  className={"rangeInput"}
                  data-elm-id={"searchFromDateInput"}
                  id={"searchFromDate"}
                  name={"fromDate"}
                  selected={allFilters?.from}
                  placeholderText={"Start Date"}
                  onChange={(date: Date) => {
                    setAllFilters((cFilter) => {
                      return {
                        ...cFilter,
                        searchKeywords: cFilter?.searchKeywords,
                        from: date,
                      };
                    });
                  }}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={15}
                  maxDate={allFilters?.until || new Date()}
                  disabled={loading}
                  customInput={<Input icon="calendar outline" />}
                />
              </Menu.Item>
              <Menu.Item className={"rangeMenuItem"}>
                <DatePicker
                  className={"rangeInput"}
                  customInput={<Input icon="calendar outline" />}
                  data-elm-id={"searchUntilDateInput"}
                  id={"searchUntilDate"}
                  name={"untilDate"}
                  selected={allFilters?.until}
                  placeholderText={"End Date"}
                  onChange={(date: Date) => {
                    setAllFilters((cFilter) => {
                      return {
                        ...cFilter,
                        searchKeywords: cFilter?.searchKeywords,
                        until: date,
                      };
                    });
                  }}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={15}
                  maxDate={new Date()}
                  minDate={allFilters?.from}
                  disabled={loading}
                />
              </Menu.Item>
            </Grid.Row>

            {/* FLAGGED POST ONLY */}
            <Grid.Row
              width={4}
              floated="right"
              className={"searchHeaderRightCol"}
            >
              <Segment compact floated="right" className="noBorderCheckbox">
                <Checkbox
                  disabled={!hasSavedKeywords}
                  label={"Flagged Posts Only"}
                  checked={allFilters?.flagged || false}
                  onChange={() => {
                    setAllFilters((cfilter) => {
                      return {
                        ...cfilter,
                        searchKeywords: cfilter?.searchKeywords,
                        flagged: !cfilter?.flagged,
                      };
                    });
                  }}
                />
              </Segment>
            </Grid.Row>
          </Grid>
        </Menu>
      </Modal.Content>

      <Modal.Actions>
        <Button
          data-elm-id={"clearFiltersBtn"}
          secondary
          onClick={() => {
            dispatch({ type: "close" });
            clearHandler();
          }}
        >
          Clear Filters
        </Button>
        <Button
          data-elm-id={`applyFiltersBtn`}
          primary
          onClick={() => {
            dispatch({ type: "close" });
            acceptHandler(allFilters);
          }}
        >
          Apply Filters
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const FilterModal = FilterModalFC;
