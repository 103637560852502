import * as React from "react";
import { Icon } from "semantic-ui-react";
import { Media } from "../../../../interfaces";

import "./styles.scss";
import { MediaPlayer } from "../../../MediaPlayer";

interface OwnProps {
  src: Media;
  id: number;

  handleClose: (id: number) => void;
  handleUpdate: (id: number, url: string) => void;
}

const VideoProjectMediaItemFC: React.FC<OwnProps> = ({ src, id, handleClose }: OwnProps) => {
  return (
    <div className={`video-project-media-item-wrapper`}>
      <div
        className={`close-icon-wrapper${src.validAspectRatio ? " close-icon-basic" : " close-icon-error"}`}
        id={`${id}`}
        onClick={e => handleClose(id)}
      >
        <Icon name="trash alternate outline" className="close-icon" />
      </div>
      <MediaPlayer media={src} />
    </div>
  );
};

export const VideoProjectMediaItem = VideoProjectMediaItemFC;
