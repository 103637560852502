import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import{ useIntl, FormattedMessage } from "react-intl";
import { useCookies } from "react-cookie";
import { Form } from "semantic-ui-react";

import { URLS } from "../../../utils";
import { FloatedCard } from "../../../containers";
import { AppContext } from "../../../providers";

import { ThankYouType, descriptors } from "./descriptors";
import "./styles.scss";

type Props = RouteComponentProps;

const ThankYouClass: React.FC<Props> = ({ history }) => {
  const { formatMessage } = useIntl();
  const { setUserContext } = React.useContext(AppContext);

  const content = (
    <div className={"buttonContent"}>
      <Form.Button
        size="medium"
        type="submit"
        centered={"true"}
        onClick={() => {
          setUserContext();
          history.push(URLS.home);
        }}>
        <FormattedMessage {...descriptors[ThankYouType.done]} />
      </Form.Button>
    </div>
  );

  return (
    <FloatedCard
      title={formatMessage({ ...descriptors[ThankYouType.title] })}
      subtitle={formatMessage({ ...descriptors[ThankYouType.description] })}
      content={content}
      isSubmitting={false}
      size={"bigCentered"}
      padding={"small"}
    />
  );
};

export const ThankYou = withRouter(ThankYouClass)