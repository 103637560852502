import * as React from "react";
import{ useIntl } from "react-intl";
import * as DTO from "../../../../../../../interfaces";
// import { Svg } from "../../../../../../../assets";
// import { EmptyView } from "../../../../../../EmptyView";

import { PromptsByCategory } from "./components/PromptsByCategory";

interface OwnProps {
  categories: DTO.Category[];
  coachId: string;
  campaignPrompts: DTO.SelectedPrompts;

  onAddPromptHandler: (prompt: DTO.Prompt) => void;
  onRemovePromptHandler: (prompt: DTO.Prompt) => void;
}

type Props = OwnProps 

export default 
  ({ categories, coachId, campaignPrompts = {}, onAddPromptHandler, onRemovePromptHandler }: Props) => {
    const uncategorized: DTO.Category = {
      categoryId: "uncategorized",
      name: "Uncategorized",
    };
    return (
      <div className="promptListContainer">
        <PromptsByCategory
          coachId={coachId}
          key={"uncategorized"}
          category={uncategorized}
          campaignPrompts={campaignPrompts}
          onAddPrompt={onAddPromptHandler}
          onRemovePrompt={onRemovePromptHandler}
        />

        {categories.map(category => {
          return (
            <PromptsByCategory
              coachId={coachId}
              key={category.categoryId}
              category={category}
              campaignPrompts={campaignPrompts}
              onAddPrompt={onAddPromptHandler}
              onRemovePrompt={onRemovePromptHandler}
            />
          );
        })}
        {/* {showEmptySection && <EmptyView text={"You have no Library Prompts"} customIconImage={Svg.Library} />} */}
      </div>
    );
  }