import * as React from "react";
import { Link } from "react-router-dom";
import { Grid } from "semantic-ui-react";

import "./styles.scss";

class Footer extends React.Component {
  render() {
    return (
      <Grid className={"footer"}>
        <Grid.Column floated="left" computer={5} mobile={9}>
          <Link id="terms" to="/terms">
            Terms and Conditions
          </Link>
        </Grid.Column>

        <Grid.Column floated="right" tablet={2} largescreen={1} computer={1} mobile={4}>
          <a id="help" href="mailto:support@socialcoach.io">
            Help
          </a>
        </Grid.Column>
      </Grid>
    );
  }
}

export default Footer;
