import * as React from "react";
import { Header, Grid } from "semantic-ui-react";
import {get} from "lodash";

import { ProfileImage } from "../../../../components";
import { CoachBasic, Chat } from "../../../../interfaces";
import { userFullName } from "../../../../utils";

import "./styles.scss";

interface OwnProps {
  currentPosition?: number;
  messages: Chat[];
  coachInfo: CoachBasic;
}

type Props = OwnProps;

/**
 * Represents a Bubble detail - Right Bottom corner details
 */

const IntegratedBubbleDetails: React.FC<Props> = ({ coachInfo, messages, currentPosition = 0 }) => {
  return (
    <React.Fragment>
      <Grid only={"computer"} className={"bubble"}>
        {messages &&
          messages.map(({ message, title }, index) => {
            const currentBubble = index === currentPosition;
            return (
              <Grid.Row className={"noPadding"} only={"computer"} key={"bubble_" + index}>
                {currentBubble && (
                  <>
                    <Grid.Column className={"leftSection smallPhoto"} width={3}>
                      <ProfileImage
                        customClassName={"bubbleSmallPicture"}
                        photoUrl={get(coachInfo, "photoUrl", "")}
                      />
                    </Grid.Column>
                    <div className="leftPoint" />
                  </>
                )}
                <div className={currentBubble ? "message selected" : "message placeholder"}>
                  {currentBubble && <p className={"coachInfo"}> {userFullName(coachInfo)}</p>}
                  {title && <Header as={"h4"}>{title}</Header>}
                  <p className={"messageContent"}>{message}</p>
                </div>
              </Grid.Row>
            );
          })}
      </Grid>
    </React.Fragment>
  );
};

export const BubbleDetails = IntegratedBubbleDetails;
