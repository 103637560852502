import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Grid, Segment, Header, Loader } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";

import { UsersService } from "../../services";
import { URLS } from "../../utils/routes";
import { CoachVerifiedSection } from "./CoachVerifiedSection";

import { FloatedContainer } from "../../styling/baseStyle";
import { descriptors, VerificationPageType } from "./descriptors";
interface OwnProps {
  isPlayer: boolean;
}

type Props = OwnProps & RouteComponentProps;

const VerificationPageClass: React.FC<Props> = ({ isPlayer = false, history, match: { params } }) => {
  const { formatMessage } = useIntl();
  const [submitting, setSubmitting] = React.useState<boolean>(true);
  const [success, setSuccess] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (params["userId"] && params["actionToken"]) {
      verifyToken(params["userId"], params["actionToken"]);
    }
  }, [params["userId"], params["actionToken"], params["userRole"]]);

  const verifyToken = async (userId: string, userToken: string) => {
    UsersService.verifyAccount(userId, userToken)
      .then(() => {
        setSubmitting(false);
        setSuccess(true);
        // Comment this code to avoid redirection
        // setTimeout(() => {
        //   history.push(URLS.login);
        // }, 3000);
      })
      .catch(e => {
        setSubmitting(false);
        setSuccess(false);
      });
  };

  const toResend = () => history.push(URLS.resendEmail);

  const verifiedErrorSection = (): JSX.Element => {
    return (
      <Grid centered columns={2}>
        <Grid.Row centered>
          <Grid columns={16} verticalAlign="middle" stretched container centered>
            <Grid.Column mobile={16} tablet={10} computer={10} largescreen={10}>
              <Segment padded={"very"} centered={true}>
                <Header as="h2" textAlign="left">
                  <FormattedMessage {...descriptors[VerificationPageType.errorTitle]} />
                </Header>
                <div className="button-link">
                  <span onClick={toResend}>
                    <FormattedMessage
                      id={VerificationPageType.errorSubtitle}
                      defaultMessage={formatMessage({ ...descriptors[VerificationPageType.errorSubtitle] })}
                      values={{
                        span: name => <span className="underline">{name}</span>,
                      }}
                    />
                  </span>
                </div>
              </Segment>
            </Grid.Column>
          </Grid>
        </Grid.Row>
      </Grid>
    );
  };

  const redirectToLogin = () => {
    history.push(URLS.login);
  };

  const content = success ? <CoachVerifiedSection onClick={redirectToLogin} /> : verifiedErrorSection();

  return <FloatedContainer>{submitting ? <Loader active size="large" /> : content}</FloatedContainer>;
};

export const VerificationPage = withRouter(VerificationPageClass);
