import * as React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { SignUpType, descriptors } from "./descriptors";
import { fields } from "./formFields";
import { RouteComponentProps, withRouter } from "react-router";
import { Form, Popup, Icon } from "semantic-ui-react";
import { BasicFormProps } from "../../../interfaces/customTypes";
import { BasicCard } from "../../../containers/BasicCard";

import "./styles.scss";

interface OwnProps extends BasicFormProps {
  toSignIn?: () => void;
}

type Props = OwnProps & RouteComponentProps;

/**
 * Represents a Player SignUp section.
 */

const PlayerSignUpFC: React.FC<Props> = ({ messages, isSubmitting, handleChange, handleSubmit }) => {
  const { formatMessage } = useIntl();
  const content = (
    <React.Fragment>
      {fields.map((field, index) => {
        return (
          <Form.Group widths="equal" key={"formGroupEmail" + index}>
            <Form.Input
              key={field.id + index}
              data-elm-id={"playerRegisterInput" + field.id}
              fluid
              icon={
                field.id === "password" && (
                  <Popup trigger={<Icon name="info circle" className={"infoIcon"} link />}>
                    <FormattedMessage {...descriptors[SignUpType.passwordWithNumber]} />
                    <br />
                    <FormattedMessage {...descriptors[SignUpType.passwordWithCapital]} />
                    <br />
                    <FormattedMessage {...descriptors[SignUpType.passwordWithSpecialCharacter]} />
                    <br />
                    <FormattedMessage {...descriptors[SignUpType.passwordMin]} />
                  </Popup>
                )
              }
              id={field.id}
              type={field.type}
              name={field.name}
              placeholder={formatMessage({ ...descriptors[field.placeholder] })}
              onChange={handleChange}
              disabled={isSubmitting}
            />
          </Form.Group>
        );
      })}

      <Form.Group key={"formGroupButtons"} widths="equal" className={"buttonContainer"}>
        <Form.Button size="large" type="submit" disabled={isSubmitting} onClick={handleSubmit} className={"signUp"}>
          <FormattedMessage {...descriptors[SignUpType.submit]} />
        </Form.Button>
      </Form.Group>
    </React.Fragment>
  );

  return (
    <BasicCard
      title={formatMessage({ ...descriptors[SignUpType.title] })}
      content={content}
      messages={messages}
      isSubmitting={isSubmitting}
      size={"xsmall"}
    />
  );
};

export const PlayerSignUp = withRouter(PlayerSignUpFC);
