import * as styledComponents from "styled-components";

const spaceUnitDefault = 5;
const maxWidthSizeContainerDefault = 1250;
export interface Theme {
  primaryColor: string;
  secondaryColor: string;
  alertColor: string;
  successColor: string;

  // Texts Colors
  labelColor: string;
  titleColor: string;
  textFieldBackground: string;
  subtitleColor: string;
  defaultPlaceholder: string;
  descriptionColor: string;
  darkLine: string;
  blockedTagsColor: string;
  flaggedTagsColor: string;

  // Background colors
  backgroundColor: string;
  lightBackgroundColor: string;

  // Lines colors
  borderColor: string;
  boxLightBorder: string;
  boxColor: string;
  boxBorder: string;
  lineColor: string;
  shadowColor: string;

  // Button colors
  buttonColor: string;
  buttonDisabledColor: string;
  buttonBorderColor: string;
  buttonDisabledBorderColor: string;
  linkColor: string;
  linksColor: string;

  iconColor: string;
  iconLightColor: string;
  navBarColor: string;
  detailsColor: string;
  statsDetails: string;

  // Units
  spaceUnit: number;
  maxWidthSizeContainer: number;

  size: {
    xs: number;
    s: number;
    big: number;
    xbig: number;
    huge: number;
  };
}

const theme: Theme = {
  primaryColor: "#3aabef",
  secondaryColor: "#eb0084",
  alertColor: "#e98686",
  successColor: "#b8e986",

  // Texts Colors
  labelColor: "#989faf",
  titleColor: "#474c58",
  textFieldBackground: "#fafafb",
  subtitleColor: "#5c5c5c",
  defaultPlaceholder: "#979cac",
  descriptionColor: "#8d959e",
  darkLine: "#161616",
  blockedTagsColor: "#db0a0a",
  flaggedTagsColor: "#ffce22",

  // Background colors
  backgroundColor: "#f8f8fa",
  lightBackgroundColor: "#fefefe",

  // Lines colors
  borderColor: "#e9e9e9",
  boxLightBorder: "#ededed",
  boxColor: "#eff3fa",
  boxBorder: "#979797",
  lineColor: "#83899b",
  shadowColor: "#dedede",

  // Button colors
  buttonColor: "#3daef5",
  buttonDisabledColor: "#b3d7ff",
  buttonBorderColor: "#8d959e",
  buttonDisabledBorderColor: "#dadfe4",
  linkColor: "#5c5c5c",
  linksColor: "#8e94a5",

  iconColor: "#dddfe4",
  iconLightColor: "#eaeaea",
  navBarColor: "#000",
  detailsColor: "#767e93",
  statsDetails: "#80889c",

  // Units
  spaceUnit: spaceUnitDefault,
  maxWidthSizeContainer: maxWidthSizeContainerDefault,

  size: {
    xs: spaceUnitDefault * 1,
    s: spaceUnitDefault * 2,
    big: spaceUnitDefault * 3,
    xbig: spaceUnitDefault * 6,
    huge: spaceUnitDefault * 10,
  },
};

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} = styledComponents;

export { css, createGlobalStyle, keyframes, ThemeProvider, theme };
export default styled;
