import * as React from "react";
import { useIntl } from "react-intl";
import { Grid, Header, Loader } from "semantic-ui-react";

import { PostReadyContent } from "../../../../../interfaces";
import { PostServices } from "../../../../../services";

import { descriptors, DashboardType } from "../../descriptors";
import { ReadyToPostCard } from "./components/ReadyToPostCard";

import "./styles.scss";

interface OwnProps {
  playerId: number;
}

type Props = OwnProps;

const ReadyToPostFC: React.FC<Props> = ({ playerId }) => {
  const { formatMessage } = useIntl();

  const [posts, setPosts] = React.useState<PostReadyContent[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const getPost = async () => {
    try {
      setLoading(true);
      PostServices.readyToPost(playerId)
        .then(r => {
          console.log("rady to post", r);
          setPosts(r);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getPost();
  }, [playerId]);

  return (
    <Grid.Row columns={1} className={"readyToPostContent"}>
      <Grid.Column>
        <Grid columns={1}>
          <Grid.Row columns={1}>
            <Grid.Column width={16}>
              {loading && <Loader active size="large" />}
              {posts && posts.length > 0 && (
                <Grid className={"readyToPost"}>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Header as="h2">
                        {formatMessage({ ...descriptors[DashboardType.readyToPostSectionTitle] })}
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                  <div className="cardsContainer">
                    {posts.map((post, index) => (
                      <ReadyToPostCard key={index} post={post} refreshHandler={getPost} />
                    ))}
                  </div>
                </Grid>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid.Row>
  );
};

export const ReadyToPost = ReadyToPostFC;
