import { defineMessages } from "react-intl";

export enum VideoProjectPageType {
  title = "VideoProjectPageType.title",
  saveBtn = "VideoProjectPageType.saveBtn",
  resubmitBtn = "VideoProjectPageType.resubmitBtn",
  cancelBtn = "VideoProjectPageType.Cancel",
  archiveBtn = "VideoProjectPageType.archiveBtn",

  unknownError = "VideoProjectPageType.unknownError",
  savedMessage = "VideoProjectPageType.savedMessage",

  cancelAlertTitle = "VideoProjectPageType.cancelAlertTitle",
  archiveAlertTitle = "VideoProjectPageType.archiveAlertTitle",

  wrongAspectRatioMessage = "VideoProjectPageType.wrongAspectRatioMessage",
  wrongAspectRatioReelsMessage = "VideoProjectPageType.wrongAspectRatioReelsMessage",
  wrongFeedMultipleImagesMessage = "VideoProjectPageType.wrongFeedMultipleImagesMessage",
  wrongSpecificationsMessage = "VideoProjectPageType.wrongSpecificationsMessage",
  wrongResolutionMessage = "VideoProjectPageType.wrongResolutionMessage",
  wrongBitrateMessage = "VideoProjectPageType.wrongBitrateMessage",
  wrongDurationoMessage = "VideoProjectPageType.wrongDurationoMessage",
  wrongSizeMessage = "VideoProjectPageType.wrongSizeMessage",
  wrongFrameRateMessage = "VideoProjectPageType.wrongFrameRateMessage",

  postStatusLabel = "VideoProjectPageType.postStatusLabel",
  preferencesLabel = "VideoProjectPageType.preferencesLabel",
  attributeRequired = "VideoProjectPageType.attributeRequired",
}

export const en = {
  [VideoProjectPageType.title]: "Video Catalyst Project",
  [VideoProjectPageType.saveBtn]: "Submit Edit",
  [VideoProjectPageType.resubmitBtn]: "Re-submit",
  [VideoProjectPageType.cancelBtn]: "Cancel",
  [VideoProjectPageType.archiveBtn]: "Archive Project",
  [VideoProjectPageType.preferencesLabel]: "Style Preferences:",

  [VideoProjectPageType.cancelAlertTitle]: "Are you sure you want to leave without submitting?",
  [VideoProjectPageType.archiveAlertTitle]: "Are you sure you want to archive this project?",

  [VideoProjectPageType.savedMessage]: "Video project saved!",
  [VideoProjectPageType.unknownError]: "Unknown Error.",

  [VideoProjectPageType.wrongFeedMultipleImagesMessage]:
    "One or more of the attached videos/images does not meet the specified file requirements. Please ensure that all attachments meet the requirements in the hover over next to the upload tool below.",

  [VideoProjectPageType.wrongAspectRatioMessage]:
    "This {type} does not meet our aspect ratio requirements. {typeAfterDot} must be 1:1, 4:5, 16:9 or 1.91:1.",

  [VideoProjectPageType.wrongAspectRatioReelsMessage]:
    "This video does not meet our aspect ratio requirements. Video must be 9:16.",

  [VideoProjectPageType.wrongSpecificationsMessage]:
    "Please edit the file below to meet the required file format and size requirements.",

  [VideoProjectPageType.wrongResolutionMessage]:
    "This video does not meet the our minimum resolution requirements. Video must have a minimum resolution of 540 x 960 pixels (1080 x 1920 is recommended).",
  [VideoProjectPageType.wrongBitrateMessage]:
    "This video does not meet our bitrate requirements. Videos must have maximum bitrate of 25Mbps.",
  [VideoProjectPageType.wrongDurationoMessage]:
    "This video does not meet our video duration requirements. Videos must be between 3 and 90 seconds in duration.",
  [VideoProjectPageType.wrongSizeMessage]:
    "This {type} does not meet our file size requirements. {typeAfterDot} must be under 100MB.",
  [VideoProjectPageType.wrongFrameRateMessage]:
    "This video does not meet our frame rate requirements. Videos must be in the range of 24–60 frames per second.",

  [VideoProjectPageType.attributeRequired]: "Final Edit is required",
  [VideoProjectPageType.postStatusLabel]: "Project Status",
};

export const descriptors = defineMessages({
  [VideoProjectPageType.title]: {
    id: VideoProjectPageType.title,
    defaultMessage: en[VideoProjectPageType.title],
    description: "-",
  },
  [VideoProjectPageType.cancelBtn]: {
    id: VideoProjectPageType.cancelBtn,
    defaultMessage: en[VideoProjectPageType.cancelBtn],
    description: "-",
  },
  [VideoProjectPageType.archiveBtn]: {
    id: VideoProjectPageType.archiveBtn,
    defaultMessage: en[VideoProjectPageType.archiveBtn],
    description: "-",
  },
  [VideoProjectPageType.unknownError]: {
    id: VideoProjectPageType.unknownError,
    defaultMessage: en[VideoProjectPageType.unknownError],
    description: "-",
  },
  [VideoProjectPageType.saveBtn]: {
    id: VideoProjectPageType.saveBtn,
    defaultMessage: en[VideoProjectPageType.saveBtn],
    description: "-",
  },
  [VideoProjectPageType.resubmitBtn]: {
    id: VideoProjectPageType.resubmitBtn,
    defaultMessage: en[VideoProjectPageType.resubmitBtn],
    description: "-",
  },
  [VideoProjectPageType.savedMessage]: {
    id: VideoProjectPageType.savedMessage,
    defaultMessage: en[VideoProjectPageType.savedMessage],
    description: "-",
  },
  [VideoProjectPageType.cancelAlertTitle]: {
    id: VideoProjectPageType.cancelAlertTitle,
    defaultMessage: en[VideoProjectPageType.cancelAlertTitle],
    description: "-",
  },
  [VideoProjectPageType.archiveAlertTitle]: {
    id: VideoProjectPageType.archiveAlertTitle,
    defaultMessage: en[VideoProjectPageType.archiveAlertTitle],
    description: "-",
  },
  [VideoProjectPageType.wrongAspectRatioMessage]: {
    id: VideoProjectPageType.wrongAspectRatioMessage,
    defaultMessage: en[VideoProjectPageType.wrongAspectRatioMessage],
    description: "-",
  },
  [VideoProjectPageType.wrongAspectRatioReelsMessage]: {
    id: VideoProjectPageType.wrongAspectRatioReelsMessage,
    defaultMessage: en[VideoProjectPageType.wrongAspectRatioReelsMessage],
    description: "-",
  },
  [VideoProjectPageType.wrongFeedMultipleImagesMessage]: {
    id: VideoProjectPageType.wrongFeedMultipleImagesMessage,
    defaultMessage: en[VideoProjectPageType.wrongFeedMultipleImagesMessage],
    description: "-",
  },
  [VideoProjectPageType.wrongSpecificationsMessage]: {
    id: VideoProjectPageType.wrongSpecificationsMessage,
    defaultMessage: en[VideoProjectPageType.wrongSpecificationsMessage],
    description: "-",
  },
  [VideoProjectPageType.wrongBitrateMessage]: {
    id: VideoProjectPageType.wrongBitrateMessage,
    defaultMessage: en[VideoProjectPageType.wrongBitrateMessage],
    description: "-",
  },
  [VideoProjectPageType.wrongResolutionMessage]: {
    id: VideoProjectPageType.wrongResolutionMessage,
    defaultMessage: en[VideoProjectPageType.wrongResolutionMessage],
    description: "-",
  },
  [VideoProjectPageType.wrongDurationoMessage]: {
    id: VideoProjectPageType.wrongDurationoMessage,
    defaultMessage: en[VideoProjectPageType.wrongDurationoMessage],
    description: "-",
  },
  [VideoProjectPageType.wrongFrameRateMessage]: {
    id: VideoProjectPageType.wrongFrameRateMessage,
    defaultMessage: en[VideoProjectPageType.wrongFrameRateMessage],
    description: "-",
  },
  [VideoProjectPageType.wrongSizeMessage]: {
    id: VideoProjectPageType.wrongSizeMessage,
    defaultMessage: en[VideoProjectPageType.wrongSizeMessage],
    description: "-",
  },
  [VideoProjectPageType.attributeRequired]: {
    id: VideoProjectPageType.attributeRequired,
    defaultMessage: en[VideoProjectPageType.attributeRequired],
    description: "-",
  },
  [VideoProjectPageType.postStatusLabel]: {
    id: VideoProjectPageType.postStatusLabel,
    defaultMessage: en[VideoProjectPageType.postStatusLabel],
    description: "-",
  },
  [VideoProjectPageType.preferencesLabel]: {
    id: VideoProjectPageType.preferencesLabel,
    defaultMessage: en[VideoProjectPageType.preferencesLabel],
    description: "-",
  },
});
