import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Grid } from "semantic-ui-react";
import { SideBar } from "../../../components";
import { AppContext } from "../../../providers";
import { get } from "lodash";
import { QuestionnaireForm } from "../../../components/QuestionnaireForm";

import { WhiteContainer, ContentGrid } from "../../../styling/baseStyle";

type Props = RouteComponentProps;

const QuestionnaireFC: React.FC<Props> = ({ history }) => {
  return (
    <WhiteContainer>
      <SideBar history={history} />

      <ContentGrid columns={1}>
        <AppContext.Consumer>
          {({ userContext }) => {
            const coachId = get(userContext, "coach.id", undefined);
            return (
              <Grid columns={1}>
                <QuestionnaireForm coachId={coachId!} />
              </Grid>
            );
          }}
        </AppContext.Consumer>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const Questionnaire = withRouter(QuestionnaireFC);
