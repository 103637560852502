import * as React from "react";
import{ useIntl, FormattedMessage } from "react-intl";
import { SocialMediaStepType, descriptors } from "./descriptors";
import { RouteComponentProps, withRouter } from "react-router";
import { Header, Form, InputOnChangeData } from "semantic-ui-react";
import SocialNetworks from "../SocialNetworks";
import * as MODEL from "../../interfaces/models";
import "./styles.scss";
import { FloatedCard } from "../../containers";

interface OwnProps {
  /**
   * On Change handler
   */
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;

  /**
   * Submit handler
   */
  handleSubmit?: () => void;

  /**
   * Back button handler
   */
  handleBack?: () => void;

  /**
   * Social networks handler
   */
  handleSocialNetworks: (socialNetworks: MODEL.Social) => void;

  /**
   * SocialNetworks elements
   */
  socialNetworks: MODEL.Social;

  messages?: { info?: string[]; error?: string[]; warn?: string[] };

  locale?: string;
  i18n?: any;

  isSubmitting?: boolean;

  step: number;
}

type Props = OwnProps & RouteComponentProps;

/**
 * Represents a SignUp screen.
 */
const SocialNetworkFC: React.FC<Props> = ({
 
  messages,
  handleBack,
  handleChange,
  handleSocialNetworks,
  handleSubmit,
  isSubmitting,
  step,
}) => {
  const { formatMessage } = useIntl();
  const [tacAccepted, setTacAccepted] = React.useState<boolean>(false);

  const content = (
    <React.Fragment>
      <SocialNetworks
        handleSocialNetworks={handleSocialNetworks}
        handleTacChange={checked => setTacAccepted(checked)}
      />

      <Header as="h5" textAlign="center">
        {step} of 2
      </Header>

      <Form.Group widths="equal" className={"buttonContainer"}>
        <Form.Button
          size="large"
          data-elm-id={"registerSocialButtonBack"}
          type="submit"
          color={"grey"}
          disabled={isSubmitting}
          onClick={handleBack}
          className={"secondary"}>
          <FormattedMessage {...descriptors[SocialMediaStepType.back]} />
        </Form.Button>

        <Form.Button
          size="large"
          type="submit"
          data-elm-id={"registerSocialButtonSubmit"}
          disabled={isSubmitting || !tacAccepted}
          onClick={handleSubmit}
          className={"signUp"}>
          <FormattedMessage {...descriptors[SocialMediaStepType.submit]} />
        </Form.Button>
      </Form.Group>
    </React.Fragment>
  );

  return (
    <FloatedCard
      title={formatMessage({ ...descriptors[SocialMediaStepType.title] })}
      subtitle={formatMessage({ ...descriptors[SocialMediaStepType.subtitle] })}
      content={content}
      messages={messages}
      isSubmitting={isSubmitting}
      size={"bigCentered"}
    />
  );
};

export const SocialMediaStep = withRouter(SocialNetworkFC)
