import React from "react";
import { map } from "lodash";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";
import { InstagramPage } from "../../../../../interfaces";

interface Props {
  /**
   * Player Pages
   */
  pages: InstagramPage[];
  selectedPage: (selectedPage?: InstagramPage) => void;
}

const IGPageClass: React.FC<Props> = ({ pages, selectedPage }) => {
  const [pagesOptions, setPagesOptions] = React.useState<DropdownItemProps[]>([]);

  React.useEffect(() => {
    if (pages) {
      const filters: DropdownItemProps[] = map(pages, page => {
        return {
          key: page.instagramId,
          text: page.instagramUsername,
          description: page.name,
          value: page.instagramId,
        };
      });
      setPagesOptions(filters);
    }
  }, [pages]);

  const helpPages = {
    createIGAccount: "https://help.instagram.com/155940534568753",
    igBusiness: "https://help.instagram.com/502981923235522",
    connectToFb: "https://www.facebook.com/business/help/620548115562686",
  };

  return (
    <>
      {pages.length === 0 && (
        <div className={"igHelpGuide"}>
          <div>
            Looks like we weren't able to find any Instagram accounts connected to your Facebook account. But don't
            worry, we're here to help!
          </div>
          <div>Let's run through a few things to get you up and running.</div>
          <div>
            <ul>
              <li>
                <b>Do you have an Instagram account?</b> If not, no worries!{" "}
                <a href={helpPages.createIGAccount} target="_blank">
                  Click here
                </a>{" "}
                to create one in a flash.
              </li>
              <li>
                <b>Is your Instagram a 'Professional' account?</b>{" "}
                <a href={helpPages.igBusiness} target="_blank">
                  Click here
                </a>{" "}
                to find out how to check.
              </li>
              <li>
                <b>Have you connected your Instagram to your Facebook Business Page?</b> We've got a helpful guide right{" "}
                <a href={helpPages.connectToFb} target="_blank">
                  here
                </a>
                !
              </li>
            </ul>
          </div>
          <div>
            Once you've completed these steps, hop back here and try connecting your Instagram again. We're rooting for
            you!
          </div>
          <div>
            If you are still having problems, feel free to reach out to us at{" "}
            <a href={"mailto:support@socialcoach.io"}>support@socialcoach.io</a>
          </div>
        </div>
      )}
      {pages.length > 0 && (
        <Dropdown
          placeholder={"Select your Instagram Account"}
          fluid
          onChange={(e, data) => {
            const page = pages.find(p => p.instagramId === data.value);
            selectedPage(page);
          }}
          selection
          options={pagesOptions}
        />
      )}
    </>
  );
};

export const IGPagesList = IGPageClass;
