import * as DTO from "../../../interfaces";
import { getStatusText } from "../../../utils";

export const playersFilters = [
  {
    key: 1,
    value: DTO.SubscriptionStatus.PENDING_PAYMENT,
    text: getStatusText(DTO.SubscriptionStatus.PENDING_PAYMENT),
  },
  {
    key: 2,
    value: DTO.SubscriptionStatus.FREE_TRIAL,
    text: getStatusText(DTO.SubscriptionStatus.FREE_TRIAL),
  },
  {
    key: 3,
    value: DTO.SubscriptionStatus.ACTIVE,
    text: getStatusText(DTO.SubscriptionStatus.ACTIVE),
  },
  {
    key: 4,
    value: DTO.SubscriptionStatus.INACTIVE,
    text: "CREDIT CARD DECLINED",
  },
  {
    key: 5,
    value: DTO.SubscriptionStatus.CANCELED,
    text: getStatusText(DTO.SubscriptionStatus.CANCELED),
  },
];
