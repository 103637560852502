import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Grid } from "semantic-ui-react";

import { SideBar, TitleSection } from "../../../components";

import { URLS } from "../../../utils";

import { WhiteContainer, ContentGrid, SocialGrid } from "../../../styling/baseStyle";
import "./styles.scss";
import { SubCoachRegisterForm } from "../../SubCoach/RegisterForm";

type Props = RouteComponentProps;

const CreateSubCoachFC: React.FC<Props> = ({ history }) => {
  const formSubmitRef = React.useRef<HTMLFormElement>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const goToSubCoachesList = () => {
    setLoading(false);
    history.push(URLS.coach.subCoaches);
  };

  const customLinkSection = (): JSX.Element => {
    return (
      <SocialGrid.Column width={9} floated="right">
        <SocialGrid.Column width={8} floated="right" className={"buttonRight"}>
          <Button
            data-elm-id={`createSubcoachBackBtn`}
            fluid
            className={"secondary rounded buttonLeft"}
            onClick={goToSubCoachesList}>
            Back
          </Button>
        </SocialGrid.Column>
      </SocialGrid.Column>
    );
  };

  const addSubCoach = () => {
    // Execute handleSubmit action on create sub coach form
    if (formSubmitRef.current) {
      formSubmitRef!.current!.handleSubmit();
    }
  };

  return (
    <WhiteContainer>
      <SideBar history={history} />

      <ContentGrid className={"listManagerContent"} columns={1}>
        <Grid columns={1}>
          <TitleSection
            title={"Create a New Admin"}
            ready={true}
            customNextLabel={"Save"}
            titleSize={8}
            buttonsSize={8}
            nextButtonSize={6}
            handleNextAction={addSubCoach}
            disableButtons={loading}
            showLinkOption={true}
            customLinkSection={customLinkSection()}
          />

          <Grid.Row>
            <Grid.Column>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <SubCoachRegisterForm
                      formSubmitRef={formSubmitRef}
                      startLoading={() => setLoading(true)}
                      errorHandler={() => setLoading(false)}
                      successHandler={goToSubCoachesList}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const CreateSubCoach = withRouter(CreateSubCoachFC);
