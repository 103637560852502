import { FirstLoginType } from "./descriptors";
import * as MODEL from "../../../../interfaces/models";

export const fields: MODEL.FormField[][] = [
  [
    {
      id: "firstName",
      name: "firstName",
      type: "text",
      label: "firstName",
      placeholder: FirstLoginType.firstName,
      disabled: false,
    },
  ],
  [
    {
      id: "lastName",
      name: "lastName",
      type: "text",
      label: "lastName",
      placeholder: FirstLoginType.lastName,
      disabled: false,
    },
  ],
];
