import { defineMessages } from "react-intl";

export enum CampaignType {
  campaignTitle = "CampaignType.campaignTitle",
  deliveryLabel = "CampaignType.deliveryLabel",
  randomDelivery = "CampaignType.randomDelivery",
  specificDate = "CampaignType.specificDate",
  autoPostLabel = "CampaignType.autoPostLabel",
  autoPost = "CampaignType.autoPost",
  autoPostSocialNetworks = "CampaignType.autoPostSocialNetworks",
  randomDaysDescription = "CampaignType.randomDaysDescription",
  nextBtn = "CampaignType.nextBtn",
  cancelBtn = "CampaignType.cancel",
  startDateLabel = "CampaignType.startDateLabel",
  endDateLabel = "CampaignType.endDateLabel",
  durationSectionLabel = "CampaignType.durationSectionLabel",
  campaignDurationHoverDescription = "CampaignType.campaignDurationHoverDescription",

  // TODO: DELETEABLES
  titlePlaceholder = "CampaignType.titlePlaceholder",
  messagePlaceholder = "CampaignType.messagePlaceholder",
  uploadFilePlaceholder = "CampaignType.uploadFilePlaceholder",
  uploadVideoPlaceholder = "CampaignType.uploadVideoPlaceholder",
  categoryPlaceholder = "CampaignType.categoryPlaceholder",
  randomTimePickerPlaceholder = "CampaignType.randomTimePickerPlaceholder",

  videoBtn = "CampaignType.videoBtn",
  uploadBtn = "CampaignType.uploadBtn",
  browseBtn = "CampaignType.browseBtn",
  enableBtn = "CampaignType.enable",
  addCategoryBtn = "CampaignType.addCategory",

  categoryInfo = "CampaignType.categoryInfo",
  categoryNotSaved = "CampaignType.categoryNotSaved",
  pstZoneMessage = "CampaignType.pstZoneMessage",
}

export const en = {
  [CampaignType.campaignTitle]: "Campaign Title",
  [CampaignType.deliveryLabel]: "Delivery mode",
  [CampaignType.randomDelivery]: "Randomized",
  [CampaignType.specificDate]: "Scheduled",
  [CampaignType.autoPostLabel]: "Campaign Type",
  [CampaignType.randomTimePickerPlaceholder]: "Delivery Time (8:00 AM PST)",
  [CampaignType.pstZoneMessage]: "Prompts will be sent in Pacific Time",

  [CampaignType.autoPost]: "Auto Post",
  [CampaignType.randomDaysDescription]: "Days we should send randomized prompts to your players",
  [CampaignType.nextBtn]: "Next",
  [CampaignType.cancelBtn]: "Cancel",
  [CampaignType.titlePlaceholder]: "Enter Name",

  [CampaignType.autoPostSocialNetworks]: "Auto Post to:",
  [CampaignType.startDateLabel]: "Start Date (optional)",
  [CampaignType.endDateLabel]: "End Date (optional)",
  [CampaignType.durationSectionLabel]: "Campaign Duration (optional)",
  [CampaignType.messagePlaceholder]: "Write Message",
  [CampaignType.uploadFilePlaceholder]: "Select a file",
  [CampaignType.uploadVideoPlaceholder]: "Select a video",
  [CampaignType.categoryPlaceholder]: "Add Category",

  [CampaignType.videoBtn]: "Add your Video Message",
  [CampaignType.uploadBtn]: "Upload",
  [CampaignType.browseBtn]: "Browse",

  [CampaignType.enableBtn]: "Publish",
  [CampaignType.addCategoryBtn]: "Add Category",
  [CampaignType.campaignDurationHoverDescription]:
    "If you want your Campaign to start and/or end on specific dates, select the date(s) below. If left blank, the default Start Date for a Campaign is the day after the Campaign was created, and the default End Date is indefinite (it ends for each Player when they’ve received all the Prompts included in the Campaign).",

  [CampaignType.categoryInfo]: "Assign a category to your prompt to keep your prompts organized within the Library",
  [CampaignType.categoryNotSaved]: 'Please click "Add Category" to save your category',
};

export const descriptors = defineMessages({
  [CampaignType.categoryNotSaved]: {
    id: CampaignType.categoryNotSaved,
    defaultMessage: en[CampaignType.categoryNotSaved],
    description: "-",
  },
  [CampaignType.pstZoneMessage]: {
    id: CampaignType.pstZoneMessage,
    defaultMessage: en[CampaignType.pstZoneMessage],
    description: "-",
  },
  [CampaignType.randomTimePickerPlaceholder]: {
    id: CampaignType.randomTimePickerPlaceholder,
    defaultMessage: en[CampaignType.randomTimePickerPlaceholder],
    description: "-",
  },

  [CampaignType.categoryInfo]: {
    id: CampaignType.categoryInfo,
    defaultMessage: en[CampaignType.categoryInfo],
    description: "-",
  },
  [CampaignType.deliveryLabel]: {
    id: CampaignType.deliveryLabel,
    defaultMessage: en[CampaignType.deliveryLabel],
    description: "-",
  },
  [CampaignType.autoPost]: {
    id: CampaignType.autoPost,
    defaultMessage: en[CampaignType.autoPost],
    description: "-",
  },
  [CampaignType.randomDaysDescription]: {
    id: CampaignType.randomDaysDescription,
    defaultMessage: en[CampaignType.randomDaysDescription],
    description: "-",
  },
  [CampaignType.autoPostLabel]: {
    id: CampaignType.autoPostLabel,
    defaultMessage: en[CampaignType.autoPostLabel],
    description: "-",
  },
  [CampaignType.randomDelivery]: {
    id: CampaignType.randomDelivery,
    defaultMessage: en[CampaignType.randomDelivery],
    description: "-",
  },
  [CampaignType.specificDate]: {
    id: CampaignType.specificDate,
    defaultMessage: en[CampaignType.specificDate],
    description: "-",
  },
  [CampaignType.autoPostSocialNetworks]: {
    id: CampaignType.autoPostSocialNetworks,
    defaultMessage: en[CampaignType.autoPostSocialNetworks],
    description: "-",
  },
  [CampaignType.campaignTitle]: {
    id: CampaignType.campaignTitle,
    defaultMessage: en[CampaignType.campaignTitle],
    description: "-",
  },

  [CampaignType.startDateLabel]: {
    id: CampaignType.startDateLabel,
    defaultMessage: en[CampaignType.startDateLabel],
    description: "-",
  },
  [CampaignType.endDateLabel]: {
    id: CampaignType.endDateLabel,
    defaultMessage: en[CampaignType.endDateLabel],
    description: "-",
  },
  [CampaignType.videoBtn]: {
    id: CampaignType.videoBtn,
    defaultMessage: en[CampaignType.videoBtn],
    description: "-",
  },
  [CampaignType.durationSectionLabel]: {
    id: CampaignType.durationSectionLabel,
    defaultMessage: en[CampaignType.durationSectionLabel],
    description: "-",
  },
  [CampaignType.messagePlaceholder]: {
    id: CampaignType.messagePlaceholder,
    defaultMessage: en[CampaignType.messagePlaceholder],
    description: "-",
  },
  [CampaignType.titlePlaceholder]: {
    id: CampaignType.titlePlaceholder,
    defaultMessage: en[CampaignType.titlePlaceholder],
    description: "-",
  },
  [CampaignType.uploadFilePlaceholder]: {
    id: CampaignType.uploadFilePlaceholder,
    defaultMessage: en[CampaignType.uploadFilePlaceholder],
    description: "-",
  },
  [CampaignType.uploadVideoPlaceholder]: {
    id: CampaignType.uploadVideoPlaceholder,
    defaultMessage: en[CampaignType.uploadVideoPlaceholder],
    description: "-",
  },
  [CampaignType.uploadBtn]: {
    id: CampaignType.uploadBtn,
    defaultMessage: en[CampaignType.uploadBtn],
    description: "-",
  },
  [CampaignType.browseBtn]: {
    id: CampaignType.browseBtn,
    defaultMessage: en[CampaignType.browseBtn],
    description: "-",
  },
  [CampaignType.nextBtn]: {
    id: CampaignType.nextBtn,
    defaultMessage: en[CampaignType.nextBtn],
    description: "-",
  },
  [CampaignType.cancelBtn]: {
    id: CampaignType.cancelBtn,
    defaultMessage: en[CampaignType.cancelBtn],
    description: "-",
  },
  [CampaignType.enableBtn]: {
    id: CampaignType.enableBtn,
    defaultMessage: en[CampaignType.enableBtn],
    description: "-",
  },
  [CampaignType.categoryPlaceholder]: {
    id: CampaignType.categoryPlaceholder,
    defaultMessage: en[CampaignType.categoryPlaceholder],
    description: "-",
  },
  [CampaignType.addCategoryBtn]: {
    id: CampaignType.addCategoryBtn,
    defaultMessage: en[CampaignType.addCategoryBtn],
    description: "-",
  },
  [CampaignType.campaignDurationHoverDescription]: {
    id: CampaignType.campaignDurationHoverDescription,
    defaultMessage: en[CampaignType.campaignDurationHoverDescription],
    description: "-",
  },
});
