import * as React from "react";
import {
  Checkbox,
  Dropdown,
  DropdownItemProps,
  Form,
  Header,
} from "semantic-ui-react";
import states from "states-us";
import { map, union } from "lodash";

import { BasicFormProps } from "../../../../interfaces";
import { CoachService } from "../../../../services";

import "../styles.scss";

interface OwnProps extends BasicFormProps {
  /**
   * Coach Id to get branch, states, regions and cost center
   */
  coachId: string;
  /**
   * Input placeholder
   */
  placeholder: string;
  /**
   * Field identifier
   */
  id: string;
  /**
   * Field label (Title)
   */
  label: string;
  /**
   * Field value
   */
  fieldValue: any;
  /**
   * It's disabled
   */
  disabled: boolean;
  /**
   * Classname (optional)
   */
  className?: string;
  /**
   * To indicate if the field is multi selection
   */
  multiple: boolean;
  /**
   * Handle dropdowns changes
   */
  handleChange: (data: any) => void;
}

type Props = OwnProps;

/**
 * Add enterprise player internal form
 */

const SegmentationDropDownFC: React.FC<Props> = ({
  coachId,
  id,
  placeholder,
  fieldValue,
  label,
  disabled,
  className = "editPlayerDrowpdown",
  multiple,
  handleChange,
}) => {
  const stateOptions = map(
    states.filter((state) => !state.territory),
    (state) => ({
      key: state.abbreviation,
      text: " " + state.abbreviation + " - " + state.name,
      value: state.abbreviation,
    })
  );

  const [options, setOptions] = React.useState<DropdownItemProps[]>([]);

  React.useEffect(() => {
    if (id === "branch") {
      CoachService.branches(coachId).then((response) => {
        const filters: DropdownItemProps[] = map(response, (branch) => ({
          key: branch.name,
          text: branch.name,
          value: branch.name,
        }));
        setOptions(filters);
      });
    } else if (id === "region") {
      CoachService.regions(coachId).then((response) => {
        const filters: DropdownItemProps[] = map(response, (region) => ({
          key: region.name,
          text: region.name,
          value: region.name,
        }));
        setOptions(filters);
      });
    } else if (id === "costCenter") {
      CoachService.costCenters(coachId).then((response) => {
        const filters: DropdownItemProps[] = map(response, (region) => ({
          key: region.name,
          text: region.name,
          value: region.name,
        }));
        setOptions(filters);
      });
    }
  }, [coachId, id]);

  const handleAdditionField = (e: any, data: any) => {
    const { value } = data;
    const newCat = value.trim();
    if (newCat !== "") {
      setOptions((olds) => [{ text: value, value }, ...olds]);
    }
  };
  const props = {
    id,
    className,
    search: !multiple,
    selection: true,
    fluid: true,
    multiple,
    disabled,
    labeled: !multiple,
    placeholder: multiple
      ? fieldValue.length === 0
        ? placeholder
        : fieldValue.join(", ")
      : placeholder,
    "data-elm-id": `segmentation_dropdown_${id}`,
  };

  const simpleDropDown: JSX.Element = (
    <Dropdown
      {...props}
      icon=""
      allowAdditions
      value={fieldValue}
      options={options}
      onAddItem={handleAdditionField}
      onChange={(e, data) => handleChange(data)}
    />
  );

  const selectableDropdown: JSX.Element = (
    <Dropdown {...props}>
      <Dropdown.Menu>
        {stateOptions.map((stateFilter, index) => {
          return (
            <Dropdown.Item key={`dropdown_player_${id}_${index}`}>
              <Checkbox
                id={stateFilter.key}
                checked={fieldValue.includes(`${stateFilter!.value!}`)}
                onChange={(a, data) => {
                  const selectedState = `${stateFilter!.value!}`.toUpperCase();
                  let selected: string[] = fieldValue;
                  if (data.checked) {
                    selected = union(selected, [selectedState]);
                  } else {
                    selected = selected?.filter((r) => r !== selectedState);
                  }
                  handleChange({ value: selected });
                }}
              />
              {stateFilter.text}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );

  return (
    <Form.Group
      widths="equal"
      className={"groupInputsContainer"}
      key={`segmentation_dropdown_${id}`}
    >
      <Header as={"h3"} className={"permissionLabel"}>
        {label}
      </Header>
      {multiple ? selectableDropdown : simpleDropDown}
    </Form.Group>
  );
};

export const SegmentationDropDown = React.memo(SegmentationDropDownFC);
