import * as React from "react";
import { useIntl } from "react-intl";
import { Grid } from "semantic-ui-react";

import * as DTO from "../../../interfaces";
import { categoriesToFilter } from "../../../utils";

import { PromptServices, CategoriesServices } from "../../../services";
import { SearchComponent } from "../../Search";

import PromptsList from "./components/PromptList";

import "./styles.scss";
import { descriptors, LibraryType } from "./descriptors";

interface OwnProps {
  coachId: string;
  playerId: number;
  reloadPrompts?: boolean;
  isEmptySection?: (isEmpty: boolean) => void;
}

type Props = OwnProps;

const PlayerLibraryListContentFC: React.FC<Props> = ({
  coachId,
  reloadPrompts = false,
  playerId,
  isEmptySection = () => {},
}) => {
  const { formatMessage } = useIntl();
  const [promptList, setPromptList] = React.useState<DTO.Prompt[]>([]);
  const [categories, setCategories] = React.useState<DTO.Category[]>([]);
  const [categoriesFilters, setCategoriesFilters] = React.useState<DTO.FilterType[]>([]);
  const [typeFilters] = React.useState<DTO.FilterType[]>([
    { key: 1, text: "Feed Prompts", value: "FEED" },
    { key: 2, text: "Reel Prompts", value: "REELS" },
  ]);

  React.useEffect(() => {
    const getCategories = async () => {
      const result = await CategoriesServices.get(coachId);
      setCategories(() => result);
      setCategoriesFilters(categoriesToFilter(result));
    };

    getCategories();
  }, [coachId, playerId]);

  return (
    <Grid data-elm-id={"playerLibraryListContent"}>
      <Grid.Row>
        <Grid.Column>
          <SearchComponent
            refreshData={setPromptList}
            forceRefresh={reloadPrompts}
            content={
              <PromptsList
                categories={categories}
                promptList={promptList}
                coachId={coachId}
                isEmptySection={isEmptySection}
              />
            }
            customCoachId={`${playerId}`}
            filterPlaceholder={formatMessage({ ...descriptors[LibraryType.byCategory] })}
            secondFilterPlaceholder={formatMessage({ ...descriptors[LibraryType.byType] })}
            searchFunction={PromptServices.playerLibrary}
            showSortBy={false}
            filters={categoriesFilters}
            secondFilters={typeFilters}
            pageable={false}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const PlayerLibraryListContent = PlayerLibraryListContentFC;
