import { defineMessages } from "react-intl";

export enum PricePlanType {
  title = "PricePlan.title",
  subtitle = "PricePlan.subtitle",

  defaultPlanName = "PricePlanType.defaultPlanName",
  description = "PricePlanType.description",
  addBtn = "PricePlanType.addBtn",
  planDetails = "PricePlanType.planDetails",
  feesDesc = "PricePlanType.feesDesc",
  socialFees = "PricePlanType.socialFees",
  tdcFees = "PricePlanType.tdcFees",
  incomeDesc = "PricePlanType.incomeDesc",
  incomeError = "PricePlanType.incomeError",
  titleError = "PricePlanType.titleError",
  emptyOffersError = "PricePlanType.emptyOffersError",
  playersSubcribedError = "PricePlanType.playersSubcribedError",
}

export const en = {
  [PricePlanType.title]: "Set your Coaching Price Plan",
  [PricePlanType.subtitle]: "On this page you will customize your offering and set your monthly subscription price.",
  [PricePlanType.defaultPlanName]: "Become a Social Media Pro",
  [PricePlanType.description]: "Customize your offering",
  [PricePlanType.addBtn]: "Add another",
  [PricePlanType.planDetails]: "We take care of all the billing. You will receive a direct deposit each month.",
  [PricePlanType.feesDesc]: "Fees",
  [PricePlanType.socialFees]: "Goes to SocialCoach",
  [PricePlanType.tdcFees]: "Credit Card Proccessing Fee",
  [PricePlanType.incomeDesc]: "Monthly income per player",
  [PricePlanType.incomeError]: "Verify that total income per player should be greater than 0.",
  [PricePlanType.titleError]: "Plan name is required",

  [PricePlanType.emptyOffersError]: "Complete all offers before moving forward.",
  [PricePlanType.playersSubcribedError]: "Price cannot be updated as a player is already associated to this plan",
};

export const descriptors = defineMessages({
  [PricePlanType.title]: {
    id: PricePlanType.title,
    defaultMessage: en[PricePlanType.title],
    description: "-",
  },
  [PricePlanType.subtitle]: {
    id: PricePlanType.subtitle,
    defaultMessage: en[PricePlanType.subtitle],
    description: "-",
  },
  [PricePlanType.defaultPlanName]: {
    id: PricePlanType.defaultPlanName,
    defaultMessage: en[PricePlanType.defaultPlanName],
    description: "-",
  },
  [PricePlanType.description]: {
    id: PricePlanType.description,
    defaultMessage: en[PricePlanType.description],
    description: "-",
  },
  [PricePlanType.addBtn]: {
    id: PricePlanType.addBtn,
    defaultMessage: en[PricePlanType.addBtn],
    description: "-",
  },

  [PricePlanType.planDetails]: {
    id: PricePlanType.planDetails,
    defaultMessage: en[PricePlanType.planDetails],
    description: "-",
  },
  [PricePlanType.feesDesc]: {
    id: PricePlanType.feesDesc,
    defaultMessage: en[PricePlanType.feesDesc],
    description: "-",
  },
  [PricePlanType.socialFees]: {
    id: PricePlanType.socialFees,
    defaultMessage: en[PricePlanType.socialFees],
    description: "-",
  },
  [PricePlanType.tdcFees]: {
    id: PricePlanType.tdcFees,
    defaultMessage: en[PricePlanType.tdcFees],
    description: "-",
  },
  [PricePlanType.incomeDesc]: {
    id: PricePlanType.incomeDesc,
    defaultMessage: en[PricePlanType.incomeDesc],
    description: "-",
  },
  [PricePlanType.incomeError]: {
    id: PricePlanType.incomeError,
    defaultMessage: en[PricePlanType.incomeError],
    description: "-",
  },
  [PricePlanType.emptyOffersError]: {
    id: PricePlanType.emptyOffersError,
    defaultMessage: en[PricePlanType.emptyOffersError],
    description: "-",
  },
  [PricePlanType.titleError]: {
    id: PricePlanType.titleError,
    defaultMessage: en[PricePlanType.titleError],
    description: "-",
  },
  [PricePlanType.playersSubcribedError]: {
    id: PricePlanType.playersSubcribedError,
    defaultMessage: en[PricePlanType.playersSubcribedError],
    description: "-",
  },
});
