import * as React from "react";
import { Form } from "semantic-ui-react";
 import {NumericFormat} from "react-number-format";

import { Chat, CoachBasic, QuestionsConfig } from "../../../../../../../interfaces";
import { BubbleMobileDetails } from "../../../../BubbleMobileDetails";

import "./styles.scss";

interface OwnProps {
  field: QuestionsConfig;
  coachInfo?: CoachBasic;
  index: number;
  fieldValue: any;
  isSubmitting?: boolean;
  handleSetFieldValue?: (field: string, value: any) => void;
}

type Props = OwnProps;

/**
 * Question Input
 */
const IntegratedQuestionInput: React.FC<Props> = ({
  field,
  coachInfo,
  fieldValue,
  isSubmitting,
  handleSetFieldValue,
}) => {
  const isAFloat = field.answerType === "float";
  return (
    <>
      {coachInfo && (
        <BubbleMobileDetails
          messages={[{ message: field.content }]}
          currentPosition={0}
          coachInfo={coachInfo}
          className={"noPadding"}
        />
      )}
      <Form.Group widths="equal" key={field.key + "_group"}>
        <div className={"field"}>
          <NumericFormat
            key={field.key}
            data-elm-id={"playerProfileInformation-" + field.key}
            className={"questionInputs"}
            decimalScale={isAFloat ? 2 : 0}
            allowNegative={false}
            thousandSeparator
            onValueChange={value => {
              handleSetFieldValue!(field.key, value.floatValue);
            }}
            placeholder={field.contentDescriptor}
            value={fieldValue}
            disabled={isSubmitting}
          />
        </div>
      </Form.Group>
    </>
  );
};

export const QuestionInput = IntegratedQuestionInput;
