import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { CardContent, CardDescription, CardHeader } from "semantic-ui-react";

import { PlayerPost, ReviewStatus } from "../../../../../../../interfaces";
import { createVideoThumbnailBlob, printBasicDate } from "../../../../../../../utils";
import { PostServices } from "../../../../../../../services";

import { PostFailedModal } from "../PostFailedModal";
import { ComplianceModal } from "../ComplianceModal";
import { RepostModal } from "../RepostModal";

import useDisplayData from "./hooks";
import { NeedAttentionCard, ThumbnailPreview } from "./styled";
import "./styles.scss";

const MAX_CARD_WIDTH = 400;
const MIN_CARD_WIDTH = 148;

interface OwnProps {
  post: PlayerPost;
  refreshHandler: () => void;
}
type Props = OwnProps & RouteComponentProps;

const NeedsAttentionCardFC: React.FC<Props> = ({ post, refreshHandler }) => {
  const { hasPostMessage, createdDate, mediaType, mediaUrl, socialErrorMessage } = useDisplayData(post);
  const [thumbnail, setThumbnail] = React.useState<string | undefined>();
  const [imageWidth, setImageWidth] = React.useState<number>(MIN_CARD_WIDTH);
  const [isVertical, setIsVertical] = React.useState<boolean>(false);
  const [showPostFailedModal, setShowPostFailedModal] = React.useState<boolean>(false);
  const [showComplianceModal, setShowComplianceModal] = React.useState<boolean>(false);
  const [showRepostModal, setShowRepostModal] = React.useState<boolean>(false);

  const [selectedPost, setSelectedPost] = React.useState<PlayerPost>(post);

  React.useEffect(() => {
    const success = (width: number, height: number): void => {
      if (MAX_CARD_WIDTH * 0.6 < width) {
        if (width > height) {
          setImageWidth(MAX_CARD_WIDTH * 0.6);
        } else {
          setImageWidth((MAX_CARD_WIDTH * 0.55 * width) / height);
        }
      } else {
        setImageWidth(width);
      }
      setIsVertical(width < height);
    };

    const setImageSize = (setImageDimensions: any, imageUrl: string) => {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        setImageDimensions(img.width, img.height);
      };
    };

    if (hasPostMessage) {
      setImageSize(success, thumbnail!!);
    }
  }, [thumbnail, hasPostMessage]);

  React.useEffect(() => {
    if (mediaType) {
      const creativeUrl = mediaUrl;
      const urlPromise = mediaType === "IMAGE" ? Promise.resolve(creativeUrl) : createVideoThumbnailBlob(creativeUrl);
      urlPromise.then(url => {
        setThumbnail(url);
      });
    }
  }, [mediaUrl]);

  const cardPressed = (): void => {
    PostServices.getById(post.postId).then(response => {
      setSelectedPost(response);
      if (post.coachPostReviewStatus === ReviewStatus.REQUEST_CHANGES) {
        setShowComplianceModal(true);
      } else {
        setShowPostFailedModal(true);
      }
    });
  };

  const repostHandler = (): void => {
    setShowRepostModal(true);
  };
  return (
    <>
      <NeedAttentionCard
        onClick={cardPressed}
        className={"needAttentionCard"}
        imagewidth={imageWidth < MIN_CARD_WIDTH ? MIN_CARD_WIDTH : imageWidth}
      >
        <ThumbnailPreview
          thumbnailurl={thumbnail}
          resizemode={isVertical ? "cover" : "cover"}
          imagewidth={imageWidth}
        />
        <CardContent>
          <CardHeader>{socialErrorMessage}</CardHeader>
          <br />
          <CardDescription className={"needAttentionCardDate"} content={printBasicDate(createdDate)} />
        </CardContent>
      </NeedAttentionCard>
      {showPostFailedModal && (
        <PostFailedModal
          opened={showPostFailedModal}
          thumbnail={thumbnail || ""}
          selectedPost={selectedPost}
          repostHandler={repostHandler}
          closeHandler={() => {
            setShowPostFailedModal(false);
            refreshHandler();
          }}
        />
      )}
      {showComplianceModal && (
        <ComplianceModal
          playerId={post.playerId}
          opened={showComplianceModal}
          thumbnail={thumbnail || ""}
          selectedPost={selectedPost}
          closeHandler={() => {
            setShowPostFailedModal(false);
            refreshHandler();
          }}
        />
      )}
      {showRepostModal && (
        <RepostModal
          opened={showRepostModal}
          thumbnail={thumbnail || ""}
          selectedPost={selectedPost}
          closeHandler={() => {
            setShowRepostModal(false);
            setShowPostFailedModal(false);
            refreshHandler();
          }}
        />
      )}
    </>
  );
};

export const NeedsAttentionCard = withRouter(NeedsAttentionCardFC);
