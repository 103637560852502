import { initializeApp } from "firebase/app";
import "firebase/compat/firestore";
import {
  getStorage,
  FirebaseStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { FirebaseConfig } from "../interfaces/customTypes";

let firebaseStorage: undefined | FirebaseStorage;
export const getFirebaseStorage = (config: FirebaseConfig): FirebaseStorage => {
  if (!firebaseStorage) {
    const firebaseConfig = {
      apiKey: config.api_key,
      authDomain: config.auth_domain,
      projectId: config.project_id,
      storageBucket: config.storage_bucket,
    };
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    firebaseStorage = getStorage(app);
  }
  return firebaseStorage;
};

export const saveImageToFirebase = async (
  firebaseConfig: FirebaseConfig,
  userId: string | number,
  img: Blob,
  filename: string
) => {
  const [type, format] = img.type.split("/");

  if (!type || type !== "image") {
    throw Error("Invalid file type, expected image");
  }

  const path = `${firebaseConfig.baseImagesFolder}/users/${userId}/profiles/${filename}.${format}`;

  const firebaseStorageInstance = getFirebaseStorage(firebaseConfig);
  const storageRef = ref(firebaseStorageInstance, path);

  await uploadBytes(storageRef, img);
  
  const downloadURL = await getDownloadURL(ref(firebaseStorageInstance, path));
  
  return downloadURL;
};

export const saveElementToFirebase = async (
  firebaseConfig: FirebaseConfig,
  node: string,
  file: Blob,
  filename: string
) => {
  const [type, format] = file.type.split("/");

  if (!type) {
    throw Error("Invalid file type");
  }

  const path = `${firebaseConfig.baseImagesFolder}/${node}/${filename}.${format}`;

  const firebaseStorageInstance = getFirebaseStorage(firebaseConfig);
  const storageRef = ref(firebaseStorageInstance, path);

  await uploadBytes(storageRef, file);
  
  const downloadURL = await getDownloadURL(ref(firebaseStorageInstance, path));
  
  return downloadURL;
};

export const saveElementToFirebaseCancellable = (
  firebaseConfig: FirebaseConfig,
  node: string,
  file: Blob,
  filename: string
) => {
  const [type, format] = file.type.split("/");

  if (!type) {
    throw Error("Invalid file type");
  }
  const path = `${firebaseConfig.baseImagesFolder}/${node}/${filename}.${format}`;

  const firebaseStorageInstance = getFirebaseStorage(firebaseConfig);
  const storageRef = ref(firebaseStorageInstance, path);

  // old version: const uploadTask = firebaseStorageInstance.ref(path).put(file);
  const uploadTask = uploadBytesResumable(storageRef, file);

  return uploadTask;
};

export const getDownloadURLFirebase = async (
  firebaseConfig: FirebaseConfig,
  node: string,
  file: Blob,
  filename: string
): Promise<string> => {
  const [type, format] = file.type.split("/");

  if (!type) {
    throw Error("Invalid file type");
  }
  const path = `${firebaseConfig.baseImagesFolder}/${node}/${filename}.${format}`;
  const firebaseStorageInstance = getFirebaseStorage(firebaseConfig);
  const downloadURL = await getDownloadURL(ref(firebaseStorageInstance, path));

  return downloadURL;
};
