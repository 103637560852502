import * as React from "react";
import { Modal, Button } from "semantic-ui-react";

import { modalReducer } from "./modalReducer";

interface Props {
  /**
   * Modal Title
   */
  title?: string;
  /**
   * Modal content
   */
  children?: any;
  /**
   * Accept custom label
   */
  acceptLabel?: any;

  /**
   * Accept custom label
   */
  rejectLabel?: any;

  displayReject?: boolean;
  open?: boolean;
  acceptHandler: (customValue?: any) => void;
  rejectHandler?: () => void;
  size?: "mini" | "tiny" | "small" | "large" | "fullscreen";
}

const CustomModalFC: React.FC<Props> = ({
  open,
  title,
  children,
  acceptLabel = "Yes",
  rejectLabel = "No",
  displayReject = false,
  acceptHandler,
  rejectHandler,
  size = "small",
}) => {
  const [state, dispatch] = React.useReducer(modalReducer, { open: true });

  const close = () => {
    dispatch({ type: "close" });
    if (rejectHandler) {
      rejectHandler();
    }
  };

  return (
    <Modal size={size || "small"} open={state.open} onClose={close}>
      <Modal.Header>{title}</Modal.Header>

      <Modal.Content>{children}</Modal.Content>

      <Modal.Actions>
        {displayReject && (
          <Button negative data-elm-id={`customModalRejectBtn`} onClick={close}>
            {rejectLabel}
          </Button>
        )}
        <Button
          primary
          data-elm-id={`customModalAcceptBtn`}
          onClick={() => {
            dispatch({ type: "close" });
            acceptHandler();
          }}
        >
          {acceptLabel}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const CustomModal = CustomModalFC;
