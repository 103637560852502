import * as React from "react";
import { Grid } from "semantic-ui-react";

import { Media, PlayerAccount } from "../../../../../interfaces";
import { MediaPlayer, PlayerInfoSection } from "../../../../../components";

import "./styles.scss";

interface OwnProps {
  videos: Media[];
  playerInfo: PlayerAccount;
}

type Props = OwnProps;

export const PostVideoSection: React.FC<Props> = ({ videos, playerInfo }) => {
  return (
    <div className={"attachVideosSection"}>
      {/* PLAYER INFO */}
      <PlayerInfoSection playerInfo={playerInfo} />
      <Grid>
        {videos &&
          videos.map((media, index) => {
            return (
              <Grid.Column key={`media_detail_container_${index}`} className={"videosContainer edited"}>
                <MediaPlayer media={media} />
              </Grid.Column>
            );
          })}
      </Grid>
    </div>
  );
};
