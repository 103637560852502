import { defineMessages } from "react-intl";

export enum CoachesType {
  nameHeader = "CoachesType.nameHeader",
  emailHeader = "CoachesType.emailHeader",
  lastActivityHeader = "CoachesType.lastActivityHeader",

  tagsHeader = "CoachesType.tagsHeader",
  branchHeader = "CoachesType.branchHeader",
  regionHeader = "CoachesType.regionHeader",
  activeFrom = "CoachesType.activeFrom",
}

export const en = {
  [CoachesType.nameHeader]: "Name",
  [CoachesType.emailHeader]: "Email ",
  [CoachesType.lastActivityHeader]: "Last Activity",

  [CoachesType.tagsHeader]: "Tags",
  [CoachesType.branchHeader]: "Branch ",
  [CoachesType.regionHeader]: "Region",
  [CoachesType.activeFrom]: "Active from",
};

export const descriptors = defineMessages({
  [CoachesType.nameHeader]: {
    id: CoachesType.nameHeader,
    defaultMessage: en[CoachesType.nameHeader],
    description: "-",
  },
  [CoachesType.emailHeader]: {
    id: CoachesType.emailHeader,
    defaultMessage: en[CoachesType.emailHeader],
    description: "-",
  },
  [CoachesType.branchHeader]: {
    id: CoachesType.branchHeader,
    defaultMessage: en[CoachesType.branchHeader],
    description: "-",
  },
  [CoachesType.regionHeader]: {
    id: CoachesType.regionHeader,
    defaultMessage: en[CoachesType.regionHeader],
    description: "-",
  },
  [CoachesType.tagsHeader]: {
    id: CoachesType.tagsHeader,
    defaultMessage: en[CoachesType.tagsHeader],
    description: "-",
  },
  [CoachesType.lastActivityHeader]: {
    id: CoachesType.lastActivityHeader,
    defaultMessage: en[CoachesType.lastActivityHeader],
    description: "-",
  },
  [CoachesType.activeFrom]: {
    id: CoachesType.activeFrom,
    defaultMessage: en[CoachesType.activeFrom],
    description: "-",
  },
});
