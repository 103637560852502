import * as React from "react";
import{ useIntl } from "react-intl";
import { Message } from "semantic-ui-react";
import { Svg } from "../../../../../assets";
import * as DTO from "../../../../../interfaces";
import { validateLong } from "../../../../../utils";
import { CategoriesServices } from "../../../../../services";
import { EmptyView } from "../../../../EmptyView";

import { CategorySection } from "../CategorySection";

interface OwnProps {
  promptList: DTO.Prompt[];
  categories: DTO.Category[];
  coachId: string;

  isEmptySection: (isEmpty: boolean) => void;
}

type Props = OwnProps 

export default ({ promptList, categories, coachId, isEmptySection }: Props) => {
  const [categoriesHash, setCategoriesHash] = React.useState<{ [key: string]: string }>({});
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [withCategorizedLibraryPrompts, setWithCategorizedLibraryPrompts] = React.useState<boolean>(false);

  React.useEffect(() => {
    const convertToHash = (result: DTO.Category[]): { [key: string]: string } => {
      let hash = {};
      result.map(category => {
        hash = {
          ...hash,
          [category.categoryId!]: category.name,
        };
      });

      return hash;
    };

    setCategoriesHash(convertToHash(categories));
  }, [categories]);

  const uncategorizedPrompts = (): DTO.Prompt[] => {
    return promptList.filter(prompt => {
      return prompt.categories?.length === 0 && prompt.status !== "DRAFT" && prompt.deliveryMode === "LIBRARY";
    });
  };

  const unpublishedPrompts = (): DTO.Prompt[] => {
    return promptList.filter(prompt => {
      return prompt.status === "DRAFT" && prompt.deliveryMode === "LIBRARY";
    });
  };

  const welcomePrompts = (): DTO.Prompt[] => {
    return promptList.filter(prompt => {
      return prompt.deliveryMode === "WELCOME_PROMPT";
    });
  };

  const welcome = welcomePrompts();
  const unpublished = unpublishedPrompts();
  const uncategorized = uncategorizedPrompts();

  React.useEffect(() => {
    const emptySection: boolean =
      !withCategorizedLibraryPrompts && unpublished.length === 0 && uncategorized.length === 0 && welcome.length === 0;
    isEmptySection(emptySection);
  }, [withCategorizedLibraryPrompts]);

  const promptFilteredByCategory = (categoryId: string): DTO.Prompt[] => {
    const promptsFiltered = promptList.filter(prompt => {
      return (
        prompt.categories?.map(c => c.categoryId!).includes(categoryId) &&
        prompt.status !== "DRAFT" &&
        prompt.deliveryMode === "LIBRARY"
      );
    });

    if (!withCategorizedLibraryPrompts && promptsFiltered.length > 0) {
      setWithCategorizedLibraryPrompts(true);
    }
    return promptsFiltered;
  };

  const showPromps = (prompts: DTO.Prompt[], category: DTO.Category): JSX.Element | undefined => {
    if (prompts.length === 0) {
      return undefined;
    }
    return (
      <CategorySection
        key={"prompt_list_cat_section_" + category.categoryId}
        category={category}
        promptList={promptFilteredByCategory(category.categoryId!)}
        name={categoriesHash[category.categoryId!]}
        handleCategoryChange={newValue => {
          const newCatName = newValue ? newValue.trim() : newValue;

          if (newValue && validateLong(2, 25, newValue)) {
            CategoriesServices.update(coachId, { ...category, name: newCatName })
              .then(() => {
                setError(undefined);
                setCategoriesHash(hash => {
                  return {
                    ...hash,
                    [category.categoryId!]: newValue,
                  };
                });
              })
              .catch(e => {
                if (e.status === 409) {
                  alert("You already have a category named: " + newCatName);
                } else {
                  alert(e.message);
                }
              });
          } else {
            alert("Category name should have 2-25 characters.");
          }
        }}
      />
    );
  };

  const showEmptySection = !withCategorizedLibraryPrompts && unpublished.length === 0 && uncategorized.length === 0;
  return (
    <div data-elm-id={"promptListLibrarySection"} className="promptListContainer socialContent">
      {error && <Message error content={error} />}
      {unpublished.length > 0 && <CategorySection name={"Unpublished"} promptList={unpublished} disable={true} />}
      {uncategorized.length > 0 && <CategorySection name={"Uncategorized"} promptList={uncategorized} disable={true} />}
      {welcome.length > 0 && (
        <CategorySection name={"Welcome Prompts"} deliveryMode={"WELCOME_PROMPT"} promptList={welcome} disable={true} />
      )}

      {categories.map(category => {
        return showPromps(promptFilteredByCategory(category.categoryId!), category);
      })}

      {showEmptySection && <EmptyView text={"You have no Library Prompts"} customIconImage={Svg.Library} />}
    </div>
  );
}
