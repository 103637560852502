import * as Yup from "yup";
import { Rules } from "../../../../../utils/rules";

export const validationSchema = Yup.object().shape(
  {
    playerId: Yup.string().nullable().required("Player is required"),
    withMessage: Yup.string().trim().max(Rules.MAX_CONTENT, "The caption is too long"),
    withMediaReady: Yup.number().required("Video for Video Project is required"),
    isAutoPost: Yup.boolean(),
    socialNetworksCounter: Yup.number().when("isAutoPost", {
      is: (a: any) => a === true,
      then: (schema: any) =>
        schema.min(1, "If auto-posting, you must select at least one social network").label("Social Networks"),
    }),
  },
  []
);
