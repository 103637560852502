import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Grid, Button, Icon } from "semantic-ui-react";
import { get } from "lodash";

import { AppContext } from "../../../../../providers";
import * as DTO from "../../../../../interfaces";
import { coachWithWritePermission, URLS } from "../../../../../utils";
import { PromptCard } from "../../../../Cells";
import { EditableField } from "../../../..";

import "./styles.scss";
import { WebComponents } from "../../../../../interfaces";

interface OwnProps {
  promptList: DTO.Prompt[];
  name: string;
  category?: DTO.Category;
  disable?: boolean;
  deliveryMode?: DTO.DeliveryMode;
  handleCategoryChange?: (categoryName: string) => void;
}

type Props = OwnProps & RouteComponentProps;

const CategorySectionFC: React.FC<Props> = ({
  category,
  promptList,
  name,
  disable = false,
  history,
  deliveryMode = "LIBRARY",
  handleCategoryChange,
}) => {
  const [display, setDisplay] = React.useState<boolean>(true);
  const promptsToShow = promptList;

  const { userContext } = React.useContext(AppContext);
  const withWritePermission = coachWithWritePermission(WebComponents.LIBRARY, userContext);

  const actionTitle = (): string => {
    if (!display) {
      return "Expand (" + promptList.length + ")";
    }
    return "Collapse (" + promptList.length + ")";
  };

  const categoryName = get(category, "categoryId", name);
  return (
    <Grid
      columns={3}
      data-elm-id={"library-list-element-" + categoryName}
      key={"library-list-element-" + categoryName}
      className={display ? "libraryListCategoryGrid" : "libraryListCategoryGrid collapsed"}>
      <Grid.Row columns={3} className={display ? "libraryListCategoryGrid" : "libraryListCategoryGrid collapsed"}>
        <EditableField
          headerType={"h2"}
          dataElmId={"libraryCategoryId" + categoryName}
          currentValue={name}
          handleChangeValue={value => handleCategoryChange && handleCategoryChange(value)}
          disable={disable || !withWritePermission}
        />

        <Grid.Column width={3} floated="right" className={"noBorder"}>
          <Button
            basic
            data-elm-id={`librarySectionDisplayBtn`}
            content={actionTitle()}
            icon={!display ? "triangle right" : "triangle down"}
            className={"noBorder floatRight"}
            labelPosition={"right"}
            onClick={() => setDisplay(!display)}
          />
        </Grid.Column>
      </Grid.Row>

      {withWritePermission && (
        <Button
          data-elm-id={`librarySectionAddNewBtn`}
          className={"addButton"}
          onClick={() => {
            history.push(URLS.coach.newPrompt, {
              deliveryMode,
              category,
            });
          }}>
          <Icon circular name="add" /> Add new
        </Button>
      )}

      <div key={name} className={display ? "ui container" : "ui container cateroryCollapsed"}>
        {promptsToShow.map(prompt => {
          return <PromptCard key={"library_prompt_card_" + prompt.promptId} prompt={prompt} />;
        })}
      </div>
    </Grid>
  );
};

export const CategorySection = withRouter(CategorySectionFC);
