import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Grid, Button, Icon } from "semantic-ui-react";
import { get } from "lodash";

import * as DTO from "../../interfaces";
import { CampaignCard, EditableField } from "../../components";
import { AppContext } from "../../providers";

import { coachWithWritePermission, URLS } from "../../utils";
import "./styles.scss";
import { WebComponents } from "../../interfaces";

interface OwnProps {
  name: string;
  campaignList: DTO.CampaignResume[];
  category?: DTO.Category;

  page?: number;
  pageable?: boolean;
  latestPage?: boolean;
  firstPage?: boolean;

  totalElements?: number;
  disable?: boolean;

  handleCategoryChange?: (categoryName: string) => void;
  onNext?: (nextPage: number) => void;
}

type Props = OwnProps & RouteComponentProps;

const CategorySectionFC: React.FC<Props> = ({
  category,
  campaignList,
  name,
  page = 0,
  disable = false,
  pageable = false,
  firstPage = true,
  latestPage = false,
  totalElements = 0,
  history,
  onNext,
  handleCategoryChange,
}) => {
  const [display, setDisplay] = React.useState<boolean>(true);
  const { userContext } = React.useContext(AppContext);
  const withWritePermission = coachWithWritePermission(WebComponents.CAMPAIGNS, userContext);

  const actionTitle = (): string => {
    if (!display) {
      return "Collapsed (" + totalElements + ")";
    }
    return pageable
      ? `Showing ${page * 5} - ${page * 5 + campaignList.length} of ${totalElements}`
      : `Show all (${totalElements})`;
  };

  const onNextHandler = () => {
    if (onNext) {
      onNext(page + 1);
    }
  };
  const onPreviousHandler = () => {
    if (onNext) {
      onNext(page - 1);
    }
  };

  return (
    <Grid columns={3} data-elm-id="campaignListElement" className={"campaignsCategoryGrid"}>
      <Grid.Row columns={3} className={display ? "" : "collapsedSection"}>
        <EditableField
          headerType={"h2"}
          dataElmId={"campaignCategoryId" + get(category, "categoryId", name)}
          currentValue={name}
          maxLength={25}
          handleChangeValue={value => {
            if (handleCategoryChange) {
              handleCategoryChange(value);
            }
          }}
          disable={disable || !withWritePermission}
        />
        <Grid.Column width={4} floated="right" className={"noBorder"}>
          <Button
            data-elm-id={`categorySectionDisplayBtn`}
            basic
            content={actionTitle()}
            icon={!display ? "triangle right" : "triangle down"}
            className={"noBorder"}
            labelPosition={"right"}
            onClick={() => setDisplay(!display)}
          />
        </Grid.Column>
      </Grid.Row>
      {pageable && display && !firstPage && (
        <Button
          data-elm-id={`categorySectionOnPrevPageBtn`}
          icon={"chevron left"}
          className={"leftArrowStyle"}
          onClick={onPreviousHandler}
        />
      )}
      {display && withWritePermission && (
        <Button
          className={"addButton"}
          data-elm-id={`categorySectionAddBtn`}
          onClick={() => {
            let locationValues = {};
            const noCategories = ["unpublished", "uncategorized"];
            if (!noCategories.includes(category?.categoryId!)) {
              locationValues = {
                category,
              };
            }

            localStorage.removeItem("coachNewCampaignStep");
            localStorage.removeItem("coachCampaign");
            history.push(URLS.coach.newCampaigns, locationValues);
          }}>
          <Icon circular name="add" /> Add new
        </Button>
      )}

      {display && (
        <div key={name} className={!latestPage && !firstPage ? "ui container middlePage" : "ui container"}>
          {campaignList.map(campaign => {
            return <CampaignCard key={"campaign_card_" + campaign.campaignId} campaign={campaign} />;
          })}
        </div>
      )}
      {pageable && display && !latestPage && (
        <Button
          data-elm-id={`categorySectionOnNextPageBtn`}
          icon={"chevron right"}
          className={"arrowStyle"}
          onClick={onNextHandler}
        />
      )}
    </Grid>
  );
};

export const CategorySection = withRouter(React.memo(CategorySectionFC));
