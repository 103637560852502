import * as React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { Form } from "semantic-ui-react";
import qs from "qs";

import { LoginType, descriptors } from "./descriptors";

import { URLS } from "../../../utils";
import { BasicFormProps } from "../../../interfaces";
import { FloatedCard } from "../../../containers";
import { ButtonLink } from "../..";

interface LoginForm extends BasicFormProps {
  playerMode: boolean;
}
type Props = LoginForm & RouteComponentProps;

/**
 * Represents a login screen.
 */

const LoginClass: React.FC<Props> = ({
  history,
  location,
  handleChange,
  handleSubmit,
  messages,
  isSubmitting,
  playerMode,
  values,
}) => {

  const { formatMessage } = useIntl()
  const toSignUp = (): void => {
    history.push(URLS.register);
  };

  const toForgot = (): void => {
    history.push(URLS.forgotPassword);
  };

  const [sessionExpired, setSessionExpired] = React.useState<boolean>(false);

  React.useEffect(() => {
    const queryParams = qs.parse(location.search.slice(1));
    if (queryParams["expired"]) {
      setSessionExpired(true);
    }
  }, [location.search]);

  const content = (
    <React.Fragment>
      <Form.Input
        fluid
        data-elm-id={"loginInputEmail"}
        type="email"
        name="username"
        icon="mail outline"
        iconPosition="left"
        value={values.username}
        placeholder={formatMessage({ ...descriptors[LoginType.email] })}
        onChange={handleChange}
        disabled={isSubmitting}
      />

      <Form.Input
        fluid
        data-elm-id={"loginInputPassword"}
        name="password"
        icon="lock"
        iconPosition="left"
        placeholder={formatMessage({ ...descriptors[LoginType.password] })}
        type="password"
        onChange={handleChange}
        disabled={isSubmitting}
      />
      <ButtonLink
        data-elm-id={"loginButtonForgot"}
        type={"underline"}
        message={descriptors[LoginType.forgotPassword]}
        onClick={toForgot}
      />
      <Form.Button
        data-elm-id={"loginButtonSubmit"}
        size="large"
        type="submit"
        disabled={isSubmitting}
        onClick={handleSubmit}
        className={"centered"}
      >
        <FormattedMessage
          {...descriptors[
            playerMode ? LoginType.submitPlayers : LoginType.submit
          ]}
        />
      </Form.Button>
    </React.Fragment>
  );
  return (
    <FloatedCard
      showLogo={!playerMode}
      title={formatMessage({
        ...descriptors[playerMode ? LoginType.titlePlayers : LoginType.title],
      })}
      subtitle={
        !playerMode
          ? formatMessage({
              ...descriptors[
                sessionExpired
                  ? LoginType.sessionExpired
                  : LoginType.subtitle
              ],
            })
          : ""
      }
      content={content}
      messages={messages}
      isSubmitting={isSubmitting}
      size={"bigCentered"}
    />
  );
};

export const Login = withRouter(LoginClass);
