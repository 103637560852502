// Do not rename this file

import * as React from "react";
// import { hydrate } from "react-dom";
import { hydrateRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HeadProvider } from "react-head";

import App from "./App";
import { getReducedConfig } from "./utils";

const config = window["__PRELOADED_STATE__"];

delete window["__PRELOADED_STATE__"];

const clientConfig = getReducedConfig(config);

const container = document.getElementById("root")!!;

// hydrate(
//   <BrowserRouter>
//     <HeadProvider>
//       <App config={clientConfig} />
//     </HeadProvider>
//   </BrowserRouter>,
//   document.getElementById("root")
// );

hydrateRoot(
  container,
  <BrowserRouter>
    <HeadProvider>
      <App config={clientConfig} />
    </HeadProvider>
  </BrowserRouter>
);

// Do not remove this: This allows HMR during development
if ((module as any).hot) {
  (module as any).hot.accept();
}
