import * as React from "react";
import { Header, Grid, Image } from "semantic-ui-react";
import { Png } from "../../assets";

import "./styles.scss";

interface Props {
  title?: string;
  leftMessage: string;
  customUrl?: string;
}

class SocialHeaderClass extends React.Component<Props> {
  static defaultProps = {
    leftMessage: "SocialCoach",
  };

  render() {
    const { title, leftMessage, customUrl} = this.props;
    return (
      <div className={"navBar"}>
        <Grid>
          <Grid.Column computer={4} largescreen={4} tablet={4} mobile={6}>
            <Image src={customUrl ? customUrl : Png.LogoWhite} />
          </Grid.Column>
          {title && (
            <Grid.Column width={8}>
              <Header as="h2" className={"title centered"}>
                {title}
              </Header>
            </Grid.Column>
          )}
        </Grid>
      </div>
    );
  }
}

export const SocialHeader = SocialHeaderClass;
