import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useIntl } from "react-intl";
import {
  Header,
  Grid,
  Button,
  Segment,
  List,
  Loader,
  Message,
  Input,
  Transition,
  DropdownItemProps,
} from "semantic-ui-react";
import { isNil } from "lodash";

import {
  StatementOption,
  SideBar,
  FeesResume,
  TitleSection,
  PriceSection,
} from "../../../components";

import { URLS } from "../../../utils/routes";
import { AppContext } from "../../../providers";
import { CoachSignUpStep, CoachService } from "../../../services";
import {
  Plan,
  Fee,
  WebComponents,
  BillingPeriod,
  PriceElement,
} from "../../../interfaces/dtos";
import {
  validIncome,
  finishStep,
  emptyField,
  coachWithWritePermission,
} from "../../../utils";

import { WhiteContainer } from "../../../styling/baseStyle";

import { PricePlanType, descriptors } from "./descriptors";
import { defaultStatement } from "./defaultStatements";
import { PricePlanDropDownComponent } from "./components/PricePlanDropDown";
import "./styles.scss";

type Props = RouteComponentProps; 
const PricePlanFC: React.FC<Props> = ({ history }) => {
  const { formatMessage } = useIntl();
  const { userContext, setUserContext } = React.useContext(AppContext);
  const withWritePermission = coachWithWritePermission(
    WebComponents.PRICE_PLAN,
    userContext
  );

  const [coachPlans, setCoachPlans] = React.useState<Plan[]>();
  const [selectedPlan, setSelectedPlan] = React.useState<Plan>();
  const [selectedPlanIndex, setSelectedPlanIndex] = React.useState<number>(0);
  const [planName, setPlanName] = React.useState<string>(
    formatMessage({ ...descriptors[PricePlanType.defaultPlanName] })
  );
  const [planPrice, setPrice] = React.useState<number>(150);
  const [fees, setFees] = React.useState<Fee[]>([]);
  const [hasFreeTrial, setHasFreeTrial] = React.useState<boolean>(false);
  const [baseActive, setBaseActive] = React.useState<boolean>(true);

  const [planYearlyPrice, setYearlyPrice] = React.useState<number>(1800);
  const [yearlyHasFreeTrial, setYearlyHasFreeTrial] =
    React.useState<boolean>(false);
  const [yearlyActive, setYearlyActive] = React.useState<boolean>(false);
  const [intervalPrices, setIntervalPrices] = React.useState<
    PriceElement | undefined
  >(undefined);

  const [planStatements, setPlanStatements] =
    React.useState<string[]>(defaultStatement);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [saved, setSaved] = React.useState<boolean>(false);

  // Multi price plan manage
  const [pricePlansOptions, setPricePlansOptions] = React.useState<
    DropdownItemProps[]
  >([]);

  React.useEffect(() => {
    if (intervalPrices) {
      setYearlyPrice(intervalPrices.price || 0);
      setYearlyHasFreeTrial(intervalPrices.hasFreeTrial);
      setYearlyActive(intervalPrices.active!!);
    }
  }, [intervalPrices]);

  React.useEffect(() => {
    setLoading(true);
    CoachService.getPlans(userContext?.coach ? userContext!.coach!.id : "")
      .then((response) => {
        if (response.length > 0) {
          setCoachPlans(response);
          const planResponse = response[0];
          setSelectedPlan(planResponse);

          setLoading(false);
        } else {
          CoachService.getBaseFee()
            .then((baseFees) => {
              setLoading(false);
              setFees(baseFees);
            })
            .catch((e) => {
              setLoading(false);
            });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (coachPlans) {
      const filters: DropdownItemProps[] = coachPlans?.map((p, index) => {
        const planType = p
          .fees!!.filter(
            (fee) =>
              fee.code === "SOCIAL_COACH" || fee.code === "SOCIAL_COACH_PLUS"
          )
          .map((fee) => fee.code)[0];
        return {
          key: p.id,
          text:
            planType === "SOCIAL_COACH" ? "SocialCoach Basic" : "SocialCoach+",
          value: index,
        };
      });

      setPricePlansOptions(filters);
    }
  }, [coachPlans]);

  React.useEffect(() => {
    if (selectedPlan) {
      setFees(selectedPlan.fees!);
      setPrice(selectedPlan.price || 0);
      setPlanName(selectedPlan.planName);
      setPlanStatements(selectedPlan.planStatements || []);
      setHasFreeTrial(selectedPlan.hasFreeTrial);
      setBaseActive(!!selectedPlan.baseActive);

      if (selectedPlan.intervalPrices) {
        setIntervalPrices(selectedPlan.intervalPrices[0]);
      }
    }
  }, [selectedPlan]);

  const savePlan = () => {
    if (!yearlyActive && !baseActive) {
      alert("You need to have at least one active price.");
      return;
    }
    let toSave: Plan = {
      planName,
      fees,
      price: planPrice,
      planStatements,
      hasFreeTrial,
      baseActive,
    };

    if (intervalPrices || planYearlyPrice) {
      const yearlyPrice: PriceElement = {
        price: planYearlyPrice,
        hasFreeTrial: yearlyHasFreeTrial,
        active: yearlyActive,
        interval: BillingPeriod.YEARLY,
      };

      toSave = {
        ...toSave,
        intervalPrices: [yearlyPrice],
      };
    }

    setLoading(true);
    let service;
    if (!isNil(selectedPlan?.id)) {
      toSave.id = selectedPlan?.id;
      service = CoachService.updatePlan(toSave, userContext!.coach!.id);
    } else {
      service = CoachService.createPlan(toSave, userContext!.coach!.id);
    }

    service
      .then(() => {
        setLoading(false);

        if (
          CoachSignUpStep[userContext!.signUpStep!] <
          CoachSignUpStep["COACH_PRICE_PLAN"]
        ) {
          setUserContext({
            ...userContext!,
            signUpStep: CoachSignUpStep[CoachSignUpStep.COACH_PRICE_PLAN],
          });
        }

        if (!finishStep(userContext?.signUpStep)) {
          history.push(URLS.coach.marketingSite);
        } else {
          setSaved(true);
          setTimeout(() => {
            setSaved(false);
          }, 1000);
        }
      })
      .catch((e) => {
        setLoading(false);
        const error = e.response;

        if (
          error.status === 412 &&
          error.data &&
          error.data.message.includes("players subscribed")
        ) {
          setErrors(() => {
            return [
              formatMessage({
                ...descriptors[PricePlanType.playersSubcribedError],
              }),
            ];
          });
        } else {
          setErrors(() => {
            return [error.data.message];
          });
        }
      });
  };

  const removeOffer = (index: number) => {
    if (planStatements.length > 3) {
      if (index > -1) {
        setPlanStatements((statements) => {
          statements.splice(index, 1);
          return [...statements];
        });
      }
    }
  };

  const addOffer = () => {
    if (planStatements.length < 10) {
      setPlanStatements((statements) => statements.concat(""));
    }
  };

  const updateOffer = (index: number, value: string) => {
    setPlanStatements((statements) => {
      statements[index] = value;
      return [...statements];
    });
  };

  const updateTitle = (title: string) => {
    if (title.length < 80) {
      setPlanName(title);
    }
  };

  const handleNextBtn = () => {
    if (isValid()) {
      savePlan();
    }
  };

  const isValid = (): boolean => {
    const scFee = fees.filter(
      (fee) => fee.code === "SOCIAL_COACH" || fee.code === "SOCIAL_COACH_PLUS"
    )[0];
    const tdcFee = fees.filter((fee) => fee.code === "BANK")[0];
    const newErrors: string[] = [];
    setErrors([]);

    if (!validIncome(planPrice, scFee, tdcFee)) {
      newErrors.push(
        formatMessage({ ...descriptors[PricePlanType.incomeError] })
      );
    } else if (!validIncome(planYearlyPrice, scFee, tdcFee)) {
      newErrors.push(
        formatMessage({ ...descriptors[PricePlanType.incomeError] })
      );
    }

    let ready = true;
    planStatements.forEach((e) => {
      ready = !emptyField(e) && ready;
      return ready;
    });
    if (!ready) {
      newErrors.push(
        formatMessage({ ...descriptors[PricePlanType.emptyOffersError] })
      );
    }

    if (emptyField(planName)) {
      newErrors.push(
        formatMessage({ ...descriptors[PricePlanType.titleError] })
      );
    }

    setErrors(() => {
      return newErrors;
    });

    return newErrors.length === 0;
  };

  const titleInput = (): JSX.Element => {
    return (
      <Grid>
        <Grid.Column width={16}>
          <Input
            fluid
            name={"pricePlanTitle"}
            data-elm-id={"pricePlanTitle"}
            value={planName}
            id={"pricePlanTitle"}
            className={"inputSection h2"}
            onChange={(e) => {
              updateTitle(e.target.value);
            }}
          />
        </Grid.Column>
      </Grid>
    );
  };

  const pricePlanContent = (): JSX.Element => {
    return (
      <AppContext.Consumer>
        {({}) => {
          return (
            <div className="coachBaseContainer">
              <TitleSection
                title={formatMessage({ ...descriptors[PricePlanType.title] })}
                subtitle={formatMessage({
                  ...descriptors[PricePlanType.subtitle],
                })}
                ready={finishStep(userContext?.signUpStep)}
                showNextOption={withWritePermission}
                currentStep={CoachSignUpStep.COACH_PRICE_PLAN}
                nextButtonSize={10}
                handleNextAction={handleNextBtn}
              />
              {errors && errors.length > 0 && (
                <Message error header={""} list={errors} />
              )}

              <Grid>
                <Grid.Column width={6} floated="left">
                  {coachPlans && coachPlans.length > 1 && (
                    <PricePlanDropDownComponent
                      options={pricePlansOptions}
                      placeholder="Select a price Plan"
                      value={selectedPlanIndex}
                      onChangeHandler={(data) => {
                        if (coachPlans) {
                          setSelectedPlan(coachPlans[data]);
                          setSelectedPlanIndex(Number(data));
                        }
                      }}
                    />
                  )}
                </Grid.Column>
              </Grid>
              {saved && (
                <Transition.Group
                  as={Header}
                  duration={300}
                  animation={"drop"}
                  size="tiny"
                  verticalAlign="middle"
                >
                  <Message info content={"Saved!"} />
                </Transition.Group>
              )}
              <Grid
                columns={2}
                className={
                  !withWritePermission
                    ? "disabledForm socialSectionContent"
                    : "socialSectionContent"
                }
              >
                <Grid.Column
                  largescreen={10}
                  computer={10}
                  mobile={15}
                  floated="left"
                >
                  <Grid.Row className={"priceCard"}>
                    <Segment
                      padded={"very"}
                      centered={"true"}
                      className={"priceSegment"}
                    >
                      <Grid.Row columns={1}>
                        {titleInput()}
                        <Grid.Column className="paddingTop leftPadding">
                          <Header as="h6">
                            {formatMessage({
                              ...descriptors[PricePlanType.description],
                            })}
                          </Header>
                        </Grid.Column>
                        <List relaxed className={"menuBox"}>
                          {planStatements.map((statement, index) => {
                            return (
                              <StatementOption
                                key={index + statement}
                                statatement={statement}
                                index={index}
                                headerType="h6"
                                dataElmId={"pricePlanStatementInput" + index}
                                handleChangeValue={updateOffer}
                                handleDeleteValue={removeOffer}
                                maxLength={80}
                              />
                            );
                          })}
                        </List>

                        <Grid floated="left" padded columns={8}>
                          <Grid.Column width={14} className={"noPadding"}>
                            <Button
                              data-elm-id={`pricePlanAddOfferBtn`}
                              fluid
                              className={"simple"}
                              onClick={addOffer}
                            >
                              {" "}
                              {formatMessage({
                                ...descriptors[PricePlanType.addBtn],
                              })}
                            </Button>
                          </Grid.Column>
                        </Grid>

                        <PriceSection
                          price={planPrice}
                          interval={BillingPeriod.MONTHLY}
                          hasFreeTrial={hasFreeTrial}
                          active={baseActive}
                          handlePriceChange={setPrice}
                          handleFreeTrialChange={setHasFreeTrial}
                          handleActiveChange={setBaseActive}
                        />

                        <PriceSection
                          price={planYearlyPrice}
                          interval={BillingPeriod.YEARLY}
                          active={yearlyActive}
                          hasFreeTrial={yearlyHasFreeTrial}
                          handlePriceChange={setYearlyPrice}
                          handleFreeTrialChange={setYearlyHasFreeTrial}
                          handleActiveChange={setYearlyActive}
                        />
                      </Grid.Row>
                    </Segment>
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column
                  className={"feeResumeContainer"}
                  largescreen={5}
                  computer={5}
                  mobile={15}
                  floated="left"
                >
                  <FeesResume
                    coachIncome={planPrice}
                    feeInformation={fees}
                    interval={BillingPeriod.MONTHLY}
                  />
                  <br />
                  <br />
                  <FeesResume
                    coachIncome={planYearlyPrice}
                    feeInformation={fees}
                    interval={BillingPeriod.YEARLY}
                  />
                </Grid.Column>
              </Grid>
            </div>
          );
        }}
      </AppContext.Consumer>
    );
  };

  return (
    <WhiteContainer>
      <SideBar history={history} />

      <Grid className={"coachStepContent"} columns={1}>
        {loading ? <Loader active size="large" /> : pricePlanContent()}
      </Grid>
    </WhiteContainer>
  );
};

export const PricePlan = withRouter(PricePlanFC);
