import * as React from "react";
import axios from "axios";
import { Grid, Icon } from "semantic-ui-react";

import { Media } from "../../interfaces";
import { MediaPlayer } from "../MediaPlayer";

import "./styles.scss";

interface OwnProps {
  downloadable?: boolean;
  videos: Media[];
}

type Props = OwnProps;

export const VideoPreviewActionable: React.FC<Props> = ({ videos, downloadable = false }) => {
  const downloadFile = (filePath: string, fileName: string) => {
    axios.get(filePath, { responseType: "blob" }).then(response => {
      const urlObject = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = urlObject;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <div className={"attachVideosSection"}>
      <Grid>
        {videos &&
          videos.map((media, index) => {
            return (
              <Grid.Column key={`media_actionable_container_${index}`} className={"actionableVideosContainer"}>
                <MediaPlayer media={media} />
                {downloadable && (
                  <div
                    className="downloadIcon"
                    onClick={() => downloadFile(media.uri!!, `${index}_${media.category}_${index}.${media.extension}`)}
                  >
                    <Icon size={"large"} name="arrow alternate circle down outline" />
                  </div>
                )}
              </Grid.Column>
            );
          })}
      </Grid>
    </div>
  );
};
