import * as React from "react";
import { Header, Grid, Image } from "semantic-ui-react";

import "./styles.scss";
import { BrandHeaderImage } from "../../styling/baseStyle";

interface Props {
  title?: string;
  customUrl?: string;
}
const BrandHeaderClass: React.FC<Props> = ({ customUrl, title }) => {
  return (
    <BrandHeaderImage customurl={customUrl}>
      <Grid>
        {title && (
          <Grid.Column width={8}>
            <Header as="h2" className={"title centered"}>
              {title}
            </Header>
          </Grid.Column>
        )}
      </Grid>
    </BrandHeaderImage>
  );
};

export const BrandHeader = BrandHeaderClass;
