import { SocialNetworkMessage } from "../interfaces";

export function getSocialNetworkMessages(snFiltered: string[]): SocialNetworkMessage[] {
  return snFiltered.map((key: string) => {
    const sn = {
      code: key,
    };
    return sn;
  });
}

export function getSocialNetworkMessagesWithMessage(snFiltered: string[], message: string): SocialNetworkMessage[] {
  return snFiltered.map((key: string) => {
    const sn = {
      code: key,
      socialNetworkMessage: message,
    };
    return sn;
  });
}

export function getFilteredSocialNetworks(socialNetworkMessages: any): string[] {
  return Object.keys(socialNetworkMessages).filter(key => socialNetworkMessages[key]);
}
