import * as React from "react";
import { Button, Grid, Header, Icon, Menu } from "semantic-ui-react";
import { get, values as getValues, keys, omitBy, isUndefined, isEmpty } from "lodash";
import { Formik } from "formik";

import {
  CampaignDeliveryMode,
  PromptCampaign,
  OrderType,
  dateKey,
  timeKey,
  snKey,
  titleKey,
} from "../../../../../interfaces";

import { promptSpecificDate } from "../../../../../utils";
import { CampaignReviewInfoComponent } from "../../../../../components";

import "./styles.scss";

interface OwnProps {
  campaignPrompts: PromptCampaign[];
  campaignDeliveryMode: CampaignDeliveryMode;
  isAutoPost?: boolean;
  submitRef: any;

  onSuccessHandler: (campaignPrompts: PromptCampaign[]) => void;
  onErrorHandler: () => void;

  goBackHandler: () => void;
}

type Props = OwnProps;

const CampaignReviewFC: React.FC<Props> = ({
  campaignDeliveryMode,
  campaignPrompts,
  isAutoPost = false,
  submitRef,
  onSuccessHandler,
  onErrorHandler,
  goBackHandler,
}) => {
  const [order, setOrder] = React.useState<OrderType>("DESC");
  const [campaignSorted, setSortedCampaign] = React.useState<PromptCampaign[]>(campaignPrompts);

  const changeOrder = (): void => {
    const newOrder = ascOrder ? "DESC" : "ASC";
    setOrder(newOrder);
  };

  const ascOrder = order === "ASC";

  const transformCampaigns = () => {
    let prompstHash = {};

    campaignPrompts.map(cprompt => {
      const deliveryDatetimeUtc = get(cprompt, "deliveryDatetimeUtc", undefined);
      prompstHash = {
        ...prompstHash,
        [cprompt.promptId! + titleKey]: get(cprompt.prompt, "title", ""),
        [cprompt.promptId! + timeKey]: deliveryDatetimeUtc === "undefined" ? undefined : deliveryDatetimeUtc,
        [cprompt.promptId! + dateKey]: deliveryDatetimeUtc === "undefined" ? undefined : deliveryDatetimeUtc,
        [cprompt.promptId! + snKey]: cprompt.socialNetworks,
      };
    });
    return { ...prompstHash, deliveryMode: campaignDeliveryMode };
  };

  const goBackAction = () => goBackHandler();
  const scheduledCampaign = campaignDeliveryMode === "SPECIFIC_DATE";

  const getPromptId = (promptKey: string) =>
    promptKey
      .replace(timeKey, "")
      .replace(dateKey, "")
      .replace(titleKey, "");

  const timeRequiredMessage = (promptKey: string, prompts: { [key: string]: string }) =>
    promptKey.includes(timeKey)
      ? "Please enter a time for prompt " + prompts[getPromptId(promptKey) + titleKey] + " to be sent."
      : "Please enter a date for prompt " + prompts[getPromptId(promptKey) + titleKey] + " to be sent.";

  const validate = (values: any) => {
    let errors: Partial<Props> = {};
    if (values.deliveryMode === "SPECIFIC_DATE") {
      const dateTimeValues = keys(values).filter(valueKey => valueKey.includes(timeKey) || valueKey.includes(dateKey));
      const formErrors = dateTimeValues.reduce(
        (accum, promptId) => ({
          ...accum,
          [promptId]: !values[promptId] ? timeRequiredMessage(promptId, values) : undefined,
        }),
        {}
      );
      errors = omitBy(formErrors, isUndefined);
      if (!isEmpty(errors)) {
        onErrorHandler();
      }
      return errors;
    }

    return errors;
  };

  const isOrderedRandom = campaignDeliveryMode === "ORDERED_RANDOM";
  return (
    <Grid>
      <Grid.Row className={"smallRightPadding"}>
        {isOrderedRandom && (
          <Grid.Column width={9} floated={"left"}>
            <Header as="h6">Please drag these prompts into order in which you'd like them to post</Header>
          </Grid.Column>
        )}

        <Grid.Column width={scheduledCampaign ? 14 : isOrderedRandom ? 4 : 16} floated={"right"}>
          <Button
            data-elm-id={`campaignReviewAddPromptBtn`}
            onClick={goBackAction}
            floated={"right"}
            className={"secondary rounded"}>
            Add Prompt
          </Button>
        </Grid.Column>
        {/* ORDER BY */}
        {scheduledCampaign && (
          <Grid.Column width={1} floated={"right"} className={"reviewSortSection"}>
            <Menu.Item className={"sortIcon"} icon onClick={changeOrder}>
              <Icon name={ascOrder ? "arrow up" : "arrow down"} />
            </Menu.Item>
          </Grid.Column>
        )}
      </Grid.Row>

      <Formik
        validateOnChange={false}
        initialValues={transformCampaigns()}
        validate={validate}
        onSubmit={async (values, { validateForm }) => {
          await validateForm(values);

          const updatePrompts = campaignSorted.map(prompt => {
            const deliveryDatetimeUtc = scheduledCampaign
              ? promptSpecificDate(values[prompt.promptId + dateKey], values[prompt.promptId + timeKey])!
              : undefined;
            return {
              ...prompt,
              deliveryDatetimeUtc: `${deliveryDatetimeUtc}`,
              socialNetworks: values[prompt.promptId + snKey],
            };
          });
          onSuccessHandler(updatePrompts);
        }}>
        {({ setFieldValue, errors, handleSubmit, values }) => {
          return (
            <CampaignReviewInfoComponent
              messages={{ error: getValues(errors!) as string[] }}
              campaignPrompts={campaignPrompts}
              submitRef={submitRef}
              values={values}
              handleSubmit={handleSubmit}
              campaignDeliveryMode={campaignDeliveryMode}
              handleSetFieldValue={setFieldValue}
              handlePromptsSorted={setSortedCampaign}
              ascOrder={ascOrder}
              isAutoPost={isAutoPost}
            />
          );
        }}
      </Formik>
    </Grid>
  );
};

export const CampaignReview = CampaignReviewFC;
