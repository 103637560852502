import * as React from "react";
import {
  Dropdown,
  Menu,
  Loader,
  DropdownItemProps,
  Checkbox,
  Input,
  Pagination,
  Grid,
  PaginationProps,
} from "semantic-ui-react";
import { get, map, union, debounce } from "lodash";

import { AppContext } from "../../providers";
import { SearchFilter, Page } from "../../interfaces";

import "./styles.scss";
import { CoachService } from "../../services";
import { CustomDropDownComponent } from "../CustomDropDown";

interface Props {
  /**
   * Component
   */
  content: JSX.Element;
  /**
   * Handle to refresh content with the result
   */
  refreshData: (result: any[]) => void;

  /**
   * Handle to refresh content with the result
   */
  refreshAllDataToExport?: (result?: any[]) => void;

  /**
   * Search function name
   */
  searchFunction: (
    coachId: string,
    sort: string,
    filters?: SearchFilter | null,
    page?: number | string,
    size?: number
  ) => Promise<Page<any>>;

  customSort?: DropdownItemProps[] | undefined;
  defaultCustomSort?: string;

  forceRefresh?: boolean;
  multiple?: boolean;
  pageable?: boolean;
  displaySusbcriptionFilter?: boolean;
}

const SearchFC: React.FC<Props> = ({
  content,
  multiple = false,
  forceRefresh = false,
  pageable = false,
  displaySusbcriptionFilter = false,
  refreshData,
  refreshAllDataToExport,
  searchFunction,
}) => {
  const { userContext } = React.useContext(AppContext);
  const coachId = get(userContext, "coach.id", "");
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [totalElements, setTotalElements] = React.useState<number>(15);

  const [searchTitle, setSearchTitle] = React.useState<string | null>(null);

  const [loading, setLoading] = React.useState<boolean>(true);

  const [selectedBranches, setSelectedBranches] = React.useState<string[] | undefined>(undefined);
  const [selectedRegions, setSelectedRegions] = React.useState<string[] | undefined>(undefined);
  const [selectedStates, setSelectedStates] = React.useState<string[] | undefined>(undefined);
  const [selectedSubscriptions, setSelectedSubscriptions] = React.useState<string[] | undefined>(undefined);

  const [branchesFilters, setBranchesFilters] = React.useState<DropdownItemProps[]>([]);
  const [regionFilters, setRegionsFilters] = React.useState<DropdownItemProps[]>([]);
  const [stateFilters, setStatesFilters] = React.useState<DropdownItemProps[]>([]);

  const [results, setResults] = React.useState<any[] | undefined>(undefined);

  const [subscriptionTypeOptions] = React.useState<DropdownItemProps[]>([
    { key: "PLAYER_BASIC", text: "Basic", value: "PLAYER_BASIC" },
    {
      key: "PLAYER_PLUS",
      text: "SocialCoach+",
      value: "PLAYER_PLUS",
    },
  ]);

  const nextPage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: PaginationProps): void => {
    search(Number(data.activePage) - 1);
  };

  React.useEffect(() => {
    CoachService.branches(coachId).then(response => {
      const filters: DropdownItemProps[] = map(response, branch => ({
        key: branch.branchId,
        text: branch.name,
        value: branch.branchId,
      }));
      setBranchesFilters(filters);
    });
    CoachService.regions(coachId).then(response => {
      const filters: DropdownItemProps[] = map(response, region => ({
        key: region.regionId,
        text: region.name,
        value: region.regionId,
      }));
      setRegionsFilters(filters);
    });
    CoachService.states(coachId).then(response => {
      const filters: DropdownItemProps[] = map(response, state => ({
        key: state.stateId,
        text: state.stateCode,
        value: state.stateId,
      }));
      setStatesFilters(filters);
    });
  }, [coachId, forceRefresh]);

  React.useEffect(() => {
    search();
  }, [selectedBranches, searchFunction, selectedRegions, selectedStates, searchTitle, selectedSubscriptions]);

  React.useEffect(() => {
    search();
  }, []);

  React.useEffect(() => {
    if (forceRefresh) {
      search();
    }
  }, [forceRefresh]);

  React.useEffect(() => {
    if (refreshAllDataToExport) {
      refreshAllDataToExport(results);
    }
  }, [results]);

  const search = (page: number = 0) => {
    setLoading(true);
    let filterBody: SearchFilter = {
      title: searchTitle || null,
      branchIds: selectedBranches ? selectedBranches : undefined,
      regionIds: selectedRegions ? selectedRegions : undefined,
      stateIds: selectedStates ? selectedStates : undefined,
      subscriptionTypes: selectedSubscriptions ? selectedSubscriptions : undefined,
    };

    filterBody = {
      ...filterBody,
    };
    setResults(() => undefined);

    searchFunction(coachId, "ASC", filterBody, page, searchTitle ? totalElements : 15)
      .then(response => {
        setTotalPages(response.totalPages!);
        setTotalElements(response.totalElements!);
        refreshData(response.content);
        if (refreshAllDataToExport) {
          loadAllData();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadAllData = async () => {
    let page = 0;
    let total = 1;
    let calculatingResult: any[] = [];
    let filterBody: SearchFilter = {
      title: searchTitle || null,
      branchIds: selectedBranches ? selectedBranches : undefined,
      regionIds: selectedRegions ? selectedRegions : undefined,
      stateIds: selectedStates ? selectedStates : undefined,
    };

    filterBody = {
      ...filterBody,
    };

    while (page < total) {
      const response = await searchFunction(coachId, "ASC", filterBody, page, 50);
      if (page === 0) {
        total = response.totalPages!!;
        calculatingResult = response.content;
      } else {
        calculatingResult = calculatingResult.concat(response.content);
      }
      page++;
    }
    setResults(() => calculatingResult);
  };

  const searchByContent = (query: string): void => {
    setSearchTitle(query);
  };

  const delayedQuery = React.useRef(debounce((q: string) => searchByContent(q), 500)).current;

  const onChange = (e: any) => {
    delayedQuery(e.target.value);
  };

  return (
    <div>
      <Menu secondary className={"filterMultiMenu"}>
        {/* SEARCH BY ... */}
        <Menu.Item className={"searchMenu"}>
          <Input className={"searchInput"} icon="search" placeholder="Search..." onChange={onChange} />
        </Menu.Item>

        {/* BRANCH */}
        {branchesFilters && branchesFilters?.length > 0 && (
          <Menu.Item>
            <Dropdown search selection placeholder={"Branch"} className="searchInput" multiple={multiple}>
              <Dropdown.Menu>
                {branchesFilters.map((fbranches, index) => {
                  return (
                    <Dropdown.Item key={"branch" + index}>
                      <Checkbox
                        id={fbranches.key}
                        checked={selectedBranches?.includes(`${fbranches!.value!}`)}
                        onChange={(a, data) => {
                          setSelectedBranches(filters => {
                            if (data.checked) {
                              return union(filters, [`${fbranches!.value!}`]);
                            }
                            return filters?.filter(r => r !== `${fbranches!.value!}`);
                          });
                        }}
                      />
                      {fbranches.text}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        )}

        {/* REGION */}
        {regionFilters && regionFilters?.length > 0 && (
          <Menu.Item>
            <Dropdown search selection placeholder={"Region"} className="searchInput" multiple={multiple}>
              <Dropdown.Menu>
                {regionFilters.map((fbranches, index) => {
                  return (
                    <Dropdown.Item key={"selectedRegions" + index}>
                      <Checkbox
                        checked={selectedRegions?.includes(`${fbranches!.value!}`)}
                        onChange={(a, data) => {
                          setSelectedRegions(fregions => {
                            if (data.checked) {
                              return union(fregions, [`${fbranches!.value!}`]);
                            }
                            return fregions?.filter(r => r !== `${fbranches!.value!}`);
                          });
                        }}
                      />
                      {fbranches.text}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        )}

        {/* LICENSE STATE */}
        {stateFilters && stateFilters?.length > 0 && (
          <Menu.Item>
            <Dropdown
              search
              selection
              scrolling
              placeholder={"License State"}
              className="searchInput"
              multiple={multiple}
            >
              <Dropdown.Menu>
                {stateFilters.map((stateFilter, index) => {
                  return (
                    <Dropdown.Item key={"state" + index}>
                      <Checkbox
                        checked={selectedStates?.includes(`${stateFilter!.value!}`)}
                        onChange={(a, data) => {
                          setSelectedStates(fregions => {
                            if (data.checked) {
                              return union(fregions, [`${stateFilter!.value!}`]);
                            }
                            return fregions?.filter(r => r !== `${stateFilter!.value!}`);
                          });
                        }}
                      />
                      {stateFilter.text}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        )}

        {/* FILTER BY Review Status */}
        {displaySusbcriptionFilter && (
          <CustomDropDownComponent
            defaultValue={undefined}
            options={subscriptionTypeOptions}
            placeholder={"Subscription Type"}
            multiple={true}
            onChangeHandler={setSelectedSubscriptions}
          />
        )}
      </Menu>

      {loading ? <Loader active size="large" /> : content}

      {pageable && !searchTitle && (
        <Grid centered>
          <Pagination
            defaultActivePage={1}
            firstItem={null}
            lastItem={null}
            pointing
            secondary
            totalPages={totalPages}
            onPageChange={nextPage}
          />
        </Grid>
      )}
    </div>
  );
};

export const SearchMultipleComponent = SearchFC;
