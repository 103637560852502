import * as React from "react";
import ReactCrop, { Crop } from "react-image-crop";
import ImageUploading, { ImageListType } from "react-images-uploading";

import { Modal, Button, Grid, Input } from "semantic-ui-react";
// import "react-image-crop/lib/ReactCrop.scss";
import "./styles.scss";

import { EditSection } from "./EditSection";

interface OwnProps {
  dataElmId: string;
  label: string;
  subtitle?: string;
  placeholder: string;
  buttonName: string;
  className: string;
  file?: File;
  bannerUrl?: string;

  handleSave?: (file: any, blob: Blob) => void;
}

type Props = OwnProps;

const UploadImageFC: React.FC<Props> = ({
  dataElmId = "string",
  subtitle = "The recommended aspect ratio for the banner is 10:3",
  buttonName = "Browse",
  className = "primary",
  bannerUrl,
  file,
  placeholder,
  label,
  handleSave,
}) => {
  const [imageRef, setImageRef] = React.useState<any>("");
  const [fileUrl, setFileUrl] = React.useState<string>("");

  const [croppedImageUrl, setCroppedImageUrl] = React.useState<unknown | undefined>(undefined);
  const [blob, setBlob] = React.useState<Blob>();
  const [currentImageList, setCurrentImageList] = React.useState<ImageListType>([]);

  const [open, setOpen] = React.useState(false);
  const [crop, setCrop] = React.useState<Crop>({
    unit: "%",
    width: 100,
    aspect: 10 / 3,
    x: 0,
    y: 0,
    height: 30,
  });

  React.useEffect(() => {
    makeClientCrop(crop);
  }, [imageRef]);

  const onSelectFile = (imageList: any): void => {
    if (imageList.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setFileUrl(reader.result as string));
      reader.readAsDataURL(imageList[0].file);
    }
  };

  const getCroppedImg = async (image: any, cropSection: any, fileName: any) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = 1440; // cropSection.width;
    canvas.height = 432; // cropSection.height;
    const ctx = canvas.getContext("2d");

    ctx!.globalAlpha = 0.9;
    ctx!.globalCompositeOperation = "lighter";

    ctx!.drawImage(
      image,
      cropSection.x * scaleX,
      cropSection.y * scaleY,
      cropSection.width * scaleX,
      cropSection.height * scaleY,
      0,
      0,
      1440, // cropSection.width,
      432 // cropSection.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blobThumbnail => {
        if (!blobThumbnail) {
          return;
        }
        blobThumbnail.text = fileName;
        setBlob(blobThumbnail);
        window.URL.revokeObjectURL(fileUrl);
        resolve(window.URL.createObjectURL(blobThumbnail));
      }, "image/png");
    });
  };

  const makeClientCrop = async (cropSection: Crop) => {
    if (imageRef && cropSection.width && cropSection.height) {
      const croppedUrl = await getCroppedImg(imageRef, cropSection, "image.png");
      setCroppedImageUrl(croppedUrl);
    } else if (imageRef && crop.width && crop.height) {
      const croppedUrl = await getCroppedImg(imageRef, cropSection, "image.png");
      setCroppedImageUrl(croppedUrl);
    }
  };

  const onCropComplete = (cropSection: Crop) => {
    makeClientCrop(cropSection);
  };

  const onChange = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
    onSelectFile(imageList);
    setCurrentImageList(imageList);
    setOpen(true);
  };

  return (
    <Modal
      data-elm-id={dataElmId + "_modal"}
      className={"previewModal"}
      onClose={() => setOpen(false)}
      open={open}
      trigger={
        <div className="uploadImageSection">
          <ImageUploading value={currentImageList} onChange={onChange} data-elm-id={dataElmId + "_imageUploading"}>
            {({ onImageUpload, onImageUpdate }) => {
              return (
                <div>
                  <Grid.Row>
                    <Grid.Column>
                      <label className={"title"}>{label}</label>
                      <p className={"title small"}>{subtitle}</p>
                    </Grid.Column>

                    {!bannerUrl && (
                      <Grid.Column className={label ? "uploadContainer" : ""}>
                        <Input value={file} className={"uploadInput"} placeholder={placeholder}>
                          <input />
                          <Button
                            data-elm-id={`uploadImage${buttonName.replace(" ", "")}Btn`}
                            className={className}
                            onClick={onImageUpload}
                          >
                            {buttonName}
                          </Button>
                        </Input>
                      </Grid.Column>
                    )}

                    {bannerUrl && (
                      <EditSection
                        key={bannerUrl}
                        bannerUrl={bannerUrl}
                        onClick={() => {
                          if (currentImageList.length > 0) {
                            onImageUpdate(0);
                          } else {
                            onImageUpload();
                          }
                        }}
                      />
                    )}
                  </Grid.Row>
                </div>
              );
            }}
          </ImageUploading>
        </div>
      }
    >
      <Modal.Content>
        <Grid>
          <Grid.Row centered={true}>
            <ReactCrop
              src={fileUrl}
              crop={crop}
              onComplete={onCropComplete}
              onImageLoaded={setImageRef}
              onChange={setCrop}
            />
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Grid className={"centered"}>
          <Button
            data-elm-id={`${dataElmId}_uploadImageDoneBtn`}
            onClick={() => {
              setOpen(false);
              if (blob && croppedImageUrl) {
                handleSave!(croppedImageUrl, blob!);
              }
            }}
            className={"primary rounded centered"}
          >
            Done
          </Button>
        </Grid>
      </Modal.Actions>
    </Modal>
  );
};

export const UploadImage = UploadImageFC;
