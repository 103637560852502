import * as React from "react";
import VimeoPlayer from "../../../../VimeoPlayer";
import copy from "copy-to-clipboard";
import { isEmpty } from "lodash";
import { Grid, Form, TextArea, Image, Input, Header, Divider, Button } from "semantic-ui-react";
import { CoachingProfileType, descriptors, UsernameLinkId, FinalMessageId } from "../../descriptors";
import { ConfigContext } from "../../../../../contexts/appContexts";
import{ useIntl } from "react-intl";

import { UploadImage } from "../../../../UploadImage";
import { UploadVideo } from "../../../../UploadVideo";
import { createVideoThumbnailBlob } from "../../../../../utils";

import { joditConfig } from "./joditConfig";

const JoditEditorClientSideOnlyLazy = React.lazy(() => import("jodit-react"));

interface OwnProps {
  isSubmitting: boolean;
  handleSubmit: any;
  values: any;
  handleChange: any;
  uploadVideo: any;
  videoUrl?: string;
  bannerUrl?: string;
  setFieldValue: any;
  uploadImage: any;

  handleLoadingVideo: (loading: boolean) => void;
}

const LandingPageFormFC: React.FC<OwnProps> = (({
    isSubmitting,
    values,
    handleChange,
    uploadImage,
    uploadVideo,
    videoUrl,
    bannerUrl,
    setFieldValue,
    handleLoadingVideo,
}) => {
    const [fileUrl, setFileUrl] = React.useState<string>();
    const [file, setFile] = React.useState<File>();
    const [thumbnail, setThumbnail] = React.useState<string | undefined>();
    const isSSR = typeof window === "undefined";
    const editor = React.useRef(null);
    const { formatMessage } = useIntl();

    React.useEffect(() => {
      if (videoUrl) {
        const urlPromise = createVideoThumbnailBlob(videoUrl);
        urlPromise.then(url => setThumbnail(url));
      } else if (fileUrl) {
        const urlPromise = createVideoThumbnailBlob(fileUrl);
        urlPromise.then(url => setThumbnail(url));
      }
    }, [videoUrl, fileUrl]);

    const videoContent = !isEmpty(thumbnail) ? (
      <Image size="medium" src={thumbnail} />
    ) : (
      videoUrl && <VimeoPlayer videoUrl={videoUrl} />
    );

    const basicWelcomeMessageTextField = (
      <TextArea
        fluid
        className={"leftMargin smallMarginTop"}
        id={FinalMessageId}
        data-elm-id="finalMessageInput"
        placeholder={formatMessage({
          ...descriptors[CoachingProfileType.finalMessagePlaceholder],
        })}
        rows={3}
        onChange={handleChange}
        value={values[FinalMessageId]}
      />
    );
    return (
      <Form error={true} loading={isSubmitting}>
        <Grid columns={1}>
          <div className={"formDiv"}>
            <Header as="h6" className="noMarginBottom smallPaddingBottom">
              {formatMessage({ ...descriptors[CoachingProfileType.usernameLinkLabel] })}
            </Header>

            <Grid.Row>
              <Grid.Column className="field" width={16}>
                <ConfigContext.Consumer>
                  {({ deployBaseUrl }) => (
                    <Input
                      className={"inputWithLabel"}
                      action={{
                        color: "primary",
                        icon: "copy outline",
                        onClick: () => {
                          copy(`${deployBaseUrl}${values[UsernameLinkId]}`);
                        },
                      }}
                      label={`${deployBaseUrl}`}
                      fluid
                      id={UsernameLinkId}
                      data-elm-id="shared-link-input"
                      type={"text"}
                      name={name}
                      placeholder={formatMessage({
                        ...descriptors[CoachingProfileType.usernameLinkPlaceholder],
                      })}
                      onChange={e => {
                        const value = e.target.value;
                        setFieldValue(UsernameLinkId, value);
                      }}
                      value={values[UsernameLinkId]}
                    />
                  )}
                </ConfigContext.Consumer>
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row className={"textAreaRow"}>
              <Grid.Column>
                <div className="field">
                  <Header as="h6" className="noMargin noMarginBottom">
                    {formatMessage({ ...descriptors[CoachingProfileType.finalMessageLabel] })}
                  </Header>
                  {!isSSR && (
                    <React.Suspense fallback={basicWelcomeMessageTextField}>
                      <div className={"bottomPadding"} contentEditable={true}>
                        <JoditEditorClientSideOnlyLazy
                          ref={editor}
                          value={values[FinalMessageId]}
                          config={joditConfig}
                          onChange={newContent => {
                            // const d = document.createElement("div");
                            // d.innerHTML = newContent;
                            // handleSetFieldValue!("message", d.innerText);
                            setFieldValue(FinalMessageId, newContent);
                          }}
                        />
                      </div>
                    </React.Suspense>
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <UploadImage
              file={file}
              bannerUrl={bannerUrl}
              dataElmId="banner-image-input"
              placeholder={formatMessage({ ...descriptors[CoachingProfileType.uploadBannerTitle] })}
              className={"primary"}
              label={formatMessage({ ...descriptors[CoachingProfileType.uploadBannerTitle] })}
              buttonName={formatMessage({ ...descriptors[CoachingProfileType.uploadBtn] })}
              handleSave={(newFile, blob) => {
                setFile(newFile);
                uploadImage(newFile, blob);
              }}
            />
            <Divider />
            {(!isEmpty(fileUrl) || videoUrl) && (
              <Grid columns={3} className={"noMargin"}>
                <Grid.Row>
                  <Header as="h6" className="noMarginBottom">
                    {formatMessage({ ...descriptors[CoachingProfileType.uploadVideoTitle] })}
                  </Header>
                  {!isEmpty(videoUrl) && (
                    <Header as="h6" className={"small"}>
                      We recommend your video duration to be no more than 5 min
                    </Header>
                  )}
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={10} className={"videoPreviewSection"}>
                    {videoContent}
                  </Grid.Column>
                  <Grid.Column verticalAlign={"middle"}>
                    {" "}
                    <Button
                      data-elm-id={`landingPageUploadVideoBtn`}
                      className={"deleteButton"}
                      circular
                      icon="minus"
                      onClick={() => {
                        setFileUrl(undefined);
                        uploadVideo(undefined, "");
                      }}
                    />{" "}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
            <Grid.Row>
              <Grid.Column>
                {isEmpty(videoUrl) && (
                  <UploadVideo
                    accept={"video/mp4"}
                    data-elm-id="welcomeVideoInput"
                    placeholder={formatMessage({ ...descriptors[CoachingProfileType.uploadVideoTitle] })}
                    className={"primary"}
                    label={
                      isEmpty(fileUrl) ? formatMessage({ ...descriptors[CoachingProfileType.uploadVideoTitle] }) : ""
                    }
                    fileName={`promptVideo${new Date().toUTCString}`}
                    buttonName={formatMessage({ ...descriptors[CoachingProfileType.uploadBtn] })}
                    showErrors={true}
                    showSubtitle={true}
                    handleSuccess={url => {
                      uploadVideo(url);
                    }}
                    onChangeImage={videoFile => {
                      handleLoadingVideo(true);
                      setFileUrl(URL.createObjectURL(videoFile));
                    }}
                    handleError={() => {
                      handleLoadingVideo(false);
                      setFileUrl(undefined);
                    }}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </div>
        </Grid>
      </Form>
    );
  }
);

export const LandingPageForm = LandingPageFormFC;
