import { Media, MediaGroup } from "../../../interfaces";

export function validateMediasRequiremenst(mediaGroup?: MediaGroup): boolean {
  if (!mediaGroup || mediaGroup?.media === undefined) {
    return true;
  }

  const invalidMedia =
    mediaGroup?.media !== undefined
      ? mediaGroup?.media?.some(mediaElement => {
          return mediaWithErrors(mediaElement);
        })
      : true;

  return !invalidMedia;
}

export function mediaWithErrors(mediaElement: Media): boolean {
  const withRequirementErrors = mediaElement.errors ? Object.values(mediaElement.errors).some(error => error) : false;
  return (!mediaElement.validAspectRatio && mediaElement.validAspectRatio !== undefined) || withRequirementErrors;
}
