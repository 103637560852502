import React, { useState, useCallback, useContext, useMemo, ChangeEvent } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Form, Message } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { values as getValues } from "lodash";

import { Plan } from "../../../interfaces/dtos";
import { ConfigContext } from "../../../contexts/appContexts";
import { CoachService } from "../../../services";
import { descriptors, CoachSummaryType } from "../PlanSection/descriptors";
import { AppContext } from "../../../providers";

import { NameInput, InputWrapper, PayButton, CARD_ELEMENT_OPTIONS, CvsWrapper, ExpirationWrapper } from "./styles";

export interface UpdatePaymentFormProps {
  plan?: Plan;
  email: string;
  onSuccess?: () => void;
}

export function UpdatePaymentForm({ plan, email, onSuccess }: UpdatePaymentFormProps) {
  const config = useContext(ConfigContext);
  const stripePromise = useMemo(() => config && loadStripe(config.stripe.public_key, { locale: "en" }), [config]);

  if (!stripePromise) {
    return null;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc: "https://fonts.googleapis.com/css?family=Lato&display=swap",
            family: "Lato",
            style: "normal",
          },
        ],
      }}>
      <InnerForm plan={plan} email={email} onSuccess={onSuccess} />
    </Elements>
  );
}

function InnerForm({ plan, onSuccess }: UpdatePaymentFormProps) {
  const { userContext } = useContext(AppContext);

  const [cardFocus, setCardFocus] = useState(false);
  const [expirationFocus, setExpirationFocus] = useState(false);
  const [cvsFocus, setCvsFocus] = useState(false);

  const [name, setName] = useState("");
  const [error, setError] = useState<{
    stripeSection: string | undefined;
    password: string | undefined;
  }>({
    stripeSection: undefined,
    password: undefined,
  });

  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const cardNumberElement = elements?.getElement(CardNumberElement);

  const executePayment = useCallback(async () => {
    if (name.length === 0) {
      setError({
        stripeSection: "You must enter the name on the card.",
        password: undefined,
      });
      return;
    }

    if (!cardNumberElement) {
      return;
    }

    setLoading(true);
    setError({
      stripeSection: undefined,
      password: undefined,
    });

    try {
      const playerId = userContext?.player?.id!!;
      const secret = await CoachService.createPaymentIntent(playerId);

      // TODO: invoce payment method by default
      const result = await stripe!.confirmCardSetup(secret, {
        payment_method: {
          billing_details: {
            name,
          },
          card: cardNumberElement!!,
        },
      });

      const { error: cardError, setupIntent } = result;

      /*
       ** on setupIntent we receive an attribute named: payment_method. By example:
       ** payment_method: "pm_1LsoWlFFM1tojnil1lFNwrba"
       */

      if (cardError) {
        setError({
          stripeSection: cardError.message || "An error occurred while processing your payment information.",
          password: undefined,
        });

        setLoading(false);
        return;
      }

      await CoachService.updatePaymentMethod({
        paymentMethodId: setupIntent!!.payment_method!!.toString(),
      });

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      setError({
        stripeSection: "An unexpected error ocurred while setting up your plan. ",
        password: undefined,
      });
    }

    setLoading(false);
  }, [stripe, cardNumberElement, name, plan!!.id]);

  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  }, []);

  const cardOptions = useMemo(() => ({ ...CARD_ELEMENT_OPTIONS, disabled: loading }), [loading]);
  const errors = getValues(error).filter(e => e !== undefined) as string[];

  return (
    <Form error={errors.length > 0}>
      <Form.Field>
        <NameInput
          className={"creditCardName"}
          disabled={loading}
          placeholder="First and last name"
          onChange={handleNameChange}
        />
      </Form.Field>
      <Form.Field>
        <InputWrapper focus={cardFocus}>
          <CardNumberElement
            options={cardOptions}
            onFocus={() => setCardFocus(true)}
            onBlur={() => setCardFocus(false)}
          />
        </InputWrapper>
      </Form.Field>
      <Form.Group>
        <Form.Field>
          <ExpirationWrapper focus={expirationFocus}>
            <CardExpiryElement
              options={cardOptions}
              onFocus={() => setExpirationFocus(true)}
              onBlur={() => setExpirationFocus(false)}
            />
          </ExpirationWrapper>
        </Form.Field>
        <Form.Field>
          <CvsWrapper focus={cvsFocus}>
            <CardCvcElement options={cardOptions} onFocus={() => setCvsFocus(true)} onBlur={() => setCvsFocus(false)} />
          </CvsWrapper>
        </Form.Field>
      </Form.Group>

      <PayButton centered className={"primary"} disabled={loading} onClick={executePayment}>
        {!loading && <FormattedMessage {...descriptors[CoachSummaryType.updatePaymentButton]} />}
        {loading && <FormattedMessage {...descriptors[CoachSummaryType.processing]} />}
      </PayButton>

      {errors.map(e => (
        <Message key={e} error content={e} />
      ))}
    </Form>
  );
}
