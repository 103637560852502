import { defineMessages } from "react-intl";

export enum VerificationPageType {
  title = "VerificationPageType.title",
  subtitle = "VerificationPageType.subtitle",
  errorTitle = "VerificationPageType.errorTitle",
  errorSubtitle = "VerificationPageType.errorSubtitle",

  resendBtn = "VerificationPageType.submit",
  signIn = "VerificationPageType.signIn",
}

export const en = {
  [VerificationPageType.title]: "Account successfully verified",
  [VerificationPageType.subtitle]: "Your user was confirmed ",

  [VerificationPageType.errorTitle]: "Verification Error",
  [VerificationPageType.errorSubtitle]: "Click link to re-send a new verification email",

  [VerificationPageType.resendBtn]: "Resend Email",
  [VerificationPageType.signIn]: "Sign in",
};

export const descriptors = defineMessages({
  [VerificationPageType.title]: {
    id: VerificationPageType.title,
    defaultMessage: en[VerificationPageType.title],
    description: "title",
  },
  [VerificationPageType.subtitle]: {
    id: VerificationPageType.subtitle,
    defaultMessage: en[VerificationPageType.subtitle],
    description: "Subtitle",
  },

  [VerificationPageType.errorTitle]: {
    id: VerificationPageType.errorTitle,
    defaultMessage: en[VerificationPageType.errorTitle],
    description: "errorTitle",
  },
  [VerificationPageType.errorSubtitle]: {
    id: VerificationPageType.errorSubtitle,
    defaultMessage: en[VerificationPageType.errorSubtitle],
    description: "Subtitle",
    values: {
      span: (name: any) => name,
    }
  },

  [VerificationPageType.resendBtn]: {
    id: VerificationPageType.resendBtn,
    defaultMessage: en[VerificationPageType.resendBtn],
    description: "",
  },
  [VerificationPageType.signIn]: {
    id: VerificationPageType.signIn,
    defaultMessage: en[VerificationPageType.signIn],
    description: "Link to sign in page",
  },
});
