import { defineMessages } from "react-intl";

export enum FirstLoginType {
  title = "FirstLoginType.title",
  subtitle = "FirstLoginType.subtitle",

  firstName = "FirstLoginType.firstName",
  lastName = "FirstLoginType.lastName",
  birthdate = "FirstLoginType.birthdate",
  phoneNumber = "FirstLoginType.phoneNumber",

  submit = "FirstLoginType.submit",

  warningTitle = "FirstLoginType.warningTitle",
  errorTitle = "FirstLoginType.errorTitle",
  infoTitle = "FirstLoginType.infoTitle",
}

export const en = {
  [FirstLoginType.title]: "Let's get started",
  [FirstLoginType.subtitle]: "Tell us more about yourself.\n",
  [FirstLoginType.firstName]: "First Name",
  [FirstLoginType.lastName]: "Last Name",
  [FirstLoginType.birthdate]: "Birthdate",
  [FirstLoginType.phoneNumber]: "Phone Number",

  [FirstLoginType.submit]: "Save Profile",

  [FirstLoginType.warningTitle]: "Warning",
  [FirstLoginType.errorTitle]: "Error:",
  [FirstLoginType.infoTitle]: "Hey",
};

export const descriptors = defineMessages({
  [FirstLoginType.title]: {
    id: FirstLoginType.title,
    defaultMessage: en[FirstLoginType.title],
    description: "Log In title",
  },
  [FirstLoginType.subtitle]: {
    id: FirstLoginType.subtitle,
    defaultMessage: en[FirstLoginType.subtitle],
    description: "Log In subtitle",
  },
  [FirstLoginType.firstName]: {
    id: FirstLoginType.firstName,
    defaultMessage: en[FirstLoginType.firstName],
    description: "firstName text",
  },
  [FirstLoginType.lastName]: {
    id: FirstLoginType.lastName,
    defaultMessage: en[FirstLoginType.lastName],
    description: "lastName text",
  },
  [FirstLoginType.birthdate]: {
    id: FirstLoginType.birthdate,
    defaultMessage: en[FirstLoginType.birthdate],
    description: "birthdate text",
  },
  [FirstLoginType.phoneNumber]: {
    id: FirstLoginType.phoneNumber,
    defaultMessage: en[FirstLoginType.phoneNumber],
    description: "phoneNumber text",
  },
  [FirstLoginType.submit]: {
    id: FirstLoginType.submit,
    defaultMessage: en[FirstLoginType.submit],
    description: "Sign In text",
  },
  [FirstLoginType.warningTitle]: {
    id: FirstLoginType.warningTitle,
    defaultMessage: en[FirstLoginType.warningTitle],
    description: "Warning messages title",
  },
  [FirstLoginType.errorTitle]: {
    id: FirstLoginType.errorTitle,
    defaultMessage: en[FirstLoginType.errorTitle],
    description: "Error Messages title",
  },
  [FirstLoginType.infoTitle]: {
    id: FirstLoginType.infoTitle,
    defaultMessage: en[FirstLoginType.infoTitle],
    description: "Info messages title",
  },
});
