import * as React from "react";
import { Input, Menu, Icon, InputOnChangeData, Loader, DropdownItemProps } from "semantic-ui-react";
import { debounce, get, map } from "lodash";
import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";

import { AppContext } from "../../providers";
import { OrderType, Page, PlayerAccount, SocialNetworksId } from "../../interfaces";
import { dateMask, getDateSearchFormat } from "../../utils";

import "./styles.scss";
import { CheckDropDown } from "../CheckDropDown";
import { useSocialNetworks } from "../../reducers";

const DefaultDebounceTime = 1000;
let controller: any;
interface Props {
  /**
   * Component
   */
  content: JSX.Element;

  showSortBy?: boolean;
  customCoachId?: string;
  searchAttribute?: string;

  /**
   * Handle to refresh content with the result
   */
  refreshData: (result: any[]) => void;

  /**
   * Search function name
   */
  searchFunction: (
    coachId: string,
    signal: any,
    page?: number | string,
    size?: number,
    from?: string | undefined,
    until?: string | undefined,
    socialNetworks?: SocialNetworksId[]
  ) => Promise<Page<any>>;

  filterCondition?: (element: any, searchTerm: string) => boolean;
  /**
   * Handle to refresh selected social networks
   */
  refreshSocialNetworks: (socialNetworks: SocialNetworksId[]) => void;
}

const LocalSearchFC: React.FC<Props> = ({
  content,
  customCoachId,
  showSortBy = true,
  searchAttribute = "email",
  filterCondition,
  refreshData,
  searchFunction,
  refreshSocialNetworks,
}) => {
  const { userContext } = React.useContext(AppContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [order, setOrder] = React.useState<OrderType>("DESC");
  const [searchTitle, setSearchTitle] = React.useState<string | null>(null);
  const [results, setResults] = React.useState<PlayerAccount[]>([]);
  const [socialNetworks, setSocialNetworks] = React.useState<DropdownItemProps[]>([]);
  const [socialNetworksSelected, setSocialNetworksSelected] = React.useState<any | undefined>([]);

  const ascOrder = order === "ASC";

  const [fromDate, setFromDate] = React.useState<Date | undefined>(undefined);
  const [untilDate, setUntilDate] = React.useState<Date | undefined>(undefined);

  const datePickerProps = {
    className: "leaderBoardFilterInput",
    showYearDropdown: true,
    scrollableYearDropdown: true,
    yearDropdownItemNumber: 15,
    disabled: false,
    customInput: <MaskedInput mask={dateMask} />,
  };
  const socialNetworkLabels = {
    FB: " Facebook",
    IG: " Instagram",
    TW: " X",
    LI: " LinkedIn",
    TT: " TikTok",
  };

  const { sns } = useSocialNetworks(undefined, false);

  React.useEffect(() => {
    const socialNetworksDropdownItems: DropdownItemProps[] = map(sns, snId => ({
      key: snId,
      text: socialNetworkLabels[snId],
      value: snId,
    }));
    setSocialNetworks(socialNetworksDropdownItems);
  }, [sns]);

  // React.useEffect(() => {
  //   if (!loading) {
  //     loadAllData();
  //   }
  // }, []);

  React.useEffect(() => {
    refreshSocialNetworks(socialNetworksSelected);
    setResults([]);
    if (controller) {
      controller.abort();
    }
    loadAllData();
  }, [socialNetworksSelected]);

  React.useEffect(() => {
    setResults([]);
    if (controller) {
      controller.abort();
    }
    loadAllData();
  }, [fromDate, untilDate]);

  React.useEffect(() => {
    refreshData(results);
  }, [results]);

  const loadAllData = async () => {
    controller = new AbortController();

    setLoading(true);
    const coachId = customCoachId ? customCoachId : get(userContext, "coach.id", "");
    let page = -1;
    let total = 0;

    while (page < total) {
      const signal = controller.signal;

      try {
        const response = await searchFunction(
          coachId,
          signal,
          page + 1,
          15,
          getDateSearchFormat(fromDate),
          getDateSearchFormat(untilDate),
          socialNetworksSelected
        );

        console.log("response ->", response);
        if (page === -1) {
          total = response.totalPages!!;
        }
        setResults(currentResult => currentResult.concat(response.content));
        page++;
      } catch (error) {
        page = total;
      }
    }

    setLoading(false);
  };

  const searchByContent = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
    let result = results.sort((a, b) => new Date(b.createdDate!).valueOf() - new Date(a.createdDate!).valueOf());
    result = data.value && data.value !== "" ? result.filter(r => filterValidation(r, data.value)) : result;
    result = ascOrder ? result : result.reverse();

    refreshData(result);
    setSearchTitle(data.value);
  };

  const changeOrder = (): void => {
    const newOrder = ascOrder ? "DESC" : "ASC";
    let result = results.sort((a, b) => new Date(b.createdDate!).valueOf() - new Date(a.createdDate!).valueOf());
    result =
      searchTitle && searchTitle !== ""
        ? result.filter(r => filterValidation(r, searchTitle))
        : result.filter(r => true);
    result = !ascOrder ? result : result.reverse();

    refreshData(result);
    setOrder(newOrder);
  };

  const filterValidation = (element: any, searchTerm: string): boolean => {
    if (filterCondition) {
      return filterCondition(element, searchTerm);
    }
    return get(element, searchAttribute, "").includes(searchTerm);
  };

  return (
    <div>
      <Menu secondary className={"localInfinityFilterMenu"}>
        {/* SEARCH BY ... */}
        {!loading && (
          <Menu.Item className={"searchMenu"}>
            <Input
              className={"searchInput"}
              icon="search"
              placeholder="Search..."
              onChange={debounce(
                e => {
                  searchByContent(e, e.target);
                },
                DefaultDebounceTime,
                {
                  leading: true,
                }
              )}
            />
          </Menu.Item>
        )}

        {/* Social networks DropDown */}
        <CheckDropDown
          options={socialNetworks}
          defaultSelectedOptions={sns}
          multiple={true}
          dropDownKey={"socialNetworksFilter_"}
          placeholder={"Social Networks"}
          customClassName={"filterDropDown"}
          onChange={setSocialNetworksSelected}
        />

        <Menu.Item className={"searchMenu leaderBoardFilter"}>
          <DatePicker
            {...datePickerProps}
            data-elm-id={"searchFromDateInput"}
            id={"searchFromDate"}
            name={"fromDate"}
            selected={fromDate}
            placeholderText={"From Date"}
            onChange={setFromDate}
            maxDate={untilDate ? untilDate : new Date()}
          />
        </Menu.Item>

        <Menu.Item className={"searchMenu leaderBoardFilter"}>
          <DatePicker
            {...datePickerProps}
            data-elm-id={"searchUntilDateInput"}
            id={"searchUntilDate"}
            name={"untilDate"}
            selected={untilDate}
            placeholderText={"Until Date"}
            onChange={setUntilDate}
            minDate={fromDate}
            maxDate={new Date()}
          />
        </Menu.Item>

        {/* SORT BY  */}
        {showSortBy && (
          <Menu.Menu floated="right">
            {/* ORDER BY */}
            <Menu.Item className={"menuIcon"} icon onClick={changeOrder}>
              <Icon name={ascOrder ? "arrow up" : "arrow down"} />
            </Menu.Item>
          </Menu.Menu>
        )}
      </Menu>

      {loading && <Loader active size="large" />}
      {/* List of elements */}
      {content}
    </div>
  );
};

export const LocalInfinitySearch = React.memo(LocalSearchFC);
