import * as React from "react";
import { NumericFormat, PatternFormat } from "react-number-format";
import { useIntl } from "react-intl";
import { Header, Grid } from "semantic-ui-react";
import * as DTO from "../../interfaces/dtos";
import { FeeType, descriptors } from "./descriptors";
import { camelCase } from "lodash";
import "./styles.scss";
import { getIncome } from "../../utils";

interface OwnProps {
  coachIncome: number;
  interval: DTO.BillingPeriod;
  feeInformation: DTO.Fee[];
}

type Props = OwnProps; 

const FeesResumeFC: React.FC<Props> = ({
  feeInformation,
  coachIncome,
  interval,
}) => {
  const { formatMessage } = useIntl();
  const scFee = feeInformation.filter(
    (fee) => fee.code === "SOCIAL_COACH" || fee.code === "SOCIAL_COACH_PLUS"
  )[0];
  const tdcFee = feeInformation.filter((fee) => fee.code === "BANK")[0];
  const monthlyIncome = getIncome(coachIncome, scFee, tdcFee);

  return (
    // <Grid.Column className={"feeResumeContainer"} largescreen={5} computer={5} mobile={15} floated="left">
    <Grid.Row className={"feeResume"}>
      <Header as="h4">
        {formatMessage(
          { ...descriptors[FeeType.planDetails] },
          {
            interval: interval === DTO.BillingPeriod.MONTHLY ? "month" : "year",
          }
        )}
      </Header>
      <br />
      <Header as="h6" className={"simpleMargin"}>
        {formatMessage({ ...descriptors[FeeType.feesDesc] })}{" "}
      </Header>
      <Header as="h4" className={"simpleMargin"}>
        {tdcFee &&
          tdcFee.amount + formatMessage({ ...descriptors[FeeType.tdcFees] })}
      </Header>
      <Header as="h4" className={"simpleMargin"}>
        <NumericFormat
          className={"feesDesc"}
          decimalScale={2}
          displayType={"text"}
          thousandSeparator
          prefix="$"
          value={scFee ? scFee.amount : 0}
        />
        {formatMessage({ ...descriptors[FeeType.socialFees] })}
      </Header>

      <br />

      <div className={"greyBox"}>
        <Header as="h6" className={"simpleMargin"}>
          {formatMessage(
            { ...descriptors[FeeType.incomeDesc] },
            { intervalType: camelCase(interval) }
          )}
        </Header>
        <NumericFormat
          className={"totalIncome"}
          decimalScale={2}
          displayType={"text"}
          thousandSeparator
          prefix="$"
          value={monthlyIncome}
        />
      </div>
    </Grid.Row>
    // </Grid.Column>
  );
};

export const FeesResume = FeesResumeFC;
