import React, { useState, useCallback, ChangeEvent, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Icon, Popup } from "semantic-ui-react";

import { descriptors, PasswordValidationType } from "./descriptors";
import { simplePassword, validationSchema } from "../../../validations";

import "./styles.scss";

interface OwnProps {
  disabled: boolean;
  email: string;
  onChange: (value: string, errorMessage?: string) => void;
  onChangeConfirmPassword: (errorMessage?: string) => void;
}

/**
 * Represents a PasswordInput component.
 */
type Props = OwnProps
const PasswordInput: React.FC<Props> = ({
  disabled,
  email,
  onChange,
  onChangeConfirmPassword,
}) => {
  const { formatMessage } = useIntl();
  const [passwordFieldType, setPasswordFieldType] = useState<"text" | "password">("password");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const handlePassword = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const noSpacePwd = e.currentTarget.value.replace(/\s/g, "");
    setPassword(noSpacePwd);
    setConfirmPassword("");
    if (noSpacePwd === "") {
      onChange(e.currentTarget.value, formatMessage({ ...descriptors[PasswordValidationType.passwordMin] }));
    }
  }, []);

  const handleConfirmPassword = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const noSpacePwd = e.currentTarget.value.replace(/\s/g, "");
    setConfirmPassword(noSpacePwd);
    // if (noSpacePwd === "") {
    //   onChangeConfirmPassword(formatMessage({ ...descriptors[PasswordValidationType.confirmPasswordError] }));
    // }
  }, []);

  useEffect(() => {
    if (password !== "") {
      const validatePwd = async () => {
        try {
          await simplePassword.password.validateSync(password);
          await validationSchema.validateSync({ password, confirmPassword });
          onChange(password, undefined);
        } catch (e: any) {
          onChange(password, e.message);
        }
      };

      validatePwd();
    }
  }, [password]);

  useEffect(() => {
    if (confirmPassword !== "") {
      const validatePwd = async () => {
        try {
          await validationSchema.validateSync({ password, confirmPassword });
          onChangeConfirmPassword(undefined);
        } catch (e: any) {
          onChangeConfirmPassword(e.message);
        }
      };

      validatePwd();
    } else {
      onChangeConfirmPassword(formatMessage({ ...descriptors[PasswordValidationType.confirmPasswordError] }));
    }
  }, [confirmPassword]);

  const onTogglePassword = (): void => {
    if (passwordFieldType === "password") {
      setPasswordFieldType("text");
    } else {
      setPasswordFieldType("password");
    }
  };

  const passwordIcon = (): "eye" | "eye slash" => {
    return passwordFieldType === "password" ? "eye slash" : "eye";
  };

  return (
    <div>
      <Form.Group widths="equal" key={"checkoutFormEmailGroup"}>
        <Form.Input
          width={16}
          className={"disabledWithBorder"}
          key={"checkoutFormEmailInput"}
          data-elm-id={"checkoutFormEmailInput"}
          fluid
          value={email}
          id={"checkoutFormEmailInput"}
          type={"text"}
          name={"checkoutFormEmailInput"}
          disabled={true}
        />
      </Form.Group>
      <Form.Group widths="equal" key={"checkoutFormPasswordGroup"} className={"customPassword"}>
        <Form.Input
          width={16}
          key={"checkoutFormPasswordInput"}
          data-elm-id={"checkoutFormPasswordInput"}
          // label="Create Password - This will be used in the App"
          fluid
          icon={
            <Popup trigger={<Icon name={passwordIcon()} className={"infoIcon"} link onClick={onTogglePassword} />}>
              <FormattedMessage {...descriptors[PasswordValidationType.passwordWithNumber]} />
              <br />
              <FormattedMessage {...descriptors[PasswordValidationType.passwordWithCapital]} />
              <br />
              <FormattedMessage {...descriptors[PasswordValidationType.passwordWithSpecialCharacter]} />
              <br />
              <FormattedMessage {...descriptors[PasswordValidationType.passwordMin]} />
            </Popup>
          }
          value={password}
          id={"checkoutFormPasswordInput"}
          type={passwordFieldType}
          name={"password"}
          placeholder={formatMessage({ ...descriptors[PasswordValidationType.passwordPlaceholder] })}
          onChange={handlePassword}
          disabled={disabled}
        />
      </Form.Group>

      <Form.Group widths="equal" key={"checkoutFormConfirmPasswordGroup"} className={"customPassword"}>
        <Form.Input
          width={16}
          key={"checkoutFormConfirmPasswordInput"}
          data-elm-id={"checkoutFormConfirmPasswordInput"}
          fluid
          value={confirmPassword}
          id={"checkoutFormPasswordInput"}
          type={passwordFieldType}
          name={"confirmPassword"}
          placeholder={formatMessage({ ...descriptors[PasswordValidationType.confirmPasswordPlaceholder] })}
          onChange={handleConfirmPassword}
          disabled={disabled}
        />
      </Form.Group>
    </div>
  );
};

export const ProspectLoginInformationWithValidation = PasswordInput
