import { defineMessages } from "react-intl";

export enum ProfileContactInformationType {
  title = "ProfileContactInformationType.title",
  subtitle = "ProfileContactInformationType.subtitle",
  contactEmail = "ProfileContactInformationType.contactEmail",
  contactPhone = "ProfileContactInformationType.contactPhone",
}

export const en = {
  [ProfileContactInformationType.title]: "In-app Contact Information",
  [ProfileContactInformationType.subtitle]:
    "This is the contact information that will be displayed to players within the SocialCoach app",
  [ProfileContactInformationType.contactEmail]: "Email",
  [ProfileContactInformationType.contactPhone]: "Phone",
};

export const descriptors = defineMessages({
  [ProfileContactInformationType.title]: {
    id: ProfileContactInformationType.title,
    defaultMessage: en[ProfileContactInformationType.title],
    description: "title text",
  },
  [ProfileContactInformationType.subtitle]: {
    id: ProfileContactInformationType.subtitle,
    defaultMessage: en[ProfileContactInformationType.subtitle],
    description: "subtitle text",
  },
  [ProfileContactInformationType.contactEmail]: {
    id: ProfileContactInformationType.contactEmail,
    defaultMessage: en[ProfileContactInformationType.contactEmail],
    description: "ownersName text",
  },
  [ProfileContactInformationType.contactPhone]: {
    id: ProfileContactInformationType.contactPhone,
    defaultMessage: en[ProfileContactInformationType.contactPhone],
    description: "bank text",
  },
});
