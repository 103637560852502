import moment from "moment";
import { CoachPermission } from "../interfaces";

export const urlWithFormat = (url: string | undefined): string | undefined => {
  return url
    ? url.split('"').reduce((result, item) => {
        return `${result}${item}`;
      }, "")
    : url;
};

export function kFormatter(amount?: number): string {
  if (amount === undefined || amount === 0) {
    return "--";
  }
  const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
  /* tslint:disable:no-bitwise */
  const tier = (Math.log10(amount) / 3) | 0;
  /* tslint:enable:no-bitwise */
  // if zero, we don't need a suffix
  if (tier === 0) {
    return `${amount}`;
  }

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);
  // scale the number
  const scaled = amount / scale;
  // format number and add suffix
  return Number(scaled.toFixed(1)) + suffix;
}

export function moneyFormatter(
  amount?: number,
  prefix: string = "$",
  decimalCount: number = 0,
  decimal: "." | "," = ".",
  thousands: "." | "," = ","
): string {
  if (amount === undefined || amount === 0) {
    return "--";
  }

  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    const stringAmount = Math.abs(Number(amount) || 0).toFixed(decimalCount);

    const i = parseInt(stringAmount, 10).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      `${prefix}` +
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - Number(i))
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    return `${prefix}${amount}`;
  }
}

export function percentFormatter(amount?: string | number): string | undefined {
  if (amount === undefined || amount === "--") {
    return "--";
  }

  return `${amount}%`;
}

export function BEDate(date: string): string {
  return moment(date).format("YYYY-MM-DD");
}

export function UIDate(date: string): string {
  return moment(date).format("MM/DD/YYYY") === "Invalid date" ? date : moment(date).format("MM/DD/YYYY");
}

export function nowInUTC(): Date {
  const utcMoment = moment.utc();
  return utcMoment.toDate();
}

export function capitalizeFormatter(text: string): string {
  const capitalizedText = text.toLocaleLowerCase();
  return capitalizedText.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
}

export function toCoachPermission(permissions: any): CoachPermission[] {
  return Object.keys(permissions).map(componentId => {
    return {
      componentId: getPermissionKey(componentId),
      level: permissions[componentId],
    };
  });
}

export function getPermissionKey(componentId: string): string {
  switch (componentId) {
    case "pricePlan":
      return "PRICE_PLAN";
    case "digestEmail":
      return "DIGEST_EMAIL";
    case "settings":
      return "APP_SETTINGS";
    case "videoCatalyst":
      return "VIDEO_CATALYST";
    case "scriptLibrary":
      return "MANAGE_SCRIPT_LIBRARY";
    default:
      return componentId.toUpperCase();
  }
}

export function noSpaces(word?: string): string {
  if (!word) {
    return "";
  }
  return word.replace(/\s/g, "");
}

export function removeSpecialCharacters(word?: string): string {
  if (!word) {
    return "";
  }
  return noSpaces(word).replace(/[^\w]/gi, "").replace(/-/g, "").replace(/_/g, "");
}

export function toNumber(word?: string): string {
  if (!word) {
    return "";
  }
  return noSpaces(word).replace(/[^\w]/gi, "").replace(/[^\W]/gi, "").replace(/-/g, "").replace(/_/g, "");
}

export function isAnInvalidNumberFormat(key: string, type: string): boolean {
  const numberChars = ["+", "-"];
  const isAFloat = type === "float";
  return numberChars.includes(key) || (key === "." && !isAFloat);
}

export async function manageWebAddress(webAddressCheck?: string): Promise<string> {
  if (!webAddressCheck) {
    return "";
  }
  if (webAddressCheck.startsWith("http://")) {
    return webAddressCheck.replace("http://", "https://");
  } else if (webAddressCheck.startsWith("https://")) {
    return webAddressCheck;
  }
  // const result = await ping(`https://${webAddressCheck}`);
  return `https://${webAddressCheck}`; // : `http://${webAddressCheck}`;
}

export function ping(url: string): Promise<boolean> {
  return fetch(url)
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(true);
      }
      return Promise.resolve(false);
    })
    .catch(() => {
      return Promise.resolve(false);
    });
}
