import { KeywordElement, KeywordsType, TagElement } from "../interfaces";

export function tagsByPolicy(result: KeywordElement[], policy: KeywordsType): TagElement[] {
  return result
    .filter(keyword => keyword.policy === policy)
    .map((keyword, index) => {
      const k: TagElement = {
        index,
        displayValue: keyword.content.toLowerCase(),
        id: keyword.id,
      };
      return k;
    });
}
