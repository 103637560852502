import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import{ useIntl } from "react-intl";
import { Header, Grid, Icon } from "semantic-ui-react";

import { BillingPeriod, Plan, PriceElement } from "../../../interfaces/dtos";
import { BasicCard } from "../../BasicCard";
import { descriptors, CoachSummaryType } from "./descriptors";
import { CheckoutForm } from "../CheckoutForm";

import { AvailablePrice } from "./components/AvailablePrice";

import styled from "styled-components";
import "./styles.scss";

interface OwnProps {
  referredFromUserId: number;
  playerEmail: string;
  plan?: Plan;
  sharedLink: string;
  onPaymentSuccess?: () => void;
}

type Props = OwnProps & RouteComponentProps 

const EmailSpan = styled.span`
  color: #38abef;
`;

const CoachSummaryFC: React.FC<Props> = ({
  sharedLink,
  referredFromUserId,
  playerEmail,
  plan,
  onPaymentSuccess
}) => {
  const { formatMessage } = useIntl();
  const [selectedBillingPeriod, setSelectedBillingPeriod] = React.useState<BillingPeriod>(
    plan?.baseActive ? BillingPeriod.MONTHLY : BillingPeriod.YEARLY
  );

  const activeIntervals = React.useMemo(() => {
    const basePrice: PriceElement[] = plan?.baseActive
      ? [
          {
            price: plan?.price || 0,
            hasFreeTrial: plan?.hasFreeTrial,
            active: plan?.baseActive,
            interval: BillingPeriod.MONTHLY,
          },
        ]
      : [];

    const intervalPricesActives = plan?.intervalPrices ? plan?.intervalPrices!.filter(interval => interval.active) : [];

    return basePrice.concat(intervalPricesActives);

    //  return plan?.intervalPrices?.filter(interval => interval.active);
  }, [plan]);

  const planContent = (): JSX.Element => {
    const title = plan?.planName || "";
    // dvelasquez+tac+yearly+5@mahisoft.com
    return (
      <Grid className={"coachPlanContainer"} width={2}>
        <Grid.Row centered columns={2} className={"borderBox"}>
          <Grid className={"coachPlanTitleSection"}>
            <Grid.Row centered mobile={16} tablet={16} computer={12} largescreen={12}>
              <Header as="h3" textAlign={"center"}>
                {title}
              </Header>
            </Grid.Row>
          </Grid>
        </Grid.Row>

        <Grid.Row centered>
          {activeIntervals &&
            activeIntervals.map((intervalPrice, index) => {
              return (
                <AvailablePrice
                  key={"availablePrice" + intervalPrice!.interval + index}
                  price={intervalPrice?.price || 0}
                  interval={intervalPrice!.interval!}
                  selectedBillingPeriod={selectedBillingPeriod}
                  onSelectedPrice={setSelectedBillingPeriod}
                />
              );
            })}
        </Grid.Row>
        <Grid.Row centered>
          {formatMessage({ ...descriptors[CoachSummaryType.subscriptionFor] })}
          &nbsp;
          <EmailSpan>{playerEmail}</EmailSpan>
        </Grid.Row>
        <Grid.Row centered columns={2}>
          {plan && (
            <CheckoutForm
              plan={plan}
              referredFromUserId={referredFromUserId}
              email={playerEmail}
              onSuccess={onPaymentSuccess}
              sharedLink={sharedLink}
              selectedBillingPeriod={selectedBillingPeriod}
            />
          )}
        </Grid.Row>
      </Grid>
    );
  };

  const errorView = (
    <Header as="h3" textAlign={"center"}>
      {formatMessage({ ...descriptors[CoachSummaryType.noPlanError] })}
    </Header>
  );

  return (
    <div>
      <BasicCard
        title={""}
        size={"big"}
        content={plan ? planContent() : errorView}
        footer={
          <div className={"coachPlanFooterCentered"}>
            <Icon name="lock" />
            {formatMessage({ ...descriptors[CoachSummaryType.secureMessage] })}
          </div>
        }
      />
    </div>
  );
};

export const CoachSummary = withRouter(CoachSummaryFC)
