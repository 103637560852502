import styled from "styled-components";
import { Grid } from "semantic-ui-react";
import { theme } from "../../../../../styling/theme";

export const ThumbnailPreview = styled(Grid.Column)`
  height: 200px;
  width: 200px;
  border: 0.5px solid ${theme.borderColor};
  border-radius: 10px;
  background: url("${(props: any) => (props.thumbnailurl ? props.thumbnailurl : "")}") no-repeat center center;
  background-size: cover;
`;
