import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { useIntl, FormattedMessage } from "react-intl";
import { ResendForm } from "../ResendForm";
import { URLS } from "../../utils/routes";
import { Header } from "semantic-ui-react";
import { VerificationActions } from "../../interfaces/customTypes";
import { FloatedContainer } from "../../styling/baseStyle";

interface OwnProps {
  type?: VerificationActions;
}

type Props = OwnProps & RouteComponentProps;

const ResendPageFC: React.FC<Props> = ({ history, type = "ResendVerify" }) => {
  const [ready, setReady] = React.useState<boolean>(false);
  const [responseMessage, setResponseMessage] = React.useState<string>();

  const responseHandler = (message: string): void => {
    setReady(true);
    setResponseMessage(message);
    setTimeout(() => {
      history.push(URLS.login);
    }, 3000);
  };
  return (
    <FloatedContainer>
      <ResendForm successHandler={responseHandler} type={type} />
      {ready && (
        <Header as="h3" textAlign="center">
          {responseMessage}
        </Header>
      )}
    </FloatedContainer>
  );
};

export const ResendPage = withRouter(ResendPageFC);
