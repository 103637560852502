import * as React from "react";
import { Modal, Button, Header } from "semantic-ui-react";
import { keyBy } from "lodash";
import { PlayerPost, ReviewStatus, SocialNetworksId } from "../../../../../../../interfaces";

import { PostServices } from "../../../../../../../services";
import { SocialNetworksSection } from "../../../../../../../components";
import { useSocialNetworks } from "../../../../../../../reducers";

import { ComplianceBubbleDetails } from "./components/BubbleDetails";
import { modalReducer } from "./modalReducer";
import useDisplayData from "./hook";
import "./styles.scss";
import { ThumbnailPreview } from "../../styled";
interface OwnProps {
  opened: boolean;
  playerId: number;
  selectedPost: PlayerPost;
  thumbnail: string;
  closeHandler: () => void;
}

type Props = OwnProps;

const dataElmId = "CompliancePost";
/**
 * ComplianceModal Components
 */
const ComplianceModalFC: React.FC<Props> = ({ opened, selectedPost, closeHandler, playerId, thumbnail }) => {
  const [state, dispatch] = React.useReducer(modalReducer, { open: opened, loading: false });

  const { postReviewEvents, message, successSocialNetworks, changesRequested, postReviewEventsLabel, postedDate } =
    useDisplayData(selectedPost, playerId);

  const postSnNames = successSocialNetworks.map(sn => sn.socialNetworkId);
  const baseSns: SocialNetworksId[] = ["FB", "LI", "IG", "IG_STORY", "TW", "TT"];
  const snNamesFiltered = baseSns.filter(bsn => postSnNames.includes(bsn));
  const postSnIds = successSocialNetworks?.map(sn => sn.socialNetworkId);

  const { sns } = useSocialNetworks();

  React.useEffect(() => {
    if (opened) {
      dispatch({ type: "open" });
    } else {
      dispatch({ type: "close" });
    }
  }, [opened]);

  const markAsResolved = (): void => {
    PostServices.setReviewStatus(selectedPost.postId, ReviewStatus.CHANGES_SUBMITTED)
      .then(close)
      .catch(e => alert(e.message));
  };

  const close = () => {
    dispatch({ type: "close" });
    closeHandler();
  };

  const links = React.useMemo(() => {
    const linksArray = successSocialNetworks.map(sn => {
      return {
        socialNetworkId: sn.socialNetworkId,
        externalLink: sn.externalLink,
      };
    });

    const grouped = keyBy(linksArray, "socialNetworkId");
    let linksGrouped = {};
    Object.keys(grouped).forEach(sn => {
      linksGrouped = {
        ...linksGrouped,
        [sn]: grouped[sn]["externalLink"],
      };
    });
    return linksGrouped;
  }, [successSocialNetworks]);

  return (
    <Modal closeIcon size={"small"} open={state.open} onClose={close}>
      <Modal.Header>
        <Header.Content>
          <label>Compliance Review</label>
        </Header.Content>
      </Modal.Header>

      <Modal.Content>
        <div className="complianceContainer">
          <div className="message">
            <p className="postMessage">{message}</p>
            <br />
            {postedDate && <p className="date">{"Posted " + postedDate}</p>}
          </div>

          <ThumbnailPreview thumbnailurl={thumbnail} />
        </div>

        {postReviewEvents && (
          <div className="complianceChatSection">
            <Header as={"h5"}>{postReviewEventsLabel}</Header>
            <ComplianceBubbleDetails messages={postReviewEvents} />
          </div>
        )}

        {successSocialNetworks && (
          <div className="complianceSocialLinksSection">
            <Header as={"h5"}>Review Your Posts</Header>
            <p className="socialLinksMessage1">
              Click the highlighted icons to edit or delete the posts related to this review. If a link doesn't work,
              you may need to find the post manually.
            </p>
            <SocialNetworksSection size={"big"} actives={postSnIds} links={links} allSocialNetworks={sns} />
            <p className="socialLinksMessage2">
              When complete, tap the 'Mark as Resolved' button below to confirm that this change has been made.
            </p>
          </div>
        )}
      </Modal.Content>

      <Modal.Actions className="complianceActions">
        <Button data-elm-id={dataElmId + "dismissBtn"} secondary={true} disabled={state.loading} onClick={close}>
          Close
        </Button>

        {changesRequested && (
          <Button data-elm-id={dataElmId + "rePostBtn"} primary onClick={markAsResolved}>
            Mark as Resolved
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export const ComplianceModal = ComplianceModalFC;
