/* tslint:disable:no-namespace */
import axios from "axios";
import { SocialConfig } from "../../interfaces/customTypes/configProperties";
import { Oauth1Helper } from "../../providers/SocialNetworksCallbacks/authHelper";
import qs from "qs";

namespace TikTokNS {
  const REVOKE_URI = "https://open.tiktokapis.com/v2/oauth/revoke/";
  const ACCESS_TOK_URI_V2 = "https://open.tiktokapis.com/v2/oauth/token/";
  const ACCESS_TOK_URI = "https://open-api.tiktok.com/oauth/access_token";
  const PROFILE = "https://open.tiktokapis.com/v2/user/info/?fields=open_id,union_id,avatar_url,display_name";
  const TOKEN_GRANT_TYPE = "authorization_code";

  export const accessTokenV2 = (code: any, tiktokConfig: SocialConfig, secretKey: string): Promise<any> => {
    const verifier = Oauth1Helper.generatePKCEPair();
    return axios.post(
      ACCESS_TOK_URI_V2,
      {
        client_key: tiktokConfig.api_key,
        client_secret: secretKey,
        code: decodeURIComponent(code),
        grant_type: TOKEN_GRANT_TYPE,
        redirect_uri: tiktokConfig.callback,
        code_verifier: verifier,
      },
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    );
  };

  export const accessTokenV2New = (code: any, tiktokConfig: SocialConfig, secretKey: string): Promise<any> => {
    // const axios = require("axios");
    // const qs = require("qs");
    let data = qs.stringify({
      client_key: tiktokConfig.api_key,
      client_secret: secretKey,
      code,
      grant_type: TOKEN_GRANT_TYPE,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: ACCESS_TOK_URI_V2,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    return axios.request(config);

    // .then(response => {
    //   console.log(JSON.stringify(response.data));
    // })
    // .catch(error => {
    //   console.log(error);
    // });
  };

  export const accessToken = (
    code: any,
    state: any,
    tiktokConfig: SocialConfig,
    linkedinSecretKey: string
  ): Promise<any> => {
    let finalUrl = `${ACCESS_TOK_URI}`;
    finalUrl += "?client_key=" + tiktokConfig.api_key;
    finalUrl += "&client_secret=" + linkedinSecretKey; // '342dc77cd75e4aa240a6670e8e381ea9';
    finalUrl += "&code=" + code;
    finalUrl += "&grant_type=" + TOKEN_GRANT_TYPE;

    return axios({
      method: "post",
      url: finalUrl,
    });
  };

  export const requestProfile = async (token: string): Promise<any> => {
    const response = await axios.get(PROFILE, {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log("requestProfile", response.data);
    return response.data;
  };
}

export const Tiktok = TikTokNS;
