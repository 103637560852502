import { defineMessages } from "react-intl";

export enum LibraryType {
  cancelBtn = "LibraryType.cancelBtn",
  addPromptBtn = "LibraryType.addPromptBtn",
  uploadFilesBtn = "LibraryType.uploadFilesBtn",
  title = "LibraryType.title",
  createCampaignBtn = "LibraryType.createCampaignBtn",
  welcomePromptRequired = "LibraryType.welcomePromptRequired",
}

export const en = {
  [LibraryType.cancelBtn]: "Cancel",
  [LibraryType.addPromptBtn]: "Add Prompt",
  [LibraryType.createCampaignBtn]: "Create a campaign",
  [LibraryType.uploadFilesBtn]: "Upload Files",
  [LibraryType.welcomePromptRequired]: "A welcome prompt is required to complete your account.",
  [LibraryType.title]: "Library",
};

export const descriptors = defineMessages({
  [LibraryType.welcomePromptRequired]: {
    id: LibraryType.welcomePromptRequired,
    defaultMessage: en[LibraryType.welcomePromptRequired],
    description: "",
  },
  [LibraryType.cancelBtn]: {
    id: LibraryType.cancelBtn,
    defaultMessage: en[LibraryType.cancelBtn],
    description: "",
  },
  [LibraryType.addPromptBtn]: {
    id: LibraryType.addPromptBtn,
    defaultMessage: en[LibraryType.addPromptBtn],
    description: "",
  },
  [LibraryType.uploadFilesBtn]: {
    id: LibraryType.uploadFilesBtn,
    defaultMessage: en[LibraryType.uploadFilesBtn],
    description: "",
  },
  [LibraryType.createCampaignBtn]: {
    id: LibraryType.createCampaignBtn,
    defaultMessage: en[LibraryType.createCampaignBtn],
    description: "createCampaignBtn",
  },
  [LibraryType.title]: {
    id: LibraryType.title,
    defaultMessage: en[LibraryType.title],
    description: "title",
  },
});
