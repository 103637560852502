import { client as axios } from "./axiosClient";
import { Constants, catchServiceException } from "@socialcoach/services";
import * as DTO from "../interfaces/dtos";

const SUBSCRIPTIONS_SERVICE = `${Constants.API_URL}/subscriptions`;

export class SubscriptionServices {
  static async cancelSubscription(playerId: number, coachId: string): Promise<DTO.BasePlayerStatisticsResponse> {
    const response = await axios.delete<DTO.BasePlayerStatisticsResponse>(
      `${SUBSCRIPTIONS_SERVICE}/coaches/${coachId}/players/${playerId}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  static async reactivateSubscription(
    playerId: number,
    coachId: string
  ): Promise<DTO.EnterprisePlayerReactivationResponse> {
    const response = await axios.patch<DTO.EnterprisePlayerReactivationResponse>(
      `${SUBSCRIPTIONS_SERVICE}/coaches/${coachId}/players/${playerId}/reactivate`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  static async update(
    playerId: number,
    coachId: string,
    segmentationRequest: DTO.UpdatePlayerRequest
  ): Promise<DTO.UpdatePlayerResponse> {
    const response = await axios.put<DTO.UpdatePlayerResponse>(
      `${SUBSCRIPTIONS_SERVICE}/coaches/${coachId}/players/${playerId}`,
      {
        ...segmentationRequest,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async updatePricePlanTier(
    playerId: string | number,
    coachId: string | number,
    type: DTO.PricePlanTier
  ): Promise<void> {
    const result = await axios.put<void>(
      `${SUBSCRIPTIONS_SERVICE}/coaches/${coachId}/players/tier/${playerId}`,
      {
        tier: type,
      },
      {
        withCredentials: true,
      }
    );
    return result.data;
  }

  static async searchAllSubscriptions(
    searchTerm: string,
    page: number,
    size?: number
  ): Promise<DTO.Page<DTO.PlayerSearchResponse>> {
    const response = await axios.post<DTO.Page<DTO.PlayerSearchResponse>>(
      `${SUBSCRIPTIONS_SERVICE}/all-players?page=${page}&size=${size}&sort=playerId,DESC`,
      {
        searchTerm: searchTerm,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }
}
