import * as React from "react";
import { useIntl } from "react-intl";
import { Form, Loader, Radio, Input, Popup, Image } from "semantic-ui-react";
import DatePicker from "react-datepicker";

import {
  BasicFormProps,
  ConfigProperties,
  Prompt,
  SocialNetworksId,
  dateKey,
  socialIcons,
  socialNames,
  timeKey,
} from "../../../interfaces";

import { PostComponentType, descriptors } from "./descriptors";
import moment from "moment-timezone";

import "./styles.scss";
import "../../../styling/datepicker.scss";
import { RowBasicPadding, SocialGrid, SocialRowGrid, TitleLabel } from "../../../styling/baseStyle";
import { SocialNetworkIconButton } from "../../SocialNetworkIconButton";
import { useSocialNetworks } from "../../../reducers";
import {
  PSTimeZone,
  changeLocalDateToPST,
  filterPassedTime,
  isToday,
  nowInUTC,
  validAge,
  validateMessageLenghtInSocialNetworks,
} from "../../../utils";
import { ConfigContext } from "../../../contexts/appContexts";

interface OwnProps extends BasicFormProps {
  /**
   * Prompt Info
   */
  prompt?: Prompt;

  /**
   * Handle datePickers changes
   */
  handleSetFieldValue?: (field: string, value: any) => void;
}

type Props = OwnProps;

/**
 * Represents a Prompt content.
 */
const PostClass: React.FC<Props> = ({ handleSetFieldValue, messages, isSubmitting = false, values, prompt }) => {
  const config: ConfigProperties = React.useContext(ConfigContext);

  const { deliveryMode, socialNetworks } = values;
  const { formatMessage } = useIntl();
  const deliveryScheduled = deliveryMode === "SCHEDULED";

  const { sns } = useSocialNetworks();

  const errors = messages && messages.error && messages.error.length > 0;

  React.useEffect(() => {
    return () => {
      window.removeEventListener("loadedmetadata", () => {
        // Nothing
      });
    };
  }, []);

  const dateSection = (dateEditable: boolean): JSX.Element => {
    moment.tz.setDefault(PSTimeZone);

    const { deliveryDateUtc, deliveryTimeUtc } = values;
    const promptDate = deliveryDateUtc ? new Date(deliveryDateUtc) : undefined;
    const promptTime = deliveryTimeUtc ? new Date(deliveryTimeUtc) : undefined;

    if (promptDate !== undefined) {
      const deliveryDate = moment(deliveryDateUtc).tz(PSTimeZone);
      promptDate.setDate(deliveryDate.date());
      promptDate.setMonth(deliveryDate.month());
    }
    if (promptTime !== undefined) {
      const deliveryTime = moment(deliveryTimeUtc).tz(PSTimeZone);

      promptTime.setHours(deliveryTime.hours());
      promptTime.setMinutes(deliveryTime.minutes());
    }

    const yesterday = new Date();
    yesterday.setDate(new Date().getDate() - 1);

    return (
      <SocialGrid className={"xbigPaddingTop xxbigPaddingLeft"}>
        <SocialRowGrid>
          <SocialGrid.Column width={6} className={"noPadding"}>
            <DatePicker
              customInput={<Input icon="calendar alternate outline" />}
              data-elm-id={"postInputSelectedDate"}
              id={dateKey}
              name={dateKey}
              selected={promptDate}
              onChange={(date: any) => {
                if (date) {
                  handleSetFieldValue!(dateKey, changeLocalDateToPST(date));
                  if (isToday(date)) {
                    handleSetFieldValue!(timeKey, undefined);
                  }
                }
              }}
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={15}
              maxDate={validAge(yesterday, 1)}
              minDate={nowInUTC()}
              dateFormat="EE, MM/dd/yyyy"
              disabled={!dateEditable}
              autoComplete={"off"}
              placeholderText={formatMessage({ ...descriptors[PostComponentType.datePlaceholder] })}
            />
          </SocialGrid.Column>
          <SocialGrid.Column width={6} className={"noPadding"}>
            <DatePicker
              customInput={<Input icon="clock outline" />}
              data-elm-id={"postInputSelectedTime"}
              id={timeKey}
              name={timeKey}
              selected={promptTime}
              onChange={(date: any) => {
                if (date) {
                  handleSetFieldValue!(timeKey, changeLocalDateToPST(date));
                }
              }}
              showTimeSelect
              showTimeSelectOnly
              filterTime={(time: string | number | Date) => filterPassedTime(time, deliveryDateUtc, 1)}
              dateFormat="h:mm aa"
              autoComplete={"off"}
              disabled={!dateEditable}
              placeholderText={formatMessage({ ...descriptors[PostComponentType.timePlaceholder] })}
            />
            <span className={"timeZoneSpan"}>
              {formatMessage({ ...descriptors[PostComponentType.pstZoneMessage] })}
            </span>
          </SocialGrid.Column>
        </SocialRowGrid>
      </SocialGrid>
    );
  };

  /**
   * Return a custom Radio button with Black Style
   * @param id radio identifier
   * @param disabled if the radio is disabled
   * @param checked if the radio is on
   * @param onChange onChange handler
   * @param label Radio Label key on the descriptor file
   * @returns JSX.Element (Radio element)
   */
  const customRadioButton = (
    id: string,
    disabled: boolean | undefined,
    checked: boolean,
    onChange: (e: any, data: any) => void,
    label: string
  ): JSX.Element => {
    return (
      <Radio
        data-elm-id={`post${id}RadioBtn`}
        className={"blackRadio secondary rounded"}
        id={id}
        name={id}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        label={formatMessage({ ...descriptors[PostComponentType[label]] })}
      />
    );
  };

  const autoPostRadio = (): JSX.Element => {
    const disabled = prompt?.post?.media && prompt?.post?.media.length > 1;
    return customRadioButton(
      "isAutoPost",
      disabled,
      values.isAutoPost,
      (e: any, data: any) => {
        handleSetFieldValue!(`${data.id}`, data.checked);
      },
      "autoPost"
    );
  };

  const socialNetworkWithValidation = (sn: SocialNetworksId, index: number): JSX.Element => {
    const noMedia = !prompt?.post;
    const showNoMediaError = noMedia && (sn === "TT" || sn === "IG" || sn === "IG_STORY");
    const socialNetworksValidation = validateMessageLenghtInSocialNetworks(prompt!.message, config.post!!);

    const messageToLong =
      socialNetworksValidation.includes(socialNames["_" + sn]) ||
      (sn === "TW" && socialNetworksValidation.includes("Twitter"));

    let finalMessage = messageToLong
      ? formatMessage(
          {
            ...descriptors[PostComponentType.lengthMessageError],
          },
          {
            socialNetwork: socialNames["_" + sn],
          }
        )
      : "";

    if (showNoMediaError) {
      finalMessage =
        finalMessage +
        formatMessage(
          {
            ...descriptors[PostComponentType.noMediaError],
          },
          {
            socialNetwork: socialNames["_" + sn],
          }
        );
    }

    const isDisabled = showNoMediaError || messageToLong;

    return isDisabled ? (
      <Popup
        className="popUpErrorContent"
        content={finalMessage}
        trigger={<Image src={socialIcons["{" + sn + "}"]} className="disabledSocialNetwork" />}
      />
    ) : (
      socialNetworkButton(sn, index, isDisabled)
    );
  };

  const socialNetworkButton = (sn: SocialNetworksId, index: number, disabled: boolean): JSX.Element => {
    return (
      <SocialNetworkIconButton
        disabled={disabled}
        withPadding={false}
        size={"noMargin"}
        key={"post_sn_icon_" + index}
        active={socialNetworks ? socialNetworks[sn] : false}
        networkId={sn}
        onClickHandler={checked => {
          const snUpdated = { ...socialNetworks, [sn]: checked };
          handleSetFieldValue!("socialNetworksCounter", Object.values(snUpdated).filter(Boolean).length);
          handleSetFieldValue!("socialNetworks", { ...socialNetworks, [sn]: checked });
        }}
      />
    );
  };

  /*
   * Auto Post or Push Notification
   */
  const deliveryType = (): JSX.Element => {
    return (
      <SocialGrid.Row className={"xbigPaddingTop"}>
        <SocialGrid.Column width={10}>
          <RowBasicPadding>
            <TitleLabel>{formatMessage({ ...descriptors[PostComponentType.deliveryTypeLabel] })}</TitleLabel>
            <br />
            <label className="descriptionLabel">
              {formatMessage({ ...descriptors[PostComponentType.deliveryTypeDescription] })}
            </label>
          </RowBasicPadding>

          <SocialRowGrid className={"bigPaddingTop"} leftpadding={15} key={"post_row_auto_post"}>
            {prompt?.post?.media && prompt?.post?.media.length > 1 ? (
              <Popup
                content={formatMessage({ ...descriptors[PostComponentType.autopostNoAvailableDescription] })}
                trigger={autoPostRadio()}
              />
            ) : (
              autoPostRadio()
            )}
            {values.isAutoPost && (
              <>
                <br />
                <label className="descriptionLabel xbigPaddingLeft">
                  {formatMessage({ ...descriptors[PostComponentType.socialNetworksDescription] })}
                </label>
              </>
            )}
            {values.isAutoPost && (
              <SocialGrid className={"socialSection postPageForm"}>
                <RowBasicPadding className={"socialIconsSection"}>
                  {sns.map((sn, index) => socialNetworkWithValidation(sn, index))}
                </RowBasicPadding>
              </SocialGrid>
            )}
          </SocialRowGrid>

          <SocialRowGrid key={"post_row_push_notification"}>
            {customRadioButton(
              "isPushPost",
              false,
              !values.isAutoPost,
              (e: any, data: any) => {
                handleSetFieldValue!("isAutoPost", false);
              },
              "noAutoPost"
            )}
          </SocialRowGrid>
        </SocialGrid.Column>
      </SocialGrid.Row>
    );
  };

  const deliveryModeSection = (): JSX.Element => {
    const deliveryStatus = [
      {
        key: 0,
        text: "postNow",
        value: "IMMEDIATE",
        selected: deliveryMode === "IMMEDIATE",
      },
      {
        key: 1,
        text: "postScheduled",
        value: "SCHEDULED",
        selected: deliveryMode === "SCHEDULED",
      },
    ];

    return (
      <SocialGrid.Row className={"xbigPaddingTop"}>
        <RowBasicPadding>
          <TitleLabel>{formatMessage({ ...descriptors[PostComponentType.deliveryLabel] })}</TitleLabel>
          <br />
          <label className="descriptionLabel">
            {formatMessage({ ...descriptors[PostComponentType.deliveryModeDescription] })}
          </label>
        </RowBasicPadding>

        <SocialGrid columns={1} className={"bigPaddingTop xbigPaddingLeft mediumPaddingBottom"}>
          {deliveryStatus.map((button, index) => {
            return (
              <SocialGrid.Row key={"post_basic_column" + index}>
                {customRadioButton(
                  button.value,
                  false,
                  button.selected,
                  (e: any, data: any) => {
                    handleSetFieldValue!("deliveryMode", data.name);
                  },
                  button.text
                )}
                {deliveryScheduled && button.value === deliveryMode && dateSection(true)}
              </SocialGrid.Row>
            );
          })}
        </SocialGrid>
      </SocialGrid.Row>
    );
  };

  return (
    <Form size="large" error={errors} className={"postComponentContainer"}>
      {isSubmitting && <Loader active size="large" />}
      <div key={"postFormDiv"} className={"formDiv"}>
        {deliveryType()}
        {deliveryModeSection()}
      </div>
    </Form>
  );
};

export const PostComponent = PostClass;
