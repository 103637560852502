import { defineMessages } from "react-intl";

export enum SelectedCardType {
  createdTitle = "SelectedCardType.createdTitle",
}

export const en = {
  [SelectedCardType.createdTitle]: "Created: ",
};

export const descriptors = defineMessages({
  [SelectedCardType.createdTitle]: {
    id: SelectedCardType.createdTitle,
    defaultMessage: en[SelectedCardType.createdTitle],
    description: "-",
  },
});
