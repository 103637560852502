import { defineMessages } from "react-intl";

export enum AddPlayerType {
  email = "AddPlayerType.email",
  firstName = "AddPlayerType.firstName",
  lastName = "AddPlayerType.lastName",
  region = "AddPlayerType.region",
  branch = "AddPlayerType.branch",
  state = "AddPlayerType.state",
  costCenter = "AddPlayerType.costCenter",
  complianceDisclaimer = "AddPlayerType.complianceDisclaimer",

  submit = "AddPlayerType.submit",
  title = "AddPlayerType.title",
  segmentationTitle = "AddPlayerType.segmentationTitle",
}

export const en = {
  [AddPlayerType.email]: "Email Address",
  [AddPlayerType.firstName]: "First Name",
  [AddPlayerType.lastName]: "Last Name",
  [AddPlayerType.branch]: "Branch (optional)",
  [AddPlayerType.state]: "State (optional)",
  [AddPlayerType.region]: "Region (optional)",
  [AddPlayerType.costCenter]: "Cost Center (optional)",
  [AddPlayerType.complianceDisclaimer]: "Compliance Disclaimer (optional)",
  [AddPlayerType.submit]: "Add Player",
  [AddPlayerType.title]: "Personal Information",
  [AddPlayerType.segmentationTitle]: "Segmentation",
};

export const descriptors = defineMessages({
  [AddPlayerType.email]: {
    id: AddPlayerType.email,
    defaultMessage: en[AddPlayerType.email],
    description: "Email address",
  },
  [AddPlayerType.branch]: {
    id: AddPlayerType.branch,
    defaultMessage: en[AddPlayerType.branch],
    description: "",
  },
  [AddPlayerType.state]: {
    id: AddPlayerType.state,
    defaultMessage: en[AddPlayerType.state],
    description: "",
  },
  [AddPlayerType.costCenter]: {
    id: AddPlayerType.costCenter,
    defaultMessage: en[AddPlayerType.costCenter],
    description: "",
  },
  [AddPlayerType.firstName]: {
    id: AddPlayerType.firstName,
    defaultMessage: en[AddPlayerType.firstName],
    description: "firstName text",
  },
  [AddPlayerType.lastName]: {
    id: AddPlayerType.lastName,
    defaultMessage: en[AddPlayerType.lastName],
    description: "lastName text",
  },
  [AddPlayerType.region]: {
    id: AddPlayerType.region,
    defaultMessage: en[AddPlayerType.region],
    description: "",
  },
  [AddPlayerType.complianceDisclaimer]: {
    id: AddPlayerType.complianceDisclaimer,
    defaultMessage: en[AddPlayerType.complianceDisclaimer],
    description: "complianceDisclaimer",
  },
  [AddPlayerType.submit]: {
    id: AddPlayerType.submit,
    defaultMessage: en[AddPlayerType.submit],
    description: "",
  },
  [AddPlayerType.title]: {
    id: AddPlayerType.title,
    defaultMessage: en[AddPlayerType.title],
    description: "",
  },
  [AddPlayerType.segmentationTitle]: {
    id: AddPlayerType.segmentationTitle,
    defaultMessage: en[AddPlayerType.segmentationTitle],
    description: "segmentationTitle",
  },
});
