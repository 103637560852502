import * as React from "react";
import { Dropdown, DropdownItemProps, Form, Grid, Icon, Input, Radio } from "semantic-ui-react";
import "./styles.scss";
import { useEffect, useMemo, useState } from "react";
import { VideoPreferences, VideoPreferencesMusicGenre, VideoPreferencesTone } from "../../../../../interfaces";
import { CustomModal } from "../../../../CustomModal";
import { HexColorPicker } from "react-colorful";
import { VideoProjectServices } from "../../../../../services";

interface OwnProps {
  playerId: number;
  updatePrefs: (prefs: VideoPreferences) => void;
}

interface ToggleSetting {
  label: string;
  checked: boolean;
  id: string;
}

type Props = OwnProps;

export const StylePreferencesForm: React.FC<Props> = ({ playerId, updatePrefs }) => {
  const [showColorModal, setShowColorModal] = useState(false);
  const [newColor, setNewColor] = useState("#3aabef");

  const defaultPrefs: VideoPreferences = {
    broll: true,
    captionColors: "",
    captions: true,
    emojis: true,
    music: true,
    musicGenre: undefined,
    tone: undefined,
  };
  const [prefs, setPrefs] = useState<VideoPreferences>(defaultPrefs);

  useEffect(() => {
    const loadPrefs = async () => {
      if (playerId) {
        const latestPrefs = await VideoProjectServices.getLatestVideoProjectPreferences(playerId);
        if (latestPrefs && Object.keys(latestPrefs).length > 0) setPrefs(latestPrefs);
        else setPrefs(defaultPrefs);
      } else {
        setPrefs(defaultPrefs);
      }
    };

    loadPrefs().catch(e => console.error(e));
  }, [playerId]);

  useEffect(() => {
    updatePrefs(prefs);
  }, [prefs]);

  const existingColors = useMemo(() => {
    const colorsRaw = prefs.captionColors || "";
    const colors = colorsRaw
      .split(",")
      .map(s => s.trim())
      .filter(c => c && c.length > 0);
    return [...new Set(colors)];
  }, [prefs.captionColors]);

  const prefsToggles: ToggleSetting[] = [
    {
      label: "Include captions",
      checked: true,
      id: "captions",
    },
    {
      label: "Include emojis",
      checked: true,
      id: "emojis",
    },
    {
      label: "Include video clips",
      checked: true,
      id: "broll",
    },
    {
      label: "Include music",
      checked: true,
      id: "music",
    },
  ];

  const toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, (text: string) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase());
  };

  const musicGenreOptions: DropdownItemProps[] = Object.values(VideoPreferencesMusicGenre).map(genre => {
    return {
      key: genre,
      text: toTitleCase(genre),
      value: genre,
    };
  });

  const toneOptions: DropdownItemProps[] = Object.values(VideoPreferencesTone).map(tone => {
    return {
      key: tone,
      text: toTitleCase(tone),
      value: tone,
    };
  });

  const addColor = () => {
    if (newColor) {
      const newColorList = [...existingColors, newColor];
      setPrefs({
        ...prefs,
        captionColors: newColorList.join(","),
      });
    }
    setShowColorModal(false);
  };

  const removeColor = (color: string) => {
    if (color) {
      setPrefs({
        ...prefs,
        captionColors: existingColors.filter(c => c != color).join(","),
      });
    }
  };

  const toggleSettingSection = (setting: ToggleSetting) => {
    return (
      <Form.Group inline key={`prefs-${setting.id}`} className={"prefRow"}>
        <label className="small">{setting.label}</label>
        <Radio
          toggle
          className={"settingsLevelLabel prefToggle"}
          onChange={(e: any, data: any) => {
            setPrefs({
              ...prefs,
              [data.name]: data.checked,
            });
          }}
          name={setting.id}
          checked={prefs[setting.id]}
        />
      </Form.Group>
    );
  };

  const musicGenreSection = () => {
    return (
      <Form.Group inline className={"prefRow"}>
        <label className={"small"}>Music genre</label>
        <Dropdown
          fluid
          clearable
          selectOnNavigation={false}
          wrapSelection={false}
          selectOnBlur={false}
          placeholder={"(Optional)"}
          className={"prefDropdown"}
          onChange={(_, data) => {
            const value = data.value ? (data.value as VideoPreferencesMusicGenre) : undefined;
            setPrefs({
              ...prefs,
              musicGenre: value,
            });
          }}
          value={prefs.musicGenre}
          selection
          options={musicGenreOptions}
        />
      </Form.Group>
    );
  };

  const toneSection = () => {
    return (
      <Form.Group inline className={"prefRow"}>
        <label className={"small"}>Overall tone</label>
        <Dropdown
          fluid
          clearable
          selectOnNavigation={false}
          wrapSelection={false}
          selectOnBlur={false}
          placeholder={"(Optional)"}
          className={"prefDropdown"}
          onChange={(_, data) => {
            const value = data.value ? (data.value as VideoPreferencesTone) : undefined;
            setPrefs({
              ...prefs,
              tone: value,
            });
          }}
          value={prefs.tone}
          selection
          options={toneOptions}
        />
      </Form.Group>
    );
  };

  const colorSection = () => {
    return (
      <Form.Group className={"prefRow colorSection"}>
        <Grid className={"noPadding"}>
          <Grid.Row columns={2} className={"noPadding"}>
            <Grid.Column className={"noPadding"}>
              <label className={"small"}>Custom caption colors</label>
            </Grid.Column>
            <Grid.Column className={"noPadding"}>
              {existingColors.length < 2 && <a onClick={() => setShowColorModal(true)}>+ Add</a>}
            </Grid.Column>
          </Grid.Row>
          {existingColors.map(color => {
            return (
              <Grid.Row className={"noPadding colorRow"}>
                <div className={"colorPreview"} style={{ backgroundColor: color }} />
                <label className={"colorLabel"}>{color}</label>
                <Icon
                  name={"trash alternate outline"}
                  onClick={() => {
                    removeColor(color);
                  }}
                />
              </Grid.Row>
            );
          })}
        </Grid>
      </Form.Group>
    );
  };

  return (
    <Form.Field className={"stylePrefsForm"}>
      <div className="smallPaddingBottom">
        <label>Style Preferences</label>
      </div>
      <Form.Group grouped key={"preferences"}>
        {prefsToggles.map(setting => {
          return toggleSettingSection(setting);
        })}

        {prefs.music && musicGenreSection()}

        {toneSection()}

        {prefs.captions && colorSection()}
      </Form.Group>

      {showColorModal && (
        <CustomModal
          title="Add Caption Color"
          acceptLabel="Add Color"
          acceptHandler={addColor}
          rejectHandler={() => setShowColorModal(false)}
          size={"mini"}
        >
          <div className={"colorPickerModal"}>
            <HexColorPicker color={newColor} onChange={setNewColor} className={"colorPicker"} />
            <Form.Input
              className={"colorPickerInput"}
              value={newColor}
              onChange={(e, data) => setNewColor(data.value)}
              icon={<div className={"colorPreview"} color={newColor} style={{ backgroundColor: newColor }} />}
            />
          </div>
        </CustomModal>
      )}
    </Form.Field>
  );
};
