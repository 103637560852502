import * as React from "react";
import * as DTO from "../../../interfaces";

import { EmptyView } from "../../EmptyView";
import { PlayerVideoProjectSearchSection } from "../../PlayerVideoProjectSearchSection";

interface OwnProps {
  videoProjectList: DTO.BaseVideoProject[];
  players: DTO.PlayerResumeResponse[];
  coachId: string;
}

type Props = OwnProps 

export const VideoCatalystSearchList = ({ videoProjectList, players }: Props) => {
  const [playersHash, setPlayersHash] = React.useState<{ [key: string]: string }>({});
  const [withCategorizedVideoProjects, setWithCategorizedVideoProjects] = React.useState<boolean>(false);

  React.useEffect(() => {
    const convertToHash = (result: DTO.PlayerResumeResponse[]): { [key: string]: string } => {
      let hash = {};
      result.map(player => {
        hash = {
          ...hash,
          [player!!.playerId!]: player.fullName,
        };
      });

      return hash;
    };

    setPlayersHash(convertToHash(players));
  }, [players]);

  const videoProjectsFilteredByPlayerId = (playerId: number): DTO.BaseVideoProject[] => {
    const filtered = videoProjectList.filter(vp => {
      return vp.playerId === playerId;
    });
    if (!withCategorizedVideoProjects && filtered.length > 0) {
      setWithCategorizedVideoProjects(true);
    }
    return filtered;
  };

  const videoProjectSection = (player: DTO.PlayerResumeResponse): JSX.Element | undefined => {
    const playerId = player.playerId;
    const videoProjects: DTO.BaseVideoProject[] = videoProjectsFilteredByPlayerId(playerId);

    if (videoProjects.length === 0) {
      return undefined;
    }
    return (
      <PlayerVideoProjectSearchSection
        key={"player_video_project_section_" + playerId}
        player={player}
        videoProjectList={videoProjects}
        totalElements={videoProjects.length}
        name={playersHash[playerId]}
        pageable={false}
      />
    );
  };

  const showEmptySection = !withCategorizedVideoProjects;
  return (
    <div className="campaignListContainer">
      {players.map(player => videoProjectSection(player))}
      {showEmptySection && <EmptyView text={"You have no video projects"} />}
    </div>
  );
}
