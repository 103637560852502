import { useState, useEffect } from "react";
import { SettingsServices } from "../../services";
import { CoachAppSetting, CoachSettings } from "../../interfaces";

const useCoachSettings = (coachId: string, displayPostFailedSetting: boolean, displayPlayerReportSetting: boolean) => {
  const [coachAppSettings, setCoachAppSettings] = useState<CoachAppSetting | undefined>(undefined);
  const [settingLoading, setSettingLoading] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<{ [key: string]: string }>({});

  const handleSettingsChange = (e: any, data: any) => {
    setPermissions(p => {
      return {
        ...p,
        [data.name]: data.checked ? "ACTIVE" : "INACTIVE",
      };
    });
  };

  const findPermissionStatus = (permission: CoachSettings, defaultStatus: string = "ACTIVE"): string => {
    return coachAppSettings!!.settings.find(s => s.appSettingId === permission)?.status || defaultStatus;
  };

  useEffect(() => {
    if (coachAppSettings) {
      setPermissions({
        stickers: findPermissionStatus(CoachSettings.CUSTOM_STICKERS),
        customCampaigns: findPermissionStatus(CoachSettings.CUSTOMIZABLE_RANDOM_CAMPAIGN),
        postCustomImages: findPermissionStatus(CoachSettings.POST_CUSTOM_IMAGES),
        postCustomMessage: findPermissionStatus(CoachSettings.POST_CUSTOM_MESSAGE),
        playerStickers: findPermissionStatus(CoachSettings.PLAYER_STICKERS),
        postInFB: findPermissionStatus(CoachSettings.POST_IN_FB),
        postInIG: findPermissionStatus(CoachSettings.POST_IN_IG),
        postInTW: findPermissionStatus(CoachSettings.POST_IN_TW),
        postInTT: findPermissionStatus(CoachSettings.POST_IN_TT),
        postInLI: findPermissionStatus(CoachSettings.POST_IN_LI),
        postInIGStory: findPermissionStatus(CoachSettings.POST_IN_IG_STORY, "INACTIVE"),
        campaignDefaultSn: findPermissionStatus(CoachSettings.CAMPAIGN_DEFAULT_SN),
        playerHashtags: findPermissionStatus(CoachSettings.PLAYER_HASHTAG),
        playerCustomSticker: findPermissionStatus(CoachSettings.PLAYER_CUSTOM_STICKERS),
        playerNotifyFailedPost: displayPostFailedSetting
          ? findPermissionStatus(CoachSettings.PLAYER_NOTIFY_FAILED_POST, "INACTIVE")
          : "INACTIVE",
        summaryReportPlayers: displayPlayerReportSetting
          ? findPermissionStatus(CoachSettings.SUMMARY_REPORT_PLAYER, "INACTIVE")
          : "INACTIVE",
        recurringOnboardingNotifications: findPermissionStatus(
          CoachSettings.RECURRING_ONBOARDING_NOTIFICATIONS,
          "INACTIVE"
        ),
        recurringSocialConnectNotifications: findPermissionStatus(
          CoachSettings.RECURRING_SOCIAL_CONNECT_NOTIFICATIONS,
          "INACTIVE"
        ),
        complianceDisclaimer: findPermissionStatus(CoachSettings.COMPLIANCE_DISCLAIMER, "INACTIVE"),
        notifyPlayerVideoProjectEdited: findPermissionStatus(
          CoachSettings.NOTIFY_PLAYER_VIDEO_PROJECT_EDITED,
          "INACTIVE"
        ),
      });
    }
  }, [coachAppSettings, displayPostFailedSetting, displayPlayerReportSetting]);

  useEffect(() => {
    setSettingLoading(true);
    const getSettings = async () => {
      SettingsServices.getAppSettingsByCoachId(coachId)
        .then(setCoachAppSettings)
        .finally(() => setSettingLoading(false));
    };

    getSettings();
  }, [coachId]);

  return { settingLoading, permissions, handleSettingsChange };
};

export default useCoachSettings;
