import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { AppContext, PlayerAppContext } from "../../../providers";
import { URLS, useCheckMobileScreen } from "../../../utils";

import { FloatedContainer } from "../../../styling/baseStyle";

import { FirstWebAddressForm } from "../../FormikForms/FirstWebAddress";
import { PlayerSignUpStep, PlayersServices } from "../../../services";
import { BubbleDetails } from "../../../components/Players/FirstLogin/BubbleDetails";
import { FirstWebAddressMobileForm } from "../../FormikForms/FirstWebAddressMobile";

const IntegratedFirstWebAddressPage: React.FC<RouteComponentProps> = ({ history }) => {
  const { userContext, setUserContext } = React.useContext(AppContext);
  const { coachInformation, setCoachInformation } = React.useContext(PlayerAppContext);

  React.useEffect(() => {
    if (userContext?.signUpStep! === PlayerSignUpStep[PlayerSignUpStep.PLAYER_BUSINESS_DATA]) {
      window.location.assign(URLS.player.dashboard);
    }
    // Get Coach Information
    PlayersServices.getCoachData(userContext!.player!.id!).then(coachInfo => {
      setCoachInformation(coachInfo);
    });
  }, []);

  const successAction = (webAddress: string) => {
    setUserContext({
      ...userContext!,
      player: {
        ...userContext?.player!,
        webAddress,
      },
    });
    // First questions were removed
    // history.push(URLS.player.firstQuestions);
    history.push(URLS.player.firstSocialNetworks);
  };
  const content = (
    <FloatedContainer>
      <FirstWebAddressForm playerId={userContext?.player?.id} successHandler={successAction} />
      {coachInformation && (
        <BubbleDetails
          messages={[
            {
              message:
                "What website would you like to drive traffic to? This will likely be your business website. \n\n" +
                "I’ve created a Short Link for you below. This link will act as a proxy for your website’s URL. It’s meant to be short so it will easily fit in your social posts.\n\n" +
                "We will add this to all your posts to track engagement. Feel free to customize it, but keep it short and simple.",
            },
          ]}
          currentPosition={0}
          coachInfo={coachInformation!.coach}
        />
      )}
    </FloatedContainer>
  );

  const mobileContent = (
    <FloatedContainer>
      <FirstWebAddressMobileForm
        playerId={userContext?.player?.id}
        coachInformation={coachInformation?.coach}
        successHandler={successAction}
      />
    </FloatedContainer>
  );

  return useCheckMobileScreen() ? mobileContent : content;
};

export const FirstWebAddressPage = withRouter(IntegratedFirstWebAddressPage);
