import { SocialNetworksId } from "../../interfaces";

export enum AlertType {
  POST_FAILED = "POST_FAILED",
  SN_ERROR = "SN_ERROR",
  CAMPAIGN_ABOUT_TO_EXPIRED = "CAMPAIGN_ABOUT_TO_EXPIRED",
  PLAYER_NO_POSTING = "PLAYER_NO_POSTING",
}

export enum AlertStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}

export enum SourceType {
  PROMPT = "PROMPT",
  VIDEO_PROJECT = "VIDEO_PROJECT",
  NONE = "NONE",
}

export interface Alert {
  alertId: number;
  playerId: number;
  playerUsername: string;
  coachId: string;
  type: AlertType;
  status: AlertStatus;
  postId: number;
  campaignId: number;
  socialNetworkId: SocialNetworksId;
  detail: string;
  lastPromptScheduledDate?: string;
  createdDate: string;
}

export interface AlertResponse {
  alertId: number;
  playerId: number;
  playerUsername: string;
  coachId: string;
  type: AlertType;
  status: AlertStatus;
  postId: number;
  sourceId?: number;
  sourceType?: SourceType;
  campaignId: number;
  campaignName: string;
  lastPromptScheduledDate: string;
  socialNetworkId: SocialNetworksId;
  detail: string;
  numberOfPlayers: number;
  createdDate: string;
  campaignAlerts: Alert[];
}
