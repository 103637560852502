import * as React from "react";
import { Icon, List, InputOnChangeData, Input } from "semantic-ui-react";
import "./styles.scss";

const MAX_LENGTH = 120;
const MIN_LENGTH = 1;

interface Props {
  dataElmId: string;
  headerType: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  statatement: string;
  index: number;
  maxLength?: number;
  handleChangeValue: (index: number, value: string) => void;
  handleDeleteValue: (index: number) => void;
}

const StatementOptionFC: React.FC<Props> = ({
  maxLength = MAX_LENGTH,
  statatement,
  index,
  handleChangeValue,
  handleDeleteValue,
  dataElmId,
}) => {
  const [inputValue, setInputValue] = React.useState(statatement);

  const validStatement = (): boolean => {
    return inputValue.replace(/\s+/g, "").length >= MIN_LENGTH;
  };

  const handleSave = (): void => {
    if (validStatement() || inputValue.length > 0) {
      handleChangeValue(index, inputValue);
    } else {
      alert("Invalid statement name");
      return;
    }
  };

  const handleDelete = (): void => {
    handleDeleteValue(index);
  };

  const handleChange = (value: string): void => {
    if (!maxLength || value.length < maxLength) {
      setInputValue(value);
    }
  };

  return (
    <List.Item className={"offerItem"} key={`offer${index}${statatement}`}>
      <List.Content floated="left" width={9} className={"editingSection"}>
        <Input
          fluid
          name={dataElmId}
          placeholder={"New offer description..."}
          data-elm-id={dataElmId}
          value={inputValue}
          id={dataElmId}
          type="text"
          onBlur={handleSave}
          onChange={(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
            handleChange(data.value);
          }}
        />
      </List.Content>

      <div className={"minusIconSection"}>
        <Icon onClick={handleDelete} className={"minusIcon"} name="minus" floated="right" />
      </div>
    </List.Item>
  );
};

export const StatementOption = StatementOptionFC;
