import * as DTO from "../../../interfaces";
import { getStatusText } from "../../../utils";

export const selectOptions = [
  {
    key: DTO.CoachStatuses.ACTIVE,
    value: DTO.CoachStatuses.ACTIVE,
    text: getStatusText(DTO.CoachStatuses.ACTIVE),
  },
  {
    key: DTO.CoachStatuses.INACTIVE,
    value: DTO.CoachStatuses.INACTIVE,
    text: getStatusText(DTO.CoachStatuses.INACTIVE),
  },
  {
    key: DTO.CoachStatuses.PENDING_FOR_APPROVAL,
    value: DTO.CoachStatuses.PENDING_FOR_APPROVAL,
    text: getStatusText(DTO.CoachStatuses.PENDING_FOR_APPROVAL),
  },
];
