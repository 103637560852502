import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { PlayerSignUpStep, PlayersServices, SocialCoachSessionService } from "../../../services";
import { saveImageToFirebase, URLS } from "../../../utils";
import { AppContext, PlayerAppContext } from "../../../providers";
import { FirstLoginPicture, BubbleDetails } from "../../../components";
import { ConfigContext } from "../../../contexts/appContexts";

import { FloatedContainer } from "../../../styling/baseStyle";

type Props = RouteComponentProps;

const IntegratedPlayerPicture: React.FC<Props> = ({ history }) => {
  const { userContext, setUserContext } = React.useContext(AppContext);
  const { firebase } = React.useContext(ConfigContext);

  const { coachInformation, setCoachInformation } = React.useContext(PlayerAppContext);

  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [playerPicture, setPlayerPicture] = React.useState<File | undefined>();

  React.useEffect(() => {
    if (userContext?.signUpStep! === PlayerSignUpStep[PlayerSignUpStep.PLAYER_BUSINESS_DATA]) {
      window.location.assign(URLS.player.dashboard);
    }
    // Get Coach Information
    if (!coachInformation) {
      PlayersServices.getCoachData(userContext!.player!.id!).then(coachInfo => {
        setCoachInformation(coachInfo);
      });
    }
  }, []);

  const nextAction = (): void => {
    history.push(URLS.player.firstWebAddress);
  };

  const chatMessages = [
    {
      title: "Hey " + userContext?.profile.firstName + "!",
      message: "I'm sure you have a great picture. Let's upload it!",
    },
  ];
  return (
    <FloatedContainer>
      <FirstLoginPicture
        onChangeImage={setPlayerPicture}
        coachInfo={coachInformation?.coach}
        chatMessages={chatMessages}
        handleSkip={nextAction}
        handleSubmit={async () => {
          if (playerPicture !== undefined && userContext) {
            setLoading(true);
            const playerPictureUrl = await saveImageToFirebase(
              firebase,
              userContext?.account?.userId,
              playerPicture,
              "main"
            );

            SocialCoachSessionService.updatePlayer({
              firstName: userContext!.profile!.firstName!,
              lastName: userContext!.profile!.lastName!,
              birthday: userContext!.profile!.birthday!,
              phone: userContext!.profile!.phone!,
              photoUrl: playerPictureUrl,
            })
              .then(response => {
                setUserContext({
                  ...userContext!,
                  profile: response!.profile,
                });
              })
              .finally(() => {
                setLoading(false);
                nextAction();
              });
          } else {
            nextAction();
          }
        }}
        isSubmitting={isLoading}
        values={{
          photoUrl: userContext?.player?.photoUrl,
        }}
      />

      {coachInformation && <BubbleDetails messages={chatMessages} coachInfo={coachInformation?.coach} />}
    </FloatedContainer>
  );
};

export const SetPlayerPicture = withRouter(IntegratedPlayerPicture);
