import { capitalize } from "lodash";
import { VideoPreferences } from "../interfaces";

interface StylePreferencesOptions {
  key: string;
  colors?: string[];
}

const buildPreferenceOptions = (videoPreferences?: VideoPreferences): StylePreferencesOptions[] => {
  const finalOptions: StylePreferencesOptions[] = [];
  if (!videoPreferences) return [];

  let enableOptions: string[] = [];
  let disableOptions: string[] = [];

  if (videoPreferences?.captions) {
    enableOptions.push("captions");
  } else {
    disableOptions.push("captions");
  }
  if (videoPreferences?.emojis) {
    enableOptions.push("emojis");
  } else {
    disableOptions.push("emojis");
  }

  if (videoPreferences?.music) {
    enableOptions.push("music");
  } else {
    disableOptions.push("music");
  }

  if (videoPreferences?.broll) {
    enableOptions.push("video clips");
  } else {
    disableOptions.push("video clips");
  }

  if (enableOptions.length > 0) {
    finalOptions.push({ key: "Include " + enableOptions.join(", ") });
  }

  if (disableOptions.length > 0) {
    finalOptions.push({ key: "No " + disableOptions.join(", ") });
  }

  if (videoPreferences?.tone) {
    finalOptions.push({
      key: "Tone: " + capitalize(videoPreferences?.tone.replace(/_/g, " ")),
    });
  }
  if (videoPreferences?.musicGenre) {
    finalOptions.push({
      key: "Music style: " + capitalize(videoPreferences?.musicGenre.replace(/_/g, " ")),
    });
  }

  if (videoPreferences?.captionColors) {
    finalOptions.push({ key: "Custom caption colors:", colors: videoPreferences?.captionColors.split(",") });
  }

  return finalOptions;
};

export { buildPreferenceOptions, StylePreferencesOptions };
