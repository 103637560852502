import styled from "styled-components";
import { Grid } from "semantic-ui-react";
import { theme } from "../../../styling/theme";

// Compliance
export const ComplianceContainer = styled(Grid.Row)({
  display: "flex !important",
  marginTop: 0,
  padding: "0 !important",
  maxWidth: `${theme.maxWidthSizeContainer}px !important`,
  minWidth: "984px !important",
  backgroundColor: `${theme.backgroundColor}`,
});
