import { defineMessages } from "react-intl";

export enum FirstSocialNetworkType {
  title = "FirstSocialNetworkType.title",
  subtitle = "FirstSocialNetworkType.subtitle",

  submit = "FirstSocialNetworkType.submit",
  skip = "FirstSocialNetworkType.skip",

  warningTitle = "FirstSocialNetworkType.warningTitle",
  errorTitle = "FirstSocialNetworkType.errorTitle",
  infoTitle = "FirstSocialNetworkType.infoTitle",

  bubbleMessage1 = "FirstSocialNetworkType.bubbleMessage1",
  bubbleMessage2 = "FirstSocialNetworkType.bubbleMessage2"
}

export const en = {
  [FirstSocialNetworkType.title]: "Connect your social accounts",
  [FirstSocialNetworkType.subtitle]: "Connect your social accounts",

  [FirstSocialNetworkType.submit]: "Next",
  [FirstSocialNetworkType.skip]: "Do it later",

  [FirstSocialNetworkType.warningTitle]: "Warning:",
  [FirstSocialNetworkType.errorTitle]: "Error:",
  [FirstSocialNetworkType.infoTitle]: "Hey:",

  [FirstSocialNetworkType.bubbleMessage1]: "Awesome! Before you begin posting, we’ll need to connect your social accounts.",
  [FirstSocialNetworkType.bubbleMessage2]: "Keep in mind that in order to post to Facebook and Instagram, you’ll need to have a Facebook Business Page and an Instagram Professional account.",
};

export const descriptors = defineMessages({
  [FirstSocialNetworkType.title]: {
    id: FirstSocialNetworkType.title,
    defaultMessage: en[FirstSocialNetworkType.title],
    description: "Social Network title",
  },
  [FirstSocialNetworkType.subtitle]: {
    id: FirstSocialNetworkType.subtitle,
    defaultMessage: en[FirstSocialNetworkType.subtitle],
    description: "Social Network subtitle",
  },

  [FirstSocialNetworkType.submit]: {
    id: FirstSocialNetworkType.submit,
    defaultMessage: en[FirstSocialNetworkType.submit],
    description: "Submit text",
  },
  [FirstSocialNetworkType.skip]: {
    id: FirstSocialNetworkType.skip,
    defaultMessage: en[FirstSocialNetworkType.skip],
    description: "skip",
  },
  [FirstSocialNetworkType.warningTitle]: {
    id: FirstSocialNetworkType.warningTitle,
    defaultMessage: en[FirstSocialNetworkType.warningTitle],
    description: "Warning messages title",
  },
  [FirstSocialNetworkType.errorTitle]: {
    id: FirstSocialNetworkType.errorTitle,
    defaultMessage: en[FirstSocialNetworkType.errorTitle],
    description: "Error Messages title",
  },
  [FirstSocialNetworkType.infoTitle]: {
    id: FirstSocialNetworkType.infoTitle,
    defaultMessage: en[FirstSocialNetworkType.infoTitle],
    description: "Info messages title",
  },
  [FirstSocialNetworkType.bubbleMessage1]: {
    id: FirstSocialNetworkType.bubbleMessage1,
    defaultMessage: en[FirstSocialNetworkType.bubbleMessage1],
    description: "bubbleMessage1",
  },
  [FirstSocialNetworkType.bubbleMessage2]: {
    id: FirstSocialNetworkType.bubbleMessage2,
    defaultMessage: en[FirstSocialNetworkType.bubbleMessage2],
    description: "bubbleMessage2",
  },
});
