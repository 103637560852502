import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Grid } from "semantic-ui-react";

import { TitleSection, AdminSideBar, VideoScriptsList, SideBar } from "../../../components";
import { UserGroups } from "../../../interfaces";
import { AppContext } from "../../../providers";
import { coachWithWritePermission, URLS } from "../../../utils";

import { WhiteContainer } from "../../../styling/baseStyle";
import * as DTO from "../../../interfaces";

type Props = RouteComponentProps;

const VideoScriptsFC: React.FC<Props> = ({ history }) => {
  const { userContext } = React.useContext(AppContext);
  const isAdmin = userContext?.account.groups.some(g => g === UserGroups.ADMIN);
  const withWritePermission = coachWithWritePermission(DTO.WebComponents.MANAGE_SCRIPT_LIBRARY, userContext);

  return (
    <WhiteContainer>
      {isAdmin ? <AdminSideBar history={history} /> : <SideBar history={history} />}
      <div className={"hugePadding videoScriptsList"}>
        <TitleSection
          title={"Video Scripts"}
          ready={true}
          showNextOption={isAdmin || withWritePermission}
          customNextLabel={"New Script"}
          handleNextAction={() => {
            history.push(URLS.newVideoScript);
          }}
          showLinkOption={false}
        />
        <Grid.Row className={"coachBaseContainer"}>
          <Grid.Column>
            <VideoScriptsList redirectUrl={URLS.videoScript} />
          </Grid.Column>
        </Grid.Row>
      </div>
    </WhiteContainer>
  );
};

export const VideoScriptsPage = VideoScriptsFC;
