import { useEffect } from "react";

// Store events on window.__gaDataLayer
const DATA_LAYER_KEY = "__gaDataLayer";
const ANALYTICS_BASE_URL = "https://www.googletagmanager.com/gtag/js";
const dataLayer: any[] = [];
// Module state necessary for `config` calls
let globalGoogleAnalyticsId: string | undefined;

const gtag: Gtag.Gtag = function dataLayerFunc() {
  dataLayer.push(arguments);
};

const initialize = (googleAnalyticsId?: string) => {
  const isInitalized = !!globalGoogleAnalyticsId;
  const isProduction = process.env.NODE_ENV === "production";
  if (isInitalized || !googleAnalyticsId || !isProduction) {
    return;
  }
  globalGoogleAnalyticsId = googleAnalyticsId;

  const url = new URL(ANALYTICS_BASE_URL);
  url.searchParams.set("id", googleAnalyticsId);
  url.searchParams.set("l", DATA_LAYER_KEY);
  const script = document.createElement("script");
  script.async = true;
  script.src = url.toJSON();
  document.head.appendChild(script);

  const win = window as any;
  win[DATA_LAYER_KEY] = dataLayer;
  gtag("js", new Date());
  gtag("config", googleAnalyticsId);
};

export const eventTrack = (eventName: string, parms: any) : void => {
   gtag('event', eventName, parms);
}

// export const event = gtag.bind(null, "event");
export const set = (params: Gtag.CustomParams) => gtag("set", params);
export const config = (params: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams) =>
  globalGoogleAnalyticsId && gtag("config", globalGoogleAnalyticsId, params);

export const useAnalyticsInitalize = (googleAnalyticsId?: string) =>
  useEffect(() => {
    initialize(googleAnalyticsId);
  }, [googleAnalyticsId]);

export const useAnalyticsPageView = (path: string) =>
  useEffect(() => {
    config({
      page_path: path,
    });
  }, [path]);
