import * as React from "react";
import{ useIntl } from "react-intl";
import { Button, Grid, Header } from "semantic-ui-react";

import * as DTO from "../../../../../interfaces";
import { PromptServices, CategoriesServices } from "../../../../../services";
import { SearchComponent } from "../../../../../components";
import { categoriesToFilter } from "../../../../../utils";

import PromptSearchList from "./components/PromptSearchList";
import PromptList from "./components/PromptList";

import { CampaignPromptsType, descriptors } from "./descriptors";

import { BlueTag } from "../../../../../styling/baseStyle";
import "./styles.scss";

interface OwnProps {
  coachId: string;
  campaignPrompts?: DTO.SelectedPrompts;
  onChangeHandler: (selectedPrompts: DTO.SelectedPrompts) => void;
  onCancel: () => void;
}

type Props = OwnProps 

const CampaignPromptFC: React.FC<Props> = ({
  coachId,
  campaignPrompts = {},
  onChangeHandler,
  onCancel,
}) => {
  const [searchActive, setSearchActive] = React.useState<boolean>(false);
  const { formatMessage } = useIntl();
  
  const [searchedPromptList, setSearchedPromptList] = React.useState<DTO.Prompt[]>([]);
  const [categories, setCategories] = React.useState<DTO.Category[]>([]);
  const [categoriesFilters, setCategoriesFilters] = React.useState<DTO.FilterType[]>([]);

  const [selectedPrompts, setSelectedPrompts] = React.useState<DTO.SelectedPrompts>(campaignPrompts);

  React.useEffect(() => {
    const getCategories = async () => {
      const result = await CategoriesServices.get(coachId);
      setCategories(() => result);
      setCategoriesFilters(categoriesToFilter(result));
    };

    getCategories();
  }, [coachId]);

  const addPromptHandler = (prompt: DTO.Prompt) => {
    const { promptId, categories: categoriesPrompt, title, post } = prompt;

    const currentPrompts: DTO.SelectedPrompts = {
      ...selectedPrompts,
      [promptId!]: {
        categoryIds: categoriesPrompt ? categoriesPrompt!.map(cat => cat.categoryId!) : [],
        title,
        post,
        prompt,
      },
    };

    setSelectedPrompts(currentPrompts);
  };

  const removePromptHandler = (prompt: DTO.Prompt) => {
    const { promptId } = prompt;
    setSelectedPrompts(finalPrompts => {
      delete finalPrompts[promptId!];
      return { ...finalPrompts };
    });
  };

  const promptsCounter = Object.keys(selectedPrompts).length;
  return (
    <Grid className={"campaignComponentContainer"}>
      <Grid.Row className={"noPaddingBottom"}>
        <Grid.Column width={3}>
          <Header as="h4"> {formatMessage({ ...descriptors[CampaignPromptsType.title] })}</Header>
        </Grid.Column>
        <Grid.Column width={8}>
          {promptsCounter > 0 && (
            <BlueTag>
              {formatMessage(
                {
                  ...descriptors[
                    CampaignPromptsType[promptsCounter > 1 ? "multipleSelectedPrompts" : "selectedPrompt"]
                  ],
                },
                { promptsCounter }
              )}
            </BlueTag>
          )}
        </Grid.Column>
        <Grid.Column width={2}>
          <Button
            data-elm-id={`promptsCancelBtn`}
            type={"button"}
            onClick={onCancel}
            floated={"right"}
            className={"secondary rounded"}>
            Cancel
          </Button>
        </Grid.Column>

        <Grid.Column width={3}>
          <Button
            data-elm-id={`promptsSaveBtn`}
            type={"button"}
            onClick={() => onChangeHandler(selectedPrompts)}
            floated={"right"}
            className={"primary rounded"}>
            Save Prompts
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <SearchComponent
            refreshData={setSearchedPromptList}
            hideWhenNotSearch={true}
            filterPlaceholder={"Group by categories"}
            content={
              <PromptSearchList
                categories={categories}
                coachId={coachId}
                promptList={searchedPromptList}
                campaignPrompts={selectedPrompts}
                onAddPromptHandler={addPromptHandler}
                onRemovePromptHandler={removePromptHandler}
              />
            }
            searchFunction={PromptServices.library}
            showSortBy={false}
            filters={categoriesFilters}
            pageable={false}
            onSearchedHandler={setSearchActive}
          />
          {categories && !searchActive && (
            <PromptList
              categories={categories}
              coachId={coachId}
              campaignPrompts={selectedPrompts}
              onAddPromptHandler={addPromptHandler}
              onRemovePromptHandler={removePromptHandler}
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const PromptsStep = CampaignPromptFC
