import * as React from "react";
import { Grid, Message, Loader, Dimmer } from "semantic-ui-react";
import { RouteComponentProps, withRouter } from "react-router";
import { useIntl } from "react-intl";
import { get } from "lodash";

import { AppContext } from "../../../providers";

import { SideBar, TitleSection } from "../../../components";
import { DigestEmailServices } from "../../../services";
import * as DTO from "../../../interfaces";
import { coachWithWritePermission } from "../../../utils";

import { DigestEmailSet } from "./DigestEmailSet";
import { DigestEmailType, descriptors } from "./descriptors";

import { WhiteContainer, ContentGrid } from "../../../styling/baseStyle";
import { DigestEmailContainer, DigestEmailGrid } from "./styled";
import "./styles.scss";

type Props = RouteComponentProps; 

const emailsInTagFormat = (
  result: DTO.DigestEmailElementResponse[]
): DTO.TagElement[] => {
  return result.map((keyword, index) => {
    const k: DTO.TagElement = {
      index,
      displayValue: keyword.digestEmail,
      id: keyword.id,
    };
    return k;
  });
};

const DigestEmailFC: React.FC<Props> = ({ history }) => {
  const { formatMessage } = useIntl();
  const { userContext } = React.useContext(AppContext);
  const coachId = get(userContext, "coach.id", "");
  const withWritePermission = coachWithWritePermission(
    DTO.WebComponents.DIGEST_EMAIL,
    userContext
  );

  const [digestEmailListError, setDigestEmailListError] = React.useState<
    { [key: string]: string } | undefined
  >();
  const [digestEmailList, setDigestEmailList] = React.useState<
    DTO.TagElement[]
  >([]);

  const [generalErrors, setGeneralErrors] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getKeywords = async () => {
      const result = await DigestEmailServices.get(coachId);
      setDigestEmailList(() => emailsInTagFormat(result));
    };

    getKeywords();
  }, [coachId]);

  React.useEffect(() => {
    const errorList: string[] = [];
    if (digestEmailListError) {
      Object.keys(digestEmailListError).map((key, index) => {
        return (
          digestEmailListError[key] && errorList.push(digestEmailListError[key])
        );
      });
    }
    setGeneralErrors(() => errorList);
  }, [digestEmailListError]);

  const setErrors = (
    errorKeywords: string[]
  ): { [key: string]: string } | undefined => {
    let newErrors = {};
    errorKeywords.map((error: string, index: number) => {
      newErrors = {
        ...newErrors,
        [index]: error,
      };
    });

    return newErrors;
  };

  const onErrorDigestEmails = (errors: string[]): void => {
    setDigestEmailListError(() => setErrors(errors));
  };

  const onSuccessDigestEmails = (emails: DTO.TagElement[]): void => {
    setDigestEmailList(emails);
  };

  const updateDigestEmails = (): void => {
    const emails: DTO.DigestEmailElement[] = digestEmailList.map((flagged) => {
      return {
        email: flagged.displayValue,
      } as DTO.DigestEmailElement;
    });

    if (emails.length > 100) {
      setGeneralErrors(() => [
        formatMessage({ ...descriptors[DigestEmailType.maximunEmailsError] }),
      ]);
      return;
    }

    setLoading(true);

    DigestEmailServices.update(coachId, emails)
      .then((result) => {
        setDigestEmailList(() => emailsInTagFormat(result));
      })
      .catch((e: string) => {
        setGeneralErrors(() => [e]);
      })
      .finally(() => {
        setLoading(false);
        setGeneralErrors(() => []);
      });
  };

  return (
    <WhiteContainer>
      <SideBar history={history} />

      <ContentGrid className={"listManagerContent"} columns={1}>
        <Grid columns={1}>
          <TitleSection
            title={formatMessage({ ...descriptors[DigestEmailType.title] })}
            ready={true}
            handleNextAction={() => {
              updateDigestEmails();
            }}
            customNextLabel={formatMessage({
              ...descriptors[DigestEmailType.updateBtn],
            })}
            showNextOption={withWritePermission}
          />
          <Grid className={"digestGrid"}>
            {generalErrors.length > 0 && (
              <Grid.Row columns={1}>
                <Message floating error list={generalErrors} />
              </Grid.Row>
            )}
            <DigestEmailGrid>
              <DigestEmailContainer columns={1}>
                {loading && (
                  <Dimmer active inverted>
                    <Loader inverted content="Loading" />
                  </Dimmer>
                )}

                <Grid.Column width={16}>
                  <DigestEmailSet
                    title={formatMessage({
                      ...descriptors[DigestEmailType.emailSectionTitle],
                    })}
                    emails={digestEmailList}
                    onError={onErrorDigestEmails}
                    customStyle={`background: rgba(61, 174, 245, 0.2);`}
                    onSuccess={onSuccessDigestEmails}
                  />
                </Grid.Column>
              </DigestEmailContainer>
            </DigestEmailGrid>
          </Grid>
        </Grid>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const DigestEmailPage = withRouter(DigestEmailFC);
