import * as React from "react";
import { Header, Grid } from "semantic-ui-react";
import { printDateToChat } from "../../../../../../../../../utils";

import "./styles.scss";

export interface MessageChat {
  type: "LEFT" | "RIGHT";
  owner?: string;
  date?: string;
  message: string;
}

interface OwnProps {
  messages: MessageChat[];
}

const dataElmId = "CompliancePostBubbleDetails";
type Props = OwnProps;

/**
 * Compliance Modal - Chat Bubble Detail
 */

const IntegratedBubbleDetails: React.FC<Props> = ({ messages }) => {
  return (
    <Grid className={"complianceBubbleContainer"}>
      <Grid.Row className={"complianceBubble"}>
        <Grid>
          {messages &&
            messages.map(({ owner, date, message, type }, index) => {
              return (
                <Grid.Row className={"noPadding complianceGrid"} key={"bubble_" + index}>
                  {owner && (
                    <p
                      data-elm-id={dataElmId + "MessageInfo"}
                      className={type === "RIGHT" ? "ownInfo infoRight" : "ownInfo infoLeft"}
                    >
                      <strong data-elm-id={dataElmId + "Username"}>{owner}</strong> {" " + printDateToChat(date)}
                    </p>
                  )}
                  <div className={type === "RIGHT" ? "postMessage selected ownMessage" : "postMessage guessContent"}>
                    <p data-elm-id={dataElmId + "Content"} className={owner ? "messageContent" : "guess"}>
                      {message}
                    </p>
                  </div>
                </Grid.Row>
              );
            })}
        </Grid>
      </Grid.Row>
    </Grid>
  );
};

export const ComplianceBubbleDetails = IntegratedBubbleDetails;
