import styled from "styled-components";
import { Button, Popup, Dropdown } from "semantic-ui-react";

export const Category = styled.div`
  padding-bottom: 10px;

  & .ui.primary.button {
    margin-left: 10px;
    height: 45px;
    position: absolute;
  }
`;

export const CategoryInput = styled(Dropdown)({
  width: "70%",
});

export const AddButton = styled(Button)({
  marginLeft: "10px",
  height: "45px",
  position: "absolute",
});

export const InfoPopup = styled(Popup)({
  paddingTop: "5px",
});
