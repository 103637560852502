import { defineMessages } from "react-intl";

export enum SignupSuccessType {
  title = "SignupSuccessType.title",
  accountCreated = "SignupSuccessType.accountCreated",
  checkEmail = "SignupSuccessType.checkEmail",
}

export const en = {
  [SignupSuccessType.title]: "Congratulations!",
  [SignupSuccessType.accountCreated]:
    'Click on the verification link sent to your email {email}',
  [SignupSuccessType.checkEmail]:
    'If your email is missing, please check spam or contact us at support@socialcoach.io',
};

export const descriptors = defineMessages({
  [SignupSuccessType.title]: {
    id: SignupSuccessType.title,
    defaultMessage: en[SignupSuccessType.title],
    description: "-",
  },
  [SignupSuccessType.accountCreated]: {
    id: SignupSuccessType.accountCreated,
    defaultMessage: en[SignupSuccessType.accountCreated],
    description: "-",
  },
  [SignupSuccessType.checkEmail]: {
    id: SignupSuccessType.checkEmail,
    defaultMessage: en[SignupSuccessType.checkEmail],
    description: "-",
  },
});
