import { ProfileContactInformationType } from "./descriptors";
import { FormField } from "../../../../interfaces/models";

export const accountInformationFields: FormField[] = [
  {
    id: "webAddress",
    name: "webAddress",
    type: "text",
    label: "Short Link Destination",
    placeholder: ProfileContactInformationType.webAddress,
    disabled: false,
    maxLength: 50,
  },
  {
    id: "slug",
    name: "slug",
    type: "text",
    showInfo: true,
    label: "Short Link",
    placeholder: ProfileContactInformationType.customSlug,
    disabled: false,
    maxLength: 50,
  },
];
