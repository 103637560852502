import { defineMessages } from "react-intl";

export enum PromptPageType {
  title = "PromptPageType.title",
  titleEdit = "PromptPageType.titleEdit",

  randomDelivery = "PromptPageType.randomDelivery",
  specificDate = "PromptPageType.specificDate",
  promptTitle = "PromptPageType.promptTitle",
  promptMessage = "PromptPageType.promptMessage",
  videoMessage = "PromptPageType.videoMessage",
  attachDesc = "PromptPageType.attachDesc",
  titlePlaceholder = "PromptPageType.titlePlaceholder",
  messagePlaceholder = "PromptPageType.messagePlaceholder",

  saveDraftBtn = "PromptPageType.saveDraftBtn",
  savePostBtn = "PromptPageType.savePostBtn",
  videoBtn = "PromptPageType.videoBtn",
  uploadBtn = "PromptPageType.uploadBtn",
  browseBtn = "PromptPageType.browseBtn",
  cancelBtn = "PromptPageType.Cancel",
  unknownError = "PromptPageType.unknownError",
  savedMessage = "PromptPageType.savedMessage",

  saveBtn = "PromptPageType.saveBtn",
  savePublishBtn = "PromptPageType.savePublishBtn",
  saveUnPublishBtn = "PromptPageType.saveUnPublishBtn",

  welcomePromptAlertTitle = "PromptPageType.welcomePromptAlertTitle",
  cancelAlertTitle = "PromptPageType.cancelAlertTitle",
  welcomePromptAlertDesc = "PromptPageType.welcomePromptAlertDesc",

  duplicatedDelivery = "PromptPageType.duplicatedDelivery",
  yesBtn = "PromptPageType.yesBtn",
  noBtn = "PromptPageType.noBtn",

  wrongAspectRatioMessage = "PromptPageType.wrongAspectRatioMessage",
  wrongAspectRatioReelsMessage = "PromptPageType.wrongAspectRatioReelsMessage",
  wrongFeedMultipleImagesMessage = "PromptPageType.wrongFeedMultipleImagesMessage",
  wrongSpecificationsMessage = "PromptPageType.wrongSpecificationsMessage",
  wrongFeedSpecificationsMessage = "PromptPageType.wrongFeedSpecificationsMessage",
  wrongResolutionMessage = "PromptPageType.wrongResolutionMessage",
  wrongBitrateMessage = "PromptPageType.wrongBitrateMessage",
  wrongDurationoMessage = "PromptPageType.wrongDurationoMessage",
  wrongSizeMessage = "PromptPageType.wrongSizeMessage",
  wrongFrameRateMessage = "PromptPageType.wrongFrameRateMessage",
  wrongMessage = "PromptPageType.wrongMessage",

  attributeRequired = "PromptPageType.attributeRequired",

  segmentationSectionTitle = "PromptPageType.segmentationSectionTitle",
  segmentationRequired = "PromptPageType.segmentationRequired",
}

export const en = {
  [PromptPageType.title]: "Add a prompt",
  [PromptPageType.titleEdit]: "Edit your prompt",

  [PromptPageType.randomDelivery]: "Random Delivery Date",
  [PromptPageType.specificDate]: "Specific Date",
  [PromptPageType.promptTitle]: "Prompt Title",
  [PromptPageType.promptMessage]: "Prompt Message",
  [PromptPageType.videoMessage]: "Instructional Video (optional)",
  [PromptPageType.titlePlaceholder]: "Enter title",
  [PromptPageType.messagePlaceholder]: "Write Message",
  [PromptPageType.attachDesc]: "Image/Video for Post",
  [PromptPageType.videoBtn]: "Upload Video",
  [PromptPageType.uploadBtn]: "Upload",
  [PromptPageType.browseBtn]: "Browse Library",
  [PromptPageType.saveDraftBtn]: "Save as draft",
  [PromptPageType.cancelBtn]: "Cancel",

  [PromptPageType.saveBtn]: "Save",
  [PromptPageType.savePublishBtn]: "Save & Publish",
  [PromptPageType.savePostBtn]: "Save & Post",
  [PromptPageType.saveUnPublishBtn]: "Save & Unpublish",
  [PromptPageType.yesBtn]: "Yes",
  [PromptPageType.noBtn]: "No",

  [PromptPageType.savedMessage]: "Prompt saved!",
  [PromptPageType.unknownError]: "Unknown Error.",

  [PromptPageType.welcomePromptAlertTitle]: "Replace Your Welcome Prompt",
  [PromptPageType.welcomePromptAlertDesc]: "Are you sure you would like to replace the existing Welcome Prompt?",

  [PromptPageType.cancelAlertTitle]: "Do you want to save this prompt?",

  [PromptPageType.duplicatedDelivery]:
    "Players may have already received this prompt. Changing this Prompt delivery type may result in players receiving this Prompt multiple times.",

  [PromptPageType.wrongFeedMultipleImagesMessage]:
    "One or more of the attached videos/images does not meet the specified file requirements. Please ensure that all attachments meet the requirements in the hover over next to the upload tool below.",

  [PromptPageType.wrongAspectRatioMessage]:
    "This {type} does not meet our aspect ratio requirements. {typeAfterDot} must be 1:1, 4:5, 16:9 or 1.91:1.",

  [PromptPageType.wrongAspectRatioReelsMessage]:
    "This video does not meet our aspect ratio requirements. Video must be 9:16.",

  [PromptPageType.wrongSpecificationsMessage]:
    "Please edit the files below to meet the required file format and size requirements. Any file that does not meet the specification listed in the information hover over next to the file upload section must be deleted",
  [PromptPageType.wrongFeedSpecificationsMessage]:
    "Please edit the video/image below to meet the required file format and size requirements. Any video that does not meet the specification listed in the information hover over next to the file upload section must be deleted",

  [PromptPageType.wrongResolutionMessage]:
    "This video does not meet the our minimum resolution requirements. Video must have a minimum resolution of 540 x 960 pixels (1080 x 1920 is recommended).",
  [PromptPageType.wrongBitrateMessage]:
    "This video does not meet our bitrate requirements. Videos must have maximum bitrate of 25Mbps.",
  [PromptPageType.wrongDurationoMessage]:
    "This video does not meet our video duration requirements. Videos must be between 3 and 90 seconds in duration.",
  [PromptPageType.wrongSizeMessage]:
    "This {type} does not meet our file size requirements. {typeAfterDot} must be under 100MB.",
  [PromptPageType.wrongFrameRateMessage]:
    "This video does not meet our frame rate requirements. Videos must be in the range of 24–60 frames per second.",
  [PromptPageType.wrongMessage]:
    "Caption or media includes blocked words: {blockedwords}",

  [PromptPageType.segmentationSectionTitle]: "Eligible Players",

  [PromptPageType.attributeRequired]: "{attribute} is required",
  [PromptPageType.segmentationRequired]: "You must select at least 1 player",
};

export const descriptors = defineMessages({
  [PromptPageType.title]: {
    id: PromptPageType.title,
    defaultMessage: en[PromptPageType.title],
    description: "-",
  },
  [PromptPageType.titleEdit]: {
    id: PromptPageType.titleEdit,
    defaultMessage: en[PromptPageType.titleEdit],
    description: "-",
  },
  [PromptPageType.randomDelivery]: {
    id: PromptPageType.randomDelivery,
    defaultMessage: en[PromptPageType.randomDelivery],
    description: "-",
  },
  [PromptPageType.specificDate]: {
    id: PromptPageType.specificDate,
    defaultMessage: en[PromptPageType.specificDate],
    description: "-",
  },
  [PromptPageType.promptTitle]: {
    id: PromptPageType.promptTitle,
    defaultMessage: en[PromptPageType.promptTitle],
    description: "-",
  },

  [PromptPageType.promptMessage]: {
    id: PromptPageType.promptMessage,
    defaultMessage: en[PromptPageType.promptMessage],
    description: "-",
  },
  [PromptPageType.videoMessage]: {
    id: PromptPageType.videoMessage,
    defaultMessage: en[PromptPageType.videoMessage],
    description: "-",
  },
  [PromptPageType.videoBtn]: {
    id: PromptPageType.videoBtn,
    defaultMessage: en[PromptPageType.videoBtn],
    description: "-",
  },
  [PromptPageType.attachDesc]: {
    id: PromptPageType.attachDesc,
    defaultMessage: en[PromptPageType.attachDesc],
    description: "-",
  },
  [PromptPageType.messagePlaceholder]: {
    id: PromptPageType.messagePlaceholder,
    defaultMessage: en[PromptPageType.messagePlaceholder],
    description: "-",
  },
  [PromptPageType.titlePlaceholder]: {
    id: PromptPageType.titlePlaceholder,
    defaultMessage: en[PromptPageType.titlePlaceholder],
    description: "-",
  },
  [PromptPageType.uploadBtn]: {
    id: PromptPageType.uploadBtn,
    defaultMessage: en[PromptPageType.uploadBtn],
    description: "-",
  },
  [PromptPageType.browseBtn]: {
    id: PromptPageType.browseBtn,
    defaultMessage: en[PromptPageType.browseBtn],
    description: "-",
  },
  [PromptPageType.saveDraftBtn]: {
    id: PromptPageType.saveDraftBtn,
    defaultMessage: en[PromptPageType.saveDraftBtn],
    description: "-",
  },
  [PromptPageType.savePostBtn]: {
    id: PromptPageType.savePostBtn,
    defaultMessage: en[PromptPageType.savePostBtn],
    description: "-",
  },
  [PromptPageType.cancelBtn]: {
    id: PromptPageType.cancelBtn,
    defaultMessage: en[PromptPageType.cancelBtn],
    description: "-",
  },
  [PromptPageType.unknownError]: {
    id: PromptPageType.unknownError,
    defaultMessage: en[PromptPageType.unknownError],
    description: "-",
  },

  [PromptPageType.saveBtn]: {
    id: PromptPageType.saveBtn,
    defaultMessage: en[PromptPageType.saveBtn],
    description: "-",
  },

  [PromptPageType.savePublishBtn]: {
    id: PromptPageType.savePublishBtn,
    defaultMessage: en[PromptPageType.savePublishBtn],
    description: "-",
  },

  [PromptPageType.saveUnPublishBtn]: {
    id: PromptPageType.saveUnPublishBtn,
    defaultMessage: en[PromptPageType.saveUnPublishBtn],
    description: "-",
  },
  [PromptPageType.savedMessage]: {
    id: PromptPageType.savedMessage,
    defaultMessage: en[PromptPageType.savedMessage],
    description: "-",
  },
  [PromptPageType.welcomePromptAlertTitle]: {
    id: PromptPageType.welcomePromptAlertTitle,
    defaultMessage: en[PromptPageType.welcomePromptAlertTitle],
    description: "-",
  },
  [PromptPageType.welcomePromptAlertDesc]: {
    id: PromptPageType.welcomePromptAlertDesc,
    defaultMessage: en[PromptPageType.welcomePromptAlertDesc],
    description: "-",
  },

  [PromptPageType.cancelAlertTitle]: {
    id: PromptPageType.cancelAlertTitle,
    defaultMessage: en[PromptPageType.cancelAlertTitle],
    description: "-",
  },

  [PromptPageType.duplicatedDelivery]: {
    id: PromptPageType.duplicatedDelivery,
    defaultMessage: en[PromptPageType.duplicatedDelivery],
    description: "-",
  },

  [PromptPageType.yesBtn]: {
    id: PromptPageType.yesBtn,
    defaultMessage: en[PromptPageType.yesBtn],
    description: "-",
  },
  [PromptPageType.noBtn]: {
    id: PromptPageType.noBtn,
    defaultMessage: en[PromptPageType.noBtn],
    description: "-",
  },
  [PromptPageType.wrongAspectRatioMessage]: {
    id: PromptPageType.wrongAspectRatioMessage,
    defaultMessage: en[PromptPageType.wrongAspectRatioMessage],
    description: "-",
  },
  [PromptPageType.wrongAspectRatioReelsMessage]: {
    id: PromptPageType.wrongAspectRatioReelsMessage,
    defaultMessage: en[PromptPageType.wrongAspectRatioReelsMessage],
    description: "-",
  },
  [PromptPageType.wrongFeedSpecificationsMessage]: {
    id: PromptPageType.wrongFeedSpecificationsMessage,
    defaultMessage: en[PromptPageType.wrongFeedSpecificationsMessage],
    description: "-",
  },
  [PromptPageType.wrongFeedMultipleImagesMessage]: {
    id: PromptPageType.wrongFeedMultipleImagesMessage,
    defaultMessage: en[PromptPageType.wrongFeedMultipleImagesMessage],
    description: "-",
  },
  [PromptPageType.wrongSpecificationsMessage]: {
    id: PromptPageType.wrongSpecificationsMessage,
    defaultMessage: en[PromptPageType.wrongSpecificationsMessage],
    description: "-",
  },
  [PromptPageType.wrongBitrateMessage]: {
    id: PromptPageType.wrongBitrateMessage,
    defaultMessage: en[PromptPageType.wrongBitrateMessage],
    description: "-",
  },
  [PromptPageType.wrongResolutionMessage]: {
    id: PromptPageType.wrongResolutionMessage,
    defaultMessage: en[PromptPageType.wrongResolutionMessage],
    description: "-",
  },
  [PromptPageType.wrongDurationoMessage]: {
    id: PromptPageType.wrongDurationoMessage,
    defaultMessage: en[PromptPageType.wrongDurationoMessage],
    description: "-",
  },
  [PromptPageType.wrongFrameRateMessage]: {
    id: PromptPageType.wrongFrameRateMessage,
    defaultMessage: en[PromptPageType.wrongFrameRateMessage],
    description: "-",
  },
  [PromptPageType.wrongSizeMessage]: {
    id: PromptPageType.wrongSizeMessage,
    defaultMessage: en[PromptPageType.wrongSizeMessage],
    description: "-",
  },
  [PromptPageType.wrongMessage]: {
    id: PromptPageType.wrongMessage,
    defaultMessage: en[PromptPageType.wrongMessage],
    description: "-",
  },
  [PromptPageType.segmentationSectionTitle]: {
    id: PromptPageType.segmentationSectionTitle,
    defaultMessage: en[PromptPageType.segmentationSectionTitle],
    description: "",
  },
  [PromptPageType.attributeRequired]: {
    id: PromptPageType.attributeRequired,
    defaultMessage: en[PromptPageType.attributeRequired],
    description: "-",
  },
  [PromptPageType.segmentationRequired]: {
    id: PromptPageType.segmentationRequired,
    defaultMessage: en[PromptPageType.segmentationRequired],
    description: "-",
  },
});
