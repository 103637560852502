import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { BubbleDetails } from "../../../components/Players/FirstLogin/BubbleDetails";
import { UserRegistered, Chat } from "../../../interfaces";
import { AppContext, PlayerAppContext } from "../../../providers";
import { PlayerSignUpStep, PlayersServices } from "../../../services";
import { FloatedContainer } from "../../../styling/baseStyle";
import { URLS } from "../../../utils";
import { PersonalInformationForm } from "../../FormikForms/FirstPersonalInformation";

const IntegratedFirstLoginPage: React.FC<RouteComponentProps> = ({ history }) => {
  const { coachInformation, setCoachInformation } = React.useContext(PlayerAppContext);
  const { userContext, setUserContext } = React.useContext(AppContext);

  React.useEffect(() => {
    if (userContext?.signUpStep! === PlayerSignUpStep[PlayerSignUpStep.PLAYER_BUSINESS_DATA]) {
      window.location.assign(URLS.player.dashboard);
    }
    // Get Coach Information
    PlayersServices.getCoachData(userContext!.player!.id!).then(coachInfo => {
      setCoachInformation(coachInfo);
    });
  }, []);

  const updateContextAndNext = (user: UserRegistered) => {
    setUserContext({
      ...userContext!,
      profile: {
        ...user.profile!,
      },
    });
    history.push(URLS.player.firstPicture);
  };

  const bubbleMessages: Chat[] = [
    {
      title: "Hi there!",
      message: "I'm so happy you are here! Let's complete your profile.",
    },
  ];

  return (
    <FloatedContainer>
      <PersonalInformationForm
        chatMessages={bubbleMessages}
        coachInfo={coachInformation?.coach}
        successHandler={updateContextAndNext}
      />
      {coachInformation && <BubbleDetails messages={bubbleMessages} coachInfo={coachInformation?.coach} />}
    </FloatedContainer>
  );
};

export const FirstLoginPage = withRouter(IntegratedFirstLoginPage);
