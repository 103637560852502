import * as React from "react";
import * as DTO from "../../../../../interfaces";
import { Button, Grid, Icon, Image } from "semantic-ui-react";
import axios from "axios";
import { useMemo, useState } from "react";
import "./styles.scss";

interface OwnProps {
  videoProjectId: number;
  stickers: DTO.ComposedStickerResponse | undefined;
}

type Props = OwnProps;

/**
 * Prediction Components
 */
const StickersFC: React.FC<Props> = ({ videoProjectId, stickers }) => {
  const [collapsed, setCollapsed] = useState(true);
  const downloadFile = (filePath: string, fileName: string) => {
    axios.get(filePath, { responseType: "blob" }).then(response => {
      const urlObject = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = urlObject;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const mediaList = useMemo(() => {
    const list: DTO.Media[] = [];
    stickers?.playerSticker?.stickers?.media?.forEach(m => list.push(m));
    stickers?.coachSticker?.stickers?.media?.forEach(m => list.push(m));
    return list;
  }, [stickers?.playerSticker?.stickers?.media, stickers?.coachSticker?.stickers?.media]);

  const hiderLabel = useMemo(() => {
    if (mediaList.length === 0) {
      return "No Stickers";
    }
    if (collapsed) {
      return `Show ${mediaList.length} stickers`;
    } else {
      return `Hide ${mediaList.length} stickers`;
    }
  }, [mediaList.length, collapsed]);

  return (
    <>
      <Grid.Row className={"projectStickerHeader"}>
        <label className={"small"} onClick={() => setCollapsed(!collapsed)}>
          Stickers
        </label>
        {mediaList.length > 0 && (
          <a className={"stickerHider"} onClick={() => setCollapsed(!collapsed)}>
            {hiderLabel}
            {mediaList.length > 0 && <Icon name={collapsed ? "triangle right" : "triangle down"} />}
          </a>
        )}
        {mediaList.length === 0 && <span className={"noStickers"}>No Stickers</span>}
      </Grid.Row>
      <Grid.Row className={"projectStickersContainer"}>
        {!collapsed &&
          stickers?.playerSticker?.stickers &&
          mediaList.map((media, index) => {
            return (
              <Grid.Column key={`sticker_container_${index}`} className={"projectStickerCard"}>
                <Image size={"tiny"} src={media?.thumbnailUri || media?.uri} />
                <div
                  className="downloadIcon"
                  onClick={() =>
                    downloadFile(media.uri!!, `${videoProjectId}_STICKER_${media.category}_${index}.${media.extension}`)
                  }
                >
                  <Icon size={"large"} name="arrow alternate circle down outline" />
                </div>
              </Grid.Column>
            );
          })}
      </Grid.Row>
    </>
  );
};

export const Stickers = StickersFC;
