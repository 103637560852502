import * as React from "react";
import { Formik } from "formik";
import { get } from "lodash";
import { CoachSettings, Media, PostWithBrandRequest, dateKey, timeKey } from "../../../../interfaces";
import { PostServices, SettingsServices } from "../../../../services";
import { getFilteredSocialNetworks, getSocialNetworkMessages, promptSpecificDate } from "../../../../utils";

import { FormStepper } from "../FormStepper";
import { ScheduledPostStep } from "../ScheduledPostForm";
import { PostInfoStep } from "../PostInfoStep";
import { SelectedPlayers } from "../SelectPlayers";

import { playersValidationSchema, scheduledValidationSchema, validationSchema } from "./validations";
import { WhiteContainer } from "../../../../styling/baseStyle";
import useBlockedWords from "./hooks";
interface OwnProps {
  brandPostRequest?: PostWithBrandRequest;
  coachId?: string;
  playerId?: number;
  myCoachId?: string;
  brandId: string;

  onContentloading?: (value: boolean) => void;
  onSuccess: (isAutoPost: boolean, sendMode: string) => void;
}

const BrandPostFormFC: React.FC<OwnProps> = ({
  brandPostRequest,
  coachId = undefined,
  myCoachId = undefined,
  playerId = undefined,
  brandId,
  onContentloading,
  onSuccess,
}) => {
  const formikRef = React.useRef<any>(null);
  const [attachedLoading, setAttachedLoading] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const [showHashtagToggle, setShowHashtagToggle] = React.useState(true);

  React.useEffect(() => {
    if (onContentloading) {
      onContentloading(attachedLoading);
    }
  }, [attachedLoading]);

  // Blocked / Flagged Words
  const { blockedNamesList, flaggedNamesList } = useBlockedWords(
    coachId ? coachId : undefined,
    playerId ? playerId : undefined
  );

  const [warnings, setWarnings] = React.useState<string[]>([]);

  React.useEffect(() => {
    const getSettings = async () => {
      const id = coachId ? coachId : myCoachId;
      SettingsServices.getAppSettingsByCoachId(id!!).then(response => {
        const hashtagSettingStatus =
          response.settings.find(s => s.appSettingId === CoachSettings.PLAYER_HASHTAG)?.status || "ACTIVE";
        setShowHashtagToggle(hashtagSettingStatus === "ACTIVE");
      });
    };

    if (coachId || myCoachId) {
      getSettings();
    }
  }, [coachId, myCoachId]);

  React.useEffect(() => {
    setWarnings([]);
  }, [step]);

  const addFlaggedWordsWarning = (warning: string): void => {
    if (warning.length === 0) {
      setWarnings([]);
    } else {
      setWarnings(() => [`Caption includes flagged words: ${warning}`]);
    }
  };
  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        messageToPost: get(brandPostRequest, "messageToPost", ""),
        allowShortLink: get(brandPostRequest, "allowShortLink", true),
        allowComplianceDisclaimer: get(brandPostRequest, "allowComplianceDisclaimer", false),
        allowHashtags: get(brandPostRequest, "allowHashtags", true),
        withMediaReady: get(brandPostRequest, "mediaGroup.length", 0),
        mediaGroup: get(brandPostRequest, "mediaGroup", undefined),
        imageWithErrors: 0,
        videoWithDurationError: 0,
        videoWithBitrateError: 0,
        videoWithResolutionError: 0,
        isAutoPost: get(brandPostRequest, "isAutoPost", false),
        sendMode: "IMMEDIATE",
        deliveryDateUtc: undefined,
        deliveryTimeUtc: undefined,
        socialNetworks: [],
        socialNetworksCounter: 0,
        playerIds: playerId ? [playerId] : [],
        blockedWords: "",
        flaggedWords: "",
      }}
      validationSchema={step === 0 ? validationSchema : coachId ? playersValidationSchema : scheduledValidationSchema}
      onSubmit={async (values, { setErrors }) => {
        const {
          sendMode,
          messageToPost,
          isAutoPost,
          mediaGroup,
          allowComplianceDisclaimer,
          allowHashtags,
          allowShortLink,
          playerIds,
          socialNetworks,
        } = values;

        let sheduleSection = {};
        if (sendMode === "SCHEDULED") {
          sheduleSection = {
            scheduledDatetimeUtc:
              values[dateKey] && values[timeKey] ? promptSpecificDate(values[dateKey], values[timeKey])! : undefined,
          };
        }

        const snFiltered = getFilteredSocialNetworks(socialNetworks);
        const socialNetworkMessages = getSocialNetworkMessages(snFiltered);

        const request: PostWithBrandRequest = {
          ...sheduleSection,
          playerIds,
          coachId: coachId,
          brandId,
          messageToPost,
          socialNetworkMessages,
          isAutoPost,
          sendMode,
          mediaGroup: mediaGroup!!,
          allowComplianceDisclaimer,
          allowHashtags,
          allowShortLink,
        };

        PostServices.createWithBrand(request)
          .then(() => {
            onSuccess(isAutoPost, sendMode);
          })
          .catch(error => {
            setErrors({
              messageToPost: error,
            });
          });
      }}
    >
      {({ values, errors, setFieldValue, setErrors }) => {
        return (
          <WhiteContainer>
            <FormStepper
              warnings={warnings}
              errors={Object.values(errors) as string[]}
              formikRef={formikRef}
              updateStep={setStep}
            >
              {/* FIRST STEP */}
              <PostInfoStep
                showHashtagToggle={showHashtagToggle}
                showComplianceToggle={!!coachId}
                isCoach={!!coachId}
                brandId={brandId}
                blockedNamesList={blockedNamesList}
                flaggedNamesList={flaggedNamesList}
                handleAttachedFileUploadStarted={() => setAttachedLoading(true)}
                handleAttachedFileUploadFinished={() => setAttachedLoading(false)}
                handleMediaUpdated={(newMedia: Media[]) => {
                  const invalidImages = newMedia.filter((m: Media) => m.validAspectRatio === false);
                  const invalidDuration = newMedia.filter((m: Media) => m.errors?.wrongLength);
                  const invalidBitrate = newMedia.filter((m: Media) => m.errors?.wrongBitrate);
                  const invalidSize = newMedia.filter((m: Media) => m.errors?.wrongSize);

                  setFieldValue("withMediaReady", newMedia.length);
                  setFieldValue("imageWithErrors", invalidImages.length);
                  setFieldValue("videoWithDurationError", invalidDuration.length);
                  setFieldValue("videoWithBitrateError", invalidBitrate.length);
                  setFieldValue("videoWithResolutionError", invalidSize.length);

                  setFieldValue("mediaGroup", {
                    media: newMedia.map((item, sortOrder) => {
                      const newMediaObj = {
                        ...item,
                        sortOrder,
                      };
                      return newMediaObj;
                    }),
                    mediaGroupId: get(values.mediaGroup, "mediaGroupId", null),
                    type: newMedia[0] && newMedia[0].category,
                  });
                }}
                handleSetFieldValue={(field, value) => {
                  setFieldValue(field, value);

                  if (field === "flaggedWords") {
                    addFlaggedWordsWarning(value);
                  }
                }}
              />

              {/* SECOND STEP (COACHES ONLY) */}
              {coachId && (
                <SelectedPlayers
                  disabled={false}
                  selectedPlayers={values.playerIds}
                  onChange={players => {
                    setFieldValue("playerIds", players);
                  }}
                />
              )}

              {/* THIRD STEP */}
              <ScheduledPostStep handleSetFieldValue={setFieldValue} isCoach={!!coachId} />
            </FormStepper>
          </WhiteContainer>
        );
      }}
    </Formik>
  );
};

export const BrandPostForm = BrandPostFormFC;
