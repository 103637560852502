import * as React from "react";
import{ useIntl } from "react-intl";
import * as DTO from "../../../../../../../interfaces";

import { CampaignByCategory } from "./components/CampaignByCategory";

interface OwnProps {
  categories: DTO.Category[];
  coachId: string;
  campaignPrompts: DTO.SelectedCampaign;

  onAddCampaignHandler: (prompt: DTO.CampaignResume) => void;
  onRemoveCampaignHandler: (prompt: DTO.CampaignResume) => void;
}

type Props = OwnProps 

export default 
  ({ categories, coachId, campaignPrompts = {}, onAddCampaignHandler, onRemoveCampaignHandler }: Props) => {
    const uncategorized: DTO.Category = {
      categoryId: "uncategorized",
      name: "Uncategorized",
    };
    return (
      <div className="promptListContainer">
        <CampaignByCategory
          coachId={coachId}
          key={"uncategorized"}
          category={uncategorized}
          campaignPrompts={campaignPrompts}
          onAddPrompt={onAddCampaignHandler}
          onRemovePrompt={onRemoveCampaignHandler}
        />

        {categories.map(category => {
          return (
            <CampaignByCategory
              coachId={coachId}
              key={category.categoryId}
              category={category}
              campaignPrompts={campaignPrompts}
              onAddPrompt={onAddCampaignHandler}
              onRemovePrompt={onRemoveCampaignHandler}
            />
          );
        })}
        {/* {showEmptySection && <EmptyView text={"You have no Library Prompts"} customIconImage={Svg.Library} />} */}
      </div>
    );
  }
