import { Constants } from "@socialcoach/services";
import { AxiosError } from "axios";

export interface AuthError {
  error: string;
  error_description: string;
}

export interface ErrorMessage {
  title: string;
  message: string;
  errors: any[];
  code?: string;
}

export function scCatchServiceException(throwFullErrorObject: boolean = false) {
  return (
    target: object,
    propertyKey: string,
    descriptor: TypedPropertyDescriptor<(..._: any[]) => Promise<any>>
  ): any => {
    const originalMethod = descriptor.value;
    descriptor.value = async function (...args: any[]): Promise<any> {
      try {
        return await originalMethod!.apply(this, args);
      } catch (e) {
        const err = e as AxiosError;
        if (err && err.response && err.response.data) {
          const responseData = err.response.data;
          if (throwFullErrorObject) {
            throw responseData;
          } else if ((responseData as AuthError).error_description) {
            throw (responseData as AuthError).error_description;
          } else if ((responseData as ErrorMessage).errors) {
            throw (responseData as ErrorMessage).errors[0].defaultMessage;
          } else if ((responseData as ErrorMessage).message) {
            throw (responseData as ErrorMessage).message;
          } else {
            throw Constants.ERROR_MESSAGES.UNKNOW;
          }
        } else {
          if (throwFullErrorObject) {
            throw { message: Constants.ERROR_MESSAGES.UNKNOW };
          } else {
            throw Constants.ERROR_MESSAGES.UNKNOW;
          }
        }
      }
    };
    return descriptor;
  };
}
