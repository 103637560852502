import * as React from "react";
import{ useIntl } from "react-intl";
import { Grid } from "semantic-ui-react";

import { PostPageType, descriptors } from "../../descriptors";

interface OwnProps {
  /**
   * Function called when cancel button is clicked
   */
  goBack: () => void;
}

const HeaderActionButtonsFC: React.FC<OwnProps> = ({ goBack }) => {
  const { formatMessage } = useIntl();
  return (
    <Grid.Column width={8} floated="right" className="skip-link noMarginRight">
      <span className={"rightPadding"} data-elm-id={"promptPageCancelBtn"} onClick={() => goBack()}>
        {formatMessage({ ...descriptors[PostPageType.cancelBtn] })}
      </span>
    </Grid.Column>
  );
};

export const HeaderActionButtons = HeaderActionButtonsFC;
