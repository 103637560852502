import { client as axios } from "./axiosClient";
import { Constants } from "@socialcoach/services";
import { catchServiceException } from "@socialcoach/services";
import * as DTO from "../interfaces/dtos";

export class AIService {
  @catchServiceException()
  static async getCaption(request: DTO.AICaptionRequest): Promise<DTO.AICaptionResponse> {
    if (request.input.length === 0) {
      request.input = "Generate a random caption";
    }
    const { data } = await axios.post<DTO.AICaptionResponse>(`${Constants.API_URL}/ai/caption`, request, {
      withCredentials: true,
    });
    return data;
  }
}
