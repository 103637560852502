import * as React from "react";
import { DraggableMediaItem } from "../";
import { Media, UploadProgressMap } from "../../interfaces";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import "./styles.scss";

interface DraggableMediaContainerProps {
  onReordered?: any;
  media: Media[];
  uploadProgress: UploadProgressMap;
  handleClose: (id: number) => void;
  handleUpdate: (id: number, url: string) => void;
  onDragEnd: (result: any) => void;
}

const validArray = (arr: any[]): boolean => {
  return Boolean(arr && arr.length && arr.length > 0);
};

const DraggableMediaContainerFC: React.FC<DraggableMediaContainerProps> = ({
  // onReordered,
  media,
  uploadProgress,
  handleClose,
  handleUpdate,
  onDragEnd,
}: DraggableMediaContainerProps) => {
  const numberOfRows = media.length > 5 ? 2 : 1;
  const firstRow = numberOfRows === 1 ? media.length : 5;
  const mediasWithGroup = [media.slice(0, firstRow)];
  if (numberOfRows === 2) {
    mediasWithGroup.push(media.slice(firstRow, media.length));
  }
  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        {mediasWithGroup.map((subMedia, index) => (
          <Droppable key={"droppable_media_" + index} droppableId={`${index}`} direction="horizontal">
            {(provided: any) => (
              <div ref={provided.innerRef} className="draggable-media-container-wrapper" {...provided.droppableProps}>
                {validArray(media) &&
                  subMedia.map((item: Media, i: number) => (
                    <DraggableMediaItem
                      total={media.length}
                      src={item}
                      key={"dragglable_media_item_" + item.id}
                      id={item.id}
                      index={i}
                      handleClose={handleClose}
                      handleUpdate={handleUpdate}
                      uploadProgress={uploadProgress[item.id] && uploadProgress[item.id].progress}
                    />
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>
    </div>
  );
};

export const DraggableMediaContainer = DraggableMediaContainerFC;
