import { defineMessages } from "react-intl";

export enum LIOrganizationModalType {
  title = "LIOrganizationModalType.title",

  selectYourLIOrganization = "LIOrganizationModalType.selectYourLIOrganization",
  yesBtn = "LIOrganizationModalType.yesBtn",
  noBtn = "LIOrganizationModalType.noBtn",
}

export const en = {
  [LIOrganizationModalType.title]: "",
  [LIOrganizationModalType.selectYourLIOrganization]: "Select your organization",
  [LIOrganizationModalType.yesBtn]: "OK",
  [LIOrganizationModalType.noBtn]: "Cancel",
};

export const descriptors = defineMessages({
  [LIOrganizationModalType.title]: {
    id: LIOrganizationModalType.title,
    defaultMessage: en[LIOrganizationModalType.title],
    description: "-",
  },
  [LIOrganizationModalType.selectYourLIOrganization]: {
    id: LIOrganizationModalType.selectYourLIOrganization,
    defaultMessage: en[LIOrganizationModalType.selectYourLIOrganization],
    description: "-",
  },

  [LIOrganizationModalType.yesBtn]: {
    id: LIOrganizationModalType.yesBtn,
    defaultMessage: en[LIOrganizationModalType.yesBtn],
    description: "-",
  },
  [LIOrganizationModalType.noBtn]: {
    id: LIOrganizationModalType.noBtn,
    defaultMessage: en[LIOrganizationModalType.noBtn],
    description: "-",
  },
});
