import { Image, Grid } from "semantic-ui-react";
import styled from "styled-components";
import { theme } from "../../styling/theme";

const iconSize = (customSize: string): string => {
  if (customSize === "mini") {
    return "25px";
  } else if (customSize === "big") {
    return "40px";
  } else {
    return "35px";
  }
};
export const SocialIcon = styled(Image)`
  ${(props: any) => `padding: ${props.custompadding};`}
  min-width: ${(props: any) => iconSize(props.customsize)};
  min-height: ${(props: any) => iconSize(props.customsize)};
  max-width: ${(props: any) => iconSize(props.customsize) + " !important"};
  max-height: ${(props: any) => iconSize(props.customsize)};
  ${(props: any) => (props.active ? `color: ${theme.subtitleColor};` : "")}
  ${(props: any) => (props.selected ? "opacity: 1;" : "opacity: 0.4; filter: grayscale(100%);")}
`;

export const IconContainer = styled.a<{ disabled: boolean }>`
  ${(props: any) =>
    props.disabled
      ? `cursor: default;
      padding: 0;`
      : "padding: 0px;"}
`;

export const SocialIconSection = styled(Grid.Column)({
  marginRight: "30px !important",
  padding: "0px !important",
});
