import * as React from "react";
import { Media, UploadProgressMap } from "../../interfaces";
import { getValidFiles } from "../../utils";

import "./styles.scss";
import { VideoProjectMediaItem } from "./components/VideoProjectMediaItem";
import { ConfigContext } from "../../contexts/appContexts";

const MAXREELSDURATION = 900;
const MINREELSDURATION = 3;
const MAXEDITEDMEDIA = 1;
const MAXMEDIA = 10;

interface MultiVideoUploaderProps {
  editedFile?: boolean;
  originalMedia: Media[];
  handleMediaUpdated: (newMedia: Media[]) => void;
}

/*
 *
 * This Multi-Image uploader is designed to allow users to upload variable image and video media
 * types and reorder them using the DraggableMediaContainer.
 *
 */
const VideoProjectUploaderFC: React.FC<MultiVideoUploaderProps> = ({
  originalMedia,
  editedFile = false,
  handleMediaUpdated,
}) => {
  const hiddenFileInput = React.useRef<HTMLInputElement | null>(null);
  const [media, setMedia] = React.useState<Media[]>(originalMedia || []);

  React.useEffect(() => {
    handleMediaUpdated(media);
  }, [media]);

  /**
   * handles browse button clicks
   */
  const handleBrowseButtonClicked = (): void => {
    const maxMediaFile = editedFile ? MAXEDITEDMEDIA : MAXMEDIA;
    if (media.length <= maxMediaFile) {
      hiddenFileInput.current?.click();
    } else if (editedFile) {
      hiddenFileInput.current?.click();
    } else {
      alert(`We're only allowed to send ${maxMediaFile} media items at a time to social media. Sorry about that!`);
    }
  };

  /**
   * Occurs directly after the user selects files to upload from their local device.
   */
  const handleFilesSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const inputFiles = e.target.files || new FileList();
    let validFiles = getValidFiles(Array.from(inputFiles));

    // no files provided
    if (validFiles.length === 0) {
      return;
    }

    const maxMediaFile = editedFile ? MAXEDITEDMEDIA : MAXMEDIA;
    const diff = validFiles.length + media.length - maxMediaFile;
    if (!editedFile && diff > 0) {
      alert(`Only ${maxMediaFile} files can be added.`);
      const validRange = maxMediaFile - media.length;
      validFiles = validFiles.splice(0, validRange);
    } else if (editedFile) {
      setMedia([]);
    }

    const ids = media.map(item => item.id);
    let nextId = ids.length > 0 ? Math.max(...ids) + 1 : 1;
    const sortOrders = media.map(item => item.sortOrder);
    let nextSortOrder = sortOrders.length > 0 ? Math.max(...sortOrders) + 1 : 1;
    const timestamp = new Date().getTime();

    const newMedia = validFiles.map(file => {
      const mediaCategory = file.type.includes("image/") ? "IMAGE" : "VIDEO";
      const fileName = file.name.split(".")[0] + timestamp;
      return {
        id: nextId++,
        sortOrder: nextSortOrder++,
        category: mediaCategory,
        uri: URL.createObjectURL(file),
        type: file.type,
        extension: file.type.split("/")[1],
        imageFile: file,
        fileName,
      } as Media;
    });

    setMedia([...media, ...newMedia]);
  };

  /**
   *  Updates media state when user deletes a media object.
   */
  const handleDeletedMedia = (id: number): void => {
    const newMedia = media.filter(mediaItem => mediaItem.id !== id);
    setMedia(newMedia);

    // reset mediaLoaded if there are no more media items
    if (newMedia.length === 0) {
      hiddenFileInput.current!.value = "";
    }
  };

  /**
   *  Updates media state when user update a media object.
   */
  const handleUpdateMedia = (id: number, uri: string): void => {
    const newMedia = media.map(mediaItem => {
      if (mediaItem.id === id) {
        return {
          ...mediaItem,
          uri,
          validAspectRatio: true,
          downloadLink: uri,
        };
      }
      return mediaItem;
    });

    setMedia(newMedia);
  };

  return (
    <div className="video-project-uploader-wrapper">
      <form className="video-project-uploader-input-form">
        <input
          className="custom-file-input"
          type="file"
          multiple
          accept={"image/*, video/*"}
          onChange={handleFilesSelected}
          ref={hiddenFileInput}
        />
        {media.length > 0 && (
          <div className={"uploaded-files-manager"}>
            {media.map((item, index) => (
              <VideoProjectMediaItem
                src={item}
                key={"video_project_media_item_" + item.id}
                id={item.id}
                handleClose={handleDeletedMedia}
                handleUpdate={handleUpdateMedia}
              />
            ))}
          </div>
        )}
        <div className="browse-button" onClick={handleBrowseButtonClicked}>
          {editedFile && originalMedia.length > 0 ? "Replace" : editedFile ? "Upload" : "Add File"}
        </div>
      </form>
    </div>
  );
};

export const VideoProjectUploader = VideoProjectUploaderFC;
