import * as React from "react";
import { CookiesProvider } from "react-cookie";
import "./styling/base.scss";
import { ConfigContext } from "./contexts/appContexts";
import { IntlProvider } from "react-intl";
import { createGlobalStyle, ThemeProvider, theme } from "./styling/theme";
import Routes from "./Routes";
import Footer from "../src/components/Footer";
import { ConfigProperties } from "./interfaces/customTypes";
import { useAnalyticsInitalize } from "./utils/gtag";

// Only add global styles or framework overrides here. Component styles go with the components themselves.
const GlobalStyle = createGlobalStyle`
  body {
    background-color: #F8F8FA;
    margin: 0;
    padding: 0;

    img.ui.small.image.imageBottom {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
    img.ui.small.image.imageTopLeft {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
  img.ui.small.image {
    max-width: 150px;
  }
`;

interface Props {
  config: ConfigProperties;
}

const App: React.FC<Props> = ({ config }): JSX.Element => {
  useAnalyticsInitalize(config.googleAnalyticsId);

  return (
    <CookiesProvider>
      <ConfigContext.Provider value={config}>
        <IntlProvider locale="en">
          <ThemeProvider theme={theme}>
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <div style={{ flexGrow: 1, overflow: "hidden" }}>
                <Routes {...config} />
              </div>
            </div>
            <GlobalStyle />
          </ThemeProvider>
        </IntlProvider>
      </ConfigContext.Provider>
    </CookiesProvider>
  );
};

export default App;
