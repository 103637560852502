export enum CoachStatuses {
  PENDING_FOR_APPROVAL = "PENDING_FOR_APPROVAL",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum UserStatuses {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  SUSPENDED = "SUSPENDED",
}
