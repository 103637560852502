import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import{ useIntl } from "react-intl";
import { Button } from "semantic-ui-react";
import { get } from "lodash";

import { SideBar, TitleSection, CampaignListContent } from "../../../components";
import { AppContext } from "../../../providers";
import { coachWithWritePermission, URLS } from "../../../utils";
import { WebComponents } from "../../../interfaces";
import { WhiteContainer, ContentGrid, SocialGrid } from "../../../styling/baseStyle";

import { CampaignsType, descriptors } from "./descriptors";
import { RowContainer } from "./styled";
import "./styles.scss";

type Props = RouteComponentProps;

const CampaignsFC: React.FC<Props> = ({ history }) => {
  const { formatMessage } = useIntl();
  const { userContext } = React.useContext(AppContext);
  const writePermission = coachWithWritePermission(WebComponents.CAMPAIGNS, userContext);

  const coachId = get(userContext, "coach.id", undefined);

  const addCampaignHandler = () => {
    localStorage.removeItem("coachNewCampaignStep");
    localStorage.removeItem("coachCampaign");
    history.push(URLS.coach.newCampaigns);
  };

  const customLinkSection = (): JSX.Element => {
    return (
      <SocialGrid.Column floated="right" className={"buttonRight"}>
        <Button
          data-elm-id={`campaignListAddCampaignBtn`}
          className={"primary buttonRight"}
          onClick={addCampaignHandler}>
          {formatMessage({ ...descriptors[CampaignsType.addCampaignBtn] })}
        </Button>
      </SocialGrid.Column>
    );
  };

  return (
    <WhiteContainer>
      <SideBar history={history} />

      <ContentGrid className={"listManagerContent"} columns={1}>
        <SocialGrid columns={1}>
          <TitleSection
            title={formatMessage({ ...descriptors[CampaignsType.title] })}
            ready={true}
            showNextOption={false}
            showLinkOption={writePermission}
            customLinkSection={customLinkSection()}
          />
          <RowContainer>
            <SocialGrid.Column>
              <CampaignListContent coachId={coachId!!} />
            </SocialGrid.Column>
          </RowContainer>
        </SocialGrid>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const Campaigns = withRouter(CampaignsFC)
