import { PostReadyContent } from "../../../../../../../interfaces";

const useDisplayData = (post: PostReadyContent) => {
  const { media, contentId, contentType } = post;

  const mediaType = media.thumbnailUri ? "IMAGE" : media.category;

  const mediaUrl = media.thumbnailUri || media.uri;

  return {
    contentId,
    contentType,
    mediaType,
    mediaUrl,
  };
};

export default useDisplayData;
