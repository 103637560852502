import * as React from "react";
import { Card, Button, Icon, Grid } from "semantic-ui-react";
import { useDrop } from "react-dnd";

interface Props {
  onDelete: () => void;
  onEdit: () => void;
  onDrop: () => void;
  header: string;
}

export enum ItemTypes {
  CARD = "card",
}

export default ({ onDelete, onEdit, children, onDrop, header }: React.PropsWithChildren<Props>) => {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
    drop: () => onDrop(),
  });

  return (
    <div className="ui card" ref={drop} style={{ opacity: isOver ? 0.5 : 1 }}>
      <Card.Content>
        <Grid>
          <Grid.Row style={{ paddingTop: "8px", paddingBottom: "8px" }}>
            <Grid.Column width={6} style={{ paddingTop: "8px" }}>
              <Card.Header className="ui tiny">{header}</Card.Header>
            </Grid.Column>
            <Grid.Column width={6} />
            <Button.Group basic size="tiny">
              <Button compact icon="edit" onClick={onEdit} />
              <Button compact icon="trash" onClick={onDelete} />
            </Button.Group>
          </Grid.Row>
        </Grid>
      </Card.Content>
      <Card.Content style={{ padding: "4px" }}>{children}</Card.Content>
    </div>
  );
};
