import * as React from "react";
import { Formik } from "formik";
import { useIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { get } from "lodash";

import { SocialCoachSessionService, SubCoachesServices } from "../../../services";
import { validationSchema } from "./validations";
import { AppContext } from "../../../providers";
import { SubCoachSignUp } from "../../../components/SubCoachSignUp";
import { InternalGrid } from "../../../styling/baseStyle";
import { toCoachPermission } from "../../../utils";
import { WebComponents, SubCoachSegmentationRequest, SegmentationDetail } from "../../../interfaces";

export interface OwnProps {
  subcoachFirstName: string;
  subcoachLastName: string;
  subcoachEmail: string;
  password?: string;
  coachPermissions: any;
  userId: number;
  createdDate: string;
  latestLoginDate: string;
  formSubmitRef?: any;
  withWritePermission?: boolean;

  onChangeHandler: () => void;
  successHandler: () => void;
  errorHandler: () => void;
}

type Props = OwnProps & RouteComponentProps;

const SubCoachEditFormFC: React.FC<Props> = ({
  formSubmitRef,
  subcoachFirstName,
  subcoachLastName,
  subcoachEmail,
  coachPermissions,
  userId,
  createdDate,
  latestLoginDate,
  onChangeHandler,
  successHandler,
  withWritePermission,
  errorHandler,
}) => {
  const { userContext } = React.useContext(AppContext);
  const coachId = get(userContext, "coach.id", "");

  const basicCoach = userContext?.coach?.type && userContext?.coach?.type === "BASIC";
  const permissions = basicCoach
    ? {
        marketing: get(coachPermissions, "MARKETING", "NONE"),
        pricePlan: get(coachPermissions, WebComponents.PRICE_PLAN, "NONE"),
        library: get(coachPermissions, "LIBRARY", "NONE"),
        campaigns: get(coachPermissions, "CAMPAIGNS", "NONE"),
        videoCatalyst: get(coachPermissions, "VIDEO_CATALYST", "NONE"),
        scriptLibrary: get(coachPermissions, "MANAGE_SCRIPT_LIBRARY", "NONE"),
        players: get(coachPermissions, "PLAYERS", "NONE"),
        compliance: get(coachPermissions, "COMPLIANCE", "NONE"),
        admin: get(coachPermissions, "ADMIN", "NONE"),
        alerts: get(coachPermissions, "ALERTS", "NONE"),
        digestEmail: get(coachPermissions, WebComponents.DIGEST_EMAIL, "NONE"),
        settings: get(coachPermissions, WebComponents.APP_SETTINGS, "NONE"),
      }
    : {
        library: get(coachPermissions, "LIBRARY", "NONE"),
        campaigns: get(coachPermissions, "CAMPAIGNS", "NONE"),
        videoCatalyst: get(coachPermissions, "VIDEO_CATALYST", "NONE"),
        scriptLibrary: get(coachPermissions, "MANAGE_SCRIPT_LIBRARY", "NONE"),
        players: get(coachPermissions, "PLAYERS", "NONE"),
        compliance: get(coachPermissions, "COMPLIANCE", "NONE"),
        admin: get(coachPermissions, "ADMIN", "NONE"),
        alerts: get(coachPermissions, "ALERTS", "NONE"),
        digestEmail: get(coachPermissions, WebComponents.DIGEST_EMAIL, "NONE"),
        settings: get(coachPermissions, WebComponents.APP_SETTINGS, "NONE"),
      };
  return (
    <InternalGrid>
      <Formik
        validateOnBlur={false}
        validateOnChange={true}
        initialValues={{
          firstName: subcoachFirstName,
          lastName: subcoachLastName,
          email: subcoachEmail,
          branches: [],
          states: [],
          regions: [],
          password: "",
          permissions,
          passwordUpdated: false,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors, validateForm }) => {
          const { firstName, lastName, email, password, passwordUpdated, regions, states, branches } = values;

          try {
            const errors = await validateForm({
              firstName,
              lastName,
              email,
              passwordUpdated,
              password,
            });

            if (errors && Object.values(errors).length > 0) {
              setErrors(errors);
              errorHandler();
            } else {
              const permissionsArray = toCoachPermission(values.permissions);
              const branchesToSave = branches.map(branchId => {
                return {
                  segmentationDetailId: Number(branchId),
                  coachId,
                  type: "BRANCH",
                } as SegmentationDetail;
              });

              const regionsToSave = regions.map(branchId => {
                return {
                  segmentationDetailId: Number(branchId),
                  coachId,
                  type: "REGION",
                } as SegmentationDetail;
              });

              const segmentation: SubCoachSegmentationRequest = {
                userId,
                segmentation: branchesToSave.concat(regionsToSave),
              };
              SubCoachesServices.setSegmentation(coachId, segmentation);

              SocialCoachSessionService.updateSubCoach(userId, {
                firstName,
                lastName,
                email: subcoachEmail,
                permissions: permissionsArray,
              }).then(() => {
                if (passwordUpdated) {
                  SocialCoachSessionService.changeSubCoachPassword(userId, password)
                    .then(() => {
                      alert("User updated successfully");
                      successHandler();
                    })
                    .catch(e => {
                      setErrors({
                        email: undefined,
                        password: e,
                        firstName: undefined,
                        lastName: undefined,
                      });
                      errorHandler();
                    });
                } else {
                  alert("User updated successfully");
                  successHandler();
                }
              });
            }
          } catch (e) {
            setErrors({
              email: `${e}`,
              password: undefined,
              firstName: undefined,
              lastName: undefined,
            });
            errorHandler();
          }
          setSubmitting(false);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting }) => {
          return (
            <SubCoachSignUp
              messages={{ error: Object.values(errors) as string[] }}
              coachId={coachId}
              userId={userId}
              handleChange={(e, data) => {
                handleChange(e);
                if (data !== undefined) {
                  if (data.id === "password") {
                    setFieldValue("passwordUpdated", true);
                  }
                }
                onChangeHandler();
              }}
              handleSubmit={handleSubmit}
              formSubmitRef={formSubmitRef}
              handleSetFieldValue={(fieldName, data) => {
                setFieldValue(fieldName, data);
                onChangeHandler();
              }}
              basicCoach={basicCoach}
              latestLoginDate={latestLoginDate}
              createdDate={createdDate}
              isSubmitting={isSubmitting}
              withWritePermission={withWritePermission}
              values={values}
            />
          );
        }}
      </Formik>
    </InternalGrid>
  );
};

export const SubCoachEditForm = withRouter(SubCoachEditFormFC);
