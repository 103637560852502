import React, { useMemo } from "react";

import { Button, Icon, Modal, ModalDescription, Table } from "semantic-ui-react";

import * as DTO from "../../../../interfaces";
import { getPlayerValue, getStatusText, printOnlyDate, userFullName } from "../../../../utils";
import { TitleSubtitleCell } from "../../../Cells";

import { CoachInformation } from "../../../../containers/Admin/CoachInformation";
import { ActionList } from "../../../../containers/Admin/MergedActions";
import { CoachPermissions } from "../../../../containers/Admin/CoachPermissions";
import { ReportServices } from "../../../../services/ReportServices";
import "./styles.scss";
import { AppContext } from "../../../../providers";
import { CoachService } from "../../../../services";
import { modalReducer } from "./modalReducer";
interface OwnProps {
  coachAccount: DTO.CompleteAccount;
  coachStats?: DTO.CoachStatistic;
  coachIndex: number;

  handlerRefresh: () => void;
  handlerImpersonate: (username: string) => void;
}

const CoachCellFC: React.FC<OwnProps> = ({
  coachAccount,
  coachStats,
  coachIndex,
  handlerRefresh,
  handlerImpersonate,
}) => {
  const { profile, user, coach, signUpStep } = coachAccount;
  const [coachDetailsModalOpen, setCoachDetailsModalOpen] = React.useState<boolean>(false);
  const [coachPermissionsModalOpen, setCoachPermissionsModalOpen] = React.useState<boolean>(false);
  const [sendingReport, setSendingReport] = React.useState<boolean>(false);
  const { userContext } = React.useContext(AppContext);

  const [state, dispatch] = React.useReducer(modalReducer, { open_edit: false, open_delete: false });

  const numberOfPlayers =
    coachStats &&
    coachStats.playersReport &&
    (getPlayerValue(DTO.PlayerStatisticType.ACTIVE_PLAYERS, coachStats.playersReport.stats) || 0) +
      (getPlayerValue(DTO.PlayerStatisticType.TRIAL_PLAYERS, coachStats.playersReport.stats) || 0);

  const tag = useMemo(() => {
    const groupStrings = user.groups.map(g => g.toString());
    if (groupStrings.includes("ADMIN")) {
      return "Admin";
    } else if (groupStrings.includes("EDITOR")) {
      return "Editor";
    } else if (user.isDemo) {
      return "Demo";
    }
    return undefined;
  }, [user]);

  const coachModal = (): JSX.Element => {
    return (
      <Modal
        data-elm-id={`admin-dashboard-modal-${user.userId}`}
        open={coachDetailsModalOpen}
        onClose={() => setCoachDetailsModalOpen(false)}
        closeIcon={true}
      >
        <Modal.Header> Coach Information</Modal.Header>
        <Modal.Content>
          <CoachInformation
            profile={profile!!}
            user={user}
            coach={coach!!}
            signUpStep={signUpStep!!}
            handleUpdateSuccess={() => handlerRefresh()}
          />
        </Modal.Content>
      </Modal>
    );
  };

  const deleteCoach = () => {
    CoachService.delete(coach?.id!!)
      .then(() => {
        alert("The coach has been deleted.");
        handlerRefresh();
      })
      .catch(e => alert(e));
  };

  const deleteModal = (): JSX.Element => {
    return (
      <Modal
        open={state.open_delete}
        size={"small"}
        onClose={() => dispatch({ type: "close_delete" })}
        closeIcon={true}
      >
        <Modal.Header>Warning!!</Modal.Header>
        <Modal.Content>
          <ModalDescription>
            <p className="pDelete">
              <strong>WARNING!!!</strong>
              {` Deleting this coach will permanently DELETE all data associated to this user ${user.email} and all their players
                 including profile data, media library, campaigns, posts, and subscription history.
              
              This cannot be undone.`}
            </p>
          </ModalDescription>
        </Modal.Content>

        <Modal.Actions>
          <Button
            secondary
            data-elm-id={`basicModalNoBtn`}
            onClick={() => {
              dispatch({ type: "close_delete" });
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            data-elm-id={`basicModalYesBtn`}
            onClick={() => {
              dispatch({ type: "close_delete" });
              deleteCoach();
            }}
          >
            DELETE
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  const handleAction = (index: number): void => {
    switch (index) {
      case 0:
        return setCoachDetailsModalOpen(true);
      case 1:
        return setCoachPermissionsModalOpen(true);
      case 2:
        return handlerImpersonate(user.email!);
      case 3:
        return dispatch({ type: "open_delete" });
    }
  };

  const sendReportHandler = () => {
    setSendingReport(true);
    ReportServices.sendCoachReport(coachAccount.coach!!.id)
      .then(() => {
        alert("Success! Your report was sent to your pre-selected email address.");
      })
      .catch(e => {
        alert("Oops. Something went wrong. Please refresh this page and try again.");
      })
      .finally(() => {
        setSendingReport(false);
      });
  };

  const actions =
    coach?.coachStatus === DTO.CoachStatuses.INACTIVE &&
    userContext?.account.scGroups?.includes(DTO.UserGroups.SYSTEM_ADMIN)
      ? [
          {
            content: "Edit",
            icon: "pencil",
          },
          {
            content: "Coach Settings",
            icon: "cogs",
          },
          {
            content: "Impersonate",
            icon: "sign-in",
          },
          {
            content: "Delete Coach",
            icon: "trash",
          },
        ]
      : [
          {
            content: "Edit",
            icon: "pencil",
          },
          {
            content: "Coach Settings",
            icon: "cogs",
          },
          {
            content: "Impersonate",
            icon: "sign-in",
          },
        ];

  return (
    <Table.Row key={coachIndex}>
      <TitleSubtitleCell title={user.email} subtitle={userFullName(profile!)} tag={tag} />
      <Table.Cell>{printOnlyDate(user!!.createdDate!)}</Table.Cell>
      <Table.Cell>
        {coach!!.coachStatus!! === "ACTIVE" && <Icon color="green" name="checkmark" size="small" />}
        {getStatusText(coach!!.coachStatus!!)}
      </Table.Cell>
      <Table.Cell>{coachStats && coachStats.promptEngagementReport.published}</Table.Cell>
      <Table.Cell>{coachStats && coachStats.promptEngagementReport.lifetimeSent}</Table.Cell>
      <Table.Cell>{coachStats && coachStats.promptEngagementReport.remaining}</Table.Cell>
      <Table.Cell>{numberOfPlayers}</Table.Cell>
      <Table.Cell>{coachStats && coachStats.promptEngagementReport.randomScheduleDays}</Table.Cell>
      <Table.Cell>
        {coachStats && printOnlyDate(coachStats.promptEngagementReport.lastSuccessfulDeliveryDate)}
      </Table.Cell>
      {/* <Table.Cell>{profile?.socialNetworks?.length}</Table.Cell> */}
      <Table.Cell>
        <ActionList items={actions} handleAction={async index => handleAction(index)} />
        {/* {(numberOfPlayers && numberOfPlayers > 0) ? <Button
          disabled={sendingReport}
          className="secondary sendButton"
          loading={sendingReport}
          onClick={sendReportHandler}>
          Send Report
        </Button> : <div/>}  */}

        <Button
          disabled={sendingReport || numberOfPlayers === undefined || numberOfPlayers === 0}
          className="secondary sendButton"
          loading={sendingReport}
          onClick={sendReportHandler}
        >
          Send Report
        </Button>
      </Table.Cell>
      {coachModal()}
      {coachPermissionsModalOpen && (
        <CoachPermissions
          opened={coachPermissionsModalOpen}
          coach={coach!!}
          handleUpdateSuccess={() => {
            setCoachPermissionsModalOpen(false);
          }}
        />
      )}
      {deleteModal()}
    </Table.Row>
  );
};

export const CoachCell = CoachCellFC;
