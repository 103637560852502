import * as React from "react";
import { useIntl } from "react-intl";
import { Radio, Header } from "semantic-ui-react";
import { head } from "lodash";

import * as DTO from "../../interfaces";
import { PlayersListWithBranch } from "../../components";
import { PlayersServices } from "../../services";

import { BranchSearch } from "../BranchSearch";

import {
  TransparentContainer,
  ContentGrid,
  SocialGrid,
  RowNoPadding,
  SmallGrid,
  SegmentedGrid,
} from "../../styling/baseStyle";

import { SegmentationType, descriptors } from "./descriptors";
import "./styles.scss";

interface OwnProps {
  selectedPlayers: number[];
  segmentedCampaign?: boolean;
  criteria?: "include" | "exclude";
  parentResource?: string;
  disabled?: boolean;
  onChange: (selectedPlayers: number[], isInclude: boolean) => void;
  isSegmentedOnChange: (isSegmented: boolean) => void;
}

type Props = OwnProps; 

const SegmentationFC: React.FC<Props> = ({
  segmentedCampaign = false,
  selectedPlayers,
  onChange,
  disabled = false,
  parentResource = "campaign",
  criteria = "include",
  isSegmentedOnChange,
}) => {
  const { formatMessage } = useIntl();
  const [playersProcessList, setPlayersProcessList] = React.useState<
    DTO.PlayerWithBranch[]
  >([]);

  const [segmentation, setSegmentation] = React.useState<string>(
    segmentedCampaign ? "specific" : "all"
  );
  const [eligibilityCriteria, setEligibilityCriteria] =
    React.useState<string>(criteria);

  const isInclude = eligibilityCriteria === "include";
  const isSegmented = segmentation === "specific";

  React.useEffect(() => {
    isSegmentedOnChange(isSegmented);
  }, [segmentation]);

  React.useEffect(() => {
    onChange(selectedPlayers, isInclude);
  }, [eligibilityCriteria]);

  const preparePlayerList = (
    playersList: DTO.SubscribedPlayerSearchResponse[]
  ): void => {
    const playerWithStatsResponse: DTO.PlayerWithBranch[] = playersList.map(
      (player) => {
        return {
          info: player.playerAccount,
          photoUrl: player.playerAccount.photoUrl,
          email: player.playerAccount.email,
          branch: head(player.branchNames),
          region: head(player.regionNames),
        } as DTO.PlayerWithBranch;
      }
    );

    setPlayersProcessList(() => playerWithStatsResponse);
  };
  const segmentationOnChange = (e: any, data: any) =>
    setSegmentation(data.value);
  const elegibilityOnChange = (e: any, data: any) =>
    setEligibilityCriteria(data.value);

  const resourceType = parentResource;

  const specificPlayersSection = (): JSX.Element => {
    return (
      <ContentGrid.Row className={"playersListRowContainer"}>
        <SocialGrid.Column>
          <div className={"darkLine"} />

          <SocialGrid>
            <SmallGrid className={"bigxLeftPadding"}>
              <RowNoPadding>
                <Radio
                  label={formatMessage(
                    {
                      ...descriptors[SegmentationType.excludeLabel],
                    },
                    { campaignOrPrompt: resourceType }
                  )}
                  name="eligibilityRadioGroup"
                  value="exclude"
                  checked={eligibilityCriteria === "exclude"}
                  onChange={elegibilityOnChange}
                />
              </RowNoPadding>

              <RowNoPadding>
                <Radio
                  label={formatMessage(
                    {
                      ...descriptors[SegmentationType.includeLabel],
                    },
                    { campaignOrPrompt: resourceType }
                  )}
                  name="eligibilityRadioGroup"
                  value="include"
                  checked={eligibilityCriteria === "include"}
                  onChange={elegibilityOnChange}
                />
              </RowNoPadding>
            </SmallGrid>
            <SocialGrid.Row>
              <ContentGrid.Column>
                <BranchSearch
                  refreshData={preparePlayerList}
                  content={
                    <PlayersListWithBranch
                      players={playersProcessList}
                      selectedPlayers={selectedPlayers}
                      onChangeSelectedPlayer={(
                        playerIds: number[],
                        checked: boolean
                      ) => {
                        let newSelected = selectedPlayers;
                        if (checked) {
                          playerIds.forEach((playerId) =>
                            newSelected.push(playerId)
                          );
                        } else {
                          newSelected = newSelected.filter((pId) => {
                            return !playerIds.includes(pId);
                          });
                        }
                        onChange(newSelected, isInclude);
                      }}
                    />
                  }
                  searchFunction={PlayersServices.searchSubscribedPlayersByTag}
                  multiple={true}
                />
              </ContentGrid.Column>
            </SocialGrid.Row>
          </SocialGrid>
        </SocialGrid.Column>
      </ContentGrid.Row>
    );
  };
  return (
    <TransparentContainer className={disabled ? "disabledForm" : ""}>
      <SegmentedGrid className={"segmentationManagerContent"} columns={1}>
        <SocialGrid.Row>
          <Header as="h4">
            {" "}
            {formatMessage(
              {
                ...descriptors[SegmentationType.title],
              },
              { campaignOrPrompt: resourceType }
            )}
            <span>
              {formatMessage({ ...descriptors[SegmentationType.subtitle] })}
            </span>
          </Header>
        </SocialGrid.Row>
        <SmallGrid className={"bigxLeftPadding"}>
          <RowNoPadding>
            <Radio
              label={formatMessage(
                {
                  ...descriptors[SegmentationType.allPlayersLabel],
                },
                { campaignOrPrompt: resourceType }
              )}
              name="radioGroup"
              value="all"
              checked={segmentation === "all"}
              onChange={segmentationOnChange}
            />
          </RowNoPadding>
          <RowNoPadding>
            <Radio
              label={formatMessage(
                {
                  ...descriptors[SegmentationType.specificPlayersLabel],
                },
                { campaignOrPrompt: resourceType }
              )}
              name="radioGroup"
              value="specific"
              checked={segmentation === "specific"}
              onChange={segmentationOnChange}
            />
          </RowNoPadding>
        </SmallGrid>
        {segmentation === "specific" && specificPlayersSection()}
      </SegmentedGrid>
    </TransparentContainer>
  );
};

export const PlayersSegmentation = SegmentationFC;
