import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Form, Grid } from "semantic-ui-react";
import { get } from "lodash";

import { AppContext } from "../../providers";
import { TagElement } from "../../interfaces";

import { HashTagSet } from "./components/HashTagSet";
import "./styles.scss";

interface OwnProps {
  /**
   * Handle Hashtags changes
   */
  onHashtagsChanges: (hashtags: TagElement[]) => void;
}

type Props = OwnProps & RouteComponentProps;

/**
 * Represents Coach Hashtags tab section
 */
const CoachHashtagsTabFC: React.FC<Props> = ({ onHashtagsChanges }) => {
  const { userContext } = React.useContext(AppContext);
  const coachId = get(userContext, "coach.id", "");
  const [hashtagsListError, setHashtagsListError] = React.useState<{ [key: string]: string } | undefined>();
  const [updatedHashTagsList, setUpdatedHashTagsList] = React.useState<TagElement[] | undefined>(undefined);

  React.useEffect(() => {
    onHashtagsChanges(updatedHashTagsList || []);
  }, [updatedHashTagsList]);

  const errorHashTagHandler = (keywords: string[]): void => {
    // setHashtagsListError(() => setErrors(keywords));
  };

  const content = (
    <React.Fragment>
      <Grid columns={1} padded>
        <Grid.Column>
          <Form.Group grouped key={"appSettingsGroupPermissions"}>
            <Grid.Row>
              <HashTagSet
                title={"Default Hashtags"}
                coachId={coachId}
                customTitleClassName={"hashTagSetTitle titleBigSize"}
                customRowClassName={"noPaddingTop"}
                onError={errorHashTagHandler}
                customTagStyle={`background: rgba(61, 174, 245, 0.2);`}
                onSuccess={updatedHashTags => {
                  setUpdatedHashTagsList(updatedHashTags);
                }}
              />
            </Grid.Row>
          </Form.Group>
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );

  return (
    <Grid columns={16} verticalAlign="middle" stretched container textAlign={"left"} className={"customFloatedGrid"}>
      <Grid.Column width={16}>{content}</Grid.Column>
    </Grid>
  );
};

export const CoachHashtagsTab = withRouter(CoachHashtagsTabFC);
