import { VideoProjectWithPlayer } from "..";
import { Category } from "../models";

export interface VideoScript {
  videoScriptId?: number;
  coachId?: number | string;
  playerId?: number | string;
  title: string;
  content: string;
  categories: Category[];
}

export interface VideoScriptDetails {
  videoScriptId?: number;
  ownerName: string;
  createdDate: string;
  updatedDate?: string;
  totalVideos: number;
  totalVideosLast30Days: number;
  latestVideoProjects: VideoProjectWithPlayer[];
}

export interface VideoScriptRequest {
  coachId?: number;
  playerId?: number;
  title: string;
  content: string;
  categories: string[];
}

export interface CategorizedVideoScripts {
  category: Category;
  scripts: VideoScript[];
}

export interface VideoScripts {
  categorizedScripts: CategorizedVideoScripts[];
}

export interface ScriptNotificationRequest {
  scriptId: number;
  title: string;
  message: string;
}

export interface ScriptNotificationResponse {
  numberOfPlayers: number;
}

export enum VideoScriptOrderType {
  ALPHABETICAL = "ALPHABETICAL",
  SHUFFLED = "SHUFFLED",
}

export interface VideoScriptsSearchResponse {
  scripts: VideoScript[];
}
