import * as React from "react";
import{ useIntl } from "react-intl";
import * as DTO from "../../../../../../../interfaces";

import { CategoryRow } from "../CategoryRow";

interface OwnProps {
  promptList: DTO.CampaignResume[];
  categories: DTO.Category[];
  campaignPrompts: DTO.SelectedCampaign;
  coachId: string;

  onAddCampaignHandler: (prompt: DTO.CampaignResume) => void;
  onRemovePromptHandler: (prompt: DTO.CampaignResume) => void;
}

type Props = OwnProps 

export default 
  ({ promptList, categories, campaignPrompts, onAddCampaignHandler, onRemovePromptHandler }: Props) => {
    const [categoriesHash, setCategoriesHash] = React.useState<{ [key: string]: string }>({});
    const [withCategorizedLibraryPrompts, setWithCategorizedLibraryPrompts] = React.useState<boolean>(false);

    React.useEffect(() => {
      const convertToHash = (result: DTO.Category[]): { [key: string]: string } => {
        let hash = {};
        result.map(category => {
          hash = {
            ...hash,
            [category.categoryId!]: category.name,
          };
        });

        return hash;
      };

      setCategoriesHash(convertToHash(categories));
    }, [categories]);

    const uncategorizedPrompts = (): DTO.CampaignResume[] => {
      return promptList.filter(campaign => {
        return campaign.categoryIds?.length === 0 && campaign.campaignStatus !== "DRAFT";
      });
    };

    const uncategorized = uncategorizedPrompts();

    const promptFilteredByCategory = (categoryId: string): DTO.CampaignResume[] => {
      const promptsFiltered = promptList.filter(campaign => {
        return campaign.categoryIds?.includes(categoryId) && campaign.campaignStatus !== "DRAFT";
      });

      if (!withCategorizedLibraryPrompts && promptsFiltered.length > 0) {
        setWithCategorizedLibraryPrompts(true);
      }
      return promptsFiltered;
    };

    const showPromps = (category: DTO.Category): JSX.Element | undefined => {
      const prompts = promptFilteredByCategory(category.categoryId!);
      if (prompts.length === 0) {
        return undefined;
      }
      return (
        <CategoryRow
          key={category.categoryId}
          category={category}
          campaignList={prompts}
          totalElements={prompts.length}
          name={categoriesHash[category.categoryId!]}
          campaignPrompts={campaignPrompts}
          onAddPrompt={onAddCampaignHandler}
          onRemovePrompt={onRemovePromptHandler}
        />
      );
    };

    return (
      <div className="promptListContainer">
        {uncategorized.length > 0 && (
          <CategoryRow
            key={"uncategorized"}
            name={"Uncategorized"}
            campaignList={uncategorized}
            totalElements={uncategorized.length}
            campaignPrompts={campaignPrompts}
            onAddPrompt={onAddCampaignHandler}
            onRemovePrompt={onRemovePromptHandler}
          />
        )}

        {categories.map(category => showPromps(category))}
      </div>
    );
  }
