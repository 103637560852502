import * as React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Form, Header, Input, Grid, Icon, Popup, Message } from "semantic-ui-react";

import { BasicFormProps, CoachAppSetting, CoachSettings, TagElement } from "../../../../interfaces";
import { noSpaces, removeSpecialCharacters } from "../../../../utils";

import { accountInformationFields } from "./formFields";
import { descriptors, ProfileContactInformationType } from "./descriptors";
import { HashTagSet } from "../../../Forms/EditPlayer/HashTagSet";

import "./styles.scss";
import { SettingsServices } from "../../../../services";
import { get, groupBy } from "lodash";

interface OwnProps extends BasicFormProps {
  isSubmitting?: boolean;
  shortLinkBase: string;
  playerId: number;
  coachId: string;
  boxClassName?: string;
  handleSetFieldValue: (field: string, value: any) => void;
  handleUpdateHashTags: (hashtags: TagElement[]) => void;
}

type Props = OwnProps;

const IntegratedPlayerAccountInformation: React.FC<Props> = ({
  handleSetFieldValue,
  handleUpdateHashTags,
  shortLinkBase = "",
  isSubmitting = false,
  boxClassName = " leftPadding",
  values,
  playerId,
  coachId,
}) => {
  const [updatedHashTagsList] = React.useState<TagElement[]>([]);
  const [hashtagsListError, setHashtagsListError] = React.useState<string[] | undefined>();
  const [coachAppSettings, setCoachAppSettings] = React.useState<CoachAppSetting | undefined>(undefined);
  const [showHashTagSection, setShowHashTagSection] = React.useState<boolean>(false);

  const { formatMessage } = useIntl();

  React.useEffect(() => {
    if (coachId) {
      SettingsServices.getAppSettingsByCoachId(coachId).then(setCoachAppSettings);
    }
  }, []);

  React.useEffect(() => {
    if (coachAppSettings) {
      const coachAppSettingsGrouped = groupBy(coachAppSettings?.settings, b => b.appSettingId);
      const coachStatus: string = get(coachAppSettingsGrouped[CoachSettings.PLAYER_HASHTAG], "[0].status", "ACTIVE");
      setShowHashTagSection(coachStatus !== "INACTIVE");
    }
  }, [coachAppSettings]);

  const errorHashTagHandler = (keywords: string[]): void => {
    setHashtagsListError(keywords);
  };

  React.useEffect(() => {
    handleUpdateHashTags(updatedHashTagsList);
  }, [updatedHashTagsList]);

  return (
    <>
      <Grid.Row className={"customizationRow smallTop"}>
        <Header as="h4" textAlign={"center"}>
          <FormattedMessage {...descriptors[ProfileContactInformationType.title]} />
        </Header>
        <div className={"profileInformation"}>
          {accountInformationFields.map((field, index) => {
            return (
              <Form.Group widths="equal" key={"customizations-" + field.id + "group" + index}>
                <Form.Field>
                  <label className={"customizationInputLabel"}>
                    {formatMessage({ ...descriptors[field.placeholder] })}
                    {field.showInfo && (
                      <Popup
                        content={formatMessage({ ...descriptors[field.placeholder + "Info"] })}
                        trigger={<Icon name="info circle" className={"infoIcon"} link />}
                      />
                    )}
                  </label>
                  <Input
                    key={field.id + index}
                    className={"inputWithSlugLabel"}
                    label={field.id === "slug" && `${shortLinkBase}`}
                    fluid
                    data-elm-id={"playerProfileInformation-" + field.id}
                    type={"text"}
                    id={field.id}
                    name={field.name}
                    placeholder={formatMessage({ ...descriptors[field.placeholder] })}
                    onChange={e => {
                      const newValue =
                        field.id === "webAddress"
                          ? noSpaces(e.currentTarget.value)
                          : removeSpecialCharacters(e.currentTarget.value);
                      handleSetFieldValue(e.currentTarget.id, newValue);
                    }}
                    value={values[field.id]}
                    disabled={isSubmitting}
                  />
                </Form.Field>
              </Form.Group>
            );
          })}
        </div>
      </Grid.Row>

      {/* HASH TAGS */}
      {showHashTagSection && (
        <Grid.Row className={"customizationRow"}>
          <Header as="h4" textAlign={"center"}>
            <FormattedMessage {...descriptors[ProfileContactInformationType.hashtagsTitle]} />
          </Header>
          <div className={"profileInformation"}>
            {hashtagsListError && hashtagsListError.length > 0 && (
              <Grid className={"marginBottom marginTop paddingTop leftPadding"}>
                <Grid.Row columns={1}>
                  <Message floating error list={hashtagsListError} />
                </Grid.Row>
              </Grid>
            )}

            <Grid className={"marginBottom paddingTop" + boxClassName}>
              <HashTagSet
                asPlayer={true}
                title={"Custom Hashtags"}
                playerId={playerId}
                coachId={coachId}
                addHashTagAutomatically={values["addHashTagAutomatically"]}
                customTitleClassName={"customizationHashTagInputLabel"}
                onError={errorHashTagHandler}
                customTagStyle={`background: rgba(61, 174, 245, 0.2);`}
                addHashtagOnChange={checked => handleSetFieldValue("addHashTagAutomatically", checked)}
                onSuccess={handleUpdateHashTags}
              />
            </Grid>
          </div>
        </Grid.Row>
      )}
    </>
  );
};

export const PlayerCustomizations = IntegratedPlayerAccountInformation;
