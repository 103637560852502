import * as React from "react";
import * as MODEL from "../../interfaces/models";
import ProfileSocialAccounts from "../Coaches/Profile/ProfileSocialAccounts";
import { TacSection } from "../TacSection";

import "./styles.scss";

interface OwnProps {
  /**
   * SocialNetworks handler
   */
  handleSocialNetworks?: (socialNetworks: MODEL.Social) => void;

  /**
   * Accept terms and conditions handler
   */
  handleTacChange: (checked: boolean) => void;
}

type Props = OwnProps;

const SocialNetworks: React.FC<Props> = ({ handleSocialNetworks, handleTacChange }) => {
  return (
    <div className={"socialContainer"}>
      {/* <ProfileSocialAccounts
        type={"SIGNUP"}
        deleteEnable={false}
        handleSocialNetworks={networks => {
          handleSocialNetworks!(networks);
        }}
      /> */}
      <TacSection handleTacChange={handleTacChange} dataElmId={"registerSocialContainer"} />
    </div>
  );
};

export default SocialNetworks;
