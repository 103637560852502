import * as React from "react";
import { FormattedMessage } from "react-intl";

interface Props {
  message: any;
  type?: "underline" | "" | "blueButton";
  onClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

/**
 * Represents a ButtonLink component.
 */
const ButtonLinkFC: React.FC<Props> = ({ message, type = "", onClick }) => {
  return (
    <div className="buttons-link">
      <span onClick={onClick} className={type}>
        <FormattedMessage {...message} />
      </span>
    </div>
  );
};

export const ButtonLink = ButtonLinkFC;
