import * as React from "react";
import { Formik } from "formik";
import { get } from "lodash";

import { ShortLinksServices, SubscriptionServices } from "../../../services";
import { UpdatePlayerRequest, ShortLinkResponse, ShortLinkRequest } from "../../../interfaces/dtos";
import { EditPlayer } from "../../../components/Forms/EditPlayer";

import { manageWebAddress, removeSpecialCharacters } from "../../../utils";
import { validationSchema } from "./validations";

export interface OwnProps {
  playerId: number;
  coachId: string;
  branches: string[];
  regions: string[];
  states: string[];
  costCenters: string[];
  shortLinkObject?: ShortLinkResponse;
  formRef?: any;

  setLoading: (loading: boolean) => void;
  successHandler: () => void;
  errorHandler: () => void;
  onChangeHandler: () => void;
}

type Props = OwnProps;

const EditPlayerFormFC: React.FC<Props> = ({
  playerId,
  coachId,
  branches,
  regions,
  states,
  costCenters,
  formRef,
  shortLinkObject,
  setLoading,
  successHandler,
  errorHandler,
  onChangeHandler,
}) => {
  const shortLinkEditable = shortLinkObject?.slug !== null;

  const updateShortLinkInfo = (slug: string, webAddress: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      const available =
        slug !== removeSpecialCharacters(shortLinkObject?.slug)
          ? ShortLinksServices.checkSlug(slug)
          : Promise.resolve(true);
      available
        .then(() => {
          const updateShortLink: ShortLinkRequest = {
            slug,
            webAddress,
          };
          ShortLinksServices.update(playerId, updateShortLink)
            .then(resolve)
            .catch(reject);
        })
        .catch(() => reject("Short link is already taken"));
    });
  };

  const shortLinkBase = shortLinkObject?.shortLink ? shortLinkObject?.shortLink.split("/") : undefined;
  const shortLinkBaseString = shortLinkBase ? "https://" + shortLinkBase[shortLinkBase.length - 2] + "/" : "";
  return (
    <div>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          branch: branches.length > 0 ? branches[0] : "",
          state: states,
          region: regions.length > 0 ? regions[0] : "",
          costCenter: costCenters.length > 0 ? costCenters[0] : "",
          shortLinkUpdated: false,
          slug: get(shortLinkObject, "slug", undefined),
          webAddress: get(shortLinkObject, "webAddress", undefined),
        }}
        validationSchema={validationSchema}
        onSubmit={async (
          { branch, region, state, slug, webAddress, shortLinkUpdated, costCenter },
          { setSubmitting, setErrors, validateForm }
        ) => {
          try {
            const webAddressToSave = webAddress?.endsWith("/") ? webAddress.slice(0, -1) : webAddress;
            const errors = await validateForm({
              shortLinkUpdated,
              webAddress: webAddressToSave,
              slug,
            });

            if (errors && Object.values(errors).length > 0) {
              setErrors(errors);
              errorHandler();
              setLoading(false);
              setSubmitting(false);
            } else {
              setLoading(true);
              setSubmitting(true);

              const updatePlayerRequest: UpdatePlayerRequest = {
                branchNames: branch ? [branch] : undefined,
                stateCodes: state ? state : undefined,
                regionNames: region ? [region] : undefined,
                costCenterNames: costCenter ? [costCenter] : undefined,
              };

              const webAddressWithFormat = await manageWebAddress(webAddress!);
              SubscriptionServices.update(playerId, coachId, updatePlayerRequest)
                .then(_ => {
                  if (shortLinkUpdated) {
                    updateShortLinkInfo(slug!, webAddressWithFormat)
                      .then(() => successHandler())
                      .catch(e => {
                        setErrors({
                          branch: e,
                          state: undefined,
                          region: undefined,
                        });
                        errorHandler();
                      })
                      .finally(() => {
                        setSubmitting(false);
                        successHandler();
                      });
                  } else {
                    setSubmitting(false);
                    successHandler();
                  }
                })
                .catch(e => {
                  setLoading(false);
                  setSubmitting(false);
                  setErrors({
                    branch: e,
                    state: undefined,
                    region: undefined,
                  });
                  errorHandler();
                });
            }
          } catch (e) {
            setLoading(false);
            setSubmitting(false);
            setErrors({
              branch: `${e}`,
              state: undefined,
              region: undefined,
            });
          }
        }}>
        {({ values, errors, handleSubmit, setFieldValue, isSubmitting }) => (
          <EditPlayer
            messages={{ error: Object.values(errors) as string[] }}
            handleSubmit={handleSubmit}
            formRef={formRef}
            shortLinkBase={shortLinkBaseString}
            handleSetFieldValue={(field, value) => {
              if (field === "slug" || field === "webAddress") {
                setFieldValue("shortLinkUpdated", shortLinkEditable);
              }
              setFieldValue(field, value);
              onChangeHandler();
            }}
            coachId={coachId}
            isSubmitting={isSubmitting}
            values={values}
          />
        )}
      </Formik>
    </div>
  );
};

export const EditPlayerForm = EditPlayerFormFC
