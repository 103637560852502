import { defineMessages } from "react-intl";

export enum SubCoachSignUpType {
  segmenationSectionTitle = "SubCoachSignUpType.segmenationSectionTitle",
  email = "SubCoachSignUpType.email",
  password = "SubCoachSignUpType.password",
  firstName = "SubCoachSignUpType.firstName",
  lastName = "SubCoachSignUpType.lastName",
  branches = "SubCoachSignUpType.branches",
  regions = "SubCoachSignUpType.regions",

  submit = "SubCoachSignUpType.submit",

  warningTitle = "SubCoachSignUpType.warningTitle",
  errorTitle = "SubCoachSignUpType.errorTitle",
  infoTitle = "SubCoachSignUpType.infoTitle",

  passwordMin = "SubCoachSignUpType.passwordMin",
  passwordWithNumber = "SubCoachSignUpType.passwordNumber",
  passwordWithSpecialCharacter = "SubCoachSignUpType.passwordSpecialCharacter",
  passwordWithCapital = "SubCoachSignUpType.passwordCapital",

  segmenationPopUpInfo = "SubCoachSignUpType.segmenationPopUpInfo",
}

export const en = {
  [SubCoachSignUpType.segmenationSectionTitle]: "Admin Player Assignments",
  [SubCoachSignUpType.email]: "Email Address",
  [SubCoachSignUpType.password]: "Password",
  [SubCoachSignUpType.firstName]: "First Name",
  [SubCoachSignUpType.lastName]: "Last Name",
  [SubCoachSignUpType.branches]: "Branches",
  [SubCoachSignUpType.regions]: "Regions",

  [SubCoachSignUpType.submit]: "Next",

  [SubCoachSignUpType.warningTitle]: "Warning",
  [SubCoachSignUpType.errorTitle]: "Sign Up error:",
  [SubCoachSignUpType.infoTitle]: "Hey",

  [SubCoachSignUpType.passwordMin]: "Password must be at least 8 characters.",
  [SubCoachSignUpType.passwordWithCapital]: "Password must be at least 1 capital letter.",
  [SubCoachSignUpType.passwordWithNumber]: "Password must be at least 1 numeric value.",
  [SubCoachSignUpType.passwordWithSpecialCharacter]: "Password must be at least 1 special character.",

  [SubCoachSignUpType.segmenationPopUpInfo]:
    "Please select the Branches and/or Regions this Admin should have access to.",
};

export const descriptors = defineMessages({
  [SubCoachSignUpType.segmenationPopUpInfo]: {
    id: SubCoachSignUpType.segmenationPopUpInfo,
    defaultMessage: en[SubCoachSignUpType.segmenationPopUpInfo],
    description: "-",
  },
  [SubCoachSignUpType.segmenationSectionTitle]: {
    id: SubCoachSignUpType.segmenationSectionTitle,
    defaultMessage: en[SubCoachSignUpType.segmenationSectionTitle],
    description: "-",
  },
  [SubCoachSignUpType.email]: {
    id: SubCoachSignUpType.email,
    defaultMessage: en[SubCoachSignUpType.email],
    description: "Email address",
  },
  [SubCoachSignUpType.password]: {
    id: SubCoachSignUpType.password,
    defaultMessage: en[SubCoachSignUpType.password],
    description: "Password text",
  },
  [SubCoachSignUpType.firstName]: {
    id: SubCoachSignUpType.firstName,
    defaultMessage: en[SubCoachSignUpType.firstName],
    description: "firstName text",
  },
  [SubCoachSignUpType.lastName]: {
    id: SubCoachSignUpType.lastName,
    defaultMessage: en[SubCoachSignUpType.lastName],
    description: "lastName text",
  },
  [SubCoachSignUpType.branches]: {
    id: SubCoachSignUpType.firstName,
    defaultMessage: en[SubCoachSignUpType.branches],
    description: "branches",
  },
  [SubCoachSignUpType.regions]: {
    id: SubCoachSignUpType.regions,
    defaultMessage: en[SubCoachSignUpType.regions],
    description: "regions",
  },
  [SubCoachSignUpType.submit]: {
    id: SubCoachSignUpType.submit,
    defaultMessage: en[SubCoachSignUpType.submit],
    description: "",
  },
  [SubCoachSignUpType.warningTitle]: {
    id: SubCoachSignUpType.warningTitle,
    defaultMessage: en[SubCoachSignUpType.warningTitle],
    description: "Warning messages title",
  },
  [SubCoachSignUpType.errorTitle]: {
    id: SubCoachSignUpType.errorTitle,
    defaultMessage: en[SubCoachSignUpType.errorTitle],
    description: "Error Messages title",
  },
  [SubCoachSignUpType.infoTitle]: {
    id: SubCoachSignUpType.infoTitle,
    defaultMessage: en[SubCoachSignUpType.infoTitle],
    description: "Info messages title",
  },
  [SubCoachSignUpType.passwordMin]: {
    id: SubCoachSignUpType.passwordMin,
    defaultMessage: en[SubCoachSignUpType.passwordMin],
    description: "passwordMin",
  },
  [SubCoachSignUpType.passwordWithCapital]: {
    id: SubCoachSignUpType.passwordWithCapital,
    defaultMessage: en[SubCoachSignUpType.passwordWithCapital],
    description: "passwordWithCapital text",
  },
  [SubCoachSignUpType.passwordWithNumber]: {
    id: SubCoachSignUpType.passwordWithNumber,
    defaultMessage: en[SubCoachSignUpType.passwordWithNumber],
    description: "passwordWithNumber",
  },
  [SubCoachSignUpType.passwordWithSpecialCharacter]: {
    id: SubCoachSignUpType.passwordWithSpecialCharacter,
    defaultMessage: en[SubCoachSignUpType.passwordWithSpecialCharacter],
    description: "passwordWithSpecialCharacter",
  },
});
