import { defineMessages } from "react-intl";

export enum DashboardType {
  statisticsSectionTitle = "DashboardType.statisticsSectionTitle",

  posts = "DashboardType.posts",
  followers = "DashboardType.followers",
  likes = "DashboardType.likes",
  comments = "DashboardType.comments",
  shares = "DashboardType.shares",
  clicks = "DashboardType.clicks",

  watchMessage = "DashboardType.watchMessage",
  quickActionsSectionTitle = "DashboardType.quickActionsSectionTitle",
  needsAttentionSectionTitle = "DashboardType.needsAttentionSectionTitle",
  readyToPostSectionTitle = "DashboardType.readyToPostSectionTitle",
}

export const en = {
  [DashboardType.statisticsSectionTitle]: "Your social insights",
  [DashboardType.quickActionsSectionTitle]: "Get started",
  [DashboardType.needsAttentionSectionTitle]: "Needs your attention",
  [DashboardType.readyToPostSectionTitle]: "Ready To Post",

  [DashboardType.posts]: "Post",
  [DashboardType.followers]: "Followers",
  [DashboardType.likes]: "Likes",
  [DashboardType.comments]: "Comments",
  [DashboardType.shares]: "Shares",
  [DashboardType.clicks]: "Clicks",

  [DashboardType.watchMessage]: "Needs Attention", // TODO: REMOVE
};

export const descriptors = defineMessages({
  [DashboardType.readyToPostSectionTitle]: {
    id: DashboardType.readyToPostSectionTitle,
    defaultMessage: en[DashboardType.readyToPostSectionTitle],
    description: "-",
  },
  [DashboardType.watchMessage]: {
    id: DashboardType.watchMessage,
    defaultMessage: en[DashboardType.watchMessage],
    description: "-",
  },
  [DashboardType.quickActionsSectionTitle]: {
    id: DashboardType.quickActionsSectionTitle,
    defaultMessage: en[DashboardType.quickActionsSectionTitle],
    description: "-",
  },
  [DashboardType.needsAttentionSectionTitle]: {
    id: DashboardType.needsAttentionSectionTitle,
    defaultMessage: en[DashboardType.needsAttentionSectionTitle],
    description: "-",
  },
  [DashboardType.statisticsSectionTitle]: {
    id: DashboardType.statisticsSectionTitle,
    defaultMessage: en[DashboardType.statisticsSectionTitle],
    description: "-",
  },
  [DashboardType.likes]: {
    id: DashboardType.likes,
    defaultMessage: en[DashboardType.likes],
    description: "-",
  },
  [DashboardType.followers]: {
    id: DashboardType.followers,
    defaultMessage: en[DashboardType.followers],
    description: "-",
  },
  [DashboardType.comments]: {
    id: DashboardType.comments,
    defaultMessage: en[DashboardType.comments],
    description: "-",
  },
  [DashboardType.posts]: {
    id: DashboardType.posts,
    defaultMessage: en[DashboardType.posts],
    description: "-",
  },
  [DashboardType.clicks]: {
    id: DashboardType.clicks,
    defaultMessage: en[DashboardType.clicks],
    description: "-",
  },
  [DashboardType.shares]: {
    id: DashboardType.shares,
    defaultMessage: en[DashboardType.shares],
    description: "-",
  },
});
