import styled from "styled-components";

export const UploadedMediaFilesManager = styled.div<{ medialoaded: any }>`
  ${(props) =>
    props.medialoaded
      ? `
    background-color: transparent;
    border: none;
    padding-right: 15px;
  ` : `
    display: none;
    padding: 10px 0 0 15px;
  `}
`;
