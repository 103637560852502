import { useContext, useMemo } from "react";
import { SocialNetworksId } from "../interfaces";

import { AppContext } from "../providers";

export const usePlayerSocialNetworks = () => {
  const { userContext } = useContext(AppContext);
  const playerSn: SocialNetworksId[] = useMemo(() => {
    const baseSns: SocialNetworksId[] = userContext?.profile.socialNetworks?.map((sn) => sn.networkId) || []
    return  baseSns?.includes("IG") ? baseSns.concat("IG_STORY") : baseSns;
  }, []);

  return { playerSn };
};


