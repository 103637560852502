import { client as axios } from "./axiosClient";
import { FacebookPage, InstagramPage } from "../interfaces/dtos";
import { Constants } from "@socialcoach/services";
import { scCatchServiceException } from "../annotations";

const GRAPH_API_VERSION = "v17.0";
const PROFILE_URI = `${Constants.API_URL}/accounts/profiles`;

export class FacebookServices {
  static graphApiVersion(): string {
    return GRAPH_API_VERSION;
  }

  static graphApiVersionNumber(): string {
    return GRAPH_API_VERSION.replace("v", "");
  }

  @scCatchServiceException()
  static async getPages(): Promise<FacebookPage[]> {
    const response = await axios.get<{ pages: FacebookPage[] }>(`${PROFILE_URI}/facebook-pages`, {
      withCredentials: false,
    });
    return response.data.pages || [];
  }

  @scCatchServiceException()
  static async getInstagramPages(): Promise<InstagramPage[]> {
    const response = await axios.get<{ pages: InstagramPage[] }>(`${PROFILE_URI}/instagram-pages`, {
      withCredentials: false,
    });
    return response.data.pages || [];
  }
}
