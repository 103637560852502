import { defineMessages } from "react-intl";

export enum PriceSectionType {
  setPrice = "PriceSectionType.setPrice",
  priceFrequency = "PriceSectionType.priceFrequency",
  setTrial = "PriceSectionType.setTrial",
  trialDescription = "PriceSectionType.trialDescription",
}

export const en = {
  [PriceSectionType.setPrice]: "Set your price per {interval}",
  [PriceSectionType.priceFrequency]: " per {interval}",
  [PriceSectionType.setTrial]: "Optional Trial Period",
  [PriceSectionType.trialDescription]: "  Free {trialDays} Day Trial",
};

export const descriptors = defineMessages({
  [PriceSectionType.setPrice]: {
    id: PriceSectionType.setPrice,
    defaultMessage: en[PriceSectionType.setPrice],
    description: "-",
  },
  [PriceSectionType.priceFrequency]: {
    id: PriceSectionType.priceFrequency,
    defaultMessage: en[PriceSectionType.priceFrequency],
    description: "-",
  },
  [PriceSectionType.trialDescription]: {
    id: PriceSectionType.trialDescription,
    defaultMessage: en[PriceSectionType.trialDescription],
    description: "-",
  },
  [PriceSectionType.setTrial]: {
    id: PriceSectionType.setTrial,
    defaultMessage: en[PriceSectionType.setTrial],
    description: "-",
  },
});
