import axios from "axios";

const client = axios.create({});
// const CancelToken = axios.CancelToken;

client.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const responseURL = error.response.request.responseURL;
    const status = error.response?.status;
    if (status === 401 && responseURL.includes("login") == false) {
      // Session Expired. We must log back in.
      // This cannot be done with history because we're not in React's domain here.
      window.location.href = "/sign-in?expired=true";
      return;
    } else {
      throw error;
    }
  }
);

export { client };
// CancelToken
