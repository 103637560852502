import { defineMessages } from "react-intl";

export const getDefaultInfoMessages = (defaultDeliveryTime: string) => [
  `All prompts will be sent to players device at ${defaultDeliveryTime} Pacific Time`,
  `All changes applied will take effect starting tomorrow`,
];

export enum LibraryType {
  addPrompt = "LibraryType.addPrompt",
  saveButton = "LibraryType.saveButton",
  importantTitle = "LibraryType.importantTitle",
  limitDaysMessage = "LibraryType.limitDaysMessage",
  promptsListTitle = "LibraryType.promptsListTitle",
  schedulePromptsTitle = "LibraryType.schedulePromptsTitle",
  createdTitle = "LibraryType.createdTitle",
  remainingPrompts = "LibraryType.remainingPrompts",
  morePromptsRequired = "LibraryType.morePromptsRequired",
  welcomePromptRequired = "LibraryType.welcomePromptRequired",
  specificDateTitle = "LibraryType.specificDateTitle",
  randomTitle = "LibraryType.randomTitle",
  limitDaysTitle = "LibraryType.limitDaysTitle",
  byCategory = "LibraryType.byCategory",
  byType = "LibraryType.byType",
}

export const en = {
  [LibraryType.addPrompt]: "Add Prompt",
  [LibraryType.saveButton]: "Save Random Schedule",
  [LibraryType.importantTitle]: "Important:",
  [LibraryType.limitDaysMessage]: "Select at least 3 days",
  [LibraryType.promptsListTitle]: "My Prompts",
  [LibraryType.schedulePromptsTitle]: "Schedule Prompts",
  [LibraryType.createdTitle]: "Created ",
  [LibraryType.remainingPrompts]: "random prompts remaining until you can publish",
  [LibraryType.welcomePromptRequired]: "A welcome prompt is required to publish.",
  [LibraryType.morePromptsRequired]: "more prompts required to publish.",
  [LibraryType.specificDateTitle]: "To be sent on:",
  [LibraryType.randomTitle]: "Sent randomly",
  [LibraryType.limitDaysTitle]: "Days we should send randomized prompts to your players",
  [LibraryType.byCategory]: "Filter by Categories",
  [LibraryType.byType]: "Filter by Prompt Type",
};

export const descriptors = defineMessages({
  [LibraryType.byCategory]: {
    id: LibraryType.byCategory,
    defaultMessage: en[LibraryType.byCategory],
    description: "-",
  },
  [LibraryType.byType]: {
    id: LibraryType.byType,
    defaultMessage: en[LibraryType.byType],
    description: "-",
  },
  [LibraryType.addPrompt]: {
    id: LibraryType.addPrompt,
    defaultMessage: en[LibraryType.addPrompt],
    description: "-",
  },
  [LibraryType.limitDaysTitle]: {
    id: LibraryType.limitDaysTitle,
    defaultMessage: en[LibraryType.limitDaysTitle],
    description: "-",
  },
  [LibraryType.saveButton]: {
    id: LibraryType.saveButton,
    defaultMessage: en[LibraryType.saveButton],
    description: "-",
  },
  [LibraryType.importantTitle]: {
    id: LibraryType.importantTitle,
    defaultMessage: en[LibraryType.importantTitle],
    description: "-",
  },
  [LibraryType.limitDaysMessage]: {
    id: LibraryType.limitDaysMessage,
    defaultMessage: en[LibraryType.limitDaysMessage],
    description: "-",
  },
  [LibraryType.promptsListTitle]: {
    id: LibraryType.promptsListTitle,
    defaultMessage: en[LibraryType.promptsListTitle],
    description: "-",
  },
  [LibraryType.schedulePromptsTitle]: {
    id: LibraryType.schedulePromptsTitle,
    defaultMessage: en[LibraryType.schedulePromptsTitle],
    description: "-",
  },
  [LibraryType.createdTitle]: {
    id: LibraryType.createdTitle,
    defaultMessage: en[LibraryType.createdTitle],
    description: "-",
  },
  [LibraryType.remainingPrompts]: {
    id: LibraryType.remainingPrompts,
    defaultMessage: en[LibraryType.remainingPrompts],
    description: "-",
  },
  [LibraryType.morePromptsRequired]: {
    id: LibraryType.morePromptsRequired,
    defaultMessage: en[LibraryType.morePromptsRequired],
    description: "-",
  },
  [LibraryType.welcomePromptRequired]: {
    id: LibraryType.welcomePromptRequired,
    defaultMessage: en[LibraryType.welcomePromptRequired],
    description: "-",
  },
  [LibraryType.specificDateTitle]: {
    id: LibraryType.specificDateTitle,
    defaultMessage: en[LibraryType.specificDateTitle],
    description: "-",
  },
  [LibraryType.randomTitle]: {
    id: LibraryType.randomTitle,
    defaultMessage: en[LibraryType.randomTitle],
    description: "-",
  },
});
