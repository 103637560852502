import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Grid, Tab } from "semantic-ui-react";
import { get } from "lodash";

import { AppContext } from "../../../providers";
import * as DTO from "../../../interfaces";
import {
  SearchComponent,
  SideBar,
  TitleSection,
  PlayersList,
  LocalSearch,
  ProspectList,
  SearchMultipleComponent,
} from "../../../components";
import { PlayersServices } from "../../../services";
import {
  exportPlayerList,
  exportPlayerToCsv,
  coachWithWritePermission,
  URLS,
  DEFAULT_PLAYER_FIELDS,
  DEFAULT_CANCELED_PLAYER_FIELDS,
  exportCanceledPlayerList,
  removeElement,
} from "../../../utils";

import { filters } from "./filters";

import { WhiteContainer, ContentGrid, SocialGrid } from "../../../styling/baseStyle";
import "./styles.scss";
import { PlayersInfinitySearch } from "../../../components/PlayersInfinitySearch";
import useAdminCoachSettings from "../../../components/AppSettingsTab/adminSettingsHook";

type Props = RouteComponentProps;

const PlayersFC: React.FC<Props> = ({ history, location }) => {
  const { userContext } = React.useContext(AppContext);
  const { displayAdminSettings } = useAdminCoachSettings(`${userContext?.coach?.id}`);

  const basicCoach = userContext?.coach?.type && userContext?.coach?.type === "BASIC";
  const withWritePermission = coachWithWritePermission(DTO.WebComponents.PLAYERS, userContext);

  const [playersList, setPlayersList] = React.useState<DTO.SubscribedPlayerSearchResponse[] | undefined>(undefined);
  const [playersListToExport, setPlayersListToExport] = React.useState<
    DTO.SubscribedPlayerSearchResponse[] | undefined
  >(undefined);
  const [exporting, setExporting] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState<boolean>(true);
  const [forceRefresh, setForceRefresh] = React.useState<boolean>(false);

  const [prospects, setProspects] = React.useState<DTO.ProspectResponse[]>([]);
  const [cancelled, setCancelled] = React.useState<DTO.SubscribedPlayerSearchResponse[]>([]);
  const tabPosition = {
    ACTIVE: 0,
    PROSPECTS: 1,
    CANCELLED: basicCoach ? 2 : 1,
  };

  const selectedTab = get(location, "state.selectedTab", "ACTIVE");
  const [selectedIndex, setSelectedIndex] = React.useState<number>(tabPosition[selectedTab]);

  React.useEffect(() => {
    if (playersList) {
      setLoading(false);
      setForceRefresh(false);
    }
  }, [playersList]);

  const refreshView = () => setForceRefresh(true);

  const exportDataHandler = (results: any[]) => {
    let removeHeaders: string[] | undefined;

    if (!displayAdminSettings.costCenter) {
      removeHeaders = ["costCenters"];
    }

    if (selectedIndex === 0) {
      exportData("active", DEFAULT_PLAYER_FIELDS, exportPlayerList(results!!, removeHeaders));
    } else {
      exportData("canceled", DEFAULT_CANCELED_PLAYER_FIELDS, exportCanceledPlayerList(results!!, removeHeaders));
    }
    setExporting(false);
  };

  const exportData = (filename: string, defaultHeaders: string[], results: DTO.ExportPlayerObject[]) => {
    const headers = displayAdminSettings.costCenter ? defaultHeaders : removeElement(defaultHeaders, "Cost Centers");
    exportPlayerToCsv(filename, results!, headers);
  };
  const panes = [
    {
      menuItem: "Subscribed",
      render: () => (
        <Tab.Pane>
          {basicCoach ? (
            <PlayersInfinitySearch
              refreshData={result => {
                if (exporting) {
                  exportDataHandler(result);
                }
                setPlayersList(() => result);
                setPlayersListToExport(() => result);
              }}
              content={
                <PlayersList
                  scPlusTier={displayAdminSettings.scPlusTier}
                  handleCancelAction={refreshView}
                  loading={loading}
                  playersList={playersList}
                />
              }
              searchFunction={PlayersServices.searchSubscribedPlayersByStatus}
              searchAttribute={"player.playerAccount.email"}
              showSortBy={false}
              filterCondition={(element, searchTerm) => {
                const lowerTerm = searchTerm.toLowerCase().trim();
                const fullName =
                  get(element, "player.playerAccount.firstName", "").toLowerCase() +
                  " " +
                  get(element, "player.playerAccount.lastName", "").toLowerCase();
                return (
                  get(element, "player.playerAccount.email", "")
                    .toLowerCase()
                    .includes(lowerTerm) || fullName.includes(lowerTerm)
                );
              }}
              filters={filters}
              displaySusbcriptionFilter={displayAdminSettings.scPlusTier}
            />
          ) : (
            <SearchMultipleComponent
              refreshData={setPlayersList}
              refreshAllDataToExport={results => {
                if (exporting && results !== undefined) {
                  exportDataHandler(results!!);
                }
                setPlayersListToExport(() => results);
              }}
              content={
                <PlayersList
                  handleCancelAction={refreshView}
                  isBasic={basicCoach}
                  loading={loading}
                  playersList={playersList}
                  scPlusTier={displayAdminSettings.scPlusTier}
                />
              }
              searchFunction={PlayersServices.searchSubscribedPlayersByStatus}
              multiple={true}
              pageable={true}
              forceRefresh={forceRefresh}
              displaySusbcriptionFilter={displayAdminSettings.scPlusTier}
            />
          )}
        </Tab.Pane>
      ),
    },
  ];

  if (basicCoach) {
    panes.push({
      menuItem: "Prospects",
      render: () => (
        <Tab.Pane>
          <LocalSearch
            refreshData={setProspects}
            content={<ProspectList prospectsList={prospects} />}
            searchFunction={PlayersServices.searchProspects}
          />
        </Tab.Pane>
      ),
    });
  }
  panes.push({
    menuItem: "Canceled",
    render: () => (
      <Tab.Pane>
        {basicCoach ? (
          <div className="paddingLeft">
            <SearchComponent
              refreshData={setCancelled}
              refreshAllDataToExport={results => {
                if (exporting && results !== undefined) {
                  exportDataHandler(results!!);
                }
                setPlayersListToExport(() => results);
              }}
              content={<PlayersList isCancelled={true} loading={false} playersList={cancelled} />}
              searchFunction={PlayersServices.searchCanceledPlayersByTag}
              showSortBy={false}
              multiple={true}
              pageable={true}
              filters={[]}
            />
          </div>
        ) : (
          <SearchMultipleComponent
            refreshData={setCancelled}
            refreshAllDataToExport={results => {
              if (exporting && results !== undefined) {
                exportDataHandler(results!!);
              }
              setPlayersListToExport(() => results);
            }}
            content={<PlayersList isCancelled={true} loading={loading} playersList={cancelled} isBasic={basicCoach} />}
            searchFunction={PlayersServices.searchCanceledPlayersByTag}
            multiple={true}
            pageable={true}
            displaySusbcriptionFilter={displayAdminSettings.scPlusTier}
          />
        )}
      </Tab.Pane>
    ),
  });

  const customLinkSectionMemo = React.useMemo(() => {
    const notProspectSection = !basicCoach || selectedIndex !== 1;
    return (
      <SocialGrid.Column width={11} floated="right">
        {!basicCoach && withWritePermission && (
          <SocialGrid.Column width={8} floated="right" className={"buttonRight"}>
            {" "}
            <Button
              data-elm-id={`playerListAddPlayerBtn`}
              fluid
              className={"primary rounded buttonLeft mediumSize"}
              onClick={() => history.push(URLS.coach.newPlayer)}>
              Add player
            </Button>
          </SocialGrid.Column>
        )}
        <SocialGrid.Column width={6} floated="right" className={"buttonLeft"}>
          {notProspectSection && (
            <Button
              data-elm-id={`playerListExportBtn`}
              fluid
              className={"secondary rounded buttonLeft"}
              loading={exporting}
              onClick={() => {
                if (playersListToExport !== undefined) {
                  exportDataHandler(playersListToExport!!);
                } else {
                  setExporting(true);
                }
              }}>
              Export players
            </Button>
          )}
        </SocialGrid.Column>
      </SocialGrid.Column>
    );
  }, [basicCoach, selectedIndex, withWritePermission, playersListToExport, cancelled, exporting]);

  return (
    <WhiteContainer>
      <SideBar history={history} />

      <ContentGrid className={"listManagerContent"} columns={1}>
        <Grid columns={1}>
          <TitleSection
            title={"Players List"}
            ready={true}
            buttonsSize={9}
            titleSize={7}
            showLinkOption={true}
            showNextOption={false}
            customLinkSection={customLinkSectionMemo}
          />
          <Grid.Row className={"playersListTabContainer"}>
            <Grid.Column>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Tab
                      defaultActiveIndex={tabPosition[selectedTab]}
                      panes={panes}
                      onTabChange={(e, data) => {
                        setSelectedIndex(Number(data.activeIndex) || 0);
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const Players = withRouter(PlayersFC);
