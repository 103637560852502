import { defineMessages } from "react-intl";

export enum ResendErrorType {
  email = "ResendErrorType.email",
  invalidEmail = "ResendErrorType.invalidEmail",
  responseMessage = "ResendErrorType.verifyResponse",
  errorMessage = "ResendErrorType.errorMessage",
}

export const en = {
  [ResendErrorType.email]: "Email is Required",
  [ResendErrorType.invalidEmail]: "Invalid email format",
  [ResendErrorType.responseMessage]:
    "If that email address has a SocialCoach account, we will send you an email to reset your password.",
  [ResendErrorType.errorMessage]: "An error occurs",
};

export const descriptors = defineMessages({
  [ResendErrorType.email]: {
    id: ResendErrorType.email,
    defaultMessage: en[ResendErrorType.email],
    description: "Email address",
  },
  [ResendErrorType.invalidEmail]: {
    id: ResendErrorType.invalidEmail,
    defaultMessage: en[ResendErrorType.invalidEmail],
    description: "Invalid email format",
  },
  [ResendErrorType.responseMessage]: {
    id: ResendErrorType.responseMessage,
    defaultMessage: en[ResendErrorType.responseMessage],
    description: "",
  },
  [ResendErrorType.errorMessage]: {
    id: ResendErrorType.errorMessage,
    defaultMessage: en[ResendErrorType.errorMessage],
    description: "",
  },
});
