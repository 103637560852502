import { UserStatus } from "@socialcoach/services";
import { SocialNetworksId } from "../SocialNetwork";

export enum UserGroups {
  USER = "USER",
  ADMIN = "ADMIN",
  COACH = "COACH",
  EDITOR = "EDITOR",
  PLAYER = "PLAYER",
  SYSTEM_ADMIN = "SYSTEM_ADMIN",
}

export interface Profile {
  profileId?: string;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  birthday?: string;
  photoUrl?: string;
  socialNetworks?: SocialNetwork[];
  referredFromUserId?: string;
  complianceDisclaimer?: string;
}

export interface Account {
  userId: number;
  username: string;
  email?: string;
  accountType: string; // "PROPIETARY";
  groups: UserGroups[];
  scGroups: UserGroups[];
  status: UserStatus;
  latestLoginDate?: string;
  verificationDate?: string;
  passwordExpirationDate?: string;
  createdDate?: string;
  isDemo?: boolean;
}

export interface AddSocialTokenRequest extends SocialNetwork {
  id?: number;
  accessToken?: string;
}
export interface UserRegistered {
  profile: Profile;
  player?: { id: number };
  account: Account;
  user: Account;
}

// TODO: Move to another file
export interface SocialNetwork {
  username: string;
  networkId: SocialNetworksId;
  dataNetwork?: DataNetwork;
  publicData?: string | null;
}

export interface SocialNetworkPayload {
  companyName: string;
  title: string;
  socialNetworks: SocialNetwork[];
}

export type DataNetwork = BaseDataNetwork | FacebookCredentials;

export interface BaseDataNetwork {
  type: SocialNetworksId;
  accessToken?: string;
  token?: string;
  tokenSecret?: string;
  refreshToken?: string;
  refreshTokenExpiresIn?: string;
  refreshExpireIn?: string;
  expiresIn?: string;
  organizationUrn?: string;
}

export interface FacebookCredentials {
  type: SocialNetworksId;
  accessToken: string;
  pageId: string;
  pageToken: string;
}

export interface LinekdInCredentials {
  type: SocialNetworksId;
  accessToken: string;
  organizationUrn?: string;
  name: string;
  link: string;
  refreshToken?: string;
  refreshTokenExpiresIn?: string;
  refreshExpireIn?: string;
  expiresIn?: string;
}

export interface InstagramCredentials {
  type: SocialNetworksId;
  accessToken: string;
  pageToken: string;
  igAccountId: string;
}

export interface FacebookAccountInfo {
  accessToken: string;
  name: string;
  type: "IG" | "FB";
}

export interface LinkedInOrganizationBaseInfo {
  id: string;
  name: string;
}
