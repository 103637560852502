import * as React from "react";
import { Header, Grid, Form, Message, Loader, Transition } from "semantic-ui-react";

import { get, groupBy } from "lodash";
import { Formik } from "formik";

import { PlayerCustomizations, PlayerStickersSection, MenuTitleSection } from "../../../components";
import { ConfigContext } from "../../../contexts/appContexts";
import * as IT from "../../../interfaces";
import { AppContext } from "../../../providers";
import {
  SettingsServices,
  HashTagsServices,
  PlayerSignUpStep,
  PlayersServices,
  ShortLinksServices,
} from "../../../services";
import { manageWebAddress, redirectToPlayerStep } from "../../../utils";

import { validationProfileSchema } from "./validations";

import { WhiteContainer, InternalGrid, InternalContentGrid } from "../../../styling/baseStyle";

import "./styles.scss";

const IntegratedPlayerProfile: React.FC = () => {
  const { userContext, setUserContext } = React.useContext(AppContext);
  const config: IT.ConfigProperties = React.useContext(ConfigContext);

  const [playerShortLink, setPlayerShortLink] = React.useState<IT.ShortLinkResponse | undefined>();
  const [saved, setSaved] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [coachInfo, setCoachInfo] = React.useState<IT.LandingResponse>();
  const [updatedHashTagsList, setUpdatedHashTagsList] = React.useState<IT.TagElement[]>([]);
  const [stickerRequest, setStickerRequest] = React.useState<IT.PlayerStickerRequest | undefined>(undefined);

  const [coachAppSettings, setCoachAppSettings] = React.useState<IT.CoachAppSetting | undefined>(undefined);
  const [showCustomStickersSection, setShowCustomStickersSection] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (userContext && userContext.player) {
      PlayersServices.getCoachData(userContext.player.id).then(response => {
        setCoachInfo(response);
      });
    }
  }, []);

  React.useEffect(() => {
    if (coachInfo) {
      SettingsServices.getAppSettingsByCoachId(coachInfo.coachId).then(setCoachAppSettings);
    }
  }, [coachInfo]);

  React.useEffect(() => {
    if (coachAppSettings) {
      const coachAppSettingsGrouped = groupBy(coachAppSettings?.settings, b => b.appSettingId);
      const settingValue: string = get(
        coachAppSettingsGrouped[IT.CoachSettings.PLAYER_CUSTOM_STICKERS],
        "[0].status",
        "ACTIVE"
      );
      setShowCustomStickersSection(settingValue !== "INACTIVE");
    }
  }, [coachAppSettings]);

  React.useEffect(() => {
    if (userContext?.signUpStep! !== PlayerSignUpStep[PlayerSignUpStep.PLAYER_BUSINESS_DATA]) {
      const url = redirectToPlayerStep(userContext?.signUpStep!);
      window.location.assign(url);
    }
    if (userContext?.player?.id) {
      setLoading(true);
      ShortLinksServices.getByPlayerId(userContext?.player.id)
        .then(response => {
          setPlayerShortLink(response);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [userContext]);

  const savedBanner = () => {
    return (
      saved && (
        <Transition.Group as={Header} duration={300} animation={"drop"} size="tiny" verticalalign="middle">
          <Message info content={"Saved!"} />
        </Transition.Group>
      )
    );
  };

  const errorBanner = (errors: any) => {
    return (
      errors &&
      Object.values(errors).length > 0 &&
      !loading && (
        <Grid.Row columns={1}>
          <Grid.Column width={12}>
            <Message negative list={Object.values(errors) as string[]} />
          </Grid.Column>
        </Grid.Row>
      )
    );
  };

  return (
    <WhiteContainer>
      <InternalContentGrid>
        <Formik
          validateOnBlur={false}
          validateOnChange={true}
          enableReinitialize={true}
          validateOnMount={true}
          initialValues={{
            slug: get(playerShortLink, "slug", undefined),
            webAddress: get(userContext, "player.webAddress", undefined),
            addHashTagAutomatically: get(userContext, "player.addHashTagAutomatically", false),
          }}
          validationSchema={validationProfileSchema}
          onSubmit={async (
            { slug, webAddress, addHashTagAutomatically },
            { setSubmitting, setErrors, validateForm }
          ) => {
            try {
              const errors = await validateForm({
                webAddress,
                slug,
              });

              if (errors && Object.values(errors).length > 0) {
                setErrors(errors);
                setLoading(false);
              } else {
                if (userContext?.account && userContext?.player?.id) {
                  const { player } = userContext!;

                  setLoading(true);

                  const webAddressWithFormat = await manageWebAddress(webAddress!);

                  const updateSlug = ShortLinksServices.updateShortLinkInfo(
                    userContext?.player.id,
                    slug!,
                    webAddressWithFormat!
                  );

                  const updatedHashTags = await HashTagsServices.update(player!.id, {
                    hashtags: updatedHashTagsList.map(hashtag => hashtag.displayValue),
                    addHashTagAutomatically,
                  });

                  let updateStickers;
                  if (stickerRequest) {
                    updateStickers = await PlayersServices.updatePlayerStickers(
                      userContext?.player!!.id!!,
                      stickerRequest
                    );
                  }
                  Promise.all([updatedHashTags, updateSlug, updateStickers])
                    .then(() => {
                      setSaved(true);
                      setTimeout(() => {
                        setSaved(false);
                        setUserContext({
                          ...userContext!,
                          player: {
                            ...player!,
                            webAddress: webAddressWithFormat,
                            addHashTagAutomatically,
                          },
                        });
                      }, 1000);
                    })
                    .catch(e => {
                      const errorMessage = String(e);
                      setErrors({
                        slug: errorMessage,
                        webAddress: undefined,
                        addHashTagAutomatically: undefined,
                      });
                    })
                    .finally(() => {
                      setLoading(false);
                      setSubmitting(false);
                      setStickerRequest(undefined);
                    });
                }
              }
            } catch (e) {
              setErrors({
                slug: String(e),
                webAddress: undefined,
                addHashTagAutomatically: undefined,
              });
            }
          }}
        >
          {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
            <Form className={isSubmitting ? "disabledForm" : ""} error>
              <Grid columns={1}>
                <MenuTitleSection
                  isLoading={loading}
                  disableDuringLoading={true}
                  handleNextAction={() => handleSubmit()}
                />

                {savedBanner()}
                {errorBanner(errors)}

                <InternalGrid columns={showCustomStickersSection ? 2 : 1} className={"menuBaseContainer"}>
                  {/* Left Section  */}
                  <Grid.Column className={"paddingTop noPaddingLeft"} floated="right">
                    {coachInfo && userContext && (
                      <PlayerCustomizations
                        playerId={userContext?.player?.id!!}
                        coachId={coachInfo?.coachId!!}
                        isSubmitting={isSubmitting}
                        boxClassName={""}
                        shortLinkBase={config.slugBaseUrl}
                        handleSetFieldValue={setFieldValue}
                        values={values}
                        handleUpdateHashTags={setUpdatedHashTagsList}
                      />
                    )}
                  </Grid.Column>

                  {/* Right Section */}
                  {showCustomStickersSection && (
                    <Grid.Column floated="left" className="playerCustomizationStickerSection">
                      <PlayerStickersSection playerId={userContext?.player?.id!!} onChange={setStickerRequest} />
                    </Grid.Column>
                  )}
                </InternalGrid>
              </Grid>
            </Form>
          )}
        </Formik>
      </InternalContentGrid>
      {loading && <Loader active size="large" />}
    </WhiteContainer>
  );
};

export const PlayerMyCustomizations = IntegratedPlayerProfile;
