import * as React from "react";
import axios from "axios";

import { Media } from "../../../../../interfaces";
import { printDateTime } from "../../../../../utils";

import "./styles.scss";
import { Grid, Icon } from "semantic-ui-react";
import { MediaPlayer } from "../../../../MediaPlayer";
import { useMemo, useState } from "react";

interface OwnProps {
  title: string;
  videoProjectId: number;
  type: "EDITED" | "ORIGINAL";
  videos: Media[];
  date: string;
  hideable?: boolean;
}

type Props = OwnProps;

export const VideosSection: React.FC<Props> = ({ title, videoProjectId, type, videos, date, hideable }) => {
  const [showFiles, setShowFiles] = useState(!hideable);

  const downloadFile = (filePath: string, fileName: string) => {
    axios.get(filePath, { responseType: "blob" }).then(response => {
      const urlObject = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = urlObject;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const printedDate = useMemo(() => {
    return printDateTime(date);
  }, [date]);

  const showFilesLabel = useMemo(() => {
    if (videos && videos.length > 0) {
      const postfix = `${videos.length} file${videos.length > 1 ? "s" : ""}`;
      if (showFiles) return `Hide ${postfix}`;
      else return `Show ${postfix}`;
    } else {
      return "No Files";
    }
  }, [showFiles, videos]);

  return (
    <div className={"filesContainer"}>
      <label className={"small"}>
        {title}
        {printedDate && <span className={"smallDetail"}>Added {printedDate}</span>}
      </label>

      <Grid>
        {hideable && (
          <Grid.Row className={"noPadding"}>
            <a onClick={() => setShowFiles(!showFiles)}>{showFilesLabel}</a>
          </Grid.Row>
        )}

        {videos &&
          videos.length > 0 &&
          showFiles &&
          videos.map((media, index) => {
            return (
              <Grid.Column
                key={`media_${type}_container_${index}`}
                className={type === "ORIGINAL" ? "videosContainer" : "videosContainer edited"}
              >
                <MediaPlayer media={media} />
                <div
                  className="downloadIcon"
                  onClick={() =>
                    downloadFile(media.uri!!, `${videoProjectId}_${type}_${media.category}_${index}.${media.extension}`)
                  }
                >
                  <Icon size={"large"} name="arrow alternate circle down outline" />
                </div>
              </Grid.Column>
            );
          })}
      </Grid>
    </div>
  );
};
