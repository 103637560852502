import * as React from "react";
import { Header, Grid, Pagination, PaginationProps, Loader } from "semantic-ui-react";

import * as DTO from "../../../../../interfaces/dtos";
import { coachWithWritePermission } from "../../../../../utils";
import { AppContext } from "../../../../../providers";
import { CampaignServices } from "../../../../../services";

import "./styles.scss";

interface OwnProps {
  playerId: number;
  isActive?: boolean;

  handleCancelAction?: () => void;
}

type Props = OwnProps;

const PlayerCampaignsFC: React.FC<Props> = ({ playerId, isActive = false }) => {
  const { userContext } = React.useContext(AppContext);
  const withWritePermission = coachWithWritePermission(DTO.WebComponents.PLAYERS, userContext);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [playerCampaigns, setPlayerCampaigns] = React.useState<DTO.PlayerCampaign[]>([]);
  const [totalPages, setTotalPages] = React.useState<number>(0);

  React.useEffect(() => {
    getPlayerCampaigns(0);
  }, [playerId]);

  const getPlayerCampaigns = (page: number): void => {
    setLoading(true);
    CampaignServices.playerCampaigns(playerId, page).then((response: any) => {
      setPlayerCampaigns(response.content);
      setTotalPages(response.totalPages ?? 1);
      setLoading(false);
    });
  };

  const nextPage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: PaginationProps): void => {
    getPlayerCampaigns(Number(data.activePage) - 1);
  };

  return (
    <>
      {playerCampaigns.length > 0 && withWritePermission && isActive && (
        <Grid.Row>
          <Grid.Column width={16} floated={"left"} className={"playerInfoCampaignsSection"}>
            <Header as="h2" textAlign="left">
              Enrolled Campaigns:
            </Header>

            {!loading ? (
              playerCampaigns.map((c, index) => (
                <Grid.Row key={c.campaignName + index}>
                  <a href={`/campaigns/${c.campaignId}`}>{c.campaignName}</a>
                </Grid.Row>
              ))
            ) : (
              <Loader active size={"large"} />
            )}

            {totalPages > 1 && (
              <Grid centered className={"paginationSection"}>
                <Pagination
                  defaultActivePage={1}
                  firstItem={null}
                  lastItem={null}
                  pointing
                  secondary
                  totalPages={totalPages}
                  onPageChange={nextPage}
                />
              </Grid>
            )}
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  );
};

export const PlayerCampaigns = PlayerCampaignsFC;
