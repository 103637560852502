import { URLS } from "./routes";

export function cleanBrandInfoInLocalStorage() {
  localStorage.removeItem("redirectTo");
  localStorage.removeItem("brandId");
}

export function setBrandInfoInLocalStorage(assetUrl: string, returnUrl: string, brandId: string) {
  const redirectTo = `${URLS.brandPost}?asset=${encodeURIComponent(
    assetUrl!!
  )}&brandId=${brandId}&returnUrl=${returnUrl}`;

  localStorage.setItem("redirectTo", redirectTo);
  localStorage.setItem("brandId", brandId);
}

export function getBrandRedirectUrl(): string | null {
  return localStorage.getItem("redirectTo");
}
