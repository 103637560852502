import * as React from "react";

import { Table, Icon, Grid, Menu, Button } from "semantic-ui-react";

import * as DTO from "../../../../interfaces";
import { printOnlyDate } from "../../../../utils";
import { TitleSubtitleCell } from "../../../Cells";

import { AlertType } from "../../../../interfaces";

import "./styles.scss";

interface OwnProps {
  alert: DTO.AlertResponse;
  withWritePermission: boolean;
  removeAlert: (alertId: number) => void;
}

const AlertCellFC: React.FC<OwnProps> = ({ alert, withWritePermission, removeAlert }) => {
  const [display, setDisplay] = React.useState<boolean>(false);

  const removeAction = (alertId: number): void => {
    if (window.confirm("Do you want to remove this alert?")) {
      return removeAlert(alertId);
    } else {
      return;
    }
  };
  const handleAction = (index: number): void => {
    switch (index) {
      case 0:
        return removeAction(alert.alertId);
      default:
        return;
    }
  };

  const campaignAlerts = (): JSX.Element => {
    const campainsToShow = display ? alert.campaignAlerts : alert.campaignAlerts.slice(0, 1);
    return (
      <Grid className={"alertCampaignSection mediumPadding"}>
        {campainsToShow.map(subAlert => {
          return (
            <Grid.Row key={subAlert.alertId}>
              <Grid className="alert-cell-button-link">
                <Grid.Column width={14}>
                  <span className={"underline"}>
                    Player id: {subAlert.playerId}
                    <br />
                    Player name: {subAlert.playerUsername}
                  </span>
                </Grid.Column>
                {withWritePermission && (
                  <Grid.Column width={2}>
                    <Icon
                      className={"clickable"}
                      name={"trash alternate outline"}
                      onClick={() => removeAction(subAlert.alertId)}
                    />
                  </Grid.Column>
                )}
              </Grid>
            </Grid.Row>
          );
        })}
        {/* Show more section  */}
        {alert.campaignAlerts.length > 1 && (
          <Grid.Column width={5} floated="right" className={"readMoreButton"}>
            <Button
              basic
              data-elm-id={`alertCellShowMoreBtn`}
              content={display ? "Show less" : "Show more"}
              icon={display ? "chevron up" : "chevron down"}
              color={"blue"}
              className={"noBorder"}
              labelPosition={"right"}
              onClick={() => setDisplay(!display)}
            />
          </Grid.Column>
        )}
      </Grid>
    );
  };
  const campaignAlertCell = (): JSX.Element => {
    return (
      <Table.Row key={alert.alertId}>
        <Table.Cell>
          <Icon color="green" name="checkmark" size="small" />
          {`Campaign ${alert.campaignName || alert.campaignId} is about to expire for this player.`}
          <br />
          {alert.lastPromptScheduledDate &&
            `\n\n\nLast prompt scheduled for ${printOnlyDate(alert.lastPromptScheduledDate)}`}
        </Table.Cell>
        <TitleSubtitleCell title={""} subcontent={campaignAlerts()} />
        <Table.Cell>
          <Grid className={"alertCampaignSection mediumPadding"}>
            <a className={"campaignTitle"} href={`/campaigns/${alert.campaignId}`}>
              {`Campaign Id: ${alert.campaignId} `}
              <Icon name={"linkify"} />
            </a>
          </Grid>
        </Table.Cell>
      </Table.Row>
    );
  };

  const clasicAlertCell = (): JSX.Element => {
    return (
      <Table.Row key={alert.alertId}>
        <Table.Cell>
          <Icon color="green" name="checkmark" size="small" />
          {alert.detail}
        </Table.Cell>
        <TitleSubtitleCell
          title={alert.playerUsername}
          subtitle={
            `Player id: ${alert.playerId}` + (alert.type === AlertType.POST_FAILED ? `\nPost id: ${alert.postId}` : "")
          }
        />
        <Table.Cell>{alert.createdDate ? printOnlyDate(alert.createdDate!) : "-"}</Table.Cell>
        {withWritePermission && (
          <Table.Cell key={alert.alertId + "_cell"}>
            <Menu
              key={alert.alertId + "_menu_" + alert.postId}
              className={"alertDeleteIconSection"}
              items={[
                {
                  content: "",
                  icon: "trash alternate outline",
                },
              ]}
              onItemClick={() => handleAction(0)}
              vertical
              secondary
            />
          </Table.Cell>
        )}
      </Table.Row>
    );
  };

  const socialTitles = {
    FB: "Facebook",
    TW: "X (Twitter)",
    LI: "LinkedIn",
    IG: "Instagram",
    TT: "TikTok",
    IG_STORY: "Instagram Story",
  };

  const postAlertCell = (): JSX.Element => {
    const sourceLabel =
      alert.sourceType && alert.sourceType !== DTO.SourceType.NONE ? `${alert.sourceType} #${alert.sourceId}` : "";

    const sourceUrl = alert.sourceType === DTO.SourceType.PROMPT ? "prompt" : "videoproject/detail";
    return (
      <Table.Row key={alert.alertId}>
        <Table.Cell>
          <b>{socialTitles[alert.socialNetworkId] || "Unknown Network"}:</b>
          <div>{alert.detail}</div>
        </Table.Cell>
        <TitleSubtitleCell
          title={alert.playerUsername}
          subcontent={
            alert.sourceId && (
              <Grid className={"alertCampaignSection mediumPadding"}>
                <a target="_blank" className={"sourceTitle"} href={`/${sourceUrl}/${alert.sourceId}`}>
                  {sourceLabel}
                </a>
              </Grid>
            )
          }
        />
        <Table.Cell>{alert.createdDate ? printOnlyDate(alert.createdDate!) : "-"}</Table.Cell>
        {withWritePermission && (
          <Table.Cell key={alert.alertId + "_cell"}>
            <Menu
              key={alert.alertId + "_menu_" + alert.postId}
              className={"alertDeleteIconSection"}
              items={[
                {
                  content: "",
                  icon: "trash alternate outline",
                },
              ]}
              onItemClick={() => handleAction(0)}
              vertical
              secondary
            />
          </Table.Cell>
        )}
      </Table.Row>
    );
  };

  return alert.type === AlertType.CAMPAIGN_ABOUT_TO_EXPIRED
    ? campaignAlertCell()
    : alert.type === AlertType.POST_FAILED
    ? postAlertCell()
    : clasicAlertCell();
};

export const AlertCell = AlertCellFC;
