import { client as axios } from "./axiosClient";
import { Constants } from "@socialcoach/services";
import { catchServiceException } from "@socialcoach/services";
import { CoachAppSetting, AdminWebSetting } from "../interfaces";

const SETTINGS_SERVICE = `${Constants.API_URL}/settings`;
const ADMIN_SETTINGS_SERVICE = `${Constants.API_URL}/admin/web-settings`;

export class SettingsServices {
  @catchServiceException()
  static async getAppSettingsByCoachId(coachId: string): Promise<CoachAppSetting> {
    const { data } = await axios.get<CoachAppSetting>(`${SETTINGS_SERVICE}/${coachId}`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async updateAppSettings(settings: CoachAppSetting): Promise<CoachAppSetting> {
    const { data } = await axios.put<CoachAppSetting>(
      `${SETTINGS_SERVICE}`,
      {
        ...settings,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async getAdminSettingsByCoachId(coachId: string): Promise<AdminWebSetting> {
    const { data } = await axios.get<AdminWebSetting>(`${ADMIN_SETTINGS_SERVICE}/${coachId}`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async updateAdminSettings(settings: AdminWebSetting): Promise<AdminWebSetting> {
    const { data } = await axios.put<AdminWebSetting>(
      `${ADMIN_SETTINGS_SERVICE}`,
      {
        ...settings,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }
}
