import { defineMessages } from "react-intl";

export enum PlanSummaryType {
  title = "PlanSummaryType.title",
  trialTitle = "PlanSummaryType.defaultPlanName",
  trialDescription = "PlanSummaryType.trialDescription",
  planTitle = "PlanSummaryType.planTitle",
  paymentFrequency = "PlanSummaryType.paymentFrequency",
  startNow = "PlanSummaryType.startNow",
  monthlyPayment = "PlanSummaryType.monthlyPayment",
}

export const en = {
  [PlanSummaryType.title]: " What's Included ",
  [PlanSummaryType.trialTitle]: " Free Trial ",
  [PlanSummaryType.planTitle]: "Plan Includes:",
  [PlanSummaryType.trialDescription]: "Start Free {trialDays} Day{s} Trial",
  [PlanSummaryType.startNow]: " Get Started Now ",

  [PlanSummaryType.monthlyPayment]: " Pay {interval} ",
  [PlanSummaryType.paymentFrequency]: " per month",
};

export const descriptors = defineMessages({
  [PlanSummaryType.title]: {
    id: PlanSummaryType.title,
    defaultMessage: en[PlanSummaryType.title],
    description: "-",
  },
  [PlanSummaryType.monthlyPayment]: {
    id: PlanSummaryType.monthlyPayment,
    defaultMessage: en[PlanSummaryType.monthlyPayment],
    description: "-",
  },
  [PlanSummaryType.trialTitle]: {
    id: PlanSummaryType.trialTitle,
    defaultMessage: en[PlanSummaryType.trialTitle],
    description: "-",
  },
  [PlanSummaryType.planTitle]: {
    id: PlanSummaryType.planTitle,
    defaultMessage: en[PlanSummaryType.planTitle],
    description: "-",
  },
  [PlanSummaryType.trialDescription]: {
    id: PlanSummaryType.trialDescription,
    defaultMessage: en[PlanSummaryType.trialDescription],
    description: "-",
  },
  [PlanSummaryType.paymentFrequency]: {
    id: PlanSummaryType.paymentFrequency,
    defaultMessage: en[PlanSummaryType.paymentFrequency],
    description: "-",
  },
  [PlanSummaryType.startNow]: {
    id: PlanSummaryType.startNow,
    defaultMessage: en[PlanSummaryType.startNow],
    description: "-",
  },
});
