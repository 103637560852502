import { defineMessages } from "react-intl";

export enum PasswordValidationType {
  passwordPlaceholder = "PasswordValidationType.passwordPlaceholder",
  confirmPasswordPlaceholder = "PasswordValidationType.confirmPasswordPlaceholder",
  passwordMin = "PasswordValidationType.passwordMin",
  passwordWithNumber = "PasswordValidationType.passwordNumber",
  passwordWithSpecialCharacter = "PasswordValidationType.passwordSpecialCharacter",
  passwordWithCapital = "PasswordValidationType.passwordCapital",
  confirmPasswordError = "PasswordValidationType.confirmPasswordError",
}

export const en = {
  [PasswordValidationType.passwordPlaceholder]: "PASSWORD",
  [PasswordValidationType.confirmPasswordPlaceholder]: "CONFIRM PASSWORD",
  [PasswordValidationType.passwordMin]: "Password must be at least 8 characters.",
  [PasswordValidationType.passwordWithCapital]: "Password must be at least 1 capital letter.",
  [PasswordValidationType.passwordWithNumber]: "Password must be at least 1 numeric value.",
  [PasswordValidationType.passwordWithSpecialCharacter]: "Password must be at least 1 special character.",
  [PasswordValidationType.confirmPasswordError]: "Passwords must match",
};

export const descriptors = defineMessages({
  [PasswordValidationType.passwordPlaceholder]: {
    id: PasswordValidationType.passwordPlaceholder,
    defaultMessage: en[PasswordValidationType.passwordPlaceholder],
    description: "passwordPlaceholder",
  },
  [PasswordValidationType.confirmPasswordPlaceholder]: {
    id: PasswordValidationType.confirmPasswordPlaceholder,
    defaultMessage: en[PasswordValidationType.confirmPasswordPlaceholder],
    description: "confirmPasswordPlaceholder",
  },

  [PasswordValidationType.passwordMin]: {
    id: PasswordValidationType.passwordMin,
    defaultMessage: en[PasswordValidationType.passwordMin],
    description: "passwordMin",
  },
  [PasswordValidationType.passwordWithCapital]: {
    id: PasswordValidationType.passwordWithCapital,
    defaultMessage: en[PasswordValidationType.passwordWithCapital],
    description: "passwordWithCapital text",
  },
  [PasswordValidationType.passwordWithNumber]: {
    id: PasswordValidationType.passwordWithNumber,
    defaultMessage: en[PasswordValidationType.passwordWithNumber],
    description: "passwordWithNumber",
  },
  [PasswordValidationType.passwordWithSpecialCharacter]: {
    id: PasswordValidationType.passwordWithSpecialCharacter,
    defaultMessage: en[PasswordValidationType.passwordWithSpecialCharacter],
    description: "passwordWithSpecialCharacter",
  },
  [PasswordValidationType.confirmPasswordError]: {
    id: PasswordValidationType.confirmPasswordError,
    defaultMessage: en[PasswordValidationType.confirmPasswordError],
    description: "confirmPasswordError",
  },
});
