import * as React from "react";
import { useIntl } from "react-intl";

import * as DTO from "../../../interfaces";
import { printOnlyDate } from "../../../utils";
import { Svg } from "../../../assets";

import { descriptors, PromptCardType } from "./descriptors";

import { ThumbnailPreview, PromptCardStyle, PromptHeader, WelcomePromptCardStyle } from "./styled";
import "./styles.scss";
import { Image } from "semantic-ui-react";
import { PromptType } from "../../../interfaces";

interface OwnProps {
  prompt: DTO.Prompt;
  onClick?: (thumbnail: string) => void;
}

type Props = OwnProps;

const PromptCardFC: React.FC<Props> = ({
  prompt: { promptId, title, creationDate, post, deliveryMode, status, type },
  onClick,
}: Props) => {
  const [thumbnail, setThumbnail] = React.useState<string | undefined>();
  const { formatMessage } = useIntl();
  React.useEffect(() => {
    if (post?.media[0] && post?.media[0].uri) {
      const creativeUrl = post?.media[0].uri;
      if (post?.media[0].category === "IMAGE") {
        setThumbnail(creativeUrl);
      } else if (post?.media[0].thumbnailUri) {
        setThumbnail(post?.media[0].thumbnailUri);
      }
    }
  }, [post]);

  return (
    <PromptCardStyle
      className={"promptCard"}
      href={onClick ? undefined : `/prompt/${promptId}`}
      onClick={() => {
        if (onClick) {
          onClick(thumbnail!!);
        }
      }}
    >
      {type === PromptType.REELS && <Image className={"reelsIcon"} size="small" src={Svg.Reels} />}
      <ThumbnailPreview width={3} thumbnailurl={thumbnail} />
      <PromptCardStyle.Content>
        <PromptHeader>{title}</PromptHeader>
        <PromptCardStyle.Description>
          {formatMessage({ ...descriptors[PromptCardType.createdTitle] }) + printOnlyDate(creationDate!!)}
        </PromptCardStyle.Description>
      </PromptCardStyle.Content>
    </PromptCardStyle>
  );
};

export const PromptCard = PromptCardFC;
