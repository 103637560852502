import * as React from "react";

import { Button, Modal, ModalDescription, Table } from "semantic-ui-react";

import { PlayerSearchResponse, UserGroups } from "../../../interfaces";
import { CoachService, PlayersServices, SocialCoachSessionService } from "../../../services";
import { getFullName, getLargeSubscriptionType, printBasicDate, URLS } from "../../../utils";

import { SocialNetworksSection } from "../../SocialNetworksSection";
import { useSocialNetworks } from "../../../reducers";
import { ActionList } from "../../../containers/Admin/MergedActions";
import { ReportServices } from "../../../services/ReportServices";
import { PlayerInformation } from "../../../containers/Admin/PlayerInformation";

import "./styles.scss";
import { modalReducer } from "./modalReducer";
import { AppContext } from "../../../providers";

interface Props {
  player: PlayerSearchResponse;
}

const AdminPlayerFC: React.FC<Props> = ({ player }) => {
  const coachId = player.coachProfile?.coachId;
  const playerSnIds = player.socialNetworks?.map(sn => sn.networkId);
  const { userContext } = React.useContext(AppContext);

  const { sns } = useSocialNetworks(`${coachId}`, false);

  const [updatedEmail, setUpdatedEmail] = React.useState<string>(player.email!);

  const [state, dispatch] = React.useReducer(modalReducer, { open_edit: false, open_delete: false });

  const impersonateCoach = async () => {
    CoachService.getById(coachId!!.toString()).then(response => {
      SocialCoachSessionService.impersonate(response.user.username!!).then(() => {
        // Redirect to coach dashboard
        window.location.assign(URLS.coach.dashboard);
      });
    });
  };

  const impersonatePlayer = async () => {
    SocialCoachSessionService.impersonate(player.email!!).then(() => {
      // Redirect to player dashboard
      window.location.assign(URLS.player.dashboard);
    });
  };

  const sendReportHandler = () => {
    ReportServices.sendPlayerReport(player.playerId!!)
      .then(() => {
        alert("Success! Your report was sent to support accounts");
      })
      .catch(e => {
        alert("Oops. Something went wrong. Please refresh this page and try again.");
      });
  };
  const playerModal = (): JSX.Element => {
    return (
      <Modal open={state.open_edit} size={"mini"} onClose={() => dispatch({ type: "close_edit" })} closeIcon={true}>
        <Modal.Header>Player Email</Modal.Header>
        <Modal.Content>
          <PlayerInformation
            userId={player.userId}
            toUpdateEmail={updatedEmail}
            handleUpdateSuccess={(newEmail: string) => {
              dispatch({ type: "close_edit" });
              setUpdatedEmail(newEmail);
            }}
          />
        </Modal.Content>
      </Modal>
    );
  };

  const deletePlayer = () => {
    PlayersServices.delete(player.playerId)
      .then(() => alert("The player has been deleted"))
      .catch(e => alert(e));
  };

  const deleteModal = (): JSX.Element => {
    return (
      <Modal
        open={state.open_delete}
        size={"small"}
        onClose={() => dispatch({ type: "close_delete" })}
        closeIcon={true}
      >
        <Modal.Header>Warning!!</Modal.Header>
        <Modal.Content>
          <ModalDescription>
            <p className="pDelete">
              <strong>WARNING!!!</strong>
              {` Deleting this player will permanently DELETE all data associated
          to this account ${player.email} including profile data, posts, and subscription history.

          This cannot be undone.`}
            </p>
          </ModalDescription>
        </Modal.Content>

        <Modal.Actions>
          <Button
            secondary
            data-elm-id={`basicModalNoBtn`}
            onClick={() => {
              dispatch({ type: "close_delete" });
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            data-elm-id={`basicModalYesBtn`}
            onClick={() => {
              dispatch({ type: "close_delete" });
              deletePlayer();
            }}
          >
            DELETE
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  const handleAction = (index: number): void => {
    switch (index) {
      case 0:
        dispatch({ type: "open_edit" });
        break;
      case 1:
        sendReportHandler();
        break;
      case 2:
        impersonateCoach();
        break;
      case 3:
        impersonatePlayer();
        break;
      case 4:
        dispatch({ type: "open_delete" });
        break;
    }
  };

  const actions =
    player.subscriptionSummary?.status === "CANCELED" &&
    userContext?.account.scGroups?.includes(UserGroups.SYSTEM_ADMIN)
      ? [
          {
            content: "Edit email",
            icon: "pencil",
          },
          {
            content: "Send Report",
            icon: "file alternate outline",
          },
          {
            content: "Impersonate Coach",
            icon: "sign-in",
          },
          {
            content: "Impersonate Player",
            icon: "sign-in",
          },
          {
            content: "Delete Player",
            icon: "trash",
          },
        ]
      : [
          {
            content: "Edit email",
            icon: "pencil",
          },
          {
            content: "Send Report",
            icon: "file alternate outline",
          },
          {
            content: "Impersonate Coach",
            icon: "sign-in",
          },
          {
            content: "Impersonate Player",
            icon: "sign-in",
          },
        ];

  return (
    <Table.Row>
      <Table.Cell>
        <div className={"playerAdminCellTitle"}>{getFullName(player.firstName, player.lastName) || "No Name"}</div>
        <div>{updatedEmail}</div>
        <div>{player.phone}</div>
        <div>
          <SocialNetworksSection actives={playerSnIds} allSocialNetworks={sns} />
        </div>
      </Table.Cell>

      <Table.Cell>
        <div>{getLargeSubscriptionType(player.subscriptionSummary?.tier)}</div>
        <div>{player.subscriptionSummary?.status}</div>
        {player.lastLoginDate && <div>{"Last Login: " + printBasicDate(player.lastLoginDate)}</div>}
      </Table.Cell>

      <Table.Cell>
        {player.coachProfile && (
          <>
            <div>
              <span className={"playerAdminCellTitle"}>
                {getFullName(player.coachProfile.firstName, player.coachProfile.lastName) || "No Name"}
              </span>
            </div>
            <div>{player.coachProfile.companyName}</div>
            <div>{player.coachProfile.email}</div>
          </>
        )}
      </Table.Cell>

      <Table.Cell>
        <ActionList items={actions} handleAction={async index => handleAction(index)} />
      </Table.Cell>
      {playerModal()}
      {deleteModal()}
    </Table.Row>
  );
};

export const AdminPlayerCell = AdminPlayerFC;
