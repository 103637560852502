import * as React from "react";
import { Grid, Button, Header } from "semantic-ui-react";
import { CampaignSelectableCard } from "../../../../../../../components/Cells/CampaignSelectableCard";

import * as DTO from "../../../../../../../interfaces";

import "./styles.scss";

interface OwnProps {
  campaignList: DTO.CampaignResume[];
  name: string;
  category?: DTO.Category;

  page?: number;
  pageable?: boolean;
  latestPage?: boolean;
  firstPage?: boolean;

  totalElements?: number;
  campaignPrompts?: DTO.SelectedCampaign;

  onNext?: (nextPage: number) => void;
  onAddPrompt: (prompt: DTO.CampaignResume) => void;
  onRemovePrompt: (prompt: DTO.CampaignResume) => void;
}

type Props = OwnProps;

const CategorySectionFC: React.FC<Props> = ({
  category,
  campaignList,
  name,
  page = 0,
  campaignPrompts = {},
  pageable = false,
  firstPage = true,
  latestPage = false,
  totalElements = 0,
  onNext,
  onAddPrompt,
  onRemovePrompt,
}) => {
  const [display, setDisplay] = React.useState<boolean>(true);

  const [selectedCampaigns, setSelectedCampaigns] = React.useState<DTO.SelectedCampaign>(campaignPrompts);
  const [selectedPromptsCounter, setSelectedPromptsCounter] = React.useState<number>(0);

  const actionTitle = (): string => {
    if (!display) {
      return "Collapsed (" + totalElements + ")";
    }
    return `Showing ${page * 4} - ${page * 4 + campaignList.length} of  ${totalElements}`;
  };

  React.useEffect(() => {
    const globalCounter = Object.values(campaignPrompts).filter(info => selectedValidation(info.categoryIds)).length;
    if (globalCounter > 0 || selectedPromptsCounter > 0) {
      setSelectedCampaigns(() => {
        return { ...campaignPrompts };
      });
    }
  }, [campaignPrompts]);

  React.useEffect(() => {
    const globalCounter = Object.values(selectedCampaigns).filter(info => selectedValidation(info.categoryIds)).length;
    setSelectedPromptsCounter(globalCounter);
  }, [selectedCampaigns]);

  const onNextHandler = () => {
    if (onNext) {
      onNext(page + 1);
    }
  };
  const onPreviousHandler = () => {
    if (onNext) {
      onNext(page - 1);
    }
  };

  const selectedValidation = (categoryIds: string[]): boolean => {
    if (category && category.categoryId !== "uncategorized") {
      return categoryIds.includes(category!.categoryId!);
    }

    return categoryIds.length === 0;
  };

  const onSelectPrompt = (campaign: DTO.CampaignResume, checked: boolean): void => {
    const { campaignId, categoryIds, campaignName, firstPromptMedia } = campaign;

    if (!checked) {
      onRemovePrompt(campaign);
    } else {
      onAddPrompt(campaign);
    }
    setSelectedCampaigns(selected => {
      let newSelectedPromps = selected;
      if (!checked) {
        delete newSelectedPromps[campaignId!];
      } else {
        newSelectedPromps = {
          ...newSelectedPromps,
          [campaignId!]: {
            categoryIds,
            title: campaignName,
            post: firstPromptMedia,
            campaignId,
          },
        };
      }
      return { ...newSelectedPromps };
    });
  };

  const promptSelectedMessage = selectedPromptsCounter > 1 ? "Campaigns Selected" : "Campaign Selected";

  return (
    <Grid columns={3} data-elm-id="campaign-prompts-list-element" className={"campaignCategoryGrid"}>
      <Grid.Row columns={3} className={display ? "" : "collapsedSection"}>
        <Header as="h2" content={name} />
        {selectedPromptsCounter > 0 && (
          <span className={"tagSection"}>
            {selectedPromptsCounter} {promptSelectedMessage}
          </span>
        )}

        <Grid.Column width={4} floated="right" className={"noBorder"}>
          <Button
            data-elm-id={`categoryRowDisplayBtn`}
            basic
            content={actionTitle()}
            icon={!display ? "triangle right" : "triangle down"}
            className={"noBorder"}
            labelPosition={"right"}
            onClick={() => setDisplay(!display)}
          />
        </Grid.Column>
      </Grid.Row>
      {pageable && display && !firstPage && (
        <Button
          data-elm-id={`campaignCategorySectionOnPrevPageBtn`}
          icon={"chevron left"}
          className={"leftArrowStyle"}
          onClick={onPreviousHandler}
        />
      )}
      {display && (
        <div key={name} className={!latestPage && !firstPage ? "ui container middlePage" : "ui container"}>
          {campaignList.map(campaign => {
            return (
              <CampaignSelectableCard
                key={campaign.campaignId}
                campaign={campaign}
                selected={selectedCampaigns[campaign!.campaignId!] ? true : false}
                onChange={checked => onSelectPrompt(campaign, checked)}
                editable={true}
              />
            );
          })}
        </div>
      )}
      {pageable && display && !latestPage && (
        <Button
          data-elm-id={`campaignCategorySectionOnNextPageBtn`}
          icon={"chevron right"}
          className={"arrowStyle"}
          onClick={onNextHandler}
        />
      )}
    </Grid>
  );
};

export const CategoryRow = React.memo(CategorySectionFC);
