import React from "react";
import { map } from "lodash";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";
import { FacebookPage } from "../../../../../interfaces";

interface Props {
  /**
   * Player Pages
   */
  pages: FacebookPage[];
  selectedPage: (selectedPage?: FacebookPage) => void;
}

const FacebookPagesClass: React.FC<Props> = ({ pages, selectedPage }) => {
  const [pagesOptions, setPagesOptions] = React.useState<DropdownItemProps[]>([]);

  React.useEffect(() => {
    if (pages) {
      const filters: DropdownItemProps[] = map(pages, page => {
        let pageName = page.name_with_location_descriptor || page.name;
        return {
          key: page.id,
          text: pageName,
          value: page.id,
        };
      });
      setPagesOptions(filters);
    }
  }, [pages]);

  const helpPages = {
    createPage: "https://www.facebook.com/business/help/1199464373557428",
    pageAccess: "https://www.facebook.com/business/help/2169003770027706",
  };

  return (
    <>
      {pages.length === 0 && (
        <div className={"fbHelpGuide"}>
          <div>
            Looks like we weren't able to find any Facebook Business Pages connected to your Facebook account. But don't
            worry, we're here to help!
          </div>
          <div>Let's run through a few things to get you up and running.</div>
          <div>
            <ul>
              <li>
                <b>Do you have a Facebook Business Page?</b> If not, no worries!{" "}
                <a href={helpPages.createPage} target="_blank">
                  Click here
                </a>{" "}
                to create one in a flash.
              </li>
              <li>
                <b>Check that your Facebook account has full access to the page you want use.</b>{" "}
                <a href={helpPages.pageAccess} target="_blank">
                  Click here
                </a>{" "}
                to learn more about how to check that.
              </li>
            </ul>
          </div>
          <div>
            Once you've completed these steps, hop back here and try connecting your Facebook again. We're rooting for
            you!
          </div>
          <div>
            If you are still having problems, feel free to reach out to us at{" "}
            <a href={"mailto:support@socialcoach.io"}>support@socialcoach.io</a>
          </div>
        </div>
      )}
      {pages.length > 0 && (
        <Dropdown
          placeholder={"Select your Facebook Page"}
          fluid
          onChange={(_, data) => {
            const page = pages.find(p => p.id === data.value);
            selectedPage(page);
          }}
          selection
          options={pagesOptions}
        />
      )}
    </>
  );
};

export const FacebookPagesList = FacebookPagesClass;
