import * as React from "react";
import { Grid } from "semantic-ui-react";

import { VideoScriptCard, VideoScriptGroup } from "../../../components";
import * as DTO from "../../../interfaces";
import { VideoScriptsServices } from "../../../services";

import { SearchComponent } from "./components/Search";
import "./styles.scss";

interface Props {
  redirectUrl: string;
}

const VideoScriptsListFC: React.FC<Props> = ({ redirectUrl }) => {
  const [scriptCategories, setScriptCategories] = React.useState<DTO.CategorizedVideoScripts[]>([]);
  const [searchScriptsResponse, setSearchScriptsResponse] = React.useState<DTO.VideoScriptsSearchResponse | undefined>(
    undefined
  );
  const [searchActive, setSearchActive] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getScripts = async () => {
      const result = await VideoScriptsServices.getAll();
      setScriptCategories(result.categorizedScripts);
    };

    getScripts().catch(console.error);
  }, []);

  return (
    <Grid className={"playerVideoScriptListComponentContainer"} data-elm-id="videoScriptListComponent">
      <SearchComponent
        refreshData={setSearchScriptsResponse}
        searchFunction={VideoScriptsServices.search}
        onSearchedHandler={setSearchActive}
      >
        <Grid.Row>
          <div className={"scriptCategory"}>
            {searchScriptsResponse?.scripts.map(videoScript => (
              <VideoScriptCard
                redirectUrl={redirectUrl}
                key={"video_script_card_" + videoScript.videoScriptId}
                videoScript={videoScript}
              />
            ))}
          </div>
        </Grid.Row>
      </SearchComponent>
      {!searchActive &&
        scriptCategories.map(group => {
          return (
            <VideoScriptGroup
              redirectUrl={redirectUrl}
              key={group.category.categoryId}
              videoScriptGroup={group}
              numberOfCards={4}
              floated={"floatedRight"}
            />
          );
        })}
    </Grid>
  );
};

export const VideoScriptsList = VideoScriptsListFC;
