import * as React from "react";
import{ useIntl, FormattedMessage } from "react-intl";
import { Form, Grid, Loader, Message } from "semantic-ui-react";

import { BasicFormProps, LandingResponse, PlayerQuestion } from "../../../../../interfaces";
import { ConfigContext } from "../../../../../contexts/appContexts";
import { AppContext } from "../../../../../providers";
import { PlayersServices } from "../../../../../services";

import { FirstLoginType, descriptors } from "./descriptors";
import { QuestionInput } from "./components/QuestionInput";

import "./styles.scss";

interface OwnProps extends BasicFormProps {
  handleSetFieldValue?: (field: string, value: any) => void;
}

type Props = OwnProps 

/**
 * Mobile Version: Represents a Player First login screen - Step 3.
 */
const IntegratedFirstLoginQuestions: React.FC<Props> = ({
 
  messages,
  isSubmitting,
  values,
  handleSetFieldValue,
  handleSubmit,
}) => {
  const { questions: configQuestions } = React.useContext(ConfigContext);
  const { userContext } = React.useContext(AppContext);

  const [questions, setQuestions] = React.useState<PlayerQuestion[] | undefined>();
  const [coachInfo, setCoachInfo] = React.useState<LandingResponse>();
  const [currentQuestion, setCurrentQuestion] = React.useState<number>(1);

  React.useEffect(() => {
    if (userContext && userContext.player) {
      PlayersServices.getCoachData(userContext.player.id).then(response => {
        setCoachInfo(response);
      });
    }
  }, []);

  React.useEffect(() => {
    setQuestions(configQuestions.slice().reverse());
  }, []);

  const content = (
    <div className={"questionsDiv"}>
      {!isSubmitting &&
        configQuestions &&
        configQuestions.length > 0 &&
        configQuestions.slice(0, currentQuestion).map((field, index) => {
          return (
            <QuestionInput
              index={index}
              key={field.key}
              field={field}
              coachInfo={coachInfo?.coach}
              isSubmitting={isSubmitting}
              handleSetFieldValue={(fieldName, value) => {
                if (handleSetFieldValue) {
                  handleSetFieldValue(fieldName, value);
                }
                if (index < currentQuestion) {
                  setCurrentQuestion(index + 1);
                }
              }}
              fieldValue={values[field.key]}
            />
          );
        })}

      <Form.Group widths="equal" className={"buttonContainer"}>
        <Form.Button
          size="large"
          type="submit"
          disabled={isSubmitting}
          loading={isSubmitting}
          onClick={() => {
            if (currentQuestion === configQuestions.length && handleSubmit) {
              handleSubmit();
            } else {
              setCurrentQuestion(currentQ => currentQ + 1);
            }
          }}
          className={"signUp"}>
          <FormattedMessage {...descriptors[FirstLoginType.submit]} />
        </Form.Button>
      </Form.Group>
    </div>
  );

  return (
    <Grid className="mobileFormContainer">
      <Form size="large">
        {isSubmitting && <Loader active size="large" />}
        {content}
        {messages && messages.error && messages.error.length > 0 && (
          <Message error header={"Errors:"} list={messages && messages.error} />
        )}
        {messages && messages.warn && messages.warn.length > 0 && (
          <Message warning header={"Warnings: "} list={messages && messages.warn} />
        )}
      </Form>
    </Grid>
  );
};

export const FirstLoginQuestionsMobile = IntegratedFirstLoginQuestions
