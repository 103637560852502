import { defineMessages } from "react-intl";

export enum PromptCardType {
  createdTitle = "PromptCardType.createdTitle",
  specificDateTitleFuture = "PromptCardType.specificDateTitleFuture",
  specificDateTitlePass = "PromptCardType.specificDateTitlePass",
  randomTitle = "PromptCardType.randomTitle",
  welcomePromptTitle = "PromptCardType.welcomePromptTitle",
  welcomePromptSubtitle = "PromptCardType.welcomePromptSubtitle",
}

export const en = {
  [PromptCardType.createdTitle]: "Created: ",
  [PromptCardType.specificDateTitleFuture]: "To be sent on:",
  [PromptCardType.specificDateTitlePass]: "Sent on:",
  [PromptCardType.randomTitle]: "Sent randomly",
  [PromptCardType.welcomePromptTitle]: "First prompt seen by all new players",
  [PromptCardType.welcomePromptSubtitle]: "Welcome Prompt",
};

export const descriptors = defineMessages({
  [PromptCardType.welcomePromptTitle]: {
    id: PromptCardType.welcomePromptTitle,
    defaultMessage: en[PromptCardType.welcomePromptTitle],
    description: "-",
  },
  [PromptCardType.welcomePromptSubtitle]: {
    id: PromptCardType.welcomePromptSubtitle,
    defaultMessage: en[PromptCardType.welcomePromptSubtitle],
    description: "-",
  },
  [PromptCardType.createdTitle]: {
    id: PromptCardType.createdTitle,
    defaultMessage: en[PromptCardType.createdTitle],
    description: "-",
  },
  [PromptCardType.specificDateTitleFuture]: {
    id: PromptCardType.specificDateTitleFuture,
    defaultMessage: en[PromptCardType.specificDateTitleFuture],
    description: "-",
  },
  [PromptCardType.specificDateTitlePass]: {
    id: PromptCardType.specificDateTitlePass,
    defaultMessage: en[PromptCardType.specificDateTitlePass],
    description: "-",
  },
  [PromptCardType.randomTitle]: {
    id: PromptCardType.randomTitle,
    defaultMessage: en[PromptCardType.randomTitle],
    description: "-",
  },
});
