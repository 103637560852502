import * as Yup from "yup";
import { webUrlRegExp } from "../../../utils";
import { shortLink } from "../../../validations";

export const validationSchema = Yup.object().shape({
  webAddress: Yup.string()
    .lowercase()
    .trim()
    .required("Web address is required")
    .matches(webUrlRegExp, "Web address is invalid"),
  slug: shortLink.slug,
});
