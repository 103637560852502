import * as React from "react";
import { Form } from "semantic-ui-react";
import "./styles.scss";
import TextareaAutosize from "react-textarea-autosize";

interface Props {
  title: string;
  aiButtonText: string;
  messagePlaceholder: string;
  error?: string;
  warning?: string;
  disabled: boolean;
  hashtags?: string;
  id?: string;

  currentValue: string;
  setShowAIModal: (show: boolean) => void;
  handleSetFieldValue?: (field: string, value: any) => void;

  dataElmId: string;
}

const CaptionFieldFC: React.FC<Props> = ({
  title,
  error,
  warning,
  aiButtonText = "Generate Caption",
  messagePlaceholder,
  disabled = false,
  currentValue,
  hashtags,
  dataElmId,
  id = "messageToPost",
  setShowAIModal,
  handleSetFieldValue,
}) => {
  return (
    <Form.Field className={"captionFieldContainer"}>
      <div className={"captionLabelRow"}>
        <label className={"captionTitle"}>{title}</label>
        {error ? (
          <label className="error">{error}</label>
        ) : warning ? (
          <label className="warning">{warning}</label>
        ) : (
          <></>
        )}
        <a className="aiButton" onClick={() => setShowAIModal(true)}>
          {aiButtonText}
        </a>
      </div>
      <TextareaAutosize
        data-elm-id={dataElmId}
        id={id}
        name={id}
        className="captionField"
        placeholder={messagePlaceholder}
        rows={3}
        disabled={disabled}
        value={currentValue}
        onChange={data => {
          handleSetFieldValue!("withMessage", data.currentTarget.value + "\n" + hashtags);
          handleSetFieldValue!("messageToPost", data.currentTarget.value);
        }}
      />
    </Form.Field>
  );
};

export const CaptionField = CaptionFieldFC;
