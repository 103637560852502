export enum ScheduleType {
  RANDOM_PROMPTS_DELIVERY = "RANDOM_PROMPTS_DELIVERY",
}

export enum DayOfWeek {
  Monday = "MONDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
  Thursday = "THURSDAY",
  Friday = "FRIDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
}

export const ArrayOfDays = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
  DayOfWeek.Sunday,
];
export interface ScheduleDayRequest {
  dayOfWeek: DayOfWeek;
  time?: string;
}

export interface WeeklyScheduleRequest {
  id?: number;
  type: ScheduleType;
  coachId: string;
  schedule: ScheduleDayRequest[];
}
