import * as React from "react";
import { Grid } from "semantic-ui-react";

import { EditorVideoQueueList } from "../../../components";
import * as DTO from "../../../interfaces";

import "./styles.scss";

const EditingQueueListContentFC: React.FC = () => {
  return (
    <Grid className={"videoCatalystListComponentContainer"} data-elm-id="editorQueueListComponent">
      <Grid.Row>
        <Grid.Column>
          <EditorVideoQueueList />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const EditingQueueListContent = EditingQueueListContentFC;
