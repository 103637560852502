import * as React from "react";
import { Grid, Header, Image } from "semantic-ui-react";
import {FormattedMessage} from "react-intl";
import { descriptors, NotFoundPageType } from "./descriptors";

import { Png } from "../../assets/png";

import { FloatedContainer } from "../../styling/baseStyle";
import "./styles.scss";

interface OwnProps {}

const NotFoundFC: React.FC<OwnProps> = () => {
  return (
    <FloatedContainer>
      <Grid centered columns={1}>
        <Grid.Column width={16} className={"notFoundContainer"}>
          <Image src={Png.Logo} size="large" />
          <Header as="h2" textAlign="center">
            <FormattedMessage {...descriptors[NotFoundPageType.title]} />
          </Header>
          <Header as="h6" textAlign="center">
            <FormattedMessage {...descriptors[NotFoundPageType.subtitle]} />
          </Header>
          <Image src={Png.Error} size="large" />
        </Grid.Column>
      </Grid>
    </FloatedContainer>
  );
};

export const NotFoundPage = NotFoundFC;
