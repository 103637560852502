import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Grid } from "semantic-ui-react";
import { get } from "lodash";

import { AppContext } from "../../../providers";
import { SideBar, TitleSection } from "../../../components";
import { SubCoachEditForm } from "../../SubCoach/EditForm";
import { SubCoachesServices } from "../../../services/SubCoachesServices";
import {
  UserStatuses,
  WebComponents,
} from "../../../interfaces";
import { coachWithWritePermission } from "../../../utils";

import {
  WhiteContainer,
  ContentGrid,
  SocialGrid,
} from "../../../styling/baseStyle";
import "./styles.scss";

type Props = RouteComponentProps;

const EditSubCoachFC: React.FC<Props> = ({
  history,
  match: { params },
  location,
}) => {
  const { userContext } = React.useContext(AppContext);

  const formSubmitRef = React.useRef<HTMLFormElement>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [subCoachEdited, setSubCoachEdited] = React.useState<boolean>(false);

  const [firstName] = React.useState<string>(
    get(location, "state.firstName", "")
  );
  const [lastName] = React.useState<string>(
    get(location, "state.lastName", "")
  );
  const [email] = React.useState<string>(get(location, "state.email", ""));
  const [isInactive, setIsInactive] = React.useState<boolean>(
    get(location, "state.inactive", false)
  );
  const [lastestLoginDate, setLastestLoginDate] = React.useState<string>("");
  const [createdDate, setCreatedDate] = React.useState<string>("");

  const withWritePermission = coachWithWritePermission(
    WebComponents.ADMIN,
    userContext
  );

  React.useEffect(() => {
    setCreatedDate(get(location, "state.createdDate", ""));
    setLastestLoginDate(get(location, "state.lastestLoginDate", ""));
  }, [params["userId"]]);

  const goToSubCoachesList = () => {
    setLoading(false);
    history.goBack();
  };

  const refreshView = () => {
    setLoading(false);
    setSubCoachEdited(false);
  };

  const updateSubCoach = () => {
    setLoading(true);
    // Execute handleSubmit action on create sub coach form
    if (formSubmitRef.current) {
      formSubmitRef!.current!.handleSubmit();
    }
  };

  const inactiveSubcoach = () => {
    setLoading(true);
    SubCoachesServices.updateSubCoachStatus(
      params["userId"],
      UserStatuses.SUSPENDED
    )
      .then(() => {
        alert("Subcoach inactivated successfully");
        history.goBack();
      })
      .catch(() => {
        alert("Unknow error. Try again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const reactiveSubcoach = () => {
    setLoading(true);
    SubCoachesServices.updateSubCoachStatus(
      params["userId"],
      UserStatuses.ACTIVE
    )
      .then(() => {
        alert("Subcoach activated successfully");
        setIsInactive(false);
      })
      .catch(() => {
        alert("Unknow error. Try again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const customLinkSection = (): JSX.Element => {
    return (
      <SocialGrid.Column width={10} floated="right">
        <SocialGrid.Column
          width={isInactive ? 6 : 8}
          floated="right"
          className={"buttonLeft"}
        >
          <Button
            data-elm-id={`editSubcoachesBackBtn`}
            fluid
            className={"secondary rounded buttonRight"}
            onClick={goToSubCoachesList}
          >
            Back
          </Button>
        </SocialGrid.Column>
        {withWritePermission && (
          <SocialGrid.Column
            width={isInactive ? 10 : 8}
            floated="right"
            className={"buttonRight"}
          >
            {!isInactive && subCoachEdited && (
              <Button
                data-elm-id={`editSubcoachesSaveBtn`}
                fluid
                className={"primary rounded buttonRight"}
                onClick={updateSubCoach}
              >
                Save
              </Button>
            )}
            {!isInactive && !subCoachEdited && (
              <Button
                data-elm-id={`editSubcoachesInactiveBtn`}
                loading={loading}
                fluid
                className={"secondary deleteButton rounded buttonRight"}
                disabled={loading}
                onClick={inactiveSubcoach}
              >
                Inactive
              </Button>
            )}
            {isInactive && (
              <Button
                data-elm-id={`editSubcoachesReactiveBtn`}
                disabled={loading}
                loading={loading}
                fluid
                className={"primary rounded buttonRight"}
                onClick={reactiveSubcoach}
              >
                Reactivate
              </Button>
            )}
          </SocialGrid.Column>
        )}
      </SocialGrid.Column>
    );
  };

  return (
    <WhiteContainer>
      <SideBar history={history} />

      <ContentGrid className={"listManagerContent"} columns={1}>
        <Grid columns={1}>
          <TitleSection
            title={"Edit an Admin"}
            ready={true}
            showNextOption={false}
            titleSize={isInactive ? 6 : 9}
            buttonsSize={isInactive ? 10 : 7}
            nextButtonSize={6}
            disableButtons={loading}
            showLinkOption={true}
            customLinkSection={customLinkSection()}
          />

          <Grid.Row className="coachBaseContainer">
            <Grid.Column>
              <Grid>
                <Grid.Row>
                  <Grid.Column
                    className={
                      !withWritePermission || isInactive ? "disabledForm" : ""
                    }
                  >
                    <SubCoachEditForm
                      subcoachFirstName={firstName}
                      subcoachLastName={lastName}
                      subcoachEmail={email}
                      coachPermissions={get(location, "state.permissions", {})}
                      createdDate={createdDate}
                      latestLoginDate={lastestLoginDate}
                      userId={params["userId"]}
                      formSubmitRef={formSubmitRef}
                      errorHandler={() => setLoading(false)}
                      successHandler={refreshView}
                      withWritePermission={withWritePermission}
                      onChangeHandler={() => setSubCoachEdited(true)}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const EditSubCoach = withRouter(EditSubCoachFC);
