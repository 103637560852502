import * as React from "react";
import { Grid, Header } from "semantic-ui-react";
import * as styles from "./styles.scss";
import { terms } from "./terms";

export class TermsPage extends React.Component {
  render() {
    const t = styles;
    return (
      <div>
        <Grid>
          <Grid.Row>
            <div className={"titleSection"}>
              <Header textAlign="center" as="h1">
                TERMS OF SERVICE
              </Header>
              <p>Date of Last Revision: June 26, 2020</p>
              <p>Please read these terms and conditions carefully before using Our Service.</p>
            </div>
          </Grid.Row>
        </Grid>

        <Grid.Row className={"termsGrid"}>
          {terms.map((option, index) => {
            return (
              <Grid.Row key={index} className={"termSection"}>
                <Header as="h1">{option.title}</Header>
                <Header as="h2">{option.subtitle}</Header>
                <Header as="h3">{option.h3}</Header>

                {option.description &&
                  option.description.map((desc, descIndex) => {
                    return <p key={"desc" + index + descIndex} dangerouslySetInnerHTML={{ __html: desc }} />;
                  })}

                <ul>
                  {option.bullets &&
                    option.bullets.map((bullet, bulletsIndex) => {
                      return <li key={"bullets" + index + bulletsIndex} dangerouslySetInnerHTML={{ __html: bullet }} />;
                    })}
                </ul>
              </Grid.Row>
            );
          })}
        </Grid.Row>
      </div>
    );
  }
}
