import * as React from "react";
import { Formik } from "formik";
import { RouteComponentProps, withRouter } from "react-router";
import { get, map as mapValues } from "lodash";

import { validationSchema } from "./validations";
import { Campaign, Category, DayOfWeek } from "../../../../../interfaces";
import { CampaignBasicInfo } from "../../../../../components";

export interface OwnProps {
  /**
   * Campaign Info
   */
  campaign?: Campaign;

  /**
   * Function called when the form is submitted succesfully
   */
  onSuccessHandler: (campaign: Campaign) => void;
  /**
   * Function called after fields validation.
   * @param: withErrors: If all mandatory fields are filled return false
   */
  onValidationHandler: (withErrors: boolean) => void;
  /**
   * Forms ref - calls submit outside form
   */
  basicInfoSubmitRef: any;
}

type Props = OwnProps & RouteComponentProps;

const BasicForm: React.FC<Props> = ({
  campaign,
  location,
  basicInfoSubmitRef,
  onSuccessHandler,
  onValidationHandler,
}) => {
  const mandatoryFields = ["socialNetworksCounter", "isAutoPost", "deliveryMode", "name", "randomScheduleDays"];
  const submitAction = (values: any): void => {
    const {
      name,
      deliveryMode,
      randomScheduleDays,
      isAutoPost,
      socialNetworks,
      categories,
      deliveryTimeDatePicker,
      randomScheduleDeliveryTimePst,
      startDate,
      startDatePicker,
      endDate,
      endDatePicker,
    } = values;

    const days = randomScheduleDays!.map((day: DayOfWeek) => {
      return {
        dayOfWeek: day,
        time: randomScheduleDeliveryTimePst,
      };
    });
    const updatedCampaing = {
      ...campaign,
      name,
      randomScheduleDays: deliveryMode !== "SPECIFIC_DATE" ? days : undefined,
      deliveryMode,
      isAutoPost,
      socialNetworks,
      campaignPrompts: get(campaign, "campaignPrompts", []),
      categoryIds: categories.map((cat: Category) => cat.categoryId),
      randomScheduleDeliveryTimePst,
      deliveryTimeDatePicker,
      categories,
      startDatePicker,
      endDatePicker,
      startDate,
      endDate,
    } as Campaign;

    onSuccessHandler(updatedCampaing);
  };

  return (
    <div>
      <Formik
        validateOnBlur={true}
        validateOnChange={false}
        validateOnMount={false}
        initialValues={{
          campaignId: get(campaign, "campaignId", null),
          randomScheduleDeliveryTimePst: get(campaign, "randomScheduleDeliveryTimePst", undefined),
          deliveryTimeDatePicker: get(campaign, "deliveryTimeDatePicker", undefined),
          name: get(campaign, "name", ""),
          deliveryMode: get(campaign, "deliveryMode", get(location, "state.deliveryMode", "RANDOM_DELIVERY")),
          isAutoPost: get(campaign, "isAutoPost", true),
          randomScheduleDays: mapValues(get(campaign, "randomScheduleDays", []), "dayOfWeek"),
          socialNetworks: get(campaign, "socialNetworks", {}),
          socialNetworksCounter: get(campaign, "Object.keys(socialNetworks)", 0),
          categories: get(
            campaign,
            "categories",
            location.state && location.state["category"] ? [location.state["category"]] : []
          ),
          startDate: get(campaign, "startDate", undefined),
          endDate: get(campaign, "endDate", undefined),
          startDatePicker: get(campaign, "startDatePicker", undefined),
          endDatePicker: get(campaign, "endDatePicker", undefined),
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, validateForm }) => {
          const errorsResponse = await validateForm(values);
          if (errorsResponse && Object.values(errorsResponse).length > 0) {
            setErrors(errorsResponse);
          } else {
            setErrors({});
            submitAction(values);
          }
        }}>
        {({ values, errors, handleChange, setFieldValue, handleSubmit, validateForm }) => {
          return (
            <CampaignBasicInfo
              messages={{
                error: Object.values(errors) as string[],
              }}
              basicInfoSubmitRef={basicInfoSubmitRef}
              handleSubmit={handleSubmit}
              handleChange={(e, data) => {
                handleChange(e);
                if (mandatoryFields.includes(data?.id)) {
                  validateForm({ ...values, [data?.id]: data?.value }).then(errorsResponse => {
                    if (errorsResponse && Object.values(errorsResponse).length > 0) {
                      onValidationHandler(true);
                    } else {
                      onValidationHandler(false);
                    }
                  });
                }
              }}
              handleSetFieldValue={(field, value) => {
                setFieldValue(field, value);
                if (mandatoryFields.includes(field)) {
                  validateForm({ ...values, [field]: value }).then(errorsResponse => {
                    if (errorsResponse && Object.values(errorsResponse).length > 0) {
                      onValidationHandler(true);
                    } else {
                      onValidationHandler(false);
                    }
                  });
                }
              }}
              values={values}
              isSubmitting={false}
            />
          );
        }}
      </Formik>
    </div>
  );
};

export const BasicInfoStep = withRouter(BasicForm)
