import * as React from "react";
import { Formik } from "formik";

import { FirstLoginQuestions } from "../../../components/Players";
import { PlayersServices } from "../../../services";

import { validationSchemaBuilder } from "./validations";
import { AppContext } from "../../../providers";
import { ConfigContext } from "../../../contexts/appContexts";
import { get, keys } from "lodash";
import { manageWebAddress, useCheckMobileScreen } from "../../../utils";
import { FirstLoginQuestionsMobile } from "../../../components/Players/FirstLogin/Mobile/Questions";

interface OwnProps {
  coachId: string;
  successHandler: () => void;
}

export const FirstQuestionsForm: React.FC<OwnProps> = ({ coachId, successHandler }) => {
  const { userContext, setUserContext } = React.useContext(AppContext);
  const { questions: configQuestions, questionsLimits } = React.useContext(ConfigContext);

  const questionsValues = configQuestions.reduce(
    (acc, question) => ((acc[question.key] = get(userContext, "player." + question.key, "")), acc),
    {}
  );
  const questionsNames = configQuestions.reduce(
    (acc, question) => ((acc[question.key] = question.contentDescriptor), acc),
    {}
  );

  const FormComponent = useCheckMobileScreen() ? FirstLoginQuestionsMobile : FirstLoginQuestions;
  return (
    <div>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          ...questionsValues,
        }}
        validationSchema={() => validationSchemaBuilder(questionsValues, questionsLimits, questionsNames)}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            const payload: any = {};
            setSubmitting(true);

            Object.keys(values).forEach(questionIndex => {
              payload[questionIndex] = values[questionIndex];
            });

            const webAddressWithFormat = await manageWebAddress(userContext?.player?.webAddress!);

            payload.webAddress = webAddressWithFormat;

            // LoginStepServices.sendPlayerStep(PlayerSignUpStep.PLAYER_BUSINESS_DATA).then(() => {
            PlayersServices.updatePlayerQuestions(userContext?.player?.id!, payload).then(async () => {
              setUserContext({
                ...userContext!,
                player: {
                  ...userContext!.player!,
                  ...payload,
                },
              });
              successHandler();
            });
            // });
          } catch (e) {
            setSubmitting(false);
            setErrors({
              [keys(values)[0]]: "Error updating player questions",
            });
          }
        }}>
        {({ values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue, setErrors }) => (
          <FormComponent
            messages={{ error: Object.values(errors) as string[] }}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleSetFieldValue={(field, value) => {
              setErrors({});
              setFieldValue(field, value);
            }}
            isSubmitting={isSubmitting}
            values={values}
          />
        )}
      </Formik>
    </div>
  );
};
