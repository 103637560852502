import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { URLS } from "../../../utils";

import { AppContext } from "../../../providers";
import { PlayerSignUpStep, PlayersServices } from "../../../services";

import { FloatedContainer } from "../../../styling/baseStyle";
import { FirstQuestionsForm } from "../../FormikForms/FirstQuestions";

// TODO: REMOVE
const IntegratedFirstQuestionsPage: React.FC<RouteComponentProps> = ({ history }) => {
  const { userContext } = React.useContext(AppContext);
  const [coachId, setCoachId] = React.useState<string>("");
  const playerId = userContext?.player?.id;

  React.useEffect(() => {
    if (userContext?.signUpStep! === PlayerSignUpStep[PlayerSignUpStep.PLAYER_BUSINESS_DATA]) {
      window.location.assign(URLS.player.dashboard);
    }
    if (userContext && userContext.player && userContext.player?.id) {
      PlayersServices.getCoachData(userContext.player?.id).then(response => {
        setCoachId(response.coachId);
      });
    }
  }, [playerId]);

  const successHandler = () => {
    history.push(URLS.player.firstSocialNetworks);
  };

  return (
    <FloatedContainer>
      <FirstQuestionsForm coachId={coachId} successHandler={successHandler} />
    </FloatedContainer>
  );
};

export const FirstQuestionsPage = withRouter(IntegratedFirstQuestionsPage);
