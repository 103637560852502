export function modalReducer(state: any, action: any) {
  switch (action.type) {
    case "close":
      return { open: false, success: false };
    case "open":
      return { open: true, status: action.status, success: false };
    case "loading":
      return {
        ...state,
        loading: true,
      };
    case "loaded":
      return {
        ...state,
        loading: false,
      };
    case "success":
      return {
        ...state,
        success: true,
      };
    default:
      throw new Error("Unsupported action...");
  }
}
