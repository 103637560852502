import { FirstLoginType } from "./descriptors";
import * as MODEL from "../../../../../interfaces/models";

export const fields: MODEL.FormField[] = [
  {
    id: "webAddress",
    name: "webAddress",
    type: "text",
    label: "webAddress",
    placeholder: FirstLoginType.webAddress,
    disabled: false,
  },
  {
    id: "slug",
    name: "slug",
    type: "text",
    label: "shortLink",
    placeholder: FirstLoginType.shortLink,
    disabled: false,
  },
];
