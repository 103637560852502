import React, {FC, useEffect, useState} from "react";
import * as DTO from "../../interfaces";
import ReactPlayer from "react-player";
import {Image} from "semantic-ui-react";

interface OwnProps {
  media: DTO.Media;
}

type Props = OwnProps;

const MediaPlayerFC: FC<Props> = ({
  media
}: Props) => {
  const [ playWhenReady, setPlayWhenReady ] = useState(false)
  const [ mediaPlaying, setMediaPlaying ] = useState(false)
  const [ mediaReady, setMediaReady ] = useState(false)

  useEffect(() => {
    if (playWhenReady && mediaReady) {
      setMediaPlaying(true);
    }
  }, [playWhenReady, mediaReady])

  const placeholder = () => {
    return media?.thumbnailUri && (
      <Image src={media?.thumbnailUri} className={"mediaThumbnail"} />
    )
  }

  return (
    <div className={"mediaContainer"}>
      {media?.category === 'VIDEO' && (
        <ReactPlayer
          url={media?.uri}
          light={placeholder()}
          width={"100%"}
          height={"auto"}
          controls
          playsinline
          playing={mediaPlaying}
          onClickPreview={() => { setPlayWhenReady(true) }}
          onReady={() => { setMediaReady(true) }}
          onPause={() => setMediaPlaying(false) }
        />
      )}
      {media?.category === 'IMAGE' && (
        <Image src={media?.thumbnailUri || media?.uri} className={"mediaThumbnail"} />
      )}
    </div>
  )
}

export const MediaPlayer = MediaPlayerFC
