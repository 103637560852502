import styled from "styled-components";
import { Grid } from "semantic-ui-react";

import { theme } from "../../styling/theme";

export const BoxDescription = styled.p`
  color: ${theme.labelColor};
  font-family: "Open Sans", sans-serif;
  font-size: 1.15em;
  letter-spacing: -0.24px;
  line-height: 19px;
  text-align: center;
  white-space: pre-line;
`;

export const UploadGrid = styled(Grid)`
  .ui.primary.button {
    font-size: 16px;
  }
  .ui.primary.button:hover {
    height: 50px;
  }
`;

export const UploadButtonColumn = styled(Grid.Column)({
  paddingTop: "30px !important",
  paddingBottom: "30px !important",
});
