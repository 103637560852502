export function modalReducer(state: any, action: any) {
  switch (action.type) {
    case "close_edit":
      return { open_edit: false, open_delete: false };
    case "open_edit":
      return { open_edit: true, open_delete: false };
    case "close_delete":
      return { open_delete: false, open_edit: false };
    case "open_delete":
      return { open_delete: true, open_edit: false };
    default:
      throw new Error("Unsupported action...");
  }
}
