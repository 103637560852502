import * as React from "react";
import { get, join } from "lodash";

import { ConfigContext } from "../../../contexts/appContexts";
import { ConfigProperties, SocialNetworksName } from "../../../interfaces";
import SocialNetworkButton from "../../SocialNetworkButton";

import { SocialNetworkCustomProps } from "../SocialProps";
import CoachSocialNetworkButton from "../../Coaches/CoachSocialNetworkButton";
import useScript from "../../../utils/useScript";

const TikTokFC: React.FC<SocialNetworkCustomProps> = ({
  title,
  username,
  viewName = "Register",
  handleLogin,
  handleDelete,
  inProfile,
  asACoach = false,
  loading,
  connected = false,
  displayTiktokPreferences = false,
}) => {
  useScript("https://www.tiktok.com/embed.js");
  const config: ConfigProperties = React.useContext(ConfigContext);

  const [loginInProgress, setLoginInProgress] = React.useState<boolean>(false);
  const [displayPreferences, setDisplayPreferences] = React.useState<boolean>(false);

  React.useEffect(() => {
    window.addEventListener("message", handlePostMessage);
  }, []);

  React.useEffect(() => {
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, []);

  React.useEffect(() => {
    if (displayTiktokPreferences === true) {
      setDisplayPreferences(displayTiktokPreferences);
    }
  }, [displayTiktokPreferences]);

  const handlePostMessage = (event: any) => {
    if (event.data.type === "profile") {
      const profile = {
        username: get(event.data.profile.user, "display_name", undefined),
        code: get(event.data.profile, "access_token", ""),
        expiresIn: get(event.data.profile, "expires_in", "5184000"),
        refreshToken: get(event.data.profile, "refresh_token", ""),
        refreshTokenExpiresIn: get(event.data.profile, "refresh_expires_in", "5184000"),
        openId: get(event.data.profile.data, "open_id", ""),
      };
      setLoginInProgress(true);
      setTimeout(() => {
        handleLogin(null, profile);
        setLoginInProgress(false);
      }, 5000);
    }
  };

  const handlerAction = (sn: SocialNetworksName) => {
    if (username && asACoach) {
      disconnectHandlerAction(sn);
    } else {
      requestProfile();
    }
  };

  const disconnectHandlerAction = (sn: SocialNetworksName) => {
    if (username && handleDelete) {
      handleDelete(sn);
    }
  };

  const requestProfile = () => {
    const { permissions, api_key, callback } = config.socialNetworks.tiktok;
    const scope = join(permissions, ",");

    const width = 420;
    const height = 500;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    const csrfState = Math.random().toString(36).substring(2);

    let url = "https://www.tiktok.com/v2/auth/authorize/";

    console.log("callback", callback);
    url += "?client_key=" + api_key;
    url += "&scope=" + scope;
    url += "&response_type=code";
    url += `&redirect_uri=${callback}`;
    url += "&state=" + csrfState;

    window.open(
      url,
      "TikTok",
      "menubar=no,location=no,resizable=no,scrollbars=no,status=no,width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  const ButtomComponent = asACoach ? CoachSocialNetworkButton : SocialNetworkButton;
  return (
    <ButtomComponent
      title={title || ""}
      data-elm-id={`social${viewName}ButtonTikTok${connected}`}
      account={username ?? ""}
      handler={handlerAction}
      disconnectHandler={disconnectHandlerAction}
      inProfile={inProfile}
      socialNetwork={"tiktok"}
      loading={asACoach ? loading : loginInProgress}
      connected={connected}
      displayPreferences={displayPreferences}
    />
  );
};

export const TikTok = TikTokFC;
