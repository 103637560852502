import * as React from "react";
import { Header, Grid, SemanticFLOATS } from "semantic-ui-react";

import { LandingResponse } from "../../interfaces/dtos";
import ReactHtmlParser from "react-html-parser";

import "./styles.scss";

interface OwnProps {
  coachInfo: LandingResponse;
  preview?: boolean;
  phoneMode?: boolean;
}

type Props = OwnProps;

const UserResumeFC: React.FC<Props> = ({ coachInfo, phoneMode }) => {
  const props = phoneMode
    ? {
        className: "coachPreviewSection",
      }
    : {
        floated: "left" as SemanticFLOATS,
        className: "coachSection",
      };
  const UserResumeCompleted: JSX.Element = (
    <Grid.Column floated="left" width={16} className={"coachSection"}>
      <Grid centered className={"infoSection"}>
        <Grid.Row>
          <Header as="h1">{coachInfo.coach.firstName + " " + coachInfo?.coach.lastName} </Header>
        </Grid.Row>
        {coachInfo?.salesMessage && (
          <Grid.Row className={"salesMessageSection"}>
            <div>{ReactHtmlParser(coachInfo!.salesMessage)}</div>
          </Grid.Row>
        )}
      </Grid>
    </Grid.Column>
  );

  const previewMode: JSX.Element = (
    <Grid.Column width={16} className={"coachPreviewSection"}>
      <Grid centered className={"infoSection"}>
        <Grid.Row className={"infoSectionContent"}>
          <Grid.Row>
            <Header as="h1">{coachInfo.coach.firstName + " " + coachInfo?.coach.lastName} </Header>
          </Grid.Row>

          {coachInfo?.salesMessage && (
            <Grid.Row className={"salesMessageSection"}>
              <Header as="h5"> {coachInfo!.salesMessage} </Header>
            </Grid.Row>
          )}
        </Grid.Row>
      </Grid>
    </Grid.Column>
  );

  return (
    <Grid.Column width={16} {...props}>
      <Grid centered className={"infoSection"}>
        <Grid centered className={phoneMode ? "infoSectionContent" : "messageSection"}>
          <Grid.Row>
            <Header as="h2">{coachInfo.coach.firstName + " " + coachInfo?.coach.lastName} </Header>
          </Grid.Row>

          {coachInfo?.salesMessage && (
            <Grid.Row className={"salesMessageSection"}>
              <div>{ReactHtmlParser(coachInfo!.salesMessage)}</div>
            </Grid.Row>
          )}
        </Grid>
      </Grid>
    </Grid.Column>
  );
};

export const UserResume = UserResumeFC;
