import * as React from "react";
import { PostReadyContent } from "../../../../../../../interfaces";
import { createVideoThumbnailBlob } from "../../../../../../../utils";

import { RepostModal } from "../RepostModal";

import useDisplayData from "./hooks";
import { PostCard, ThumbnailPreview } from "./styled";
import "./styles.scss";

const MAX_CARD_WIDTH = 400;
const MIN_CARD_WIDTH = 148;

interface OwnProps {
  post: PostReadyContent;
  refreshHandler: () => void;
}

const ReadyToPostCardFC: React.FC<OwnProps> = ({ post, refreshHandler }) => {
  const { contentId, contentType, mediaType, mediaUrl } = useDisplayData(post);

  const [thumbnail, setThumbnail] = React.useState<string | undefined>();
  const [imageWidth, setImageWidth] = React.useState<number>(MIN_CARD_WIDTH);
  const [isVertical, setIsVertical] = React.useState<boolean>(false);
  const [showRepostModal, setShowRepostModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    const success = (width: number, height: number): void => {
      if (MAX_CARD_WIDTH * 0.6 < width) {
        if (width > height) {
          setImageWidth(MAX_CARD_WIDTH * 0.6);
        } else {
          setImageWidth((MAX_CARD_WIDTH * 0.55 * width) / height);
        }
      } else {
        setImageWidth(width);
      }
      setIsVertical(width < height);
    };

    const setImageSize = (setImageDimensions: any, imageUrl: string) => {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        setImageDimensions(img.width, img.height);
      };
    };

    if (post.media) {
      setImageSize(success, thumbnail!!);
    }
  }, [thumbnail, post.media]);

  React.useEffect(() => {
    if (mediaType) {
      const creativeUrl = mediaUrl;
      const urlPromise = mediaType === "IMAGE" ? Promise.resolve(creativeUrl) : createVideoThumbnailBlob(creativeUrl);
      urlPromise.then(url => {
        setThumbnail(url);
      });
    }
  }, [mediaUrl]);

  const cardPressed = (): void => {
    setShowRepostModal(true);
  };

  return (
    <>
      <PostCard
        onClick={cardPressed}
        className={"postReadyToPostCard"}
        imagewidth={imageWidth < MIN_CARD_WIDTH ? MIN_CARD_WIDTH : imageWidth}
      >
        <ThumbnailPreview
          thumbnailurl={thumbnail}
          resizemode={isVertical ? "cover" : "cover"}
          imagewidth={imageWidth}
        />
      </PostCard>

      {showRepostModal && (
        <RepostModal
          opened={showRepostModal}
          thumbnail={thumbnail || ""}
          contentId={contentId}
          contentType={contentType}
          closeHandler={() => {
            setShowRepostModal(false);
            refreshHandler();
          }}
        />
      )}
    </>
  );
};

export const ReadyToPostCard = ReadyToPostCardFC;
