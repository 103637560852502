import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useIntl } from "react-intl";
import { Header, Grid, Message, Loader, Transition, Icon, Button, Modal } from "semantic-ui-react";

import { UserContext } from "@socialcoach/services";

import { TitleSection, SideBar } from "../../../components";
import { SubscriptionStatus } from "../../../interfaces";
import { AppContext } from "../../../providers";

import { UpdatePaymentForm } from "../UpdatePaymentForm";

import { WhiteContainer, ContentGrid, InternalGrid } from "../../../styling/baseStyle";
import "./styles.scss";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { UsersService } from "../../../services";

interface OwnProps {
  successHandler: (user: UserContext) => void;
}

type Props = OwnProps & RouteComponentProps;

const IntegratedPaymentInfo: React.FC<Props> = ({ history }) => {
  const { userContext, setUserContext } = React.useContext(AppContext);

  const [saved, setSaved] = React.useState<boolean>(false);
  const [updateModalOpen, setUpdateModalOpen] = React.useState<boolean>(false);

  const savedBanner = () => {
    return (
      saved && (
        <Transition.Group as={Header} duration={300} animation={"drop"} size="tiny" verticalalign="middle">
          <Message info content={"Payment method updated"} />
        </Transition.Group>
      )
    );
  };

  const errorBanner = (errors: any) => {
    return (
      errors &&
      Object.values(errors).length > 0 && (
        <Grid.Row columns={1}>
          <Grid.Column width={12}>
            <Message negative list={Object.values(errors) as string[]} />
          </Grid.Column>
        </Grid.Row>
      )
    );
  };

  const pmIndex = userContext?.paymentMethods ? userContext.paymentMethods.length - 1 : 0;
  const paymentMethod = userContext?.paymentMethods!![pmIndex];

  const cardIcon = (creditCardBrand: string): SemanticICONS => {
    switch (creditCardBrand) {
      case "visa":
        return "cc visa";
      case "mastercard":
        return "cc mastercard";
      case "paypal":
        return "cc paypal";
      case "stripe":
        return "cc stripe";
      case "jcb":
        return "cc jcb";
      case "discover":
        return "cc discover";
      case "diners club":
        return "cc diners club";
      case "apple pay":
        return "cc apple pay";
      case "amex":
        return "cc amex";
      case "amazon pay":
        return "cc amazon pay";
      default:
        return "cc visa";
    }
  };
  return (
    <WhiteContainer>
      <SideBar history={history} />

      <ContentGrid>
        <Grid className={"coachPlanContainer"} width={2}>
          <Grid columns={1} className={"socialContent"}>
            <TitleSection
              title={`Payment Information`}
              ready={true}
              isLoading={false}
              disableDuringLoading={true}
              showNextOption={false}
              showLinkOption={false}
            />

            {userContext?.player && (
              <InternalGrid className={"coachBaseContainer"}>
                {userContext!!.subscriptions!![0].status === SubscriptionStatus.INACTIVE &&
                  errorBanner({
                    creditCrad:
                      "Your credit card has been declined. Please update your information to avoid interruption to your service.",
                  })}

                {savedBanner()}
                <Grid.Column centered width={8}>
                  <div className="ui cards">
                    <div className="card">
                      {paymentMethod && (
                        <div className="content">
                          <div className="header">Current Credit Card Information</div>
                          <Icon size={"large"} name={cardIcon(paymentMethod!!.creditCardBrand)} />
                          <div className="description">{"**** " + paymentMethod!!.creditCardLast4}</div>
                          <div className="meta">
                            Expiration Date:{" "}
                            {paymentMethod!!.creditCardExpirationMonth + "/" + paymentMethod!!.creditCardExpirationYear}
                          </div>
                        </div>
                      )}

                      <div className="extra content">
                        <Modal
                          trigger={
                            <Button fluid centered className={"primary"} onClick={() => setUpdateModalOpen(true)}>
                              Update Credit Card
                            </Button>
                          }
                          open={updateModalOpen}
                          onClose={() => {
                            setUpdateModalOpen(false);
                          }}
                          closeIcon={true}
                          size={"tiny"}
                        >
                          <Modal.Header centered> Update Credit Card</Modal.Header>
                          <Modal.Content>
                            <Modal.Description>
                              <UpdatePaymentForm
                                plan={userContext.subscriptions!![0].pricePlan}
                                email={userContext.account!!.email!!}
                                onSuccess={() => {
                                  UsersService.getUserContext()
                                    .then(setUserContext)
                                    .finally(() => {
                                      setUpdateModalOpen(false);
                                      setSaved(true);
                                    });
                                }}
                              />
                            </Modal.Description>
                          </Modal.Content>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </Grid.Column>
              </InternalGrid>
            )}
          </Grid>
        </Grid>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const PaymentInfo = withRouter(IntegratedPaymentInfo);
