import * as React from "react";
import { Modal, Button, TextArea, Input, Header } from "semantic-ui-react";

import { modalReducer } from "./modalReducer";
import "./styles.scss";
import { AIService } from "../../../../../services/AIService";

interface OwnProps {
  opened: boolean;

  acceptHandler: (promptContent: string, hashtags?: string[]) => void;
  rejectHandler?: () => void;
}

type Props = OwnProps;

/**
 * Prediction Components
 */
const PredictionsComponentClass: React.FC<Props> = ({ opened, acceptHandler, rejectHandler }) => {
  const [state, dispatch] = React.useReducer(modalReducer, { open: opened, loading: false });

  const [stopSequences, setStopSequences] = React.useState<string[]>([]);
  const [prediction, setPrediction] = React.useState<string | undefined>(undefined);
  const [prompt, setPrompt] = React.useState<string>("");

  const exampleList = [
    "How 2-1 buydowns can help with affordability in the Chicago market",
    "Using your tax return towards your down payment on a house",
    "About relaxing on your own porch",
    "Use a quote from Warren Buffet",
    "Something funny with a loan pun",
  ];

  React.useEffect(() => {
    if (opened) {
      dispatch({ type: "open" });
    } else {
      dispatch({ type: "close" });
    }
  }, [opened]);

  const generatePrediction = (predictionText: string): void => {
    setPrediction(undefined);
    dispatch({ type: "loading" });
    AIService.getCaption({
      input: predictionText,
    }).then(captionResponse => {
      if (captionResponse.success) {
        setPrediction(captionResponse.caption);
        setStopSequences(current => {
          return current.concat(captionResponse.caption || "");
        });
        dispatch({ type: "loaded" });
      }
    });
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      generatePrediction(prompt);
    }
  };

  const tryIdea = (idea: string) => {
    setPrompt(idea);
    generatePrediction(idea);
  };

  return (
    <Modal
      closeIcon
      size={"small"}
      open={state.open}
      onClose={() => {
        dispatch({ type: "close" });
        if (rejectHandler) {
          rejectHandler();
        }
      }}
    >
      <Modal.Header className="aiModalHeader">
        <Header.Content>
          <label>SocialCoach AI Caption Generator</label>
        </Header.Content>
      </Modal.Header>

      <Modal.Content>
        <div className="examplesSection">
          <p className="exampleTitle">In a few words, what do you want the caption to be about? Here's some ideas:</p>
          <ul>
            {exampleList.map((item, index) => {
              return (
                <li key={"example" + index}>
                  <a className="example" onClick={() => tryIdea(item)}>
                    {item}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>

        <Input
          fluid
          placeholder={"Enter instructions"}
          key={"aiPromptText"}
          data-elm-id={"aiPredictionModalPromptToCompleteText"}
          value={prompt}
          onKeyUp={handleKeyPress}
          className={"aiPromptText"}
          onChange={(e, data) => setPrompt(`${data!.value}`)}
          loading={state.loading}
        />

        {prediction && (
          <div className="predictionResultConatiner">
            <TextArea
              key={"aiPromptText"}
              data-elm-id={"aiPredictionModalPrediction"}
              className={"predictionArea"}
              fluid="true"
              label={"Tell more about the changes"}
              id={"aiPromptText"}
              name={"aiPromptText"}
              placeholder={""}
              value={prediction}
              rows={10}
            />
            <p className="copyrightLabel">* Powered by ChatGPT</p>
          </div>
        )}
      </Modal.Content>

      <Modal.Actions className="predictionActions">
        <Button
          data-elm-id={"aiPredictionModalGeneratePromptBtn"}
          primary={!prediction}
          secondary={!!prediction}
          disabled={state.loading}
          onClick={() => {
            generatePrediction(prompt);
          }}
        >
          {prediction ? "Generate New Caption" : "Generate Caption"}
        </Button>

        {prediction && (
          <Button
            data-elm-id={"aiPredictionModalGeneratePromptBtn"}
            primary
            onClick={() => {
              // dispatch({ type: "close" });
              const hashtags: string[] = [];
              const caption: string = prediction
                ?.split(" ")
                .map(word => {
                  if (word.startsWith("#")) {
                    hashtags.push(word);
                    return "";
                  } else {
                    return word;
                  }
                })
                .join(" ");

              acceptHandler(caption, hashtags);
            }}
          >
            Use & Edit Caption
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export const PredictionsComponent = PredictionsComponentClass;
