import * as React from "react";
import { Option, CardsGrid, OptionGroup, OptionMeta, OptionContent, OptionImage } from "./styled";
import "./styles.scss";

interface CardElement {
  header: string;
  imageSrc: any;
  meta: string;
  path: string;
  disable?: boolean;
}
interface OwnProps {
  cards: CardElement[];
  onClick(card: CardElement): void;
}

const OptionCardsFC: React.FC<OwnProps> = ({ cards, onClick }) => {
  return (
    <CardsGrid>
      <OptionGroup centered itemsPerRow={3} stackable>
        {cards.map((card, index) => {
          return (
            <Option
              className={"optionCard"}
              key={"option_card_" + index}
              onClick={() => {
                if (!card.disable) {
                  onClick(card);
                } else {
                  alert("Coming Soon");
                }
              }}>
              <OptionContent className={"padded"}>
                <OptionImage src={card.imageSrc} size="tiny" />
                <Option.Header>{card.header}</Option.Header>
                <OptionMeta className={"noUppercase"}>{card.meta}</OptionMeta>
              </OptionContent>
              {card.disable && (
                <OptionContent className={"noPadded"} extra>
                  Coming Soon
                </OptionContent>
              )}
            </Option>
          );
        })}
      </OptionGroup>
    </CardsGrid>
  );
};

export const OptionCards = OptionCardsFC;
