import * as React from "react";
import { Grid, Button } from "semantic-ui-react";

import { VideoProjectCard } from "../Cells";
import * as DTO from "../../interfaces";

import "./styles.scss";

interface OwnProps {
  sectionName: string;
  videoProjects: DTO.PlayerVideoProjectResponse[];
  totalElements?: number;
}

type Props = OwnProps;

const PlayerSectionFC: React.FC<Props> = ({ videoProjects, sectionName, totalElements = 0 }) => {
  const [display, setDisplay] = React.useState<boolean>(true);

  const actionTitle = (): string => {
    if (!display) {
      return "Collapsed (" + totalElements + ")";
    }
    return `Show all (${totalElements})`;
  };

  return totalElements > 0 ? (
    <Grid columns={3} data-elm-id="videoProjectListElement" className={"videoProjectsPlayerGrid"}>
      <Grid.Row columns={3} className={display ? "" : "collapsedSection"}>
        <Grid.Column width={16} floated="left" className={"subSectionTitle"}>
          <label>{sectionName}</label>
        </Grid.Column>
        <Grid.Column width={4} floated="right" className={"noBorder"}>
          <Button
            data-elm-id={`playerSectionDisplayBtn`}
            basic
            content={actionTitle()}
            icon={!display ? "triangle right" : "triangle down"}
            className={"noBorder"}
            labelPosition={"right"}
            onClick={() => setDisplay(!display)}
          />
        </Grid.Column>
      </Grid.Row>
      {display && (
        <div key={sectionName} className={"ui container"}>
          {videoProjects.map(videoProject => {
            return (
              <VideoProjectCard
                key={"player_video_project_card_" + videoProject.videoProjectId}
                videoProject={videoProject}
              />
            );
          })}
        </div>
      )}
    </Grid>
  ) : (
    <></>
  );
};

export const PlayerVideoCardSection = React.memo(PlayerSectionFC);
