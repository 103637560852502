import { Header } from "semantic-ui-react";
import styled from "styled-components";

export const Title = styled(Header)`
  color: #474c58;
  font-weight: bold;
  font-size: 28px;
`;

export const EmailMessage = styled.span`
  max-width: 300px;
  text-align: center;
`;

export const SuccessMessage = styled.span`
  max-width: 340px;
  background-color: #f8f8fa;
  padding: 25px;
  text-align: center;
`;
