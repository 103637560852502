import { defineMessages } from "react-intl";

export enum PostComponentType {
  autopostNoAvailableDescription = "PostComponentType.autopostNoAvailableDescription",
  deliveryLabel = "PostComponentType.deliveryLabel",
  postNow = "PostComponentType.postNow",
  postScheduled = "PostComponentType.postScheduled",
  datePlaceholder = "PostComponentType.datePlaceholder",
  deliveryTypeLabel = "PostComponentType.deliveryTypeLabel",
  autoPost = "PostComponentType.autoPost",
  noAutoPost = "PostComponentType.noAutoPost",
  autoPostSocialNetworks = "PostComponentType.autoPostSocialNetworks",
  noVideoError = "PostComponentType.noVideoError",

  deliveryTimeDescription = "PostComponentType.deliveryTimeDescription",
  noMediaError = "PostComponentType.noMediaError",
  lengthMessageError = "PostComponentType.lengthMessageError",
  cancelBtn = "PostComponentType.cancel",
  pstZoneMessage = "PostComponentType.pstZoneMessage",
  customDurationLabel = "PostComponentType.customDurationLabel",
  startDateLabel = "PostComponentType.startDateLabel",
  endDateLabel = "PostComponentType.endDateLabel",
  socialNetworksDescription = "PostComponentType.socialNetworksDescription",
  deliveryModeDescription = "PostComponentType.deliveryModeDescription",
  deliveryTypeDescription = "PostComponentType.deliveryTypeDescription",
  timePlaceholder = "PostComponentType.campaignDurationDescription",
}

export const en = {
  [PostComponentType.deliveryLabel]: "Scheduling options",
  [PostComponentType.deliveryTypeLabel]: "Post Type",
  [PostComponentType.customDurationLabel]: "Players",

  [PostComponentType.deliveryModeDescription]: "Would you like to post this now or schedule it for later?",
  [PostComponentType.deliveryTypeDescription]: "How would you like this Prompt to be sent to your Players' accounts?",

  [PostComponentType.autopostNoAvailableDescription]:
    "Because this prompt contains multiple images, it cannot be auto posted",

  [PostComponentType.datePlaceholder]: "Select a date",
  [PostComponentType.timePlaceholder]: "Select a time",

  [PostComponentType.postNow]: "Post Now",
  [PostComponentType.postScheduled]: "Post Later",

  [PostComponentType.autoPost]: "Auto Post",
  [PostComponentType.noAutoPost]: "Push Notification",
  [PostComponentType.socialNetworksDescription]: "Select the social network(s) you would like to Auto Post to:",

  [PostComponentType.noVideoError]:
    "Because this Prompt does not include a video file, it cannot be posted to {socialNetwork}",
  [PostComponentType.noMediaError]:
    "Because this Prompt does not include a media file, it cannot be posted to {socialNetwork}",

  [PostComponentType.lengthMessageError]: "Because the caption is too long, it cannot be posted to {socialNetwork}.\n",

  [PostComponentType.deliveryTimeDescription]: "Delivery Time (8:00 AM PST)",

  [PostComponentType.cancelBtn]: "Cancel",

  [PostComponentType.autoPostSocialNetworks]:
    "How would you like the Prompts in this Campaign to be organized for posting?",
  [PostComponentType.startDateLabel]: "Start Date (optional)",
  [PostComponentType.endDateLabel]: "End Date (optional)",

  [PostComponentType.pstZoneMessage]: "All prompts will be sent using Pacific Time Zone",
};

export const descriptors = defineMessages({
  [PostComponentType.pstZoneMessage]: {
    id: PostComponentType.pstZoneMessage,
    defaultMessage: en[PostComponentType.pstZoneMessage],
    description: "-",
  },
  [PostComponentType.deliveryTimeDescription]: {
    id: PostComponentType.deliveryTimeDescription,
    defaultMessage: en[PostComponentType.deliveryTimeDescription],
    description: "-",
  },
  [PostComponentType.deliveryLabel]: {
    id: PostComponentType.deliveryLabel,
    defaultMessage: en[PostComponentType.deliveryLabel],
    description: "-",
  },
  [PostComponentType.autoPost]: {
    id: PostComponentType.autoPost,
    defaultMessage: en[PostComponentType.autoPost],
    description: "-",
  },
  [PostComponentType.noVideoError]: {
    id: PostComponentType.noVideoError,
    defaultMessage: en[PostComponentType.noVideoError],
    description: "-",
  },
  [PostComponentType.deliveryTypeLabel]: {
    id: PostComponentType.deliveryTypeLabel,
    defaultMessage: en[PostComponentType.deliveryTypeLabel],
    description: "-",
  },
  [PostComponentType.postNow]: {
    id: PostComponentType.postNow,
    defaultMessage: en[PostComponentType.postNow],
    description: "-",
  },
  [PostComponentType.datePlaceholder]: {
    id: PostComponentType.datePlaceholder,
    defaultMessage: en[PostComponentType.datePlaceholder],
    description: "-",
  },
  [PostComponentType.postScheduled]: {
    id: PostComponentType.postScheduled,
    defaultMessage: en[PostComponentType.postScheduled],
    description: "-",
  },
  [PostComponentType.autoPostSocialNetworks]: {
    id: PostComponentType.autoPostSocialNetworks,
    defaultMessage: en[PostComponentType.autoPostSocialNetworks],
    description: "-",
  },
  [PostComponentType.autopostNoAvailableDescription]: {
    id: PostComponentType.autopostNoAvailableDescription,
    defaultMessage: en[PostComponentType.autopostNoAvailableDescription],
    description: "-",
  },

  [PostComponentType.startDateLabel]: {
    id: PostComponentType.startDateLabel,
    defaultMessage: en[PostComponentType.startDateLabel],
    description: "-",
  },
  [PostComponentType.endDateLabel]: {
    id: PostComponentType.endDateLabel,
    defaultMessage: en[PostComponentType.endDateLabel],
    description: "-",
  },
  [PostComponentType.noAutoPost]: {
    id: PostComponentType.noAutoPost,
    defaultMessage: en[PostComponentType.noAutoPost],
    description: "-",
  },
  [PostComponentType.customDurationLabel]: {
    id: PostComponentType.customDurationLabel,
    defaultMessage: en[PostComponentType.customDurationLabel],
    description: "-",
  },
  [PostComponentType.deliveryModeDescription]: {
    id: PostComponentType.deliveryModeDescription,
    defaultMessage: en[PostComponentType.deliveryModeDescription],
    description: "-",
  },
  [PostComponentType.deliveryTypeDescription]: {
    id: PostComponentType.deliveryTypeDescription,
    defaultMessage: en[PostComponentType.deliveryTypeDescription],
    description: "-",
  },
  [PostComponentType.noMediaError]: {
    id: PostComponentType.noMediaError,
    defaultMessage: en[PostComponentType.noMediaError],
    description: "-",
  },
  [PostComponentType.lengthMessageError]: {
    id: PostComponentType.lengthMessageError,
    defaultMessage: en[PostComponentType.lengthMessageError],
    description: "-",
  },
  [PostComponentType.cancelBtn]: {
    id: PostComponentType.cancelBtn,
    defaultMessage: en[PostComponentType.cancelBtn],
    description: "-",
  },
  [PostComponentType.timePlaceholder]: {
    id: PostComponentType.timePlaceholder,
    defaultMessage: en[PostComponentType.timePlaceholder],
    description: "-",
  },
  [PostComponentType.socialNetworksDescription]: {
    id: PostComponentType.socialNetworksDescription,
    defaultMessage: en[PostComponentType.socialNetworksDescription],
    description: "-",
  },
});
