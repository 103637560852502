import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import TextareaAutosize from "react-textarea-autosize";
import { Form, Message, Loader, Grid, Radio, Header } from "semantic-ui-react";
import { get } from "lodash";

import { ConfigContextProvider } from "../../../contexts/appContexts";
import { BasicFormProps, Media, VideoProjectPlayerSummary } from "../../../interfaces";
import { useSocialNetworks } from "../../../reducers";

import { AppContext } from "../../../providers";
import { VideoProjectServices } from "../../../services";
import { VideoProjectUploader } from "../../VideoProjectUploader";
import defaultConfigProperties from "../../../defaultConfigProperties";

import { SocialNetworkIconButton } from "../../SocialNetworkIconButton";

import { RowBasicPadding, SocialGrid, SocialRowGrid, TitleLabel } from "../../../styling/baseStyle";
import { VideoProjectComponentType, descriptors } from "./descriptors";
import { PlayerDropDown } from "./components/PlayerDropDown";

import "./styles.scss";
import "../../../styling/datepicker.scss";
import { StylePreferencesForm } from "./components/StylePreferencesForm";
import { ProjectCaption } from "./components/ProjectCaption";
import { ProjectScheduler } from "./components/ProjectScheduler";

interface OwnProps extends BasicFormProps {
  /**
   * Handle attached file is uploaded
   */
  handleMediaUpdated: (newMedia: Media[]) => void;

  /**
   * Handle datePickers changes
   */
  handleSetFieldValue?: (field: string, value: any) => void;
  handleValidation: (isValid: boolean) => void;
  /**
   * Handle cancel action
   */
  handleCancel?: () => void;

  selectedPlayer?: boolean;
  modeEditable: boolean;
  /**
   * To Indicate if the form is disabled
   */
  disabled: boolean;
  /**
   * To show or hide the Player Custom Hashtags Toggle
   */
  showHashtagToggle: boolean;
}

type Props = OwnProps;
const dataElmId = "createVideoProject";
/**
 * Represents a Prompt content.
 */
const VideoProjectClass: React.FC<Props> = ({
  handleSetFieldValue,
  handleMediaUpdated,
  handleValidation,

  messages,
  isSubmitting = false,
  selectedPlayer = false,
  values,
  disabled,
  showHashtagToggle,
}) => {
  const { userContext } = React.useContext(AppContext);
  const { sns } = useSocialNetworks();
  const { formatMessage } = useIntl();

  const coachId = get(userContext, "coach.id", "");
  const { socialNetworkMessages, preferredPostDate } = values;

  const [contentWarnings, setContentWarnings] = useState<string[]>([]);
  const [contentErrors, setContentErrors] = useState<string[]>([]);

  const errors = useMemo(() => {
    return contentErrors.concat(messages?.error || []);
  }, [contentErrors, messages?.error]);

  const warnings = useMemo(() => {
    return contentWarnings.concat(messages?.warn || []);
  }, [contentWarnings, messages?.warn]);

  useEffect(() => {
    handleValidation(errors.length === 0);
  }, [errors]);

  // Player section
  const [playerSummaries, setPlayerSummaries] = React.useState<VideoProjectPlayerSummary[]>([]);

  React.useEffect(() => {
    VideoProjectServices.getPlayerSummaries(coachId).then(players => {
      setPlayerSummaries(players);
    });
  }, []);

  const playerSummary = useMemo(() => {
    if (values.playerId) {
      return playerSummaries.find(summary => summary.playerId.toString() === values.playerId.toString());
    } else {
      return null;
    }
  }, [playerSummaries, values.playerId]);

  const playerList = useMemo(() => {
    return playerSummaries.map(player => ({
      key: player.playerId,
      text: player.playerName,
      value: player.playerId,
    }));
  }, [playerSummaries]);

  const updateCaption = (value: string) => {
    handleSetFieldValue!("withMessage", value);
    handleSetFieldValue!("messageToPost", value);
  };

  const updateHashtags = (value: string) => {
    handleSetFieldValue!("hashtags", value);
  };

  React.useEffect(() => {
    return () => {
      window.removeEventListener("loadedmetadata", () => {
        // Nothing
      });
    };
  }, []);

  const settingsSection = (): JSX.Element => {
    const { allowShortLink, allowHashtags, allowComplianceDisclaimer } = values;

    const settings: any[] = [];

    if (showHashtagToggle) {
      settings.push({
        label: formatMessage({ ...descriptors[VideoProjectComponentType.allowHashtags] }),
        checked: allowHashtags,
        id: "allowHashtags",
      });
    }

    settings.push({
      label: formatMessage({ ...descriptors[VideoProjectComponentType.allowShortLink] }),
      checked: allowShortLink,
      id: "allowShortLink",
    });

    settings.push({
      label: formatMessage({ ...descriptors[VideoProjectComponentType.allowComplianceDisclaimer] }),
      checked: allowComplianceDisclaimer,
      id: "allowComplianceDisclaimer",
    });

    return (
      <React.Fragment>
        <Grid padded>
          <Grid.Column floated="right" width={10}>
            <Form.Group grouped key={"videoProjectPermissions"} className={"floatedRight"}>
              {settings.map(appSetting => {
                return (
                  <div key={"videoProjectPermissions" + appSetting.id}>
                    <Form.Group inline key={appSetting.id} className={"floatedRight"}>
                      <label className="small">{appSetting.label}</label>
                      <Radio
                        toggle
                        className={"settingsLevelLabel"}
                        onChange={(e: any, data: any) => {
                          handleSetFieldValue!(data.name, data.checked);
                        }}
                        name={appSetting.id}
                        checked={appSetting.checked}
                      />
                    </Form.Group>
                  </div>
                );
              })}
            </Form.Group>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  };

  /**
   * Return a custom Radio button with Black Style
   * @param id radio identifier
   * @param radioButtonDisabled if the radio is disabled
   * @param checked if the radio is on
   * @param onChange onChange handler
   * @param label Radio Label key on the descriptor file
   * @returns JSX.Element (Radio element)
   */
  const customRadioButton = (
    id: string,
    radioButtonDisabled: boolean | undefined,
    checked: boolean,
    onChange: (e: any, data: any) => void,
    label: string
  ): JSX.Element => {
    return (
      <Radio
        data-elm-id={`${dataElmId}${id}RadioBtn`}
        className={"blackRadio secondary rounded"}
        id={id}
        name={id}
        onChange={onChange}
        checked={checked}
        disabled={radioButtonDisabled}
        label={formatMessage({ ...descriptors[VideoProjectComponentType[label]] })}
      />
    );
  };

  const autoPostRadio = (): JSX.Element => {
    return customRadioButton(
      "isAutoPost",
      false,
      values.isAutoPost,
      (e: any, data: any) => {
        handleSetFieldValue!(`${data.id}`, data.checked);
      },
      "autoPost"
    );
  };

  /*
   * Auto Post or Push Notification
   */
  const deliveryType = () => {
    return (
      <SocialGrid.Row className={"xbigPaddingTop"}>
        <SocialGrid.Column width={10}>
          <Grid.Row className={"noPadding"}>
            <label>{formatMessage({ ...descriptors[VideoProjectComponentType.postSectionTitle] })}</label>
          </Grid.Row>

          <SocialRowGrid className={"bigPaddingTop"} leftpadding={15} key={"post_row_auto_post"}>
            {autoPostRadio()}
            {values.isAutoPost && (
              <SocialGrid className={"autoPostSection"}>
                <ProjectScheduler
                  deliveryDate={preferredPostDate}
                  onChange={(date: Date) => {
                    handleSetFieldValue!("preferredPostDate", date.toISOString());
                  }}
                />
                <RowBasicPadding className={"socialIconsSection"}>
                  <SocialGrid className={"socialSection"} divided={"vertically"} columns={6}>
                    {sns.map((sn, index) => {
                      return (
                        <SocialNetworkIconButton
                          disabled={disabled}
                          withPadding={false}
                          size={"noMargin"}
                          key={"video_project_post_sn_icon_" + index}
                          active={socialNetworkMessages ? socialNetworkMessages[sn] : false}
                          networkId={sn}
                          customIconClassname={"socialNetworkIconCentered"}
                          onClickHandler={checked => {
                            const snUpdated = { ...socialNetworkMessages, [sn]: checked };
                            const checkedNumber = Object.values(snUpdated).filter(Boolean).length;
                            handleSetFieldValue!("socialNetworkMessages", snUpdated);
                            handleSetFieldValue!("socialNetworksCounter", checkedNumber);
                          }}
                        />
                      );
                    })}
                  </SocialGrid>
                </RowBasicPadding>
              </SocialGrid>
            )}
          </SocialRowGrid>

          <SocialRowGrid key={"post_row_push_notification"}>
            {customRadioButton(
              "isPushPost",
              false,
              !values.isAutoPost,
              () => {
                handleSetFieldValue!("isAutoPost", false);
              },
              "pushNotification"
            )}
          </SocialRowGrid>
        </SocialGrid.Column>
      </SocialGrid.Row>
    );
  };

  const specialRequestSection = () => {
    return (
      <Form.Field>
        <div className="smallPaddingBottom xbigPaddingTop">
          <label>{formatMessage({ ...descriptors[VideoProjectComponentType.specialRequests] })}</label>
        </div>
        <TextareaAutosize
          data-elm-id={dataElmId + "SpecialRequestInput"}
          id="initialMessages"
          name="initialMessages"
          className="captionField"
          placeholder={formatMessage({ ...descriptors[VideoProjectComponentType.specialRequestsPlaceholder] })}
          rows={3}
          disabled={disabled}
          value={
            values.initialMessages && values.initialMessages.length > 0 ? values.initialMessages[0].content : undefined
          }
          onChange={data => {
            handleSetFieldValue!("initialMessages", [{ content: data.currentTarget.value }]);
          }}
        />
      </Form.Field>
    );
  };

  const preferencesSection = () => {
    return (
      <StylePreferencesForm
        playerId={values.playerId}
        updatePrefs={prefs => {
          handleSetFieldValue!("preferences", prefs);
        }}
      />
    );
  };

  return (
    <>
      <Form
        size="large"
        error={errors.length > 0}
        warning={warnings.length > 0}
        className={"videoProjectFormContainer"}
        disabled={disabled}
      >
        {isSubmitting && <Loader active size="large" />}

        <div key={"videoProjectFormDiv"} className={disabled ? "newProjectForm disabledForm" : "newProjectForm"}>
          {errors.length > 0 && <Message error list={errors} />}

          {warnings.length > 0 && <Message warning list={warnings} />}

          <Grid columns={2}>
            <Grid.Column>
              {/* IF PLAYER IS NOT SELECTED INCLUDE PLAYER DROPDOWN */}
              <div className={"playerSection"}>
                <label>{formatMessage({ ...descriptors[VideoProjectComponentType.playerSection] })}</label>
                {!selectedPlayer && playerList && playerList?.length > 0 && (
                  <PlayerDropDown
                    options={playerList}
                    placeholder={"Player"}
                    value={values.playerId ? Number(values.playerId) : null}
                    onChangeHandler={data => {
                      handleSetFieldValue!("playerId", data);
                    }}
                  />
                )}
                {playerSummary && (
                  <div>
                    {selectedPlayer && (
                      <Header as="h4" textAlign="left" className="noMargin noPadding">
                        {playerSummary.playerName}
                      </Header>
                    )}
                    <div className={"playerSummary"}>{playerSummary.playerEmail}</div>
                    <div className={"playerSummary"}>
                      Video Credits Remaining:{" "}
                      {playerSummary.unlimitedCredits ? "Unlimited" : playerSummary.creditsRemaining}
                    </div>
                  </div>
                )}
              </div>
              {/* Files section */}
              <div className={"attachVideosSection"}>
                <label>{formatMessage({ ...descriptors[VideoProjectComponentType.attachDesc] })}</label>

                <ConfigContextProvider value={defaultConfigProperties}>
                  <VideoProjectUploader
                    handleMediaUpdated={handleMediaUpdated}
                    originalMedia={values.originalMediaGroup?.media || []}
                  />
                </ConfigContextProvider>
              </div>

              {/* CAPTION */}
              <ProjectCaption
                coachId={coachId}
                playerId={values.playerId}
                includePlayerHashtags={values.allowHashtags}
                role={"COACH"}
                captionValue={values.messageToPost}
                disabled={disabled}
                onUpdateCaption={v => updateCaption(v)}
                onUpdateHashtags={v => updateHashtags(v)}
                onUpdateErrors={setContentErrors}
                onUpdateWarnings={setContentWarnings}
              />

              {/* Settings */}
              {settingsSection()}

              {/* Post Schedule */}
              {deliveryType()}
            </Grid.Column>
            <Grid.Column className={"rightCol"}>
              {preferencesSection()}
              {specialRequestSection()}
            </Grid.Column>
          </Grid>
        </div>
      </Form>
    </>
  );
};

export const VideoProjectComponent = VideoProjectClass;
