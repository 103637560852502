import * as React from "react";
import { Image, Card, SemanticICONS, Divider, Grid, Icon } from "semantic-ui-react";

import "./styles.scss";
import { kFormatter } from "../../utils";

interface Props {
  title: string;
  subtitle?: string;
  icon?: SemanticICONS;
  imageName: string;
  valueSymbol?: string;
  value?: number;
  description?: number;
  descType?: "positive" | "negative" | "";
  additionalValue?: string | number;
  additionalDesc?: string | number;
}

const PlayerStatsCardFC: React.FC<Props> = ({
  imageName,
  title,
  icon,
  subtitle = "subtitle",
  value,
  valueSymbol = "",
  description,
  descType = "",
  additionalValue,
  additionalDesc,
}) => {
  const simple = (): JSX.Element => {
    return (
      <Card className={"playerStatCard noClickeable"} key={title + description + "simple"}>
        <Card.Content>
          <Card.Meta className={"statsCardHeader"}>
            <Grid className={"statsCardHeader"}>
              <Grid.Column width={4} className="iconContainer">
                {icon ? <Icon tiny={"true"} name={icon} /> : <Image src={imageName} size="tiny" />}
              </Grid.Column>
              <Grid.Column width={10} className="infoContainer">
                <p>{title}</p>
                <span>{subtitle}</span>
              </Grid.Column>
            </Grid>
            {/* <p>
              {icon ? <Icon tiny={"true"} name={icon} /> : <Image src={imageName} size="tiny" />}
              {title}
            </p>
            <span>{subtitle}</span> */}
          </Card.Meta>
          <Grid className={"statsCardFooter"}>
            <Grid.Column width={12} className="footerElement">
              <Card.Description className={"statsInfo"} content={value && value > 9999 ? kFormatter(value) : value} />
            </Grid.Column>
            <Grid.Column width={4} className="footerElement">
              {description && description != 0 && description < 999 && description > -999 ? (
                <Card.Meta textAlign="right" className={descType + " descriptionValue"}>
                  {description + "%"}
                </Card.Meta>
              ) : (
                <></>
              )}
            </Grid.Column>
          </Grid>
        </Card.Content>
      </Card>
    );
  };

  const doubleCard = (): JSX.Element => {
    return (
      <Card className={"playerStatCard"} key={title + description + "double"}>
        <Card.Content>
          <Card.Meta className={"statsCardHeader"}>
            <p>
              {icon ? <Icon tiny name="heart outline" /> : <Image src={imageName} size="tiny" />}
              {title}
            </p>
            <span>{subtitle + "additionalValue"}</span>
          </Card.Meta>
          <Card.Description className={"statsInfo"}>
            <Grid className="multipleValues" divided={"vertically"}>
              <Grid.Column floated="right" width={7}>
                {value}
              </Grid.Column>
              <Grid.Column width={1}>
                <div className={"verticalDivider"} />
              </Grid.Column>
              <Grid.Column className={"noPadding"} width={6}>
                {additionalValue}
              </Grid.Column>
            </Grid>
          </Card.Description>

          <Card.Meta>
            <Grid stretched className={"multipleValues"}>
              {description && (
                <Grid.Column className={"descLabel"} floated="left" width={7}>
                  {description}
                </Grid.Column>
              )}
              <Grid.Column className={"descLabel"} floated="right" width={7}>
                {additionalDesc}
              </Grid.Column>
            </Grid>
          </Card.Meta>
        </Card.Content>
      </Card>
    );
  };

  return additionalValue ? doubleCard() : simple();
};

export const PlayerStatsCard = PlayerStatsCardFC;
