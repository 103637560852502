import { UpdateUserPayload, Profile, Account, CoachStatuses } from "../dtos";
import { SCAccount } from "../../providers";

export interface BaseCoach {
  id: string;
  companyName?: string;
  title?: string;
  contactEmail?: string;
  type?: "BASIC" | "ENTERPRISE";
  contactPhone?: string;
  coachBankAccount?: CoachBankAccount;
  coachStatus?: CoachStatuses;
}

export enum PermissionLevel {
  NONE = "NONE",
  READ = "READ",
  WRITE = "WRITE",
}

export enum WebComponents {
  MARKETING = "MARKETING",
  PRICE_PLAN = "PRICE_PLAN",
  LIBRARY = "LIBRARY",
  CAMPAIGNS = "CAMPAIGNS",
  PLAYERS = "PLAYERS",
  COMPLIANCE = "COMPLIANCE",
  VIDEO_CATALYST = "VIDEO_CATALYST",
  MANAGE_SCRIPT_LIBRARY = "MANAGE_SCRIPT_LIBRARY",
  ADMIN = "ADMIN",
  ALERTS = "ALERTS",
  DIGEST_EMAIL = "DIGEST_EMAIL",
  APP_SETTINGS = "APP_SETTINGS",
}

export interface CoachPermission {
  componentId: string;
  level: PermissionLevel;
}

export interface CoachComponent {
  componentId: string;
  name: string;
}

export interface CoachBankAccount {
  bankAccountId?: number;
  ownersName?: string;
  bankName?: string;
  accountType?: string;
  accountNumber?: string;
  routingNumber?: string;
}

export interface BaseUpdateCoach {
  coach: BaseCoach;
  profile: Profile;
}

export interface ResponseUpdateCoach {
  bank?: CoachBankAccount;
  profile: UpdateUserPayload;
  company: BaseCoach;
  contactEmail?: string;
  contactPhone?: string;
  user?: Account;
}

export interface SubCoach {
  user: SCAccount;
  profile: Profile;
  permissions?: CoachPermission[];
}
