import * as React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { Form, Image } from "semantic-ui-react";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";

import { getImageAsBase64 } from "../../../../utils";
import { FloatedCard } from "../../../../containers";
import { BasicFormProps } from "../../../../interfaces";
import { Svg } from "../../../../assets";

import { FirstPictureType, descriptors } from "./descriptors";

import "./styles.scss";
import { BubbleMobileDetails } from "../BubbleMobileDetails";
import { Chat, CoachBasic } from "../../../../interfaces";

interface OwnProps extends BasicFormProps {
  chatMessages: Chat[];
  coachInfo?: CoachBasic;

  onChangeImage: (img: File) => void;
  handleSetFieldValue?: (field: string, value: any) => void;

  handleSkip: () => void;
}

type Props = OwnProps & RouteComponentProps;

/**
 * Represents a Player set picture screen.
 */
const FirstLoginPictureClass: React.FC<Props> = ({
  chatMessages,
  coachInfo,

  messages,
  values,
  handleSubmit,
  handleSkip,
  isSubmitting = false,
  onChangeImage,
}) => {
  const { formatMessage } = useIntl();
  const [imageFile, setImageFile] = React.useState<string | undefined>(values.photoUrl || Svg.UserBorder);
  const [userWithPicture, setUserWithPicture] = React.useState<boolean>(!!values.photoUrl);

  const content = (
    <React.Fragment>
      <>
        {coachInfo && <BubbleMobileDetails messages={chatMessages} coachInfo={coachInfo} />}
        <div className={"firstPictureUploadPictureContainer"}>
          <Image src={imageFile} className={!userWithPicture ? "defaultPicture" : "profilePicture"} />

          <ImgCrop
            onModalOk={file => {
              getImageAsBase64(file, (base64Image: any) => {
                setImageFile(base64Image);
                onChangeImage(file as File);
                setUserWithPicture(true);
              });
            }}
          >
            <Upload
              action={(file): string => {
                // getImageAsBase64(file, (base64Image: any) => {
                //   setImageFile(base64Image);
                //   onChangeImage(file);
                //   setUserWithPicture(true);
                // });
                return Svg.UserBorder;
              }}
              showUploadList={false}
              listType="picture"
              className="avatar-uploader"
            >
              <div className="cameraIconContainer">
                <Image src={Svg.Camera} className="cameraIcon" />
              </div>
            </Upload>
          </ImgCrop>
        </div>
      </>

      <Form.Group widths="equal" className={"buttonContainer"}>
        <Form.Button
          size="large"
          type="submit"
          disabled={isSubmitting}
          onClick={() => {
            userWithPicture ? handleSubmit!() : handleSkip();
          }}
          className={"signUp"}
        >
          <FormattedMessage {...descriptors[userWithPicture ? FirstPictureType.submit : FirstPictureType.skip]} />
        </Form.Button>
      </Form.Group>
    </React.Fragment>
  );

  return (
    <FloatedCard
      subtitle={formatMessage({ ...descriptors[FirstPictureType.subtitle] })}
      content={content}
      messages={messages}
      isSubmitting={isSubmitting}
      size={"bigCentered"}
    />
  );
};

export const FirstLoginPicture = withRouter(FirstLoginPictureClass);
