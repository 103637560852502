import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { get } from "lodash";

import { SideBar, MarketingPageForm } from "../../../components";
import { AppContext } from "../../../providers";
import { coachWithWritePermission } from "../../../utils";
import { WebComponents } from "../../../interfaces";

import { WhiteContainer, ContentGrid } from "../../../styling/baseStyle";

const MarketingSiteFC: React.FC<RouteComponentProps> = ({ history }) => {
  const { userContext } = React.useContext(AppContext);
  const firstName = get(userContext, "profile.firstName", "");
  const lastName = get(userContext, "profile.lastName", "");
  const coachId = get(userContext, "coach.id", "");
  const withWritePermission = coachWithWritePermission(WebComponents.MARKETING, userContext);

  return (
    <WhiteContainer>
      <SideBar history={history} />

      <ContentGrid className={!withWritePermission ? "disabledForm socialContent" : "socialContent"}>
        <MarketingPageForm firstName={firstName} lastName={lastName} coachId={coachId} />
      </ContentGrid>
    </WhiteContainer>
  );
};

export const MarketingSite = withRouter(MarketingSiteFC);
