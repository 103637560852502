import * as React from "react";
import { useIntl } from "react-intl";
import { Image, Table, Message, Loader, Checkbox } from "semantic-ui-react";
import { filter } from "lodash";

import * as DTO from "../../../interfaces/dtos";
import { descriptors, PlayersType } from "./descriptors";
import { Svg } from "../../../assets/svg";
import { TitleSubtitleCell } from "../../Cells";
import { userFullName } from "../../../utils";
import { playerListReducer } from "./reducer";

import "./styles.scss";

interface OwnProps {
  players: DTO.PlayerWithBranch[];
  selectedPlayers: number[];

  showState?: boolean;
  onChangeSelectedPlayer: (playerIds: number[], checked: boolean) => void;
}

type Props = OwnProps; 

const PlayerListFC: React.FC<Props> = ({
  players,
  selectedPlayers = [],
  showState = false,
  onChangeSelectedPlayer,
}) => {
  const { formatMessage } = useIntl();
  const [state, dispatch] = React.useReducer(playerListReducer, {
    column: null,
    data: players,
    direction: null,
  });

  React.useEffect(() => {
    dispatch({ type: "REFRESH_DATA", column: null, data: players });
  }, [players]);

  const { column, data, direction } = state;

  const hasRegions = filter(players, "region").length > 0;
  const hasBranches = filter(players, "branch").length > 0;
  const hasStates = filter(players, "state").length > 0;

  const contentSimple = (player: DTO.PlayerWithBranch): JSX.Element => {
    const {
      email,
      photoUrl,
      info,
      branch,
      state: playerState,
      region,
    } = player;
    return (
      <Table.Row key={"player_branch_" + info.playerId}>
        <Table.Cell data-elm-id={`playerBranchListCellCheckboxCell`}>
          <Checkbox
            data-elm-id={`playerBranchListCellCheckboxBtn`}
            checked={selectedPlayers.includes(info!.playerId!)}
            onChange={(e, checkData) => {
              onChangeSelectedPlayer([info!.playerId!], checkData!.checked!);
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Image
            circular
            size="mini"
            src={photoUrl ? photoUrl : Svg.noImageuser}
          />
        </Table.Cell>
        <TitleSubtitleCell title={email} subtitle={userFullName(info)} />
        {hasBranches && (
          <Table.Cell data-elm-id={`playerBranchListCellBranchCell`}>
            {branch && branch}
          </Table.Cell>
        )}
        {hasRegions && (
          <Table.Cell data-elm-id={`playerBranchListCellRegionCell`}>
            {region && region}
          </Table.Cell>
        )}

        {showState && hasStates && (
          <Table.Cell data-elm-id={`playerBranchListCellStateCell`}>
            {playerState && playerState}
          </Table.Cell>
        )}
      </Table.Row>
    );
  };

  const allPlayersSelected = data.reduce(
    (accumulator: boolean, playerR: DTO.PlayerWithBranch) =>
      selectedPlayers.includes(playerR.info!.playerId!) &&
      accumulator &&
      selectedPlayers.length > 0 &&
      accumulator,
    true
  );

  return (
    <div className="branchPlayerList">
      {data && data.length > 0 ? (
        <Table sortable striped compact="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                data-elm-id={`playerBranchListHeaderCheckbox`}
                width={1}
              >
                <Checkbox
                  data-elm-id={`playerBranchListHeaderCheckboxBtn`}
                  checked={allPlayersSelected}
                  onChange={(e, checkData) => {
                    const allPlayersIds = data.map(
                      (playerR: DTO.PlayerWithBranch) => playerR.info!.playerId!
                    );
                    onChangeSelectedPlayer(allPlayersIds, checkData!.checked!);
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell width={1} />
              <Table.HeaderCell>
                {formatMessage({ ...descriptors[PlayersType.nameHeader] })}
              </Table.HeaderCell>
              {hasBranches && (
                <Table.HeaderCell
                  data-elm-id={`playerBranchListHeaderBranchSortBtn`}
                  width={2}
                  sorted={column === "branch" ? direction : null}
                  onClick={() =>
                    dispatch({ type: "CHANGE_SORT", column: "branch" })
                  }
                >
                  Branch
                </Table.HeaderCell>
              )}
              {hasRegions && (
                <Table.HeaderCell
                  data-elm-id={`playerBranchListHeaderRegionSortBtn`}
                  width={2}
                  sorted={column === "region" ? direction : null}
                  onClick={() =>
                    dispatch({ type: "CHANGE_SORT", column: "region" })
                  }
                >
                  Region
                </Table.HeaderCell>
              )}
              {showState && hasStates && (
                <Table.HeaderCell
                  data-elm-id={`playerBranchListHeaderStateSortBtn`}
                  width={2}
                  sorted={column === "state" ? direction : null}
                  onClick={() =>
                    dispatch({ type: "CHANGE_SORT", column: "state" })
                  }
                >
                  State
                </Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((playerR: DTO.PlayerWithBranch) =>
              contentSimple(playerR)
            )}
          </Table.Body>
        </Table>
      ) : (
        <Message
          data-elm-id={`playerBranchListNoResultMessage`}
          icon="search"
          header="No results"
          content={formatMessage({
            ...descriptors[PlayersType.noResultsMessage],
          })}
          size="large"
        />
      )}
    </div>
  );
};

export const PlayersListWithBranch = PlayerListFC;
