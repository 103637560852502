import * as React from "react";
import { Dropdown, Menu, DropdownItemProps, DropdownProps } from "semantic-ui-react";

import "./styles.scss";

interface Props {
  options: DropdownItemProps[];
  placeholder: string;
  multiple?: boolean;
  clearable?: boolean;
  defaultValue?: any[];
  onChangeHandler: (data: any) => void;
}

const CustomDropDownFC: React.FC<Props> = ({
  options,
  placeholder,
  multiple = true,
  clearable = true,
  defaultValue,
  onChangeHandler,
}) => {
  const getMultipleValueInput = (data: DropdownProps): string[] => {
    return data.value !== undefined ? (data.value as string[]) : [];
  };

  const onChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps): void => {
    if (multiple) {
      onChangeHandler(getMultipleValueInput(data));
    } else {
      onChangeHandler(`${data.value}`);
    }
  };
  const props = defaultValue
    ? {
        value: defaultValue,
      }
    : {};
  return (
    <Menu.Item>
      <Dropdown
        {...props}
        search
        fluid
        selection
        placeholder={placeholder}
        className="filterByInput"
        multiple={multiple}
        onChange={onChange}
        clearable={clearable}
        options={options}
      />
    </Menu.Item>
  );
};

export const CustomDropDownComponent = CustomDropDownFC;
