import { defineMessages } from "react-intl";

export enum RecordPageType {
  controlsTitle = "RecordPageType.controlsTitle",
  scriptTitle = "RecordPageType.scriptTitle",
  liveSectionTitle = "RecordPageType.liveSectionTitle",

  textSubtitle = "RecordPageType.textSubtitle",
  autoScrollSubtitle = "RecordPageType.autoScrollSubtitle",
  cameraSubtitle = "RecordPageType.cameraSubtitle",
  microphoneSubtitle = "RecordPageType.microphoneSubtitle",
  permissionsError = "RecordPageType.permissionsError",
  counterControl = "RecordPageType.counterControl",

  backBtn = "RecordPageType.backBtn",
  resumeBtn = "RecordPageType.resumeBtn",
  actionBtn = "RecordPageType.actionBtn",
  startOverBtn = "RecordPageType.startOverBtn",
  doneBtn = "RecordPageType.doneBtn",
  confirmationMessage = "RecordPageType.confirmationMessage",
}

export const en = {
  [RecordPageType.controlsTitle]: "Controls",
  [RecordPageType.scriptTitle]: "Script",
  [RecordPageType.liveSectionTitle]: "Live Video",

  [RecordPageType.textSubtitle]: "Text Size",
  [RecordPageType.autoScrollSubtitle]: "Auto-scroll Speed",
  [RecordPageType.cameraSubtitle]: "Camera",
  [RecordPageType.microphoneSubtitle]: "Microphone",
  [RecordPageType.permissionsError]: "Please allow camera and microphone access to start recording",
  [RecordPageType.counterControl]: "{status} - {time}",

  [RecordPageType.backBtn]: "Back",
  [RecordPageType.resumeBtn]: "Resume Recording",
  [RecordPageType.actionBtn]: "{status} Recording",
  [RecordPageType.startOverBtn]: "Start over",
  [RecordPageType.doneBtn]: "Done Recording!",

  [RecordPageType.confirmationMessage]: "Are you sure you want to discard this recording?",
};

export const descriptors = defineMessages({
  [RecordPageType.controlsTitle]: {
    id: RecordPageType.controlsTitle,
    defaultMessage: en[RecordPageType.controlsTitle],
    description: "-",
  },
  [RecordPageType.scriptTitle]: {
    id: RecordPageType.scriptTitle,
    defaultMessage: en[RecordPageType.scriptTitle],
    description: "-",
  },
  [RecordPageType.liveSectionTitle]: {
    id: RecordPageType.liveSectionTitle,
    defaultMessage: en[RecordPageType.liveSectionTitle],
    description: "-",
  },
  [RecordPageType.textSubtitle]: {
    id: RecordPageType.textSubtitle,
    defaultMessage: en[RecordPageType.textSubtitle],
    description: "-",
  },
  [RecordPageType.autoScrollSubtitle]: {
    id: RecordPageType.autoScrollSubtitle,
    defaultMessage: en[RecordPageType.autoScrollSubtitle],
    description: "-",
  },
  [RecordPageType.cameraSubtitle]: {
    id: RecordPageType.cameraSubtitle,
    defaultMessage: en[RecordPageType.cameraSubtitle],
    description: "-",
  },
  [RecordPageType.microphoneSubtitle]: {
    id: RecordPageType.microphoneSubtitle,
    defaultMessage: en[RecordPageType.microphoneSubtitle],
    description: "-",
  },
  [RecordPageType.permissionsError]: {
    id: RecordPageType.permissionsError,
    defaultMessage: en[RecordPageType.permissionsError],
    description: "-",
  },
  [RecordPageType.counterControl]: {
    id: RecordPageType.counterControl,
    defaultMessage: en[RecordPageType.counterControl],
    description: "-",
  },
  [RecordPageType.backBtn]: {
    id: RecordPageType.backBtn,
    defaultMessage: en[RecordPageType.backBtn],
    description: "-",
  },
  [RecordPageType.resumeBtn]: {
    id: RecordPageType.resumeBtn,
    defaultMessage: en[RecordPageType.resumeBtn],
    description: "-",
  },
  [RecordPageType.actionBtn]: {
    id: RecordPageType.actionBtn,
    defaultMessage: en[RecordPageType.actionBtn],
    description: "-",
  },
  [RecordPageType.startOverBtn]: {
    id: RecordPageType.startOverBtn,
    defaultMessage: en[RecordPageType.startOverBtn],
    description: "-",
  },
  [RecordPageType.doneBtn]: {
    id: RecordPageType.doneBtn,
    defaultMessage: en[RecordPageType.doneBtn],
    description: "-",
  },
  [RecordPageType.confirmationMessage]: {
    id: RecordPageType.confirmationMessage,
    defaultMessage: en[RecordPageType.confirmationMessage],
    description: "-",
  },
});
