import * as React from "react";
import { Header, Grid, Button, SemanticFLOATS } from "semantic-ui-react";
import { useIntl } from "react-intl";

import { TitleSectionType, descriptors } from "./descriptors";
import { isNil } from "lodash";
import { SemanticWIDTHS } from "semantic-ui-react/dist/commonjs/generic";

import "./styles.scss";

interface Props {
  title?: string;

  disableButtons?: boolean;

  customNextLabel?: string;
  customLinkLabel?: string;

  showNextOption?: boolean;

  /**
   * Loading options
   */
  showLoading?: boolean;
  isLoading?: boolean;
  disableDuringLoading?: boolean;
  absolute?: boolean;
  floatedRight?: boolean;

  /**
   * Size of grid - title section
   */
  titleSize?: SemanticWIDTHS;
  /**
   * Size of grid - link section
   */
  buttonsSize?: SemanticWIDTHS;

  /**
   * Size of grid - link section
   */
  nextButtonSize?: SemanticWIDTHS;

  /**
   * Custom content for the link section
   */
  customLinkSection?: JSX.Element;

  /**
   * Custom content for the next section
   */
  customNextSection?: JSX.Element;

  handleLoading?(loading: boolean): void;
  handleNextAction?(): void;
  handleSkipAction?(): void;
}

const MenuTitleSectionFC: React.FC<Props> = ({
  title,
  customNextLabel,
  customLinkLabel,
  disableButtons,
  customLinkSection,
  customNextSection,
  absolute = false,
  floatedRight = false,
  showLoading = true,
  isLoading = false,
  disableDuringLoading = false,
  showNextOption = true,
  titleSize = 9,
  buttonsSize = 7,
  nextButtonSize = 5,
  handleLoading,
  handleNextAction,
}) => {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = React.useState<boolean>(false);
  const nextButtonLabel = customNextLabel
    ? customNextLabel
    : formatMessage({ ...descriptors[TitleSectionType["saveBtn"]] });
  const skipButtonLabel = customLinkLabel
    ? customLinkLabel
    : formatMessage({ ...descriptors[TitleSectionType.skipBtn] });

  React.useEffect(() => {
    if (handleLoading) {
      handleLoading(loading);
    }
  }, [loading]);

  const onClickNext = async () => {
    setLoading(true);

    if (handleNextAction) {
      handleNextAction();
    }

    setLoading(false);
  };

  const nextSection = (): JSX.Element => {
    const customSection = !isNil(customNextSection);

    const props =
      isNil(customLinkSection) || floatedRight
        ? {
            floated: "right" as SemanticFLOATS,
          }
        : {};

    const content = (
      <Grid.Column {...props} floated={"right"} width={nextButtonSize}>
        <Button
          floated="right"
          disabled={disableButtons || (disableDuringLoading && isLoading)}
          fluid
          onClick={onClickNext}
          className={"primary rounded"}
          data-elm-id={`menuTitleSection${nextButtonLabel.replace(" ", "")}Btn`}
          loading={showLoading && (loading || isLoading)}
        >
          {nextButtonLabel}
        </Button>
      </Grid.Column>
    );

    return customSection ? customNextSection! : content;
  };

  return (
    <Grid columns={1} className={"menuTitleSectionContainer"}>
      <Grid.Row className={absolute ? "menuPositionAbsolute" : ""}>
        {title && (
          <Grid.Column width={titleSize}>
            <Header as="h2">{title}</Header>
          </Grid.Column>
        )}
        <Grid.Column width={buttonsSize} floated="right">
          <Grid>
            {!isNil(customLinkSection) && customLinkSection}
            {showNextOption && nextSection()}
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const MenuTitleSection = MenuTitleSectionFC;
