import * as React from "react";
import{ useIntl } from "react-intl";
import { Table, Message, Loader } from "semantic-ui-react";

import * as DTO from "../../interfaces/dtos";
import { descriptors, CoachesType } from "./descriptors";
import { TitleSubtitleCell } from "../Cells";
import { userFullName, printOnlyDate, URLS } from "../../utils";

import "./styles.scss";

interface OwnProps {
  coaches?: DTO.SubCoach[];
  loading: boolean;
  history: any;
}

type Props = OwnProps 

const CoachesFC: React.FC<Props> = ({ coaches, loading = true, history}) => {
  const { formatMessage } = useIntl();
  const content = ({ profile, user: account, permissions }: DTO.SubCoach): JSX.Element => {
    const { email, latestLoginDate, userId, createdDate, status } = account;
    const subCoachUrl = URLS.coach.editSubCoach.replace(":userId", `${userId}`);

    let permissionsObject = {};
    permissions?.map(p => {
      permissionsObject = {
        ...permissionsObject,
        [p.componentId]: p.level,
      };
    });

    const goToSubCoachDetail = () => {
      const locationValues = {
        firstName: profile.firstName,
        lastName: profile.lastName,
        email,
        permissions: permissionsObject,
        createdDate: printOnlyDate(createdDate!),
        inactive: status === "SUSPENDED",
        lastestLoginDate: printOnlyDate(latestLoginDate!),
      };

      history.push(subCoachUrl, locationValues);
    };
    return (
      <Table.Row onClick={goToSubCoachDetail} key={"coach_element_" + userId}>
        <Table.Cell />
        <TitleSubtitleCell title={userFullName(profile)} subtitle={""} />
        <Table.Cell>{email?.toLocaleLowerCase()}</Table.Cell>
        <Table.Cell>{latestLoginDate && printOnlyDate(latestLoginDate!)}</Table.Cell>
      </Table.Row>
    );
  };

  const emptySection = loading ? (
    <Loader active size="large" />
  ) : (
    <Message icon="search" header="No results" content="There are no coaches in this section" size="large" />
  );

  return (
    <div className="subcoachesListContainer">
      {coaches && coaches.length > 0 ? (
        <Table striped compact="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1} />
              <Table.HeaderCell width={3}>{formatMessage({ ...descriptors[CoachesType.nameHeader] })}</Table.HeaderCell>
              <Table.HeaderCell width={3}>
                {formatMessage({ ...descriptors[CoachesType.emailHeader] })}
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>
                {formatMessage({ ...descriptors[CoachesType.lastActivityHeader] })}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{coaches.map((subcoach: DTO.SubCoach) => content(subcoach))}</Table.Body>
        </Table>
      ) : (
        emptySection
      )}
    </div>
  );
};

export const CoachesList = CoachesFC
