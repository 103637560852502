import * as React from "react";
import{ useIntl } from "react-intl";
import { Grid } from "semantic-ui-react";

import { SearchComponent, VideoCatalystListByPlayer, VideoCatalystSearchList } from "../../../components";
import * as DTO from "../../../interfaces";
import { VideoProjectServices } from "../../../services";

import "./styles.scss";

interface OwnProps {
  coachId: string;

  isEmptySection: (isEmpty: boolean) => void;
}

type Props = OwnProps 

const VideoCatalystListContentFC: React.FC<Props> = ({ coachId, isEmptySection }) => {
  const [searchedVideoProjectsList, setSearchedVideoProjectsList] = React.useState<DTO.BaseVideoProject[]>([]);
  const [searchActive, setSearchActive] = React.useState<boolean>(false);

  const [players, setPlayers] = React.useState<DTO.PlayerResumeResponse[]>([]);
  const [videoProjectStatus] = React.useState<DTO.FilterType[]>([
    {
      key: 0,
      text: "SUBMITTED FOR EDITING",
      value: "SUBMITTED_FOR_EDITING",
    },
    {
      key: 1,
      text: "READY TO POST",
      value: "READY_TO_POST",
    },
    {
      key: 2,
      text: "SCHEDULED",
      value: "SCHEDULED",
    },
    {
      key: 3,
      text: "POSTED",
      value: "POSTED",
    },
    {
      key: 4,
      text: "POST FAILED",
      value: "POST_FAILED",
    },
  ]);

  React.useEffect(() => {
    const getCategories = async () => {
      const result = await VideoProjectServices.getPlayersWithVideoProjects(coachId);
      setPlayers(() => result);
      isEmptySection(result.length === 0);
    };

    getCategories();
  }, [coachId]);

  return (
    <Grid className={"videoCatalystListComponentContainer"} data-elm-id="videoCatalystListComponent">
      <Grid.Row>
        <Grid.Column>
          <SearchComponent
            refreshData={setSearchedVideoProjectsList}
            refreshAllDataToExport={result => {
              if (result) {
                setSearchedVideoProjectsList(() => result!);
              } else {
                setSearchedVideoProjectsList(() => []);
              }
            }}
            hideWhenNotSearch={true}
            filterPlaceholder={"Filter By Status"}
            customSize={50}
            content={
              searchedVideoProjectsList && (
                <VideoCatalystSearchList
                  players={players}
                  videoProjectList={searchedVideoProjectsList}
                  coachId={coachId}
                />
              )
            }
            searchFunction={VideoProjectServices.search}
            showSortBy={false}
            showSearchTerm={false}
            filters={videoProjectStatus}
            pageable={false}
            onSearchedHandler={setSearchActive}
          />

          {players && !searchActive && <VideoCatalystListByPlayer players={players} coachId={coachId} />}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const VideoCatalystListContent = VideoCatalystListContentFC
