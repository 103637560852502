import * as React from "react";
import { Grid, Message, Button, Loader, Dimmer } from "semantic-ui-react";
import { RouteComponentProps, withRouter } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { get, map, keyBy } from "lodash";

import { AppContext } from "../../../providers";
import { SearchSetComponent } from "./SearchSet"
import { SideBar, TitleSection } from "../../../components";
import {
  PostServices,
  KeywordServices,
  PlayersServices,
} from "../../../services";
import * as DTO from "../../../interfaces";
import {
  coachWithWritePermission,
  exportPostFeedList,
  exportPostFeedToCsv,
} from "../../../utils";

import { KeywordSet } from "./KeywordSet";
import { PostFeed } from "./PostFeed";

import { ComplianceType, descriptors } from "./descriptors";

import { WhiteContainer, ContentGrid } from "../../../styling/baseStyle";
import { ComplianceContainer } from "./styled";
import "./styles.scss";

type Props = RouteComponentProps;

const tagsByPolicy = (
  result: DTO.KeywordElement[],
  policy: DTO.KeywordsType
): DTO.TagElement[] => {
  return result
    .filter((keyword) => keyword.policy === policy)
    .map((keyword, index) => {
      const k: DTO.TagElement = {
        index,
        displayValue: keyword.content,
        id: keyword.id,
      };
      return k;
    });
};

const ComplianceFC: React.FC<Props> = ({ history }) => {
  const { formatMessage } = useIntl();

  const { userContext } = React.useContext(AppContext);
  const coachId = get(userContext, "coach.id", "");
  const withWritePermission = coachWithWritePermission(
    DTO.WebComponents.COMPLIANCE,
    userContext
  );
  const [forceRefresh, setForceRefresh] = React.useState<boolean>(false);

  const [players, setPlayers] = React.useState<{ [key: string]: DTO.PlayerAccount }>(
    {}
  );

  const [postList, setPostList] = React.useState<DTO.Post[]>([]);

  const [exportFilters, setExportFilters] = React.useState<
    DTO.SearchFilter | undefined
  >(undefined);
  const [exportOrder, setExportOrder] = React.useState<
    DTO.OrderType | undefined
  >("DESC");

  const [flaggedListError, setFlaggedListError] = React.useState<
    { [key: string]: string } | undefined
  >();
  const [flaggedList, setFlaggedList] = React.useState<DTO.TagElement[]>([]);
  const [displayedFlaggedList, setDisplayedFlagList] = React.useState<DTO.TagElement[]>([]);

  const [blockedListError, setBlockedListError] = React.useState<
    { [key: string]: string } | undefined
  >();
  const [blockedList, setBlockedList] = React.useState<DTO.TagElement[]>([]);
  const [displayedBlockedList, setDisplayedBlockedList] = React.useState<DTO.TagElement[]>([]);

  const [generalErrors, setGeneralErrors] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setDisplayedFlagList(flaggedList)
  }, [flaggedList])

  React.useEffect(() => {
    setDisplayedBlockedList(blockedList)
  }, [blockedList])

  React.useEffect(() => {
    const getKeywords = async () => {
      const result = await KeywordServices.get(coachId);
      setFlaggedList(() => tagsByPolicy(result, DTO.KeywordsType.Flagged));
      setBlockedList(() => tagsByPolicy(result, DTO.KeywordsType.Blocked));
    };

    getKeywords();
  }, [coachId]);

  React.useEffect(() => {
    setForceRefresh(false);
  }, [postList]);

  React.useEffect(() => {
    loadPlayers();
  }, [coachId]);

  React.useEffect(() => {
    const errorList: string[] = [];
    if (flaggedListError) {
      Object.keys(flaggedListError).map((key, index) => {
        return flaggedListError[key] && errorList.push(flaggedListError[key]);
      });
    }
    if (blockedListError) {
      Object.keys(blockedListError).map((key) => {
        return (
          blockedListError[key] &&
          !errorList.includes(blockedListError[key]) &&
          errorList.push(blockedListError[key])
        );
      });
    }
    setGeneralErrors(() => errorList);
  }, [flaggedListError, blockedListError]);

  const exportPosts = async (): Promise<void> => {
    let exportPage = 0;
    let lastPage = false;
    let totalResult: DTO.Post[] = [];

    while (!lastPage) {
      const exportResponse = await PostServices.search(
        coachId,
        `createdDate,${exportOrder}`,
        exportFilters,
        exportPage,
        200
      );

      lastPage = exportResponse.last || false;
      totalResult = totalResult.concat(exportResponse.content);
      exportPage++;
    }

    const data: DTO.ExportPostFeedObject[] = exportPostFeedList(
      totalResult!,
      players
    );
    if (data.length > 0) {
      exportPostFeedToCsv("PostFeed", data);
    }
  };

  const loadPlayers = (): void => {
    PlayersServices.searchSubscribedAllPlayersByTag(coachId).then(
      (response) => {
        const playerData = map(response.content, (player) => player.playerAccount);
        setPlayers(keyBy(playerData, 'playerId'));
      }
    );
  };

  const setErrors = (
    errorKeywords: string[]
  ): { [key: string]: string } | undefined => {
    let newErrors = {};
    errorKeywords.map((keyword: string) => {
      newErrors = {
        ...newErrors,
        [keyword]: "This keyword has already been used: `" + keyword + "`.",
      };
    });

    return newErrors;
  };

  const onErrorFlagged = (keywords: string[]): void => {
    setFlaggedListError(() => setErrors(keywords));
  };

  const onErrorBlocked = (keywords: string[]): void => {
    setBlockedListError(() => setErrors(keywords));
  };

  const onSuccessBlocked = (keywords: DTO.TagElement[]): void => {
    setDisplayedBlockedList(keywords);
  };

  const onSuccessFlagged = (keywords: DTO.TagElement[]): void => {
    setDisplayedFlagList(keywords);
  };

  const updateKeywords = (): void => {
    const keywords: DTO.KeywordElement[] = displayedFlaggedList
      .map((flagged) => {
        return {
          content: flagged.displayValue,
          policy: DTO.KeywordsType.Flagged,
        } as DTO.KeywordElement;
      })
      .concat(
        displayedBlockedList.map((blocked) => {
          return {
            content: blocked.displayValue,
            policy: DTO.KeywordsType.Blocked,
          } as DTO.KeywordElement;
        })
      );

    setLoading(true);

    KeywordServices.update(coachId, keywords)
      .then((result) => {
        setFlaggedList(() => tagsByPolicy(result, DTO.KeywordsType.Flagged));
        setBlockedList(() => tagsByPolicy(result, DTO.KeywordsType.Blocked));
      })
      .catch((e: string) => {
        setGeneralErrors(() => [e]);
      })
      .finally(() => {
        setLoading(false);
        setGeneralErrors(() => []);
      });
  };

  return (
    <WhiteContainer>
      <SideBar history={history} />
      <ContentGrid className={"complianceListManagerContent"} columns={1}>
        <TitleSection
          title={formatMessage({ ...descriptors[ComplianceType.title] })}
          ready={true}
          showNextOption={false}
        />
        <Grid
          columns={1}
          className={"leftMargin noPadding socialContent"}
        >
          {generalErrors.length > 0 && (
            <Grid.Row columns={1}>
              <Message floating error list={generalErrors} />
            </Grid.Row>
          )}

          <ComplianceContainer columns={2}>
            {loading && (
              <Dimmer active inverted>
                <Loader inverted content="Loading" />
              </Dimmer>
            )}
            <Grid.Column
              className={"noPadding"}
            >
              <KeywordSet
                type={DTO.KeywordsType.Flagged}
                title={formatMessage({
                  ...descriptors[ComplianceType.flaggedTitle],
                })}
                tooltip={formatMessage({
                  ...descriptors[ComplianceType.flaggedTooltip],
                })}
                keywords={displayedFlaggedList}
                unavailableKeywords={blockedList.map((a) =>
                  a.displayValue.toLowerCase()
                )}
                onError={onErrorFlagged}
                customTagStyle={`background: rgba(255,206,34, 0.2);`}
                onSuccess={onSuccessFlagged}
              />
            </Grid.Column>
            <Grid.Column
              className={"noPadding"}
            >
              <KeywordSet
                type={DTO.KeywordsType.Blocked}
                title={formatMessage({
                  ...descriptors[ComplianceType.blockedTitle],
                })}
                tooltip={formatMessage({
                  ...descriptors[ComplianceType.blockedTooltip],
                })}
                keywords={displayedBlockedList}
                unavailableKeywords={displayedFlaggedList.map((a) =>
                  a.displayValue.toLowerCase()
                )}
                onError={onErrorBlocked}
                customTagStyle={"background: rgb(254,233,233);"}
                onSuccess={onSuccessBlocked}
              />
            </Grid.Column>
            <Grid.Column textAlign={"center"} width={16} className={"marginBottom"}>
              <Button
                data-elm-id={`compliancUpdateKeywordsBtn`}
                size="medium"
                onClick={updateKeywords}
                className={!withWritePermission ? "disabledForm" : ""}
              >
                <FormattedMessage {...descriptors[ComplianceType.updateBtn]} />
              </Button>
            </Grid.Column>
          </ComplianceContainer>
          <Grid.Row>
            <Grid.Column className={"greyBox bigPadding gridBaseWidth"}>
              <SearchSetComponent
                refreshData={setPostList}
                updateExportFilters={(filter, order) => {
                  setExportFilters(filter);
                  setExportOrder(order);
                }}
                content={
                  <PostFeed
                    postList={postList}
                    players={players}
                    exportFilters={exportFilters}
                    exportOrder={exportOrder}
                    refresh={() => {
                      setForceRefresh(true);
                    }}
                  />
                }
                searchFunction={PostServices.search}
                exportFunction={exportPosts}
                listOfKeywords={flaggedList}
                forceRefresh={forceRefresh}
                showSortBy={false}
                multiple={true}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ContentGrid>
      <div />
    </WhiteContainer>
  );
};

export const CompliancePage = withRouter(ComplianceFC);
