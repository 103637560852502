import * as React from "react";
import { useIntl } from "react-intl";
import { head } from "lodash";

import * as DTO from "../../interfaces";
import { PlayersListWithBranch } from "../../components";
import { PlayersServices } from "../../services";
import { BranchSearch } from "../BranchSearch";

import {
  TransparentContainer,
  ContentGrid,
  SocialGrid,
  SegmentedGrid,
  TitleLabel,
  RowNoPadding,
} from "../../styling/baseStyle";

import { PlayerPostingType, descriptors } from "./descriptors";
import "./styles.scss";

interface OwnProps {
  selectedPlayers?: number[];

  parentResource?: string;
  disabled?: boolean;
  onChange: (selectedPlayers: number[]) => void;
}

type Props = OwnProps; 

const PlayerPostingFC: React.FC<Props> = ({
  selectedPlayers = [],
  onChange,
}) => {
  const { formatMessage } = useIntl();
  const [playersProcessList, setPlayersProcessList] = React.useState<
    DTO.PlayerWithBranch[]
  >([]);
  const eligibilityCriteria = "include";
  React.useEffect(() => {
    onChange(selectedPlayers);
  }, [eligibilityCriteria]);

  const preparePlayerList = (
    playersList: DTO.SubscribedPlayerSearchResponse[]
  ): void => {
    const playerWithStatsResponse: DTO.PlayerWithBranch[] = playersList.map(
      (player) => {
        return {
          info: player.playerAccount,
          photoUrl: player.playerAccount.photoUrl,
          email: player.playerAccount.email,
          branch: head(player.branchNames),
          region: head(player.regionNames),
        } as DTO.PlayerWithBranch;
      }
    );

    setPlayersProcessList(() => playerWithStatsResponse);
  };

  const specificPlayersSection = (): JSX.Element => {
    return (
      <ContentGrid.Row className={"playersListRowContainer"}>
        <SocialGrid.Column>
          <SocialGrid>
            <SocialGrid.Row>
              <ContentGrid.Column className={"noPadding"}>
                <BranchSearch
                  refreshData={preparePlayerList}
                  customFilter={
                    <span className="descriptionLabel">Filter By:</span>
                  }
                  content={
                    <PlayersListWithBranch
                      players={playersProcessList}
                      selectedPlayers={selectedPlayers}
                      onChangeSelectedPlayer={(
                        playerIds: number[],
                        checked: boolean
                      ) => {
                        let newSelected = selectedPlayers;
                        if (checked) {
                          playerIds.forEach((playerId) =>
                            newSelected.push(playerId)
                          );
                        } else {
                          newSelected = newSelected.filter((pId) => {
                            return !playerIds.includes(pId);
                          });
                        }
                        onChange(newSelected);
                      }}
                    />
                  }
                  searchFunction={PlayersServices.searchSubscribedPlayersByTag}
                  multiple={true}
                />
              </ContentGrid.Column>
            </SocialGrid.Row>
          </SocialGrid>
        </SocialGrid.Column>
      </ContentGrid.Row>
    );
  };

  return (
    <TransparentContainer className={"leftMargin minContainer"}>
      <SegmentedGrid className={"postSegmentationManagerContent"} columns={1}>
        <RowNoPadding>
          <TitleLabel>
            {formatMessage({
              ...descriptors[PlayerPostingType.title],
            })}
          </TitleLabel>
        </RowNoPadding>
        <SocialGrid.Row>
          <span className={"descriptionLabel"}>
            {formatMessage({ ...descriptors[PlayerPostingType.subtitle] })}
          </span>
        </SocialGrid.Row>
        {specificPlayersSection()}
      </SegmentedGrid>
    </TransparentContainer>
  );
};

export const PlayersPosting = PlayerPostingFC;
