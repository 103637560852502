import * as Yup from "yup";
import { Rules } from "../../../../utils/rules";

export const validationSchema = Yup.object().shape(
  {
    messageToPost: Yup.string()
      .trim()
      .max(Rules.MAX_CONTENT, "The caption is too long")
      .required("Caption is required")
      .max(Rules.MAX_CONTENT, "Caption is too long"),
    withMediaReady: Yup.number().min(0, "Media files with errors required"),
    imageWithErrors: Yup.number().max(0, "This image does not meet our aspect ratio requirements. Image must be 1:1, 4:5, 16:9 or 1.91:1."),
    videoWithDurationError: Yup.number().max(0, "This video does not meet our video duration requirements. Videos must be between 3 and 90 seconds in duration."),
    videoWithBitrateError: Yup.number().max(0, "This video does not meet our bitrate requirements. Videos must have maximum bitrate of 25Mbps."),
    videoWithResolutionError: Yup.number().max(0, "This video does not meet the our minimum resolution requirements. Video must have a minimum resolution of 540 x 960 pixels (1080 x 1920 is recommended)."),
    videoWithFrameRateError: Yup.number().max(0, "This video does not meet our frame rate requirements. Videos must be in the range of 24–60 frames per second."),
    blockedWords: Yup.string()
    .trim()
    .max(0, (obj) => {
      const value = obj.value;
      return `Caption includes blocked words: ${value}`;
    })
  },
  []
);

export const playersValidationSchema = Yup.object().shape(
  {
    playerIds: Yup.array().min(1, "Select a player")
  },
  []
);


export const scheduledValidationSchema = Yup.object().shape(
  {
    socialNetworksCounter: Yup.number().when("isAutoPost", {
      is: (a: any) => a === true,
      then: () => Yup.number().min(1, "You must select at least one social network to Auto Post"),
    }),
  },
  []
);