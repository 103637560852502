import * as React from "react";
import { Header, Grid, Button } from "semantic-ui-react";
import { useIntl } from "react-intl";
import { PreviewPromptType, descriptors } from "./descriptors";
import { Png } from "../../../assets";

import { LandingResponse } from "../../../interfaces";
import { UserResume } from "../../../containers/UserResume";

import "./styles.scss";
interface Props {
  title?: string;
  photoURL?: string;
  salesMessage?: string;
  firstName: string;
  lastName: string;
  bannerURL?: string;
  disabled: boolean;
  socialNetworks?: any[];

  buttonName?: string;
  handleWebPreview: (e: any) => Promise<any>;
}

const PreviewLandingSection: React.FC<Props> = ({
  buttonName,
  firstName,
  lastName,
  salesMessage,
  title,
  socialNetworks,
  photoURL,
  handleWebPreview,
  bannerURL = undefined,
  disabled = false,
}) => {
  const { formatMessage } = useIntl();
  const bannerStyle = bannerURL
    ? {
        backgroundImage: "url(" + bannerURL + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundSize: "contain",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        height: 95,
      }
    : {};

  const phoneFrameStyle = {
    backgroundImage: "url(" + Png.PreviewPhone + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
    backgroundSize: "cover",
    padding: "45px 8px",
    overflow: "hidden",
    height: "628px",
    width: "300px",
  };

  const coachInfo: LandingResponse = {
    coachId: "",
    salesMessage,
    coach: {
      title,
      firstName,
      lastName,
      socialNetworks,
      photoUrl: photoURL,
    },
  };

  return (
    <div className={"previewSegment landing"}>
      <Header as="h4" className={"mediumPaddingBottom"}>
        {formatMessage({ ...descriptors[PreviewPromptType.title] })}
      </Header>
      <div style={phoneFrameStyle}>
        <Grid className={"previewContainer"} columns={1}>
          <Grid columns={1} className={"noPadding smallPreviewContent"}>
            <div className={"userBanner"} style={bannerStyle} />
            <Grid className={"landingContent"}>
              <Grid.Column floated="left" width={16}>
                <Grid className={"phonePreviewContent"}>
                  {coachInfo && <UserResume phoneMode={true} coachInfo={coachInfo!} />}
                </Grid>
              </Grid.Column>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid.Row centered={true} className={"paddingTop"}>
        <Grid.Column centered={true} className={"centered"}>
          <Button
            data-elm-id={`previewLandingPageOKBtn`}
            onClick={async e => {
              e.preventDefault();
              handleWebPreview(e);
            }}
            className={"primary rounded centered"}
            disabled={disabled}
          >
            {buttonName}
          </Button>
        </Grid.Column>
      </Grid.Row>
    </div>
  );
};

export const PreviewLanding = PreviewLandingSection;
