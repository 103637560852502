import { client as axios } from "./axiosClient";
import { catchServiceException, Constants } from "@socialcoach/services";
import * as DTO from "../interfaces/dtos";

const MEDIA_SERVICE = `${Constants.API_URL}/media`;

export class MediaServices {
  @catchServiceException()
  static async transcode(transcodeRequest: DTO.TranscodeRequest): Promise<DTO.TranscodeResponse> {
    const { data } = await axios.post<DTO.TranscodeResponse>(
      `${MEDIA_SERVICE}/transcode`,
      {
        ...transcodeRequest,
      },
      {
        withCredentials: true,
      }
    );

    return data;
  }

  @catchServiceException()
  static async getJobStatus(jobId: string): Promise<DTO.TranscodeResponse> {
    const { data } = await axios.get<DTO.TranscodeResponse>(`${MEDIA_SERVICE}/transcode/${encodeURIComponent(jobId)}`, {
      withCredentials: true,
    });

    return data;
  }
}
