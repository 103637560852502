import { sortBy } from "lodash";

export function playerListReducer(state: any, action: any) {
  switch (action.type) {
    case "CHANGE_SORT":
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
        };
      }

      return {
        column: action.column,
        data: sortBy(state.data, [action.column]),
        direction: "ascending",
      };
    case "REFRESH_DATA":
      return {
        column: null,
        data: action.data,
        direction: null,
      };
    default:
      throw new Error();
  }
}
