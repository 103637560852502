import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Grid } from "semantic-ui-react";

import { CoachList, TitleSection, AdminSideBar } from "../../../components";
import { AppContext } from "../../../providers";

type Props = RouteComponentProps;

const AdminDashboardFC: React.FC<Props> = ({ history }) => {
  return (
    <AppContext.Consumer>
      {({ userContext }) => {
        return (
          <div className={"whiteContainer"}>
            <AdminSideBar history={history} />

            <div className={"hugePadding playerAdminList"}>
              <TitleSection
                title={"Coaches List"}
                subtitle={`Hello, ${userContext?.profile.firstName}`}
                ready={true}
                showNextOption={false}
                showLinkOption={true}
                customLinkSection={
                  <></>
                }
              />
              <Grid.Row className={"coachBaseContainer"}>
                <Grid.Column>
                  <CoachList/>
                </Grid.Column>
              </Grid.Row>
            </div>
          </div>
        );
      }}
    </AppContext.Consumer>
  );
};

export const AdminDashboard = withRouter(AdminDashboardFC);
