import * as React from "react";
import { Grid, Button, Header } from "semantic-ui-react";

import { DayOfWeek, ArrayOfDays } from "../../interfaces";
import { getDayOfWeekState } from "../../utils";

import "./styles.scss";

interface Props {
  selected: DayOfWeek[];
  description?: string;
  editable?: boolean;
  onChange: (value: DayOfWeek[]) => any;
}

export const MultipleDaySelectorComponent = ({
  selected,
  editable = true,
  description = "Days we should send randomized prompts to your players",
  onChange,
}: Props) => {
  const selectedDays = getDayOfWeekState(selected);

  const toggleSelectedDay = (dayOfWeek: DayOfWeek) => {
    selectedDays[dayOfWeek] = !selectedDays[dayOfWeek];
    onChange(ArrayOfDays.filter(day => selectedDays[day]));
  };

  return (
    <Grid columns="16" className={"dayOfWeekGridComponent"}>
      <Grid.Row>
        <Header as="span" className={"smallLeftPadding"} floated={"right"}>
          {description}
        </Header>
      </Grid.Row>
      <Grid.Row className={"leftPadding"}>
        {ArrayOfDays.map(dayOfWeek => (
          <Grid.Column key={"multi_days_selector_" + dayOfWeek} width={2} textAlign={"center"} className={"noPadding"}>
            <Button
              className={editable ? "" : "disabled"}
              data-elm-id={`multidaysSelectorBtn`}
              type={"button"}
              toggle
              fluid
              name={dayOfWeek}
              active={selectedDays[dayOfWeek]}
              onClick={(e: any) => {
                toggleSelectedDay(e.target.name as DayOfWeek);
              }}>
              {dayOfWeek.substring(0, 3)}
            </Button>
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );
};
