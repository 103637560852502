import * as React from "react";
import{ useIntl } from "react-intl";
import { Grid } from "semantic-ui-react";

import { PromptPageType, descriptors } from "../../descriptors";
import { PromptStatus } from "../../../../../interfaces";

interface OwnProps {
  /**
   * Boolean to indicate if the prompt is active
   */
  promptActive?: boolean;

  /**
   * Boolean to indicate if the coach has post permission
   */
  availableToPost?: boolean;

  /**
   * Boolean to indicate that all buttons needs to be disabled
   */
  disableButtons: boolean;
  /**
   * Boolean to indicate the prompt is new
   */
  isANewPrompt: boolean;
  /**
   * Function called when cancel button is clicked
   */
  goBack: () => void;

  verifyAndSave: (action: PromptStatus) => void;
  onContentloading?: (value: boolean) => void;
}

const HeaderActionButtonsFC: React.FC<OwnProps> = ({
  promptActive,
  disableButtons,
  isANewPrompt = true,
  availableToPost = false,
  goBack,
  verifyAndSave,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Grid.Column
      width={availableToPost ? (promptActive ? 7 : 6) : 11}
      floated="right"
      className="skip-link noMarginRight">
      <span
        className={"rightPadding"}
        data-elm-id={"promptPageCancelBtn"}
        onClick={
          disableButtons
            ? undefined
            : () => {
                goBack();
              }
        }>
        {formatMessage({ ...descriptors[PromptPageType.cancelBtn] })}
      </span>
      {!isANewPrompt && (
        <span
          className={"rightPadding deletePromptButton"}
          data-elm-id={"promptPageCancelBtn"}
          onClick={
            disableButtons
              ? undefined
              : () => {
                  verifyAndSave("DISABLED");
                }
          }>
          Delete
        </span>
      )}
      <span
        data-elm-id={"promptPageSaveBtn"}
        onClick={
          disableButtons
            ? undefined
            : () => {
                verifyAndSave("DRAFT");
              }
        }>
        {formatMessage({ ...descriptors[PromptPageType[promptActive ? "saveUnPublishBtn" : "saveBtn"]] })}
      </span>
    </Grid.Column>
  );
};

export const HeaderActionButtons = HeaderActionButtonsFC;
