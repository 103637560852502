import crypto from "crypto";
// const crypto = require('crypto');
const oauth1a = require("oauth-1.0a");

export class Oauth1Helper {
  /**
   * @param: length - Size of random string. Minimum length of 43 characters and a maximum length of 128 characters.
   */
  static generateRandomString(length: number) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  static generatePKCEPair() {
    const NUM_OF_BYTES = 22; // Total of 44 characters (1 Bytes = 2 char) (standard states that: 43 chars <= verifier <= 128 chars)
    const NUM_OF_CHARS = 43;
    const HASH_ALG = "sha256";

    const randomVerifier = Oauth1Helper.generateRandomString(NUM_OF_CHARS); // crypto.randomBytes(NUM_OF_BYTES).toString("hex");

    const hash = crypto.createHash(HASH_ALG).update(randomVerifier).digest("base64");
    const challenge = hash.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, ""); // Clean base64 to make it URL safe

    // code_challenge = CryptoJS.SHA256(code_verifier).toString(CryptoJS.enc.Hex);
    return { randomVerifier, challenge };
  }

  static getAuthHeaderForRequest(request: any, consumerKey: string, consumerSecret: string) {
    const oauth = oauth1a({
      consumer: { key: consumerKey, secret: consumerSecret },
      signature_method: "HMAC-SHA1",
      hash_function(base_string: any, key: any) {
        return crypto.createHmac("sha1", key).update(base_string).digest("base64");
      },
    });

    const authorization = oauth.authorize(request, {
      key: "", // TOKENKEY,
      secret: "", // TOKENSECRET,
    });

    return oauth.toHeader(authorization);
  }
}
