import * as React from "react";
import VimeoPlayer, { EventCallback } from "@vimeo/player";
import * as gtag from "../../utils/gtag";

import "./styles.scss";

interface Props {
  videoUrl: string;
  preview?: boolean;
  onPlay?: (e: EventCallback) => void;
  onPause?: (e: EventCallback) => void;
  onEnded?: (e: EventCallback) => void;
}

export default ({ videoUrl, preview, onPlay, onPause, onEnded }: Props) => {
  const vimeoURL = `https://player.vimeo.com/video/${videoUrl.substr(18, videoUrl.length)}`;
  const localVideoUrl = `${vimeoURL}?title=0&byline=0&portrait=0&badge=0&api=1&autoplay=0`;

  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  React.useEffect(() => {
    if (!iframeRef.current) {
      return;
    }

    const player = new VimeoPlayer(iframeRef.current);
    const trackEvent = (eventName: string) => async () => {
      const title = await player.getVideoTitle();
      gtag.eventTrack(eventName, {
        event_category: "video",
        event_label: title,
        content_id: videoUrl,
      });
      // gtag.event(eventName, {
      //   event_category: "video",
      //   event_label: title,
      //   content_id: videoUrl,
      // });
    };

    const trackVideoPlay = trackEvent("video_play");
    const trackVideoEnded = trackEvent("video_ended");
    player.on("play", trackVideoPlay);
    player.on("ended", trackVideoEnded);

    return () => {
      player.off("play", trackVideoPlay);
      player.off("ended", trackVideoEnded);
    };
  }, [localVideoUrl]);

  return (
    <div className="embedContainer">
      <iframe
        ref={iframeRef}
        src={localVideoUrl}
        className={preview ? "previewMode" : ""}
        width="100%"
        height="auto"
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
};
