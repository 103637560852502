import * as React from "react";
import{ useIntl, FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { Header, Form, Input, Grid } from "semantic-ui-react";

import { fields } from "./formFields";
import { FloatedCard } from "../../../../containers";
import { BasicFormProps, ConfigProperties } from "../../../../interfaces";

import "./styles.scss";
import { FirstLoginType, descriptors } from "./descriptors";
import { noSpaces, removeSpecialCharacters, useCheckMobileScreen } from "../../../../utils";
import { FloatedContainer } from "../../../../styling/baseStyle";
import { ConfigContext } from "../../../../contexts/appContexts";

interface OwnProps extends BasicFormProps {
  handleSetFieldValue: (field: string, value: any) => void;
}

type Props = OwnProps   & RouteComponentProps;

/**
 * Represents a Player First login screen - Step 3.
 */

const IntegratedFirstLoginWebAddress: React.FC<Props> = ({

  messages,
  handleSetFieldValue,
  handleSubmit,
  isSubmitting,
  values,
}) => {
  const { formatMessage } = useIntl();
  const { slugBaseUrl }: ConfigProperties = React.useContext(ConfigContext);

  const content = (
    <FloatedContainer>
      {fields.map((field, index) => {
        return (
          <Form.Group widths="equal" key={"firstLogin-" + field.id + "group" + index}>
            <Form.Field>
              <Input
                key={field.id + index}
                className={"inputWithLabel"}
                label={field.id !== "webAddress" && `${slugBaseUrl}`}
                fluid
                data-elm-id={"firstLoginInput" + field.id}
                type={"text"}
                id={field.id}
                name={field.name}
                placeholder={formatMessage({ ...descriptors[field.placeholder] })}
                onChange={e => {
                  const newValue =
                    field.id === "webAddress"
                      ? noSpaces(e.currentTarget.value)
                      : removeSpecialCharacters(e.currentTarget.value);
                  handleSetFieldValue(e.currentTarget.id, newValue);
                }}
                value={values[field.id]}
                disabled={isSubmitting}
              />
            </Form.Field>
          </Form.Group>
        );
      })}
      <Grid className={"buttonRight"}>
        <Header as={"h6"} className={"urlDescription"} floated={"right"}>
          You can customize the short code URL
        </Header>
      </Grid>
      <Form.Group widths="equal" className={"buttonContainer"}>
        <Form.Button size="large" type="submit" disabled={isSubmitting} onClick={handleSubmit} className={"signUp"}>
          <FormattedMessage {...descriptors[FirstLoginType.submit]} />
        </Form.Button>
      </Form.Group>
    </FloatedContainer>
  );

  const mobileContent = (
    <FloatedContainer>
      {fields.map((field, index) => {
        return (
          <Form.Group widths="equal" key={"firstLogin-" + field.id + "group" + index}>
            <Form.Field>
              <Input
                key={field.id + index}
                className={"inputWithLabel"}
                label={field.id !== "webAddress" && `${slugBaseUrl}`}
                fluid
                data-elm-id={"firstLoginInput" + field.id}
                type={"text"}
                id={field.id}
                name={field.name}
                placeholder={formatMessage({ ...descriptors[field.placeholder] })}
                onChange={e => {
                  const newValue =
                    field.id === "webAddress"
                      ? noSpaces(e.currentTarget.value)
                      : removeSpecialCharacters(e.currentTarget.value);
                  handleSetFieldValue(e.currentTarget.id, newValue);
                }}
                value={values[field.id]}
                disabled={isSubmitting}
              />
            </Form.Field>
          </Form.Group>
        );
      })}
      <Grid className={"buttonRight"}>
        <Header as={"h6"} className={"urlDescription"} floated={"right"}>
          You can customize the short code URL
        </Header>
      </Grid>
      <Form.Group widths="equal" className={"buttonContainer"}>
        <Form.Button size="large" type="submit" disabled={isSubmitting} onClick={handleSubmit} className={"signUp"}>
          <FormattedMessage {...descriptors[FirstLoginType.submit]} />
        </Form.Button>
      </Form.Group>
    </FloatedContainer>
  );

  return (
    <FloatedCard
      subtitle={formatMessage({ ...descriptors[FirstLoginType.subtitle] })}
      content={useCheckMobileScreen() ? mobileContent : content}
      messages={messages}
      isSubmitting={isSubmitting}
      size={"bigCentered"}
    />
  );
};

export const FirstLoginWebAddress = withRouter(IntegratedFirstLoginWebAddress)
