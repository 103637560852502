import { URLS } from "../../utils";

export const webComponents: { [key: string]: any } = {
  BASIC_PRICE_PLAN: {
    title: "Plan",
    path: URLS.coach.pricePlan,
  },
  BASIC_MARKETING: {
    title: "Marketing Site",
    path: URLS.coach.marketingSite,
  },
  LIBRARY: {
    title: "Library",
    path: URLS.coach.library,
  },
  VIDEO_CATALYST: {
    title: "Video Catalyst",
    path: URLS.coach.videoCatalyst,
  },
  MANAGE_SCRIPT_LIBRARY: {
    title: "Script Library",
    path: URLS.videoScripts,
  },
  CAMPAIGNS: {
    title: "Campaigns",
    path: URLS.coach.campaigns,
  },
  PLAYERS: {
    title: "Players",
    path: URLS.coach.players,
  },
  COMPLIANCE: {
    title: "Compliance Manager",
    path: URLS.coach.compliance,
  },
  ADMIN: {
    title: "Admin",
    path: URLS.coach.subCoaches,
  },
  ALERTS: {
    title: "Alerts",
    path: URLS.coach.alerts,
  },
  DIGEST_EMAIL: {
    title: "Digest Email Manager",
    path: URLS.coach.digestEmails,
  },
  APP_SETTINGS: {
    title: "Settings",
    path: URLS.coach.appSettings,
  },
};

export const playerWebOptions = [
  {
    title: "Dashboard",
    path: URLS.player.dashboard,
  },
  {
    title: "Library",
    path: URLS.player.library.replace(":tab?", ""),
  },
  {
    title: "Settings",
    path: URLS.player.settings.replace(":tab?", ""),
  },
];
