import * as React from "react";
import { Grid } from "semantic-ui-react";

import { VideoScriptsList, MenuTitleSection } from "../../../components";
import { WhiteContainer } from "../../../styling/baseStyle";

import "./styles.scss";
import { URLS } from "../../../utils";

interface Props {
  handleNextAction: () => void;
}

const PlayerVideoScriptsSectionFC: React.FC<Props> = ({ handleNextAction }) => {
  return (
    <WhiteContainer>
      <div className={"playerVideoScriptsList"}>
        <MenuTitleSection
          customNextLabel={"New Script"}
          nextButtonSize={8}
          absolute
          handleNextAction={handleNextAction}
        />
        <Grid.Row className={"menuBaseContainer"}>
          <Grid.Column>
            <VideoScriptsList redirectUrl={URLS.player.videoScript} />
          </Grid.Column>
        </Grid.Row>
      </div>
    </WhiteContainer>
  );
};

export const PlayerVideoScriptsSection = PlayerVideoScriptsSectionFC;
