import * as React from "react";
import { SocialNetworksName } from "../../../interfaces";
import { Loader } from "semantic-ui-react";

import "./styles.scss";
interface Props {
  /**
   * Social network title
   */
  title: string;
  /**
   * User account name (username)
   */
  account: string;

  /**
   * Defines how the button will look
   */
  inProfile?: boolean;
  /**
   * Defines how the button will look
   */
  socialNetwork?: SocialNetworksName;
  /**
   * On click handler
   */
  handler?: (socialNetwork: SocialNetworksName) => void;

  loading?: boolean;
}

const CoachSocialNetworkButton: React.FC<Props> = ({ title, account, handler, inProfile, socialNetwork, loading }) => {
  return (
    <div>
      {!inProfile && (
        <div
          onClick={() => {
            if (handler) {
              handler!(socialNetwork!);
            }
          }}
          className={socialNetwork + " coach-profile-social-button" + (account !== "" ? " selected" : " bordered")}>
          <div className={"coach-profile-social-button-content"}>
            <i className={"social-network-name" + (account !== "" ? " selected" : "")}>{title}</i>
            <br />
            <span className="profile-social-network-name">{account}</span>
          </div>
        </div>
      )}
      {inProfile && (
        <div
          onClick={() => {
            if (handler) {
              handler!(socialNetwork!);
            }
          }}
          className={socialNetwork + " coach-profile-social-button" + (account !== "" ? " selected" : "")}>
          <div className={"coach-profile-social-button-content"}>
            {loading ? <Loader active size="small" /> : <span className="profile-social-network-name">{title}</span>}
          </div>
        </div>
      )}
    </div>
  );
};

export default CoachSocialNetworkButton;
