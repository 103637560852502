import * as React from "react";
import { Header, Grid, Button, Image as SemanticImage, Icon } from "semantic-ui-react";
import{ useIntl } from "react-intl";
import { PreviewPromptType, descriptors } from "./descriptors";
import moment from "moment";
import { Png } from "../../assets";
import VimeoPlayer from "../VimeoPlayer";
import { createVideoThumbnailBlob } from "../../utils/vimeo";
import { isEmpty } from "lodash";
import { ThumbnailCarousel, VideoPreview } from "../";
import { Media } from "../../interfaces";

import "./styles.scss";
import { textWithLimit } from "../../utils";
import { ConfigContext } from "../../contexts/appContexts";

interface Props {
  title: string;
  message: string;
  hashtags?: string;
  date: string;
  coachImage?: string;
  coachFullName: string;
  showVideo: boolean;
  showAttached?: boolean;
  showShortLink?: boolean;
  showComplianceDisclaimer?: boolean;
  media: Media[];
  videoUrl: string | undefined;
}

const PromptPreviewSection: React.FC<Props> = ({
  title,
  message,
  hashtags,
  date,
  coachFullName,
  coachImage = "https://react.semantic-ui.com/images/wireframe/square-image.png",
  showAttached = true,
  showVideo = true,
  showShortLink = true,
  showComplianceDisclaimer = true,
  videoUrl,
  media,
 
}) => {
  const [thumbnailVideo, setThumbnailVideo] = React.useState<string>();
  const { formatMessage } = useIntl();
  
  React.useEffect(() => {
    if (videoUrl) {
      const urlPromise = createVideoThumbnailBlob(videoUrl);
      urlPromise.then(url => setThumbnailVideo(url));
    }
  }, [videoUrl]);

  const promptDate = date ? moment.utc(date, "YYYY-MM-DDTHH:mm:ssZ") : undefined;

  const bannerStyle = {
    backgroundImage: "url(" + Png.PreviewPhone + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
    backgroundSize: "cover",
    padding: "45px 8px",
    overflow: "hidden",
    height: "628px",
    width: "300px",
  };

  const videoContent = !isEmpty(thumbnailVideo) ? (
    <VideoPreview thumbnailSrc={thumbnailVideo} />
  ) : (
    videoUrl && <VimeoPlayer videoUrl={videoUrl} />
  );

  return (
    <div className={"previewSegment"}>
      <Header as="h4" className={"mediumPaddingBottom"}>
        {formatMessage({ ...descriptors[PreviewPromptType.title] })}
      </Header>
      <div style={bannerStyle}>
        <Grid className={"previewContent"}>
          <Grid.Row columns={2}>
            <Grid.Column width={3}>
              <SemanticImage src={coachImage} avatar />
            </Grid.Column>
            <Grid.Column width={10}>
              <Header as="h3">{coachFullName}</Header>
            </Grid.Column>
            {date && (
              <Grid.Column width={2}>
                <Header as="h6">{promptDate?.format("MM/DD/YY")}</Header>
              </Grid.Column>
            )}
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Header as="h4">{title}</Header>
              <p dangerouslySetInnerHTML={{ __html: textWithLimit(message, 300) }} />
            </Grid.Column>
          </Grid.Row>

          {showShortLink && (
            <ConfigContext.Consumer>
              {({ deployBaseUrl }) => (
                <Grid.Row>
                  <Grid.Column>
                    <p
                      className="shortLinkLabel"
                      dangerouslySetInnerHTML={{ __html: `<a>${deployBaseUrl}example</a>` }}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
            </ConfigContext.Consumer>
          )}

          {hashtags && (
            <Grid.Row>
              <Grid.Column>
                <p className="hashtags" dangerouslySetInnerHTML={{ __html: `${hashtags}` }} />
              </Grid.Column>
            </Grid.Row>
          )}

          {showComplianceDisclaimer && (
            <ConfigContext.Consumer>
              {({ defaultComplianceDisclaimer }) => (
                <Grid.Row>
                  <Grid.Column>
                    <p dangerouslySetInnerHTML={{ __html: `${defaultComplianceDisclaimer}` }} />
                  </Grid.Column>
                </Grid.Row>
              )}
            </ConfigContext.Consumer>
          )}

          {showVideo && videoUrl && (
            <Grid.Row columns={1} className={"videoSection"}>
              {videoContent}
            </Grid.Row>
          )}
          {showAttached && media.length > 0 && <ThumbnailCarousel media={media} />}
          <Grid.Row>
            <Grid.Column>
              <Button disabled fluid>
                {formatMessage({ ...descriptors[PreviewPromptType.postBtn] })}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export const PromptPreview = PromptPreviewSection
