import * as React from "react";
import { Grid, Form, Radio, Loader, Modal, Button, Dimmer } from "semantic-ui-react";
import { Account, BaseCoach, AdminWebSetting, WebSettings } from "../../../interfaces";
import { SettingsServices } from "../../../services";

import { modalReducer } from "./modalReducer";
import { appSettings } from "./formFields";
import "./styles.scss";

interface OwnProps {
  opened: boolean;
  coach: BaseCoach;
  handleUpdateSuccess: () => void;
}

const CoachPermissionsFC: React.FC<OwnProps> = ({ coach, opened, handleUpdateSuccess }) => {
  const [state, dispatch] = React.useReducer(modalReducer, { open: opened });

  const [loading, setLoading] = React.useState<boolean>(false);
  const [permissions, setPermissions] = React.useState<{ [key: string]: string }>({});
  const [coachWebSettings, setCoachWebSettings] = React.useState<AdminWebSetting | undefined>(undefined);

  React.useEffect(() => {
    setLoading(true);
    const getSettings = async () => {
      SettingsServices.getAdminSettingsByCoachId(coach.id)
        .then(setCoachWebSettings)
        .finally(() => setLoading(false));
    };

    getSettings();
  }, [coach.id]);

  React.useEffect(() => {
    if (coachWebSettings) {
      setPermissions({
        externalPost:
          coachWebSettings.settings.find(s => s.webSettingId === WebSettings.EXTERNAL_POST)?.status || "INACTIVE",
        failedPost:
          coachWebSettings.settings.find(s => s.webSettingId === WebSettings.FAILED_POST_NOTIFICATION)?.status ||
          "INACTIVE",
        tiktok: coachWebSettings.settings.find(s => s.webSettingId === WebSettings.TIKTOK)?.status || "INACTIVE",
        coachSummaryReport:
          coachWebSettings.settings.find(s => s.webSettingId === WebSettings.SUMMARY_REPORT_COACH)?.status ||
          "INACTIVE",
        costCenter:
          coachWebSettings.settings.find(s => s.webSettingId === WebSettings.COST_CENTER)?.status || "INACTIVE",
        scPlusTier:
          coachWebSettings.settings.find(s => s.webSettingId === WebSettings.SC_PLUS_TIER)?.status || "INACTIVE",
        accessScriptLibrary:
          coachWebSettings.settings.find(s => s.webSettingId === WebSettings.ACCESS_SCRIPT_LIBRARY)?.status ||
          "INACTIVE",
        manageScriptLibrary:
          coachWebSettings.settings.find(s => s.webSettingId === WebSettings.MANAGE_SCRIPT_LIBRARY)?.status ||
          "INACTIVE",
      });
    }
  }, [coachWebSettings]);

  const handleSettingsChange = (e: any, data: any) => {
    setPermissions(p => {
      return {
        ...p,
        [data.name]: data.checked ? "ACTIVE" : "INACTIVE",
      };
    });
  };

  const updateAdminSettings = (): void => {
    setLoading(true);
    const settingsRequest: AdminWebSetting = {
      coachId: coach.id,
      settings: [
        {
          webSettingId: WebSettings.EXTERNAL_POST,
          status: permissions["externalPost"],
        },
        {
          webSettingId: WebSettings.FAILED_POST_NOTIFICATION,
          status: permissions["failedPost"],
        },
        {
          webSettingId: WebSettings.TIKTOK,
          status: permissions["tiktok"],
        },
        {
          webSettingId: WebSettings.SUMMARY_REPORT_COACH,
          status: permissions["coachSummaryReport"],
        },
        {
          webSettingId: WebSettings.COST_CENTER,
          status: permissions["costCenter"],
        },
        {
          webSettingId: WebSettings.SC_PLUS_TIER,
          status: permissions["scPlusTier"],
        },
        {
          webSettingId: WebSettings.ACCESS_SCRIPT_LIBRARY,
          status: permissions["accessScriptLibrary"],
        },
        {
          webSettingId: WebSettings.MANAGE_SCRIPT_LIBRARY,
          status: permissions["manageScriptLibrary"],
        },
      ],
    };

    SettingsServices.updateAdminSettings(settingsRequest)
      .then(() => {
        dispatch({ type: "close" });
        handleUpdateSuccess();
      })
      .catch((e: string) => {
        alert(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const content = (
    <React.Fragment>
      <Grid columns={1} padded>
        <Grid.Column>
          <Form.Group grouped key={"appSettingsGroupPermissions"}>
            {appSettings.map(appSetting => {
              return (
                <>
                  <Form.Group inline key={appSetting.id}>
                    <Radio
                      toggle
                      label={appSetting.label}
                      className={"settingsLevelLabel"}
                      onChange={handleSettingsChange}
                      name={appSetting.id}
                      checked={permissions && permissions[appSetting.id] === "ACTIVE"}
                    />
                  </Form.Group>
                </>
              );
            })}
          </Form.Group>
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );

  return (
    <Modal
      data-elm-id={`admin-dashboard-modal-coach-permission-${coach.id}`}
      open={state.open}
      onClose={() => {
        dispatch({ type: "close" });
        handleUpdateSuccess();
      }}
      closeIcon={true}
    >
      <Modal.Header> Coach Permissions</Modal.Header>
      <Modal.Content>
        <Grid
          columns={16}
          className="adminPermissionContainer"
          verticalAlign="middle"
          stretched
          container
          textAlign={"left"}
        >
          <Grid.Column width={16}>
            {loading ? (
              <Dimmer active inverted>
                <Loader size="medium">Loading</Loader>
              </Dimmer>
            ) : (
              content
            )}
          </Grid.Column>
        </Grid>
      </Modal.Content>

      <Modal.Actions>
        <Button data-elm-id={`admin-dashboard-modal-coach-permission-save-btn`} primary onClick={updateAdminSettings}>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const CoachPermissions = CoachPermissionsFC;
