import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Grid, Transition, Message, Loader, Header } from "semantic-ui-react";
import { get } from "lodash";

import { AppContext } from "../../../providers";
import { SideBar, TitleSection, PromptsManager } from "../../../components";
import { CoachSignUpStep, LoginStepServices } from "../../../services";
import { finishStep } from "../../../utils";

import { WhiteContainer, ContentGrid } from "../../../styling/baseStyle";

type Props = RouteComponentProps;

const PromptsFC: React.FC<Props> = ({ history }) => {
  const { setUserContext, userContext } = React.useContext(AppContext);
  const [loading, setLoading] = React.useState<boolean>(false);

  const currentStep = CoachSignUpStep.COACH_PROMPTS;
  const coachId = get(userContext, "coach.id", undefined);

  const updateCoachStep = (): void => {
    if (CoachSignUpStep[userContext!.signUpStep!] < currentStep) {
      LoginStepServices.sendStep(CoachSignUpStep[currentStep]).then(() => {
        setUserContext({
          ...userContext!,
          signUpStep: CoachSignUpStep[currentStep],
        });
      });
    }
  };

  return (
    <WhiteContainer>
      <SideBar history={history} />
      <ContentGrid className={"promptManagerContent"} columns={1}>
        <Grid columns={1}>
          <TitleSection
            title={"Create your Prompts"}
            ready={finishStep(userContext?.signUpStep)}
            currentStep={currentStep}
            handleLoading={setLoading}
            showNextOption={false}
          />
          <Grid.Row className={"promptListRowContainer coachBaseContainer"}>
            {loading && <Loader active size="large" />}
            <Grid.Column>
              <PromptsManager handlePromptsReady={updateCoachStep} coachId={coachId!} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const Prompts = withRouter(PromptsFC);
