import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Grid, Loader } from "semantic-ui-react";
import { get } from "lodash";

import { AppContext } from "../../../providers";
import { SideBar, TitleSection, PromptListContent } from "../../../components";
import { CoachSignUpStep } from "../../../services";
import { URLS } from "../../../utils";

import { WhiteContainer, ContentGrid } from "../../../styling/baseStyle";

type Props = RouteComponentProps;

const PromptListFC: React.FC<Props> = ({ history }) => {
  const { userContext } = React.useContext(AppContext);
  const [loading, setLoading] = React.useState<boolean>(false);

  const currentStep = CoachSignUpStep.COACH_PROMPTS;
  const coachId = get(userContext, "coach.id", undefined);

  return (
    <WhiteContainer>
      <SideBar history={history} />
      <ContentGrid className={"promptManagerContent"} columns={1}>
        <Grid columns={1}>
          <TitleSection
            title={"List of Prompts"}
            ready={true}
            currentStep={currentStep}
            handleLoading={setLoading}
            showNextOption={true}
            customNextLabel={"Add Prompt"}
            nextButtonSize={12}
            handleNextAction={async () => {
              history.push(URLS.coach.newPrompt);
            }}
          />
          <Grid.Row className={"promptListRowContainer coachBaseContainer"}>
            {loading && <Loader active size="large" />}
            <Grid.Column>
              <PromptListContent coachId={coachId!} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const PromptList = withRouter(PromptListFC);
