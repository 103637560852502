import * as React from "react";
import{ useIntl } from "react-intl";
import { Header, Grid, Checkbox, Form, Radio } from "semantic-ui-react";
 import { PatternFormat, NumericFormat } from "react-number-format";
import { PriceSectionType, descriptors } from "./descriptors";
import "./styles.scss";
import { ConfigContext } from "../../contexts/appContexts";
import { BillingPeriod } from "../../interfaces";

interface OwnProps {
  price?: number;
  hasFreeTrial: boolean;
  active: boolean;
  interval: BillingPeriod;

  handlePriceChange(value: number): void;
  handleFreeTrialChange(value: boolean): void;
  handleActiveChange(value: boolean): void;
}

type Props = OwnProps;

const PriceSectionFC: React.FC<Props> = ({
  price,
  hasFreeTrial,
  active,
  interval,
  handlePriceChange,
  handleFreeTrialChange,
  handleActiveChange,
}) => {
  const { formatMessage } = useIntl();
  const configContext = React.useContext(ConfigContext);
  return (
    <Grid className={"priceSection"}>
      <Grid.Column width={3} floated="left" className="marginTop">
        <Form.Group inline key={interval + "toogle"} className={"floatedRight"}>
          <Radio
            toggle
            className={"settingsLevelLabel"}
            onChange={(e: any, data: any) => {
              handleActiveChange(data.checked);
            }}
            name={interval + "toogle"}
            checked={active}
          />
        </Form.Group>
      </Grid.Column>
      <Grid.Column className="paddingTop" width={6} floated="left">
        <Header as="h6" className={"noMarginBottom"}>
          {formatMessage(
            { ...descriptors[PriceSectionType.setPrice] },
            { interval: interval === BillingPeriod.MONTHLY ? "month" : "year" }
          )}
        </Header>

        <NumericFormat
          className={"priceHeader"}
          decimalScale={2}
          allowNegative={false}
          thousandSeparator
          prefix="$ "
          isAllowed={value => {
            return value.value.length < 10;
          }}
          onValueChange={value => {
            handlePriceChange(value.floatValue as number);
          }}
          placeholder={formatMessage(
            { ...descriptors[PriceSectionType.setPrice] },
            { interval: interval === BillingPeriod.MONTHLY ? "month" : "year" }
          )}
          value={price}
        />
      </Grid.Column>
      <Grid.Column className={"paddingTop"} width={6} floated="right">
        <Header as="h6" className={"noMarginBottom"}>
          {formatMessage({ ...descriptors[PriceSectionType.setTrial] })}
        </Header>
        <div className={"simpleMarginTop"}>
          <Checkbox
            data-elm-id={"priceSectionCheckboxTrial"}
            checked={hasFreeTrial}
            onChange={(_, data) => {
              handleFreeTrialChange(data.checked || false);
            }}
            label={formatMessage(
              { ...descriptors[PriceSectionType.trialDescription] },
              { trialDays: configContext.trialDays }
            )}
          />
        </div>
      </Grid.Column>
    </Grid>
  );
};

export const PriceSection = PriceSectionFC
