import { PlayerPost, socialNames } from "../../../../../../../interfaces";

const useDisplayData = (post: PlayerPost) => {
  const { mediaGroup, createdDate, socialNetworks } = post;

  const failedSocialNetworks = socialNetworks.filter(sn => sn.status === "FAILED");

  const hasPostMessage = mediaGroup?.media?.length && !!mediaGroup?.media![0].uri;

  const socialErrorMessage =
    failedSocialNetworks.length > 0
      ? (failedSocialNetworks[0] && socialNames["_" + failedSocialNetworks[0].socialNetworkId!] + " post failed") || ""
      : "Changes Requested";

  const mediaType = hasPostMessage
    ? mediaGroup?.media![0].thumbnailUri
      ? "IMAGE"
      : mediaGroup?.media![0].category
    : "";

  const mediaUrl = hasPostMessage ? mediaGroup?.media![0].thumbnailUri || mediaGroup?.media![0].uri : "";

  return {
    createdDate,
    hasPostMessage,
    socialNetworks,
    mediaGroup,
    mediaType,
    mediaUrl,
    failedSocialNetworks,
    socialErrorMessage,
  };
};

export default useDisplayData;
