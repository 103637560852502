import * as React from "react";
import { Header, Image } from "semantic-ui-react";

import { Png } from "../../assets";
import { BasicWhiteContainer } from "../../styling/baseStyle";

interface OwnProps {
  isAutoPost?: boolean;
  sendMode: string;
}

const PlayerPostSuccessFC: React.FC<OwnProps> = ({ isAutoPost = true, sendMode }) => {
  return (
    <BasicWhiteContainer>
      <Header as={"h4"} className="centered">
        {isAutoPost
          ? `Congrats! Your post has been ${sendMode === "SCHEDULED" ? "scheduled!" : "published!"}`
          : `Congrats! Your post has been sent to your mobile device!`}
      </Header>
      <Image className={"centered"} src={Png.BrandPostSuccess} size={"medium"} />
    </BasicWhiteContainer>
  );
};

export const PlayerPostSuccess = PlayerPostSuccessFC;
