import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import{ useIntl } from "react-intl";
import { Button, Message } from "semantic-ui-react";
import _, { get } from "lodash";

import { SideBar, TitleSection, LibraryListContent, ButtonLink } from "../../../components";
import * as DTO from "../../../interfaces";
import { AppContext } from "../../../providers";
import { coachWithWritePermission, URLS } from "../../../utils";
import { CoachSignUpStep, LoginStepServices, PromptServices } from "../../../services";

import { UploadModal } from "./components/UploadModal";

import { WhiteContainer, ContentGrid, SocialGrid } from "../../../styling/baseStyle";
import {
  RowContainer,
  LoadingSection,
  PromptUploadingSection,
  CompleteIcon,
  PromptLoader,
  LoadingSectionTitle,
  PromptTitle,
  ErrorIcon,
} from "./styled";

import { LibraryType, descriptors } from "./descriptors";
import "./styles.scss";
import { ConfigContext } from "../../../contexts/appContexts";
import { WebComponents } from "../../../interfaces";
import { getDownloadURL } from "firebase/storage";

type Props = RouteComponentProps;

const LibraryFC: React.FC<Props> = ({ history }) => {
  const { formatMessage } = useIntl();
  const { userContext, setUserContext } = React.useContext(AppContext);
  const coachId = get(userContext, "coach.id", undefined);
  const writePermission = coachWithWritePermission(WebComponents.LIBRARY, userContext);
  const writeCampaignPermission = coachWithWritePermission(WebComponents.CAMPAIGNS, userContext);

  const [displayLoadingSection, setDisplayLoadingSection] = React.useState<boolean>(true);
  const [promptsBulkDic, setPromptsBulkDic] = React.useState<
    | {
        [key: string]: DTO.PromptPreview;
      }
    | undefined
  >(undefined);

  const currentStep = CoachSignUpStep.COACH_PROMPTS;

  const [isEmpty, setIsEmpty] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [uploadEnable, setUploadEnable] = React.useState<boolean>(true);

  const [uploading, setUploading] = React.useState<boolean>(false);
  const [bulkRequest, setBulkRequest] = React.useState<DTO.PromptBulkRequest | undefined>(undefined);

  const [welcomePrompt, setWelcomePrompt] = React.useState<DTO.Prompt | undefined>(undefined);
  const [loadingWelcomeInfo, setLoadingWelcomeInfo] = React.useState<boolean>(true);

  const {
    randomPrompts: { welcomePromptRequired },
  } = React.useContext(ConfigContext);

  React.useEffect(() => {
    const checkWelcomePrompts = async () => {
      if (coachId) {
        const welcome = await PromptServices.getWelcomePrompt(coachId);
        setWelcomePrompt(welcome);
        setLoadingWelcomeInfo(false);
      }
    };
    checkWelcomePrompts();
  }, [coachId]);

  React.useEffect(() => {
    if (welcomePrompt || !welcomePromptRequired) {
      updateCoachStep();
    }
  }, [welcomePrompt]);

  React.useEffect(() => {
    if (!uploadEnable) {
      Object.values(promptsBulkDic!).map(({ uploadTask }) => {
        if (uploadTask) {
          uploadTask.cancel();
        }
      });
      setPromptsBulkDic(undefined);
      setBulkRequest(undefined);
    }
  }, [uploadEnable]);

  React.useEffect(() => {
    if (uploadEnable && bulkRequest) {
      setUploadEnable(false);
      PromptServices.createBulk(bulkRequest).finally(() => {
        setPromptsBulkDic(undefined);
        setRefresh(true);
        setBulkRequest(undefined);
      });
    } else if (!uploadEnable && bulkRequest) {
      setPromptsBulkDic(undefined);
      setRefresh(true);
      setBulkRequest(undefined);
    }
  }, [bulkRequest]);

  React.useEffect(() => {
    if (uploading && uploadEnable) {
      uploadPrompts();
      setUploading(false);
    }
  }, [promptsBulkDic]);

  const updateCoachStep = (): void => {
    if (CoachSignUpStep[userContext!.signUpStep!] < currentStep) {
      LoginStepServices.sendStep(CoachSignUpStep[currentStep]).then(() => {
        setUserContext({
          ...userContext!,
          signUpStep: CoachSignUpStep[currentStep],
        });
      });
    }
  };

  const createCampaign = () => {
    localStorage.removeItem("coachNewCampaignStep");
    localStorage.removeItem("coachCampaign");
    history.push(URLS.coach.newCampaigns);
  };

  const addPromptHandler = () => {
    history.push(URLS.coach.newPrompt, {
      deliveryMode: "LIBRARY",
    });
  };

  const customLinkSection = (): JSX.Element => {
    return (
      <SocialGrid.Column width={9} floated="right">
        {writePermission && (
          <UploadModal
            trigger={
              <SocialGrid.Column width={6} floated="right" className={"buttonRight"}>
                <Button data-elm-id={`libraryCreateUploadFilesBtn`} fluid className={"secondary rounded buttonLeft"}>
                  {formatMessage({ ...descriptors[LibraryType.uploadFilesBtn] })}
                </Button>
              </SocialGrid.Column>
            }
            onUploaded={submitPrompts => {
              setUploadEnable(true);
              setUploading(true);
              setPromptsBulkDic(submitPrompts);
            }}
            modalOpened={() => {
              setRefresh(false);
            }}
            submittingFiles={submitPrompts => {
              if (uploadEnable) {
                setPromptsBulkDic(submitPrompts);
              }
            }}
          />
        )}
        {writeCampaignPermission && (
          <SocialGrid.Column width={6} floated="right" className={"buttonLeft"}>
            <Button
              data-elm-id={`libraryCreateCampaignBtn`}
              fluid
              className={"secondary rounded buttonLeft"}
              onClick={createCampaign}>
              {formatMessage({ ...descriptors[LibraryType.createCampaignBtn] })}
            </Button>
          </SocialGrid.Column>
        )}
      </SocialGrid.Column>
    );
  };

  const uploadPrompts = (): void => {
    if (promptsBulkDic) {
      const uploadTaskPromises = Object.values(promptsBulkDic!).map(({ uploadTask }) => {
        return uploadTask;
      });
      if (uploadTaskPromises) {
        Promise.all(uploadTaskPromises).then(values => {
          const imagesPromises = Object.values(values).map(uploadTask => {
            return  getDownloadURL(uploadTask!.ref)
          });

          Promise.all(imagesPromises).then(values2 => {
            const prompts: DTO.PromptRequest[] = [];
            if (promptsBulkDic) {
              Object.values(promptsBulkDic!).map(
                async ({ promptId, categoryIds, title, message, richMessage, status, deliveryMode, post }, index) => {
                  const url = values2[index];

                  const newPost: DTO.MediaGroup = {
                    media: post?.media || [],
                    mediaGroupId: undefined,
                    type: post!.type,
                  };

                  newPost!.media[0]!.uri = url;
                  prompts.push({
                    promptId,
                    coachId,
                    title,
                    message,
                    richMessage,
                    status,
                    deliveryMode,
                    post: newPost,
                    categoryIds,
                  });
                }
              );
              const request: DTO.PromptBulkRequest = {
                coachId: coachId!,
                deliveryMode: "LIBRARY",
                promptList: prompts,
              };
              setBulkRequest(request);
            }
          });
        });
      }
    }
  };

  return (
    <WhiteContainer>
      <SideBar history={history} />

      <ContentGrid className={"listManagerContent"} columns={1}>
        <SocialGrid columns={1}>
          <TitleSection
            title={formatMessage({ ...descriptors[LibraryType.title] })}
            ready={true}
            customNextLabel={formatMessage({ ...descriptors[LibraryType.addPromptBtn] })}
            showNextOption={writePermission}
            showLinkOption={writePermission || writeCampaignPermission}
            titleSize={5}
            buttonsSize={11}
            nextButtonSize={5}
            handleNextAction={addPromptHandler}
            customLinkSection={customLinkSection()}
          />
          <RowContainer>
            {!welcomePrompt && welcomePromptRequired && !loadingWelcomeInfo && (
              <Message negative className={"leftMargin"}>
                <Message.Header>{formatMessage({ ...descriptors[LibraryType.welcomePromptRequired] })}</Message.Header>
              </Message>
            )}
            <SocialGrid.Column>
              <LibraryListContent
                data-elm-id={"libraryListContent"}
                reloadPrompts={refresh}
                coachId={coachId!}
                isEmptySection={setIsEmpty}
              />
            </SocialGrid.Column>
          </RowContainer>

          {promptsBulkDic && (
            <LoadingSection>
              <LoadingSectionTitle>
                <Button
                  data-elm-id={`libraryUploadBtn`}
                  basic
                  content={`Uploading ${Object.keys(promptsBulkDic).length} Files`}
                  icon={displayLoadingSection ? "chevron up" : "chevron down"}
                  color={"grey"}
                  className={"buttonNoBorder"}
                  labelPosition={"right"}
                  onClick={() => setDisplayLoadingSection(!displayLoadingSection)}
                />
              </LoadingSectionTitle>
              <ButtonLink
                type={"blueButton"}
                message={descriptors[LibraryType.cancelBtn]}
                onClick={() => setUploadEnable(false)}
              />

              {displayLoadingSection &&
                Object.values(promptsBulkDic).map((prompt, index) => {
                  let icon = <PromptLoader active inline size={"small"} color={"blue"} className={"blueLoader"} />;
                  if (prompt.loadStatus === "SUBMITTED") {
                    icon = <CompleteIcon size="large" name="check circle" />;
                  } else if (prompt.loadStatus === "FAILED") {
                    icon = <ErrorIcon size="large" name="remove circle" />;
                  }
                  return (
                    <PromptUploadingSection key={index} className={"noMargin"}>
                      <PromptTitle width={12}>{prompt.title}</PromptTitle>
                      <PromptUploadingSection.Column width={4}>{icon}</PromptUploadingSection.Column>
                    </PromptUploadingSection>
                  );
                })}
            </LoadingSection>
          )}
        </SocialGrid>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const Library = withRouter(LibraryFC)
