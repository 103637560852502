import * as React from "react";

import * as DTO from "../../../interfaces";
import { CampaignServices, CategoriesServices } from "../../../services";

import { CategorySection } from "../../../components/CategorySection";
import { validateLong } from "../../../utils";

interface OwnProps {
  coachId: string;
  category: DTO.Category;

  showError?: (ErrorMessage: string) => void;
}

type Props = OwnProps;

const CampaignListFC: React.FC<Props> = ({ category, coachId, showError }) => {
  const [campaigns, setCampaigns] = React.useState<DTO.Page<DTO.CampaignResume> | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [categoryName, setCategoryName] = React.useState<string>(category.name);

  React.useEffect(() => {
    if (category && coachId) {
      loadBase();
    }
  }, [coachId, category]);

  async function loadBase() {
    const { categoryId } = category;

    setLoading(true);

    const promptsResponse = await CampaignServices.campaignsByCategory(coachId, categoryId).finally(() => {
      setLoading(false);
    });

    setCampaigns(cpages => {
      return {
        ...cpages!,
        ...promptsResponse,
      };
    });
  }

  const loadMore = React.useCallback(
    async (nextPage: number) => {
      const { categoryId } = category;

      if (
        !loading &&
        ((campaigns && campaigns.totalPages && campaigns.number! < campaigns.totalPages!) || !campaigns)
      ) {
        setLoading(true);

        const promptsResponse = await CampaignServices.campaignsByCategory(coachId, categoryId, nextPage);

        setLoading(false);
        setCampaigns(cpages => {
          return {
            ...cpages,
            ...promptsResponse,
            content: promptsResponse.content,
          };
        });
      }
    },
    [coachId, category, loading]
  );

  const campaignsFiltered = campaigns ? campaigns.content : [];

  return campaigns && campaigns.content.length > 0 ? (
    <CategorySection
      key={category.categoryId}
      name={categoryName}
      category={category}
      campaignList={campaignsFiltered}
      totalElements={campaigns?.totalElements}
      page={campaigns?.number}
      pageable={true}
      firstPage={campaigns?.first}
      latestPage={campaigns?.last}
      onNext={nextPage => loadMore(nextPage)}
      disable={["uncategorized", "unpublished", "pending"].includes(category.categoryId!!)}
      handleCategoryChange={newValue => {
        const newCatName = newValue ? newValue.trim() : newValue;
        if (newValue && validateLong(2, 25, newValue)) {
          CategoriesServices.update(coachId, { ...category, name: newCatName })
            .then(() => {
              setCategoryName(newValue);
            })
            .catch(e => {
              if (showError) {
                if (e.status === 409) {
                  showError("You already have a category named: " + newCatName);
                } else {
                  showError(e.message);
                }
              }
            });
        } else {
          if (showError) {
            alert("Category name should have 2-25 characters.");
          }
        }
      }}
    />
  ) : null;
};

export const CampaignListComponent = CampaignListFC;
