import { defineMessages } from "react-intl";

export const UsernameLinkId = "coachingProfileUsernameLink";

export const FinalMessageId = "coachingProfileFinalMessage";

export enum CoachingProfileType {
  title = "CoachingProfile.title",
  usernameLinkLabel = "CoachingProfile.usernameLinkLabel",
  usernameLinkPlaceholder = "CoachingProfile.usernameLinkPlaceholder",
  finalMessageLabel = "CoachingProfile.finalMessageLabel",
  finalMessagePlaceholder = "CoachingProfile.finalMessagePlaceholder",
  uploadBannerTitle = "CoachingProfile.uploadBannerTitle",
  uploadVideoTitle = "CoachingProfile.uploadVideoTitle",
  uploadBtn = "CoachingProfile.uploadBtn",
  previewBtn = "CoachingProfile.previewBtn",
  unsavedChanges = "CoachingProfile.unsavedChanges",
  unknownError = "CoachingProfile.unknownError",
  usernameLinkDesc = "CoachingProfile.usernameLinkDesc",
  noPreviewWithoutMessage = "CoachingProfileType.noPreviewWithoutMessage",
}

export const en = {
  [CoachingProfileType.title]: "Set up Your Marketing Site",

  [CoachingProfileType.usernameLinkLabel]: "Customize share link",
  [CoachingProfileType.usernameLinkPlaceholder]: "Shared Link",
  [CoachingProfileType.finalMessageLabel]: "Customize your welcome message",
  [CoachingProfileType.finalMessagePlaceholder]: "What are you waiting for?",
  [CoachingProfileType.uploadBannerTitle]: "Add your Banner",
  [CoachingProfileType.uploadVideoTitle]: "Add Video",
  [CoachingProfileType.uploadBtn]: "Browse",
  [CoachingProfileType.previewBtn]: "Web Preview",

  [CoachingProfileType.noPreviewWithoutMessage]: "Cannot preview until you complete your Message to Players.",
  [CoachingProfileType.unsavedChanges]: "Changes must be saved in order to be applied on the landing page.",
  [CoachingProfileType.unknownError]: "Unknown Error.",
  [CoachingProfileType.usernameLinkDesc]:
    "SocialCoach provides you with your very own marketing site. Use this page to send to potential Players to view more about your plan and be able to sign up.\n\n Below you will configure the different aspects of the page including the header image, video message from you, and a text box to talk about your program.",
};

export const descriptors = defineMessages({
  [CoachingProfileType.title]: {
    id: CoachingProfileType.title,
    defaultMessage: en[CoachingProfileType.title],
    description: "Title for coaching page profile",
  },
  [CoachingProfileType.usernameLinkLabel]: {
    id: CoachingProfileType.usernameLinkLabel,
    defaultMessage: en[CoachingProfileType.usernameLinkLabel],
    description: "Label for shared link input",
  },
  [CoachingProfileType.usernameLinkPlaceholder]: {
    id: CoachingProfileType.usernameLinkPlaceholder,
    defaultMessage: en[CoachingProfileType.usernameLinkPlaceholder],
    description: "Placeholder for shared link input",
  },
  [CoachingProfileType.finalMessageLabel]: {
    id: CoachingProfileType.finalMessageLabel,
    defaultMessage: en[CoachingProfileType.finalMessageLabel],
    description: "Label for final message input",
  },
  [CoachingProfileType.finalMessagePlaceholder]: {
    id: CoachingProfileType.finalMessagePlaceholder,
    defaultMessage: en[CoachingProfileType.finalMessagePlaceholder],
    description: "Placeholder for final message input",
  },
  [CoachingProfileType.uploadBannerTitle]: {
    id: CoachingProfileType.uploadBannerTitle,
    defaultMessage: en[CoachingProfileType.uploadBannerTitle],
    description: "Upload banner button title",
  },
  [CoachingProfileType.uploadVideoTitle]: {
    id: CoachingProfileType.uploadVideoTitle,
    defaultMessage: en[CoachingProfileType.uploadVideoTitle],
    description: "Upload video title",
  },
  [CoachingProfileType.uploadBtn]: {
    id: CoachingProfileType.uploadBtn,
    defaultMessage: en[CoachingProfileType.uploadBtn],
    description: "Upload video button",
  },
  [CoachingProfileType.previewBtn]: {
    id: CoachingProfileType.previewBtn,
    defaultMessage: en[CoachingProfileType.previewBtn],
    description: "Preview Button",
  },
  [CoachingProfileType.unsavedChanges]: {
    id: CoachingProfileType.unsavedChanges,
    defaultMessage: en[CoachingProfileType.unsavedChanges],
    description: "Unsaved changes error message",
  },
  [CoachingProfileType.unknownError]: {
    id: CoachingProfileType.unknownError,
    defaultMessage: en[CoachingProfileType.unknownError],
    description: "Default message for unknown error",
  },
  [CoachingProfileType.usernameLinkDesc]: {
    id: CoachingProfileType.usernameLinkDesc,
    defaultMessage: en[CoachingProfileType.usernameLinkDesc],
    description: "",
  },
  [CoachingProfileType.noPreviewWithoutMessage]: {
    id: CoachingProfileType.noPreviewWithoutMessage,
    defaultMessage: en[CoachingProfileType.noPreviewWithoutMessage],
    description: "",
  },
});
