import * as React from "react";
import { useIntl } from "react-intl";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Grid, Message, Transition, Header } from "semantic-ui-react";
import { Formik } from "formik";
import { get, isEqual, omit, isEmpty, values as getValues } from "lodash";

import { CoachSignUpStep, CoachingProfileService } from "../../../services";

import { TitleSection } from "../../TitleSection";
import { PreviewLanding } from "../PreviewLandingPage";
import { LandingPageForm } from "./components/LandingPageForm";

import { CoachingProfileRequestType, CoachingProfileData } from "../../../interfaces/dtos";
import { AppContext } from "../../../providers";
import { finishStep, URLS, saveImageToFirebase } from "../../../utils";

import { CoachingProfileType, descriptors, UsernameLinkId, FinalMessageId } from "./descriptors";

import { ConfigContext } from "../../../contexts/appContexts";
import { validationCoachingPageSchema } from "./validations";
import "./styles.scss";

const MinValueUsernameLink = 1000;
const MaxValueUsernameLink = 9999;

interface OwnProps {
  coachId: string;
  firstName: string;
  lastName: string;
}

type Props = OwnProps & RouteComponentProps;

const MarketingPageFC: React.FC<Props> = ({ coachId, firstName, lastName, history }) => {
  const [bannerUrl, setBannerUrl] = React.useState<string | undefined>("");
  const [videoUrl, setVideoUrl] = React.useState<string | undefined>("");
  const [sharedLinkId, setSharedLinkId] = React.useState<string>("");
  const [salesMessage, setSalesMessage] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [videoLoading, setVideoLoading] = React.useState<boolean>(false);
  const [unsaved, setUnsaved] = React.useState<boolean>(true);
  const [saved, setSaved] = React.useState<boolean>(false);

  const [unknownError, setUnknownError] = React.useState<boolean>(false);
  const [submitError, setSubmitError] = React.useState<string>("");

  const { firebase: firebaseConfig } = React.useContext(ConfigContext);

  React.useEffect(() => {
    const getInitialData = async () => {
      try {
        setLoading(true);
        const preview = await CoachingProfileService.getPreview(coachId);
        setBannerUrl(preview.bannerUrl);
        setVideoUrl(preview.videoUrl);
        setSalesMessage(preview.salesMessage);
        setSharedLinkId(preview.sharedLinkId);

        try {
          const final = await CoachingProfileService.getFinal(coachId);
          if (preview && final) {
            const omttedValues = ["id"];
            const chackeUnsaved = isEqual(omit(preview, omttedValues), omit(final, omttedValues));
            setUnsaved(!chackeUnsaved);
          }
        } catch (e: any) {
          if (e.status !== 404) {
            setUnknownError(true);
          }
        }
      } catch (e: any) {
        if (e.status !== 404) {
          setUnknownError(true);
        }
      } finally {
        setLoading(false);
      }
    };

    getInitialData();
  }, [coachId]);

  const { userContext, setUserContext } = React.useContext(AppContext);

  const getRandomNumber = (min: number, max: number) => Math.ceil(Math.random() * (max - min) + min);

  const getDefaultUsernameLink = (localFirstName: string, localLastName: string) => {
    const name = `${localFirstName}${localLastName}`.replace(/\s+/g, "").toLowerCase();
    return `${name}${getRandomNumber(MinValueUsernameLink, MaxValueUsernameLink)}`;
  };

  const onSubmitPreview = (localCoachId: string, body: CoachingProfileData) => {
    return CoachingProfileService.savePreview(localCoachId, body);
  };

  const onSubmitFinal = (localCoachId: string, body: CoachingProfileData) => {
    return CoachingProfileService.saveFinal(localCoachId, body);
  };

  const { formatMessage } = useIntl();

  let internalSubmitForm: any;

  const onSubmit = async (type: CoachingProfileRequestType, data: any) => {
    try {
      const usernameLink = get(data, UsernameLinkId);
      const localSalesMessage = get(data, FinalMessageId);

      const landingPage = {
        sharedLinkId: usernameLink,
        salesMessage: localSalesMessage,
      };

      if (videoUrl && videoUrl !== "") {
        landingPage["videoUrl"] = videoUrl;
      }
      if (bannerUrl && bannerUrl !== "") {
        landingPage["bannerUrl"] = bannerUrl;
      }

      if (type === CoachingProfileRequestType.PREVIEW) {
        await onSubmitPreview(coachId, landingPage);
      } else {
        await onSubmitFinal(coachId, landingPage);
        setUnsaved(false);
      }
    } catch (e: any) {
      if (e.status === 412 && e.message) {
        setSubmitError(e.message);
      }
      throw e;
    }
  };
  return (
    <Grid columns={1}>
      <TitleSection
        title={formatMessage({ ...descriptors[CoachingProfileType.title] })}
        subtitle={formatMessage({ ...descriptors[CoachingProfileType.usernameLinkDesc] })}
        ready={finishStep(userContext?.signUpStep)}
        currentStep={CoachSignUpStep.COACH_COACHING_PROFILE}
        disableButtons={videoLoading}
        nextButtonSize={10}
        handleNextAction={async () => {
          if (internalSubmitForm) {
            await internalSubmitForm();
          }
        }}
      />
      {!loading && (
        <Grid.Row className={"marketingSiteRowContainer coachBaseContainer"}>
          {saved && (
            <Transition.Group as={Header} duration={300} animation={"drop"} size="tiny" verticalAlign="middle">
              <Message info content={"Saved!"} />
            </Transition.Group>
          )}
          <Formik
            validateOnBlur={false}
            validateOnChange={true}
            initialValues={{
              [UsernameLinkId]: sharedLinkId || getDefaultUsernameLink(firstName, lastName),
              [FinalMessageId]: salesMessage,
            }}
            validationSchema={validationCoachingPageSchema}
            onSubmit={async (data, actions) => {
              actions.setSubmitting(true);
              try {
                await onSubmit(CoachingProfileRequestType.FINAL, data);
                if (CoachSignUpStep[userContext!.signUpStep!] < CoachSignUpStep.COACH_COACHING_PROFILE) {
                  setUserContext({
                    ...userContext!,
                    signUpStep: CoachSignUpStep[CoachSignUpStep.COACH_COACHING_PROFILE],
                  });
                } else {
                  setSaved(true);
                  setTimeout(() => {
                    setSaved(false);
                  }, 1000);
                }
                if (!finishStep(userContext?.signUpStep)) {
                  history.push(URLS.coach.library);
                }
              } catch (e) {
                throw e;
              } finally {
                actions.setSubmitting(false);
              }
            }}>
            {({
              values,
              errors,
              handleChange,
              setFieldValue,
              submitForm,
              handleSubmit,
              isSubmitting,
              setSubmitting,
              validateForm,
            }) => {
              internalSubmitForm = submitForm;
              const hasErrors = !isEmpty(errors) || unsaved || unknownError;
              const initialMessagevalidation = isEmpty(values[FinalMessageId]) && isEmpty(salesMessage);

              const uploadVideo = (url: any) => {
                setVideoUrl(url);
                setVideoLoading(false);
              };

              const uploadImage = async (file: any, blob: Blob) => {
                setSubmitting(true);
                const url = await saveImageToFirebase(firebaseConfig, coachId, blob, "banner");
                setBannerUrl(url);
                setSubmitting(false);
                return "";
              };

              return (
                <Grid columns={2}>
                  <Grid.Row>
                    <Grid.Column>
                      {unsaved && (
                        <Message
                          error
                          content={formatMessage({
                            ...descriptors[CoachingProfileType.unsavedChanges],
                          })}
                        />
                      )}
                      {unknownError && (
                        <Message
                          error
                          content={formatMessage({
                            ...descriptors[CoachingProfileType.unknownError],
                          })}
                        />
                      )}
                      {submitError && <Message error content={submitError} />}
                      {hasErrors && getValues(errors).map(error => <Message key={error} error content={error} />)}
                      {!hasErrors && initialMessagevalidation && (
                        <Message
                          error
                          content={formatMessage({ ...descriptors[CoachingProfileType.noPreviewWithoutMessage] })}
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={9}>
                      <LandingPageForm
                        isSubmitting={isSubmitting}
                        handleSubmit={handleSubmit}
                        values={values}
                        handleChange={handleChange}
                        uploadImage={uploadImage}
                        uploadVideo={uploadVideo}
                        videoUrl={videoUrl}
                        bannerUrl={bannerUrl}
                        setFieldValue={setFieldValue}
                        handleLoadingVideo={setVideoLoading}
                      />
                    </Grid.Column>
                    <Grid.Column width={7}>
                      <PreviewLanding
                        firstName={userContext ? userContext!.profile.firstName : ""}
                        lastName={userContext ? userContext!.profile.lastName : ""}
                        socialNetworks={userContext ? userContext!.profile.socialNetworks : []}
                        title={userContext?.coach?.title}
                        photoURL={userContext?.profile.photoUrl}
                        bannerURL={bannerUrl}
                        salesMessage={values[FinalMessageId]}
                        disabled={!isEmpty(errors) || isEmpty(values[FinalMessageId])}
                        handleWebPreview={async e => {
                          setSubmitting(true);
                          try {
                            await onSubmit(CoachingProfileRequestType.PREVIEW, values);
                            setSubmitting(false);
                            window.open(`/preview-landing/${coachId}`);
                          } catch (e) {
                            setSubmitting(false);
                          }
                        }}
                        buttonName={formatMessage({
                          ...descriptors[CoachingProfileType.previewBtn],
                        })}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              );
            }}
          </Formik>
        </Grid.Row>
      )}
    </Grid>
  );
};

export const MarketingPageForm = withRouter(MarketingPageFC)
