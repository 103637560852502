import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import{ useIntl } from "react-intl";

import { Table, Message, Loader } from "semantic-ui-react";
import * as DTO from "../../interfaces/dtos";
import { descriptors, PlayersType } from "./descriptors";
import { URLS } from "../../utils";

import { PlayerCell } from "./components/PlayerCell";
import "./styles.scss";
import { useSocialNetworks } from"../../reducers";

interface OwnProps {
  playersList?: DTO.SubscribedPlayerSearchResponse[];
  loading: boolean;
  isBasic?: boolean;
  // To indicate that is a canceled section
  isCancelled?: boolean;
  scPlusTier?: boolean;

  handleCancelAction?: () => void;
}

type Props = OwnProps   & RouteComponentProps;

const PlayersFC: React.FC<Props> = ({
  playersList,
  loading = true,
 
  isBasic = true,
  isCancelled = false,
  scPlusTier = false,
  history,
}) => {
  const { formatMessage } = useIntl();
  const emptySection = loading ? (
    <Loader active size="large" />
  ) : (
    <Message icon="search" header="No results" content="There are no players in this section" size="large" />
  );

  let widthProps = {};
  if (!isBasic) {
    widthProps = {
      width: 3,
    };
  }
  const toDetail = (playerId: string | number): void => {
    const playerUrl = URLS.coach.editPlayer.replace(":playerId", `${playerId!}`);
    history.push(playerUrl);
  };

  const { sns } = useSocialNetworks(undefined, false);

  return (
    <div className="coachListContainer">
      {playersList && playersList.length > 0 ? (
        <Table striped compact="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1} />
              <Table.HeaderCell {...widthProps}>
                {formatMessage({ ...descriptors[PlayersType.nameHeader] })}
              </Table.HeaderCell>
              <Table.HeaderCell width={isBasic ? 2 : 1}>
                {formatMessage({ ...descriptors[PlayersType[isBasic ? "phoneHeader" : "branchHeader"]] })}
              </Table.HeaderCell>

              {isBasic ? (
                <Table.HeaderCell width={1}>
                  Subscription <br /> Status
                </Table.HeaderCell>
              ) : (
                <Table.HeaderCell width={1}>
                  {formatMessage({ ...descriptors[PlayersType.regionHeader] })}
                </Table.HeaderCell>
              )}
              <Table.HeaderCell width={isCancelled ? 2 : 1}>Sign Up Date</Table.HeaderCell>
              {isCancelled && <Table.HeaderCell>Canceled Date</Table.HeaderCell>}
              {isCancelled && <Table.HeaderCell /> /* Reactivate button */}
              {scPlusTier && <Table.HeaderCell width={1}>Subscription Type</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {playersList.map((playerResponse: DTO.SubscribedPlayerSearchResponse) => (
              <PlayerCell
                keyId={playerResponse.playerAccount.playerId}
                isBasic={isBasic}
                isCancelled={isCancelled}
                player={playerResponse}
                onClick={toDetail}
                allSocialNetworks={sns}
                scPlusTier={scPlusTier}
              />
            ))}
          </Table.Body>
        </Table>
      ) : (
        emptySection
      )}
    </div>
  );
};

export const PlayersList = withRouter(PlayersFC)
