import * as React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { PromptCampaign, CampaignDeliveryMode, snKey } from "../../interfaces";
import { DraggablePromptItem } from "../DraggablePromptItem";

import "./styles.scss";

interface DraggablePromptContainerProps {
  onReordered?: any;
  prompts: PromptCampaign[];
  isAutoPost: boolean;
  campaignDeliveryMode: CampaignDeliveryMode;
  socialNetworks: string[];
  /**
   * Id of prompts that were included previously
   */
  initialPromptsIds?: string[];
  editable?: boolean;
  fromEdit?: boolean;
  handleSetFieldValue: (field: string, value: any) => void;
  onDragEnd: (result: any) => void;
}

const DraggablePromptContainerFC: React.FC<DraggablePromptContainerProps> = ({
  prompts,
  isAutoPost,
  campaignDeliveryMode,
  fromEdit = false,
  editable = true,
  initialPromptsIds = [],
  socialNetworks,
  onDragEnd,
  handleSetFieldValue,
}: DraggablePromptContainerProps) => {
  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable key={"droppable_prompts_"} droppableId={"droppable_campaign_prompts_ids"} direction={"vertical"}>
          {(provided: any) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {prompts.length > 0 &&
                prompts.map((item: PromptCampaign, i: number) => {
                  const promptId = fromEdit ? `${item.prompt?.promptId}` : `${item.promptId}`;
                  return (
                    <DraggablePromptItem
                      src={item}
                      key={"dragglable_prompt_item_" + promptId}
                      id={Number(promptId)}
                      index={i}
                      campaignDeliveryMode={campaignDeliveryMode}
                      socialNetworks={socialNetworks[`${promptId}${snKey}`]}
                      handleSetFieldValue={handleSetFieldValue}
                      isAutoPost={isAutoPost}
                      editable={editable || !initialPromptsIds.includes(`${item.prompt!.promptId}`)}
                    />
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export const DraggablePromptContainer = DraggablePromptContainerFC;
