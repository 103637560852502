import * as React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { ResendType, descriptors } from "./descriptors";
import {
  Form
} from "semantic-ui-react";
import {
  BasicFormProps,
  VerificationActions
} from "../../interfaces/customTypes";
import "./styles.scss";
import { FloatedCard } from "../../containers";

interface OwnProps extends BasicFormProps {
  type: VerificationActions;
}

type Props = OwnProps;
/**
 * Represents a resend verification screen.
 */
const ResendVerificationEmailFC: React.FC<Props> = ({
  messages,
  handleChange,
  handleSubmit,
  isSubmitting = false,
  type
}) => {
  const { formatMessage } = useIntl();
  const content = (
    <React.Fragment>
      <Form.Input
        fluid
        data-elm-id={"ResendVerificationInputEmail"}
        type="email"
        name="email"
        icon="mail outline"
        iconPosition="left"
        placeholder={formatMessage({ ...descriptors[ResendType.email] })}
        onChange={handleChange}
        disabled={isSubmitting}
      />
      <Form.Button
        size="large"
        type="submit"
        disabled={isSubmitting}
        onClick={handleSubmit}
        className={"centered"}
      >
        <FormattedMessage {...descriptors[ResendType.submit]} />
      </Form.Button>
    </React.Fragment>
  );

  return (
    <FloatedCard
      title={formatMessage({ ...descriptors[ResendType[`title${type}`]] })}
      subtitle={""}
      content={content}
      messages={messages}
      isSubmitting={isSubmitting}
      size={"bigCentered"}
    />
  );
};

export const ResendVerificationEmail = ResendVerificationEmailFC;
