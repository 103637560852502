import * as Yup from "yup";
import { phoneRegExp, Rules, validAge } from "../../../utils";

export const validationProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required("First Name is required"),
  lastName: Yup.string()
    .trim()
    .required("Last Name is required"),
  birthday: Yup.date()
    .max(validAge(new Date(), -13), "The minimum age for using SocialCoach is 13 years old")
    .required("Birthday is required"),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required"),
  title: Yup.string()
    .trim()
    .required("Title is required2")
    .max(Rules.MAX_PROFILE_TITLE, "The maximum length for Title is 50 characters."),
  contactPhone: Yup.string().matches(phoneRegExp, "Contact Phone number is not valid."),
  contactEmail: Yup.string().email("Contact email is not valid."),
  // ownersName: Yup.string().trim(),
  // bank: Yup.string().trim(),
  // accountNumber: Yup.string()
  //   .trim()
  //   .max(20, "Account number max 20 characters long"),
  // routingNumber: Yup.string()
  //   .trim()
  //   .length(9, "Routing number must be 9 characters long"),
  // accountType: Yup.string().trim(),
  // sn: Yup.array().min(1, "You must be connected at least to one social network"),
});
