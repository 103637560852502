import * as Yup from "yup";
import { Rules } from "../../../../../utils/rules";

export const validationSchema = Yup.object().shape(
  {
    title: Yup.string()
      .trim()
      .required("Title is required")
      .max(Rules.MAX_TITLE, "Title is too long"),
    withMessage: Yup.string().when("withMediaForPost", {
      is: (a: number) => a == 0,
      then: () =>
        Yup.string()
          .trim()
          .max(Rules.MAX_CONTENT, "Prompt caption is too long")
          .required("Caption, Hashtags, or Image/Video for Post is required"),
      otherwise: () => Yup.string().trim().max(Rules.MAX_CONTENT, "Prompt caption is too long")   
    }),
    withMediaForPost: Yup.number().when("withMessage", {
      is: (m?: string) => m === "" || m === undefined,
      then: () => Yup.number().required(
        "Caption, Hashtags, or Image/Video for Post is required"
      ),
      otherwise: () => Yup.number(),
    }),
  },
  [["withMessage", "withMediaForPost"]]
);
