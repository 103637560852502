import * as React from "react";
import { Icon } from "semantic-ui-react";

import { ThumbnailPreview, StickerCardStyle, DeleteButton, StickerGridStyle } from "./styled";
import "./styles.scss";

interface OwnProps {
  uri: string;
  width?: number;
  height?: number;
  onDelete: () => void;
}

const StickerCardFC: React.FC<OwnProps> = ({ uri, onDelete, width = 100, height = 100 }: OwnProps) => {
  const [thumbnail, setThumbnail] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (uri) {
      setThumbnail(uri);
    }
  }, [uri]);

  return (
    <StickerGridStyle>
      <StickerCardStyle className={"StickerCard"}>
        <ThumbnailPreview width={4} thumbnailurl={thumbnail} imagewidth={width} imageheight={height} />
        <DeleteButton onClick={onDelete}>
          <DeleteButton.Content visible>
            <Icon name="close" />
          </DeleteButton.Content>
        </DeleteButton>
      </StickerCardStyle>
    </StickerGridStyle>
  );
};

export const TinyStickerCard = StickerCardFC;
