import { defineMessages } from "react-intl";

export enum ComplianceType {
  title = "ComplianceType.title",
  flaggedTitle = "ComplianceType.flaggedTitle",
  flaggedTooltip = "ComplianceType.flaggedTooltip",
  blockedTitle = "ComplianceType.blockedTitle",
  blockedTooltip = "ComplianceType.blockedTooltip",
  updateBtn = "ComplianceType.updateBtn",

  maximunKeyphrasesError = "ComplianceType.nameHeader",
  phoneHeader = "ComplianceType.phoneHeader",
  tagsHeader = "ComplianceType.tagsHeader",
  lastPaymentHeader = "ComplianceType.lastPaymentHeader",
  activeFrom = "ComplianceType.activeFrom",
}

export const en = {
  [ComplianceType.title]: "Compliance Manager",
  [ComplianceType.flaggedTitle]: "Flagged",
  [ComplianceType.flaggedTooltip]: "Posts containing words or phrases you have flagged will be allowed, but marked for your review. Add words and phrases to the flag list below.",
  [ComplianceType.blockedTitle]: "Blocked",
  [ComplianceType.blockedTooltip]: "Posts containing words or phrases you have blocked will NOT be allowed. Add words and phrases to the block list below.",
  [ComplianceType.updateBtn]: "Update",

  [ComplianceType.maximunKeyphrasesError]: "Only 200 keywords or phrases allowed",
  [ComplianceType.phoneHeader]: "Phone ",
  [ComplianceType.tagsHeader]: "Tags",
  [ComplianceType.lastPaymentHeader]: "Last Payment",
  [ComplianceType.activeFrom]: "Active from",
};

export const descriptors = defineMessages({
  [ComplianceType.title]: {
    id: ComplianceType.title,
    defaultMessage: en[ComplianceType.title],
    description: "-",
  },
  [ComplianceType.flaggedTitle]: {
    id: ComplianceType.flaggedTitle,
    defaultMessage: en[ComplianceType.flaggedTitle],
    description: "-",
  },
  [ComplianceType.flaggedTooltip]: {
    id: ComplianceType.flaggedTooltip,
    defaultMessage: en[ComplianceType.flaggedTooltip],
    description: "-",
  },
  [ComplianceType.blockedTitle]: {
    id: ComplianceType.blockedTitle,
    defaultMessage: en[ComplianceType.blockedTitle],
    description: "-",
  },
  [ComplianceType.blockedTooltip]: {
    id: ComplianceType.blockedTooltip,
    defaultMessage: en[ComplianceType.blockedTooltip],
    description: "-",
  },
  [ComplianceType.updateBtn]: {
    id: ComplianceType.updateBtn,
    defaultMessage: en[ComplianceType.updateBtn],
    description: "-",
  },
  [ComplianceType.maximunKeyphrasesError]: {
    id: ComplianceType.maximunKeyphrasesError,
    defaultMessage: en[ComplianceType.maximunKeyphrasesError],
    description: "-",
  },
  [ComplianceType.phoneHeader]: {
    id: ComplianceType.phoneHeader,
    defaultMessage: en[ComplianceType.phoneHeader],
    description: "-",
  },
  [ComplianceType.tagsHeader]: {
    id: ComplianceType.tagsHeader,
    defaultMessage: en[ComplianceType.tagsHeader],
    description: "-",
  },
  [ComplianceType.lastPaymentHeader]: {
    id: ComplianceType.lastPaymentHeader,
    defaultMessage: en[ComplianceType.lastPaymentHeader],
    description: "-",
  },
  [ComplianceType.activeFrom]: {
    id: ComplianceType.activeFrom,
    defaultMessage: en[ComplianceType.activeFrom],
    description: "-",
  },
});
