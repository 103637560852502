import React, { useState, useEffect } from "react";
import { Header, Grid, Radio, Icon } from "semantic-ui-react";

import "./styles.scss";

interface BrandOption {
  id: string;
  label: string;
  index: number;
  terms: string;
  link: string;
  linkLabel: string;
  infoBubble: string;
}
const options: BrandOption[] = [
  {
    id: "FIRST_PARTY",
    label: "Myself/my brand",
    index: 0,
    terms: "By posting, you agree to Tiktok's ",
    linkLabel: "Music Usage Confirmation.",
    link: "https://www.tiktok.com/legal/page/global/music-usage-confirmation/en",
    infoBubble:
      'Your image/video will be labeled "Promotional content".\nThis cannot be changed once your image/video is posted.',
  },
  {
    id: "THIRD_PARTY",
    label: "A third party",
    index: 1,
    terms: "By posting, you agree to Tiktok's ",
    linkLabel: "Branded Content Policy and Music Usage Confirmation.",
    link: "https://www.tiktok.com/legal/page/global/bc-policy/en",
    infoBubble:
      'Your image/video will be labeled "Paid partnership".\nThis cannot be changed once your image/video is posted.',
  },
  {
    id: "BOTH",
    label: "Both myself & a third party",
    index: 2,
    terms: "By posting, you agree to Tiktok's ",
    linkLabel: "Branded Content Policy and Music Usage Confirmation.",
    link: "https://www.tiktok.com/legal/page/global/bc-policy/en",
    infoBubble:
      'Your image/video will be labeled "Paid partnership".\nThis cannot be changed once your image/video is posted.',
  },
];

interface OwnProps {
  disabled: boolean;
  defaultBrandedContentDisclosure: boolean;
  defaultBrandOption?: string;
  updateBrand: (selectedBrand: string) => void;
  updateBrandConfig: (active: boolean) => void;
}

type Props = OwnProps;
const baseDataElm = "TiktokBrandedSection";
/**
 * TiktokPreferences Components
 */
const TiktokBrandedClass: React.FC<Props> = ({
  disabled,
  defaultBrandedContentDisclosure,
  defaultBrandOption,
  updateBrand,
  updateBrandConfig,
}) => {
  const [configToggleValue, setConfigToggle] = useState<boolean>(defaultBrandedContentDisclosure);
  const [selectedOption, setSelectedOption] = useState<BrandOption | undefined>(
    defaultBrandOption ? options.filter(option => option.id === defaultBrandOption)[0] : undefined
  );

  useEffect(() => {
    if (configToggleValue === false) {
      setSelectedOption(undefined);
    }
    updateBrandConfig(configToggleValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configToggleValue]);

  useEffect(() => {
    setConfigToggle(defaultBrandedContentDisclosure);
  }, [defaultBrandedContentDisclosure]);

  useEffect(() => {
    if (defaultBrandOption) {
      setSelectedOption(options.filter(option => option.id === defaultBrandOption)[0]);
    }
  }, [defaultBrandOption]);

  return (
    <Grid.Row>
      <Grid className="brandToggleGrid">
        <Grid.Row>
          <Header as="h5">Branded content disclosure</Header>
          <Grid className={"brandToggleInternalGrid"}>
            <Grid.Row>
              <Radio
                toggle
                key={"brandDisclousure"}
                label={"Content posted to Tiktok through SocialCoach is promoting one of the \nfollowing"}
                className={"settingsLevelLabel"}
                onChange={(e: any, data: any) => {
                  // option.updateAction(data.checked);
                  setConfigToggle(data.checked);
                }}
                disabled={disabled}
                name={"brandDisclousure"}
                checked={configToggleValue}
              />
            </Grid.Row>

            {options.map(option => {
              return (
                <Grid.Row key={"row_branded_option" + option.id} className="floatedRight toggleRow">
                  <div key={"branded_option" + option.id} className="toggleRight">
                    {/* <label className="toggleLabel">{option.label}</label> */}
                    <Radio
                      data-elm-id={`${baseDataElm}_brand_${option.id}`}
                      className={"blackRadio secondary rounded"}
                      id={option.id}
                      name={option.id}
                      onChange={(e: any, data: any) => {
                        setSelectedOption(option);
                        updateBrand(option.id);
                        //  handleSetFieldValue!("isAutoPost", false);
                      }}
                      checked={selectedOption?.id === option.id}
                      disabled={!configToggleValue}
                      label={option.label}
                    />
                  </div>
                </Grid.Row>
              );
            })}
          </Grid>
        </Grid.Row>

        <Grid.Row>
          {selectedOption && (
            <Grid>
              <Grid.Row className="infoSection">
                <Grid>
                  <Grid.Column width={1}>
                    <Icon className={"alertIcon"} name="exclamation circle" />
                  </Grid.Column>

                  <Grid.Column width={15}>
                    <Header as="h6">{selectedOption?.infoBubble}</Header>
                  </Grid.Column>
                </Grid>
              </Grid.Row>

              <Grid.Row className="termsSection">
                <p>
                  {selectedOption?.infoBubble}
                  <a className={"termsLink"} href={selectedOption.link} data-elm-id="brandTerms" target="_blank">
                    {selectedOption.linkLabel}
                  </a>
                </p>
              </Grid.Row>
            </Grid>
          )}
        </Grid.Row>
      </Grid>
    </Grid.Row>
  );
};

export const TiktokBrandedSection = TiktokBrandedClass;
