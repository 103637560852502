import * as React from "react";
import { Grid, Button, Header } from "semantic-ui-react";

import * as DTO from "../../../../../../../interfaces";

import { PromptSelectableCard } from "../../../../../../../components";

import "./styles.scss";

interface OwnProps {
  promptList: DTO.Prompt[];
  name: string;
  category?: DTO.Category;

  page?: number;
  pageable?: boolean;
  latestPage?: boolean;
  firstPage?: boolean;

  totalElements?: number;
  campaignPrompts?: DTO.SelectedPrompts;

  onNext?: (nextPage: number) => void;
  onAddPrompt: (prompt: DTO.Prompt) => void;
  onRemovePrompt: (prompt: DTO.Prompt) => void;
}

type Props = OwnProps;

const CategorySectionFC: React.FC<Props> = ({
  category,
  promptList,
  name,
  page = 0,
  campaignPrompts = {},
  pageable = false,
  firstPage = true,
  latestPage = false,
  totalElements = 0,
  onNext,
  onAddPrompt,
  onRemovePrompt,
}) => {
  const [display, setDisplay] = React.useState<boolean>(true);

  const [selectedPrompts, setSelectedPrompts] = React.useState<DTO.SelectedPrompts>(campaignPrompts);
  const [selectedPromptsCounter, setSelectedPromptsCounter] = React.useState<number>(0);

  const actionTitle = (): string => {
    if (!display) {
      return "Collapsed (" + totalElements + ")";
    }
    return `Showing ${page * 4} - ${page * 4 + promptList.length} of  ${totalElements}`;
  };

  const onNextHandler = () => {
    if (onNext) {
      onNext(page + 1);
    }
  };
  const onPreviousHandler = () => {
    if (onNext) {
      onNext(page - 1);
    }
  };

  const selectedValidation = (categoryIds: string[]): boolean => {
    if (category && category.categoryId !== "uncategorized") {
      return categoryIds.includes(category!.categoryId!);
    }

    return categoryIds.length === 0;
  };

  React.useEffect(() => {
    const globalCounter = Object.values(campaignPrompts).filter(info => selectedValidation(info.categoryIds)).length;
    if (globalCounter > 0 || selectedPromptsCounter > 0) {
      setSelectedPrompts(() => {
        return { ...campaignPrompts };
      });
    }
  }, [campaignPrompts]);

  React.useEffect(() => {
    const globalCounter = Object.values(campaignPrompts).filter(info => selectedValidation(info.categoryIds)).length;
    setSelectedPromptsCounter(globalCounter);
  }, [selectedPrompts]);

  const onSelectPrompt = (prompt: DTO.Prompt, checked: boolean): void => {
    const { promptId, categories, title, post } = prompt;

    if (!checked) {
      onRemovePrompt(prompt);
    } else {
      onAddPrompt(prompt);
    }
    setSelectedPrompts(selected => {
      let newSelectedPromps = selected;
      if (!checked) {
        delete newSelectedPromps[promptId!];
      } else {
        newSelectedPromps = {
          ...newSelectedPromps,
          [promptId!]: {
            categoryIds: categories ? categories!.map(cat => cat.categoryId!) : [],
            title,
            post,
          },
        };
      }
      return { ...newSelectedPromps };
    });
  };

  const promptSelectedMessage = selectedPromptsCounter > 1 ? "Prompts Selected" : "Prompt Selected";

  return (
    <Grid columns={3} data-elm-id="campaign-prompts-list-element" className={"campaignCategoryGrid"}>
      <Grid.Row columns={3} className={display ? "" : "collapsedSection"}>
        <Header as="h2" content={name} />
        {selectedPromptsCounter > 0 && (
          <span className={"tagSection"}>
            {selectedPromptsCounter} {promptSelectedMessage}
          </span>
        )}

        <Grid.Column width={4} floated="right" className={"noBorder"}>
          <Button
            data-elm-id={`editCampaignDisplayBtn`}
            basic
            content={actionTitle()}
            icon={!display ? "triangle right" : "triangle down"}
            className={"noBorder"}
            labelPosition={"right"}
            onClick={() => setDisplay(!display)}
          />
        </Grid.Column>
      </Grid.Row>
      {pageable && display && !firstPage && (
        <Button
          data-elm-id={`editCampaignCategorySectionOnPrevPageBtn`}
          icon={"chevron left"}
          className={"leftArrowStyle"}
          onClick={onPreviousHandler}
        />
      )}
      {display && (
        <div key={name} className={!latestPage && !firstPage ? "ui container middlePage" : "ui container"}>
          {promptList.map(prompt => {
            return (
              <PromptSelectableCard
                key={prompt.promptId}
                prompt={prompt}
                selected={selectedPrompts[prompt!.promptId!] ? true : false}
                onChange={checked => onSelectPrompt(prompt, checked)}
                editable={true}
              />
            );
          })}
        </div>
      )}
      {pageable && display && !latestPage && (
        <Button
          data-elm-id={`editCampaignCategorySectionOnNextPageBtn`}
          icon={"chevron right"}
          className={"arrowStyle"}
          onClick={onNextHandler}
        />
      )}
    </Grid>
  );
};

export const CategoryRow = React.memo(CategorySectionFC);
