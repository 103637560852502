import { client as axios } from "./axiosClient";
import { Constants } from "@socialcoach/services";
import { UserContext, catchServiceException } from "@socialcoach/services";
import * as DTO from "../interfaces";
import { scCatchServiceException } from "../annotations";

export class SocialCoachSessionService {
  @catchServiceException()
  static async createProspect(payload: DTO.CreateProspectPayload): Promise<DTO.ProspectResponse> {
    const response = await axios.post<DTO.ProspectResponse>(`${Constants.API_URL}/prospects`, payload, {
      withCredentials: false,
    });
    return response.data;
  }

  @catchServiceException()
  static async createBasicUser(payload: DTO.CreateUserPayload): Promise<DTO.PlayerRegistered> {
    const response = await axios.post<DTO.PlayerRegistered>(`${Constants.API_URL}/accounts/basicWithCaptcha`, payload, {
      withCredentials: false,
    });
    return response.data;
  }

  @catchServiceException()
  static async createSubCoach(payload: DTO.CreateSubUserPayload, coachId: string): Promise<DTO.UserRegistered> {
    const response = await axios.post<DTO.UserRegistered>(
      `${Constants.API_URL}/accounts/subcoaches/${coachId}`,
      payload,
      {
        withCredentials: false,
      }
    );
    return response.data;
  }

  @scCatchServiceException()
  static async createUser(payload: DTO.CreateUserPayload): Promise<DTO.UserRegistered> {
    const response = await axios.post<DTO.UserRegistered>(
      `${Constants.API_URL}/accounts`,
      {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        phone: payload.phone,
        birthday: payload.birthday,
        password: payload.password,
        userRole: payload.userRole,
      },
      {
        withCredentials: false,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async createUserStep2(payload: DTO.SocialNetworkPayload, userId?: number): Promise<UserContext> {
    const response = await axios.put<UserContext>(`${Constants.API_URL}/accounts/step2?userId=${userId!}`, payload, {
      withCredentials: false,
    });
    return response.data;
  }

  @scCatchServiceException()
  static async updateDuringRegister(payload: DTO.CreateUserPayload, userId: number): Promise<DTO.UserRegistered> {
    const response = await axios.put<DTO.UserRegistered>(
      `${Constants.API_URL}/accounts?userId=${userId}`,
      {
        firstName: payload.firstName,
        lastName: payload.lastName,
        phone: payload.phone,
        birthday: payload.birthday,
        userRole: payload.userRole,
      },
      {
        withCredentials: false,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async update(payload: DTO.UpdateUserPayload): Promise<DTO.UserRegistered> {
    const response = await axios.put<DTO.UserRegistered>(
      `${Constants.API_URL}/accounts/update`,
      {
        firstName: payload.firstName,
        lastName: payload.lastName,
        phone: payload.phone,
        birthday: payload.birthday,
        photoUrl: payload.photoUrl,
        socialNetworks: payload.socialNetworks,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @scCatchServiceException()
  static async updatePlayer(payload: DTO.UpdateUserPayload): Promise<DTO.UserRegistered> {
    const response = await axios.put<DTO.UserRegistered>(
      `${Constants.API_URL}/accounts/update`,
      {
        firstName: payload.firstName,
        lastName: payload.lastName,
        phone: payload.phone,
        birthday: payload.birthday,
        photoUrl: payload.photoUrl,
        socialNetworks: payload.socialNetworks,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @scCatchServiceException()
  static async saveAccessToken(accessToken: string, networkId: DTO.SocialNetworksId): Promise<any> {
    const response = await axios.post<any>(
      `${Constants.API_URL}/accounts/profiles/add-social-network`,
      {
        accessToken,
        networkId,
      },
      {
        withCredentials: false,
      }
    );

    return response.data;
  }

  @scCatchServiceException()
  static async addSocialNetwork(payload: DTO.AddSocialTokenRequest): Promise<DTO.SocialNetwork> {
    const response = await axios.post<DTO.SocialNetwork>(
      `${Constants.API_URL}/accounts/profiles/complete-social-network`,
      {
        ...payload,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async updateCoach(
    coachId: string,
    payload: DTO.BaseUpdateCoach,
    userId?: number
  ): Promise<DTO.ResponseUpdateCoach> {
    const response = await axios.put<DTO.ResponseUpdateCoach>(
      `${Constants.API_URL}/accounts/coach/${coachId}?userId=${userId}`,
      {
        ...payload,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async updateSubCoach(userId: number, payload: DTO.UpdateSubCoachRequest): Promise<DTO.ResponseUpdateCoach> {
    const response = await axios.put<DTO.ResponseUpdateCoach>(
      `${Constants.API_URL}/accounts/subcoaches/${userId}`,
      {
        ...payload,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async resendVerify(email: string): Promise<void> {
    await axios.put<void>(
      `${Constants.API_URL}/accounts/resend-verify`,
      {
        email,
      },
      {
        withCredentials: false,
      }
    );
  }

  @catchServiceException()
  static async removeSocialNetwork(socialNetworkID: DTO.SocialNetworksId, profileId: string): Promise<any> {
    const response = await axios.delete<any>(
      `${Constants.API_URL}/accounts/profiles/${profileId}/social-networks/${socialNetworkID}`,
      {
        withCredentials: false,
      }
    );
    return response.data;
  }

  @catchServiceException()
  static async resetPasswordRequest(email: string): Promise<void> {
    await axios.put<void>(
      `${Constants.API_URL}/accounts/send-change-password?email=${encodeURIComponent(email)}`,
      null,
      {
        withCredentials: false,
      }
    );
  }

  @catchServiceException()
  static async changePassword(userId: string, actionToken: string, newPassword: string): Promise<void> {
    await axios.put<void>(
      `${Constants.API_URL}/accounts/change-password`,
      {
        userId,
        token: actionToken,
        password: newPassword,
      },
      {
        withCredentials: false,
      }
    );
  }

  @catchServiceException()
  static async userChangePassword(oldPassword: string, newPassword: string): Promise<void> {
    await axios.put<void>(
      `${Constants.API_URL}/accounts/change-my-password`,
      {
        oldPassword,
        newPassword,
      },
      {
        withCredentials: true,
      }
    );
  }

  @catchServiceException()
  static async changeSubCoachPassword(userId: number, newPassword: string): Promise<void> {
    await axios.put<void>(
      `${Constants.API_URL}/accounts/change-subcoach-password`,
      {
        userId,
        newPassword,
      },
      {
        withCredentials: true,
      }
    );
  }

  @catchServiceException()
  static async impersonate(username: string): Promise<void> {
    await axios.post<void>(
      `${Constants.API_URL}/auth/impersonate`,
      {
        username,
      },
      {
        withCredentials: true,
      }
    );
  }

  @catchServiceException()
  static async getUserContext(): Promise<UserContext> {
    const response = await axios.get<UserContext>(`${Constants.API_URL}/accounts/me`, {
      withCredentials: true,
    });
    return response.data;
  }
}
