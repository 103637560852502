import { defineMessages } from "react-intl";

export enum CampaignPageType {
  title = "CampaignPageType.title",
  titleEdit = "CampaignPageType.titleEdit",

  promptStepError = "CampaignPageType.promptStepError",
  savingError = "CampaignPageType.savingError",
  segmentationStepError = "CampaignPageType.segmentationStepError",

  randomDelivery = "CampaignPageType.randomDelivery",
  specificDate = "CampaignPageType.specificDate",

  segmentationSectionTitle = "CampaignPageType.videoMessage",
  publishBtn = "CampaignPageType.publishBtn",
  titlePlaceholder = "CampaignPageType.titlePlaceholder",

  autoSubcribeSectionTitle = "CampaignPageType.autoSubcribeSectionTitle",
  enrolledSectionTitle = "CampaignPageType.enrolledSectionTitle",

  saveBtn = "CampaignPageType.saveBtn",

  saveUnPublishBtn = "CampaignPageType.saveUnPublishBtn",

  cancelBtn = "CampaignPageType.Cancel",
  unknownError = "CampaignPageType.unknownError",
  savedMessage = "CampaignPageType.savedMessage",

  saveCampaignAlert = "CampaignPageType.saveCampaignAlert",
  yesBtn = "CampaignPageType.yesBtn",
  noBtn = "CampaignPageType.noBtn",
}

export const en = {
  [CampaignPageType.title]: "Edit Campaign",
  [CampaignPageType.promptStepError]: "You must select at least 1 prompt",
  [CampaignPageType.segmentationStepError]: "You must select at least 1 player",
  [CampaignPageType.savingError]: "Error updating your campaign",

  [CampaignPageType.randomDelivery]: "Random Delivery Date",
  [CampaignPageType.specificDate]: "Specific Date",
  [CampaignPageType.titlePlaceholder]: "Enter title",

  [CampaignPageType.segmentationSectionTitle]: "Eligible Players",
  [CampaignPageType.autoSubcribeSectionTitle]: "Auto Enroll Players",
  [CampaignPageType.enrolledSectionTitle]: "Enrolled Players",

  [CampaignPageType.cancelBtn]: "Cancel",
  [CampaignPageType.saveBtn]: "Save",
  [CampaignPageType.publishBtn]: "Save & Publish",

  [CampaignPageType.saveUnPublishBtn]: "Save & Unpublish",

  [CampaignPageType.savedMessage]: "Prompt saved!",
  [CampaignPageType.unknownError]: "Unknown Error.",

  [CampaignPageType.saveCampaignAlert]: "Do you want to save this campaign?",

  [CampaignPageType.yesBtn]: "Yes",
  [CampaignPageType.noBtn]: "No",
};

export const descriptors = defineMessages({
  [CampaignPageType.title]: {
    id: CampaignPageType.title,
    defaultMessage: en[CampaignPageType.title],
    description: "-",
  },
  [CampaignPageType.titleEdit]: {
    id: CampaignPageType.titleEdit,
    defaultMessage: en[CampaignPageType.titleEdit],
    description: "-",
  },
  [CampaignPageType.randomDelivery]: {
    id: CampaignPageType.randomDelivery,
    defaultMessage: en[CampaignPageType.randomDelivery],
    description: "-",
  },
  [CampaignPageType.specificDate]: {
    id: CampaignPageType.specificDate,
    defaultMessage: en[CampaignPageType.specificDate],
    description: "-",
  },
  [CampaignPageType.promptStepError]: {
    id: CampaignPageType.promptStepError,
    defaultMessage: en[CampaignPageType.promptStepError],
    description: "-",
  },

  [CampaignPageType.savingError]: {
    id: CampaignPageType.savingError,
    defaultMessage: en[CampaignPageType.savingError],
    description: "-",
  },
  [CampaignPageType.segmentationSectionTitle]: {
    id: CampaignPageType.segmentationSectionTitle,
    defaultMessage: en[CampaignPageType.segmentationSectionTitle],
    description: "-",
  },
  [CampaignPageType.enrolledSectionTitle]: {
    id: CampaignPageType.enrolledSectionTitle,
    defaultMessage: en[CampaignPageType.enrolledSectionTitle],
    description: "-",
  },
  [CampaignPageType.publishBtn]: {
    id: CampaignPageType.publishBtn,
    defaultMessage: en[CampaignPageType.publishBtn],
    description: "-",
  },
  [CampaignPageType.segmentationStepError]: {
    id: CampaignPageType.segmentationStepError,
    defaultMessage: en[CampaignPageType.segmentationStepError],
    description: "-",
  },
  [CampaignPageType.titlePlaceholder]: {
    id: CampaignPageType.titlePlaceholder,
    defaultMessage: en[CampaignPageType.titlePlaceholder],
    description: "-",
  },
  [CampaignPageType.cancelBtn]: {
    id: CampaignPageType.cancelBtn,
    defaultMessage: en[CampaignPageType.cancelBtn],
    description: "-",
  },
  [CampaignPageType.unknownError]: {
    id: CampaignPageType.unknownError,
    defaultMessage: en[CampaignPageType.unknownError],
    description: "-",
  },

  [CampaignPageType.autoSubcribeSectionTitle]: {
    id: CampaignPageType.autoSubcribeSectionTitle,
    defaultMessage: en[CampaignPageType.autoSubcribeSectionTitle],
    description: "-",
  },

  [CampaignPageType.saveBtn]: {
    id: CampaignPageType.saveBtn,
    defaultMessage: en[CampaignPageType.saveBtn],
    description: "-",
  },

  [CampaignPageType.saveUnPublishBtn]: {
    id: CampaignPageType.saveUnPublishBtn,
    defaultMessage: en[CampaignPageType.saveUnPublishBtn],
    description: "-",
  },
  [CampaignPageType.savedMessage]: {
    id: CampaignPageType.savedMessage,
    defaultMessage: en[CampaignPageType.savedMessage],
    description: "-",
  },

  [CampaignPageType.saveCampaignAlert]: {
    id: CampaignPageType.saveCampaignAlert,
    defaultMessage: en[CampaignPageType.saveCampaignAlert],
    description: "-",
  },

  [CampaignPageType.yesBtn]: {
    id: CampaignPageType.yesBtn,
    defaultMessage: en[CampaignPageType.yesBtn],
    description: "-",
  },
  [CampaignPageType.noBtn]: {
    id: CampaignPageType.noBtn,
    defaultMessage: en[CampaignPageType.noBtn],
    description: "-",
  },
});
