import * as React from "react";
import { Formik } from "formik";
import { useIntl } from "react-intl";
import { ResendErrorType, descriptors } from "./descriptors";
import { validEmail } from "../../utils";
import { ResendVerificationEmail } from "../../components";
import { SocialCoachSessionService } from "../../services";
import { VerificationActions } from "../../interfaces/customTypes";

export interface Props {
  email?: string;
  successHandler: (message: string) => void;
  type?: VerificationActions;
}

const ResendFormFC: React.FC<Props> = ({ email = "", type = "ResendVerify", successHandler }) => {
  const { formatMessage } = useIntl();
  // Synchronous validation
  const validate = (values: any) => {
    const errors: Partial<Props> = {};
    if (!values.email) {
      errors.email = formatMessage({ ...descriptors[ResendErrorType.email] });
    } else if (validEmail(values.email)) {
      errors.email = formatMessage({ ...descriptors[ResendErrorType.invalidEmail] });
    }
    return errors;
  };

  return (
    <div>
      <Formik
        validateOnBlur={false}
        validateOnChange={true}
        initialValues={{ email }}
        validate={validate}
        onSubmit={async ({ email: usr }, { setSubmitting, setErrors, validateForm }) => {
          try {
            const errors = await validateForm({ email: usr });
            if (errors && Object.values(errors).length > 0) {
              setErrors(errors);
            } else {
              switch (type) {
                case "ForgotPassword":
                  await SocialCoachSessionService.resetPasswordRequest(usr!);
                  break;
                case "ResendVerify":
                  await SocialCoachSessionService.resendVerify(usr!);
                  break;
                default:
                  await SocialCoachSessionService.resendVerify(usr!);
                  break;
              }

              successHandler(formatMessage({ ...descriptors[ResendErrorType.responseMessage] }));
            }
          } catch (e: any) {
            successHandler(formatMessage({ ...descriptors[ResendErrorType.responseMessage] }));
          }
          setSubmitting(false);
        }}
      >
        {({ errors, handleChange, handleSubmit, isSubmitting }) => (
          <ResendVerificationEmail
            messages={{
              error: Object.values(errors) as string[],
            }}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            type={type!}
          />
        )}
      </Formik>
    </div>
  );
};

export const ResendForm = ResendFormFC;
