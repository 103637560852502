import * as Yup from "yup";
import { Rules } from "../../../utils";

export const validationSchema = Yup.object().shape(
  {
    messageToPost: Yup.string()
      .trim()
      .max(Rules.MAX_CONTENT, "The caption is too long")
      .required("Caption is required")
      .max(Rules.MAX_CONTENT, "Caption is too long"),
    withMediaReady: Yup.number().min(0, "Media files required"),
    blockedWords: Yup.string()
      .trim()
      .max(0, obj => {
        // const value = obj.value;
        return `Blocked words detected: ${obj.value}`;
      }),
  },
  []
);

// \\ `Caption includes blocked words: ${value}`;
export const scheduledValidationSchema = Yup.object().shape(
  {
    socialNetworksCounter: Yup.number().when("isAutoPost", {
      is: (a: any) => a === true,
      then: () => Yup.number().min(1, "You must select at least one social network to Auto Post"),
    }),
  },
  []
);
