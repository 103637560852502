import styled from "styled-components";
import { Grid, Card, Button } from "semantic-ui-react";
import { Svg } from "../../../assets";
import { theme } from "../../../styling/theme";

const defaultImage = Svg.PromptPlaceholder;

const thumbnailSize = (thumbnail?: string): string => {
  if (thumbnail) {
    return `url("${thumbnail}") no-repeat center top`;
  }
  return `url(${defaultImage}) no-repeat center center`;
};

export const StickerGridStyle = styled(Grid.Column)({
  margin: "0 !important",
  padding: "0 !important",
});

export const StickerCardStyle = styled(Card)({
  background: `${theme.lightBackgroundColor} !important`,
  borderRadius: "6.3px !important",
  border: `1px solid ${theme.backgroundColor} !important`,
  boxShadow: `none !important`,
  width: "100px !important",
  height: "100px !important",
  margin: " 0 15px 15px 15px !important",
});

export const ThumbnailPreview = styled(Grid.Column)`
  width: ${(props: any) => props.imagewidth}px;
  height: ${(props: any) => props.imageheight}px;
  margin: 0px;
  background: ${(props: any) => thumbnailSize(props.thumbnailurl)};
  background-size: ${(props: any) => (props.thumbnailurl ? "cover" : "inherit")};
  background-color: #eaeaf0;
`;

export const DeleteButton = styled(Button)({
  position: "absolute",
  top: "0px",
  right: "0px",
  background: "white !important",
  color: theme.buttonColor + " !important",
  height: "25px",
  width: "25px !important",
  padding: "4px !important",
});
