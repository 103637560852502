import * as React from "react";
import { Image } from "semantic-ui-react";

import { Svg } from "../../../assets";
import * as DTO from "../../../interfaces";
import { URLS } from "../../../utils";

import "./styles.scss";
import { ThumbnailPreview, VideoProjectCardContentStyle, VideoProjectCardStyle, VideoProjectHeader } from "./styled";
import moment from "moment";

interface OwnProps {
  videoProject: DTO.PlayerVideoProjectResponse;
  edited: boolean;
}

type Props = OwnProps;

const VideoProjectEditorCardFC: React.FC<Props> = ({
  videoProject: { videoProjectId, statusLabel, thumbnail, companyName, playerName, statusDate, needsAck },
}: Props) => {
  return (
    <VideoProjectCardStyle
      className={needsAck ? "videoProjectCard editorNeedsAck" : "videoProjectCard"}
      href={URLS.editor.editVideo.replace(":id", `${videoProjectId}`)}
    >
      <Image className={"reelsIcon"} size="small" src={Svg.Reels} />
      <ThumbnailPreview width={3} thumbnailurl={thumbnail && thumbnail.thumbnailUri} />
      <VideoProjectCardContentStyle>
        <VideoProjectHeader>{playerName}</VideoProjectHeader>
        <VideoProjectHeader>{companyName || "-"}</VideoProjectHeader>
        <VideoProjectCardStyle.Description>{statusLabel}</VideoProjectCardStyle.Description>
        <VideoProjectCardStyle.Description>
          {moment(statusDate!!).format("MMM DD, YY [at] hh:mm a")}
        </VideoProjectCardStyle.Description>
      </VideoProjectCardContentStyle>
    </VideoProjectCardStyle>
  );
};

export const VideoProjectEditorCard = VideoProjectEditorCardFC;
