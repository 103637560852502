import Cookies from "js-cookie";
import * as React from "react";
import { UserContext, Constants } from "@socialcoach/services";
import { PropsWithChildren } from "react";
import { SocialCoachSessionService } from "../../services";

export const SessionContext = React.createContext<State>({
  loading: false,
  setUserContext: (_?: UserContext) => null,
  userContext: undefined,
});

type State = {
  userContext?: UserContext;
  setUserContext: (userContext?: UserContext) => void;
  loading: boolean;
};

type Props = {
  userContext?: UserContext;
  loading?: boolean;
};

export class SessionContextProvider extends React.Component<PropsWithChildren<Props>> {
  updateContext = (userContext?: UserContext): void => {
    this.setState({
      userContext,
      loading: false,
    });
  };

  state: State = {
    loading: this.props.loading === undefined ? true : this.props.loading,
    userContext: this.props.userContext,
    setUserContext: this.updateContext,
  };

  componentDidMount() {
    this.checkForSession();
  }

  checkForSession = async (): Promise<void> => {
    const isCookieSessionAvailable = !!Cookies.get(Constants.COOKIE_NAME);
    const newState: Partial<State> = { loading: false };
    if (isCookieSessionAvailable) {
      try {
        newState.userContext = await SocialCoachSessionService.getUserContext();
      } catch (e) {
        newState.userContext = undefined;
        console.warn("While getting userContext: ", e);
      }
    }
    this.setState(newState);
  };

  render() {
    return <SessionContext.Provider value={this.state}>{this.props.children}</SessionContext.Provider>;
  }
}
