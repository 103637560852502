import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { AppContext } from "../../providers";
import { Grid, Button, Icon } from "semantic-ui-react";

import { VideoProjectCard } from "../Cells";
import * as DTO from "../../interfaces";
import { URLS, coachWithWritePermission } from "../../utils";

import "./styles.scss";

interface OwnProps {
  name: string;
  videoProjects: DTO.PlayerVideoProjectResponse[];
  player?: DTO.PlayerResumeResponse;

  page?: number;
  pageable?: boolean;
  latestPage?: boolean;
  firstPage?: boolean;

  totalElements?: number;
  onNext?: (nextPage: number) => void;
}

type Props = OwnProps & RouteComponentProps;

const PlayerSectionFC: React.FC<Props> = ({
  player,
  videoProjects,
  name,
  page = 0,
  pageable = false,
  firstPage = true,
  latestPage = false,
  totalElements = 0,
  history,
  onNext,
}) => {
  const [display, setDisplay] = React.useState<boolean>(true);
  const { userContext } = React.useContext(AppContext);
  const withWritePermission = coachWithWritePermission(DTO.WebComponents.VIDEO_CATALYST, userContext);

  const actionTitle = (): string => {
    if (!display) {
      return "Collapsed (" + totalElements + ")";
    }
    return pageable
      ? `Showing ${page * 5} - ${page * 5 + videoProjects.length} of ${totalElements}`
      : `Show all (${totalElements})`;
  };

  const onNextHandler = () => {
    if (onNext) {
      onNext(page + 1);
    }
  };
  const onPreviousHandler = () => {
    if (onNext) {
      onNext(page - 1);
    }
  };

  return (
    <Grid columns={3} data-elm-id="videoProjectListElement" className={"videoProjectsPlayerGrid"}>
      <Grid.Row columns={3} className={display ? "" : "collapsedSection"}>
        <Grid.Column width={16} floated="left" className={"subSectionTitle"}>
          <label>{name}</label>
        </Grid.Column>
        <Grid.Column width={4} floated="right" className={"noBorder"}>
          <Button
            data-elm-id={`playerSectionDisplayBtn`}
            basic
            content={actionTitle()}
            icon={!display ? "triangle right" : "triangle down"}
            className={"noBorder"}
            labelPosition={"right"}
            onClick={() => setDisplay(!display)}
          />
        </Grid.Column>
      </Grid.Row>
      {pageable && display && !firstPage && (
        <Button
          data-elm-id={`playerSectionOnPrevPageBtn`}
          icon={"chevron left"}
          className={"leftArrowStyle"}
          onClick={onPreviousHandler}
        />
      )}
      {display && withWritePermission && (
        <Button
          className={"addButton"}
          data-elm-id={`playerVideoProjectsSectionAddBtn`}
          onClick={() => {
            let locationValues = {};
            locationValues = {
              playerId: player?.playerId,
            };
            history.push(URLS.coach.newVideoProject + "?playerId=" + player?.playerId, locationValues);
          }}>
          <Icon circular name="add" /> Add new
        </Button>
      )}

      {display && (
        <div key={name} className={!latestPage && !firstPage ? "ui container middlePage" : "ui container"}>
          {videoProjects.map(videoProject => {
            return (
              <VideoProjectCard key={"video_project_card_" + videoProject.videoProjectId} videoProject={videoProject} />
            );
          })}
        </div>
      )}
      {pageable && display && !latestPage && (
        <Button
          data-elm-id={"playerSectionOnNextPageBtn"}
          icon={"chevron right"}
          className={"arrowStyle"}
          onClick={onNextHandler}
        />
      )}
    </Grid>
  );
};

export const PlayerSection = withRouter(React.memo(PlayerSectionFC));
