import * as React from "react";
import { Formik } from "formik";
import { RouteComponentProps, withRouter } from "react-router";
import _, { get, map as mapValues } from "lodash";

import {
  EditableCampaign,
  Category,
  dateKey,
  DayOfWeek,
  snKey,
  timeKey,
  titleKey,
  PromptCampaign,
} from "../../../../../interfaces";
import { CampaignInformationForm } from "../../../../../components";
import { promptSpecificDate } from "../../../../../utils";

import { validationSchemaBuilder } from "./validations";

export interface CampaignCustomForm {
  campaignId: string;
  name: string;
  deliveryMode: string;
  isAutoPost: boolean;
  randomScheduleDays: string[];
  categories: any[];
  prompts: { [key: string]: string };
  randomScheduleDeliveryTimePst?: string;
  datePickerDeliveryTime?: string;
  startDate?: string;
  endDate?: string;
  startDatePicker?: string;
  endDatePicker?: string;
}

export interface OwnProps {
  /**
   * Campaign Info
   */
  campaign?: EditableCampaign;

  /**
   * Forms ref - calls submit outside form
   */
  submitRef: any;

  disabled: boolean;

  /**
   * Campaign can be updated (date, time, prompts)
   */
  editable?: boolean;

  /**
   * Id of prompts that were included previously
   */
  initialPromptsIds: string[];

  /**
   * Function called when the form is submitted succesfully
   */
  onSuccessHandler: (campaign: EditableCampaign) => void;

  /**
   * Function called when delete button is clicked
   */
  onDeleteHandler: () => void;

  /**
   * Function called when add prompt button is clicked
   */
  addPromptHandler: () => void;
}

type Props = OwnProps & RouteComponentProps;

const InformationForm: React.FC<Props> = ({
  campaign,
  submitRef,
  disabled,
  editable = true,
  initialPromptsIds = [],
  onSuccessHandler,
  onDeleteHandler,
  addPromptHandler,
}) => {
  const [promptsSorted, setSortedPrompts] = React.useState<PromptCampaign[] | undefined>(
    _.orderBy(campaign!.campaignPrompts, ["sortOrder"], ["asc"])
  );

  const transformCampaigns = (): CampaignCustomForm => {
    let prompstHash = {};
    if (promptsSorted) {
      promptsSorted.map((cprompt: PromptCampaign) => {
        return (prompstHash = {
          ...prompstHash,
          [cprompt.prompt!.promptId + titleKey]: cprompt.prompt!.title,
          [cprompt.prompt!.promptId + timeKey]: get(cprompt, "deliveryDatetimeUtc", undefined),
          [cprompt.prompt!.promptId + dateKey]: get(cprompt, "deliveryDatetimeUtc", undefined),
          [cprompt.prompt!.promptId + snKey]: get(cprompt, "socialNetworks", []),
        });
      });
    }

    return {
      campaignId: get(campaign, "campaignId", ""),
      name: get(campaign, "name", ""),
      deliveryMode: get(campaign, "deliveryMode", "RANDOM_DELIVERY"),
      isAutoPost: get(campaign, "isAutoPost", false),
      randomScheduleDays: mapValues(get(campaign, "randomWeeklySchedule.schedule", []), "dayOfWeek"),
      categories: get(campaign, "categories", []),
      prompts: prompstHash,
      randomScheduleDeliveryTimePst: get(campaign, "randomScheduleDeliveryTimePst", undefined),
      datePickerDeliveryTime: undefined,
      startDate: get(campaign, "startDate", undefined),
      endDate: get(campaign, "endDate", undefined),
      startDatePicker: get(campaign, "startDate", undefined),
      endDatePicker: get(campaign, "endDate", undefined),
    };
  };

  const submitAction = (values: any): void => {
    const {
      name,
      deliveryMode,
      randomScheduleDays,
      isAutoPost,
      categories,
      prompts,
      randomScheduleDeliveryTimePst,
      startDate,
      endDate,
    } = values;

    const campaignDeliveryTime =
      deliveryMode !== "SPECIFIC_DATE" ? get(campaign, "randomWeeklySchedule.schedule", [])[0].time : "";

    const days = randomScheduleDays!.map((day: DayOfWeek) => {
      return {
        dayOfWeek: day,
        time: randomScheduleDeliveryTimePst || campaignDeliveryTime,
      };
    });

    const updatePrompts = promptsSorted
      ? promptsSorted.map((cprompt: PromptCampaign) => {
          const promptsValues = prompts;
          const deliveryDatetimeUtc =
            promptsValues[cprompt.prompt!.promptId + timeKey] && promptsValues[cprompt.prompt!.promptId + dateKey]
              ? promptSpecificDate(
                  promptsValues[cprompt.prompt!.promptId + dateKey],
                  promptsValues[cprompt.prompt!.promptId + timeKey]
                )!
              : undefined;

          return {
            ...cprompt,
            deliveryDatetimeUtc,
            sortOrder: cprompt.sortOrder,
            socialNetworks: promptsValues[cprompt.prompt!.promptId + snKey],
          };
        })
      : [];

    const updatedCampaing = {
      ...campaign,
      name,
      randomScheduleDays: deliveryMode !== "SPECIFIC_DATE" ? days : undefined,
      randomScheduleDeliveryTimePst,
      deliveryMode,
      isAutoPost,
      campaignPrompts: updatePrompts,
      categoryIds: categories.map((cat: Category) => cat.categoryId),
      startDate,
      endDate,
    } as EditableCampaign;

    onSuccessHandler(updatedCampaing);
  };

  return (
    <div>
      {campaign && (
        <Formik
          validateOnBlur={true}
          validateOnChange={false}
          validateOnMount={false}
          initialValues={transformCampaigns()}
          validationSchema={() => validationSchemaBuilder(transformCampaigns())}
          onSubmit={async (values, { setErrors, validateForm }) => {
            const errorsResponse = await validateForm(values);
            if (errorsResponse && Object.values(errorsResponse).length > 0) {
              setErrors(errorsResponse);
            } else {
              setErrors({});
              submitAction(values);
            }
          }}>
          {({ values, errors, handleChange, setFieldValue, handleSubmit }) => {
            return (
              <CampaignInformationForm
                messages={{
                  error: Object.values(errors) as string[],
                }}
                disabled={disabled}
                editable={editable}
                initialPromptsIds={initialPromptsIds}
                prompts={promptsSorted!!}
                submitRef={submitRef}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                handleSetFieldValue={setFieldValue}
                values={values}
                isSubmitting={false}
                onDeleteHandler={onDeleteHandler}
                addPromptHandler={addPromptHandler}
                handlePromptsSorted={setSortedPrompts}
              />
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export const InformationComponent = withRouter(InformationForm)
